import React, {Component} from 'react';
import { Badge, ProgressBar, OverlayTrigger, Tooltip, Form } from 'react-bootstrap';
import { L10n } from '@syncfusion/ej2-base';
import { getObject } from '@syncfusion/ej2-grids';
import { TreeGridComponent, ColumnsDirective, ColumnDirective, ColumnMenu } from '@syncfusion/ej2-react-treegrid';
import { Inject, Edit, Toolbar, ColumnChooser, ContextMenu, Filter, Sort, Page, Reorder, Resize, ExcelExport } from '@syncfusion/ej2-react-treegrid';
import * as ej2FRlocale from './EJ2_LOCALE/ej2FRlocale.json';
import * as ej2ESlocale from './EJ2_LOCALE/ej2ESlocale.json';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';
import IconCloud from '../Images/IconCloud.png';
import IconMoon from '../Images/IconMoon.png';
import IconRain from '../Images/IconRain.png';
import IconSun from '../Images/IconSun.png';
import IconThunder from '../Images/IconThunder.png';
import Timeline from './Timeline';

// Traductions
L10n.load({ fr: ej2FRlocale.fr, es: ej2ESlocale.es });

class MiniTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authId: null,
      language: null,
      formatDate: null,
      itemId: null,
      itemType: null,
      itemTitle: null,
      blockType: null,
      editable: null,
      pagging: false,
      currentView: {},
      columns: [],
      rows: [],
      dataSource: [],
      error: {}
    };

    // Table Structure
    this.created = this.created.bind(this);
    this.createColumn = this.createColumn.bind(this);
    this.dataStructure = this.dataStructure.bind(this);
    this.dataBound = this.dataBound.bind(this);
    this.rowDataBound = this.rowDataBound.bind(this);
    this.getColumnHeaders = this.getColumnHeaders.bind(this);
    this.getColumnType = this.getColumnType.bind(this);
    this.getColumnUnit = this.getColumnUnit.bind(this);
    this.getColumnConditionalFormattings = this.getColumnConditionalFormattings.bind(this);
    this.getCellConditionalFormatting = this.getCellConditionalFormatting.bind(this);
    this.isConditionalFormattingRespected = this.isConditionalFormattingRespected.bind(this);
    this.formatDateEn = this.formatDateEn.bind(this);
    this.formatDateFr = this.formatDateFr.bind(this);
    this.formatDateKr = this.formatDateKr.bind(this);

    // Actions
    this.actionBegin = this.actionBegin.bind(this);
    this.actionComplete = this.actionComplete.bind(this);
    this.resizeStop = this.resizeStop.bind(this);
    this.refreshColumns = this.refreshColumns.bind(this);
    this.excelQueryCellInfo = this.excelQueryCellInfo.bind(this);
    this.exportXLSX = this.exportXLSX.bind(this);
    this.searchItem = this.searchItem.bind(this);

    // Template Functions
    this.templateColumnAction = this.templateColumnAction.bind(this);
    this.templateColumnActive = this.templateColumnActive.bind(this);
    this.templateColumnAdmin = this.templateColumnAdmin.bind(this);
    this.templateColumnBudgetCode = this.templateColumnBudgetCode.bind(this);
    this.templateColumnBusinessLine = this.templateColumnBusinessLine.bind(this);
    this.templateColumnDataFreshness = this.templateColumnDataFreshness.bind(this);
    this.templateColumnDecisions = this.templateColumnDecisions.bind(this);
    this.templateColumnEmail = this.templateColumnEmail.bind(this);
    this.templateColumnEntity = this.templateColumnEntity.bind(this);
    this.templateColumnExternal = this.templateColumnExternal.bind(this);
    this.templateColumnHashtags = this.templateColumnHashtags.bind(this);
    this.templateColumnHighlighted = this.templateColumnHighlighted.bind(this);
    this.templateColumnImpact = this.templateColumnImpact.bind(this);
    this.templateColumnIndex = this.templateColumnIndex.bind(this);
    this.templateColumnItemType = this.templateColumnItemType.bind(this);
    this.templateColumnLag = this.templateColumnLag.bind(this);
    this.templateColumnLicenceType = this.templateColumnLicenceType.bind(this);
    this.templateColumnMeetingIndex = this.templateColumnMeetingIndex.bind(this);
    this.templateColumnMeeting = this.templateColumnMeeting.bind(this);
    this.templateColumnMeteo = this.templateColumnMeteo.bind(this);
    this.templateColumnMeteoFreshness = this.templateColumnMeteoFreshness.bind(this);
    this.templateColumnMonthBurned = this.templateColumnMonthBurned.bind(this);
    this.templateColumnName = this.templateColumnName.bind(this);
    this.templateColumnPriority = this.templateColumnPriority.bind(this);
    this.templateColumnProbability = this.templateColumnProbability.bind(this);
    this.templateColumnProgress = this.templateColumnProgress.bind(this);
    this.templateColumnProject = this.templateColumnProject.bind(this);
    this.templateColumnResourceName = this.templateColumnResourceName.bind(this);
    this.templateColumnRowType = this.templateColumnRowType.bind(this);
    this.templateColumnSeverity = this.templateColumnSeverity.bind(this);
    this.templateColumnSprint = this.templateColumnSprint.bind(this);
    this.templateColumnStatus = this.templateColumnStatus.bind(this);
    this.templateColumnTimeline = this.templateColumnTimeline.bind(this);
    this.templateColumnTrend = this.templateColumnTrend.bind(this);
    this.templateColumnValidated = this.templateColumnValidated.bind(this);
    this.templateColumnWarning = this.templateColumnWarning.bind(this);
    this.templateColumnWorkpackage = this.templateColumnWorkpackage.bind(this);
    this.templateColumnYear = this.templateColumnYear.bind(this);

    this.templateTypeAxe = this.templateTypeAxe.bind(this);
    this.templateTypeAxisTable = this.templateTypeAxisTable.bind(this);
    this.templateTypeBoolean = this.templateTypeBoolean.bind(this);
    this.templateTypeDate = this.templateTypeDate.bind(this);
    this.templateTypeDouble = this.templateTypeDouble.bind(this);
    this.templateTypeFiles = this.templateTypeFiles.bind(this);
    this.templateTypeHTML = this.templateTypeHTML.bind(this);
    this.templateTypeLink = this.templateTypeLink.bind(this);
    this.templateTypeLocation = this.templateTypeLocation.bind(this);
    this.templateTypePercentage = this.templateTypePercentage.bind(this);
    this.templateTypeProgress = this.templateTypeProgress.bind(this);
    this.templateTypeRating = this.templateTypeRating.bind(this);
    this.templateTypeResource = this.templateTypeResource.bind(this);
    this.templateTypeResourceTable = this.templateTypeResourceTable.bind(this);
    this.templateTypeText = this.templateTypeText.bind(this); 
  }
  
  componentDidMount() {
    const authId = Authentication.getCookie('authId');
    const language = Authentication.getCookie('language');
    const formatDate = Authentication.getCookie('formatDate');
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const itemTitle = this.props.Title;
    const blockType = this.props.BlockType;
    const editable = this.props.Editable;
    const pagging = this.props.Pagging;
    const currentView = this.props.CurrentView;
    const columns = this.props.Columns;
    const rows = this.props.Rows;

    // Build Table Datasource
    let dataSource = this.dataStructure(rows);

    this.setState({ authId, language, formatDate, itemId, itemType, itemTitle, blockType, editable, pagging, currentView, columns, rows, dataSource });
  }

  componentDidUpdate(prevProps) {
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const itemTitle = this.props.Title;
    const blockType = this.props.BlockType;
    const editable = this.props.Editable;
    const pagging = this.props.Pagging;
    const currentView = this.props.CurrentView;
    const columns = this.props.Columns;
    const rows = this.props.Rows;

    if(this.props.ItemId !== prevProps.ItemId || this.props.ItemType !== prevProps.ItemType || this.props.BlockType !== prevProps.BlockType) {
      this.setState({ itemId, itemType, itemTitle, blockType });
    }
    if(this.props.Editable !== prevProps.Editable) {
      this.setState({ editable });
    }
    if(this.props.Pagging !== prevProps.Pagging) {
      this.setState({ pagging });
    }
    if(JSON.stringify(this.props.CurrentView) !== JSON.stringify(prevProps.CurrentView) || this.props.Columns !== prevProps.Columns || JSON.stringify(this.props.Rows) !== JSON.stringify(prevProps.Rows)) {
      // Build Table Datasource
      let dataSource = this.dataStructure(rows);

      if(this.grid) {
        this.grid.dataSource = dataSource;
      }

      // Refresh Component Columns
      this.refreshColumns(currentView, columns);

      this.setState({ currentView, columns, rows, dataSource });
    }
  }

  created() {
    const currentView = this.state.currentView;
    let columns = [], columnsWidth = [], filters = [], sort = [];

    // Get Current View Columns
    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Columns')) {
      columns = (currentView.Parameters.find(param => param.Name === 'Columns').Value).split(',');
    }
    // Get Current View Columns Width
    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'ColumnsWidth')) {
      columnsWidth = JSON.parse(currentView.Parameters.find(param => param.Name === 'ColumnsWidth').Value);
    }

    // Get Columns Headers
    const columnHeaders = this.getColumnHeaders();

    // Auto Generated Columns
    if(this.grid) {
      // Clean Grid columns
      this.grid.columns = [];

      // Clean Grid Filter & Sort
      this.grid.clearFiltering();
      this.grid.clearSorting();

      // Loop through the columns to build and add them to the Grid
      for(let i=0; i < columns.length; i++) {
        let columnHeader, colObj;

        if(columnHeaders.find(columnHeader => columnHeader.FieldName === columns[i])) {
          // Get Column corresponding Column Header
          columnHeader = columnHeaders.find(columnHeader => columnHeader.FieldName === columns[i]);

          // Create Column object
          colObj = this.createColumn(columnHeader, columnsWidth);

          this.grid.columns.push(colObj);

          // Height (subtract 290px for Header & Filters)
          // if(this.grid.height > (window.innerHeight - 290)) {
          //   this.grid.height = (window.innerHeight - 290);
          // }
        }
      }

      // Add Column Item_ID
      if(!columns.includes('Item_ID') && columnHeaders.find(columnHeader => columnHeader.FieldName === 'Item_ID')) {
        let columnHeader = columnHeaders.find(columnHeader => columnHeader.FieldName === 'Item_ID');

        // Define Column object for Grid
        var colObj = {
          lockColumn: false,
          field: columnHeader.FieldName,
          headerText: columnHeader.Label,
          visible: false,
          showInColumnChooser: false,
          allowEditing: false,
          isPrimaryKey: true
        };

        this.grid.columns.push(colObj);
      }
      // Add Column Item_Type
      else if(!columns.includes('Item_Type') && columnHeaders.find(columnHeader => columnHeader.FieldName === 'Item_Type')) {
        let columnHeader = columnHeaders.find(columnHeader => columnHeader.FieldName === 'Item_Type');

        // Define Column object for Grid
        var colObj = {
          lockColumn: false,
          field: columnHeader.FieldName,
          headerText: columnHeader.Label,
          visible: false,
          showInColumnChooser: false,
          allowEditing: false,
          isPrimaryKey: false
        };

        this.grid.columns.push(colObj);
      }

      // Update Tree Column Index
      this.grid.treeColumnIndex = null;
      // this.grid.treeColumnIndex = this.getTreeColumnIndex(currentView);

      // Refresh Grid Columns
      this.grid.refreshColumns();

      // Apply Filters
      // if(currentView.Filters && currentView.Filters.length > 0) {
      //   // Get Current View Sort
      //   filters = currentView.Filters;

      //   filters.forEach(filter => {
      //     // If Filter Field is included in Columns list
      //     if(columns.includes(filter.Field)) {
      //       let suffix = "";

      //       // Add Suffix .Label to Object Fields {"Id": id, "Label": label} for Grid Component
      //       if(this.getColumnType(columnHeaders, filter.Field) === 'Axe' || this.getColumnType(columnHeaders, filter.Field) === 'Resource' || filter.Field === 'Meteo' || filter.Field === 'Trend' || filter.Field === 'Business_Line' || filter.FieldName === 'Project' || filter.Field === 'Workpackage' || filter.Field === 'Action' || filter.Field === 'Task' || filter.Field === 'Entity') {
      //         suffix = ".Label";
      //       }

      //       this.grid.filterByColumn(filter.Field + suffix, filter.Operator, filter.Items.split(','));
      //     }
      //   });
      // }
      // Apply Sort
      // if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Sort')) {
      //   // Get Current View Sort
      //   sort = currentView.Parameters.find(param => param.Name === 'Sort').Value;

      //   // If Sort Field is included in Columns list
      //   if(columns.includes(sort.split(' ')[0])) {
      //     let suffix = "";

      //     // Add Suffix .Label to Object Fields {"Id": id, "Label": label} for Grid Component
      //     if(this.getColumnType(columnHeaders, sort.split(' ')[0]) === 'Axe' || this.getColumnType(columnHeaders, sort.split(' ')[0]) === 'Resource' || sort.split(' ')[0] === 'Meteo' || sort.split(' ')[0] === 'Trend' || sort.split(' ')[0] === 'Business_Line' || sort.split(' ')[0] === 'Project' || sort.split(' ')[0] === 'Workpackage' || sort.split(' ')[0] === 'Action' || sort.split(' ')[0] === 'Task' || sort.split(' ')[0] === 'Entity') {
      //       suffix = ".Label";
      //     }

      //     this.grid.sortByColumn(sort.split(' ')[0] + suffix, sort.split(' ')[1]);
      //   }
      // }
    }
  }

  createColumn(columnHeader, columnsWidth) {
    const formatDate = this.state.formatDate;
    const itemType = this.state.itemType;
    const blockType = this.state.blockType;
    let type, field, format, label, template, headerTextAlign, textAlign, primaryKey, width;

    // Type
    if(columnHeader.FieldType === 'String' || columnHeader.FieldName === 'Meteo' || columnHeader.FieldName === 'Trend') {
      type = 'string';
    }
    else if(columnHeader.FieldType === 'Double' || columnHeader.FieldType === 'Percentage') {
      type = 'number';
    }
    else if(columnHeader.FieldType === 'Date') {
      type = 'date';
    }
    else {
      type = null;
    }

    // Field (Add Suffix .Label to Object Fields {"Id": id, "Label": label} for Grid Component)
    if((columnHeader.FieldType === 'Axe' && columnHeader.FieldName !== 'HashTag') || columnHeader.FieldType === 'Resource' || columnHeader.FieldType === 'Location' || columnHeader.FieldName === 'Meteo' || columnHeader.FieldName === 'Trend' || columnHeader.FieldName === 'Business_Line' || columnHeader.FieldName === 'Project' || columnHeader.FieldName === 'Workpackage' || columnHeader.FieldName === 'Action' || columnHeader.FieldName === 'Task' || columnHeader.FieldName === 'Entity') {
      field = columnHeader.FieldName;
      // field = columnHeader.FieldName + ".Label";
    }
    else {
      field = columnHeader.FieldName;
    }

    // Format
    if(columnHeader.FieldType === 'Date') {
      if(formatDate === 'MM/DD/YYYY') {
        format = { type: 'date', format: 'MM/dd/yyyy' };
      }
      else if(formatDate === 'DD/MM/YYYY') {
        format = { type: 'date', format: 'dd/MM/yyyy' };
      }
      else if(formatDate === 'YYYY-MM-DD') {
        format = { type: 'date', format: 'yyyy-MM-dd' };
      }
    }
    else {
      format = null;
    }

    // Label
    if(columnHeader.FieldName === 'WarningMessage') {
      label = 'W';
    }
    else {
      label = columnHeader.Label;
    }

    // Template functions
    // By FieldName
    if(columnHeader.FieldName === 'Action') {
      template = this.templateColumnAction;
    }
    else if(columnHeader.FieldName === 'Active') {
      template = this.templateColumnActive;
    }
    else if(columnHeader.FieldName === 'Admin') {
      template = this.templateColumnAdmin;
    }
    else if(columnHeader.FieldName === 'BudgetCode') {
      template = this.templateColumnBudgetCode;
    }
    else if(columnHeader.FieldName === 'Business_Line') {
      template = this.templateColumnBusinessLine;
    }
    else if(columnHeader.FieldName === 'Data_Freshness') {
      template = this.templateColumnDataFreshness;
    }
    else if(columnHeader.FieldName === 'Decisions') {
      template = this.templateColumnDecisions;
    }
    else if(columnHeader.FieldName === 'Email') {
      template = this.templateColumnEmail;
    }
    else if (columnHeader.FieldName === 'Next_Lag' || columnHeader.FieldName === 'Previous_Lag') {
        template = this.templateColumnLag;
    }
    else if(columnHeader.FieldName === 'Entity') {
      template = this.templateColumnEntity;
    }
    else if(columnHeader.FieldName === 'External') {
      template = this.templateColumnExternal;
    }
    else if(columnHeader.FieldName === 'HashTag') {
      template = this.templateColumnHashtags;
    }
    else if(columnHeader.FieldName === 'Highlighted') {
      template = this.templateColumnHighlighted;
    }
    else if(columnHeader.FieldName === 'Impact') {
      template = this.templateColumnImpact;
    }
    else if(columnHeader.FieldName === 'Index') {
      template = this.templateColumnIndex;
    }
    else if(columnHeader.FieldName === 'Item_Type') {
      template = this.templateColumnItemType;
    }
    else if(columnHeader.FieldName === 'Licence_Type') {
      template = this.templateColumnLicenceType;
    }
    else if(columnHeader.FieldName === 'Meeting') {
      template = this.templateColumnMeeting;
    }
    else if(columnHeader.FieldName === 'Meteo') {
      template = this.templateColumnMeteo;
    }
    else if(columnHeader.FieldName === 'Meteo_Freshness') {
      template = this.templateColumnMeteoFreshness;
    }
    else if(columnHeader.FieldName === 'Month_Burned') {
      template = this.templateColumnMonthBurned;
    }
    else if(columnHeader.FieldName === 'Name') {
      if(itemType === 'Entity' && blockType === 'Resources') {
        template = this.templateColumnResourceName;
      }
      else {
        template = this.templateColumnName;
      }
    }
    else if(columnHeader.FieldName === 'Priority') {
      template = this.templateColumnPriority;
    }
    else if(columnHeader.FieldName === 'Probability') {
      template = this.templateColumnProbability;
    }
    else if(columnHeader.FieldName === 'Progress') {
      template = this.templateColumnProgress;
    }
    else if(columnHeader.FieldName === 'Project') {
      template = this.templateColumnProject;
    }
    else if(columnHeader.FieldName === 'RowType') {
      template = this.templateColumnRowType;
    }
    else if(columnHeader.FieldName === 'Severity') {
      template = this.templateColumnSeverity;
    }
    else if(columnHeader.FieldName === 'Sprint') {
      template = this.templateColumnSprint;
    }
    else if(columnHeader.FieldName === 'Status') {
      template = this.templateColumnStatus;
    }
    else if(columnHeader.FieldName === 'Timeline') {
      template = this.templateColumnTimeline;
    }
    else if(columnHeader.FieldName === 'Trend') {
      template = this.templateColumnTrend;
    }
    else if(columnHeader.FieldName === 'Validated') {
      template = this.templateColumnValidated;
    }
    else if(columnHeader.FieldName === 'WarningMessage') {
      template = this.templateColumnWarning;
    }
    else if(columnHeader.FieldName === 'Workpackage') {
      template = this.templateColumnWorkpackage;
    }
    else if(columnHeader.FieldName === 'Year') {
      template = this.templateColumnYear;
    }
    // By Type
    else if(columnHeader.FieldType === 'Axe') {
      template = this.templateTypeAxe;
    }
    else if(columnHeader.FieldType === 'AxisTable') {
      template = this.templateTypeAxisTable;
    }
    else if(columnHeader.FieldType === 'Boolean') {
      template = this.templateTypeBoolean;
    }
    else if(columnHeader.FieldType === 'Date') {
      template = this.templateTypeDate;
    }
    else if(columnHeader.FieldType === 'Double') {
      template = this.templateTypeDouble;
    }
    else if(columnHeader.FieldType === 'Files') {
      template = this.templateTypeFiles;
    }
    else if(columnHeader.FieldType === 'HTML') {
      template = this.templateTypeHTML;
    }
    else if(columnHeader.FieldType === 'Link') {
      template = this.templateTypeLink;
    }
    else if(columnHeader.FieldType === 'Location') {
      template = this.templateTypeLocation;
    }
    else if(columnHeader.FieldType === 'Percentage') {
      template = this.templateTypePercentage;
    }
    else if(columnHeader.FieldType === 'Progress') {
      template = this.templateTypeProgress;
    }
    else if(columnHeader.FieldType === 'Rating') {
      template = this.templateTypeRating;
    }
    else if(columnHeader.FieldType === 'Resource') {
      template = this.templateTypeResource;
    }
    else if(columnHeader.FieldType === 'ResourceTable') {
      template = this.templateTypeResourceTable;
    }
    else {
      template = this.templateTypeText;
    }

    // Align
      if (columnHeader.FieldType === 'Date' || columnHeader.FieldName === 'Active' || columnHeader.FieldName === 'Cost_Following' || columnHeader.FieldName === 'Static_Data' || columnHeader.FieldName === 'Users_Config' || columnHeader.FieldName === 'Administrator' || columnHeader.FieldName === 'Reporting' || columnHeader.FieldName === 'Previous_Lag' || columnHeader.FieldName === 'Next_Lag') {
      textAlign = 'center';
    }
    else if(columnHeader.FieldType === 'Double' && (columnHeader.FieldName !== 'Meteo' && columnHeader.FieldName !== 'Trend')) {
      textAlign = 'right';
    }

    // Primary Key
    if(columnHeader.FieldName === 'Item_ID') {
      primaryKey = true;
    }
    else {
      primaryKey = false;
    }

    // Width
    if(columnsWidth.find(column => column.Name === columnHeader.FieldName)) {
      width = columnsWidth.find(column => column.Name === columnHeader.FieldName).Width;
    }
    else {
      if(columnHeader.FieldName === 'Item_ID') {
        width = 100;
      }
      else if(columnHeader.FieldName === 'Item_Type') {
        width = 120;
      }
      else if(columnHeader.FieldName === 'Name') {
        width = 300;
      }
      else if(columnHeader.FieldName === 'Status') {
        width = 120;
      }
      else if(columnHeader.FieldName === 'Priority') {
        width = 90;
      }
      else if(columnHeader.FieldName === 'Probability') {
        width = 90;
      }
      else if(columnHeader.FieldName === 'Impact') {
        width = 90;
      }
      else if(columnHeader.FieldName === 'Meteo') {
        width = 70;
      }
      else if(columnHeader.FieldName === 'Trend') {
        width = 80;
      }
      else if(columnHeader.FieldName === 'Progress') {
        width = 150;
      }
      else if(columnHeader.FieldName === 'Workload') {
        width = 120;
      }
      else if(columnHeader.FieldName === 'Burned_Workload') {
        width = 120;
      }
      else if(columnHeader.FieldName === 'StartDate') {
        width = 110;
      }
      else if(columnHeader.FieldName === 'EndDate') {
        width = 110;
      }
      else if(columnHeader.FieldName === 'Timeline') {
        width = 180;
      }
      else if(columnHeader.FieldName === 'Resource') {
        width = 160;
      }
      else if(columnHeader.FieldName === 'Task_Type') {
        width = 150;
      }
      else if(columnHeader.FieldName === 'WarningMessage') {
        width = 65;
      }
      else if(columnHeader.FieldName === 'Description') {
        width = 300;
      }
      else if(columnHeader.FieldName === 'Comment') {
        width = 300;
      }
      else if(columnHeader.FieldName === 'Corrective_Action') {
        width = 300;
      }
      else if(columnHeader.FieldName === 'Decision_Type') {
        width = 150;
      }
      else if(columnHeader.FieldName === 'Meeting_Type') {
        width = 150;
      }
      else if(columnHeader.FieldName === 'Resource_Type') {
        width = 150;
      }
      else if(columnHeader.FieldName === 'Owner') {
        width = 140;
      }
      else if(columnHeader.FieldName === 'Project') {
        width = 220;
      }
      else if(columnHeader.FieldName === 'Message') {
        width = 300;
      }
      else if(columnHeader.FieldName === 'Time') {
        width = 120;
      }
      else if(columnHeader.FieldName === 'Active') {
        width = 80;
      }
      else if(columnHeader.FieldName === 'Email' ) {
        width = 200;
      }
      else if(columnHeader.FieldName === 'Cost_Following' || columnHeader.FieldName === 'Reporting') {
        width = 100;
      }
      else if(columnHeader.FieldName === 'Static_Data' || columnHeader.FieldName === 'Users_Config' || columnHeader.FieldName === 'Administrator') {
        width = 120;
      }
      else if(columnHeader.FieldName === 'Firm') {
        width = 120;
      }
      else if(columnHeader.FieldName === 'Login') {
        width = 180;
      }
      else if(columnHeader.FieldName === 'Licence_EndDate') {
        width = 150;
      }
      else if(columnHeader.FieldName === 'First_Name' || columnHeader.FieldName === 'Last_Name') {
        width = 120;
      }
      else if(columnHeader.FieldName === 'Licence_Type') {
        width = 120;
      }
      else if(columnHeader.FieldName === 'Highlighted' || columnHeader.FieldName === 'External' || columnHeader.FieldName === 'Validated') {
        width = 100;
      }
      else if(columnHeader.FieldName === 'Data_Freshness') {
        width = 120;
      }
      else if(columnHeader.FieldName === 'Meteo_Freshness') {
        width = 110;
      }
      else if(columnHeader.FieldName === 'Import_Row') {
        width = 60;
      }
      else if(columnHeader.FieldName === 'Import_Event' || columnHeader.FieldName === 'Import_Status') {
        width = 100;
      }
    }

    // Define Column object for Grid
    var colObj = {
      allowEditing: false,
      field: field,
      // field: columnHeader.FieldName,
      format: format,
      headerText: label,
      headerTextAlign: 'left',
      isPrimaryKey: primaryKey,
      lockColumn: false,
      showInColumnChooser: false,
      textAlign: textAlign,
      type: type,
      template: template,
      visible: true,
      width: width
    };

    return colObj;
  }

  dataStructure(rows) {
    let dataSource = [];

    if(rows.length > 0) {
      dataSource = rows.map(row => {
        return row.Cells.reduce((acc, item) => {
          // Format Dates
          if(item.FieldType === 'Date' && item.Value) {
            acc[item.ColumnName] = new Date(item.Value);
          }
          // Format Numbers
          else if(item.FieldType === 'Double' && item.Value) {
            acc[item.ColumnName] = parseFloat(item.Value);
          }
          // Format Meteo/Trend
          else if((item.ColumnName === 'Meteo' || item.ColumnName === 'Trend') && item.Value) {
            acc[item.ColumnName] = JSON.parse(item.Value);
          }
          // Format Objects
          else if(item.FieldType === 'Object' && item.Value) {
            acc[item.ColumnName] = JSON.parse(item.Value);
          }
          // Format Object Tables (Decisions/HashTags)
          else if(item.FieldType === 'ObjectTable' && item.Value) {
            acc[item.ColumnName] = JSON.parse(item.Value);
          }
          // Add the Column Name/Values to the reduced Table
          // Exclude Parent_ID for Table
          else if(item.ColumnName !== 'Parent_ID') {
            acc[item.ColumnName] = item.Value;
          }

          return acc;
        }, {});
      });
    }

    return dataSource;
  }

  dataBound(args) {
    if(this.grid) {
      // Autofit Columns width
      // this.grid.autoFitColumns();

      // TO UPDATE: Fix: Force Column Generation after Data update
      // if(this.grid.columns.length === 1) {
      //   this.created();
      // }
    }
  }

  // Row Formatting design
  rowDataBound(args) {
    // Define styles for Objects rows
    if(getObject('Item_Type', args.data) === 'Business_Line') {
      args.rowHeight = 40;
      // args.rowHeight = 60;
      // args.row.style.fontSize = '15px';
      // args.row.style.fontWeight = 'bold';
    }
    else if(getObject('Item_Type', args.data) === 'Project') {
      args.rowHeight = 40;
      // args.rowHeight = 50;
    }
    else {
      args.rowHeight = 30;
    }
    // else if(getObject('Item_Type', args.data) === 'Workpackage') {
    //   args.rowHeight = 40;
    // }
    // else if(getObject('Item_Type', args.data) === 'Action') {
    //   args.row.style.fontWeight = '500';
    // }
    // else if(getObject('Item_Type', args.data) === 'Task') {
    //   args.rowHeight = 30;
    //   args.row.style.fontWeight = '100';
    // }
  }

  getColumnHeaders() {
    return this.state.columns;
  }

  getColumnType(columnName) {
    const columns = this.state.columns;

    if(columns.find(column => column.FieldName === columnName)) {
      return columns.find(column => column.FieldName === columnName).FieldType;
    }
    else {
      return null;
    }
  }

  getColumnUnit(columnName) {
    let columns = this.state.columns;

    if(columns.find(column => column.FieldName === columnName)) {
      return columns.find(column => column.FieldName === columnName).Unit;
    }
    else {
      return null;
    }
  }

  getColumnConditionalFormattings(columnName) {
    const columns = this.state.columns;

    if(columns.find(column => column.FieldName === columnName)) {
      return columns.find(column => column.FieldName === columnName).ConditionalFormattings;
    }
    else {
      return [];
    }
  }

  getCellConditionalFormatting(conditionalFormattings, value) {
    if(conditionalFormattings) {
      for(let i = 0; i < conditionalFormattings.length; i++) {
        if(this.isConditionalFormattingRespected(conditionalFormattings[i], value)) {
          return conditionalFormattings[i];
        }
      }
    }
    else {
      return null;
    }
  }

  isConditionalFormattingRespected(conditionalFormatting, value) {
    if(conditionalFormatting) {
      switch(conditionalFormatting.Conditions) {
        case 'equal':
            if(conditionalFormatting.Value1 == value) {
              return true;
            }
            else {
              return false;
            }
        case 'notequal':
            if(conditionalFormatting.Value1 != value) {
              return true;
            }
            else {
              return false;
            }
        case 'greaterthan':
            if(conditionalFormatting.Value1 < value) {
              return true;
            }
            else {
              return false;
            }
        case 'greaterthanorequal':
            if(conditionalFormatting.Value1 <= value) {
              return true;
            }
            else {
              return false;
            }
        case 'lessthan':
            if(conditionalFormatting.Value1 > value) {
              return true;
            }
            else {
              return false;
            }
        case 'lessthanorequal':
            if(conditionalFormatting.Value1 >= value) {
              return true;
            }
            else {
              return false;
            }
        case 'between':
            if(conditionalFormatting.Value1 <= value && conditionalFormatting.Value2 >= value) {
              return true;
            }
            else {
              return false;
            }
        default:
            return false;
      }
    }
    else {
      return false;
    }
  }

  // Formatting Date to English format for Database
  formatDateEn(date) {
    let formattedDate;

    if(date) {
      // let dateFr = new Date(date).toLocaleString().split(' ')[0];
      let dateFr = new Date(date).toLocaleString("en-GB").split(/,| /)[0];

      const split = dateFr.split('/');
      const day = split[0];
      const month = split[1];
      const year = split[2];

      formattedDate = month + "/" + day + "/" + year;
    }

    return formattedDate;
  }

  // Formatting Date to French format for Database
  formatDateFr(date) {
    let formattedDate;

    if(date) {
      // let dateFr = new Date(date).toLocaleString().split(' ')[0];
      let dateFr = new Date(date).toLocaleString("en-GB").split(/,| /)[0];

      const split = dateFr.split('/');
      const day = split[0];
      const month = split[1];
      const year = split[2];

      formattedDate = day + "/" + month + "/" + year;
    }

    return formattedDate;
  }

  // Formatting Date to Korean format
  formatDateKr(date) {
    let formattedDate;

    if(date) {
      // let dateFr = new Date(date).toLocaleString().split(' ')[0];
      let dateFr = new Date(date).toLocaleString("en-GB").split(/,| /)[0];

      const split = dateFr.split('/');
      const day = split[0];
      const month = split[1];
      const year = split[2];

      formattedDate = year + "-" + month + "-" + day;
    }

    return formattedDate;
  }

  actionBegin(args) {
    // console.log(args);
  }

  actionComplete(args) {
    // Sort
    // if(args.requestType === 'sorting') {
    //   const currentView = this.state.currentView;

    //   const columnName = args.columnName;
    //   const direction = args.direction;
    //   const column = columnName + ' ' + direction;

    //   // Update Current View Sort
    //   if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Sort')) {
    //     currentView.Parameters.find(param => param.Name === 'Sort').Value = column;
    //   }
    //   // Create Current View Sort
    //   else {
    //     currentView.Parameters.push({ Name: 'Sort', Value: column, ViewFilterId: -1, ViewId: currentView.ViewId });
    //   }

    //   // Call the event from the Parent component through the props with view value
    //   this.props.onViewChange(currentView);
    // }
  }

  resizeStop(args) {
    // const currentView = this.state.currentView;
    // let columnsWidth = [];

    // // Loop through the Grid columns to build CurrentView Columns Width
    // for(let i=0; i < this.grid.columns.length; i++) {
    //   columnsWidth.push({ Name: this.grid.columns[i].field, Width: this.grid.columns[i].width });
    // }

    // // Update Current View Columns Width
    // if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'ColumnsWidth')) {
    //   currentView.Parameters.find(param => param.Name === 'ColumnsWidth').Value = JSON.stringify(columnsWidth);
    // }
    // // Create Current View Columns Width
    // else {
    //   currentView.Parameters.push({ Name: 'ColumnsWidth', Value: JSON.stringify(columnsWidth), ViewFilterId: -1, ViewId: currentView.ViewId });
    // }

    // // Call the event from the Parent component through the props with view value
    // this.props.onViewChange(currentView);
  }

  refreshColumns(currentView, columnHeaders) {
    let columns = [], columnsWidth = [], filters = [], sort = [];

    // Get Current View Columns
    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Columns')) {
      columns = (currentView.Parameters.find(param => param.Name === 'Columns').Value).split(',');
    }
    // Get Current View Columns Width
    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'ColumnsWidth')) {
      columnsWidth = JSON.parse(currentView.Parameters.find(param => param.Name === 'ColumnsWidth').Value);
    }

    // Get Columns Headers
    // const columnHeaders = this.getColumnHeaders();

    // Auto Generated Columns
    if(this.grid) {
      // Clean Grid columns
      this.grid.columns = [];

      // Clean Grid Filter & Sort
      this.grid.clearFiltering();
      this.grid.clearSorting();

      // Loop through the columns to build and add them to the Grid
      for(let i=0; i < columns.length; i++) {
        let columnHeader, colObj;

        if(columnHeaders.find(columnHeader => columnHeader.FieldName === columns[i])) {
          // Get Column corresponding Column Header
          columnHeader = columnHeaders.find(columnHeader => columnHeader.FieldName === columns[i]);

          // Create Column object
          colObj = this.createColumn(columnHeader, columnsWidth);

          this.grid.columns.push(colObj);
        }
      }

      // Add Column Item_ID
      if(!columns.includes('Item_ID') && columnHeaders.find(columnHeader => columnHeader.FieldName === 'Item_ID')) {
        let columnHeader = columnHeaders.find(columnHeader => columnHeader.FieldName === 'Item_ID');

        // Define Column object for Grid
        var colObj = {
          lockColumn: false,
          field: columnHeader.FieldName,
          headerText: columnHeader.Label,
          visible: false,
          showInColumnChooser: false,
          allowEditing: false,
          isPrimaryKey: true
        };

        this.grid.columns.push(colObj);
      }
      // Add Column Item_Type
      else if(!columns.includes('Item_Type') && columnHeaders.find(columnHeader => columnHeader.FieldName === 'Item_Type')) {
        let columnHeader = columnHeaders.find(columnHeader => columnHeader.FieldName === 'Item_Type');

        // Define Column object for Grid
        var colObj = {
          lockColumn: false,
          field: columnHeader.FieldName,
          headerText: columnHeader.Label,
          visible: false,
          showInColumnChooser: false,
          allowEditing: false,
          isPrimaryKey: false
        };

        this.grid.columns.push(colObj);
      }

      // Update Tree Column Index
      this.grid.treeColumnIndex = null;
      // this.grid.treeColumnIndex = this.getTreeColumnIndex(currentView);

      // Refresh Grid Columns
      this.grid.refreshColumns();

      // Apply Filters
      // if(currentView.Filters && currentView.Filters.length > 0) {
      //   // Get Current View Sort
      //   filters = currentView.Filters;

      //   filters.forEach(filter => {
      //     // If Filter Field is included in Columns list
      //     if(columns.includes(filter.Field)) {
      //       let suffix = "";

      //       // Add Suffix .Label to Object Fields {"Id": id, "Label": label} for Grid Component
      //       if(this.getColumnType(columnHeaders, filter.Field) === 'Axe' || this.getColumnType(columnHeaders, filter.Field) === 'Resource' || filter.Field === 'Meteo' || filter.Field === 'Trend' || filter.Field === 'Business_Line' || filter.FieldName === 'Project' || filter.Field === 'Workpackage' || filter.Field === 'Action' || filter.Field === 'Task' || filter.Field === 'Entity') {
      //         suffix = ".Label";
      //       }

      //       this.grid.filterByColumn(filter.Field + suffix, filter.Operator, filter.Items.split(','));
      //     }
      //   });
      // }
      // Apply Sort
      // if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Sort')) {
      //   // Get Current View Sort
      //   sort = currentView.Parameters.find(param => param.Name === 'Sort').Value;

      //   // If Sort Field is included in Columns list
      //   if(columns.includes(sort.split(' ')[0])) {
      //     let suffix = "";

      //     // Add Suffix .Label to Object Fields {"Id": id, "Label": label} for Grid Component
      //     if(this.getColumnType(columnHeaders, sort.split(' ')[0]) === 'Axe' || this.getColumnType(columnHeaders, sort.split(' ')[0]) === 'Resource' || sort.split(' ')[0] === 'Meteo' || sort.split(' ')[0] === 'Trend' || sort.split(' ')[0] === 'Business_Line' || sort.split(' ')[0] === 'Project' || sort.split(' ')[0] === 'Workpackage' || sort.split(' ')[0] === 'Action' || sort.split(' ')[0] === 'Task' || sort.split(' ')[0] === 'Entity') {
      //       suffix = ".Label";
      //     }

      //     this.grid.sortByColumn(sort.split(' ')[0] + suffix, sort.split(' ')[1]);
      //   }
      // }
    }
  }

  // Format Cells value for Export
  excelQueryCellInfo(args) {
    // Dates
    if(args.value && this.getColumnType(args.column.field) === 'Date') {
      args.value = new Date(args.value).toLocaleDateString();
    }
    // Percentage
    else if(this.getColumnType(args.column.field) === 'Percentage') {
      if(args.value) {
        args.value = args.value.toFixed(0) + "%";
      }
      else {
        args.value = "0%";
      }
    }
    // Objects
    else if(args.value && ((this.getColumnType(args.column.field) === 'Axe' && args.column.field !== 'HashTag') || this.getColumnType(args.column.field) === 'Resource' || this.getColumnType(args.column.field) === 'Location' || args.column.field === 'Meteo' || args.column.field === 'Trend' || args.column.field === 'Business_Line' || args.column.field === 'Project' || args.column.field === 'Workpackage' || args.column.field === 'Action' || args.column.field === 'Task' || args.column.field === 'Entity')) {
      args.value = args.value.Label;
    }
    // Table Objects
    else if(args.value && (this.getColumnType(args.column.field) === 'AxisTable' || this.getColumnType(args.column.field) === 'ResourceTable' || this.getColumnType(args.column.field) === 'Files' || args.column.field === 'HashTag' || args.column.field === 'Rights')) {
      let string = "";

      args.value.forEach((value, index) => {
        if(index < args.value.length - 1) {
          string = string.concat(value.Label, ", ");
        }
        else {
          string = string.concat(value.Label);
        }
      });

      args.value = string;
    }
  }

  exportXLSX() {
    const itemTitle = this.state.itemTitle;
    const blockType = this.state.blockType;
    const fileName = "Export " + blockType + " " + itemTitle + ".xlsx";

    if(this.grid) {
      // Define Export File Name
      let excelExportProperties = { fileName: fileName };
      
      this.grid.excelExport(excelExportProperties);
    }
  }

  searchItem(search) {
    if(this.grid) {
      this.grid.search(search);
    }
  }

  // ----- ----- Format Functions ----- -----
  // ----- ----- Columns
  templateColumnAction(props) {
    const action = props.Action;

    if(!props.column) {
      return null;
    }

    // Get Column Name
    const columnName = props.column.field;

    if(action) {
      if(action.Label === '- NA -') {
        return <div className="minitableAxe brd-dashed very-light-grey">{action.Label}</div>;
      }
      else if(action.Label) {
        return <div className="minitableAxe bordered blue">
          <a target="_self" href={`/Card/${columnName}/${action.Id}/Home`}><span className="iconAction verysmallIcons mr5"></span></a>
          <span className="">{action.Label}</span>
        </div>;
      }
      else {
        return null;
      }
    }
    else {
      return null;
    }
  }

  templateColumnActive(props) {
    const itemId = props.Item_ID;
    const itemType = props.Item_Type;

    if(!props.column) {
      return null;
    }

    // Get Column Field and Value
    const field = props.column.field;
    const value = props[field];

    const allowEditing = props.column.allowEditing;
    const id = itemId + " " + itemType + " " + field + " " + allowEditing;
    
    if(value == 'True') {
      return <div className="booleanMiniTable">
        <div id={id} className="boolean iconBooleanTrue iconActiveTrue iconsTable"></div>
      </div>;
    }
    else if(value == 'False') {
      return <div className="booleanMiniTable">
        <div id={id} className="boolean iconBooleanFalse iconActiveFalse iconsTable"></div>
      </div>;
    }
    else {
      return null;
    }
  }

  templateColumnLag(props) {
    let backColor, foreColor, borderColor;

    const columnName = props.column.field.replace(".Label", "");
    const value = props[columnName];

    foreColor = '#FFFFFF';

    if(value > 0) {
      backColor = "#00C77A";
      return <div className="axe bordered" style={{ background: backColor, color: foreColor, borderColor: backColor }}>{" + " + value+ " " }</div>;
    }
    else if(value < 0) {
      backColor = "#E21313";
      return <div className="axe bordered" style={{ background: backColor, color: foreColor, borderColor: backColor }}>{" - " + (-value) + " "}</div>;
    }
    else if(value === 0) {
      backColor = "#FFFFFF";
      foreColor = "#9D9D9F";
      return <div className="axe bordered" style={{ background: backColor, color: foreColor, borderColor: foreColor }}>{" " + value + " "}</div>;
    }
    else {
      return null;
    }
  }

  templateColumnAdmin(props) {
    const itemId = props.Item_ID;
    const itemType = props.Item_Type;

    if(!props.column) {
      return null;
    }

    // Get Column Field and Value
    const field = props.column.field;
    const value = props[field];

    const allowEditing = props.column.allowEditing;
    const id = itemId + " " + itemType + " " + field + " " + allowEditing;

    if(value == 'True') {
      return <div className="booleanMiniTable">
        <div id={id} className="boolean iconBooleanTrue iconShieldGreen iconsTable"></div>
      </div>;
    }
    else if(value == 'False') {
      return <div className="booleanMiniTable">
        <div id={id} className="boolean iconBooleanFalse iconShieldGrey iconsTable"></div>
      </div>;
    }
    else {
      return null;
    }
  }

  templateColumnBudgetCode(props) {
    const budgetCode = props.BudgetCode;

    if(!props.column) {
      return null;
    }

    // Get Column Name
    const columnName = props.column.field;

    if(budgetCode) {
      if(budgetCode.Label === '- NA -') {
        return <div className="minitableAxe brd-dashed very-light-grey">{budgetCode.Label}</div>;
      }
      else if(budgetCode.Label) {
        return <div className="minitableAxe bordered blue">
          <a target="_self" href={`/Card/${columnName}/${budgetCode.Id}/Home`}><span className="iconBudgetCode verysmallIcons mr5"></span></a>
          <span className="">{budgetCode.Label}</span>
        </div>;
      }
      else {
        return null;
      }
    }
    else {
      return null;
    }
  }

  templateColumnBusinessLine(props) {
    const businessLine = props.Business_Line;

    if(!props.column) {
      return null;
    }

    // Get Column Name
    const columnName = props.column.field;

    if(businessLine) {
      if(businessLine.Label === '- NA -') {
        return <div className="minitableAxe brd-dashed very-light-grey">{businessLine.Label}</div>;
      }
      else if(businessLine.Label) {
        return <div className="minitableAxe bordered blue">
          <a target="_self" href={`/Card/${columnName}/${businessLine.Id}/Home`}><span className="iconBusinessLine verysmallIcons mr5"></span></a>
          <span className="">{businessLine.Label}</span>
        </div>;
      }
      else {
        return null;
      }
    }
    else {
      return null;
    }
  }

  templateColumnDataFreshness(props) {
    const dataFreshness = props.Data_Freshness;

    if(dataFreshness === "1") {
      return <div className="iconFullCircleGreen iconsMiniblock"></div>;
    } 
    else if(dataFreshness === "2") {
      return <div className="iconFullCircleOrange iconsMiniblock"></div>;
    } 
    else if(dataFreshness === "3") {
      return <div className="iconFullCircleRed iconsMiniblock"></div>;
    }
    else if(dataFreshness === "4") {
      return <div className="iconCircleGrey iconsMiniblock"></div>;
    }
    else {
      return null;
    }
  }

  templateColumnDecisions(props) {
    const decisions = props.Decisions;

    if(decisions) {
      return <div className="inline-flex">
        {decisions.map((decision, index) => {
          return <div key={index} className={"minitableAxe bordered blue" + (index < decisions.length - 1 ? " mr10" : "")}>
            <span className="iconDecisions verysmallIcons mr5"></span>
            <span className="">{decision.Label}</span>
          </div>
        })}
      </div>;
    }
    else {
      return null;
    }
  }

  templateColumnEmail(props) {
    const email = props.Email;

    if(email) {
      return <a href={"mailto:" + email} className="emailTable">{email}</a>;
      // return <div onClick={(e) => {window.location = "mailto:" + email; e.preventDefault();}} className="emailTable">{email}</div>
    }
    else {
      return null;
    }
  }

  templateColumnEntity(props) {
    const entity = props.Entity;

    if(!props.column) {
      return null;
    }

    // Get Column Name
    const columnName = props.column.field;

    if(entity) {
      if(entity.Label === '- NA -' || entity.Label === '- Not assigned -') {
        return <div className="minitableAxe brd-dashed very-light-grey">{entity.Label}</div>;
      }
      else if(entity.Label) {
        return <div className="minitableAxe bordered blue">
          <a target="_self" href={`/Card/${columnName}/${entity.Id}/Home`}><span className="iconEntity verysmallIcons mr5"></span></a>
          <span className="">{entity.Label}</span>
        </div>;
      }
      else {
        return null;
      }
    }
    else {
      return null;
    }
  }

  templateColumnExternal(props) {
    const itemId = props.Item_ID;
    const itemType = props.Item_Type;

    if(!props.column) {
      return null;
    }

    // Get Column Field and Value
    const field = props.column.field;
    const value = props[field];

    const allowEditing = props.column.allowEditing;
    const id = itemId + " " + itemType + " " + field + " " + allowEditing;

    if(value == 'True') {
      return <div className="booleanMiniTable">
        <div id={id} className="boolean iconBooleanTrue iconExternal iconsRectangleMiniTable align-items-center"></div>
      </div>;
    }
    else if(value == 'False') {
      return <div className="booleanMiniTable">
        <div id={id} className="boolean iconBooleanFalse iconInternal iconsRectangleMiniTable align-items-center"></div>
      </div>;
    }
    else {
      return null;
    }
  }

  templateColumnHashtags(props) {
    const hashTags = props.HashTag;

    if(hashTags) {
      return <div className="displayblock">
        {hashTags.map((hashTag, index) => {
          let backColor, foreColor, borderColor;

          // Back, Fore & Border Color
          if(hashTag) {
            if(!hashTag.BackColor) {
              backColor = '#FFFFFF';
            }
            else {
              backColor = hashTag.BackColor;
            }
            if(!hashTag.ForeColor) {
              foreColor = '#0088C7';
              borderColor = '#0088C7';
            }
            else {
              if((hashTag.ForeColor === '#FFFFFF' || hashTag.ForeColor === '#ffffff')) {
                borderColor = hashTag.BackColor;
              }
              else {
                borderColor = hashTag.ForeColor;
              }

              foreColor = hashTag.ForeColor;
            }
          }

          return <div key={index} className={"minitableAxe bordered" + (index < hashTags.length - 1 ? " mr10" : "")} style={{ background: backColor, color: foreColor, borderColor: borderColor }}>{hashTag.Label}</div>
        })}
      </div>;
    }
    else {
      return null;
    }
  }

  templateColumnHighlighted(props) {
    const itemId = props.Item_ID;
    const itemType = props.Item_Type;

    if(!props.column) {
      return null;
    }

    // Get Column Field and Value
    const field = props.column.field;
    const value = props[field];

    const allowEditing = props.column.allowEditing;
    const id = itemId + " " + itemType + " " + field + " " + allowEditing;

    if(value == 'True') {
      return <div className="booleanMiniTable">
        <div id={id} className="boolean iconBooleanTrue iconHighlighted iconsTable"></div>
      </div>;
    }
    else if(value == 'False') {
      return <div className="booleanMiniTable">
        <div id={id} className="boolean iconBooleanFalse iconNotHighlighted iconsTable"></div>
      </div>;
    }
    else {
      return null;
    }
  }

  templateColumnImpact(props) {
    const impact = props.Impact;

    // Impact (0: Very Low, 1: Low, 2: Medium, 3: High, 4: Critical)
    if(impact) {
      switch(impact.Id) {
        case 0:
            return <li className="fs18 green"><span className="minitablePriority black">{impact.Label}</span></li>;
        case 1:
            return <li className="fs18 green"><span className="minitablePriority black">{impact.Label}</span></li>;
        case 2:
            return <li className="fs18 orange-light"><span className="minitablePriority black">{impact.Label}</span></li>;
        case 3:
            return <li className="fs18 orange"><span className="minitablePriority black">{impact.Label}</span></li>;
        case 4:
            return <li className="fs18 black"><span className="minitablePriority red">{impact.Label}</span></li>;
        default:
            return null;
      }
    }
    else {
      return null;
    }
  }

  templateColumnIndex(props) {
    const impact = props.Impact;
    const probability = props.Probability;
    const index = props.Index;

    // Impact (0: Very Low, 1: Low, 2: Medium, 3: High, 4: Critical)
    // Probability (0: Very Low, 1: Low, 2: Medium, 3: High, 4: Critical)
    if(impact && probability) {
      switch(impact.Id) {
        case 0:
          switch(probability.Id) {
            case 0:
                return <div className="brd-radius mat-index bg-verylow-verylow white">{index}</div>;
            case 1:
                return <div className="brd-radius mat-index bg-verylow-low white">{index}</div>;
            case 2:
                return <div className="brd-radius mat-index bg-verylow-medium white">{index}</div>;
            case 3:
                return <div className="brd-radius mat-index bg-verylow-high white">{index}</div>;
            case 4:
                return <div className="brd-radius mat-index bg-verylow-critical white">{index}</div>;
            default:
                return null;
          }
        case 1:
          switch(probability.Id) {
            case 0:
                return <div className="brd-radius mat-index bg-low-verylow white">{index}</div>;
            case 1:
                return <div className="brd-radius mat-index bg-low-low white">{index}</div>;
            case 2:
                return <div className="brd-radius mat-index bg-low-medium white">{index}</div>;
            case 3:
                return <div className="brd-radius mat-index bg-low-high white">{index}</div>;
            case 4:
                return <div className="brd-radius mat-index bg-low-critical white">{index}</div>;
            default:
                return null;
          }
        case 2:
          switch(probability.Id) {
            case 0:
                return <div className="brd-radius mat-index bg-medium-verylow white">{index}</div>;
            case 1:
                return <div className="brd-radius mat-index bg-medium-low white">{index}</div>;
            case 2:
                return <div className="brd-radius mat-index bg-medium-medium white">{index}</div>;
            case 3:
                return <div className="brd-radius mat-index bg-medium-high white">{index}</div>;
            case 4:
                return <div className="brd-radius mat-index bg-medium-critical white">{index}</div>;
            default:
                return null;
          }
        case 3:
          switch(probability.Id) {
            case 0:
                return <div className="brd-radius mat-index bg-high-verylow white">{index}</div>;
            case 1:
                return <div className="brd-radius mat-index bg-high-low white">{index}</div>;
            case 2:
                return <div className="brd-radius mat-index bg-high-medium white">{index}</div>;
            case 3:
                return <div className="brd-radius mat-index bg-high-high white">{index}</div>;
            case 4:
                return <div className="brd-radius mat-index bg-high-critical white">{index}</div>;
            default:
                return null;
          }
        case 4:
          switch(probability.Id) {
            case 0:
                return <div className="brd-radius mat-index bg-critical-verylow white">{index}</div>;
            case 1:
                return <div className="brd-radius mat-index bg-critical-low white">{index}</div>;
            case 2:
                return <div className="brd-radius mat-index bg-critical-medium white">{index}</div>;
            case 3:
                return <div className="brd-radius mat-index bg-critical-high white">{index}</div>;
            case 4:
                return <div className="brd-radius mat-index bg-critical-critical white">{index}</div>;
            default:
                return null;
          }
        default:
            return null;
      }
    }
    else {
      return null;
    }
  }

  templateColumnItemType(props) {
    const itemType = props.Item_Type;

    switch(itemType) {
      case 'Business_Line':
          return <div className=""><span className="treeIcon iconBusinessLineWhite verysmallIcons" alt="Business Line"></span>{itemType}</div>;
      case 'Project':
          return <div className=""><span className="treeIcon iconProjectWhite verysmallIcons" alt="Project"></span>{itemType}</div>;
      case 'Workpackage':
          return <div className=""><span className="treeIcon iconWorkpackageWhite verysmallIcons" alt="Workpackage"></span>{itemType}</div>;
      case 'Action':
          return <div className=""><span className="treeIcon iconActionWhite verysmallIcons" alt="Action"></span>{itemType}</div>;
      case 'Task':
          return <div className=""><span className="treeIcon iconTaskWhite verysmallIcons" alt="Task"></span>{itemType}</div>;
      default:
          return <div className="">{itemType}</div>;
    }
  }

  templateColumnLicenceType(props) {
    const licenceType = props.Licence_Type;
    
    switch(licenceType) {
      case 'Full':
          return <div className="minitableAxe brd-radius bg-green white">{licenceType}</div>;
      case 'Standard':
          return <div className="minitableAxe bordered green">{licenceType}</div>;
      case 'No Licence':
      case 'Duplicated license':
      case 'Invalid License':
          return <div className="minitableAxe bordered grey">{licenceType}</div>;
      default:
          return null;
    }
  }

  templateColumnMeetingIndex(props) {
    const itemId = props.Item_ID.substring(1);

    if(itemId) {
      return <a target="_self" href={`/Card/Meeting/${itemId}/Home`}><div className="tableIconBlue"><span className="iconMeetingLight verysmallIcons"></span></div></a>;
    }
    else {
      return null;
    }
  }

  templateColumnMeeting(props) {
    const meeting = props.Meeting;

    if(meeting) {
      if(meeting.Label === '- NA -') {
        return <div className="minitableAxe brd-dashed very-light-grey">{meeting.Label}</div>;
      }
      else if(meeting.Label) {
        const itemId = meeting.Label.substring(1, (meeting.Label.indexOf('|')-1));
        const name = meeting.Label.substring(meeting.Label.indexOf('|')+1);
  
        return <a target="_self" href={`/Card/Meeting/${itemId}/Home`}>
          <div className="minitableAxe bordered blue"><span className="iconMeeting verysmallIcons"></span>{name}</div>
        </a>;
      }
      else {
        return null;
      }
    }
    else {
      return null;
    }
  }

  templateColumnMeteo(props) {
    const meteo = props.Meteo;

    // Meteo (0: None, 1: Sun ☀, 2: Cloud ⛅, 3: Rain 🌧, 4: Thunder 🌩, 5: Moon ☾)
    if(meteo) {
      switch(meteo.Id) {
        case 1:
            return <div className=""><img className="iconsTable" src={IconSun} alt="Sun"/></div>;
        case 2:
            return <div className=""><img className="iconsTable" src={IconCloud} alt="Cloud"/></div>;
        case 3:
            return <div className=""><img className="iconsTable" src={IconRain} alt="Rain"/></div>;
        case 4:
            return <div className=""><img className="iconsTable" src={IconThunder} alt="Thunder"/></div>;
        case 5:
            return <div className=""><img className="iconsTable" src={IconMoon} alt="Moon"/></div>;
        default:
            return null;
      }
    }
    else {
      return null;
    }
  }

  templateColumnMeteoFreshness(props) {
    const meteoFreshness = props.Meteo_Freshness;

    if(meteoFreshness === "1") {
      return <div className="iconFullCircleGreen iconsMiniblock"></div>;
    } 
    else if(meteoFreshness === "2") {
      return <div className="iconFullCircleOrange iconsMiniblock"></div>;
    } 
    else if(meteoFreshness === "3") {
      return <div className="iconFullCircleRed iconsMiniblock"></div>;
    }
    else if(meteoFreshness === "4") {
      return <div className="iconCircleGrey iconsMiniblock"></div>;
    }
    else {
      return null;
    }
  }

  templateColumnMonthBurned(props) {
    const monthBurned = props.Month_Burned;

    if(monthBurned == 0) {
      return <div className="monthBurned brd-dashed-timetracking blue bold">{monthBurned}</div>;
    }
    else {
      return <div className="monthBurned brd-timetracking blue bold">{monthBurned}</div>;
    }
  }

  templateColumnName(props) {
    const blockType = this.state.blockType;
    const itemId = props.Item_ID;
    const itemType = props.Item_Type;
    const name = props.Name;

    switch(itemType) {
      case 'AgileBoard':
          return (<div className="bold"><a target="_self" href={`/Card/${itemType}/${itemId.substring(1)}/Home`}><span title={itemType} className="treeIconGrey iconAgileBoard verysmallIcons" alt="AgileBoard"></span></a>{name}</div>);
      case 'Sprint':
          return (<div className="bold"><a target="_self" href={`/Card/${itemType}/${itemId.substring(1)}/Home`}><span title={itemType} className="treeIconGrey iconSprint verysmallIcons" alt="Sprint"></span></a>{name}</div>);
      case 'Business_Line':
          return (<div className="bold"><a target="_self" href={`/Card/${itemType}/${itemId.substring(1)}/Home`}><span title={itemType} className="treeIcon iconBusinessLineWhite verysmallIcons" alt="Business Line"></span></a>{name}</div>);
      case 'Project':
          return (<div className=""><a target="_self" href={`/Card/${itemType}/${itemId.substring(1)}/Home`}><span title={itemType} className="treeIcon iconProjectWhite verysmallIcons" alt="Project"></span></a>{name}</div>);
      case 'Workpackage':
          return (<div className=""><a target="_self" href={`/Card/${itemType}/${itemId.substring(1)}/Home`}><span title={itemType} className="treeIcon iconWorkpackageWhite verysmallIcons" alt="Workpackage"></span></a>{name}</div>);
      case 'Action':
          return (<div className=""><a target="_self" href={`/Card/${itemType}/${itemId.substring(1)}/Home`}><span title={itemType} className="treeIcon iconActionWhite verysmallIcons" alt="Action"></span></a>{name}</div>);
      case 'Task':
          return (<div className=""><a target="_self" href={`/Card/${itemType}/${itemId.substring(1)}/Details`}><span title={itemType} className="treeIconGrey iconTaskGrey verysmallIcons" alt="Task"></span></a>{name}</div>);
      default:
          return <span className="">{name}</span>;
    }
  }

  templateColumnPriority(props) {
    const priority = props.Priority;

    // Priority (0: Very Low, 1: Low, 2: Medium, 3: High, 4: Critical)
    if(priority) {
      switch(priority.Id) {
        case 0:
            return <li className="fs18 green"><span className="minitablePriority black">{priority.Label}</span></li>;
        case 1:
            return <li className="fs18 green"><span className="minitablePriority black">{priority.Label}</span></li>;
        case 2:
            return <li className="fs18 orange-light"><span className="minitablePriority black">{priority.Label}</span></li>;
        case 3:
            return <li className="fs18 orange"><span className="minitablePriority black">{priority.Label}</span></li>;
        case 4:
            return <li className="fs18 black"><span className="minitablePriority red">{priority.Label}</span></li>;
        default:
            return null;
      }
    }
    else {
      return null;
    }
  }

  templateColumnProbability(props) {
    const probability = props.Probability;

    // Probability (0: Very Low, 1: Low, 2: Medium, 3: High, 4: Critical)
    if(probability) {
      switch(probability.Id) {
        case 0:
            return <li className="fs18 green"><span className="minitablePriority black">{probability.Label}</span></li>;
        case 1:
            return <li className="fs18 green"><span className="minitablePriority black">{probability.Label}</span></li>;
        case 2:
            return <li className="fs18 orange-light"><span className="minitablePriority black">{probability.Label}</span></li>;
        case 3:
            return <li className="fs18 orange"><span className="minitablePriority black">{probability.Label}</span></li>;
        case 4:
            return <li className="fs18 black"><span className="minitablePriority red">{probability.Label}</span></li>;
        default:
            return null;
      }
    }
    else {
      return null;
    }
  }

  templateColumnProgress(props) {
    let progress;
    
    if(props.Progress) {
      progress = props.Progress;
      // progress = (props.Progress.replace(',', '.') * 100).toFixed(0);

      if(progress == 100) {
        return <div className="progressBlock">
          <span className="progressLabel">{`${progress} %`}</span>
          <span className="progressBar"><ProgressBar className="progressTable" variant="success" now={progress}></ProgressBar></span>
        </div>;
      }
      else if(progress < 100 && progress >= 0) {
        return <div className="progressBlock">
          <span className="progressLabel">{`${progress} %`}</span>
          <span className="progressBar"><ProgressBar className="progressTable" variant="primary" now={progress}></ProgressBar></span>
        </div>;
      }
      else if(progress < 0) {
        return <div className="progressBlock">
          <span className="progressLabel">{`${progress} %`}</span>
          <span className="progressBar"><ProgressBar className="progressTable" variant="danger" now={Math.abs(progress)}></ProgressBar></span>
        </div>;
      }
      else {
        return <div className="inline-flex align-items-center">
          <span className="width45">{`${progress} %`}</span>
          <span className="width80"><ProgressBar className="progressTable" variant="danger" now={'100'}></ProgressBar></span>
        </div>;
      }
    }
    else {
      return <div className="progressBlock">
        <span className="progressLabel">{`0 %`}</span>
        <span className="progressBar"><ProgressBar className="progressTable" variant="danger" now={'0'}></ProgressBar></span>
      </div>;
    }
  }

  templateColumnProject(props) {
    const project = props.Project;

    if(!props.column) {
      return null;
    }

    // Get Column Name
    const columnName = props.column.field;

    if(project) {
      if(project.Label === '- NA -') {
        return <div className="minitableAxe brd-dashed very-light-grey">{project.Label}</div>;
      }
      else if(project.Label) {
        return <div className="minitableAxe bordered blue">
          <a target="_self" href={`/Card/${columnName}/${project.Id}/Home`}><span className="iconProject verysmallIcons mr5"></span></a>
          <span className="">{project.Label}</span>
        </div>;
      }
      else {
        return null;
      }
    }
    else {
      return null;
    }
  }

  templateColumnResourceName(props) {
    const itemId = props.Item_ID;
    const itemType = props.Item_Type;
    const name = props.Name;

    if(name === '- Not assigned -') {
      let firstname = 'N';
      let lastname = 'A';

      return <div className="align-baseline">
        <span className="iconEmptyResourceTable icons aligncenter blue">{firstname + lastname}</span>
        <span className="ml10 grey">{name}</span>
      </div>;
    }
    else if(name === '- To be assigned -') {
      let firstname = 'T';
      let lastname = 'B';

      return <div className="align-baseline">
        <span className="iconEmptyResourceTable icons aligncenter blue">{firstname + lastname}</span>
        <span className="ml10 grey">{name}</span>
      </div>;
    }
    else if(name) {
      let firstname, lastname;
      let split = name.split(' ');

      if(split.length === 1) {
        firstname = name.split(' ')[0].substring(0,1);
        lastname = '';
      }
      else if(split.length === 2) {
        firstname = name.split(' ')[0].substring(0,1);
        lastname = name.split(' ')[1].substring(0,1);
      }
      else {
        firstname = name.split(' ')[0].substring(0,1);
        lastname = name.split(' ')[split.length-1].substring(0,1);
      }

      return <div className="align-baseline">
        <a target="_self" href={`/Card/${itemType}/${itemId.substring(1)}/Home`}><div className="iconResourceTable icons aligncenter"><span className="white">{firstname + lastname}</span></div></a>
        <span className="ml10 grey">{name}</span>
      </div>;
    }
    else {
      return null;
    }
  }

  templateColumnRowType(props) {
    const rowType = props.RowType;

    if(rowType === 'Holiday') {
      return <div className="turquoise bold"><span className="iconHolidays tableIcon verysmallIcons"></span>{rowType}</div>;
    }
    else if(rowType === 'Forecast') {
      return <div className="blue"><span className="iconHourglass tableIcon verysmallIcons"></span>{rowType}</div>;
    }
    else if(rowType === 'TimeTracking') {
      return <div className="blue bold"><span className="iconTimeTrackingBlue tableIcon verysmallIcons"></span>{rowType}</div>;
    }
    else {
      return <div className="">{rowType}</div>;
    }
  }

  templateColumnSeverity(props) {
    const impact = props.Impact;
    const probability = props.Probability;
    const severity = props.Severity;

    // Impact (0: Very Low, 1: Low, 2: Medium, 3: High, 4: Critical)
    // Probability (0: Very Low, 1: Low, 2: Medium, 3: High, 4: Critical)
    if(impact && probability) {
      switch(impact.Id) {
        case 0:
          switch(probability.Id) {
            case 0:
                return <div className="brd-radius mat-index bg-verylow-verylow white">{severity}</div>;
            case 1:
                return <div className="brd-radius mat-index bg-verylow-low white">{severity}</div>;
            case 2:
                return <div className="brd-radius mat-index bg-verylow-medium white">{severity}</div>;
            case 3:
                return <div className="brd-radius mat-index bg-verylow-high white">{severity}</div>;
            case 4:
                return <div className="brd-radius mat-index bg-verylow-critical white">{severity}</div>;
            default:
                return null;
          }
        case 1:
          switch(probability.Id) {
            case 0:
                return <div className="brd-radius mat-index bg-low-verylow white">{severity}</div>;
            case 1:
                return <div className="brd-radius mat-index bg-low-low white">{severity}</div>;
            case 2:
                return <div className="brd-radius mat-index bg-low-medium white">{severity}</div>;
            case 3:
                return <div className="brd-radius mat-index bg-low-high white">{severity}</div>;
            case 4:
                return <div className="brd-radius mat-index bg-low-critical white">{severity}</div>;
            default:
                return null;
          }
        case 2:
          switch(probability.Id) {
            case 0:
                return <div className="brd-radius mat-index bg-medium-verylow white">{severity}</div>;
            case 1:
                return <div className="brd-radius mat-index bg-medium-low white">{severity}</div>;
            case 2:
                return <div className="brd-radius mat-index bg-medium-medium white">{severity}</div>;
            case 3:
                return <div className="brd-radius mat-index bg-medium-high white">{severity}</div>;
            case 4:
                return <div className="brd-radius mat-index bg-medium-critical white">{severity}</div>;
            default:
                return null;
          }
        case 3:
          switch(probability.Id) {
            case 0:
                return <div className="brd-radius mat-index bg-high-verylow white">{severity}</div>;
            case 1:
                return <div className="brd-radius mat-index bg-high-low white">{severity}</div>;
            case 2:
                return <div className="brd-radius mat-index bg-high-medium white">{severity}</div>;
            case 3:
                return <div className="brd-radius mat-index bg-high-high white">{severity}</div>;
            case 4:
                return <div className="brd-radius mat-index bg-high-critical white">{severity}</div>;
            default:
                return null;
          }
        case 4:
          switch(probability.Id) {
            case 0:
                return <div className="brd-radius mat-index bg-critical-verylow white">{severity}</div>;
            case 1:
                return <div className="brd-radius mat-index bg-critical-low white">{severity}</div>;
            case 2:
                return <div className="brd-radius mat-index bg-critical-medium white">{severity}</div>;
            case 3:
                return <div className="brd-radius mat-index bg-critical-high white">{severity}</div>;
            case 4:
                return <div className="brd-radius mat-index bg-critical-critical white">{severity}</div>;
            default:
                return null;
          }
        default:
            return null;
      }
    }
    else {
      return null;
    }
  }

  templateColumnSprint(props) {
    const blockType = this.state.blockType;
    const sprint = props.Sprint;
    let backColor, foreColor, borderColor;
    
    if(!props.column) {
      return null;
    }

    // Get Column Name (Remove Suffix .Label to Object Fields)
    const columnName = props.column.field.replace(".Label", "");

    // Back, Fore & Border Color
    if(sprint) {
      if(!sprint.BackColor) {
        backColor = '#FFFFFF';
      }
      else {
        backColor = sprint.BackColor;
      }
      if(!sprint.ForeColor) {
        foreColor = '#0088C7';
        borderColor = '#0088C7';
      }
      else {
        if((sprint.ForeColor === '#FFFFFF' || sprint.ForeColor === '#ffffff')) {
          borderColor = sprint.BackColor;
        }
        else {
          borderColor = sprint.ForeColor;
        }

        foreColor = sprint.ForeColor;
      }
    }

    if(sprint) {
      if(sprint.Label === '- NA -') {
        return <div className="minitableAxe brd-dashed very-light-grey">{sprint.Label}</div>;
      }
      else if(sprint.Label) {
        return <div className="minitableAxe bordered" style={{ background: backColor, color: foreColor, borderColor: borderColor }}>
          {(backColor === '#FFFFFF' || backColor === '#ffffff') && <a target="_self" href={`/Card/${columnName}/${sprint.Id}/${blockType}`}><span className="iconSprint verysmallIcons mr5"></span></a>}
          {(backColor !== '#FFFFFF' && backColor !== '#ffffff') && <a target="_self" href={`/Card/${columnName}/${sprint.Id}/${blockType}`}><span className="iconSprintWhite verysmallIcons mr5"></span></a>}
          <span className="">{sprint.Label}</span>
        </div>;
      }
      else {
        return null;
      }
    }
  }

  templateColumnStatus(props) {
    const status = props.Status;

    // Status (1: To be Done, 2: In Progress, 3: On Hold, 4: Cancelled, 5: Completed, 6: Open, 7: Closed)
    // Status (8: Realised, 9: To be Scheduled, 10: Scheduled, 11: Taken, 12: To be Taken)
    if(status) {
      switch(status.Id) {
        case 1:
        case 9:
            return <div className="minitableAxe brd-radius bg-grey white">{status.Label}</div>;
        case 2:
        case 10:
            return <div className="minitableAxe brd-radius bg-blue white">{status.Label}</div>;
        case 3:
            return <div className="minitableAxe bordered orange">{status.Label}</div>;
        case 4:
            return <div className="minitableAxe bordered grey">{status.Label}</div>;
        case 5:
        case 7:
        case 11:
            return <div className="minitableAxe brd-radius bg-green white">{status.Label}</div>;
        case 6:
        case 12:
            return <div className="minitableAxe brd-radius bg-orange white">{status.Label}</div>;
        case 8:
            return <div className="minitableAxe brd-radius bg-red white">{status.Label}</div>;
        default:
            return null;
      }
    }
    else {
      return null;
    }
  }

  templateColumnTimeline(props) {
    let status = props.Status;
    let startDate = props.StartDate;
    let endDate = props.EndDate;

    // Create Timeline with different colors based on status, StartDate and EndDate
    if(status && startDate && endDate) {
      // Format Date to English format (it is Date object after modification in Table Datepicker)
      if(startDate instanceof Date) {
        startDate = startDate.toLocaleDateString("en-US", { year: 'numeric', month: '2-digit', day: '2-digit' });
      }
      if(endDate instanceof Date) {
        endDate = endDate.toLocaleDateString("en-US", { year: 'numeric', month: '2-digit', day: '2-digit' });
      }

      return <Timeline Display={'Dashboard'} Status={status} StartDate={startDate} EndDate={endDate}></Timeline>;
    }
    else {
      return <div className=""></div>;
    }
  }

  templateColumnTrend(props) {
    const trend = props.Trend;

    // Trend (0: None, 1: Down ⬊, 2: Right ⮕, 3: Up ⬈)
    if(trend) {
      switch(trend.Id) {
        case 1:
            return <span className="iconTrendDown iconsMiniblock"></span>;
        case 2:
            return <span className="iconTrendRight iconsMiniblock"></span>;
        case 3:
            return <span className="iconTrendUp iconsMiniblock"></span>;
        default:
            return null;
      }
    }
    else {
      return null;
    }
  }

  templateColumnValidated(props) {
    const itemId = props.Item_ID;
    const itemType = props.Item_Type;

    if(!props.column) {
      return null;
    }

    // Get Column Field and Value
    const field = props.column.field;
    const value = props[field];

    const allowEditing = props.column.allowEditing;
    const id = itemId + " " + itemType + " " + field + " " + allowEditing;

    if(value == 'True') {
      return <div className="booleanMiniTable">
        <div id={id} className="boolean iconBooleanTrue iconCheck iconsTable"></div>
      </div>;
    }
    else if(value == 'False') {
      return <div className="booleanMiniTable">
        <div id={id} className="boolean iconBooleanFalse iconCircleGrey iconsTable"></div>
      </div>;
    }
    else {
      return null;
    }
  }

  templateColumnWarning(props) {
    const warningMessage = props.WarningMessage;

    if(warningMessage) {
      return <OverlayTrigger key="right" placement="right" overlay={<Tooltip id="tooltip-right" className="tooltip-warning">{warningMessage}</Tooltip>}>
        <Badge className="badgeTable bg-white" pill><i className="iconWarningRed verysmallIcons"/></Badge>
      </OverlayTrigger>;
    }
    else {
      return null;
    }
  }

  templateColumnWorkpackage(props) {
    const workpackage = props.Workpackage;

    if(!props.column) {
      return null;
    }

    // Get Column Name
    const columnName = props.column.field;

    if(workpackage) {
      if(workpackage.Label === '- NA -') {
        return <div className="minitableAxe brd-dashed very-light-grey">{workpackage.Label}</div>;
      }
      else if(workpackage.Label) {
        return <div className="minitableAxe bordered blue">
          <a target="_self" href={`/Card/${columnName}/${workpackage.Id}/Home`}><span className="iconWorkpackage verysmallIcons mr5"></span></a>
          <span className="">{workpackage.Label}</span>
        </div>;
      }
      else {
        return null;
      }
    }
    else {
      return null;
    }
  }

  templateColumnYear(props) {
    if(!props.column) {
      return null;
    }

    // Get Column Name and Value
    const columnName = props.column.field;
    const value = props[columnName];

    return <div className="">{value}</div>;
  }

  // ----- ----- Type
  templateTypeAxe(props) {
    let backColor, foreColor, borderColor;

    if(!props.column) {
      return null;
    }

    // Get Column Field and Value
    const field = props.column.field.replace(".Label", "");
    const value = props[field];

    // Back, Fore & Border Color
    if(value) {
      if(!value.BackColor) {
        backColor = '#FFFFFF';
      }
      else {
        backColor = value.BackColor;
      }
      if(!value.ForeColor) {
        foreColor = '#0088C7';
        borderColor = '#0088C7';
      }
      else {
        if((value.ForeColor === '#FFFFFF' || value.ForeColor === '#ffffff')) {
          borderColor = value.BackColor;
        }
        else {
          borderColor = value.ForeColor;
        }

        foreColor = value.ForeColor;
      }
    }

    if(value) {
      if(value.Label === '- NA -') {
        return <div className="minitableAxe brd-dashed very-light-grey">{value.Label}</div>;
      }
      else if(value.Label) {
        return <div className="minitableAxe bordered" style={{ background: backColor, color: foreColor, borderColor: borderColor }}>{value.Label}</div>;
      }
      else {
        return null;
      }
    }
    else {
      return null;
    }
  }

  templateTypeAxisTable(props) {
    if(!props.column) {
      return null;
    }
    
    // Get Column Name (Remove Suffix .Label to Object Fields)
    const columnName = props.column.field.replace(".Label", "");
    const values = props[columnName];

    if(values) {
      return <div className="displayblock">
        {values.map((value, index) => {
          let backColor, foreColor, borderColor;

          // Back, Fore & Border Color
          if(value) {
            if(!value.BackColor) {
              backColor = '#FFFFFF';
            }
            else {
              backColor = value.BackColor;
            }
            if(!value.ForeColor) {
              foreColor = '#0088C7';
              borderColor = '#0088C7';
            }
            else {
              if((value.ForeColor === '#FFFFFF' || value.ForeColor === '#ffffff')) {
                borderColor = value.BackColor;
              }
              else {
                borderColor = value.ForeColor;
              }

              foreColor = value.ForeColor;
            }
          }

          return <div key={index} className={"minitableAxe bordered" + (index < values.length - 1 ? " mr10" : "")} style={{ background: backColor, color: foreColor, borderColor: borderColor }}>{value.Label}</div>
        })}
      </div>;
    }
    else {
      return null;
    }
  }

  templateTypeBoolean(props) {
    const itemId = props.Item_ID;
    const itemType = props.Item_Type;

    if(!props.column) {
      return null;
    }

    // Get Column Field and Value
    const field = props.column.field;
    const value = props[field];

    const allowEditing = props.column.allowEditing;
    const id = itemId + " " + itemType + " " + field + " " + allowEditing;

    if(value == 'True') {
      return <div className="booleanMiniTable">
        <div id={id} className="boolean iconBooleanTrue iconCheck iconsTable"></div>
      </div>;
    }
    else if(value == 'False') {
      return <div className="booleanMiniTable">
        <div id={id} className="boolean iconBooleanFalse iconCircleGrey iconsTable"></div>
      </div>;
    }
    else {
      return null;
    }
  }

  templateTypeDate(props) {
    // const language = this.state.language;
    const formatDate = this.state.formatDate;
    let date;

    if(!props.column) {
      return null;
    }

    // Get Column Field and Value
    const field = props.column.field;
    const value = props[field];

    if(value) {
      if(formatDate === 'MM/DD/YYYY') {
        date = new Date(value).toLocaleDateString("en-US", { year: 'numeric', month: '2-digit', day: '2-digit' });
      }
      else if(formatDate === 'DD/MM/YYYY') {
        date = new Date(value).toLocaleDateString("fr-FR");
      }
      else if(formatDate === 'YYYY-MM-DD') {
        date = this.formatDateKr(new Date(value));
      }
      // if(language === 'English') {
      //   date = new Date(value).toLocaleDateString("en-US", { year: 'numeric', month: '2-digit', day: '2-digit' });
      // }
      // else if(language === 'Français') {
      //   date = new Date(value).toLocaleDateString("fr-FR");
      // }

      // date = new Date(value).toLocaleDateString();
      // formattedDate = date.split(/,| /)[0];
    }

    return <div className="">{date}</div>;
  }

  templateTypeDouble(props) {
    if(!props.column) {
      return null;
    }
    
    // Get Column Name and Value
    const columnName = props.column.field;
    const value = props[columnName];

    const conditionalFormattings = this.getColumnConditionalFormattings(columnName);
    const conditionalFormatting = this.getCellConditionalFormatting(conditionalFormattings, value);
    const unit = this.getColumnUnit(columnName);

    if(conditionalFormatting) {
      if(value || value === 0) {
        if(unit) {
          return <div className="conditionalFormatting" style={{ background: conditionalFormatting.BackGroundColor, color: conditionalFormatting.Color, borderColor: conditionalFormatting.Color }}>{value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " " + unit}</div>;
        }
        else {
          return <div className="conditionalFormatting" style={{ background: conditionalFormatting.BackGroundColor, color: conditionalFormatting.Color, borderColor: conditionalFormatting.Color }}>{value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}</div>;
        }
      }
      else {
        return null;
      }
    }
    else {
      if(value || value === 0) {
        if(unit) {
          return <div className="">{value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " " + unit}</div>;
        }
        else {
          return <div className="">{value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}</div>;
        }
      }
      else {
        return null;
      }
    }
  }

  templateTypeFiles(props) {
    if(!props.column) {
      return null;
    }

    // Get Column Name (Remove Suffix .Label to Object Fields)
    const columnName = props.column.field.replace(".Label", "");
    const values = props[columnName];
    
    if(values) {
      return <div className="width100p">
        <div className="cellTable inline-flex">
          {values.map((value, index) => {
            if(value && value.Label) {
              let split = value.Label.split('.');
              let extension = split[split.length - 1];

              if(extension === 'docx') {
                return <div key={index} className={"inline-flex align-items-center" + (index < values.length - 1 ? " mr10" : "")}>
                  <span className="iconWord iconsTable mr10"></span>
                  <span className="">{value.Label}</span>
                </div>;
              }
              else if(extension === 'pdf') {
                return <div key={index} className={"inline-flex align-items-center" + (index < values.length - 1 ? " mr10" : "")}>
                  <span className="iconPDF iconsTable mr10"></span>
                  <span className="">{value.Label}</span>
                </div>;
              }
              else if(extension === 'pptx') {
                return <div key={index} className={"inline-flex align-items-center" + (index < values.length - 1 ? " mr10" : "")}>
                  <span className="iconPPT iconsTable mr10"></span>
                  <span className="">{value.Label}</span>
                </div>;
              }
              else if(extension === 'xlsx') {
                return <div key={index} className={"inline-flex align-items-center" + (index < values.length - 1 ? " mr10" : "")}>
                  <span className="iconExcel iconsTable mr10"></span>
                  <span className="">{value.Label}</span>
                </div>;
              }
              else {
                return <div key={index} className={"inline-flex align-items-center" + (index < values.length - 1 ? " mr10" : "")}>
                  <span className="iconFile iconsTable mr10"></span>
                  <span className="">{value.Label}</span>
                </div>;
              }
            }
            else {
              return null;
            }
          })}
        </div>
      </div>;
    }
    else {
      // return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}><div className="emptyCellTable"></div></OverlayTrigger>;
      return null;
    }
  }

  templateTypeHTML(props) {
    if(!props.column) {
      return null;
    }
    
    // Get Column Field and Value
    const field = props.column.field;
    const value = props[field];

    if(value) {
      return <div dangerouslySetInnerHTML={{ __html: value }}></div>;
    }
    else {
      return null;
    }
  }

  templateTypeLink(props) {
    if(!props.column) {
      return null;
    }

    // Get Column Field and Value
    const field = props.column.field;
    const value = props[field];

    const split = value.split('/');
    const shortName = split[split.length-1];

    if(value) {
      return <div title={value} className=""><a target="_self" className="" href={value}><span className="treeIcon iconLink iconsPath" alt="Link"></span>{shortName}</a></div>;
    }
    else {
      return null;
    }
  }

  templateTypeLocation(props) {
    if(!props.column) {
      return null;
    }

    // Get Column Name (Remove Suffix .Label to Object Fields)
    const columnName = props.column.field.replace(".Label", "");
    const value = props[columnName];

    if(value) {
      if(value.Label === '- NA -') {
        return <div className="minitableAxe brd-dashed very-light-grey">{value.Label}</div>;
      }
      else if(value.Label) {
        return <div className="minitableLocation">
          <span className="iconMap verysmallIcons"></span>
          <span className="">{value.Label}</span>
        </div>;
      }
      else {
        return null;
      }
    }
    else {
      return null;
    }
  }

  templateTypePercentage(props) {
    if(!props.column) {
      return null;
    }

    // Get Column Field and Value
    const field = props.column.field;
    const value = props[field];

    const percentage = value;
    // const percentage = (value * 100).toFixed(0);

    if(value) {
      if(percentage == 100) {
        return <div className="progressBlock">
          <span className="progressLabel">{`${percentage} %`}</span>
          <span className="progressBar"><ProgressBar className="progressTable" variant="success" now={percentage}></ProgressBar></span>
        </div>;
      }
      else if(percentage < 100 && percentage >= 0) {
        return <div className="progressBlock">
          <span className="progressLabel">{`${percentage} %`}</span>
          <span className="progressBar"><ProgressBar className="progressTable" variant="primary" now={percentage}></ProgressBar></span>
        </div>;
      }
      else if(percentage < 0) {
        return <div className="progressBlock">
          <span className="progressLabel">{`${percentage} %`}</span>
          <span className="progressBar"><ProgressBar className="progressTable" variant="danger" now={Math.abs(percentage)}></ProgressBar></span>
        </div>;
      }
      else {
        return <div className="progressBlock">
          <span className="progressLabel">{`${percentage} %`}</span>
          <span className="progressBar"><ProgressBar className="progressTable" variant="danger" now={'100'}></ProgressBar></span>
        </div>;
      }
    }
    else {
      return null;
    }
  }

  templateTypeProgress(props) {
    if(!props.column) {
      return null;
    }

    // Get Column Field and Value
    const field = props.column.field;
    const value = props[field];

    let progress;
    
    if(value) {
      progress = (value.replace(',', '.') * 100).toFixed(0);

      if(progress == 100) {
        return <div className="progressBlock">
          <span className="progressLabel">{`${progress} %`}</span>
          <span className="progressBar"><ProgressBar className="progressTable" variant="success" now={progress}></ProgressBar></span>
        </div>;
      }
      else if(progress < 100 && progress >= 0) {
        return <div className="progressBlock">
          <span className="progressLabel">{`${progress} %`}</span>
          <span className="progressBar"><ProgressBar className="progressTable" variant="primary" now={progress}></ProgressBar></span>
        </div>;
      }
      else if(progress < 0) {
        return <div className="progressBlock">
          <span className="progressLabel">{`${progress} %`}</span>
          <span className="progressBar"><ProgressBar className="progressTable" variant="danger" now={Math.abs(progress)}></ProgressBar></span>
        </div>;
      }
      else {
        return <div className="progressBlock">
          <span className="progressLabel">{`${progress} %`}</span>
          <span className="progressBar"><ProgressBar className="progressTable" variant="danger" now={'100'}></ProgressBar></span>
        </div>;
      }
    }
    else {
      return <div className="progressBlock">
        <span className="progressLabel">{`0 %`}</span>
        <span className="progressBar"><ProgressBar className="progressTable" variant="danger" now={'0'}></ProgressBar></span>
      </div>;
    }
  }

  templateTypeRating(props) {
    if(!props.column) {
      return null;
    }

    // Get Column Name and Value
    const columnName = props.column.field;
    const value = props[columnName];

    if(value == 5) {
      return <div className="ratingTable">
        <div className="rating iconRating iconsRating mr10"></div>
        <div className="rating iconRating iconsRating mr10"></div>
        <div className="rating iconRating iconsRating mr10"></div>
        <div className="rating iconRating iconsRating mr10"></div>
        <div className="rating iconRating iconsRating mr10"></div>
      </div>;
    }
    if(value == 4) {
      return <div className="ratingTable">
        <div className="rating iconRating iconsRating mr10"></div>
        <div className="rating iconRating iconsRating mr10"></div>
        <div className="rating iconRating iconsRating mr10"></div>
        <div className="rating iconRating iconsRating mr10"></div>
        <div className="rating iconRatingEmpty iconsRating mr10"></div>
      </div>;
    }
    if(value == 3) {
      return <div className="ratingTable">
        <div className="rating iconRating iconsRating mr10"></div>
        <div className="rating iconRating iconsRating mr10"></div>
        <div className="rating iconRating iconsRating mr10"></div>
        <div className="rating iconRatingEmpty iconsRating mr10"></div>
        <div className="rating iconRatingEmpty iconsRating mr10"></div>
      </div>;
    }
    if(value == 2) {
      return <div className="ratingTable">
        <div className="rating iconRating iconsRating mr10"></div>
        <div className="rating iconRating iconsRating mr10"></div>
        <div className="rating iconRatingEmpty iconsRating mr10"></div>
        <div className="rating iconRatingEmpty iconsRating mr10"></div>
        <div className="rating iconRatingEmpty iconsRating mr10"></div>
      </div>;
    }
    if(value == 1) {
      return <div className="ratingTable">
        <div className="rating iconRating iconsRating mr10"></div>
        <div className="rating iconRatingEmpty iconsRating mr10"></div>
        <div className="rating iconRatingEmpty iconsRating mr10"></div>
        <div className="rating iconRatingEmpty iconsRating mr10"></div>
        <div className="rating iconRatingEmpty iconsRating mr10"></div>
      </div>;
    }
    else {
      return <div className="ratingTable">
        <div className="rating iconRatingEmpty iconsRating mr10"></div>
        <div className="rating iconRatingEmpty iconsRating mr10"></div>
        <div className="rating iconRatingEmpty iconsRating mr10"></div>
        <div className="rating iconRatingEmpty iconsRating mr10"></div>
        <div className="rating iconRatingEmpty iconsRating mr10"></div>
      </div>;
    }
  }

  templateTypeResource(props) {
    if(!props.column) {
      return null;
    }

    // Get Column Field and Value
    const field = props.column.field.replace(".Label", "");
    const value = props[field];

    if(value) {
      if(value.Label === '- Not assigned -') {
        let firstname = 'N';
        let lastname = 'A';

        return <div className="align-baseline">
          <span className="iconEmptyResourceTable icons aligncenter blue">{firstname + lastname}</span>
          <span className="ml10 grey">{value.Label}</span>
        </div>;
      }
      else if(value.Label === '- To be assigned -') {
        let firstname = 'T';
        let lastname = 'B';

        return <div className="align-baseline">
          <span className="iconEmptyResourceTable icons aligncenter blue">{firstname + lastname}</span>
          <span className="ml10 grey">{value.Label}</span>
        </div>;
      }
      else if(value.Id && value.Label) {
        let firstname, lastname;
        let split = value.Label.split(' ');

        if(split.length === 1) {
          firstname = value.Label.split(' ')[0].substring(0,1);
          lastname = '';
        }
        else if(split.length === 2) {
          firstname = value.Label.split(' ')[0].substring(0,1);
          lastname = value.Label.split(' ')[1].substring(0,1);
        }
        else {
          firstname = value.Label.split(' ')[0].substring(0,1);
          lastname = value.Label.split(' ')[split.length-1].substring(0,1);
        }

        return <div className="align-baseline">
          <a target="_self" href={`/Card/Resource/${value.Id}/Home`}><span className="iconResourceTable icons aligncenter white">{firstname + lastname}</span></a>
          <span className="ml10 grey">{value.Label}</span>
        </div>;
      }
      else {
        return null;
      }
    }
    else {
      return null;
    }
  }

  templateTypeResourceTable(props) {
    if(!props.column) {
      return null;
    }

    // Get Column Name (Remove Suffix .Label to Object Fields)
    const columnName = props.column.field.replace(".Label", "");
    const values = props[columnName];
    
    if(values) {
      return <div className="width100p">
        <div className="cellTable inline-flex">
          {values.map((value, index) => {
            let firstname, lastname;
            const split = value.Label.split(' ');
      
            if(split.length === 1) {
              firstname = value.Label.split(' ')[0].substring(0,1);
              lastname = '';
            }
            else if(split.length === 2) {
              firstname = value.Label.split(' ')[0].substring(0,1);
              lastname = value.Label.split(' ')[1].substring(0,1);
            }
            else {
              firstname = value.Label.split(' ')[0].substring(0,1);
              lastname = value.Label.split(' ')[split.length-1].substring(0,1);
            }

            if(value.Id === 0) {
              return <div key={index} className="multiResource mr10">{value.Label}</div>;
            }
            else {
              return <div key={index} className="multiResource mr10">
                <span className="iconResourceTable icons aligncenter white">{firstname + lastname}</span>
                <span className="ml5 grey">{value.Label}</span>
              </div>;
            }
          })}
        </div>
      </div>;
    }
    else {
      return null;
    }
  }

  templateTypeText(props) {
    if(!props.column) {
      return null;
    }

    // Get Column Field and Value
    const field = props.column.field;
    const value = props[field];

    return <div className="">{value}</div>;
  }

  render() {
    let { language, itemId, itemType, blockType, editable, pagging, currentView, columns, rows, dataSource } = this.state;

    // Grid Lines
    this.gridLines = 'Default';

    // Column Menu
    this.columnMenuItems = [];
    // this.columnMenuItems = ['Filter'];

    // Context Menu
    this.contextMenuItems = [];

    // Edit Options
    this.editOptions = { allowAdding: false, allowDeleting: false, allowEditing: false, mode: 'Cell' };

    // Filter Settings
    this.filterSettings = { type: 'Excel', hierarchyMode: 'Parent' };

    return (
      <div className="control-pane height100p">
        <div className="control-section height100p">
          {/* TreeGrid Component */}
          {dataSource.length > 0 && 
            <TreeGridComponent dataSource={dataSource} idMapping='Item_ID' locale={Traduction.translate(language, 'locale')} height={'100%'} gridLines={this.gridLines} editSettings={this.editOptions} columnMenuItems={this.columnMenuItems} contextMenuItems={this.contextMenuItems} filterSettings={this.filterSettings} created={this.created} dataBound={this.dataBound} rowDataBound={this.rowDataBound} actionBegin={this.actionBegin} actionComplete={this.actionComplete} resizeStop={this.resizeStop} excelQueryCellInfo={this.excelQueryCellInfo} showColumnMenu={false} showColumnChooser={false} allowFiltering={false} allowPaging={pagging} pageSettings={{ pageSize: 30 }} allowExcelExport={true} allowReordering={false} allowResizing={true} allowSorting={false} allowTextWrap={true} ref={treegrid=>this.grid=treegrid}>
              <ColumnsDirective>
                <ColumnDirective isPrimaryKey={true} field='Item_ID' headerText={'Item_ID'} visible={false} autoFit={true} allowEditing={false} lockColumn={false} showInColumnChooser={false}></ColumnDirective>
              </ColumnsDirective>
              <Inject services={[ Toolbar, ContextMenu, ColumnMenu, ColumnChooser, Edit, Filter, Sort, Page, Reorder, Resize, ExcelExport ]}/>
            </TreeGridComponent>
          }
        </div>
      </div>
    )
  }
}

export default MiniTable;