import React, {Component} from 'react';
import { Button, Form } from 'react-bootstrap';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';

class PopoverEditMapping extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: null,
      staticData: null,
      itemId: null,
      itemType: null,
      mapping: null,
      currentValue: null,
      values: [],
      search: ''
    };

    this.searchItem = this.searchItem.bind(this);
    this.pressKey = this.pressKey.bind(this);
    this.updateValue = this.updateValue.bind(this);

    this.search = React.createRef();
  }

  componentDidMount() {
    const language = Authentication.getCookie('language');
    const staticData = Authentication.getCookie('staticData');
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const mapping = this.props.Mapping;
    const currentValue = this.props.CurrentValue;
    const values = this.props.Values;

    // Search Auto focus
    setTimeout(() => { this.search.current.focus() }, 1);

    this.setState({ language, staticData, itemId, itemType, mapping, currentValue, values });
  }

  componentDidUpdate(prevProps) {
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const mapping = this.props.Mapping;
    const currentValue = this.props.CurrentValue;
    const values = this.props.Values;

    // if(this.props.ItemId !== prevProps.ItemId || this.props.ItemType !== prevProps.ItemType) {
    //   this.setState({ itemId, itemType });
    // }
    // if(this.props.ColumnName !== prevProps.ColumnName || this.props.AxeValue !== prevProps.AxeValue || this.props.RestrictedValues !== prevProps.RestrictedValues) {
    //   this.setState({ columnName, axeValue, restrictedValues });
    // }
  }

  searchItem(event) {
    const search = event.target.value;

    this.setState({ search });
  }

  pressKey(event) {
    const { mapping, values } = this.state;
    const search = event.target.value;
    let newValue;

    if(event.key === 'Enter' && search !== '') {
      if(values.find(value => value.Label.toLowerCase().includes(search.toLowerCase()))) {
        mapping.Field = values.find(value => value.Label.toLowerCase().includes(search.toLowerCase()));
        newValue = values.find(value => value.Label.toLowerCase().includes(search.toLowerCase()));

        // Close Popover
        if(document.getElementById("popover-basic")) {
          document.getElementById("popover-basic").classList.remove("show");
        }

        // Call the event from the Parent component through the props
        this.props.onValueEdit(mapping, newValue);

        this.setState({ currentValue: newValue });
      }
    }
  }

  updateValue(mapping, value) {
    // Close Popover
    if(document.getElementById("popover-basic")) {
      document.getElementById("popover-basic").classList.remove("show");
    }
    
    // Call the event from the Parent component through the props
    this.props.onValueEdit(mapping, value);
  }

  render() {
    const { language, staticData, itemId, itemType, mapping, currentValue, values, search } = this.state;

    return (
      <div className="axeEditPopover">
        {/* Search */}
        <div className="axeEditSearch">
          <Form.Group className="searchBlock">
            <Form.Control type="text" id="searchFilters" name="search" value={search} placeholder={Traduction.translate(language, 'search')} autoFocus onChange={this.searchItem} onKeyPress={this.pressKey} ref={this.search}/>
          </Form.Group>
        </div>
        {/* Values */}
        <div className="axeEditValues scrollbar-y">
          {/* Current Value */}
          {currentValue && <div className="axeCurrentValue">
            <div className="axe bordered blue">{currentValue.Label}</div>
          </div>}
          {/* Border */}
          <div className="popoverBorder"></div>
          {/* Other Values */}
          {values.map((value, index) => {
            if(value.FieldId !== currentValue.FieldId) {
              if(search === "" || value.Label.toLowerCase().includes(search.toLowerCase())) {
                return <div key={index} className="axeEditValue" onClick={() => this.updateValue(mapping, value)}>
                  <div className="axe bordered blue">{value.Label}</div>
                </div>;
              }
            }
          })}
        </div>
      </div>
    );
  }
}

export default PopoverEditMapping;