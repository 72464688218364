import React, {Component} from 'react';
import { Badge, ProgressBar, Popover, OverlayTrigger, Tooltip, Form } from 'react-bootstrap';
import { L10n, getValue } from '@syncfusion/ej2-base';
import { DataManager, Query, Predicate } from '@syncfusion/ej2-data';
import { getObject } from '@syncfusion/ej2-grids';
import { TreeGridComponent, ColumnsDirective, ColumnDirective, ColumnMenu } from '@syncfusion/ej2-react-treegrid';
import { Inject, Edit, Toolbar, ColumnChooser, ContextMenu, Filter, Sort, Page, Reorder, Resize, ExcelExport } from '@syncfusion/ej2-react-treegrid';
import * as ej2FRlocale from './EJ2_LOCALE/ej2FRlocale.json';
import * as ej2ESlocale from './EJ2_LOCALE/ej2ESlocale.json';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';
import IconCloud from '../Images/IconCloud.png';
import IconMoon from '../Images/IconMoon.png';
import IconRain from '../Images/IconRain.png';
import IconSun from '../Images/IconSun.png';
import IconThunder from '../Images/IconThunder.png';
import IconFrance from '../Images/IconFrance.png';
import IconEnglish from '../Images/IconEnglish.png';
import IconSpain from '../Images/IconSpain.png';
import PopoverColumnActions from './PopoverColumnActions';
import PopoverEditAxe from './PopoverEditAxe';
import PopoverEditColor from './PopoverEditColor';
import PopoverEditObjectTable from './PopoverEditObjectTable';
import PopupConfirmation from './PopupConfirmation';
// import PopupEditDependencies from './PopupEditDependencies';
import PopupEditFiles from './PopupEditFiles';
import PopupEditRights from './PopupEditRights';
import Timeline from './Timeline';
import TableAudit from './TableAudit';
import TextEditor from './TextEditor';
import TextFormulaEditor from './TextFormulaEditor';

// Traductions
L10n.load({ fr: ej2FRlocale.fr, es: ej2ESlocale.es });

class Table extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authId: null,
      language: null,
      formatDate: null,
      itemId: null,
      itemType: null,
      itemTitle: null,
      blockType: null,
      editable: null,
      guestLicence: null,
      currentView: {},
      columns: [],
      rows: [],
      dataSource: [],
      modificationErrors: [],
      month: null,
      year: null,
      auditPopup: false,
      dependenciesPopup: false,
      filesPopup: false,
      rightsPopup: false,
      textEditorPopup: false,
      textFormulaEditorPopup: false,
      confirm: false,
      deleteId: null,
      rowItemId: null,
      rowItemType: null,
      rowItemTitle: null,
      rowItemColumn: null,
      rowItemLabel: null,
      rowItemValue: null,
      error: {}
    };

    this.tableClicked = false;

    // Table Structure
    this.created = this.created.bind(this);
    this.createColumn = this.createColumn.bind(this);
    this.dataStructure = this.dataStructure.bind(this);
    this.dataBound = this.dataBound.bind(this);
    this.rowDataBound = this.rowDataBound.bind(this);
    this.rowSelected = this.rowSelected.bind(this);
    this.contextMenuOpen = this.contextMenuOpen.bind(this);
    this.contextMenuClick = this.contextMenuClick.bind(this);
    this.getColumnHeaders = this.getColumnHeaders.bind(this);
    this.getColumnEditable = this.getColumnEditable.bind(this);
    this.getColumnEditionItemType = this.getColumnEditionItemType.bind(this);
    this.getColumnFilter = this.getColumnFilter.bind(this);
    this.getColumnLabel = this.getColumnLabel.bind(this);
    this.getColumnRestrictedValues = this.getColumnRestrictedValues.bind(this);
    this.getColumnSort = this.getColumnSort.bind(this);
    this.getColumnType = this.getColumnType.bind(this);
    this.getColumnUnit = this.getColumnUnit.bind(this);
    this.getColumnConditionalFormattings = this.getColumnConditionalFormattings.bind(this);
    this.getCellConditionalFormatting = this.getCellConditionalFormatting.bind(this);
    this.isConditionalFormattingRespected = this.isConditionalFormattingRespected.bind(this);
    this.getCurrentViewLevel = this.getCurrentViewLevel.bind(this);
    this.getDeleteLabel = this.getDeleteLabel.bind(this);
    this.getDropdownList = this.getDropdownList.bind(this);
    this.getTreeColumnIndex = this.getTreeColumnIndex.bind(this);
    this.formatDateEn = this.formatDateEn.bind(this);
    this.formatDateFr = this.formatDateFr.bind(this);
    this.formatDateKr = this.formatDateKr.bind(this);

    // Actions
    this.actionBegin = this.actionBegin.bind(this);
    this.actionComplete = this.actionComplete.bind(this);
    this.cellEdit = this.cellEdit.bind(this);
    this.refreshColumns = this.refreshColumns.bind(this);
    this.filterColumns = this.filterColumns.bind(this);
    this.sortColumn = this.sortColumn.bind(this);
    this.sortColumns = this.sortColumns.bind(this);
    this.autofitColumn = this.autofitColumn.bind(this);
    this.autofitAllColumns = this.autofitAllColumns.bind(this);
    this.resizeStop = this.resizeStop.bind(this);
    this.addView = this.addView.bind(this);
    this.editAxe = this.editAxe.bind(this);
    this.editColor = this.editColor.bind(this);
    this.editObjectTable = this.editObjectTable.bind(this);
    this.onBooleanColumnClick = this.onBooleanColumnClick.bind(this);
    this.updateDatasource = this.updateDatasource.bind(this);
    this.cancelModification = this.cancelModification.bind(this);
    this.addRowsInDatasource = this.addRowsInDatasource.bind(this);
    this.buildDatasourceRow = this.buildDatasourceRow.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
    this.cancelDelete = this.cancelDelete.bind(this);
    this.deleteRowFromComponent = this.deleteRowFromComponent.bind(this);
    this.deleteRowInDatasource = this.deleteRowInDatasource.bind(this);
    this.deleteAllRowsInDatasource = this.deleteAllRowsInDatasource.bind(this);
    this.restoreRowInDatasource = this.restoreRowInDatasource.bind(this);
    this.excelQueryCellInfo = this.excelQueryCellInfo.bind(this);
    this.exportCSV = this.exportCSV.bind(this);
    this.exportXLSX = this.exportXLSX.bind(this);
    this.searchItem = this.searchItem.bind(this);

    this.popupTextEditor = this.popupTextEditor.bind(this);
    this.popupTextFormulaEditor = this.popupTextFormulaEditor.bind(this);
    this.closeAuditPopup = this.closeAuditPopup.bind(this);
    // this.closeDependenciesPopup = this.closeDependenciesPopup.bind(this);
    this.closeFilesPopup = this.closeFilesPopup.bind(this);
    this.closeRightsPopup = this.closeRightsPopup.bind(this);
    this.closeTextEditorPopup = this.closeTextEditorPopup.bind(this);
    this.closeTextFormulaEditorPopup = this.closeTextFormulaEditorPopup.bind(this);

    // Template Functions
    this.templateHeader = this.templateHeader.bind(this);

    this.templateColumnAction = this.templateColumnAction.bind(this);
    this.templateColumnActive = this.templateColumnActive.bind(this);
    this.templateColumnAxisName = this.templateColumnAxisName.bind(this);
    this.templateColumnAdmin = this.templateColumnAdmin.bind(this);
    this.templateColumnBackColor = this.templateColumnBackColor.bind(this);
    this.templateColumnBudgetCode = this.templateColumnBudgetCode.bind(this);
    this.templateColumnBusinessLine = this.templateColumnBusinessLine.bind(this);
    this.templateColumnCardLevel = this.templateColumnCardLevel.bind(this);
    this.templateColumnDataFreshness = this.templateColumnDataFreshness.bind(this);
    this.templateColumnDecisions = this.templateColumnDecisions.bind(this);
    this.templateColumnDependency = this.templateColumnDependency.bind(this);
    this.templateColumnDependencyFromItemLabel = this.templateColumnDependencyFromItemLabel.bind(this);
    this.templateColumnDependencyToItemLabel = this.templateColumnDependencyToItemLabel.bind(this);
    this.templateColumnDependencyType = this.templateColumnDependencyType.bind(this);
    this.templateColumnEmail = this.templateColumnEmail.bind(this);
    this.templateColumnEntity = this.templateColumnEntity.bind(this);
    this.templateColumnExternal = this.templateColumnExternal.bind(this);
    this.templateColumnFieldType = this.templateColumnFieldType.bind(this);
    this.templateColumnForeColor = this.templateColumnForeColor.bind(this);
    this.templateColumnHashtags = this.templateColumnHashtags.bind(this);
    this.templateColumnHighlighted = this.templateColumnHighlighted.bind(this);
    this.templateColumnImpact = this.templateColumnImpact.bind(this);
    this.templateColumnIndex = this.templateColumnIndex.bind(this);
    this.templateColumnItemType = this.templateColumnItemType.bind(this);
    this.templateColumnLag = this.templateColumnLag.bind(this);
    this.templateColumnLanguage = this.templateColumnLanguage.bind(this);
    this.templateColumnLevels = this.templateColumnLevels.bind(this);
    this.templateColumnLicenceType = this.templateColumnLicenceType.bind(this);
    this.templateColumnMeetingIndex = this.templateColumnMeetingIndex.bind(this);
    this.templateColumnMeeting = this.templateColumnMeeting.bind(this);
    this.templateColumnMeteo = this.templateColumnMeteo.bind(this);
    this.templateColumnMeteoFreshness = this.templateColumnMeteoFreshness.bind(this);
    this.templateColumnMonthBurned = this.templateColumnMonthBurned.bind(this);
    this.templateColumnName = this.templateColumnName.bind(this);
    this.templateColumnParents = this.templateColumnParents.bind(this);
    this.templateColumnPriority = this.templateColumnPriority.bind(this);
    this.templateColumnProbability = this.templateColumnProbability.bind(this);
    this.templateColumnProgress = this.templateColumnProgress.bind(this);
    this.templateColumnProject = this.templateColumnProject.bind(this);
    this.templateColumnRights = this.templateColumnRights.bind(this);
    this.templateColumnRemainingDays = this.templateColumnRemainingDays.bind(this);
    this.templateColumnResourceName = this.templateColumnResourceName.bind(this);
    this.templateColumnResources = this.templateColumnResources.bind(this);
    this.templateColumnRowType = this.templateColumnRowType.bind(this);
    this.templateColumnSeverity = this.templateColumnSeverity.bind(this);
    this.templateColumnSprint = this.templateColumnSprint.bind(this);
    this.templateColumnStatus = this.templateColumnStatus.bind(this);
    this.templateColumnTask = this.templateColumnTask.bind(this);
    this.templateColumnTimeline = this.templateColumnTimeline.bind(this);
    this.templateColumnTrend = this.templateColumnTrend.bind(this);
    this.templateColumnValidated = this.templateColumnValidated.bind(this);
    this.templateColumnWarning = this.templateColumnWarning.bind(this);
    this.templateColumnWorkpackage = this.templateColumnWorkpackage.bind(this);
    this.templateColumnYear = this.templateColumnYear.bind(this);

    this.templateTypeAxe = this.templateTypeAxe.bind(this);
    this.templateTypeAxisTable = this.templateTypeAxisTable.bind(this);
    this.templateTypeBoolean = this.templateTypeBoolean.bind(this);
    this.templateTypeDate = this.templateTypeDate.bind(this);
    this.templateTypeDouble = this.templateTypeDouble.bind(this);
    this.templateTypeFiles = this.templateTypeFiles.bind(this);
    this.templateTypeFormula = this.templateTypeFormula.bind(this);
    this.templateTypeHTML = this.templateTypeHTML.bind(this);
    this.templateTypeLink = this.templateTypeLink.bind(this);
    this.templateTypeLocation = this.templateTypeLocation.bind(this);
    this.templateTypePercentage = this.templateTypePercentage.bind(this);
    this.templateTypeProgress = this.templateTypeProgress.bind(this);
    this.templateTypeRating = this.templateTypeRating.bind(this);
    this.templateTypeResource = this.templateTypeResource.bind(this);
    this.templateTypeResourceTable = this.templateTypeResourceTable.bind(this);
    this.templateTypeText = this.templateTypeText.bind(this);
  }
  
  componentDidMount() {
    const authId = Authentication.getCookie('authId');
    const language = Authentication.getCookie('language');
    const formatDate = Authentication.getCookie('formatDate');
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const itemTitle = this.props.Title;
    const blockType = this.props.BlockType;
    const editable = this.props.Editable;
    const guestLicence = this.props.GuestLicence;
    const currentView = this.props.CurrentView;
    const columns = this.props.Columns;
    const rows = this.props.Rows;

    // Build Table Datasource
    let dataSource = this.dataStructure(rows);

    this.setState({ authId, language, formatDate, itemId, itemType, itemTitle, blockType, editable, guestLicence, currentView, columns, rows, dataSource });
    
    if(this.props.Month || this.props.Year) {
      this.setState({ month: this.props.Month, year: this.props.Year });
    }
  }

  componentDidUpdate(prevProps) {
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const itemTitle = this.props.Title;
    const blockType = this.props.BlockType;
    const editable = this.props.Editable;
    const guestLicence = this.props.GuestLicence;
    const currentView = this.props.CurrentView;
    const columns = this.props.Columns;
    const rows = this.props.Rows;

    if(this.props.ItemId !== prevProps.ItemId || this.props.ItemType !== prevProps.ItemType || this.props.BlockType !== prevProps.BlockType) {
      this.setState({ itemId, itemType, itemTitle, blockType });
    }
    if(this.props.Editable !== prevProps.Editable) {
      this.setState({ editable });
    }
    if(this.props.GuestLicence !== prevProps.GuestLicence) {
      this.setState({ guestLicence });
    }
    if(JSON.stringify(this.props.CurrentView) !== JSON.stringify(prevProps.CurrentView)) {
      this.setState({ currentView });
    }
    if(this.props.Columns !== prevProps.Columns || JSON.stringify(this.props.Rows) !== JSON.stringify(prevProps.Rows)) {
      // Build Table Datasource
      let dataSource = this.dataStructure(rows);

      // if(this.grid) {
      //   this.grid.refreshColumns();
      // }

      this.setState({ columns, rows, dataSource });
    }
    if(this.props.Month !== prevProps.Month || this.props.Year !== prevProps.Year) {
      this.setState({ month: this.props.Month, year: this.props.Year });
    }
  }

  created() {
    const blockType = this.state.blockType;
    const currentView = this.state.currentView;
    let columns = [], columnsWidth = [], filters = [], sort = [];

    // Get Current View Columns
    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Columns')) {
      columns = (currentView.Parameters.find(param => param.Name === 'Columns').Value).split(',');
    }
    // Get Current View Columns Width
    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'ColumnsWidth')) {
      columnsWidth = JSON.parse(currentView.Parameters.find(param => param.Name === 'ColumnsWidth').Value);
    }

    // Get Columns Headers
    const columnHeaders = this.getColumnHeaders();

    // Auto Generated Columns
    if(this.grid) {
      // Clean Grid columns (exclude column Index)
      this.grid.columns = [];

      // if(blockType === 'Risks') {
      //   this.grid.columns.splice(1);
      // }
      // else {
      //   this.grid.columns = [];
      // }

      // Clean Grid Filter & Sort
      this.grid.clearFiltering();
      this.grid.clearSorting();

      // Add Column Index in Block Risks
      if(blockType === 'Risks') {
        // Define Column object for Grid
        var colObj = {
          lockColumn: true,
          field: 'Index',
          headerTemplate: this.templateHeader,
          headerText: 'Index',
          visible: true,
          showInColumnChooser: false,
          allowEditing: false,
          template: this.templateColumnIndex,
          isPrimaryKey: false,
          width: '80'
        };

        this.grid.columns.push(colObj);
      }
      // Add Column Index in Block Meetings
      else if(blockType === 'Meetings') {
        // Define Column object for Grid
        var colObj = {
          lockColumn: true,
          field: 'Index',
          headerTemplate: this.templateHeader,
          headerText: 'M',
          visible: true,
          showInColumnChooser: false,
          allowEditing: false,
          textAlign: 'center',
          headerTextAlign: 'left',
          template: this.templateColumnMeetingIndex,
          isPrimaryKey: false,
          width: '70'
        };

        this.grid.columns.push(colObj);
      }

      // Loop through the columns to build and add them to the Grid
      for(let i=0; i < columns.length; i++) {
        let columnHeader, colObj;

        if(columnHeaders.find(columnHeader => columnHeader.FieldName === columns[i])) {
          // Get Column corresponding Column Header
          columnHeader = columnHeaders.find(columnHeader => columnHeader.FieldName === columns[i]);

          // Create Column object
          colObj = this.createColumn(columnHeader, columnsWidth);

          // Add the Column in the Grid (exclude column Index)
          if(columnHeader.FieldName !== 'Index') {
            this.grid.columns.push(colObj);
          }

          // Height (subtract 290px for Header & Filters)
          // if(this.grid.height > (window.innerHeight - 290)) {
          //   this.grid.height = (window.innerHeight - 290);
          // }
        }
      }

      // Add Column Item_ID
      if(!columns.includes('Item_ID') && columnHeaders.find(columnHeader => columnHeader.FieldName === 'Item_ID')) {
        let columnHeader = columnHeaders.find(columnHeader => columnHeader.FieldName === 'Item_ID');

        // Define Column object for Grid
        var colObj = {
          lockColumn: false,
          field: columnHeader.FieldName,
          headerTemplate: this.templateHeader,
          headerText: columnHeader.Label,
          visible: false,
          showInColumnChooser: false,
          allowEditing: false,
          isPrimaryKey: true
        };

        this.grid.columns.push(colObj);
      }
      // Add Column Item_Type
      else if(!columns.includes('Item_Type') && columnHeaders.find(columnHeader => columnHeader.FieldName === 'Item_Type')) {
        let columnHeader = columnHeaders.find(columnHeader => columnHeader.FieldName === 'Item_Type');

        // Define Column object for Grid
        var colObj = {
          lockColumn: false,
          field: columnHeader.FieldName,
          headerTemplate: this.templateHeader,
          headerText: columnHeader.Label,
          visible: false,
          showInColumnChooser: false,
          allowEditing: false,
          isPrimaryKey: false
        };

        this.grid.columns.push(colObj);
      }

      // Update Tree Column Index
      this.grid.treeColumnIndex = null;
      // this.grid.treeColumnIndex = this.getTreeColumnIndex(currentView);

      // Refresh Grid Columns
      this.grid.refreshColumns();

      // Apply Filters
      // if(currentView.Filters && currentView.Filters.length > 0) {
      //   // Get Current View Sort
      //   filters = currentView.Filters;

      //   filters.forEach(filter => {
      //     // If Filter Field is included in Columns list
      //     if(columns.includes(filter.Field)) {
      //       let suffix = "";

      //       // Add Suffix .Label to Object Fields {"Id": id, "Label": label} for Grid Component
      //       if(this.getColumnType(filter.Field) === 'Axe' || this.getColumnType(filter.Field) === 'Resource' || filter.Field === 'Meteo' || filter.Field === 'Trend' || filter.Field === 'Business_Line' || filter.FieldName === 'Project' || filter.Field === 'Workpackage' || filter.Field === 'Action' || filter.Field === 'Task' || filter.Field === 'Entity') {
      //         suffix = ".Label";
      //       }

      //       this.grid.filterByColumn(filter.Field + suffix, filter.Operator, filter.Items.split(','));
      //     }
      //   });
      // }
      // Apply Sort
      // if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Sort')) {
      //   // Get Current View Sort
      //   sort = currentView.Parameters.find(param => param.Name === 'Sort').Value;

      //   // If Sort Field is included in Columns list
      //   if(columns.includes(sort.split(' ')[0])) {
      //     let suffix = "";

      //     // Add Suffix .Label to Object Fields {"Id": id, "Label": label} for Grid Component
      //     if(this.getColumnType(sort.split(' ')[0]) === 'Axe' || this.getColumnType(sort.split(' ')[0]) === 'Resource' || sort.split(' ')[0] === 'Meteo' || sort.split(' ')[0] === 'Trend' || sort.split(' ')[0] === 'Business_Line' || sort.split(' ')[0] === 'Project' || sort.split(' ')[0] === 'Workpackage' || sort.split(' ')[0] === 'Action' || sort.split(' ')[0] === 'Task' || sort.split(' ')[0] === 'Entity') {
      //       suffix = ".Label";
      //     }

      //     this.grid.sortByColumn(sort.split(' ')[0] + suffix, sort.split(' ')[1]);
      //   }
      // }
    }
  }

  createColumn(columnHeader, columnsWidth) {
    // const language = this.state.language;
    const formatDate = this.state.formatDate;
    const itemType = this.state.itemType;
    const blockType = this.state.blockType;
    const currentView = this.state.currentView;
    let type, field, format, label, headerTemplate, template, textAlign, edit, editType, primaryKey, validationRules, width;

    // Type
    if(columnHeader.FieldType === 'String' || columnHeader.FieldName === 'Meteo' || columnHeader.FieldName === 'Trend') {
      type = 'string';
    }
    else if(columnHeader.FieldType === 'Double' || columnHeader.FieldType === 'Percentage') {
      type = 'number';
    }
    else if(columnHeader.FieldType === 'Date') {
      type = 'date';
    }
    else {
      type = null;
    }

    // Field (Add Suffix .Label to Object Fields {"Id": id, "Label": label} for Grid Component)
    if((columnHeader.FieldType === 'Axe' && columnHeader.FieldName !== 'HashTag') || columnHeader.FieldType === 'Resource' || columnHeader.FieldType === 'Location' || columnHeader.FieldName === 'Meteo' || columnHeader.FieldName === 'Trend' || columnHeader.FieldName === 'Business_Line' || columnHeader.FieldName === 'Project' || columnHeader.FieldName === 'Workpackage' || columnHeader.FieldName === 'Action' || columnHeader.FieldName === 'Task' || columnHeader.FieldName === 'Entity') {
      field = columnHeader.FieldName + ".Label";
    }
    else {
      field = columnHeader.FieldName;
    }

    // Format
    if(columnHeader.FieldType === 'Date') {
      if(formatDate === 'MM/DD/YYYY') {
        format = { type: 'date', format: 'MM/dd/yyyy' };
      }
      else if(formatDate === 'DD/MM/YYYY') {
        format = { type: 'date', format: 'dd/MM/yyyy' };
      }
      else if(formatDate === 'YYYY-MM-DD') {
        format = { type: 'date', format: 'yyyy-MM-dd' };
      }
    }
    else {
      format = null;
    }

    // Label
    if(blockType !== 'Warnings' && columnHeader.FieldName === 'WarningMessage') {
      label = 'W';
    }
    else {
      label = columnHeader.Label;
    }

    // Header Template
    headerTemplate = this.templateHeader;

    // Template functions
    // By FieldName
    if(columnHeader.FieldName === 'Action') {
      template = this.templateColumnAction;
    }
    else if(columnHeader.FieldName === 'Active') {
      template = this.templateColumnActive;
    }
    else if(columnHeader.FieldName === 'Admin') {
      template = this.templateColumnAdmin;
    }
    else if(columnHeader.FieldName === 'BackColor') {
      template = this.templateColumnBackColor;
    }
    else if(columnHeader.FieldName === 'BudgetCode') {
      template = this.templateColumnBudgetCode;
    }
    else if(columnHeader.FieldName === 'Business_Line') {
      template = this.templateColumnBusinessLine;
    }
    else if(columnHeader.FieldName === 'Card_Level' || columnHeader.FieldName === 'Level') {
      template = this.templateColumnCardLevel;
    }
    else if(columnHeader.FieldName === 'Data_Freshness') {
      template = this.templateColumnDataFreshness;
    }
    else if(columnHeader.FieldName === 'Decisions') {
      template = this.templateColumnDecisions;
    }
    else if(columnHeader.FieldName === 'Previous_Dependencies' || columnHeader.FieldName === 'Next_Dependencies') {
      template = this.templateColumnDependency;
    }
    else if(columnHeader.FieldName === 'FromLabel') {
      template = this.templateColumnDependencyFromItemLabel;
    }
    else if(columnHeader.FieldName === 'ToLabel') {
      template = this.templateColumnDependencyToItemLabel;
    }
    else if(columnHeader.FieldName === 'Dependency_Type') {
      template = this.templateColumnDependencyType;
    }
    else if(columnHeader.FieldName === 'Email') {
      template = this.templateColumnEmail;
    }
    else if(columnHeader.FieldName === 'Entity') {
      template = this.templateColumnEntity;
    }
    else if(columnHeader.FieldName === 'External') {
      template = this.templateColumnExternal;
    }
    else if(columnHeader.FieldName === 'FieldType') {
      template = this.templateColumnFieldType;
    }
    else if(columnHeader.FieldName === 'ForeColor') {
      template = this.templateColumnForeColor;
    }
    else if(columnHeader.FieldName === 'HashTag') {
      template = this.templateColumnHashtags;
    }
    else if(columnHeader.FieldName === 'Highlighted') {
      template = this.templateColumnHighlighted;
    }
    else if(columnHeader.FieldName === 'Impact') {
      template = this.templateColumnImpact;
    }
    else if(columnHeader.FieldName === 'Index') {
      template = this.templateColumnIndex;
    }
    else if(columnHeader.FieldName === 'Item_Type') {
      template = this.templateColumnItemType;
    }
    else if(columnHeader.FieldName === 'Lag' || columnHeader.FieldName === 'Next_Lag' || columnHeader.FieldName === 'Previous_Lag') {
      template = this.templateColumnLag;
    }
    else if(columnHeader.FieldName === 'Language') {
      template = this.templateColumnLanguage;
    }
    else if(columnHeader.FieldName === 'Levels') {
      template = this.templateColumnLevels;
    }
    else if(columnHeader.FieldName === 'Licence_Type') {
      template = this.templateColumnLicenceType;
    }
    else if(columnHeader.FieldName === 'Meeting') {
      template = this.templateColumnMeeting;
    }
    else if(columnHeader.FieldName === 'Meteo') {
      template = this.templateColumnMeteo;
    }
    else if(columnHeader.FieldName === 'Meteo_Freshness') {
      template = this.templateColumnMeteoFreshness;
    }
    else if(columnHeader.FieldName === 'Month_Burned') {
      template = this.templateColumnMonthBurned;
    }
    else if(columnHeader.FieldName === 'Name') {
      // Environment Axis Values
      if(itemType === 'Environment' && blockType === 'AxisValues') {
        template = this.templateColumnAxisName;
      }
      // Entity Table Resources
      else if(itemType === 'Entity' && blockType === 'Resources') {
        template = this.templateColumnResourceName;
      }
      else {
        template = this.templateColumnName;
      }
    }
    else if(columnHeader.FieldName === 'Parents') {
      template = this.templateColumnParents;
    }
    else if(columnHeader.FieldName === 'Priority') {
      template = this.templateColumnPriority;
    }
    else if(columnHeader.FieldName === 'Probability') {
      template = this.templateColumnProbability;
    }
    else if(columnHeader.FieldName === 'Progress') {
      template = this.templateColumnProgress;
    }
    else if(columnHeader.FieldName === 'Project') {
      template = this.templateColumnProject;
    }
    else if(columnHeader.FieldName === 'Rights') {
      template = this.templateColumnRights;
    }
    else if(columnHeader.FieldName === 'RemainingDays') {
      template = this.templateColumnRemainingDays;
    }
    else if(columnHeader.FieldName === 'Resources') {
      template = this.templateColumnResources;
    }
    else if(columnHeader.FieldName === 'RowType') {
      template = this.templateColumnRowType;
    }
    else if(columnHeader.FieldName === 'Severity') {
      template = this.templateColumnSeverity;
    }
    else if(columnHeader.FieldName === 'Sprint') {
      template = this.templateColumnSprint;
    }
    else if(columnHeader.FieldName === 'Status') {
      template = this.templateColumnStatus;
    }
    else if(columnHeader.FieldName === 'Task') {
      template = this.templateColumnTask;
    }
    else if(columnHeader.FieldName === 'Timeline') {
      template = this.templateColumnTimeline;
    }
    else if(columnHeader.FieldName === 'Trend') {
      template = this.templateColumnTrend;
    }
    else if(columnHeader.FieldName === 'Validated') {
      template = this.templateColumnValidated;
    }
    else if(columnHeader.FieldName === 'WarningMessage') {
      if(blockType !== 'Warnings') {
        template = this.templateColumnWarning;
      }
      else {
        template = this.templateTypeText;
      }
    }
    else if(columnHeader.FieldName === 'Workpackage') {
      template = this.templateColumnWorkpackage;
    }
    else if(columnHeader.FieldName === 'Year') {
      template = this.templateColumnYear;
    }
    // By Type
    else if(columnHeader.FieldType === 'Axe') {
      template = this.templateTypeAxe;
    }
    else if(columnHeader.FieldType === 'AxisTable') {
      template = this.templateTypeAxisTable;
    }
    else if(columnHeader.FieldType === 'Boolean') {
      template = this.templateTypeBoolean;
    }
    else if(columnHeader.FieldType === 'Date') {
      template = this.templateTypeDate;
    }
    else if(columnHeader.FieldType === 'Double') {
      template = this.templateTypeDouble;
    }
    else if(columnHeader.FieldType === 'Files') {
      template = this.templateTypeFiles;
    }
    else if(columnHeader.FieldType === 'Formula') {
      template = this.templateTypeFormula;
    }
    else if(columnHeader.FieldType === 'HTML') {
      template = this.templateTypeHTML;
    }
    else if(columnHeader.FieldType === 'Link') {
      template = this.templateTypeLink;
    }
    else if(columnHeader.FieldType === 'Location') {
      template = this.templateTypeLocation;
    }
    else if(columnHeader.FieldType === 'Percentage') {
      template = this.templateTypePercentage;
    }
    else if(columnHeader.FieldType === 'Progress') {
      template = this.templateTypeProgress;
    }
    else if(columnHeader.FieldType === 'Rating') {
      template = this.templateTypeRating;
    }
    else if(columnHeader.FieldType === 'Resource') {
      template = this.templateTypeResource;
    }
    else if(columnHeader.FieldType === 'ResourceTable') {
      template = this.templateTypeResourceTable;
    }
    else {
      template = this.templateTypeText;
    }

    // Align
    if(columnHeader.FieldType === 'Date' || columnHeader.FieldName === 'Active' || columnHeader.FieldName === 'Cost_Following' || columnHeader.FieldName === 'Static_Data' || columnHeader.FieldName === 'Users_Config' || columnHeader.FieldName === 'Administrator' || columnHeader.FieldName === 'Reporting' || columnHeader.FieldName === 'Previous_Lag' || columnHeader.FieldName === 'Next_Lag') {
      textAlign = 'center';
    }
    else if(columnHeader.FieldType === 'Double' && (columnHeader.FieldName !== 'Meteo' && columnHeader.FieldName !== 'Trend')) {
      textAlign = 'right';
    }

    // Edit
    if(columnHeader.FieldType === 'Date') {
      edit = { params: { firstDayOfWeek: 1, weekNumber: true } };
    }
    // if(columnHeader.FieldType === 'Axe' || columnHeader.FieldType === 'Date' || columnHeader.FieldType === 'Entity' || columnHeader.FieldType === 'Resource' || columnHeader.FieldName === 'Meteo' || columnHeader.FieldName === 'Trend') {
    //   edit = this.getDropdownList(columnHeader);
    // }
    else {
      edit = null;
    }

    // Edit Type
    if(columnHeader.FieldType === 'Axe' || columnHeader.FieldType === 'Entity' || columnHeader.FieldType === 'Resource' || columnHeader.FieldName === 'Meteo' || columnHeader.FieldName === 'Trend') {
      editType = 'dropdownedit';
    }
    else if(columnHeader.FieldType === 'Date') {
      editType = 'datepickeredit';
    }
    else {
      editType = null;
    }

    // Primary Key
    if(columnHeader.FieldName === 'Item_ID') {
      primaryKey = true;
    }
    else {
      primaryKey = false;
    }

    // Validation Rules
    if(columnHeader.FieldName === 'Name' && blockType !== 'Risks') {
      validationRules = this.nameRules;
    }
    else if(columnHeader.FieldName === 'Name' && blockType === 'Risks') {
      validationRules = this.riskNameRules;
    }
    else if(columnHeader.FieldName === 'Login') {
      validationRules = this.nameRules;
    }
    else if(columnHeader.FieldType === 'String') {
      validationRules = this.textRules;
    }
    else if(columnHeader.FieldName === 'Corrective_Action' || columnHeader.FieldName === 'Risk_Comment') {
      validationRules = this.riskCommentRules;
    }
    else if(columnHeader.FieldName === 'Burned_Workload' || columnHeader.FieldName === 'Workload' || columnHeader.FieldName === 'Month_Burned') {
      validationRules = this.numberRules;
    }
    else if(columnHeader.FieldName === 'Progress') {
      validationRules = this.progressRules;
    }
    else if(columnHeader.FieldName === 'Year') {
      validationRules = this.yearRules;
    }
    else {
      validationRules = null;
    }

    // Width
    if(columnsWidth.find(column => column.Name === columnHeader.FieldName)) {
      width = columnsWidth.find(column => column.Name === columnHeader.FieldName).Width;
    }
    else {
      if(columnHeader.FieldName === 'Name') {
        width = 400;
      }
      else if(columnHeader.FieldName === 'Status') {
        width = 150;
      }
      else if(columnHeader.FieldName === 'Priority') {
        width = 110;
      }
      else if(columnHeader.FieldName === 'Probability') {
        width = 120;
      }
      else if(columnHeader.FieldName === 'Impact') {
        width = 110;
      }
      else if(columnHeader.FieldName === 'Meteo') {
        width = 100;
      }
      else if(columnHeader.FieldName === 'Trend') {
        width = 115;
      }
      else if(columnHeader.FieldName === 'Progress') {
        width = 175;
      }
      else if(columnHeader.FieldName === 'Workload') {
        width = 135;
      }
      else if(columnHeader.FieldName === 'Burned_Workload') {
        width = 145;
      }
      else if(columnHeader.FieldName === 'StartDate') {
        width = 155;
      }
      else if(columnHeader.FieldName === 'EndDate') {
        width = 155;
      }
      else if(columnHeader.FieldName === 'Timeline') {
        width = 200;
      }
      else if(columnHeader.FieldName === 'Resource') {
        width = 160;
      }
      else if(columnHeader.FieldName === 'Task_Type') {
        width = 170;
      }
      else if(columnHeader.FieldName === 'WarningMessage') {
        if(blockType !== 'Warnings') {
          width = 90;
        }
        else {
          width = 220;
        }
      }
      else if(columnHeader.FieldName === 'WarningType') {
        width = 140;
      }
      else if(columnHeader.FieldName === 'Active') {
        width = 90;
      }
      else if(columnHeader.FieldName === 'Description') {
        width = 350;
      }
      else if(columnHeader.FieldName === 'Comment') {
        width = 350;
      }
      else if(columnHeader.FieldName === 'BackColor') {
        width = 100;
      }
      else if(columnHeader.FieldName === 'ForeColor') {
        width = 100;
      }
      else if(columnHeader.FieldName === 'Rights') {
        width = 220;
      }
      else if(columnHeader.FieldName === 'Corrective_Action') {
        width = 350;
      }
      else if(columnHeader.FieldName === 'Decision_Type') {
        width = 170;
      }
      else if(columnHeader.FieldName === 'Meeting_Type') {
        width = 170;
      }
      else if(columnHeader.FieldName === 'Resource_Type') {
        width = 170;
      }
      else if(columnHeader.FieldName === 'Owner') {
        width = 160;
      }
      else if(columnHeader.FieldName === 'Project') {
        width = 240;
      }
      else if(columnHeader.FieldName === 'Message') {
        width = 350;
      }
      else if(columnHeader.FieldName === 'Time') {
        width = 150;
      }
      else if(columnHeader.FieldName === 'Email') {
        width = 220;
      }
      else if(columnHeader.FieldName === 'Cost_Following' || columnHeader.FieldName === 'Reporting') {
        width = 120;
      }
      else if(columnHeader.FieldName === 'Static_Data' || columnHeader.FieldName === 'Users_Config' || columnHeader.FieldName === 'Administrator') {
        width = 150;
      }
      else if(columnHeader.FieldName === 'Firm') {
        width = 140;
      }
      else if(columnHeader.FieldName === 'Login') {
        width = 200;
      }
      else if(columnHeader.FieldName === 'Licence_EndDate') {
        width = 160;
      }
      else if(columnHeader.FieldName === 'First_Name' || columnHeader.FieldName === 'Last_Name') {
        width = 140;
      }
      else if(columnHeader.FieldName === 'Licence_Type') {
        width = 150;
      }
      else if(columnHeader.FieldName === 'Highlighted' || columnHeader.FieldName === 'External' || columnHeader.FieldName === 'Validated') {
        width = 125;
      }
      else if(columnHeader.FieldName === 'Data_Freshness') {
        width = 120;
      }
      else if(columnHeader.FieldName === 'Meteo_Freshness') {
        width = 110;
      }
      else if(columnHeader.FieldName === 'FromLabel' || columnHeader.FieldName === 'ToLabel') {
        width = 250;
      }
      else if(columnHeader.FieldName === 'From_Item_ID' || columnHeader.FieldName === 'To_Item_ID') {
        width = 100;
      }
      else if(columnHeader.FieldName === 'Dependency_Type') {
        width = 120;
      }
      else if(columnHeader.FieldName === 'Previous_Constrainst' || columnHeader.FieldName === 'Next_Constrainst') {
        width = 150;
      }
      else if(columnHeader.FieldName === 'Lag') {
        width = 100;
      }
    }

    // Define Column object for Grid
    var colObj = {
      allowEditing: this.getColumnEditable(columnHeader.EditionItemTypes),
      editType: editType,
      edit: edit,
      field: field,
      // field: columnHeader.FieldName,
      format: format,
      headerTemplate: headerTemplate,
      headerText: label,
      headerTextAlign: 'left',
      isPrimaryKey: primaryKey,
      lockColumn: false,
      showInColumnChooser: false,
      template: template,
      textAlign: textAlign,
      type: type,
      validationRules: validationRules,
      visible: true,
      width: width
    };

    return colObj;
  }

  dataStructure(rows) {
    let dataSource = [];

    if(rows.length > 0) {
      dataSource = rows.map(row => {
        return row.Cells.reduce((acc, item) => {
          // Add the Column Name/Values to the reduced Table
          // Format Dates
          if(item.FieldType === 'Date' && item.Value) {
            acc[item.ColumnName] = new Date(item.Value);
          }
          // Format Numbers
          else if(item.FieldType === 'Double' && item.Value) {
            acc[item.ColumnName] = parseFloat(item.Value);
          }
          // Format Meteo/Trend
          else if((item.ColumnName === 'Meteo' || item.ColumnName === 'Trend') && item.Value) {
            acc[item.ColumnName] = JSON.parse(item.Value);
          }
          // Format Objects
          else if(item.FieldType === 'Object' && item.Value) {
            acc[item.ColumnName] = JSON.parse(item.Value);
          }
          // Format Object Tables (Decisions/Files/HashTags/Rights/Resources)
          else if(item.FieldType === 'ObjectTable' || item.FieldType === 'Files' || item.FieldType === 'ResourceTable') {
            if(item.Value) {
              acc[item.ColumnName] = JSON.parse(item.Value);
            }
            else {
              acc[item.ColumnName] = [];
            }
          }
          // Format Collapse Boolean
          else if(item.ColumnName === 'isCollapsed') {
            if(item.Value === 'False') {
              acc['isExpanded'] = true;
            }
            else if(item.Value === 'True') {
              acc['isExpanded'] = false;
            }
          }
          // Exclude Parent_ID for Table
          else if(item.ColumnName !== 'Parent_ID') {
            acc[item.ColumnName] = item.Value;
          }

          return acc;
        }, {});
      });
    }

    return dataSource;
  }

  dataBound(args) {
    const blockType = this.state.blockType;

    if(this.grid) {
      // Autofit Columns width
      // this.grid.autoFitColumns();

      // TO UPDATE: Fix: Force Column Generation after Data update
      if(this.grid.columns.length === 1 || (blockType === "Risks" && this.grid.columns.length === 2)) {
        this.created();
      }

      // Table Event Listener
      if(!this.tableClicked) {
        this.grid.element.addEventListener('click', this.onBooleanColumnClick);
        // this.grid.element.addEventListener('dblclick', this.onHolidayDoubleClick);
        this.tableClicked = true;
      }
    }
  }

  // Row Formatting design
  rowDataBound(args) {
    const itemType = this.state.itemType;
    const rowItemType = getObject('Item_Type', args.data);

    // Define styles for Objects rows
    if(rowItemType === 'Business_Line') {
      args.rowHeight = 50;
    }
    else if(rowItemType === 'Project') {
      args.rowHeight = 50;
    }
    else if(rowItemType === 'Workpackage') {
      args.rowHeight = 40;
    }
    else if(rowItemType === 'Action') {
      args.rowHeight = 30;
      args.row.style.fontWeight = '500';
    }
    else if(rowItemType === 'Task') {
      args.rowHeight = 30;
      args.row.style.fontWeight = '100';
    }
    else if(rowItemType === 'Entity') {
      args.rowHeight = 50;
    }
    else if(rowItemType === 'Resource') {
      args.rowHeight = 40;
    }
    else {
      args.rowHeight = 30;
      args.row.style.fontWeight = '100';
    }
  }

  rowSelected(args) {
    // console.log(args);
  }

  contextMenuOpen(args) {
    // console.log(args);
  }

  // Right Click Events
  contextMenuClick(args) {
    const language = this.state.language;
    const blockType = this.state.blockType;
      
    if(args.item.id === 'contextMenu_Duplicate') {
      let selectedRows = this.grid.getSelectedRecords();
      
      if(selectedRows.length > 0) {
        let itemId = selectedRows[0].Item_ID.substring(1);
        let itemType = selectedRows[0].Item_Type;

        // Call the event from the Parent component through the props
        this.props.onItemCreate('', itemType, itemId, true);
      }
    }
    else if(args.item.id === 'contextMenu_Restore') {
      let selectedRows = this.grid.getSelectedRecords();
      
      if(selectedRows.length > 0) {
        let deleteId = selectedRows[0].DeleteId;

        // Call the event from the Parent component through the props
        this.props.onItemRestore(deleteId);
      }
    }
    else if(args.item.id === 'contextMenu_Reset_Password') {
      let selectedRows = this.grid.getSelectedRecords();

      if(selectedRows.length > 0) {
        let itemId = selectedRows[0].Item_ID.substring(1);
        let itemType = selectedRows[0].Item_Type;
        let login = selectedRows[0].Login;

        // Call the event from the Parent component through the props
        this.props.onUserResetPassword(login);
      }
    }
    else if(args.item.id === 'contextMenu_Delete') {
      let selectedRows = this.grid.getSelectedRecords();
      
      if(selectedRows.length > 0) {
        let itemId = selectedRows[0].Item_ID.substring(1);
        let itemType = selectedRows[0].Item_Type;
        let deleteId = selectedRows[0].DeleteId;

        if(blockType !== 'DeleteBin') {
          this.setState({ confirm: true, rowItemType: itemType, rowItemId: itemId });
        }
        else {
          this.setState({ confirm: true, deleteId });
        }
      }
    }
    else if(args.item.id === 'contextMenu_Dependencies') {
      let selectedRows = this.grid.getSelectedRecords();
      
      if(selectedRows[0]) {
        // Call the event from the Parent component through the props
        this.props.onDependenciesDisplay(selectedRows[0]);
      }
    }
    else if(args.item.id === 'contextMenu_Audit') {
      let selectedRows = this.grid.getSelectedRecords();

      if(selectedRows.length > 0) {
        let itemId = selectedRows[0].Item_ID.substring(1);
        let itemType = selectedRows[0].Item_Type;
        let name;

        // Specific Name for Block Budget
        if(blockType === 'BudgetDetails') {
          name = Traduction.translate(language, 'budget_definition') + " " + itemId;
        }
        // Specific Name for Block Meetings
        else if(blockType === 'Meetings') {
          if(selectedRows[0].EndDate) {
            if(language === 'English') {
              name = selectedRows[0].Meeting_Type.Label + " - " + this.formatDateEn(new Date(selectedRows[0].EndDate));
            }
            else if(language === 'Français') {
              name = selectedRows[0].Meeting_Type.Label + " - " + this.formatDateFr(new Date(selectedRows[0].EndDate));
            }
          }
          else {
            name = selectedRows[0].Meeting_Type.Label;
          }
        }
        else {
          name = selectedRows[0].Name;
        }

        this.setState({ auditPopup: true, rowItemType: itemType, rowItemId: itemId, rowItemTitle: name });
      }
    }
  }

  getColumnHeaders() {
    return this.state.columns;
  }

  getColumnEditable(editionItemTypes) {
    const currentView = this.state.currentView;

    // Get View corresponding level
    const level = this.getCurrentViewLevel(currentView);

    if(editionItemTypes.includes(level)) {
      return true;
    }
    else {
      return false;
    }
  }

  getColumnEditionItemType(columnName) {
    const columns = this.state.columns;

    if(columns.find(column => column.FieldName === columnName)) {
      return columns.find(column => column.FieldName === columnName).EditionItemTypes;
    }
    else {
      return false;
    }
  }

  getColumnFilter(columnName) {
    const currentView = this.state.currentView;

    if(currentView.Filters && currentView.Filters.length > 0) {
      if(currentView.Filters.find(filter => filter.Field === columnName)) {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      return false;
    }
  }

  getColumnLabel(columnName) {
    const columns = this.state.columns;
    let label = '';

    if(columns.find(column => column.FieldName === columnName)) {
      label = columns.find(column => column.FieldName === columnName).Label;
    }

    if(columnName === 'WarningMessage') {
      label = 'W';
    }
    
    return label;
  }

  getColumnRestrictedValues(columnName) {
    const columns = this.state.columns;

    if(columns.find(column => column.FieldName === columnName)) {
      return columns.find(column => column.FieldName === columnName).RestrictedValues;
    }
    else {
      return [];
    }
  }

  getColumnSort(columnName) {
    const currentView = this.state.currentView;

    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Sort')) {
      if(currentView.Parameters.find(param => param.Name === 'Sort').Value.split(',').find(sort => sort === columnName + " DESC")) {
        return "descending";
      }
      else if(currentView.Parameters.find(param => param.Name === 'Sort').Value.split(',').find(sort => sort === columnName + " Descending")) {
        return "descending";
      }
      else if(currentView.Parameters.find(param => param.Name === 'Sort').Value.split(',').find(sort => sort === columnName)) {
        return "ascending";
      }
      else if(currentView.Parameters.find(param => param.Name === 'Sort').Value.split(',').find(sort => sort === columnName + " Ascending")) {
        return "ascending";
      }
      else {
        return false;
      }

      // if(currentView.Parameters.find(param => param.Name === 'Sort').Value.includes(columnName + " DESC")) {
      //   return "descending";
      // }
      // else if(currentView.Parameters.find(param => param.Name === 'Sort').Value.includes(columnName)) {
      //   return "ascending";
      // }
    }
    else {
      return false;
    }
  }

  getColumnType(columnName) {
    const columns = this.state.columns;

    if(columns.find(column => column.FieldName === columnName)) {
      return columns.find(column => column.FieldName === columnName).FieldType;
    }
    else {
      return null;
    }
  }

  getColumnUnit(columnName) {
    const columns = this.state.columns;

    if(columns.find(column => column.FieldName === columnName)) {
      return columns.find(column => column.FieldName === columnName).Unit;
    }
    else {
      return null;
    }
  }

  getColumnConditionalFormattings(columnName) {
    const columns = this.state.columns;

    if(columns.find(column => column.FieldName === columnName)) {
      return columns.find(column => column.FieldName === columnName).ConditionalFormattings;
    }
    else {
      return [];
    }
  }

  getCellConditionalFormatting(conditionalFormattings, value) {
    if(conditionalFormattings) {
      for(let i = 0; i < conditionalFormattings.length; i++) {
        if(this.isConditionalFormattingRespected(conditionalFormattings[i], value)) {
          return conditionalFormattings[i];
        }
      }
    }
    else {
      return null;
    }
  }

  isConditionalFormattingRespected(conditionalFormatting, value) {
    if(conditionalFormatting) {
      switch(conditionalFormatting.Conditions) {
        case 'equal':
            if(conditionalFormatting.Value1 == value) {
              return true;
            }
            else {
              return false;
            }
        case 'notequal':
            if(conditionalFormatting.Value1 != value) {
              return true;
            }
            else {
              return false;
            }
        case 'greaterthan':
            if(conditionalFormatting.Value1 < value) {
              return true;
            }
            else {
              return false;
            }
        case 'greaterthanorequal':
            if(conditionalFormatting.Value1 <= value) {
              return true;
            }
            else {
              return false;
            }
        case 'lessthan':
            if(conditionalFormatting.Value1 > value) {
              return true;
            }
            else {
              return false;
            }
        case 'lessthanorequal':
            if(conditionalFormatting.Value1 >= value) {
              return true;
            }
            else {
              return false;
            }
        case 'between':
            if(conditionalFormatting.Value1 <= value && conditionalFormatting.Value2 >= value) {
              return true;
            }
            else {
              return false;
            }
        default:
            return false;
      }
    }
    else {
      return false;
    }
  }

  getCurrentViewLevel() {
    const currentView = this.state.currentView;
    let level;

    // Get View corresponding level
    if(currentView) {
      switch(currentView.DefaultLevel) {
        case 0:
            level = 'Tree';
            break;
        case 1:
            level = 'Business_Line';
            break;
        case 2:
            level = 'Project';
            break;
        case 3:
            level = 'Workpackage';
            break;
        case 4:
            level = 'Action';
            break;
        case 5:
            level = 'Task';
            break;
        case 6:
            level = 'Risk';
            break;
        case 7:
            level = 'Issue';
            break;
        case 8:
            level = 'Decision';
            break;
        case 9:
            level = 'Meeting';
            break;
        case 10:
            level = 'Definition';
            // level = 'Budget';
            break;
        case 11:
            level = 'Link';
            break;
        case 12:
            level = 'Resource';
            break;
        case 13:
            level = 'Entity';
            break;
        case 14:
            level = 'User';
            break;
        case 15:
            level = 'AxisValue';
            break;
        case 16:
            level = 'DeletedItem';
            break;
        case 18:
            level = 'Workload';
            break;
        case 19:
            level = 'CustomField';
            break;
        case 20:
            level = 'HashTag';
            break;
        case 21:
            level = 'BudgetCode';
            break;
        case 22:
            level = 'UserGroup';
            break;
        case 23:
            level = 'Warning';
            break;
        case 24:
            level = 'Sprint';
            break;
        case 25:
            level = 'AgileBoard';
            break;
        case 26:
            level = 'Dependency';
            break;
        case 30:
            level = 'NotificationRule';
            break;
        default:
            break;
      }
    }

    return level;
  }

  getDeleteLabel(itemType) {
    const language = this.state.language;
    const blockType = this.state.blockType;

    if(blockType === 'AxisValues') {
      return Traduction.translate(language, 'delete_axis_value');
    }
    else {
      switch(itemType) {
        case 'Business_Line':
            return Traduction.translate(language, 'delete_business_line');
        case 'Project':
            return Traduction.translate(language, 'delete_project');
        case 'Workpackage':
            return Traduction.translate(language, 'delete_workpackage');
        case 'Action':
            return Traduction.translate(language, 'delete_action');
        case 'Task':
            return Traduction.translate(language, 'delete_task');
        case 'Entity':
            return Traduction.translate(language, 'delete_entity');
        case 'Resource':
            return Traduction.translate(language, 'delete_resource');
        case 'AgileBoard':
            return Traduction.translate(language, 'delete_agile_board');
        case 'Sprint':
            return Traduction.translate(language, 'delete_sprint');
        case 'Decision':
            return Traduction.translate(language, 'delete_decision');
        case 'Issue':
            return Traduction.translate(language, 'delete_issue');
        case 'Meeting':
            return Traduction.translate(language, 'delete_meeting');
        case 'Risk':
            return Traduction.translate(language, 'delete_risk');
        case 'Definition':
            return Traduction.translate(language, 'delete_budget');
        case 'Dependency':
            return Traduction.translate(language, 'delete_dependency');
        case 'File':
            return Traduction.translate(language, 'delete_file');
        case 'CustomField':
            return Traduction.translate(language, 'delete_custom_field');
        case 'UserGroup':
            return Traduction.translate(language, 'delete_user_group');
      }
    }
  }

  getDropdownList(columnHeader) {
    let values = [];
    let dataSource = [];

    // Get the different values possible for a selected column
    values = columnHeader.RestrictedValues;

    // Push the different values into the DataSource to build Combo box
    values.forEach((value, index) => {
      dataSource.push({ Name: value, Id: index+1 });
      // dataSource.push({ Name: value.Label, Id: value.Id, Value: value });
    });

    return {
      params: {
        actionComplete: () => false,
        allowFiltering: true,
        dataSource: new DataManager(dataSource),
        fields: { text: "Name", value: "Name" },
        // fields: { text: "Name", value: "Id" },
        query: new Query()
      }
    };
  }

  getTreeColumnIndex(currentView) {
    let columns = [];

    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Columns')) {
      columns = (currentView.Parameters.find(param => param.Name === 'Columns').Value).split(',');
    }

    for(let i = 0; i < columns.length; i++) {
      if(columns[i] === 'Name') {
        return i;
      }
    }

    return null;
  }

  // Formatting Date to English format for Database
  formatDateEn(date) {
    let formattedDate;

    if(date) {
      // let dateFr = new Date(date).toLocaleString().split(' ')[0];
      let dateFr = new Date(date).toLocaleString("en-GB").split(/,| /)[0];

      const split = dateFr.split('/');
      const day = split[0];
      const month = split[1];
      const year = split[2];

      formattedDate = month + "/" + day + "/" + year;
    }

    return formattedDate;
  }

  // Formatting Date to French format
  formatDateFr(date) {
    let formattedDate;

    if(date) {
      // let dateFr = new Date(date).toLocaleString().split(' ')[0];
      let dateFr = new Date(date).toLocaleString("en-GB").split(/,| /)[0];

      const split = dateFr.split('/');
      const day = split[0];
      const month = split[1];
      const year = split[2];

      formattedDate = day + "/" + month + "/" + year;
    }

    return formattedDate;
  }

  // Formatting Date to Korean format
  formatDateKr(date) {
    let formattedDate;

    if(date) {
      // let dateFr = new Date(date).toLocaleString().split(' ')[0];
      let dateFr = new Date(date).toLocaleString("en-GB").split(/,| /)[0];

      const split = dateFr.split('/');
      const day = split[0];
      const month = split[1];
      const year = split[2];

      formattedDate = year + "-" + month + "-" + day;
    }

    return formattedDate;
  }

  actionBegin(args) {
    // console.log(args);
  }

  actionComplete(args) {
    // Columns
    if(args.requestType === 'columnstate' || args.requestType === 'reorder') {
      const currentView = this.state.currentView;
      let columns = '';

      this.grid.columns.forEach((column, index) => {
        if(column.visible && column.field !== 'Index') {
          // Push visible Columns (and exclude column Index)
          // columns.push(column.field);

          // Build Column's list
          // columns.join(',');

          // Remove Suffix .Label to Object Fields {"Id": id, "Label": label} for Database
          column.field = column.field.replace(".Label", "");

          // Build Columns List order (this.grid has column Item_ID not visible)
          if(index < this.grid.columns.length - 2) {
            columns = columns.concat(column.field, ',');
          }
          else {
            columns = columns.concat(column.field);
          }
        }
      });

      // Update Current View Columns list
      if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Columns')) {
        currentView.Parameters.find(param => param.Name === 'Columns').Value = columns;
      }

      // Add Modify attribute in CurrentView
      currentView["Modify"] = true;

      // Call the event from the Parent component through the props with view value
      this.props.onViewChange(currentView);
    }
    // Filter
    else if(args.requestType === 'filtering') {
      const currentView = this.state.currentView;
      let filterSettings = [];

      if(currentView.Filters) {
        if(this.grid.filterSettings.columns.length > 0) {
          this.grid.filterSettings.columns.forEach(column => {
            // Remove Suffix .Label to Object Fields {"Id": id, "Label": label} for Database
            const columnFilter = column.field.replace(".Label", "");

            if(column.value) {
              // Get Table Current View Filters
              if(filterSettings.find(filter => filter.Field === columnFilter)) {
                filterSettings.find(filter => filter.Field === columnFilter).Items += "," + column.value;
              }
              else {
                filterSettings.push({ Field: columnFilter, Operator: column.operator, Type: column.type, Items: column.value, ViewFilterId: -1, ViewId: currentView.ViewId });
              }
            }
            else if(column.value === '') {
              // Get Table Current View Filters
              if(filterSettings.find(filter => filter.Field === columnFilter)) {
                filterSettings.find(filter => filter.Field === columnFilter).Items += "," + "";
              }
              else {
                filterSettings.push({ Field: columnFilter, Operator: column.operator, Type: column.type, Items: "", ViewFilterId: -1, ViewId: currentView.ViewId });
              }
            }
            else {
              // Get Table Current View Filters
              if(filterSettings.find(filter => filter.Field === columnFilter)) {
                filterSettings.find(filter => filter.Field === columnFilter).Items = "";
              }
              else {
                filterSettings.push({ Field: columnFilter, Operator: column.operator, Type: column.type, Items: "", ViewFilterId: -1, ViewId: currentView.ViewId });
              }
            }
          });
        }

        // Update Current View Filters list
        currentView.Filters = filterSettings;

        // Add Modify attribute in CurrentView
        currentView["Modify"] = true;

        // Call the event from the Parent component through the props with view value
        this.props.onViewChange(currentView);
      }
    }
    // Sort
    else if(args.requestType === 'sorting') {
      const currentView = this.state.currentView;

      if(args.cancel === false) {
        const columnName = args.columnName;
        const direction = args.direction;

        // Remove Suffix .Label to Object Fields {"Id": id, "Label": label} for Database
        const column = columnName.replace(".Label", "") + ' ' + direction;

        // Update Current View Sort
        if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Sort')) {
          currentView.Parameters.find(param => param.Name === 'Sort').Value = column;
        }
        // Create Current View Sort
        else {
          currentView.Parameters.push({ Name: 'Sort', Value: column, ViewFilterId: -1, ViewId: currentView.ViewId });
        }
      }
      else {
        // Delete Sort
        if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Sort')) {
          currentView.Parameters.splice(currentView.Parameters.findIndex(param => param.Name === 'Sort'), 1);
        }
      }

      // Add Modify attribute in CurrentView
      currentView["Modify"] = true;

      // Call the event from the Parent component through the props with view value
      this.props.onViewChange(currentView);
    }
    // Delete
    // else if(args.requestType === 'delete') {
    //   if(args.data) {
    //     const itemId = args.data[0].Item_ID.substring(1);
    //     const itemType = args.data[0].Item_Type;
    //     const deleteId = itemType.substring(0,1).concat(itemId);

    //     if(blockType !== 'DeleteBin') {
    //       if(window.confirm(this.getDeleteLabel(itemType))) {
    //         // Call the event from the Parent component through the props
    //         this.props.onItemDelete(itemId, itemType);
    //       }
    //     }
    //     else {
    //       if(window.confirm(this.getDeleteLabel(itemType))) {
    //         // Call the event from the Parent component through the props
    //         this.props.onItemDelete(deleteId);
    //       }
    //     }
    //   }
    // }
    // Save
    else if(args.type == 'save') {
      if(args.data) {
        const itemId = args.data.Item_ID.substring(1);
        const itemType = args.data.Item_Type;
        const columnName = args.column.field;
        const type = args.column.type;
        let oldValue = args.previousData;
        let newValue = args.data[columnName];
        // const warning = args.data['WarningMessage'];

        // Force Date format after Datepicker modification (format MM/dd/YYYY for Database)
        if(type === 'date' || columnName === 'StartDate' || columnName === 'EndDate' || columnName === 'Initial_StartDate' || columnName === 'Initial_EndDate') {
          if(newValue) {
            newValue = this.formatDateEn(newValue);
          }
          else {
            newValue = "";
          }
  
          if(oldValue) {
            oldValue = this.formatDateEn(oldValue);
          }
          else {
            oldValue = "";
          }
        }
  
        if(newValue != oldValue) {
          // Call the event from the Parent component through the props
          this.props.onTableUpdate(itemId, itemType, columnName, oldValue, newValue);
        }
      }
    }
  }

  cellEdit(args) {
    const blockType = this.state.blockType;
    const columnName = args.columnName.replace(".Label", "");

    // Get Column Type
    const columnType = this.getColumnType(columnName);

    // Edit Dependencies with Popup
    if(columnName === 'Previous_Constraint' || columnName === 'Next_Constraint' || columnName === 'Previous_Lag' || columnName === 'Next_Lag' || columnName === 'Previous_Dependencies' || columnName === 'Next_Dependencies') {
      // Cancel Editing in Table
      args.cancel = true;
      
      // If Column is editable, open Popup to modify Rights value
      // if(this.getColumnEditable(this.getColumnEditionItemType(args.columnName))) {
      //   this.setState({ dependenciesPopup: true, rowItemId: args.rowData.Item_ID.substring(1), rowItemType: args.rowData.Item_Type, rowItemTitle: args.rowData.Name, rowItemColumn: args.columnName });
      // }

      // Call the event from the Parent component through the props
      this.props.onDependenciesDisplay(args.rowData);
    }
    // Edit Files with Popup
    else if(columnType === 'Files') {
      // Cancel Editing in Table
      args.cancel = true;

      this.setState({ filesPopup: true, rowItemId: args.rowData.Item_ID.substring(1), rowItemType: args.rowData.Item_Type, rowItemTitle: args.rowData.Name, rowItemColumn: args.columnName, rowItemLabel: this.getColumnLabel(args.columnName) });
    }
    // Edit value with Popup and Text Formula Editor
    else if(columnType === 'Formula') {
      // Cancel Editing in Table
      args.cancel = true;

      // If Column is editable, open Popup to modify Text Editor value
      if(this.getColumnEditable(this.getColumnEditionItemType(args.columnName))) {
        this.setState({ textFormulaEditorPopup: true, rowItemId: args.rowData.Item_ID, rowItemType: args.rowData.Item_Type, rowItemTitle: args.rowData.Label, rowItemColumn: args.columnName, rowItemLabel: this.getColumnLabel(args.columnName), rowItemValue: args.value });
      }
    }
    // Edit value with Popup and Text Editor
    else if(columnType === 'HTML') {
      // Cancel Editing in Table
      args.cancel = true;

      // If Column is editable, open Popup to modify Text Editor value
      if(this.getColumnEditable(this.getColumnEditionItemType(args.columnName))) {
        this.setState({ textEditorPopup: true, rowItemId: args.rowData.Item_ID, rowItemType: args.rowData.Item_Type, rowItemTitle: args.rowData.Name, rowItemColumn: args.columnName, rowItemLabel: this.getColumnLabel(args.columnName), rowItemValue: args.value });
      }
    }
    // Edit Rights with Popup
    else if(columnName === 'Rights') {
      let itemTitle;

      // Cancel Editing in Table
      args.cancel = true;

      if(blockType === 'CustomFields') {
        itemTitle = args.rowData.Label;
      }
      else {
        itemTitle = args.rowData.Name;
      }

      // If Column is editable, open Popup to modify Rights
      if(this.getColumnEditable(this.getColumnEditionItemType(args.columnName))) {
        this.setState({ rightsPopup: true, rowItemId: args.rowData.Item_ID.substring(1), rowItemType: args.rowData.Item_Type, rowItemTitle: itemTitle, rowItemColumn: args.columnName });
      }
    }
    // Edit value with Popover
    else if(columnType === 'Axe' || columnType === 'AxisTable' || columnType === 'Location' || columnType === 'Resource' || columnType === 'ResourceTable' || columnName === 'Meteo' || columnName === 'Trend' || columnName === 'Dependency_Type' || columnName === 'BackColor' || columnName === 'ForeColor' || columnName === 'Business_Line' || columnName === 'Project' || columnName === 'Workpackage' || columnName === 'Action' || columnName === 'Task' || columnName === 'Entity' || columnName === 'Levels') {
      // Cancel Editing in Table
      args.cancel = true;
    }
    // Edit value with Boolean icon
    else if(columnType === 'Boolean') {
      // Cancel Editing in Table
      args.cancel = true;
    }
    // Edit value with Rating icon
    else if(columnType === 'Rating') {
      // Cancel Editing in Table
      args.cancel = true;
    }
  }

  refreshColumns(currentView) {
    const blockType = this.state.blockType;
    let columns = [], columnsWidth = [], filters = [], sort = [];

    // Get Current View Columns
    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Columns')) {
      columns = (currentView.Parameters.find(param => param.Name === 'Columns').Value).split(',');
    }
    // Get Current View Columns Width
    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'ColumnsWidth')) {
      columnsWidth = JSON.parse(currentView.Parameters.find(param => param.Name === 'ColumnsWidth').Value);
    }

    // Get Columns Headers
    const columnHeaders = this.getColumnHeaders();

    // Auto Generated Columns
    if(this.grid) {
      // Clean Grid columns (exclude column Index)
      this.grid.columns = [];

      // Clean Grid Filter & Sort
      this.grid.clearFiltering();
      this.grid.clearSorting();

      // Add Column Index
      if(blockType === 'Risks') {
        // Define Column object for Grid
        var colObj = {
          lockColumn: true,
          field: 'Index',
          headerTemplate: this.templateHeader,
          headerText: 'Index',
          visible: true,
          showInColumnChooser: false,
          allowEditing: false,
          template: this.templateColumnIndex,
          isPrimaryKey: false,
          width: '80'
        };

        this.grid.columns.push(colObj);
      }
      // Add Column Index in Block Meetings
      else if(blockType === 'Meetings') {
        // Define Column object for Grid
        var colObj = {
          lockColumn: true,
          field: 'Index',
          headerTemplate: this.templateHeader,
          headerText: 'M',
          visible: true,
          showInColumnChooser: false,
          allowEditing: false,
          textAlign: 'center',
          headerTextAlign: 'left',
          template: this.templateColumnMeetingIndex,
          isPrimaryKey: false,
          width: '70'
        };

        this.grid.columns.push(colObj);
      }

      // Loop through the columns to build and add them to the Grid
      for(let i=0; i < columns.length; i++) {
        let columnHeader, colObj;

        if(columnHeaders.find(columnHeader => columnHeader.FieldName === columns[i])) {
          // Get Column corresponding Column Header
          columnHeader = columnHeaders.find(columnHeader => columnHeader.FieldName === columns[i]);

          // Create Column object
          colObj = this.createColumn(columnHeader, columnsWidth);

          // Add the Column in the Grid (exclude column Index)
          if(columnHeader.FieldName !== 'Index') {
            this.grid.columns.push(colObj);
          }
        }
      }

      // Add Column Item_ID
      if(!columns.includes('Item_ID') && columnHeaders.find(columnHeader => columnHeader.FieldName === 'Item_ID')) {
        let columnHeader = columnHeaders.find(columnHeader => columnHeader.FieldName === 'Item_ID');

        // Define Column object for Grid
        var colObj = {
          lockColumn: false,
          field: columnHeader.FieldName,
          headerTemplate: this.templateHeader,
          headerText: columnHeader.Label,
          visible: false,
          showInColumnChooser: false,
          allowEditing: false,
          isPrimaryKey: true
        };

        this.grid.columns.push(colObj);
      }
      // Add Column Item_Type
      else if(!columns.includes('Item_Type') && columnHeaders.find(columnHeader => columnHeader.FieldName === 'Item_Type')) {
        let columnHeader = columnHeaders.find(columnHeader => columnHeader.FieldName === 'Item_Type');

        // Define Column object for Grid
        var colObj = {
          lockColumn: false,
          field: columnHeader.FieldName,
          headerTemplate: this.templateHeader,
          headerText: columnHeader.Label,
          visible: false,
          showInColumnChooser: false,
          allowEditing: false,
          isPrimaryKey: false
        };

        this.grid.columns.push(colObj);
      }

      this.grid.refreshColumns();

      // Apply Filters
      // if(currentView.Filters.length > 0) {
      //   // Get Current View Sort
      //   filters = currentView.Filters;

      //   filters.forEach(filter => {
      //     // If Filter Field is included in Columns list
      //     if(columns.includes(filter.Field)) {
      //       let suffix = "";

      //       // Add Suffix .Label to Object Fields {"Id": id, "Label": label} for Grid Component
      //       if(this.getColumnType(filter.Field) === 'Axe' || this.getColumnType(filter.Field) === 'Resource' || filter.Field === 'Meteo' || filter.Field === 'Trend' || filter.Field === 'Business_Line' || filter.FieldName === 'Project' || filter.Field === 'Workpackage' || filter.Field === 'Action' || filter.Field === 'Task' || filter.Field === 'Entity') {
      //         suffix = ".Label";
      //       }

      //       this.grid.filterByColumn(filter.Field + suffix, filter.Operator, filter.Items.split(','));
      //     }
      //   });
      // }
      // Apply Sort
      // if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Sort')) {
      //   // Get Current View Sort
      //   sort = currentView.Parameters.find(param => param.Name === 'Sort').Value;

      //   // If Sort Field is included in Columns list
      //   if(columns.includes(sort.split(' ')[0])) {
      //     let suffix = "";

      //     // Add Suffix .Label to Object Fields {"Id": id, "Label": label} for Grid Component
      //     if(this.getColumnType(sort.split(' ')[0]) === 'Axe' || this.getColumnType(sort.split(' ')[0]) === 'Resource' || sort.split(' ')[0] === 'Meteo' || sort.split(' ')[0] === 'Trend' || sort.split(' ')[0] === 'Business_Line' || sort.split(' ')[0] === 'Project' || sort.split(' ')[0] === 'Workpackage' || sort.split(' ')[0] === 'Action' || sort.split(' ')[0] === 'Task' || sort.split(' ')[0] === 'Entity') {
      //       suffix = ".Label";
      //     }

      //     this.grid.sortByColumn(sort.split(' ')[0] + suffix, sort.split(' ')[1]);
      //   }
      // }
    }
  }

  filterColumns(columnName) {
    // Call the event from the Parent component through the props
    this.props.onColumnsFilter(columnName);
  }

  sortColumn(columnName) {
    const currentView = this.state.currentView;

    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Sort')) {
      if(currentView.Parameters.find(param => param.Name === 'Sort').Value.split(',').find(sort => sort === columnName + " DESC")) {
        currentView.Parameters.find(param => param.Name === 'Sort').Value = columnName;
      }
      else if(currentView.Parameters.find(param => param.Name === 'Sort').Value.split(',').find(sort => sort === columnName + " Descending")) {
        currentView.Parameters.find(param => param.Name === 'Sort').Value = columnName;
      }
      else if(currentView.Parameters.find(param => param.Name === 'Sort').Value.split(',').find(sort => sort === columnName)) {
        currentView.Parameters.find(param => param.Name === 'Sort').Value = columnName + " DESC";
      }
      else if(currentView.Parameters.find(param => param.Name === 'Sort').Value.split(',').find(sort => sort === columnName + " Ascending")) {
        currentView.Parameters.find(param => param.Name === 'Sort').Value = columnName + " DESC";
      }
      else {
        currentView.Parameters.find(param => param.Name === 'Sort').Value = columnName;
      }
    }
    else {
      currentView.Parameters.push({ Name: 'Sort', Value: columnName, ViewFilterId: -1, ViewId: currentView.ViewId });
    }

    // Call the event from the Parent component through the props
    this.props.onColumnSort(currentView);
  }

  sortColumns(columnName) {
    // Call the event from the Parent component through the props
    this.props.onColumnsSort(columnName);
  }

  autofitColumn(columnName) {
    const blockType = this.state.blockType;
    const currentView = this.state.currentView;
    let columns = [], columnsWidth = [];

    columns.push(columnName);

    this.grid.autoFitColumns(columns);

    if(blockType !== 'Dependencies' && blockType !== 'NotificationsCenter' && blockType !== 'Views') {
      // Loop through the Grid columns to build CurrentView Columns Width
      for(let i=0; i < this.grid.columns.length; i++) {
        // Remove Suffix .Label to Object Fields {"Id": id, "Label": label} for Database
        const columnName = this.grid.columns[i].field.replace(".Label", "");

        columnsWidth.push({ Name: columnName, Width: this.grid.columns[i].width });
      }

      // Update Current View Columns Width
      if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'ColumnsWidth')) {
        currentView.Parameters.find(param => param.Name === 'ColumnsWidth').Value = JSON.stringify(columnsWidth);
      }
      // Create Current View Columns Width
      else {
        currentView.Parameters.push({ Name: 'ColumnsWidth', Value: JSON.stringify(columnsWidth), ViewFilterId: -1, ViewId: currentView.ViewId });
      }

      // Add Modify attribute in CurrentView
      currentView["Modify"] = true;

      // Call the event from the Parent component through the props with view value
      this.props.onViewChange(currentView);
    }
  }

  autofitAllColumns() {
    const blockType = this.state.blockType;
    const currentView = this.state.currentView;
    let columnsWidth = [];

    this.grid.autoFitColumns();

    if(blockType !== 'Dependencies' && blockType !== 'NotificationsCenter' && blockType !== 'Views') {
      // Loop through the Grid columns to build CurrentView Columns Width
      for(let i=0; i < this.grid.columns.length; i++) {
        // Remove Suffix .Label to Object Fields {"Id": id, "Label": label} for Database
        const columnName = this.grid.columns[i].field.replace(".Label", "");

        columnsWidth.push({ Name: columnName, Width: this.grid.columns[i].width });
      }

      // Update Current View Columns Width
      if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'ColumnsWidth')) {
        currentView.Parameters.find(param => param.Name === 'ColumnsWidth').Value = JSON.stringify(columnsWidth);
      }
      // Create Current View Columns Width
      else {
        currentView.Parameters.push({ Name: 'ColumnsWidth', Value: JSON.stringify(columnsWidth), ViewFilterId: -1, ViewId: currentView.ViewId });
      }

      // Add Modify attribute in CurrentView
      currentView["Modify"] = true;

      // Call the event from the Parent component through the props with view value
      this.props.onViewChange(currentView);
    }
  }

  resizeStop(args) {
    const blockType = this.state.blockType;
    const currentView = this.state.currentView;
    let columnsWidth = [];

    if(blockType !== 'Dependencies' && blockType !== 'NotificationsCenter' && blockType !== 'Views') {
      // Loop through the Grid columns to build CurrentView Columns Width
      for(let i=0; i < this.grid.columns.length; i++) {
        // Remove Suffix .Label to Object Fields {"Id": id, "Label": label} for Database
        const columnName = this.grid.columns[i].field.replace(".Label", "");

        columnsWidth.push({ Name: columnName, Width: this.grid.columns[i].width });
      }

      // Update Current View Columns Width
      if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'ColumnsWidth')) {
        currentView.Parameters.find(param => param.Name === 'ColumnsWidth').Value = JSON.stringify(columnsWidth);
      }
      // Create Current View Columns Width
      else {
        currentView.Parameters.push({ Name: 'ColumnsWidth', Value: JSON.stringify(columnsWidth), ViewFilterId: -1, ViewId: currentView.ViewId });
      }

      // Add Modify attribute in CurrentView
      currentView["Modify"] = true;

      // Call the event from the Parent component through the props with view value
      this.props.onViewChange(currentView);
    }
  }

  addView(viewId) {
    // Call the event from the Parent component through the props with view to add
    this.props.onViewAdd(viewId);
  }

  editAxe(itemId, itemType, columnName, oldValue, newValue) {
    // Call the event from the Parent component through the props
    this.props.onTableUpdate(itemId, itemType, columnName, oldValue, newValue);

    // if(newValue != oldValue) {
    //   // Call the event from the Parent component through the props
    //   this.props.onTableUpdate(itemId, itemType, columnName, oldValue, newValue);
    // }
  }

  editColor(itemId, itemType, columnName, oldValue, newValue) {
    // Call the event from the Parent component through the props
    this.props.onTableUpdate(itemId, itemType, columnName, oldValue, newValue);
  }

  editObjectTable(itemId, itemType, columnName, oldValue, newValue) {
    // Call the event from the Parent component through the props
    this.props.onTableUpdate(itemId, itemType, columnName, oldValue, newValue);
  }

  onBooleanColumnClick(args) {
    // Click on Table element e-rowcell
    if(args.target.closest('.e-rowcell')) {
      const classList = args.target.classList;
      const element = args.target.id;

      // Get element informations to build request
      const split = element.split(' ');
      const itemType = split[1];
      const itemId = split[0].substring(1);
      const columnName = split[2];
      const allowEditing = split[3];

      let oldValue, newValue;

      // Check if Editing is allowed
      if(allowEditing === "true") {
        // Check if Click on Boolean element
        if(classList.contains('boolean')) {
          // Get Boolean new value
          if(classList.contains('iconBooleanTrue')) {
            oldValue = "True";
            newValue = "False";
          }
          else if(classList.contains('iconBooleanFalse')) {
            oldValue = "False";
            newValue = "True";
          }
        }
        else if(classList.contains('rating')) {
          // Get Boolean old and new value
          oldValue = split[4];
          newValue = split[5];
        }

        if(newValue != oldValue) {
          // Call the event from the Parent component through the props
          this.props.onTableUpdate(itemId, itemType, columnName, oldValue, newValue);
        }
      }

      this.tableClicked = false;
    }
  }

  updateDatasource(modification, propagations, warnings) {
    const columnName = modification.ColumnName;
    const newValue = modification.NewValue;
    let modificationId, currentRow, index;
    let applyModification = false;

    if(modification.ItemType === 'AgileBoard') {
      modificationId = 'O'.concat(modification.ItemId);
    }
    else {
      modificationId = modification.ItemType.substring(0,1).concat(modification.ItemId);
    }

    // Clone Datasource
    let dataSource = JSON.parse(JSON.stringify(this.grid.dataSource));

    // Find current Row to apply Modification
    if(dataSource.find(row => row['Item_ID'] === modificationId)) {
      currentRow = dataSource.find(row => row['Item_ID'] === modificationId);
    }

    // Find Index of current Row in visible Records
    // if(this.grid.getVisibleRecords().find(row => row['Item_ID'] === modificationId)) {
    //   index = this.grid.getVisibleRecords().findIndex(row => row['Item_ID'] === modificationId);
    // }

    // Get Column Type
    const columnType = this.getColumnType(columnName);

    // Build Updated Rows
    // let updatedRows = [];

    // Need to Update Datasource for these Columns (Status & StartDate & EndDdate => Timeline, Impact & Probability => Risk Index)
    if(currentRow && (columnType === 'Axe' || columnType === 'AxisTable' || columnType === 'Boolean' || columnType === 'Date' || columnType === 'Formula' || columnType === 'HTML' || columnType === 'Location' || columnType === 'Rating' || columnType === 'Resource' || columnType === 'ResourceTable' || columnName === 'Meteo' || columnName === 'Trend' || columnName === 'StartDate' || columnName === 'EndDate' || columnName === 'BackColor' || columnName === 'ForeColor')) {
      // Parse Object for Axes
      if(columnType === 'Axe' || columnType === 'AxisTable' || columnType === 'Location' || columnType === 'Resource' || columnType === 'ResourceTable' || columnName === 'Meteo' || columnName === 'Trend') {
        // Update Current Row Column
        currentRow[columnName] = JSON.parse(newValue);

        // if(this.grid) {
        //   this.grid.updateRow(index, currentRow);
        // }

        // if(this.grid) {
        //   this.grid.updateCell(index, columnName, JSON.parse(newValue));
        // }
      }
      // Parse Date
      else if(columnType === 'Date') {
        // Update Current Row Column
        if(newValue) {
          currentRow[columnName] = new Date(newValue);
        }
        else {
          currentRow[columnName] = "";
        }

        // if(newValue) {
        //   this.grid.updateCell(index, columnName, new Date(newValue));
        // }
        // else {
        //   this.grid.updateCell(index, columnName, "");
        // }

        // if(newValue) {
        //   this.grid.setCellValue(modificationId, columnName, new Date(newValue));
        // }
        // else {
        //   this.grid.setCellValue(modificationId, columnName, "");
        // }
      }
      else {
        // Update Current Row Column
        currentRow[columnName] = newValue;

        // if(this.grid) {
        //   this.grid.updateRow(index, currentRow);
        // }

        // if(this.grid) {
        //   this.grid.updateCell(index, columnName, newValue);
        // }
      }

      // Add updated Row
      // updatedRows.push({ "Index": index, "Row": currentRow });

      // Specific case for Text Editor
      if(columnType === 'HTML') {
        this.setState({ rowItemValue: newValue });
      }

      applyModification = true;
    }

    // Apply Propagations in Table DataSource
    propagations.forEach(propagation => {
      const propagationId = propagation.ToItemType.substring(0,1) + propagation.ToItemId;
      const propagationName = propagation.ColumnName;
      const propagationValue = propagation.NewValue;
      let propagationRow, propagationIndex;

      // Get Propagation Type
      const propagationType = this.getColumnType(propagationName);

      // Check if we find Column to apply Propagation
      if(this.grid.columns.find(column => column.field.replace(".Label", "") === propagationName)) {
        // Find propagation Row to apply Modification
        if(dataSource.find(row => row['Item_ID'] === propagationId)) {
          propagationRow = dataSource.find(row => row['Item_ID'] === propagationId);
        }

        // Find Index of propagation Row in visible Records
        // if(this.grid.getVisibleRecords().find(row => row['Item_ID'] === propagationId)) {
        //   propagationIndex = this.grid.getVisibleRecords().findIndex(row => row['Item_ID'] === propagationId);
        // }

        if(propagationRow) {
          // Parse Object for Axes
          if(propagationType === 'Axe' || propagationType === 'AxisTable'|| propagationType === 'Resource' || propagationType === 'ResourceTable' || propagationName === 'Meteo' || propagationName === 'Trend') {
            // Update Propagation Row Column
            propagationRow[propagationName] = JSON.parse(propagationValue);
          }
          // Parse Date
          else if(propagationType === 'Date') {
            // Update Propagation Row Column
            if(propagationValue) {
              propagationRow[propagationName] = new Date(propagationValue);
            }
            else {
              propagationRow[propagationName] = "";
            }
          }
          else {
            // Update Propagation Row Column
            propagationRow[propagationName] = propagationValue;
          }

          // Add updated Row
          // if(!updatedRows.find(row => row.Index === propagationIndex)) {
          //   updatedRows.push({ "Index": propagationIndex, "Row": propagationRow });
          // }

          applyModification = true;
        }
      }
    });

    // Apply Warnings in Table DataSource
    if(currentRow) {
      let warningMessage = '';

      if(warnings.length > 0) {
        warningMessage = warnings.join(' | ');
      }

      if(currentRow['WarningMessage'] && currentRow['WarningMessage'] !== warningMessage) {
        // Update Current Row Warning
        currentRow['WarningMessage'] = warningMessage;

        // Add updated Row
        // if(!updatedRows.find(row => row.Index === index)) {
        //   updatedRows.push({ "Index": index, "Row": currentRow });
        // }

        applyModification = true;
      }
    }
    
    // Apply Update Row on Updated Rows
    // updatedRows.forEach(updatedRows => {
    //   if(this.grid) {
    //     try{
    //       this.grid.updateRow(updatedRows.Index, updatedRows.Row);
    //     }
    //     catch(e) {
    //       console.log('erreur update row');
    //       // console.log(e);
    //       this.grid.dataSource = dataSource;
    //       return;
    //     }
    //   }
    // });

    // Update Datasource
    if(applyModification) {
      this.grid.dataSource = dataSource;
    }
  }

  // updateDatasource(modification, propagations, warnings) {
  //   let itemType = modification.ItemType;
  //   let itemId = modification.ItemId;

  //   // Clone Datasource
  //   let dataSource = JSON.parse(JSON.stringify(this.grid.dataSource));

  //   dataSource.forEach(row => {
  //     if(row.Item_Type === itemType && row.Item_ID.substring(1) == itemId) {
  //       // Apply Modification (fix for Timetracking)
  //       // row[modification.ColumnName] = modification.NewValue;

  //       // Update Modified Row with Warnings
  //       if(warnings.length > 0) {
  //         if(row.WarningMessage) {
  //           row['WarningMessage'] = warnings;
  //         }
  //       }
  //       else {
  //         if(row.WarningMessage) {
  //           row['WarningMessage'] = "";
  //         }
  //       }
  //     }

  //     // Get Row Propagations
  //     if(propagations.length > 0) {
  //       let props = this.getPropagations(propagations, row.Item_Type, row.Item_ID.substring(1));

  //       // Update Row with Propagations NewValue
  //       props.forEach(prop => {
  //         row[prop.ColumnName] = prop.NewValue;
  //       });
  //     }
  //   });

  //   // Update Datasource
  //   this.grid.dataSource = dataSource;
  // }

  cancelModification(modification) {
    const columnName = modification.ColumnName;
    const oldValue = modification.OldValue;
    let modificationId, currentRow, index;
    let applyModification = false;

    if(modification.ItemType === 'AgileBoard') {
      modificationId = 'O'.concat(modification.ItemId);
    }
    else {
      modificationId = modification.ItemType.substring(0,1).concat(modification.ItemId);
    }

    // Clone Datasource
    let dataSource = JSON.parse(JSON.stringify(this.grid.dataSource));

    // Find current Row to apply Modification
    if(dataSource.find(row => row['Item_ID'] === modificationId)) {
      currentRow = dataSource.find(row => row['Item_ID'] === modificationId);
    }

    // Find Index of current Row in visible Records
    // if(this.grid.getVisibleRecords().find(row => row['Item_ID'] === modificationId)) {
    //   index = this.grid.getVisibleRecords().findIndex(row => row['Item_ID'] === modificationId);
    // }

    // Get Column Type
    const columnType = this.getColumnType(columnName);

    if(currentRow && columnType !== 'Axe' && columnType !== 'AxisTable' && columnType !== 'HTML' && columnType !== 'Resource' && columnType !== 'ResourceTable' && columnName !== 'Meteo' && columnName !== 'Trend') {
      // Parse Date
      if(columnType === 'Date') {
        // Update Current Row Column with old Value
        if(oldValue) {
          currentRow[columnName] = new Date(oldValue);
        }
        else {
          currentRow[columnName] = "";
        }
      }
      else {
        // Update Current Row Column with old Value
        currentRow[columnName] = oldValue;
      }

      // if(this.grid) {
      //   this.grid.updateRow(index, currentRow);
      // }

      applyModification = true;
    }

    // Update Datasource
    if(applyModification) {
      this.grid.dataSource = dataSource;
    }
  }

  // cancelModification(modification) {
  //   let itemType = modification.ItemType;
  //   let itemId = modification.ItemId;

  //   // Clone Datasource
  //   let dataSource = JSON.parse(JSON.stringify(this.grid.dataSource));

  //   dataSource.forEach(row => {
  //     // Update Modified Row with OldValue
  //     if(row.Item_Type === itemType && row.Item_ID.substring(1) == itemId) {
  //       row[modification.ColumnName] = modification.OldValue;
  //     }
  //   });

  //   // Update Datasource
  //   this.grid.dataSource = dataSource;
  // }

  addRowsInDatasource(rows) {
    const currentView = this.state.currentView;

    // Get View corresponding level
    const level = this.getCurrentViewLevel(currentView);

    // Get Index to Add new Row
    // const index = this.grid.dataSource.length;

    if(this.grid) {
      // if(this.grid.dataSource.length < 2) {
      //   // Clone Datasource
      //   let dataSource = JSON.parse(JSON.stringify(this.grid.dataSource));

      //   // Add Rows in the Datasource
      //   rows.forEach(row => {
      //     if(row.Cells.find(cell => cell.ColumnName === 'Item_Type').Value === level) {
      //       dataSource.push(this.buildDatasourceRow(row));
      //     }
      //   });

      //   // Update Datasource
      //   this.grid.dataSource = dataSource;
      // }
      // else {
        // rows.forEach(row => {
        //   if(row.Cells.find(cell => cell.ColumnName === 'Item_Type').Value === level) {
        //     this.grid.addRecord(this.buildDatasourceRow(row), index - 1, 'Below');
  
        //     // if() {
        //     //   // Add Rows in the Datasource
        //     //   this.grid.dataSource.push(this.buildDatasourceRow(row));
        //     // }
        //   }
        // });
      // }
    }

    if(this.grid) {
      rows.forEach(row => {
        if(level === 'AxisValue' || row.Cells.find(cell => cell.ColumnName === 'Item_Type').Value === level) {
          this.grid.addRecord(this.buildDatasourceRow(row), 0, 'Top');
          // this.grid.addRecord(this.buildDatasourceRow(row), 0, 'Above');
        }
      });
    }
  }

  buildDatasourceRow(row) {
    const blockType = this.state.blockType;
    const rows = this.state.rows;
    let rowDatasource = {};

    rowDatasource = row.Cells.reduce((acc, item) => {
      // Format Dates
      if(item.FieldType === 'Date' && item.Value) {
        acc[item.ColumnName] = new Date(item.Value);
      }
      // Format Meteo/Trend
      else if((item.ColumnName === 'Meteo' || item.ColumnName === 'Trend') && item.Value) {
        acc[item.ColumnName] = JSON.parse(item.Value);
      }
      // Format Objects
      else if(item.FieldType === 'Object' && item.Value) {
        acc[item.ColumnName] = JSON.parse(item.Value);
      }
      // Format Object Tables (Decisions/HashTags)
      else if(item.FieldType === 'ObjectTable') {
        if(item.Value) {
          acc[item.ColumnName] = JSON.parse(item.Value);
        }
        else {
          acc[item.ColumnName] = [];
        }
      }
      // Format Collapse Boolean
      else if(item.ColumnName === 'isCollapsed') {
        if(item.Value === 'False') {
          acc['isExpanded'] = true;
        }
        else if(item.Value === 'True') {
          acc['isExpanded'] = false;
        }
      }
      // Add the Column Name/Values to the reduced Table
      else if(item.ColumnName !== 'Parent_ID') {
        acc[item.ColumnName] = item.Value;
      }

      // Add Index column inside row for Risks Matrix
      if(blockType === 'Risks') {
        acc['Index'] = rows.length + 1;
      }
      
      return acc;
    }, {});

    return rowDatasource;
  }

  confirmDelete() {
    const blockType = this.state.blockType;
    const deleteId = this.state.deleteId;
    const rowItemId = this.state.rowItemId;
    const rowItemType = this.state.rowItemType;

    if(blockType !== 'DeleteBin') {
      // Call the event from the Parent component through the props
      this.props.onItemDelete(rowItemId, rowItemType);
    }
    else {
      // Call the event from the Parent component through the props
      this.props.onItemDelete(deleteId);
    }

    this.setState({ confirm: false });
  }

  cancelDelete() {
    this.setState({ confirm: false });
  }

  deleteRowFromComponent(modification) {
    const deleteId = modification.ItemType.substring(0,1).concat(modification.ItemId);
    let indexToDelete = -1;

    // Clone Datasource
    let dataSource = JSON.parse(JSON.stringify(this.grid.dataSource));
    
    if(dataSource.find(row => row.Item_ID === deleteId)) {
      indexToDelete = dataSource.findIndex(row => row.Item_ID === deleteId);
    }

    // Delete Row in the Datasource
    if(indexToDelete >= 0) {
      dataSource.splice(indexToDelete, 1);
    }

    // Update Datasource
    this.grid.dataSource = dataSource;
  }

  deleteRowInDatasource(modification) {
    // const deleteId = modification.ItemType.substring(0,1).concat(modification.ItemId);
    // let indexToDelete = -1;

    // Clone Datasource
    // let dataSource = JSON.parse(JSON.stringify(this.grid.dataSource));
    
    // if(dataSource.find(row => row.Item_ID === deleteId)) {
    //   indexToDelete = dataSource.findIndex(row => row.Item_ID === deleteId);
    // }

    // Delete Row in the Datasource
    // if(indexToDelete >= 0) {
    //   dataSource.splice(indexToDelete, 1);
    // }

    // Delete selected Row
    if(this.grid) {
      this.grid.deleteRecord();
    }

    // Update Datasource
    // this.grid.dataSource = dataSource;
  }

  deleteAllRowsInDatasource() {
    // Reset DataSource
    if(this.grid) {
      this.grid.dataSource = [];
    }

    // Destroy Table
    // if(this.grid) {
    //   this.grid.destroy();
    // }
  }

  restoreRowInDatasource(deleteId) {
    // Delete selected Row
    if(this.grid) {
      this.grid.deleteRecord();
    }
  }

  // Format Cells value for Export
  excelQueryCellInfo(args) {
    // Dates
    if(args.value && this.getColumnType(args.column.field) === 'Date') {
      args.value = new Date(args.value).toLocaleDateString();
    }
    // Percentage
    else if(this.getColumnType(args.column.field) === 'Percentage') {
      if(args.value) {
        args.value = args.value.toFixed(0) + "%";
      }
      else {
        args.value = "0%";
      }
    }
    // Objects
    else if(args.value && ((this.getColumnType(args.column.field) === 'Axe' && args.column.field !== 'HashTag') || this.getColumnType(args.column.field) === 'Resource' || this.getColumnType(args.column.field) === 'Location' || args.column.field === 'Meteo' || args.column.field === 'Trend' || args.column.field === 'Business_Line' || args.column.field === 'Project' || args.column.field === 'Workpackage' || args.column.field === 'Action' || args.column.field === 'Task' || args.column.field === 'Entity')) {
      args.value = args.value.Label;
    }
    // Table Objects
    else if(args.value && (this.getColumnType(args.column.field) === 'AxisTable' || this.getColumnType(args.column.field) === 'ResourceTable' || this.getColumnType(args.column.field) === 'Files' || args.column.field === 'HashTag' || args.column.field === 'Rights')) {
      let string = "";

      args.value.forEach((value, index) => {
        if(index < args.value.length - 1) {
          string = string.concat(value.Label, ", ");
        }
        else {
          string = string.concat(value.Label);
        }
      });

      args.value = string;
    }
  }
  
  exportCSV() {
    const itemTitle = this.state.itemTitle;
    const blockType = this.state.blockType;
    const fileName = "Export " + blockType + " " + itemTitle + ".csv";

    if(this.grid) {
      // Define Export File Name
      let csvExportProperties = { fileName: fileName };

      this.grid.csvExport(csvExportProperties);
    }
  }

  exportXLSX() {
    const itemTitle = this.state.itemTitle;
    const blockType = this.state.blockType;
    const fileName = "Export " + blockType + " " + itemTitle + ".xlsx";

    if(this.grid) {
      // Define Export File Name
      let excelExportProperties = { fileName: fileName };
      
      this.grid.excelExport(excelExportProperties);
    }
  }

  searchItem(search) {
    if(this.grid) {
      this.grid.search(search);
    }
  }

  popupTextEditor() {
    const { language, itemId, itemType, blockType, rowItemId, rowItemType, rowItemTitle, rowItemColumn, rowItemLabel, rowItemValue } = this.state;
    
    return (<div className="textEditorPopup">
      <div className="textEditorInnerPopup">
        <div className="flex flex-column">
          <div className="flex inline-flex">
            {/* Title */}
            {language === 'English' && <div className="flex-start inline-flex mb10 width100p">
              <div className="auditLabel">{rowItemLabel}</div>
              <div className="auditLabelYellow ml5">{rowItemTitle}</div>
            </div>}
            {language === 'Français' && <div className="flex-start inline-flex mb10 width100p">
              <div className="auditLabelYellow">{rowItemTitle}</div>
              <div className="auditLabel ml5">{rowItemLabel}</div>
            </div>}

            {/* Close Popup */}
            <div className="flex-end">
              <div className="iconClear icons cursor" onClick={() => this.closeTextEditorPopup()}></div>
            </div>
          </div>

          {/* Text Editor Component */}
          <TextEditor ItemId={itemId} ItemType={itemType} BlockType={blockType} RowItemId={rowItemId} RowItemType={rowItemType} FieldName={rowItemColumn} Label={rowItemLabel} Value={rowItemValue} Edit={true} Editable={true} onTextChange={this.updateDatasource} onTextUpdate={this.props.onTableUpdate} onPopupClose={this.closeTextEditorPopup}></TextEditor>
        </div>
      </div>
    </div>);
  }

  popupTextFormulaEditor() {
    const { language, itemId, itemType, blockType, rowItemId, rowItemType, rowItemTitle, rowItemColumn, rowItemLabel, rowItemValue } = this.state;
    
    return (<div className="textEditorPopup">
      <div className="textEditorInnerPopup">
        <div className="flex flex-column">
          <div className="flex inline-flex">
            {/* Title */}
            {language === 'English' && <div className="flex-start inline-flex mb10 width100p">
              <div className="auditLabel">{rowItemLabel}</div>
              <div className="auditLabelYellow ml5">{rowItemTitle}</div>
            </div>}
            {language === 'Français' && <div className="flex-start inline-flex mb10 width100p">
              <div className="auditLabelYellow">{rowItemTitle}</div>
              <div className="auditLabel ml5">{rowItemLabel}</div>
            </div>}

            {/* Close Popup */}
            <div className="flex-end">
              <div className="iconClear icons cursor" onClick={() => this.closeTextFormulaEditorPopup()}></div>
            </div>
          </div>

          {/* Text Formula Editor Component */}
          <TextFormulaEditor ItemId={itemId} ItemType={itemType} BlockType={blockType} RowItemId={rowItemId} RowItemType={rowItemType} FieldName={rowItemColumn} Label={rowItemLabel} onTextChange={this.updateDatasource} onTextUpdate={this.props.onTableUpdate} onPopupClose={this.closeTextFormulaEditorPopup}></TextFormulaEditor>
        </div>
      </div>
    </div>);
  }

  closeAuditPopup() {
    this.setState({ auditPopup: false });
  }

  // closeDependenciesPopup() {
  //   this.setState({ dependenciesPopup: false });
  // }

  closeFilesPopup(files, rowItemId, rowItemType, rowItemColumn) {
    let currentRow;
    let cellData = [];

    // Clone Datasource
    let dataSource = JSON.parse(JSON.stringify(this.grid.dataSource));

    if(dataSource.find(row => row['Item_ID'] === rowItemType.substring(0,1).concat(rowItemId))) {
      currentRow = dataSource.find(row => row['Item_ID'] === rowItemType.substring(0,1).concat(rowItemId));
    }

    files.forEach(file => {
      cellData.push({ Id: file.CustomValueId, Label: file.FileName });
    });

    // Update Current Row Column
    if(currentRow) {
      currentRow[rowItemColumn] = cellData;
    }

    // Update Datasource
    this.grid.dataSource = dataSource;

    this.setState({ filesPopup: false });
  }

  closeRightsPopup(authorizedUsersList, rowItemId, rowItemType, rowItemColumn) {
    const language = this.state.language;
    let currentRow;
    let cellData = [];

    // Clone Datasource
    let dataSource = JSON.parse(JSON.stringify(this.grid.dataSource));

    // Find current Row to apply Modification
    if(rowItemType === 'AgileBoard' && dataSource.find(row => row['Item_ID'] === 'O'.concat(rowItemId))) {
      currentRow = dataSource.find(row => row['Item_ID'] === 'O'.concat(rowItemId));
    }
    else if(dataSource.find(row => row['Item_ID'] === rowItemType.substring(0,1).concat(rowItemId))) {
      currentRow = dataSource.find(row => row['Item_ID'] === rowItemType.substring(0,1).concat(rowItemId));
    }

    // Build 3 first Users
    authorizedUsersList.splice(0, 3).forEach(user => {
      cellData.push({ Id: user.UserId, Label: user.Name });
    });

    if(authorizedUsersList.length > 0) {
      if(authorizedUsersList.length === 1) {
        cellData.push({ Id: 0, Label: "+ " + authorizedUsersList.length + " " + Traduction.translate(language, 'other_user') });
      }
      else {
        cellData.push({ Id: 0, Label: "+ " + authorizedUsersList.length + " " + Traduction.translate(language, 'other_users') });
      }
    }

    // Update Current Row Column
    if(currentRow) {
      currentRow[rowItemColumn] = cellData;
    }

    // Update Datasource
    this.grid.dataSource = dataSource;

    this.setState({ rightsPopup: false });
  }

  closeTextEditorPopup() {
    this.setState({ textEditorPopup: false });
  }

  closeTextFormulaEditorPopup() {
    this.setState({ textFormulaEditorPopup: false });
  }

  // ----- ----- Validation Functions ----- -----
  nameValidation(args) {
    return getValue('value', args).length <= 100;
  }

  textValidation(args) {
    return getValue('value', args).length <= 100;
  }

  riskNameValidation(args) {
    return getValue('value', args).length <= 500;
  }

  riskCommentValidation(args) {
    return getValue('value', args).length <= 500;
  }

  numberValidation(args) {
    return getValue('value', args) >= 0;
  }

  progressValidation(args) {
    return (getValue('value', args) >= 0 && getValue('value', args) <= 100);
  }

  yearValidation(args) {
    return getValue('value', args) >= 2000;
  }

  // ----- ----- Format Functions ----- -----
  templateHeader(props) {
    const itemId = this.state.itemId;
    const itemType = this.state.itemType;
    const blockType = this.state.blockType;
    const guestLicence = this.state.guestLicence;

    // Get Column Name and Value (Remove Suffix .Label to Object Fields)
    const columnName = props.field.replace(".Label", "");
    const label = props.headerText;
    let editable = false, filter = false, sort = false;

    // Column Indicators
    if(columnName !== 'Index') {
      editable = this.getColumnEditable(this.getColumnEditionItemType(columnName));
      filter = this.getColumnFilter(columnName);
      sort = this.getColumnSort(columnName);
    }

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverColumnActions ItemId={itemId} ItemType={itemType} ColumnName={columnName} onColumnsFilter={this.filterColumns} onColumnsSort={this.sortColumns} onColumnAutofit={this.autofitColumn} onColumnsAutofit={this.autofitAllColumns}></PopoverColumnActions>
        </Popover.Content>
      </Popover>
    );

    if(blockType === 'AxisValues' || blockType === 'CustomFields' || blockType === 'DeleteBin') {
      return <div className={"flex space-between align-items-center" + (editable ? " columnHeaderEditable" : " columnHeaderNotEditable")}>
        <span className="flex-start" onClick={() => this.sortColumn(columnName)}>{label}</span>
      </div>;
    }
    else if(blockType === 'Dependencies' || blockType === 'Views') {
      return <div className={"flex space-between align-items-center" + (editable ? " columnHeaderEditable" : " columnHeaderNotEditable")}>
        <span className="flex-start">{label}</span>
      </div>;
    }
    else {
      return <div className={"flex space-between align-items-center" + (editable ? " columnHeaderEditable" : " columnHeaderNotEditable")}>
        <span className="flex-start" onClick={!guestLicence ? () => this.sortColumn(columnName) : null}>{label}</span>
        <div className="flex-end">
          {/* Column Indicators */}
          {filter && <span className={(editable ? "iconColumnFilter" : "iconColumnFilterGrey") + " iconsTemplateHeader mh5"}></span>}
          {sort === 'ascending' && <span className={(editable ? "iconArrowDown" : "iconArrowDownGrey") + " iconsTemplateHeader mh5"}></span>}
          {sort === 'descending' && <span className={(editable ? "iconArrowUp" : "iconArrowUpGrey") + " iconsTemplateHeader mh5"}></span>}
          {/* Column Actions Popover */}
          {!guestLicence && <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
            <span className={(editable ? "iconColumnActions" : "iconColumnActionsGrey") + " iconsTemplateHeader mh5"}></span>
          </OverlayTrigger>}
        </div>
      </div>;
    }
  }

  // ----- ----- Columns
  templateColumnAction(props) {
    let blockType = this.state.blockType;
    const itemId = props.Item_ID;
    const itemType = props.Item_Type;
    const action = props.Action;
    
    if(!props.column) {
      return null;
    }

    // Get Column Name (Remove Suffix .Label to Object Fields)
    const columnName = props.column.field.replace(".Label", "");

    const editionItemTypes = this.getColumnEditionItemType(columnName);
    const restrictedValues = this.getColumnRestrictedValues(columnName);

    // Replace BlockType by Details in Link for Timetraking
    if(blockType === 'TimeTracking') {
      blockType = "Details";
    }

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverEditAxe ItemId={itemId.substring(1)} ItemType={itemType} ColumnName={columnName} AxeValue={action} RestrictedValues={restrictedValues} onAxeEdit={this.editAxe}></PopoverEditAxe>
        </Popover.Content>
      </Popover>
    );

    if(action.Label === '- NA -') {
      // Editable with Popover
      if(editionItemTypes.includes(itemType)) {
        return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
          <div className="width100p"><div className="axe brd-dashed very-light-grey">{action.Label}</div></div>
        </OverlayTrigger>;
      }
      // Non Editable
      else {
        return <div className="axe brd-dashed very-light-grey">{action.Label}</div>;
      }
    }
    else if(action.Label) {
      // Editable with Popover
      if(editionItemTypes.includes(itemType)) {
        return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
          <div className="width100p">
            <div className="axe bordered blue">
              <a target="_self" href={`/Card/${columnName}/${action.Id}/${blockType}`}><span className="iconAction verysmallIcons mr5"></span></a>
              <span className="">{action.Label}</span>
            </div>
          </div>
        </OverlayTrigger>;
      }
      // Non Editable
      else {
        return <div className="axe bordered blue">
          <a target="_self" href={`/Card/${columnName}/${action.Id}/${blockType}`}><span className="iconAction verysmallIcons mr5"></span></a>
          <span className="">{action.Label}</span>
        </div>;
      }
    }
    else {
      return null;
    }
  }

  templateColumnActive(props) {
    const itemId = props.Item_ID;
    const itemType = props.Item_Type;

    if(!props.column) {
      return null;
    }

    // Get Column Name and Value
    const columnName = props.column.field;
    const value = props[columnName];

    const allowEditing = props.column.allowEditing;
    const id = itemId + " " + itemType + " " + columnName + " " + allowEditing;
    
    if(value == 'True') {
      return <div className="booleanTable">
        <div id={id} className="boolean iconBooleanTrue iconActiveTrue iconsTable"></div>
      </div>;
    }
    else if(value == 'False') {
      return <div className="booleanTable">
        <div id={id} className="boolean iconBooleanFalse iconActiveFalse iconsTable"></div>
      </div>;
    }
    else {
      return null;
    }
  }

  templateColumnAxisName(props) {
    const name = props.Name;
    let backColor = props.BackColor;
    let foreColor = props.ForeColor;
    let borderColor;

    if(!props.column) {
      return null;
    }

    if(name) {
      if(!backColor) {
        backColor = '#FFFFFF';
      }
      if(!foreColor) {
        foreColor = '#0088C7';
      }
      else {
        if((foreColor === '#FFFFFF' || foreColor === '#ffffff')) {
          borderColor = backColor;
        }
        else {
          borderColor = foreColor;
        }
      }

      return <div className="axe bordered" style={{ background: backColor, color: foreColor, borderColor: borderColor }}>{name}</div>;
    }
    else {
      return null;
    }
  }

  templateColumnAdmin(props) {
    const itemId = props.Item_ID;
    const itemType = props.Item_Type;

    if(!props.column) {
      return null;
    }
    
    // Get Column Name and Value
    const columnName = props.column.field;
    const value = props[columnName];

    const allowEditing = props.column.allowEditing;
    const id = itemId + " " + itemType + " " + columnName + " " + allowEditing;

    if(value == 'True') {
      return <div className="booleanTable">
        <div id={id} className="boolean iconBooleanTrue iconShieldGreen iconsTable"></div>
      </div>;
    }
    else if(value == 'False') {
      return <div className="booleanTable">
        <div id={id} className="boolean iconBooleanFalse iconShieldGrey iconsTable"></div>
      </div>;
    }
    else {
      return null;
    }
  }

  templateColumnBackColor(props) {
    const currentView = this.state.currentView;
    const itemId = props.Item_ID;
    const itemType = props.Item_Type;
    const backColor = props.BackColor;

    if(!props.column) {
      return null;
    }

    // Get Column Name (Remove Suffix .Label to Object Fields)
    const columnName = props.column.field.replace(".Label", "");

    const editionItemTypes = this.getColumnEditionItemType(columnName);

    // Get View corresponding level
    const level = this.getCurrentViewLevel(currentView);

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverEditColor ItemId={itemId.substring(1)} ItemType={itemType} ColumnName={columnName} ColorValue={backColor} onColorEdit={this.editColor}></PopoverEditColor>
        </Popover.Content>
      </Popover>
    );

    if(backColor) {
      // Editable with Popover
      if(editionItemTypes.includes(level)) {
        return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
          <div className="width100p"><div className="axisColor" style={{ background: backColor, border: '1px solid', borderColor: '#8E8E90' }}></div></div>
        </OverlayTrigger>;
      }
      // Non Editable
      else {
        return <div className="axe bordered blue">{backColor}</div>;
      }
    }
    else {
      // Editable with Popover
      if(editionItemTypes.includes(level)) {
        return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
          <div className="width100p"><div className="axisColor" style={{ background: '#FFFFFF', border: '1px solid', borderColor: '#8E8E90' }}></div></div>
        </OverlayTrigger>;
      }
      // Non Editable
      else {
        return <div className="axisColor" style={{ background: '#FFFFFF', border: '1px solid', borderColor: '#8E8E90' }}></div>;
      }
    }
  }

  templateColumnBudgetCode(props) {
    const blockType = this.state.blockType;
    const itemId = props.Item_ID;
    const itemType = props.Item_Type;
    const budgetCode = props.BudgetCode;
    
    if(!props.column) {
      return null;
    }

    // Get Column Name (Remove Suffix .Label to Object Fields)
    const columnName = props.column.field.replace(".Label", "");

    const editionItemTypes = this.getColumnEditionItemType(columnName);
    const restrictedValues = this.getColumnRestrictedValues(columnName);

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverEditAxe ItemId={itemId.substring(1)} ItemType={itemType} ColumnName={columnName} AxeValue={budgetCode} RestrictedValues={restrictedValues} onAxeEdit={this.editAxe}></PopoverEditAxe>
        </Popover.Content>
      </Popover>
    );

    if(budgetCode.Label === '- NA -') {
      // Editable with Popover
      if(editionItemTypes.includes(itemType)) {
        return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
          <div className="width100p"><div className="axe brd-dashed very-light-grey">{budgetCode.Label}</div></div>
        </OverlayTrigger>;
      }
      // Non Editable
      else {
        return <div className="axe brd-dashed very-light-grey">{budgetCode.Label}</div>;
      }
    }
    else if(budgetCode.Label) {
      // Editable with Popover
      if(editionItemTypes.includes(itemType)) {
        return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
          <div className="width100p">
            <div className="axe bordered blue">
              <span className="iconBudgetCode verysmallIcons mr5"></span>
              {/* <a target="_self" href={`/Card/${columnName}/${budgetCode.Id}/Home`}><span className="iconBudgetCode verysmallIcons mr5"></span></a> */}
              <span className="">{budgetCode.Label}</span>
            </div>
          </div>
        </OverlayTrigger>;
      }
      // Non Editable
      else {
        return <div className="axe bordered blue">
          <span className="iconBudgetCode verysmallIcons mr5"></span>
          {/* <a target="_self" href={`/Card/${columnName}/${budgetCode.Id}/Home`}><span className="iconBudgetCode verysmallIcons mr5"></span></a> */}
          <span className="">{budgetCode.Label}</span>
        </div>;
      }
    }
    else {
      return null;
    }
  }

  templateColumnBusinessLine(props) {
    let blockType = this.state.blockType;
    const itemId = props.Item_ID;
    const itemType = props.Item_Type;
    const businessLine = props.Business_Line;
    
    if(!props.column) {
      return null;
    }

    // Get Column Name (Remove Suffix .Label to Object Fields)
    const columnName = props.column.field.replace(".Label", "");

    const editionItemTypes = this.getColumnEditionItemType(columnName);
    const restrictedValues = this.getColumnRestrictedValues(columnName);

    // Replace BlockType by Details in Link for Timetraking
    if(blockType === 'TimeTracking') {
      blockType = "Details";
    }

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverEditAxe ItemId={itemId.substring(1)} ItemType={itemType} ColumnName={columnName} AxeValue={businessLine} RestrictedValues={restrictedValues} onAxeEdit={this.editAxe}></PopoverEditAxe>
        </Popover.Content>
      </Popover>
    );

    if(businessLine.Label === '- NA -') {
      // Editable with Popover
      if(editionItemTypes.includes(itemType)) {
        return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
          <div className="width100p"><div className="axe brd-dashed very-light-grey">{businessLine.Label}</div></div>
        </OverlayTrigger>;
      }
      // Non Editable
      else {
        return <div className="axe brd-dashed very-light-grey">{businessLine.Label}</div>;
      }
    }
    else if(businessLine.Label) {
      // Editable with Popover
      if(editionItemTypes.includes(itemType)) {
        return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
          <div className="width100p">
            <div className="axe bordered blue">
              <a target="_self" href={`/Card/${columnName}/${businessLine.Id}/${blockType}`}><span className="iconBusinessLine verysmallIcons mr5"></span></a>
              <span className="">{businessLine.Label}</span>
            </div>
          </div>
        </OverlayTrigger>;
      }
      // Non Editable
      else {
        return <div className="axe bordered blue">
          <a target="_self" href={`/Card/${columnName}/${businessLine.Id}/${blockType}`}><span className="iconBusinessLine verysmallIcons mr5"></span></a>
          <span className="">{businessLine.Label}</span>
        </div>;
      }
    }
    else {
      return null;
    }
  }

  templateColumnCardLevel(props) {
    const itemId = props.Item_ID;
    const itemType = props.Item_Type;

    if(!props.column) {
      return null;
    }

    // Get Column Name (Remove Suffix .Label to Object Fields)
    const columnName = props.column.field.replace(".Label", "");
    const value = props[columnName];

    const editionItemTypes = this.getColumnEditionItemType(columnName);
    const restrictedValues = this.getColumnRestrictedValues(columnName);

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverEditAxe ItemId={itemId.substring(1)} ItemType={itemType} ColumnName={columnName} AxeValue={value} RestrictedValues={restrictedValues} onAxeEdit={this.editAxe}></PopoverEditAxe>
        </Popover.Content>
      </Popover>
    );

    if(value && value.Id === 29) {
      // Editable with Popover
      if(editionItemTypes.includes(itemType)) {
        return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
          <div className="width100p"><div className="axe brd-dashed very-light-grey">{value.Label}</div></div>
        </OverlayTrigger>;
      }
      // Non Editable
      else {
        return <div className="axe brd-dashed very-light-grey">{value.Label}</div>;
      }
    }
    else if(value && value.Label) {
      // Editable with Popover
      if(editionItemTypes.includes(itemType)) {
        return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
          <div className="width100p">
            {value.Id === 1 && <div className="axeTable"><span className="iconBusinessLine verysmallIcons mr5" alt="Business Line"/><span className="">{value.Label}</span></div>}
            {value.Id === 2 && <div className="axeTable"><span className="iconProject verysmallIcons mr5" alt="Project"/><span className="">{value.Label}</span></div>}
            {value.Id === 3 && <div className="axeTable"><span className="iconWorkpackage verysmallIcons mr5" alt="Workpackage"/><span className="">{value.Label}</span></div>}
            {value.Id === 4 && <div className="axeTable"><span className="iconAction verysmallIcons mr5" alt="Action"/><span className="">{value.Label}</span></div>}
            {value.Id === 5 && <div className="axeTable"><span className="iconTask verysmallIcons mr5" alt="Task"/><span className="">{value.Label}</span></div>}
            {value.Id === 6 && <div className="axeTable"><span className="iconRisks verysmallIcons mr5" alt="Risk"/><span className="">{value.Label}</span></div>}
            {value.Id === 7 && <div className="axeTable"><span className="iconIssues verysmallIcons mr5" alt="Issue"/><span className="">{value.Label}</span></div>}
            {value.Id === 8 && <div className="axeTable"><span className="iconDecisions verysmallIcons mr5" alt="Decision"/><span className="">{value.Label}</span></div>}
            {value.Id === 9 && <div className="axeTable"><span className="iconMeetings verysmallIcons mr5" alt="Meeting"/><span className="">{value.Label}</span></div>}
            {value.Id === 12 && <div className="axeTable"><span className="iconResource verysmallIcons mr5" alt="Resource"/><span className="">{value.Label}</span></div>}
            {value.Id === 13 && <div className="axeTable"><span className="iconEntity verysmallIcons mr5" alt="Entity"/><span className="">{value.Label}</span></div>}
            {value.Id === 17 && <div className="axeTable"><span className="iconBudgetCode verysmallIcons mr5" alt="BudgetCode"/><span className="">{value.Label}</span></div>}
          </div>
        </OverlayTrigger>;
      }
      // Non Editable
      else {
        return <div className="inline-flex">
          {value.Id === 1 && <div className="axeTable"><span className="iconBusinessLine verysmallIcons mr5" alt="Business Line"/><span className="">{value.Label}</span></div>}
          {value.Id === 2 && <div className="axeTable"><span className="iconProject verysmallIcons mr5" alt="Project"/><span className="">{value.Label}</span></div>}
          {value.Id === 3 && <div className="axeTable"><span className="iconWorkpackage verysmallIcons mr5" alt="Workpackage"/><span className="">{value.Label}</span></div>}
          {value.Id === 4 && <div className="axeTable"><span className="iconAction verysmallIcons mr5" alt="Action"/><span className="">{value.Label}</span></div>}
          {value.Id === 5 && <div className="axeTable"><span className="iconTask verysmallIcons mr5" alt="Task"/><span className="">{value.Label}</span></div>}
          {value.Id === 6 && <div className="axeTable"><span className="iconRisks verysmallIcons mr5" alt="Risk"/><span className="">{value.Label}</span></div>}
          {value.Id === 7 && <div className="axeTable"><span className="iconIssues verysmallIcons mr5" alt="Issue"/><span className="">{value.Label}</span></div>}
          {value.Id === 8 && <div className="axeTable"><span className="iconDecisions verysmallIcons mr5" alt="Decision"/><span className="">{value.Label}</span></div>}
          {value.Id === 9 && <div className="axeTable"><span className="iconMeetings verysmallIcons mr5" alt="Meeting"/><span className="">{value.Label}</span></div>}
          {value.Id === 12 && <div className="axeTable"><span className="iconResource verysmallIcons mr5" alt="Resource"/><span className="">{value.Label}</span></div>}
          {value.Id === 13 && <div className="axeTable"><span className="iconEntity verysmallIcons mr5" alt="Entity"/><span className="">{value.Label}</span></div>}
          {value.Id === 17 && <div className="axeTable"><span className="iconBudgetCode verysmallIcons mr5" alt="BudgetCode"/><span className="">{value.Label}</span></div>}
        </div>;
      }
    }
    else {
      return null;
    }
  }

  templateColumnDataFreshness(props) {
    const dataFreshness = props.Data_Freshness;

    if(dataFreshness === "1") {
      return <div className="iconFullCircleGreen iconsTable"></div>;
    } 
    else if(dataFreshness === "2") {
      return <div className="iconFullCircleOrange iconsTable"></div>;
    } 
    else if(dataFreshness === "3") {
      return <div className="iconFullCircleRed iconsTable"></div>;
    }
    else if(dataFreshness === "4") {
      return <div className="iconCircleGrey iconsTable"></div>;
    }
    else {
      return null;
    }
  }

  templateColumnDecisions(props) {
    const decisions = props.Decisions;

    if(decisions) {
      return <div className="inline-flex">
        {decisions.map((decision, index) => {
          return <div key={index} className={"axe bordered blue" + (index < decisions.length - 1 ? " mr10" : "")}>
            <span className="iconDecisions verysmallIcons mr5"></span>
            <span className="">{decision.Label}</span>
          </div>
        })}
      </div>;
    }
    else {
      return null;
    }
  }

  templateColumnDependency(props) {
    if(!props.column) {
      return null;
    }

    // Get Column Field and Value
    const field = props.column.field;
    const value = props[field];
    let dependencies = value.split(',');

    if(value) {
      return <div className="">
        {dependencies.map((dependency, index) => {
          switch(value.substring(0,1)) {
            case 'B':
                return <div key={index} className={"inline-flex" + (index < dependencies.length - 1 ? " mr10" : "")}><span className="treeIcon iconBusinessLineWhite verysmallIcons" alt="Business Line"></span>{dependency}</div>;
            case 'P':
                return <div key={index} className={"inline-flex" + (index < dependencies.length - 1 ? " mr10" : "")}><span className="treeIcon iconProjectWhite verysmallIcons" alt="Project"></span>{dependency}</div>;
            case 'W':
                return <div key={index} className={"inline-flex" + (index < dependencies.length - 1 ? " mr10" : "")}><span className="treeIcon iconWorkpackageWhite verysmallIcons" alt="Workpackage"></span>{dependency}</div>;
            case 'A':
                return <div key={index} className={"inline-flex" + (index < dependencies.length - 1 ? " mr10" : "")}><span className="treeIcon iconActionWhite verysmallIcons" alt="Action"></span>{dependency}</div>;
            case 'T':
                return <div key={index} className={"inline-flex" + (index < dependencies.length - 1 ? " mr10" : "")}><span className="treeIconGrey iconTaskGrey verysmallIcons" alt="Task"></span>{dependency}</div>;
            default:
                return <div key={index} className={"inline-flex" + (index < dependencies.length - 1 ? " mr10" : "")}>{dependency}</div>;
          }
        })}
      </div>;
    }
    else {
      return null;
    }
  }

  templateColumnDependencyFromItemLabel(props) {
    const fromLabel = props.FromLabel;
    const fromItemId = props.From_Item_ID;

    if(fromLabel && fromItemId) {
      switch(fromItemId.substring(0,1)) {
        case 'B':
            return <div className=""><span className="treeIcon iconBusinessLineWhite verysmallIcons" alt="Business Line"></span>{fromLabel}</div>;
        case 'P':
            return <div className=""><span className="treeIcon iconProjectWhite verysmallIcons" alt="Project"></span>{fromLabel}</div>;
        case 'W':
            return <div className=""><span className="treeIcon iconWorkpackageWhite verysmallIcons" alt="Workpackage"></span>{fromLabel}</div>;
        case 'A':
            return <div className=""><span className="treeIcon iconActionWhite verysmallIcons" alt="Action"></span>{fromLabel}</div>;
        case 'T':
            return <div className=""><span className="treeIcon iconTaskWhite verysmallIcons" alt="Task"></span>{fromLabel}</div>;
        default:
            return <div className="">{fromLabel}</div>;
      }
    }
    else {
      return null;
    }
  }

  templateColumnDependencyToItemLabel(props) {
    const toLabel = props.ToLabel;
    const toItemId = props.To_Item_ID;

    if(toLabel && toItemId) {
      switch(toItemId.substring(0,1)) {
        case 'B':
            return <div className=""><span className="treeIcon iconBusinessLineWhite verysmallIcons" alt="Business Line"></span>{toLabel}</div>;
        case 'P':
            return <div className=""><span className="treeIcon iconProjectWhite verysmallIcons" alt="Project"></span>{toLabel}</div>;
        case 'W':
            return <div className=""><span className="treeIcon iconWorkpackageWhite verysmallIcons" alt="Workpackage"></span>{toLabel}</div>;
        case 'A':
            return <div className=""><span className="treeIcon iconActionWhite verysmallIcons" alt="Action"></span>{toLabel}</div>;
        case 'T':
            return <div className=""><span className="treeIcon iconTaskWhite verysmallIcons" alt="Task"></span>{toLabel}</div>;
        default:
            return <div className="">{toLabel}</div>;
      }
    }
    else {
      return null;
    }
  }

  templateColumnDependencyType(props) {
    const itemId = props.Item_ID;
    const itemType = props.Item_Type;
    const dependencyType = props.Dependency_Type;

    if(!props.column) {
      return null;
    }

    // Get Column Name (Remove Suffix .Label to Object Fields)
    const columnName = props.column.field.replace(".Label", "");

    const editionItemTypes = this.getColumnEditionItemType(columnName);
    const restrictedValues = this.getColumnRestrictedValues(columnName);

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverEditAxe ItemId={itemId.substring(1)} ItemType={itemType} ColumnName={columnName} AxeValue={dependencyType} RestrictedValues={restrictedValues} onAxeEdit={this.editAxe}></PopoverEditAxe>
        </Popover.Content>
      </Popover>
    );

    // Editable with Popover
    if(editionItemTypes.includes(itemType)) {
      // Dependency Type (0: EndToStart, 1: EndToEnd, 2: StartToStart, 3: Miroir)
      switch(dependencyType.Id) {
        case 0:
            return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
              <div className="width100p"><span className="treeIcon iconDependencyEndToStart verysmallIcons" alt=""></span>{dependencyType.Label}</div>
            </OverlayTrigger>;
        case 1:
            return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
              <div className="width100p"><span className="treeIcon iconDependencyEndToEnd verysmallIcons" alt=""></span>{dependencyType.Label}</div>
            </OverlayTrigger>;
        case 2:
            return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
              <div className="width100p"><span className="treeIcon iconDependencyStartToStart verysmallIcons" alt=""></span>{dependencyType.Label}</div>
            </OverlayTrigger>;
        case 3:
            return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
              <div className="width100p"><span className="treeIcon iconDependencyMirror verysmallIcons" alt=""></span>{dependencyType.Label}</div>
            </OverlayTrigger>;
      }
    }
    // Non Editable
    else {
      switch(dependencyType.Id) {
        case 0:
            return <div className=""><span className="treeIcon iconDependencyEndToStart verysmallIcons" alt=""></span>{dependencyType.Label}</div>;
        case 1:
            return <div className=""><span className="treeIcon iconDependencyEndToEnd verysmallIcons" alt=""></span>{dependencyType.Label}</div>;
        case 2:
            return <div className=""><span className="treeIcon iconDependencyStartToStart verysmallIcons" alt=""></span>{dependencyType.Label}</div>;
        case 3:
            return <div className=""><span className="treeIcon iconDependencyMirror verysmallIcons" alt=""></span>{dependencyType.Label}</div>;
      }
    }
  }

  templateColumnEmail(props) {
    const email = props.Email;

    if(email) {
      return <a href={"mailto:" + email} className="emailTable">{email}</a>;
      // return <div onClick={(e) => {window.location = "mailto:" + email; e.preventDefault();}} className="emailTable">{email}</div>
    }
    else {
      return null;
    }
  }

  templateColumnEntity(props) {
    let blockType = this.state.blockType;
    const itemId = props.Item_ID;
    const itemType = props.Item_Type;
    const entity = props.Entity;
    
    if(!props.column) {
      return null;
    }

    // Get Column Name (Remove Suffix .Label to Object Fields)
    const columnName = props.column.field.replace(".Label", "");

    const editionItemTypes = this.getColumnEditionItemType(columnName);
    const restrictedValues = this.getColumnRestrictedValues(columnName);

    // Replace BlockType by Details in Link for Timetraking
    if(blockType === 'TimeTracking') {
      blockType = "Details";
    }

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverEditAxe ItemId={itemId.substring(1)} ItemType={itemType} ColumnName={columnName} AxeValue={entity} RestrictedValues={restrictedValues} onAxeEdit={this.editAxe}></PopoverEditAxe>
        </Popover.Content>
      </Popover>
    );

    if(entity.Label === '- NA -' || entity.Label === '- Not assigned -') {
      // Editable with Popover
      if(editionItemTypes.includes(itemType)) {
        return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
          <div className="width100p"><div className="axe brd-dashed very-light-grey">{entity.Label}</div></div>
        </OverlayTrigger>;
      }
      // Non Editable
      else {
        return <div className="axe brd-dashed very-light-grey">{entity.Label}</div>;
      }
    }
    else if(entity.Label) {
      // Editable with Popover
      if(editionItemTypes.includes(itemType)) {
        return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
          <div className="width100p">
            <div className="axe bordered blue">
              <a target="_self" href={`/Card/${columnName}/${entity.Id}/${blockType}`}><span className="iconEntity verysmallIcons mr5"></span></a>
              <span className="">{entity.Label}</span>
            </div>
          </div>
        </OverlayTrigger>;
      }
      // Non Editable
      else {
        return <div className="axe bordered blue">
          <a target="_self" href={`/Card/${columnName}/${entity.Id}/${blockType}`}><span className="iconEntity verysmallIcons mr5"></span></a>
          <span className="">{entity.Label}</span>
        </div>;
      }
    }
    else {
      return null;
    }
  }

  templateColumnExternal(props) {
    const itemId = props.Item_ID;
    const itemType = props.Item_Type;

    if(!props.column) {
      return null;
    }

    // Get Column Name and Value
    const columnName = props.column.field;
    const value = props[columnName];

    const allowEditing = props.column.allowEditing;
    const id = itemId + " " + itemType + " " + columnName + " " + allowEditing;

    if(value == 'True') {
      return <div className="booleanTable">
        <div id={id} className="boolean iconBooleanTrue iconExternal iconsRectangle"></div>
      </div>;
    }
    else if(value == 'False') {
      return <div className="booleanTable">
        <div id={id} className="boolean iconBooleanFalse iconInternal iconsRectangle"></div>
      </div>;
    }
    else {
      return null;
    }
  }

  templateColumnFieldType(props) {
    const itemId = props.Item_ID;
    const itemType = props.Item_Type;
    let backColor, foreColor, borderColor, icon;

    if(!props.column) {
      return null;
    }

    // Get Column Name (Remove Suffix .Label to Object Fields)
    const columnName = props.column.field.replace(".Label", "");
    const value = props[columnName];

    const editionItemTypes = this.getColumnEditionItemType(columnName);
    const restrictedValues = this.getColumnRestrictedValues(columnName);

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverEditAxe ItemId={itemId.substring(1)} ItemType={itemType} ColumnName={columnName} AxeValue={value} RestrictedValues={restrictedValues} onAxeEdit={this.editAxe}></PopoverEditAxe>
        </Popover.Content>
      </Popover>
    );

    // Back, Fore, Border Color & icon
    if(value) {
      if(!value.BackColor) {
        backColor = '#FFFFFF';
      }
      else {
        backColor = value.BackColor;
      }
      if(!value.ForeColor) {
        foreColor = '#0088C7';
        borderColor = '#0088C7';
      }
      else {
        if((value.ForeColor === '#FFFFFF' || value.ForeColor === '#ffffff')) {
          borderColor = value.BackColor;
        }
        else {
          borderColor = value.ForeColor;
        }

        foreColor = value.ForeColor;
      }
    }

    if(value.Label) {
      // Editable with Popover
      if(editionItemTypes.includes(itemType)) {
        return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
          <div className="width100p"><div className="axe bordered" style={{ background: backColor, color: foreColor, borderColor: borderColor }}>{value.Label}</div></div>
        </OverlayTrigger>;
      }
      // Non Editable
      else {
        return <div className="axe bordered" style={{ background: backColor, color: foreColor, borderColor: borderColor }}>{value.Label}</div>;
      }
    }
    else {
      return null;
    }
  }

  templateColumnForeColor(props) {
    const currentView = this.state.currentView;
    const itemId = props.Item_ID;
    const itemType = props.Item_Type;
    const foreColor = props.ForeColor;

    if(!props.column) {
      return null;
    }

    // Get Column Name (Remove Suffix .Label to Object Fields)
    const columnName = props.column.field.replace(".Label", "");

    const editionItemTypes = this.getColumnEditionItemType(columnName);

    // Get View corresponding level
    const level = this.getCurrentViewLevel(currentView);

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverEditColor ItemId={itemId.substring(1)} ItemType={itemType} ColumnName={columnName} ColorValue={foreColor} onColorEdit={this.editColor}></PopoverEditColor>
        </Popover.Content>
      </Popover>
    );

    if(foreColor) {
      // Editable with Popover
      if(editionItemTypes.includes(level)) {
        return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
          <div className="width100p"><div className="axisColor" style={{ background: foreColor, border: '1px solid', borderColor: '#8E8E90' }}></div></div>
        </OverlayTrigger>;
      }
      // Non Editable
      else {
        return <div className="axe bordered blue">{foreColor}</div>;
      }
    }
    else {
      // Editable with Popover
      if(editionItemTypes.includes(level)) {
        return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
          <div className="width100p"><div className="axisColor" style={{ background: '#0088C7', border: '1px solid', borderColor: '#8E8E90' }}></div></div>
        </OverlayTrigger>;
      }
      // Non Editable
      else {
        return <div className="axisColor" style={{ background: '#0088C7', border: '1px solid', borderColor: '#8E8E90' }}></div>;
      }
    }
  }

  templateColumnHashtags(props) {
    const itemId = props.Item_ID;
    const itemType = props.Item_Type;
    const hashTags = props.HashTag;

    if(!props.column) {
      return null;
    }

    // Get Column Name (Remove Suffix .Label to Object Fields)
    const columnName = props.column.field.replace(".Label", "");

    const editionItemTypes = this.getColumnEditionItemType(columnName);
    const restrictedValues = this.getColumnRestrictedValues(columnName);

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverEditObjectTable ItemId={itemId.substring(1)} ItemType={itemType} ColumnName={columnName} ObjectTableValues={hashTags} RestrictedValues={restrictedValues} onObjectTableEdit={this.editObjectTable}></PopoverEditObjectTable>
        </Popover.Content>
      </Popover>
    );
    
    if(hashTags) {
      // Editable with Popover
      if(editionItemTypes.includes(itemType)) {
        return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
          <div className="width100p">
            <div className="cellTable inline-flex">
              {hashTags.map((hashTag, index) => {
                let backColor, foreColor, borderColor;

                // Back, Fore & Border Color
                if(hashTag) {
                  if(!hashTag.BackColor) {
                    backColor = '#FFFFFF';
                  }
                  else {
                    backColor = hashTag.BackColor;
                  }
                  if(!hashTag.ForeColor) {
                    foreColor = '#0088C7';
                    borderColor = '#0088C7';
                  }
                  else {
                    if((hashTag.ForeColor === '#FFFFFF' || hashTag.ForeColor === '#ffffff')) {
                      borderColor = hashTag.BackColor;
                    }
                    else {
                      borderColor = hashTag.ForeColor;
                    }
      
                    foreColor = hashTag.ForeColor;
                  }
                }

                return <div key={index} className={"hashtag bordered" + (index < hashTags.length - 1 ? " mr10" : "")} style={{ background: backColor, color: foreColor, borderColor: borderColor }}>{hashTag.Label}</div>;
              })}
            </div>
          </div>
        </OverlayTrigger>;
      }
      // Non Editable
      else {
        return <div className="inline-flex">
          {hashTags.map((hashTag, index) => {
            let backColor, foreColor, borderColor;

            // Back, Fore & Border Color
            if(hashTag) {
              if(!hashTag.BackColor) {
                backColor = '#FFFFFF';
              }
              else {
                backColor = hashTag.BackColor;
              }
              if(!hashTag.ForeColor) {
                foreColor = '#0088C7';
                borderColor = '#0088C7';
              }
              else {
                if((hashTag.ForeColor === '#FFFFFF' || hashTag.ForeColor === '#ffffff')) {
                  borderColor = hashTag.BackColor;
                }
                else {
                  borderColor = hashTag.ForeColor;
                }
  
                foreColor = hashTag.ForeColor;
              }
            }

            return <div key={index} className={"hashtag bordered" + (index < hashTags.length - 1 ? " mr10" : "")} style={{ background: backColor, color: foreColor, borderColor: borderColor }}>{hashTag.Label}</div>;
          })}
        </div>;
      }
    }
    else {
      return null;
    }
  }

  templateColumnHighlighted(props) {
    const itemId = props.Item_ID;
    const itemType = props.Item_Type;

    if(!props.column) {
      return null;
    }

    // Get Column Name and Value
    const columnName = props.column.field;
    const value = props[columnName];

    const allowEditing = props.column.allowEditing;
    const id = itemId + " " + itemType + " " + columnName + " " + allowEditing;

    if(value == 'True') {
      return <div className="booleanTable">
        <div id={id} className="boolean iconBooleanTrue iconHighlighted iconsTable"></div>
      </div>;
    }
    else if(value == 'False') {
      return <div className="booleanTable">
        <div id={id} className="boolean iconBooleanFalse iconNotHighlighted iconsTable"></div>
      </div>;
    }
    else {
      return null;
    }
  }

  templateColumnImpact(props) {
    const itemId = props.Item_ID;
    const itemType = props.Item_Type;
    const impact = props.Impact;
    
    if(!props.column) {
      return null;
    }

    // Get Column Name (Remove Suffix .Label to Object Fields)
    const columnName = props.column.field.replace(".Label", "");

    const editionItemTypes = this.getColumnEditionItemType(columnName);
    const restrictedValues = this.getColumnRestrictedValues(columnName);

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverEditAxe ItemId={itemId.substring(1)} ItemType={itemType} ColumnName={columnName} AxeValue={impact} RestrictedValues={restrictedValues} onAxeEdit={this.editAxe}></PopoverEditAxe>
        </Popover.Content>
      </Popover>
    );

    // Impact (0: Very Low, 1: Low, 2: Medium, 3: High, 4: Critical)
    switch(impact.Id) {
      case 0:
          // Editable with Popover
          if(editionItemTypes.includes(itemType)) {
            return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
              <li className="fs18 green"><span className="minicardPriority black">{impact.Label}</span></li>
            </OverlayTrigger>;
          }
          // Non Editable
          else {
            return <li className="fs18 green"><span className="minicardPriority black">{impact.Label}</span></li>;
          }
      case 1:
          // Editable with Popover
          if(editionItemTypes.includes(itemType)) {
            return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
              <li className="fs18 green"><span className="minicardPriority black">{impact.Label}</span></li>
            </OverlayTrigger>;
          }
          // Non Editable
          else {
            return <li className="fs18 green"><span className="minicardPriority black">{impact.Label}</span></li>;
          }
      case 2:
          // Editable with Popover
          if(editionItemTypes.includes(itemType)) {
            return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
              <li className="fs18 orange-light"><span className="minicardPriority black">{impact.Label}</span></li>
            </OverlayTrigger>;
          }
          // Non Editable
          else {
            return <li className="fs18 orange-light"><span className="minicardPriority black">{impact.Label}</span></li>;
          }
      case 3:
          // Editable with Popover
          if(editionItemTypes.includes(itemType)) {
            return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
              <li className="fs18 orange"><span className="minicardPriority black">{impact.Label}</span></li>
            </OverlayTrigger>;
          }
          // Non Editable
          else {
            return <li className="fs18 orange"><span className="minicardPriority black">{impact.Label}</span></li>;
          }
      case 4:
          // Editable with Popover
          if(editionItemTypes.includes(itemType)) {
            return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
              <li className="fs18 black"><span className="minicardPriority red">{impact.Label}</span></li>
            </OverlayTrigger>;
          }
          // Non Editable
          else {
            return <li className="fs18 black"><span className="minicardPriority red">{impact.Label}</span></li>;
          }
      default:
          return null;
    }
  }

  templateColumnIndex(props) {
    const impact = props.Impact;
    const probability = props.Probability;
    const index = props.Index;

    // Impact (0: Very Low, 1: Low, 2: Medium, 3: High, 4: Critical)
    // Probability (0: Very Low, 1: Low, 2: Medium, 3: High, 4: Critical)
    if(impact && probability) {
      switch(impact.Id) {
        case 0:
          switch(probability.Id) {
            case 0:
                return <div className="brd-radius mat-index bg-verylow-verylow white">{index}</div>;
            case 1:
                return <div className="brd-radius mat-index bg-verylow-low white">{index}</div>;
            case 2:
                return <div className="brd-radius mat-index bg-verylow-medium white">{index}</div>;
            case 3:
                return <div className="brd-radius mat-index bg-verylow-high white">{index}</div>;
            case 4:
                return <div className="brd-radius mat-index bg-verylow-critical white">{index}</div>;
            default:
                return null;
          }
        case 1:
          switch(probability.Id) {
            case 0:
                return <div className="brd-radius mat-index bg-low-verylow white">{index}</div>;
            case 1:
                return <div className="brd-radius mat-index bg-low-low white">{index}</div>;
            case 2:
                return <div className="brd-radius mat-index bg-low-medium white">{index}</div>;
            case 3:
                return <div className="brd-radius mat-index bg-low-high white">{index}</div>;
            case 4:
                return <div className="brd-radius mat-index bg-low-critical white">{index}</div>;
            default:
                return null;
          }
        case 2:
          switch(probability.Id) {
            case 0:
                return <div className="brd-radius mat-index bg-medium-verylow white">{index}</div>;
            case 1:
                return <div className="brd-radius mat-index bg-medium-low white">{index}</div>;
            case 2:
                return <div className="brd-radius mat-index bg-medium-medium white">{index}</div>;
            case 3:
                return <div className="brd-radius mat-index bg-medium-high white">{index}</div>;
            case 4:
                return <div className="brd-radius mat-index bg-medium-critical white">{index}</div>;
            default:
                return null;
          }
        case 3:
          switch(probability.Id) {
            case 0:
                return <div className="brd-radius mat-index bg-high-verylow white">{index}</div>;
            case 1:
                return <div className="brd-radius mat-index bg-high-low white">{index}</div>;
            case 2:
                return <div className="brd-radius mat-index bg-high-medium white">{index}</div>;
            case 3:
                return <div className="brd-radius mat-index bg-high-high white">{index}</div>;
            case 4:
                return <div className="brd-radius mat-index bg-high-critical white">{index}</div>;
            default:
                return null;
          }
        case 4:
          switch(probability.Id) {
            case 0:
                return <div className="brd-radius mat-index bg-critical-verylow white">{index}</div>;
            case 1:
                return <div className="brd-radius mat-index bg-critical-low white">{index}</div>;
            case 2:
                return <div className="brd-radius mat-index bg-critical-medium white">{index}</div>;
            case 3:
                return <div className="brd-radius mat-index bg-critical-high white">{index}</div>;
            case 4:
                return <div className="brd-radius mat-index bg-critical-critical white">{index}</div>;
            default:
                return null;
          }
        default:
            return null;
      }
    }
    else {
      return null;
    }
  }

  templateColumnItemType(props) {
    const itemType = props.Item_Type;

    switch(itemType) {
      case 'Business_Line':
          return <div className=""><span className="treeIcon iconBusinessLineWhite verysmallIcons" alt="Business Line"></span>{itemType}</div>;
      case 'Project':
          return <div className=""><span className="treeIcon iconProjectWhite verysmallIcons" alt="Project"></span>{itemType}</div>;
      case 'Workpackage':
          return <div className=""><span className="treeIcon iconWorkpackageWhite verysmallIcons" alt="Workpackage"></span>{itemType}</div>;
      case 'Action':
          return <div className=""><span className="treeIcon iconActionWhite verysmallIcons" alt="Action"></span>{itemType}</div>;
      case 'Task':
          return <div className=""><span className="treeIcon iconTaskWhite verysmallIcons" alt="Task"></span>{itemType}</div>;
      default:
          return <div className="">{itemType}</div>;
    }
  }

  templateColumnLanguage(props) {
    const itemId = props.Item_ID;
    const itemType = props.Item_Type;

    if(!props.column) {
      return null;
    }

    // Get Column Name (Remove Suffix .Label to Object Fields)
    const columnName = props.column.field.replace(".Label", "");
    const value = props[columnName];

    const editionItemTypes = this.getColumnEditionItemType(columnName);
    const restrictedValues = this.getColumnRestrictedValues(columnName);

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverEditAxe ItemId={itemId.substring(1)} ItemType={itemType} ColumnName={columnName} AxeValue={value} RestrictedValues={restrictedValues} onAxeEdit={this.editAxe}></PopoverEditAxe>
        </Popover.Content>
      </Popover>
    );

    if(value.Label) {
      // Editable with Popover
      if(editionItemTypes.includes(itemType)) {
        if(value.Label === 'English') {
          return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
            <div className="width100p"><div className="axe bordered blue brd-blue bg-white align-items-center"><img className="iconsLanguage mr5" src={IconEnglish} alt="English"/>{value.Label}</div></div>
          </OverlayTrigger>;
        }
        else if(value.Label === 'Français') {
          return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
            <div className="width100p"><div className="axe bordered blue brd-blue bg-white align-items-center"><img className="iconsLanguage mr5" src={IconFrance} alt="France"/>{value.Label}</div></div>
          </OverlayTrigger>;
        }
        else if(value.Label === 'Spanish') {
          return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
            <div className="width100p"><div className="axe bordered blue brd-blue bg-white align-items-center"><img className="iconsLanguage mr5" src={IconSpain} alt="Spain"/>{value.Label}</div></div>
          </OverlayTrigger>;
        }
      }
      // Non Editable
      else {
        if(value.Label === 'English') {
          return <div className="axe bordered blue brd-blue bg-white align-items-center"><img className="iconsLanguage mr5" src={IconEnglish} alt="English"/>{value.Label}</div>;
        }
        else if(value.Label === 'Français') {
          return <div className="axe bordered blue brd-blue bg-white align-items-center"><img className="iconsLanguage mr5" src={IconFrance} alt="France"/>{value.Label}</div>;
        }
        else if(value.Label === 'Spanish') {
          return <div className="axe bordered blue brd-blue bg-white align-items-center"><img className="iconsLanguage mr5" src={IconSpain} alt="Spain"/>{value.Label}</div>;
        }
      }
    }
    else {
      return null;
    }
  }

  templateColumnLag(props) {
    let backColor, foreColor, borderColor;

    const columnName = props.column.field.replace(".Label", "");
    const value = props[columnName];

    foreColor = '#FFFFFF';

    if(value > 0) {
      backColor = "#00C77A";
      return <div className="axe bordered" style={{ background: backColor, color: foreColor, borderColor: backColor }}>{" + " + value+ " " }</div>;
    }
    else if(value < 0) {
      backColor = "#E21313";
      return <div className="axe bordered" style={{ background: backColor, color: foreColor, borderColor: backColor }}>{" - " + (-value) + " "}</div>;
    }
    else if(value === 0) {
      backColor = "#FFFFFF";
      foreColor = "#9D9D9F";
      return <div className="axe bordered" style={{ background: backColor, color: foreColor, borderColor: foreColor }}>{" " + value + " "}</div>;
    }
    else {
      return null;
    }
  }

  templateColumnLevels(props) {
    const itemId = props.Item_ID;
    const itemType = props.Item_Type;
    const levels = props.Levels;
    
    if(!props.column) {
      return null;
    }

    // Get Column Name (Remove Suffix .Label to Object Fields)
    const columnName = props.column.field.replace(".Label", "");

    const editionItemTypes = this.getColumnEditionItemType(columnName);
    const restrictedValues = this.getColumnRestrictedValues(columnName);

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverEditObjectTable ItemId={itemId.substring(1)} ItemType={itemType} ColumnName={columnName} ObjectTableValues={levels} RestrictedValues={restrictedValues} onObjectTableEdit={this.editObjectTable}></PopoverEditObjectTable>
        </Popover.Content>
      </Popover>
    );

    if(levels) {
      // Editable with Popover
      if(editionItemTypes.includes(itemType)) {
        return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
          <div className="width100p">
            <div className="cellTable inline-flex">
              {levels.map((level, index) => {
                switch(level.Id) {
                  // Business Line
                  case 1:
                      return <div key={index} className="axeTable">
                        <span className="iconBusinessLine verysmallIcons mr5" alt="Business Line"/>
                        <span className="">{level.Label}</span>
                      </div>;
                  // Project
                  case 2:
                      return <div key={index} className="axeTable">
                        <span className="iconProject verysmallIcons mr5" alt="Project"/>
                        <span className="">{level.Label}</span>
                      </div>;
                  // Workpackage
                  case 3:
                      return <div key={index} className="axeTable">
                        <span className="iconWorkpackage verysmallIcons mr5" alt="Workpackage"/>
                        <span className="">{level.Label}</span>
                      </div>;
                  // Action
                  case 4:
                      return <div key={index} className="axeTable">
                        <span className="iconAction verysmallIcons mr5" alt="Action"/>
                        <span className="">{level.Label}</span>
                      </div>;
                  // Task
                  case 5:
                      return <div key={index} className="axeTable">
                        <span className="iconTask verysmallIcons mr5" alt="Task"/>
                        <span className="">{level.Label}</span>
                      </div>;
                  // Risk
                  case 6:
                      return <div key={index} className="axeTable">
                        <span className="iconRisks verysmallIcons mr5" alt="Risk"/>
                        <span className="">{level.Label}</span>
                      </div>;
                  // Issue
                  case 7:
                      return <div key={index} className="axeTable">
                        <span className="iconIssues verysmallIcons mr5" alt="Issue"/>
                        <span className="">{level.Label}</span>
                      </div>;
                  // Decision
                  case 8:
                      return <div key={index} className="axeTable">
                        <span className="iconDecisions verysmallIcons mr5" alt="Decision"/>
                        <span className="">{level.Label}</span>
                      </div>;
                  // Meeting
                  case 9:
                      return <div key={index} className="axeTable">
                        <span className="iconMeetings verysmallIcons mr5" alt="Meeting"/>
                        <span className="">{level.Label}</span>
                      </div>;
                  // Resource
                  case 12:
                      return <div key={index} className="axeTable">
                        <span className="iconResource verysmallIcons mr5" alt="Resource"/>
                        <span className="">{level.Label}</span>
                      </div>;
                  // Entity
                  case 13:
                      return <div key={index} className="axeTable">
                        <span className="iconEntity verysmallIcons mr5" alt="Entity"/>
                        <span className="">{level.Label}</span>
                      </div>;
                  // BudgetCode
                  case 17:
                      return <div key={index} className="axeTable">
                        <span className="iconBudgetCode verysmallIcons mr5" alt="BudgetCode"/>
                        <span className="">{level.Label}</span>
                      </div>;
                  default:
                      return null;
                }
              })}
            </div>
          </div>
        </OverlayTrigger>;
      }
      // Non Editable
      else {
        return <div className="inline-flex">
          {levels.map((level, index) => {
            switch(level.Id) {
              // Business Line
              case 1:
                  return <div key={index} className="axeTable">
                    <span className="iconBusinessLine verysmallIcons mr5" alt="Business Line"/>
                    <span className="">{level.Label}</span>
                  </div>;
              // Project
              case 2:
                  return <div key={index} className="axeTable">
                    <span className="iconProject verysmallIcons mr5" alt="Project"/>
                    <span className="">{level.Label}</span>
                  </div>;
              // Workpackage
              case 3:
                  return <div key={index} className="axeTable">
                    <span className="iconWorkpackage verysmallIcons mr5" alt="Workpackage"/>
                    <span className="">{level.Label}</span>
                  </div>;
              // Action
              case 4:
                  return <div key={index} className="axeTable">
                    <span className="iconAction verysmallIcons mr5" alt="Action"/>
                    <span className="">{level.Label}</span>
                  </div>;
              // Task
              case 5:
                  return <div key={index} className="axeTable">
                    <span className="iconTask verysmallIcons mr5" alt="Task"/>
                    <span className="">{level.Label}</span>
                  </div>;
              // Risk
              case 6:
                  return <div key={index} className="axeTable">
                    <span className="iconRisks verysmallIcons mr5" alt="Risk"/>
                    <span className="">{level.Label}</span>
                  </div>;
              // Issue
              case 7:
                  return <div key={index} className="axeTable">
                    <span className="iconIssues verysmallIcons mr5" alt="Issue"/>
                    <span className="">{level.Label}</span>
                  </div>;
              // Decision
              case 8:
                  return <div key={index} className="axeTable">
                    <span className="iconDecisions verysmallIcons mr5" alt="Decision"/>
                    <span className="">{level.Label}</span>
                  </div>;
              // Meeting
              case 9:
                  return <div key={index} className="axeTable">
                    <span className="iconMeetings verysmallIcons mr5" alt="Meeting"/>
                    <span className="">{level.Label}</span>
                  </div>;
              // Resource
              case 12:
                  return <div key={index} className="axeTable">
                    <span className="iconResource verysmallIcons mr5" alt="Resource"/>
                    <span className="">{level.Label}</span>
                  </div>;
              // Entity
              case 13:
                  return <div key={index} className="axeTable">
                    <span className="iconEntity verysmallIcons mr5" alt="Entity"/>
                    <span className="">{level.Label}</span>
                  </div>;
              // BudgetCode
              case 17:
                  return <div key={index} className="axeTable">
                    <span className="iconBudgetCode verysmallIcons mr5" alt="BudgetCode"/>
                    <span className="">{level.Label}</span>
                  </div>;
              default:
                  return null;
            }
          })}
        </div>;
      }
    }
    else {
      return null;
      // return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}><div className="emptyCellTable"></div></OverlayTrigger>;
    }
  }
  
  templateColumnLicenceType(props) {
    const licenceType = props.Licence_Type;
    
    switch(licenceType) {
      case 'Full':
          return <div className="axe brd-radius bg-green white">{licenceType}</div>;
      case 'Standard':
          return <div className="axe bordered green">{licenceType}</div>;
      case 'No Licence':
      case 'Duplicated license':
      case 'Invalid License':
          return <div className="axe bordered grey">{licenceType}</div>;
      default:
          return null;
    }
  }

  templateColumnMeetingIndex(props) {
    const itemId = props.Item_ID;

    if(itemId) {
      return <a target="_self" href={`/Card/Meeting/${itemId.substring(1)}/Home`}><div className="tableIconBlue"><span className="iconMeetingLight verysmallIcons"></span></div></a>;
    }
    else {
      return null;
    }
  }

  templateColumnMeeting(props) {
    const blockType = this.state.blockType;
    const itemId = props.Item_ID;
    const itemType = props.Item_Type;
    const meeting = props.Meeting;
    
    if(!props.column) {
      return null;
    }

    // Get Column Name (Remove Suffix .Label to Object Fields)
    const columnName = props.column.field.replace(".Label", "");

    const editionItemTypes = this.getColumnEditionItemType(columnName);
    const restrictedValues = this.getColumnRestrictedValues(columnName);

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverEditAxe ItemId={itemId.substring(1)} ItemType={itemType} ColumnName={columnName} AxeValue={meeting} RestrictedValues={restrictedValues} onAxeEdit={this.editAxe}></PopoverEditAxe>
        </Popover.Content>
      </Popover>
    );

    if(meeting.Label === '- NA -') {
      // Editable with Popover
      if(editionItemTypes.includes(itemType)) {
        return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
          <div className="width100p"><div className="axe brd-dashed very-light-grey">{meeting.Label}</div></div>
        </OverlayTrigger>;
      }
      // Non Editable
      else {
        return <div className="axe brd-dashed very-light-grey">{meeting.Label}</div>;
      }
    }
    else if(meeting.Label) {
      // Editable with Popover
      if(editionItemTypes.includes(itemType)) {
        return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
          <div className="width100p">
            <div className="axe bordered blue">
              <a target="_self" href={`/Card/${columnName}/${meeting.Id}/${blockType}`}><span className="iconMeeting verysmallIcons mr5"></span></a>
              <span className="">{meeting.Label}</span>
            </div>
          </div>
        </OverlayTrigger>;
      }
      // Non Editable
      else {
        return <div className="axe bordered blue">
          <a target="_self" href={`/Card/${columnName}/${meeting.Id}/${blockType}`}><span className="iconMeeting verysmallIcons mr5"></span></a>
          <span className="">{meeting.Label}</span>
        </div>;
      }
    }
    else {
      return null;
    }
  }

  templateColumnMeteo(props) {
    const itemId = props.Item_ID;
    const itemType = props.Item_Type;
    const meteo = props.Meteo;
    
    if(!props.column) {
      return null;
    }

    // Get Column Name (Remove Suffix .Label to Object Fields)
    const columnName = props.column.field.replace(".Label", "");

    const editionItemTypes = this.getColumnEditionItemType(columnName);
    const restrictedValues = this.getColumnRestrictedValues(columnName);

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverEditAxe ItemId={itemId.substring(1)} ItemType={itemType} ColumnName={columnName} AxeValue={meteo} RestrictedValues={restrictedValues} onAxeEdit={this.editAxe}></PopoverEditAxe>
        </Popover.Content>
      </Popover>
    );

    // Meteo (0: None, 1: Sun ☀, 2: Cloud ⛅, 3: Rain 🌧, 4: Thunder 🌩, 5: Moon ☾)
    switch(meteo.Id) {
      case 0:
          // Editable with Popover
          if(editionItemTypes.includes(itemType)) {
            return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
              <div className="width100p"><span className="iconsTable"></span></div>
            </OverlayTrigger>;
          }
          // Non Editable
          else {
            return null;
          }
      case 1:
          // Editable with Popover
          if(editionItemTypes.includes(itemType)) {
            return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
              <div className="width100p"><img className="iconsTable" src={IconSun} alt="Sun"/></div>
            </OverlayTrigger>;
          }
          // Non Editable
          else {
            return <img className="iconsTable" src={IconSun} alt="Sun"/>;
          }
      case 2:
          // Editable with Popover
          if(editionItemTypes.includes(itemType)) {
            return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
              <div className="width100p"><img className="iconsTable" src={IconCloud} alt="Cloud"/></div>
            </OverlayTrigger>;
          }
          // Non Editable
          else {
            return <img className="iconsTable" src={IconCloud} alt="Cloud"/>;
          }
      case 3:
          // Editable with Popover
          if(editionItemTypes.includes(itemType)) {
            return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
              <div className="width100p"><img className="iconsTable" src={IconRain} alt="Rain"/></div>
            </OverlayTrigger>;
          }
          // Non Editable
          else {
            return <img className="iconsTable" src={IconRain} alt="Rain"/>;
          }
      case 4:
          // Editable with Popover
          if(editionItemTypes.includes(itemType)) {
            return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
              <div className="width100p"><img className="iconsTable" src={IconThunder} alt="Thunder"/></div>
            </OverlayTrigger>;
          }
          // Non Editable
          else {
            return <img className="iconsTable" src={IconThunder} alt="Thunder"/>;
          }
      case 5:
          // Editable with Popover
          if(editionItemTypes.includes(itemType)) {
            return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
              <div className="width100p"><img className="iconsTable" src={IconMoon} alt="Moon"/></div>
            </OverlayTrigger>;
          }
          // Non Editable
          else {
            return <img className="iconsTable" src={IconMoon} alt="Moon"/>;
          }
      default:
          return null;
    }
  }

  templateColumnMeteoFreshness(props) {
    const meteoFreshness = props.Meteo_Freshness;

    if(meteoFreshness === "1") {
      return <div className="iconFullCircleGreen iconsTable"></div>;
    } 
    else if(meteoFreshness === "2") {
      return <div className="iconFullCircleOrange iconsTable"></div>;
    } 
    else if(meteoFreshness === "3") {
      return <div className="iconFullCircleRed iconsTable"></div>;
    }
    else if(meteoFreshness === "4") {
      return <div className="iconCircleGrey iconsTable"></div>;
    }
    else {
      return null;
    }
  }

  templateColumnMonthBurned(props) {
    const monthBurned = props.Month_Burned;

    if(monthBurned == 0) {
      return <div className="monthBurned brd-dashed-timetracking blue bold">{monthBurned}</div>;
    }
    else {
      return <div className="monthBurned brd-timetracking blue bold">{monthBurned}</div>;
    }
  }

  templateColumnName(props) {
    const blockType = this.state.blockType;
    const itemId = props.Item_ID;
    const itemType = props.Item_Type;
    const name = props.Name;
    let split, firstname = '', lastname = '';

    if(itemType === 'Resource') {
      if(name === '- Not assigned -') {
        firstname = 'N';
        lastname = 'A';
      }
      else if(name === '- To be assigned -') {
        firstname = 'T';
        lastname = 'B';
      }
      else if(name) {
        split = name.split(' ');
  
        if(split.length === 1) {
          firstname = name.split(' ')[0].substring(0,1);
          lastname = '';
        }
        else if(split.length === 2) {
          firstname = name.split(' ')[0].substring(0,1);
          lastname = name.split(' ')[1].substring(0,1);
        }
        else {
          firstname = name.split(' ')[0].substring(0,1);
          lastname = name.split(' ')[split.length-1].substring(0,1);
        }
      }
    }
    
    if(blockType === 'Views') {
      return <div className="inline-flex align-items-center">
        <div className="buttonPinView"><span className="iconPinWhite verysmallIcons" onClick={() => this.addView(itemId.substring(1))}></span></div>
        <span className="">{name}</span>
      </div>;
    }
    else if(blockType === 'DeleteBin') {
      switch(itemType) {
        case 'Business_Line':
            return (<div className="bold"><span title={itemType} className="treeIcon iconBusinessLineWhite verysmallIcons" alt="Business Line"></span>{name}</div>);
        case 'Project':
            return (<div className="bold"><span title={itemType} className="treeIcon iconProjectWhite verysmallIcons" alt="Project"></span>{name}</div>);
        case 'Workpackage':
            return (<div className=""><span title={itemType} className="treeIcon iconWorkpackageWhite verysmallIcons" alt="Workpackage"></span>{name}</div>);
        case 'Action':
            return (<div className=""><span title={itemType} className="treeIcon iconActionWhite verysmallIcons" alt="Action"></span>{name}</div>);
        case 'Task':
            return (<div className=""><span title={itemType} className="treeIconGrey iconTaskGrey verysmallIcons" alt="Task"></span>{name}</div>);
        case 'Entity':
            return (<div className="bold"><span title={itemType} className="treeIconTurquoise iconEntityWhite verysmallIcons" alt="Entity"></span>{name}</div>);
        case 'Resource':
            return (<div className=""><div title={itemType} className="iconResourceTable icons aligncenter"><span className="white">{firstname + lastname}</span></div><span className="ml10 grey">{name}</span></div>);
        default:
            return <span className="">{name}</span>;
      }
    }
    else {
      switch(itemType) {
        case 'AgileBoard':
            return (<div className="bold"><a target="_self" href={`/Card/${itemType}/${itemId.substring(1)}/Home`}><span title={itemType} className="treeIconGrey iconAgileBoard verysmallIcons" alt="AgileBoard"></span></a>{name}</div>);
        case 'Sprint':
            return (<div className="bold"><a target="_self" href={`/Card/${itemType}/${itemId.substring(1)}/${blockType}`}><span title={itemType} className="treeIconGrey iconSprint verysmallIcons" alt="Sprint"></span></a>{name}</div>);
        case 'Business_Line':
            return (<div className="bold"><a target="_self" href={`/Card/${itemType}/${itemId.substring(1)}/${blockType}`}><span title={itemType} className="treeIcon iconBusinessLineWhite verysmallIcons" alt="Business Line"></span></a>{name}</div>);
        case 'Project':
            return (<div className="bold"><a target="_self" href={`/Card/${itemType}/${itemId.substring(1)}/${blockType}`}><span title={itemType} className="treeIcon iconProjectWhite verysmallIcons" alt="Project"></span></a>{name}</div>);
        case 'Workpackage':
            return (<div className=""><a target="_self" href={`/Card/${itemType}/${itemId.substring(1)}/${blockType}`}><span title={itemType} className="treeIcon iconWorkpackageWhite verysmallIcons" alt="Workpackage"></span></a>{name}</div>);
        case 'Action':
            return (<div className=""><a target="_self" href={`/Card/${itemType}/${itemId.substring(1)}/${blockType}`}><span title={itemType} className="treeIcon iconActionWhite verysmallIcons" alt="Action"></span></a>{name}</div>);
        case 'Task':
            return (<div className=""><a target="_self" href={`/Card/${itemType}/${itemId.substring(1)}/Details`}><span title={itemType} className="treeIconGrey iconTaskGrey verysmallIcons" alt="Task"></span></a>{name}</div>);
        case 'Entity':
            return (<div className="bold"><a target="_self" href={`/Card/${itemType}/${itemId.substring(1)}/Home`}><span title={itemType} className="treeIconTurquoise iconEntityWhite verysmallIcons" alt="Entity"></span></a>{name}</div>);
        case 'Resource':
            return (<div className="align-baseline"><a target="_self" href={`/Card/${itemType}/${itemId.substring(1)}/Home`}><div title={itemType} className="iconResourceTable icons aligncenter"><span className="white">{firstname + lastname}</span></div></a><span className="ml10 grey">{name}</span></div>);
        default:
            return <span className="">{name}</span>;
      }
    }
  }

  templateColumnParents(props) {
    const parents = props.Parents;

    if(parents) {
      let itemId, itemType;

      return <div className="inline-flex">
        {parents.split(',').map((parent, index) => {
          itemId = parent.substring(1);
          itemType = parent.substring(0, 1);

          switch(itemType) {
            case 'B':
                itemType = "Business_Line";
                return <div key={index} className="inline-flex align-items-center mr10"><a target="_self" href={`/Card/${itemType}/${itemId}/Details`}><span title={itemType} className="treeIcon iconBusinessLineWhite verysmallIcons" alt="Business Line"></span></a><span className="">{itemType.substring(0, 1)}{itemId}</span></div>;
            case 'P':
                itemType = "Project";
                return <div key={index} className="inline-flex align-items-center mr10"><a target="_self" href={`/Card/${itemType}/${itemId}/Details`}><span title={itemType} className="treeIcon iconProjectWhite verysmallIcons" alt="Project"></span></a><span className="">{itemType.substring(0, 1)}{itemId}</span></div>;
            case 'W':
                itemType = "Workpackage";
                return <div key={index} className="inline-flex align-items-center mr10"><a target="_self" href={`/Card/${itemType}/${itemId}/Details`}><span title={itemType} className="treeIcon iconWorkpackageWhite verysmallIcons" alt="Workpackage"></span></a><span className="">{itemType.substring(0, 1)}{itemId}</span></div>;
            case 'A':
                itemType = "Action";
                return <div key={index} className="inline-flex align-items-center mr10"><a target="_self" href={`/Card/${itemType}/${itemId}/Details`}><span title={itemType} className="treeIcon iconActionWhite verysmallIcons" alt="Action"></span></a><span className="">{itemType.substring(0, 1)}{itemId}</span></div>;
            case 'T':
                itemType = "Task";
                return <div key={index} className="inline-flex align-items-center mr10"><a target="_self" href={`/Card/${itemType}/${itemId}/Details`}><span title={itemType} className="treeIconGrey iconTaskGrey verysmallIcons" alt="Task"></span></a><span className="">{itemType.substring(0, 1)}{itemId}</span></div>;
            case 'E':
                itemType = "Entity";
                return <div key={index} className="inline-flex align-items-center mr10"><a target="_self" href={`/Card/${itemType}/${itemId}/Details`}><span title={itemType} className="treeIconGrey iconEntity verysmallIcons" alt="Entity"></span></a><span className="">{itemType.substring(0, 1)}{itemId}</span></div>;
            case 'R':
                itemType = "Resource";
                return <div key={index} className="inline-flex align-items-center mr10"><a target="_self" href={`/Card/${itemType}/${itemId.substring(1)}/Details`}><span title={itemType} className="treeIconGrey iconResourceTable verysmallIcons" alt="Resource"></span></a><span className="">{itemType.substring(0, 1)}{itemId}</span></div>;
          }
        })}
      </div>;
    }
    else {
      return null;
    }
  }

  templateColumnPriority(props) {
    const itemId = props.Item_ID;
    const itemType = props.Item_Type;
    const priority = props.Priority;
    
    if(!props.column) {
      return null;
    }

    // Get Column Name (Remove Suffix .Label to Object Fields)
    const columnName = props.column.field.replace(".Label", "");

    const editionItemTypes = this.getColumnEditionItemType(columnName);
    const restrictedValues = this.getColumnRestrictedValues(columnName);

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverEditAxe ItemId={itemId.substring(1)} ItemType={itemType} ColumnName={columnName} AxeValue={priority} RestrictedValues={restrictedValues} onAxeEdit={this.editAxe}></PopoverEditAxe>
        </Popover.Content>
      </Popover>
    );

    // Priority (0: Very Low, 1: Low, 2: Medium, 3: High, 4: Critical)
    switch(priority.Id) {
      case 0:
          // Editable with Popover
          if(editionItemTypes.includes(itemType)) {
            return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
              <li className="fs18 green"><span className="minicardPriority black">{priority.Label}</span></li>
            </OverlayTrigger>;
          }
          // Non Editable
          else {
            return <li className="fs18 green"><span className="minicardPriority black">{priority.Label}</span></li>;
          }
      case 1:
          // Editable with Popover
          if(editionItemTypes.includes(itemType)) {
            return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
              <li className="fs18 green"><span className="minicardPriority black">{priority.Label}</span></li>
            </OverlayTrigger>;
          }
          // Non Editable
          else {
            return <li className="fs18 green"><span className="minicardPriority black">{priority.Label}</span></li>;
          }
      case 2:
          // Editable with Popover
          if(editionItemTypes.includes(itemType)) {
            return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
              <li className="fs18 orange-light"><span className="minicardPriority black">{priority.Label}</span></li>
            </OverlayTrigger>;
          }
          // Non Editable
          else {
            return <li className="fs18 orange-light"><span className="minicardPriority black">{priority.Label}</span></li>;
          }
      case 3:
          // Editable with Popover
          if(editionItemTypes.includes(itemType)) {
            return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
              <li className="fs18 orange"><span className="minicardPriority black">{priority.Label}</span></li>
            </OverlayTrigger>;
          }
          // Non Editable
          else {
            return <li className="fs18 orange"><span className="minicardPriority black">{priority.Label}</span></li>;
          }
      case 4:
          // Editable with Popover
          if(editionItemTypes.includes(itemType)) {
            return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
              <li className="fs18 black"><span className="minicardPriority red">{priority.Label}</span></li>
            </OverlayTrigger>;
          }
          // Non Editable
          else {
            return <li className="fs18 black"><span className="minicardPriority red">{priority.Label}</span></li>;
          }
      default:
          return null;
    }
  }

  templateColumnProbability(props) {
    const itemId = props.Item_ID;
    const itemType = props.Item_Type;
    const probability = props.Probability;
    
    if(!props.column) {
      return null;
    }

    // Get Column Name (Remove Suffix .Label to Object Fields)
    const columnName = props.column.field.replace(".Label", "");

    const editionItemTypes = this.getColumnEditionItemType(columnName);
    const restrictedValues = this.getColumnRestrictedValues(columnName);

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverEditAxe ItemId={itemId.substring(1)} ItemType={itemType} ColumnName={columnName} AxeValue={probability} RestrictedValues={restrictedValues} onAxeEdit={this.editAxe}></PopoverEditAxe>
        </Popover.Content>
      </Popover>
    );

    // Probability (0: Very Low, 1: Low, 2: Medium, 3: High, 4: Critical)
    switch(probability.Id) {
      case 0:
          // Editable with Popover
          if(editionItemTypes.includes(itemType)) {
            return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
              <li className="fs18 green"><span className="minicardPriority black">{probability.Label}</span></li>
            </OverlayTrigger>;
          }
          // Non Editable
          else {
            return <li className="fs18 green"><span className="minicardPriority black">{probability.Label}</span></li>;
          }
      case 1:
          // Editable with Popover
          if(editionItemTypes.includes(itemType)) {
            return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
              <li className="fs18 green"><span className="minicardPriority black">{probability.Label}</span></li>
            </OverlayTrigger>;
          }
          // Non Editable
          else {
            return <li className="fs18 green"><span className="minicardPriority black">{probability.Label}</span></li>;
          }
      case 2:
          // Editable with Popover
          if(editionItemTypes.includes(itemType)) {
            return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
              <li className="fs18 orange-light"><span className="minicardPriority black">{probability.Label}</span></li>
            </OverlayTrigger>;
          }
          // Non Editable
          else {
            return <li className="fs18 orange-light"><span className="minicardPriority black">{probability.Label}</span></li>;
          }
      case 3:
          // Editable with Popover
          if(editionItemTypes.includes(itemType)) {
            return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
              <li className="fs18 orange"><span className="minicardPriority black">{probability.Label}</span></li>
            </OverlayTrigger>;
          }
          // Non Editable
          else {
            return <li className="fs18 orange"><span className="minicardPriority black">{probability.Label}</span></li>;
          }
      case 4:
          // Editable with Popover
          if(editionItemTypes.includes(itemType)) {
            return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
              <li className="fs18 black"><span className="minicardPriority red">{probability.Label}</span></li>
            </OverlayTrigger>;
          }
          // Non Editable
          else {
            return <li className="fs18 black"><span className="minicardPriority red">{probability.Label}</span></li>;
          }
      default:
          return null;
    }
  }

  templateColumnProgress(props) {
    let progress;
    
    if(props.Progress) {
      progress = props.Progress;
      // progress = (props.Progress.replace(',', '.') * 100).toFixed(0);

      if(progress == 100) {
        return <div className="progressBlock">
          <span className="progressLabel">{`${progress} %`}</span>
          <span className="progressBar"><ProgressBar className="progressTable" variant="success" now={progress}></ProgressBar></span>
        </div>;
      }
      else if(progress < 100 && progress >= 0) {
        return <div className="progressBlock">
          <span className="progressLabel">{`${progress} %`}</span>
          <span className="progressBar"><ProgressBar className="progressTable" variant="primary" now={progress}></ProgressBar></span>
        </div>;
      }
      else if(progress < 0) {
        return <div className="progressBlock">
          <span className="progressLabel">{`${progress} %`}</span>
          <span className="progressBar"><ProgressBar className="progressTable" variant="danger" now={Math.abs(progress)}></ProgressBar></span>
        </div>;
      }
      else {
        return <div className="progressBlock">
          <span className="progressLabel">{`${progress} %`}</span>
          <span className="progressBar"><ProgressBar className="progressTable" variant="danger" now={'100'}></ProgressBar></span>
        </div>;
      }
    }
    else {
      return <div className="progressBlock">
        <span className="progressLabel">{`0 %`}</span>
        <span className="progressBar"><ProgressBar className="progressTable" variant="danger" now={'0'}></ProgressBar></span>
      </div>;
    }
  }

  templateColumnProject(props) {
    let blockType = this.state.blockType;
    const itemId = props.Item_ID;
    const itemType = props.Item_Type;
    const project = props.Project;

    if(!props.column) {
      return null;
    }

    // Get Column Name (Remove Suffix .Label to Object Fields)
    const columnName = props.column.field.replace(".Label", "");

    const editionItemTypes = this.getColumnEditionItemType(columnName);
    const restrictedValues = this.getColumnRestrictedValues(columnName);

    // Replace BlockType by Details in Link for Timetraking
    if(blockType === 'TimeTracking') {
      blockType = "Details";
    }

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverEditAxe ItemId={itemId.substring(1)} ItemType={itemType} ColumnName={columnName} AxeValue={project} RestrictedValues={restrictedValues} onAxeEdit={this.editAxe}></PopoverEditAxe>
        </Popover.Content>
      </Popover>
    );

    if(project.Label === '- NA -') {
      // Editable with Popover
      if(editionItemTypes.includes(itemType)) {
        return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
          <div className="width100p"><div className="axe brd-dashed very-light-grey">{project.Label}</div></div>
        </OverlayTrigger>;
      }
      // Non Editable
      else {
        return <div className="axe brd-dashed very-light-grey">{project.Label}</div>;
      }
    }
    else if(project.Label) {
      // Editable with Popover
      if(editionItemTypes.includes(itemType)) {
        return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
          <div className="width100p">
            <div className="axe bordered blue">
              <a target="_self" href={`/Card/${columnName}/${project.Id}/${blockType}`}><span className="iconProject verysmallIcons mr5"></span></a>
              <span className="">{project.Label}</span>
            </div>
          </div>
        </OverlayTrigger>;
      }
      // Non Editable
      else {
        return <div className="axe bordered blue">
          <a target="_self" href={`/Card/${columnName}/${project.Id}/${blockType}`}><span className="iconProject verysmallIcons mr5"></span></a>
          <span className="">{project.Label}</span>
        </div>;
      }
    }
    else {
      return null;
    }
  }

  templateColumnRights(props) {
    const itemId = props.Item_ID;
    const itemType = props.Item_Type;
    const rights = props.Rights;

    if(!props.column) {
      return null;
    }

    // Get Column Name (Remove Suffix .Label to Object Fields)
    const columnName = props.column.field.replace(".Label", "");

    const editionItemTypes = this.getColumnEditionItemType(columnName);
    const restrictedValues = this.getColumnRestrictedValues(columnName);

    // Define Popover template
    // const popover = (
    //   <Popover id="popover-basic">
    //     <Popover.Content>
    //       <PopoverEditObjectTable ItemId={itemId.substring(1)} ItemType={itemType} ColumnName={columnName} ObjectTableValues={rights} RestrictedValues={restrictedValues} onObjectTableEdit={this.editObjectTable}></PopoverEditObjectTable>
    //     </Popover.Content>
    //   </Popover>
    // );
    
    if(rights) {
      return <div className="width100p">
        <div className="inline-flex">
          {rights.map((right, index) => {
            let firstname, lastname;
            const split = right.Label.split(' ');
      
            if(split.length === 1) {
              firstname = right.Label.split(' ')[0].substring(0,1);
              lastname = '';
            }
            else if(split.length === 2) {
              firstname = right.Label.split(' ')[0].substring(0,1);
              lastname = right.Label.split(' ')[1].substring(0,1);
            }
            else {
              firstname = right.Label.split(' ')[0].substring(0,1);
              lastname = right.Label.split(' ')[split.length-1].substring(0,1);
            }

            if(right.Id === 0) {
              return <div key={index} className="align-baseline align-items-center mr5">{right.Label}</div>;
            }
            else {
              return <div key={index} className="align-baseline mr5"><span className="iconResourceTable icons aligncenter white">{firstname + lastname}</span></div>;
            }
          })}
        </div>
      </div>;
    }
    else {
      // return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}><div className="emptyCellTable"></div></OverlayTrigger>;
      return null;
    }
  }

  templateColumnRemainingDays(props) {
    const language = this.state.language;
    const remainingDays = props.RemainingDays;
    let label;

    if(!props.column) {
      return null;
    }

    if(Math.trunc(remainingDays) > 1) {
      label = Traduction.translate(language, 'deleted_in') + " " + Math.trunc(remainingDays) + " " + Traduction.translate(language, 'days');
    }
    else {
      label = Traduction.translate(language, 'deleted_today');
    }

    if(remainingDays) {
      if(Math.trunc(remainingDays) > 7) {
        return <div className="axe bordered bg-grey white" style={{ borderColor: '#8E8E90' }}>{label}</div>;
      }
      else if(Math.trunc(remainingDays) > 2) {
        return <div className="axe bordered bg-orange white" style={{ borderColor: '#F57C12' }}>{label}</div>;
      }
      else {
        return <div className="axe bordered bg-red white" style={{ borderColor: '#E21313' }}>{label}</div>;
      }
    }
    else {
      return null;
    }
  }

  templateColumnResourceName(props) {
    const itemId = props.Item_ID;
    const itemType = props.Item_Type;
    const name = props.Name;

    if(name === '- Not assigned -') {
      let firstname = 'N';
      let lastname = 'A';

      return <div className="align-baseline">
        <span className="iconEmptyResourceTable icons aligncenter blue">{firstname + lastname}</span>
        <span className="ml10 grey">{name}</span>
      </div>;
    }
    else if(name === '- To be assigned -') {
      let firstname = 'T';
      let lastname = 'B';

      return <div className="align-baseline">
        <span className="iconEmptyResourceTable icons aligncenter blue">{firstname + lastname}</span>
        <span className="ml10 grey">{name}</span>
      </div>;
    }
    else if(name) {
      let firstname, lastname;
      let split = name.split(' ');

      if(split.length === 1) {
        firstname = name.split(' ')[0].substring(0,1);
        lastname = '';
      }
      else if(split.length === 2) {
        firstname = name.split(' ')[0].substring(0,1);
        lastname = name.split(' ')[1].substring(0,1);
      }
      else {
        firstname = name.split(' ')[0].substring(0,1);
        lastname = name.split(' ')[split.length-1].substring(0,1);
      }

      return <div className="align-baseline">
        <a target="_self" href={`/Card/${itemType}/${itemId.substring(1)}/Home`}><div className="iconResourceTable icons aligncenter"><span className="white">{firstname + lastname}</span></div></a>
        <span className="ml10 grey">{name}</span>
      </div>;
    }
    else {
      return null;
    }
  }

  templateColumnResources(props) {
    const language = this.state.language;
    const itemId = props.Item_ID;
    const itemType = props.Item_Type;

    if(!props.column) {
      return null;
    }

    // Get Column Name (Remove Suffix .Label to Object Fields)
    const columnName = props.column.field.replace(".Label", "");
    const values = props[columnName];

    const editionItemTypes = this.getColumnEditionItemType(columnName);
    const restrictedValues = this.getColumnRestrictedValues(columnName);

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverEditObjectTable ItemId={itemId.substring(1)} ItemType={itemType} ColumnName={columnName} ObjectTableValues={values} RestrictedValues={restrictedValues} onObjectTableEdit={this.editObjectTable}></PopoverEditObjectTable>
        </Popover.Content>
      </Popover>
    );
    
    if(values) {
      // Editable with Popover
      if(editionItemTypes.includes(itemType)) {
        return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
          <div className="width100p">
            <div className="inline-flex">
              {values.slice(0, 4).map((value, index) => {
                if(index < 3) {
                  let firstname, lastname;
                  const split = value.Label.split(' ');
            
                  if(split.length === 1) {
                    firstname = value.Label.split(' ')[0].substring(0,1);
                    lastname = '';
                  }
                  else if(split.length === 2) {
                    firstname = value.Label.split(' ')[0].substring(0,1);
                    lastname = value.Label.split(' ')[1].substring(0,1);
                  }
                  else {
                    firstname = value.Label.split(' ')[0].substring(0,1);
                    lastname = value.Label.split(' ')[split.length-1].substring(0,1);
                  }

                  if(value.Id === 0) {
                    return <div key={index} className="align-baseline align-items-center mr5">{value.Label}</div>;
                  }
                  else {
                    return <div key={index} className="align-baseline mr5"><span className="iconResourceTable icons aligncenter white">{firstname + lastname}</span></div>;
                  }
                }
                else {
                  let label;
                  let count = values.length - 3;

                  if(count === 1) {
                    label = "+ " + count + " " + Traduction.translate(language, 'other_user');
                  }
                  else {
                    label = "+ " + count + " " + Traduction.translate(language, 'other_users');
                  }

                  return <div key={index} className="align-baseline align-items-center mr5">{label}</div>;
                }
              })}
            </div>
          </div>
        </OverlayTrigger>;
      }
      // Non Editable
      else {
        return <div className="inline-flex">
          {values.slice(0, 4).map((value, index) => {
            if(index < 3) {
              let firstname, lastname;
              const split = value.Label.split(' ');
        
              if(split.length === 1) {
                firstname = value.Label.split(' ')[0].substring(0,1);
                lastname = '';
              }
              else if(split.length === 2) {
                firstname = value.Label.split(' ')[0].substring(0,1);
                lastname = value.Label.split(' ')[1].substring(0,1);
              }
              else {
                firstname = value.Label.split(' ')[0].substring(0,1);
                lastname = value.Label.split(' ')[split.length-1].substring(0,1);
              }

              if(value.Id === 0) {
                return <div key={index} className="align-baseline align-items-center mr5">{value.Label}</div>;
              }
              else {
                return <div key={index} className="align-baseline mr5"><span className="iconResourceTable icons aligncenter white">{firstname + lastname}</span></div>;
              }
            }
            else {
              let label;
              let count = values.length - 3;

              if(count === 1) {
                label = "+ " + count + " " + Traduction.translate(language, 'other_user');
              }
              else {
                label = "+ " + count + " " + Traduction.translate(language, 'other_users');
              }

              return <div key={index} className="align-baseline align-items-center mr5">{label}</div>;
            }
          })}
        </div>;
      }
    }
    else {
      return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}><div className="emptyCellTable"></div></OverlayTrigger>;
    }
  }

  templateColumnRowType(props) {
    const rowType = props.RowType;

    if(rowType === 'Holiday') {
      return <div className="turquoise bold"><span className="iconHolidays tableIcon verysmallIcons"></span>{rowType}</div>;
    }
    else if(rowType === 'Forecast') {
      return <div className="blue"><span className="iconHourglass tableIcon verysmallIcons"></span>{rowType}</div>;
    }
    else if(rowType === 'TimeTracking') {
      return <div className="blue bold"><span className="iconTimeTrackingBlue tableIcon verysmallIcons"></span>{rowType}</div>;
    }
    else {
      return <div className="">{rowType}</div>;
    }
  }

  templateColumnSeverity(props) {
    const impact = props.Impact;
    const probability = props.Probability;
    const severity = props.Severity;

    // Impact (0: Very Low, 1: Low, 2: Medium, 3: High, 4: Critical)
    // Probability (0: Very Low, 1: Low, 2: Medium, 3: High, 4: Critical)
    if(impact && probability) {
      switch(impact.Id) {
        case 0:
          switch(probability.Id) {
            case 0:
                return <div className="brd-radius mat-index bg-verylow-verylow white">{severity}</div>;
            case 1:
                return <div className="brd-radius mat-index bg-verylow-low white">{severity}</div>;
            case 2:
                return <div className="brd-radius mat-index bg-verylow-medium white">{severity}</div>;
            case 3:
                return <div className="brd-radius mat-index bg-verylow-high white">{severity}</div>;
            case 4:
                return <div className="brd-radius mat-index bg-verylow-critical white">{severity}</div>;
            default:
                return null;
          }
        case 1:
          switch(probability.Id) {
            case 0:
                return <div className="brd-radius mat-index bg-low-verylow white">{severity}</div>;
            case 1:
                return <div className="brd-radius mat-index bg-low-low white">{severity}</div>;
            case 2:
                return <div className="brd-radius mat-index bg-low-medium white">{severity}</div>;
            case 3:
                return <div className="brd-radius mat-index bg-low-high white">{severity}</div>;
            case 4:
                return <div className="brd-radius mat-index bg-low-critical white">{severity}</div>;
            default:
                return null;
          }
        case 2:
          switch(probability.Id) {
            case 0:
                return <div className="brd-radius mat-index bg-medium-verylow white">{severity}</div>;
            case 1:
                return <div className="brd-radius mat-index bg-medium-low white">{severity}</div>;
            case 2:
                return <div className="brd-radius mat-index bg-medium-medium white">{severity}</div>;
            case 3:
                return <div className="brd-radius mat-index bg-medium-high white">{severity}</div>;
            case 4:
                return <div className="brd-radius mat-index bg-medium-critical white">{severity}</div>;
            default:
                return null;
          }
        case 3:
          switch(probability.Id) {
            case 0:
                return <div className="brd-radius mat-index bg-high-verylow white">{severity}</div>;
            case 1:
                return <div className="brd-radius mat-index bg-high-low white">{severity}</div>;
            case 2:
                return <div className="brd-radius mat-index bg-high-medium white">{severity}</div>;
            case 3:
                return <div className="brd-radius mat-index bg-high-high white">{severity}</div>;
            case 4:
                return <div className="brd-radius mat-index bg-high-critical white">{severity}</div>;
            default:
                return null;
          }
        case 4:
          switch(probability.Id) {
            case 0:
                return <div className="brd-radius mat-index bg-critical-verylow white">{severity}</div>;
            case 1:
                return <div className="brd-radius mat-index bg-critical-low white">{severity}</div>;
            case 2:
                return <div className="brd-radius mat-index bg-critical-medium white">{severity}</div>;
            case 3:
                return <div className="brd-radius mat-index bg-critical-high white">{severity}</div>;
            case 4:
                return <div className="brd-radius mat-index bg-critical-critical white">{severity}</div>;
            default:
                return null;
          }
        default:
            return null;
      }
    }
    else {
      return null;
    }
  }

  templateColumnSprint(props) {
    const blockType = this.state.blockType;
    const itemId = props.Item_ID;
    const itemType = props.Item_Type;
    const sprint = props.Sprint;
    let backColor, foreColor, borderColor;
    
    if(!props.column) {
      return null;
    }

    // Get Column Name (Remove Suffix .Label to Object Fields)
    const columnName = props.column.field.replace(".Label", "");

    const editionItemTypes = this.getColumnEditionItemType(columnName);
    const restrictedValues = this.getColumnRestrictedValues(columnName);

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverEditAxe ItemId={itemId.substring(1)} ItemType={itemType} ColumnName={columnName} AxeValue={sprint} RestrictedValues={restrictedValues} onAxeEdit={this.editAxe}></PopoverEditAxe>
        </Popover.Content>
      </Popover>
    );

    // Back, Fore & Border Color
    if(sprint) {
      if(!sprint.BackColor) {
        backColor = '#FFFFFF';
      }
      else {
        backColor = sprint.BackColor;
      }
      if(!sprint.ForeColor) {
        foreColor = '#0088C7';
        borderColor = '#0088C7';
      }
      else {
        if((sprint.ForeColor === '#FFFFFF' || sprint.ForeColor === '#ffffff')) {
          borderColor = sprint.BackColor;
        }
        else {
          borderColor = sprint.ForeColor;
        }

        foreColor = sprint.ForeColor;
      }
    }

    if(sprint.Label === '- NA -') {
      // Editable with Popover
      if(editionItemTypes.includes(itemType)) {
        return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
          <div className="width100p"><div className="axe brd-dashed very-light-grey">{sprint.Label}</div></div>
        </OverlayTrigger>;
      }
      // Non Editable
      else {
        return <div className="axe brd-dashed very-light-grey">{sprint.Label}</div>;
      }
    }
    else if(sprint.Label) {
      // Editable with Popover
      if(editionItemTypes.includes(itemType)) {
        return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
          <div className="width100p">
            <div className="axe bordered" style={{ background: backColor, color: foreColor, borderColor: borderColor }}>
              {(backColor === '#FFFFFF' || backColor === '#ffffff') && <a target="_self" href={`/Card/${columnName}/${sprint.Id}/${blockType}`}><span className="iconSprint verysmallIcons mr5"></span></a>}
              {(backColor !== '#FFFFFF' && backColor !== '#ffffff') && <a target="_self" href={`/Card/${columnName}/${sprint.Id}/${blockType}`}><span className="iconSprintWhite verysmallIcons mr5"></span></a>}
              <span className="">{sprint.Label}</span>
            </div>
          </div>
        </OverlayTrigger>;
      }
      // Non Editable
      else {
        return <div className="axe bordered" style={{ background: backColor, color: foreColor, borderColor: borderColor }}>
          {(backColor === '#FFFFFF' || backColor === '#ffffff') && <a target="_self" href={`/Card/${columnName}/${sprint.Id}/${blockType}`}><span className="iconSprint verysmallIcons mr5"></span></a>}
          {(backColor !== '#FFFFFF' && backColor !== '#ffffff') && <a target="_self" href={`/Card/${columnName}/${sprint.Id}/${blockType}`}><span className="iconSprintWhite verysmallIcons mr5"></span></a>}
          <span className="">{sprint.Label}</span>
        </div>;
      }
    }
    else {
      return null;
    }
  }

  templateColumnStatus(props) {
    const itemId = props.Item_ID;
    const itemType = props.Item_Type;
    const status = props.Status;

    if(!props.column) {
      return null;
    }

    // Get Column Name (Remove Suffix .Label to Object Fields)
    const columnName = props.column.field.replace(".Label", "");

    const editionItemTypes = this.getColumnEditionItemType(columnName);
    const restrictedValues = this.getColumnRestrictedValues(columnName);

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverEditAxe ItemId={itemId.substring(1)} ItemType={itemType} ColumnName={columnName} AxeValue={status} RestrictedValues={restrictedValues} onAxeEdit={this.editAxe}></PopoverEditAxe>
        </Popover.Content>
      </Popover>
    );

    // Status (1: To be Done, 2: In Progress, 3: On Hold, 4: Cancelled, 5: Completed, 6: Open, 7: Closed)
    // Status (8: Realised, 9: To be Scheduled, 10: Scheduled, 11: Taken, 12: To be Taken)
    switch(status.Id) {
      case 1:
      case 9:
          // Editable with Popover
          if(editionItemTypes.includes(itemType)) {
            return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
                <div className="width100p"><div className="axe brd-radius bg-grey white">{status.Label}</div></div>
              </OverlayTrigger>;
          }
          // Non Editable
          else {
            return <div className="axe brd-radius bg-grey white">{status.Label}</div>;
          }
      case 2:
      case 10:
          // Editable with Popover
          if(editionItemTypes.includes(itemType)) {
            return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
                <div className="width100p"><div className="axe brd-radius bg-blue white">{status.Label}</div></div>
              </OverlayTrigger>;
          }
          // Non Editable
          else {
            return <div className="axe brd-radius bg-blue white">{status.Label}</div>;
          }
      case 3:
          // Editable with Popover
          if(editionItemTypes.includes(itemType)) {
            return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
                <div className="width100p"><div className="axe bordered orange">{status.Label}</div></div>
              </OverlayTrigger>;
          }
          // Non Editable
          else {
            return <div className="axe bordered orange">{status.Label}</div>;
          }
      case 4:
          // Editable with Popover
          if(editionItemTypes.includes(itemType)) {
            return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
                <div className="width100p"><div className="axe bordered grey">{status.Label}</div></div>
              </OverlayTrigger>;
          }
          // Non Editable
          else {
            return <div className="axe bordered grey">{status.Label}</div>;
          }
      case 5:
      case 7:
      case 11:
          // Editable with Popover
          if(editionItemTypes.includes(itemType)) {
            return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
                <div className="width100p"><div className="axe brd-radius bg-green white">{status.Label}</div></div>
              </OverlayTrigger>;
          }
          // Non Editable
          else {
            return <div className="axe brd-radius bg-green white">{status.Label}</div>;
          }
      case 6:
      case 12:
          // Editable with Popover
          if(editionItemTypes.includes(itemType)) {
            return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
                <div className="width100p"><div className="axe brd-radius bg-orange white">{status.Label}</div></div>
              </OverlayTrigger>;
          }
          // Non Editable
          else {
            return <div className="axe brd-radius bg-orange white">{status.Label}</div>;
          }
      case 8:
          // Editable with Popover
          if(editionItemTypes.includes(itemType)) {
            return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
                <div className="width100p"><div className="axe brd-radius bg-red white">{status.Label}</div></div>
              </OverlayTrigger>;
          }
          // Non Editable
          else {
            return <div className="axe brd-radius bg-red white">{status.Label}</div>;
          }
      default:
          return null;
    }
  }

  templateColumnTask(props) {
    const task = props.Task;

    if(!props.column) {
      return null;
    }

    // Get Column Name (Remove Suffix .Label to Object Fields)
    const columnName = props.column.field.replace(".Label", "");

    if(task) {
      if(task.Label === '- NA -') {
        return <div className="minitableAxe brd-dashed very-light-grey">{task.Label}</div>;
      }
      else if(task.Label) {
        return <div className="minitableAxe bordered blue">
          <a target="_self" href={`/Card/${columnName}/${task.Id}/Details`}><span className="iconTask verysmallIcons mr5"></span></a>
          <span className="">{task.Label}</span>
        </div>;
      }
      else {
        return null;
      }
    }
    else {
      return null;
    }
  }

  templateColumnTimeline(props) {
    let status = props.Status;
    let startDate = props.StartDate;
    let endDate = props.EndDate;

    // Create Timeline with different colors based on status, StartDate and EndDate
    if(status && startDate && endDate) {
      // Format Date to English format (it is Date object after modification in Table Datepicker)
      if(startDate instanceof Date) {
        startDate = startDate.toLocaleDateString("en-US", { year: 'numeric', month: '2-digit', day: '2-digit' });
      }
      if(endDate instanceof Date) {
        endDate = endDate.toLocaleDateString("en-US", { year: 'numeric', month: '2-digit', day: '2-digit' });
      }

      return <Timeline View={'Block'} Status={status} StartDate={startDate} EndDate={endDate}></Timeline>;
    }
    else {
      return null;
    }
  }

  templateColumnTrend(props) {
    const itemId = props.Item_ID;
    const itemType = props.Item_Type;
    const trend = props.Trend;

    if(!props.column) {
      return null;
    }

    // Get Column Name (Remove Suffix .Label to Object Fields)
    const columnName = props.column.field.replace(".Label", "");

    const editionItemTypes = this.getColumnEditionItemType(columnName);
    const restrictedValues = this.getColumnRestrictedValues(columnName);

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverEditAxe ItemId={itemId.substring(1)} ItemType={itemType} ColumnName={columnName} AxeValue={trend} RestrictedValues={restrictedValues} onAxeEdit={this.editAxe}></PopoverEditAxe>
        </Popover.Content>
      </Popover>
    );

    // Trend (0: None, 1: Down ⬊, 2: Right ⮕, 3: Up ⬈)
    switch(trend.Id) {
      case 0:
          // Editable with Popover
          if(editionItemTypes.includes(itemType)) {
            return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
              <div className="width100p"><span className="iconsTable"></span></div>
            </OverlayTrigger>;
          }
          // Non Editable
          else {
            return null;
          }
      case 1:
          // Editable with Popover
          if(editionItemTypes.includes(itemType)) {
            return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
              <div className="width100p"><span className="iconTrendDown iconsTable"></span></div>
            </OverlayTrigger>;
          }
          // Non Editable
          else {
            return <div className="iconTrendDown iconsTable"></div>;
          }
      case 2:
          // Editable with Popover
          if(editionItemTypes.includes(itemType)) {
            return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
              <div className="width100p"><span className="iconTrendRight iconsTable"></span></div>
            </OverlayTrigger>;
          }
          // Non Editable
          else {
            return <div className="iconTrendRight iconsTable"></div>;
          }
      case 3:
          // Editable with Popover
          if(editionItemTypes.includes(itemType)) {
            return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
              <div className="width100p"><span className="iconTrendUp iconsTable"></span></div>
            </OverlayTrigger>;
          }
          // Non Editable
          else {
            return <div className="iconTrendUp iconsTable"></div>;
          }
      default:
          return null;
    }
  }

  templateColumnValidated(props) {
    const itemId = props.Item_ID;
    const itemType = props.Item_Type;

    if(!props.column) {
      return null;
    }

    // Get Column Name 
    const columnName = props.column.field;
    const value = props[columnName];

    const allowEditing = props.column.allowEditing;
    const id = itemId + " " + itemType + " " + columnName + " " + allowEditing;

    if(value == 'True') {
      return <div className="booleanTable">
        <div id={id} className="boolean iconBooleanTrue iconCheck iconsTable"></div>
      </div>;
    }
    else if(value == 'False') {
      return <div className="booleanTable">
        <div id={id} className="boolean iconBooleanFalse iconCircleGrey iconsTable"></div>
      </div>;
    }
    else {
      return null;
    }
  }

  templateColumnWarning(props) {
    const warningMessage = props.WarningMessage;

    if(warningMessage) {
      return <OverlayTrigger key="right" placement="right" overlay={<Tooltip id="tooltip-right" className="tooltip-warning">{warningMessage}</Tooltip>}>
        <Badge className="badgeTable bg-white" pill><i className="iconWarningRed verysmallIcons"/></Badge>
      </OverlayTrigger>;
    }
    else {
      return null;
    }
  }

  templateColumnWorkpackage(props) {
    let blockType = this.state.blockType;
    const itemId = props.Item_ID;
    const itemType = props.Item_Type;
    const workpackage = props.Workpackage;

    if(!props.column) {
      return null;
    }

    // Get Column Name (Remove Suffix .Label to Object Fields)
    const columnName = props.column.field.replace(".Label", "");

    const editionItemTypes = this.getColumnEditionItemType(columnName);
    const restrictedValues = this.getColumnRestrictedValues(columnName);

    // Replace BlockType by Details in Link for Timetraking
    if(blockType === 'TimeTracking') {
      blockType = "Details";
    }

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverEditAxe ItemId={itemId.substring(1)} ItemType={itemType} ColumnName={columnName} AxeValue={workpackage} RestrictedValues={restrictedValues} onAxeEdit={this.editAxe}></PopoverEditAxe>
        </Popover.Content>
      </Popover>
    );

    if(workpackage.Label === '- NA -') {
      // Editable with Popover
      if(editionItemTypes.includes(itemType)) {
        return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
          <div className="width100p"><div className="axe brd-dashed very-light-grey">{workpackage.Label}</div></div>
        </OverlayTrigger>;
      }
      // Non Editable
      else {
        return <div className="axe brd-dashed very-light-grey">{workpackage.Label}</div>;
      }
    }
    else if(workpackage.Label) {
      // Editable with Popover
      if(editionItemTypes.includes(itemType)) {
        return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
          <div className="width100p">
            <div className="axe bordered blue">
              <a target="_self" href={`/Card/${columnName}/${workpackage.Id}/${blockType}`}><span className="iconWorkpackage verysmallIcons mr5"></span></a>
              <span className="">{workpackage.Label}</span>
            </div>
          </div>
        </OverlayTrigger>;
      }
      // Non Editable
      else {
        return <div className="axe bordered blue">
          <a target="_self" href={`/Card/${columnName}/${workpackage.Id}/${blockType}`}><span className="iconWorkpackage verysmallIcons mr5"></span></a>
          <span className="">{workpackage.Label}</span>
        </div>;
      }
    }
    else {
      return null;
    }
  }

  templateColumnYear(props) {
    if(!props.column) {
      return null;
    }

    // Get Column Name and Value
    const columnName = props.column.field;
    const value = props[columnName];

    return <div className="">{value}</div>;
  }

  // ----- ----- Type
  templateTypeAxe(props) {
    const itemId = props.Item_ID;
    const itemType = props.Item_Type;
    let backColor, foreColor, borderColor;

    if(!props.column) {
      return null;
    }

    // Get Column Name (Remove Suffix .Label to Object Fields)
    const columnName = props.column.field.replace(".Label", "");
    const value = props[columnName];

    const editionItemTypes = this.getColumnEditionItemType(columnName);
    const restrictedValues = this.getColumnRestrictedValues(columnName);

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverEditAxe ItemId={itemId.substring(1)} ItemType={itemType} ColumnName={columnName} AxeValue={value} RestrictedValues={restrictedValues} onAxeEdit={this.editAxe}></PopoverEditAxe>
        </Popover.Content>
      </Popover>
    );

    // Back, Fore & Border Color
    if(value) {
      if(!value.BackColor) {
        backColor = '#FFFFFF';
      }
      else {
        backColor = value.BackColor;
      }
      if(!value.ForeColor) {
        foreColor = '#0088C7';
        borderColor = '#0088C7';
      }
      else {
        if((value.ForeColor === '#FFFFFF' || value.ForeColor === '#ffffff')) {
          borderColor = value.BackColor;
        }
        else {
          borderColor = value.ForeColor;
        }

        foreColor = value.ForeColor;
      }
    }

    if(value.Label === '- NA -') {
      // Editable with Popover
      if(editionItemTypes.includes(itemType)) {
        return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
          <div className="width100p"><div className="axe brd-dashed very-light-grey">{value.Label}</div></div>
        </OverlayTrigger>;
      }
      // Non Editable
      else {
        return <div className="axe brd-dashed very-light-grey">{value.Label}</div>;
      }
    }
    else if(value.Label) {
      // Editable with Popover
      if(editionItemTypes.includes(itemType)) {
        return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
          <div className="width100p"><div className="axe bordered" style={{ background: backColor, color: foreColor, borderColor: borderColor }}>{value.Label}</div></div>
        </OverlayTrigger>;
      }
      // Non Editable
      else {
        return <div className="axe bordered" style={{ background: backColor, color: foreColor, borderColor: borderColor }}>{value.Label}</div>;
      }
    }
    else {
      return null;
    }
  }

  templateTypeAxisTable(props) {
    const itemId = props.Item_ID;
    const itemType = props.Item_Type;

    if(!props.column) {
      return null;
    }

    // Get Column Name (Remove Suffix .Label to Object Fields)
    const columnName = props.column.field.replace(".Label", "");
    const values = props[columnName];

    const editionItemTypes = this.getColumnEditionItemType(columnName);
    const restrictedValues = this.getColumnRestrictedValues(columnName);

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverEditObjectTable ItemId={itemId.substring(1)} ItemType={itemType} ColumnName={columnName} ObjectTableValues={values} RestrictedValues={restrictedValues} onObjectTableEdit={this.editObjectTable}></PopoverEditObjectTable>
        </Popover.Content>
      </Popover>
    );
    
    if(values) {
      // Editable with Popover
      if(editionItemTypes.includes(itemType)) {
        return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
          <div className="width100p">
            <div className="cellTable inline-flex">
              {values.map((value, index) => {
                let backColor, foreColor, borderColor;

                // Back, Fore & Border Color
                if(value) {
                  if(!value.BackColor) {
                    backColor = '#FFFFFF';
                  }
                  else {
                    backColor = value.BackColor;
                  }
                  if(!value.ForeColor) {
                    foreColor = '#0088C7';
                    borderColor = '#0088C7';
                  }
                  else {
                    if((value.ForeColor === '#FFFFFF' || value.ForeColor === '#ffffff')) {
                      borderColor = value.BackColor;
                    }
                    else {
                      borderColor = value.ForeColor;
                    }
      
                    foreColor = value.ForeColor;
                  }
                }

                return <div key={index} className={"axisTable bordered" + (index < values.length - 1 ? " mr10" : "")} style={{ background: backColor, color: foreColor, borderColor: borderColor }}>{value.Label}</div>;
              })}
            </div>
          </div>
        </OverlayTrigger>;
      }
      // Non Editable
      else {
        return <div className="inline-flex">
          {values.map((value, index) => {
            let backColor, foreColor, borderColor;

            // Back, Fore & Border Color
            if(value) {
              if(!value.BackColor) {
                backColor = '#FFFFFF';
              }
              else {
                backColor = value.BackColor;
              }
              if(!value.ForeColor) {
                foreColor = '#0088C7';
                borderColor = '#0088C7';
              }
              else {
                if((value.ForeColor === '#FFFFFF' || value.ForeColor === '#ffffff')) {
                  borderColor = value.BackColor;
                }
                else {
                  borderColor = value.ForeColor;
                }
  
                foreColor = value.ForeColor;
              }
            }

            return <div key={index} className={"axisTable bordered" + (index < values.length - 1 ? " mr10" : "")} style={{ background: backColor, color: foreColor, borderColor: borderColor }}>{value.Label}</div>;
          })}
        </div>;
      }
    }
    else {
      return null;
      // return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}><div className="emptyCellTable"></div></OverlayTrigger>;
    }
  }

  templateTypeBoolean(props) {
    const itemId = props.Item_ID;
    const itemType = props.Item_Type;

    if(!props.column) {
      return null;
    }

    // Get Column Name and Value
    const columnName = props.column.field;
    const value = props[columnName];

    const allowEditing = props.column.allowEditing;
    const id = itemId + " " + itemType + " " + columnName + " " + allowEditing;

    if(value == 'True') {
      return <div className="booleanTable">
        <div id={id} className="boolean iconBooleanTrue iconCheck iconsTable"></div>
      </div>;
    }
    else if(value == 'False') {
      return <div className="booleanTable">
        <div id={id} className="boolean iconBooleanFalse iconCircleGrey iconsTable"></div>
      </div>;
    }
    else {
      return null;
    }
  }

  templateTypeDate(props) {
    // const language = this.state.language;
    const formatDate = this.state.formatDate;
    let date;

    if(!props.column) {
      return null;
    }

    // Get Column Name and Value
    const columnName = props.column.field;
    const value = props[columnName];

    if(value) {
      if(formatDate === 'MM/DD/YYYY') {
        date = new Date(value).toLocaleDateString("en-US", { year: 'numeric', month: '2-digit', day: '2-digit' });
      }
      else if(formatDate === 'DD/MM/YYYY') {
        date = new Date(value).toLocaleDateString("fr-FR");
      }
      else if(formatDate === 'YYYY-MM-DD') {
        date = this.formatDateKr(new Date(value));
      }
      // if(language === 'English') {
      //   date = new Date(value).toLocaleDateString("en-US", { year: 'numeric', month: '2-digit', day: '2-digit' });
      // }
      // else if(language === 'Français') {
      //   date = new Date(value).toLocaleDateString("fr-FR");
      // }

      // date = new Date(value).toLocaleDateString();
      // formattedDate = date.split(/,| /)[0];
    }

    return <div className="">{date}</div>;
  }

  templateTypeDouble(props) {
    if(!props.column) {
      return null;
    }
    
    // Get Column Name and Value
    const columnName = props.column.field;
    const value = props[columnName];

    const conditionalFormattings = this.getColumnConditionalFormattings(columnName);
    const conditionalFormatting = this.getCellConditionalFormatting(conditionalFormattings, value);
    const unit = this.getColumnUnit(columnName);

    if(conditionalFormatting) {
      if(value || value === 0) {
        if(unit) {
          return <div className="conditionalFormatting" style={{ background: conditionalFormatting.BackGroundColor, color: conditionalFormatting.Color, borderColor: conditionalFormatting.Color }}>{value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " " + unit}</div>;
        }
        else {
          return <div className="conditionalFormatting" style={{ background: conditionalFormatting.BackGroundColor, color: conditionalFormatting.Color, borderColor: conditionalFormatting.Color }}>{value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}</div>;
        }
      }
      else {
        return null;
      }
    }
    else {
      if(value || value === 0) {
        if(unit) {
          return <div className="">{value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " " + unit}</div>;
        }
        else {
          return <div className="">{value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}</div>;
        }
      }
      else {
        return null;
      }
    }
  }

  templateTypeFiles(props) {
    if(!props.column) {
      return null;
    }

    // Get Column Name (Remove Suffix .Label to Object Fields)
    const columnName = props.column.field.replace(".Label", "");
    const values = props[columnName];
    
    if(values) {
      return <div className="width100p">
        <div className="cellTable inline-flex">
          {values.map((value, index) => {
            if(value && value.Label) {
              let split = value.Label.split('.');
              let extension = split[split.length - 1];

              if(extension === 'docx') {
                return <div key={index} className={"inline-flex align-items-center" + (index < values.length - 1 ? " mr10" : "")}>
                  <span className="iconWord iconsTable mr10"></span>
                  <span className="">{value.Label}</span>
                </div>;
              }
              else if(extension === 'pdf') {
                return <div key={index} className={"inline-flex align-items-center" + (index < values.length - 1 ? " mr10" : "")}>
                  <span className="iconPDF iconsTable mr10"></span>
                  <span className="">{value.Label}</span>
                </div>;
              }
              else if(extension === 'pptx') {
                return <div key={index} className={"inline-flex align-items-center" + (index < values.length - 1 ? " mr10" : "")}>
                  <span className="iconPPT iconsTable mr10"></span>
                  <span className="">{value.Label}</span>
                </div>;
              }
              else if(extension === 'xlsx') {
                return <div key={index} className={"inline-flex align-items-center" + (index < values.length - 1 ? " mr10" : "")}>
                  <span className="iconExcel iconsTable mr10"></span>
                  <span className="">{value.Label}</span>
                </div>;
              }
              else {
                return <div key={index} className={"inline-flex align-items-center" + (index < values.length - 1 ? " mr10" : "")}>
                  <span className="iconFile iconsTable mr10"></span>
                  <span className="">{value.Label}</span>
                </div>;
              }
            }
            else {
              return null;
            }
          })}
        </div>
      </div>;
    }
    else {
      // return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}><div className="emptyCellTable"></div></OverlayTrigger>;
      return null;
    }
  }

  templateTypeFormula(props) {
    if(!props.column) {
      return null;
    }
    
    // Get Column Name and Value
    const columnName = props.column.field;
    const value = props[columnName];

    return <div dangerouslySetInnerHTML={{ __html: value }}/>;
  }

  templateTypeHTML(props) {
    if(!props.column) {
      return null;
    }
    
    // Get Column Name and Value
    const columnName = props.column.field;
    const value = props[columnName];

    return <div dangerouslySetInnerHTML={{ __html: value }}/>;
  }

  templateTypeLink(props) {
    if(!props.column) {
      return null;
    }

    // Get Column Name and Value
    const columnName = props.column.field;
    const value = props[columnName];

    const split = value.split('/');
    const shortName = split[split.length-1];

    if(value) {
      return <div title={value} className=""><a target="_self" className="" href={value}><span className="treeIcon iconLink iconsPath" alt="Link"></span>{shortName}</a></div>;
    }
    else {
      return null;
    }
  }

  templateTypeLocation(props) {
    const itemId = props.Item_ID;
    const itemType = props.Item_Type;

    if(!props.column) {
      return null;
    }

    // Get Column Name (Remove Suffix .Label to Object Fields)
    const columnName = props.column.field.replace(".Label", "");
    const value = props[columnName];

    const columnType = this.getColumnType(columnName);
    const editionItemTypes = this.getColumnEditionItemType(columnName);
    const restrictedValues = this.getColumnRestrictedValues(columnName);

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverEditAxe ItemId={itemId.substring(1)} ItemType={itemType} ColumnName={columnName} ColumnType={columnType} AxeValue={value} RestrictedValues={restrictedValues} onAxeEdit={this.editAxe}></PopoverEditAxe>
        </Popover.Content>
      </Popover>
    );

    if(value.Label === '- NA -') {
      // Editable with Popover
      if(editionItemTypes.includes(itemType)) {
        return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
          <div className="width100p"><div className="axe brd-dashed very-light-grey">{value.Label}</div></div>
        </OverlayTrigger>;
      }
      // Non Editable
      else {
        return <div className="axe brd-dashed very-light-grey">{value.Label}</div>;
      }
    }
    else if(value.Label) {
      // Editable with Popover
      if(editionItemTypes.includes(itemType)) {
        return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
          <div className="width100p">
            <span className="iconMap verysmallIcons"></span>
            <span className="">{value.Label}</span>
          </div>
        </OverlayTrigger>;
      }
      // Non Editable
      else {
        return <div className="">
        <span className="iconMap verysmallIcons"></span>
        <span className="">{value.Label}</span>
      </div>;
      }
    }
    else {
      return null;
    }
  }

  templateTypePercentage(props) {
    if(!props.column) {
      return null;
    }

    // Get Column Name and Value
    const columnName = props.column.field;
    const value = props[columnName];

    const percentage = value;
    // const percentage = (value * 100).toFixed(0);

    if(value) {
      if(percentage == 100) {
        return <div className="progressBlock">
          <span className="progressLabel">{`${percentage} %`}</span>
          <span className="progressBar"><ProgressBar className="progressTable" variant="success" now={percentage}></ProgressBar></span>
        </div>;
      }
      else if(percentage < 100 && percentage >= 0) {
        return <div className="progressBlock">
          <span className="progressLabel">{`${percentage} %`}</span>
          <span className="progressBar"><ProgressBar className="progressTable" variant="primary" now={percentage}></ProgressBar></span>
        </div>;
      }
      else if(percentage < 0) {
        return <div className="progressBlock">
          <span className="progressLabel">{`${percentage} %`}</span>
          <span className="progressBar"><ProgressBar className="progressTable" variant="danger" now={Math.abs(percentage)}></ProgressBar></span>
        </div>;
      }
      else {
        return <div className="progressBlock">
          <span className="progressLabel">{`${percentage} %`}</span>
          <span className="progressBar"><ProgressBar className="progressTable" variant="danger" now={'100'}></ProgressBar></span>
        </div>;
      }
    }
    else {
      return null;
    }
  }

  templateTypeProgress(props) {
    if(!props.column) {
      return null;
    }

    // Get Column Name and Value
    const columnName = props.column.field;
    const value = props[columnName];

    let progress;
    
    if(value) {
      progress = (value.replace(',', '.') * 100).toFixed(0);

      if(progress == 100) {
        return <div className="progressBlock">
          <span className="progressLabel">{`${progress} %`}</span>
          <span className="progressBar"><ProgressBar className="progressTable" variant="success" now={progress}></ProgressBar></span>
        </div>;
      }
      else if(progress < 100 && progress >= 0) {
        return <div className="progressBlock">
          <span className="progressLabel">{`${progress} %`}</span>
          <span className="progressBar"><ProgressBar className="progressTable" variant="primary" now={progress}></ProgressBar></span>
        </div>;
      }
      else if(progress < 0) {
        return <div className="progressBlock">
          <span className="progressLabel">{`${progress} %`}</span>
          <span className="progressBar"><ProgressBar className="progressTable" variant="danger" now={Math.abs(progress)}></ProgressBar></span>
        </div>;
      }
      else {
        return <div className="progressBlock">
          <span className="progressLabel">{`${progress} %`}</span>
          <span className="progressBar"><ProgressBar className="progressTable" variant="danger" now={'100'}></ProgressBar></span>
        </div>;
      }
    }
    else {
      return <div className="progressBlock">
        <span className="progressLabel">{`0 %`}</span>
        <span className="progressBar"><ProgressBar className="progressTable" variant="danger" now={'0'}></ProgressBar></span>
      </div>;
    }
  }

  templateTypeRating(props) {
    const itemId = props.Item_ID;
    const itemType = props.Item_Type;

    if(!props.column) {
      return null;
    }

    // Get Column Name and Value
    const columnName = props.column.field;
    const value = props[columnName];

    const allowEditing = props.column.allowEditing;
    const id = itemId + " " + itemType + " " + columnName + " " + allowEditing + " " + value;

    if(value == 5) {
      return <div className="ratingTable">
        <div id={id + " 1"} className="rating iconRating iconsRating mr10"></div>
        <div id={id + " 2"} className="rating iconRating iconsRating mr10"></div>
        <div id={id + " 3"} className="rating iconRating iconsRating mr10"></div>
        <div id={id + " 4"} className="rating iconRating iconsRating mr10"></div>
        <div id={id + " 5"} className="rating iconRating iconsRating mr10"></div>
      </div>;
    }
    if(value == 4) {
      return <div className="ratingTable">
        <div id={id + " 1"} className="rating iconRating iconsRating mr10"></div>
        <div id={id + " 2"} className="rating iconRating iconsRating mr10"></div>
        <div id={id + " 3"} className="rating iconRating iconsRating mr10"></div>
        <div id={id + " 4"} className="rating iconRating iconsRating mr10"></div>
        <div id={id + " 5"} className="rating iconRatingEmpty iconsRating mr10"></div>
      </div>;
    }
    if(value == 3) {
      return <div className="ratingTable">
        <div id={id + " 1"} className="rating iconRating iconsRating mr10"></div>
        <div id={id + " 2"} className="rating iconRating iconsRating mr10"></div>
        <div id={id + " 3"} className="rating iconRating iconsRating mr10"></div>
        <div id={id + " 4"} className="rating iconRatingEmpty iconsRating mr10"></div>
        <div id={id + " 5"} className="rating iconRatingEmpty iconsRating mr10"></div>
      </div>;
    }
    if(value == 2) {
      return <div className="ratingTable">
        <div id={id + " 1"} className="rating iconRating iconsRating mr10"></div>
        <div id={id + " 2"} className="rating iconRating iconsRating mr10"></div>
        <div id={id + " 3"} className="rating iconRatingEmpty iconsRating mr10"></div>
        <div id={id + " 4"} className="rating iconRatingEmpty iconsRating mr10"></div>
        <div id={id + " 5"} className="rating iconRatingEmpty iconsRating mr10"></div>
      </div>;
    }
    if(value == 1) {
      return <div className="ratingTable">
        <div id={id + " 0"} className="rating iconRating iconsRating mr10"></div>
        <div id={id + " 2"} className="rating iconRatingEmpty iconsRating mr10"></div>
        <div id={id + " 3"} className="rating iconRatingEmpty iconsRating mr10"></div>
        <div id={id + " 4"} className="rating iconRatingEmpty iconsRating mr10"></div>
        <div id={id + " 5"} className="rating iconRatingEmpty iconsRating mr10"></div>
      </div>;
    }
    if(value === 0) {
      return <div className="ratingTable">
        <div id={id + " 1"} className="rating iconRatingEmpty iconsRating mr10"></div>
        <div id={id + " 2"} className="rating iconRatingEmpty iconsRating mr10"></div>
        <div id={id + " 3"} className="rating iconRatingEmpty iconsRating mr10"></div>
        <div id={id + " 4"} className="rating iconRatingEmpty iconsRating mr10"></div>
        <div id={id + " 5"} className="rating iconRatingEmpty iconsRating mr10"></div>
      </div>;
    }
    else {
      return <div className="ratingTable">
        <div id={id + " 1"} className="rating iconRatingEmpty iconsRating mr10"></div>
        <div id={id + " 2"} className="rating iconRatingEmpty iconsRating mr10"></div>
        <div id={id + " 3"} className="rating iconRatingEmpty iconsRating mr10"></div>
        <div id={id + " 4"} className="rating iconRatingEmpty iconsRating mr10"></div>
        <div id={id + " 5"} className="rating iconRatingEmpty iconsRating mr10"></div>
      </div>;
    }
  }

  templateTypeResource(props) {
    let blockType = this.state.blockType;
    const itemId = props.Item_ID;
    const itemType = props.Item_Type;

    if(!props.column) {
      return null;
    }

    // Get Column Name (Remove Suffix .Label to Object Fields)
    const columnName = props.column.field.replace(".Label", "");
    const value = props[columnName];

    const columnType = this.getColumnType(columnName);
    const editionItemTypes = this.getColumnEditionItemType(columnName);
    const restrictedValues = this.getColumnRestrictedValues(columnName);

    // Replace BlockType by Details in Link for Timetraking
    if(blockType === 'TimeTracking') {
      blockType = "Details";
    }

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverEditAxe ItemId={itemId.substring(1)} ItemType={itemType} ColumnName={columnName} ColumnType={columnType} AxeValue={value} RestrictedValues={restrictedValues} onAxeEdit={this.editAxe}></PopoverEditAxe>
        </Popover.Content>
      </Popover>
    );

    if(value.Label === '- Not assigned -') {
      const firstname = 'N';
      const lastname = 'A';

      // Editable with Popover
      if(editionItemTypes.includes(itemType)) {
        return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
          <div className="align-baseline">
            <span className="iconEmptyResourceTable icons aligncenter blue">{firstname + lastname}</span>
            <span className="ml10 grey">{value.Label}</span>
          </div>
        </OverlayTrigger>;
      }
      // Non Editable
      else {
        return <div className="align-baseline">
          <span className="iconEmptyResourceTable icons aligncenter blue">{firstname + lastname}</span>
          <span className="ml10 grey">{value.Label}</span>
        </div>;
      }
    }
    else if(value.Label === '- To be assigned -') {
      const firstname = 'T';
      const lastname = 'B';

      if(editionItemTypes.includes(itemType)) {
        return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
          <div className="align-baseline">
            <span className="iconEmptyResourceTable icons aligncenter blue">{firstname + lastname}</span>
            <span className="ml10 grey">{value.Label}</span>
          </div>
        </OverlayTrigger>;
      }
      // Non Editable
      else {
        return <div className="align-baseline">
          <span className="iconEmptyResourceTable icons aligncenter blue">{firstname + lastname}</span>
          <span className="ml10 grey">{value.Label}</span>
        </div>;
      }
    }
    else if(value.Id && value.Label) {
      let firstname, lastname;
      const split = value.Label.split(' ');

      if(split.length === 1) {
        firstname = value.Label.split(' ')[0].substring(0,1);
        lastname = '';
      }
      else if(split.length === 2) {
        firstname = value.Label.split(' ')[0].substring(0,1);
        lastname = value.Label.split(' ')[1].substring(0,1);
      }
      else {
        firstname = value.Label.split(' ')[0].substring(0,1);
        lastname = value.Label.split(' ')[split.length-1].substring(0,1);
      }

      if(editionItemTypes.includes(itemType)) {
        return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
          <div className="align-baseline">
            <a target="_self" href={`/Card/Resource/${value.Id}/${blockType}`}><span className="iconResourceTable icons aligncenter white">{firstname + lastname}</span></a>
            <span className="ml10 grey">{value.Label}</span>
          </div>
        </OverlayTrigger>;
      }
      // Non Editable
      else {
        return <div className="align-baseline">
          <a target="_self" href={`/Card/Resource/${value.Id}/${blockType}`}><span className="iconResourceTable icons aligncenter white">{firstname + lastname}</span></a>
          <span className="ml10 grey">{value.Label}</span>
        </div>;
      }
    }
    else {
      return null;
    }
  }

  templateTypeResourceTable(props) {
    const language = this.state.language;
    const itemId = props.Item_ID;
    const itemType = props.Item_Type;

    if(!props.column) {
      return null;
    }

    // Get Column Name (Remove Suffix .Label to Object Fields)
    const columnName = props.column.field.replace(".Label", "");
    const values = props[columnName];

    const columnType = this.getColumnType(columnName);
    const editionItemTypes = this.getColumnEditionItemType(columnName);
    const restrictedValues = this.getColumnRestrictedValues(columnName);

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverEditObjectTable ItemId={itemId.substring(1)} ItemType={itemType} ColumnName={columnName} ColumnType={columnType} ObjectTableValues={values} RestrictedValues={restrictedValues} onObjectTableEdit={this.editObjectTable}></PopoverEditObjectTable>
        </Popover.Content>
      </Popover>
    );
    
    if(values) {
      // Editable with Popover
      if(editionItemTypes.includes(itemType)) {
        return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
          <div className="width100p">
            <div className="cellTable inline-flex">
              {values.map((value, index) => {
                let firstname, lastname;
                const split = value.Label.split(' ');
          
                if(split.length === 1) {
                  firstname = value.Label.split(' ')[0].substring(0,1);
                  lastname = '';
                }
                else if(split.length === 2) {
                  firstname = value.Label.split(' ')[0].substring(0,1);
                  lastname = value.Label.split(' ')[1].substring(0,1);
                }
                else {
                  firstname = value.Label.split(' ')[0].substring(0,1);
                  lastname = value.Label.split(' ')[split.length-1].substring(0,1);
                }

                if(value.Id === 0) {
                  return <div key={index} className="multiResource mr10">{value.Label}</div>;
                }
                else {
                  return <div key={index} className="multiResource mr10">
                    <span className="iconResourceTable icons aligncenter white">{firstname + lastname}</span>
                    <span className="ml5 grey">{value.Label}</span>
                  </div>;
                }
              })}
            </div>
          </div>
        </OverlayTrigger>;
      }
      // Non Editable
      else {
        return <div className="inline-flex">
          {values.map((value, index) => {
            let firstname, lastname;
            const split = value.Label.split(' ');
      
            if(split.length === 1) {
              firstname = value.Label.split(' ')[0].substring(0,1);
              lastname = '';
            }
            else if(split.length === 2) {
              firstname = value.Label.split(' ')[0].substring(0,1);
              lastname = value.Label.split(' ')[1].substring(0,1);
            }
            else {
              firstname = value.Label.split(' ')[0].substring(0,1);
              lastname = value.Label.split(' ')[split.length-1].substring(0,1);
            }

            if(value.Id === 0) {
              return <div key={index} className="multiResource mr10">{value.Label}</div>;
            }
            else {
              return <div key={index} className="multiResource mr10">
                <span className="iconResourceTable icons aligncenter white">{firstname + lastname}</span>
                <span className="ml5 grey">{value.Label}</span>
              </div>;
            }
          })}
        </div>;
      }
    }
    else {
      return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}><div className="emptyCellTable"></div></OverlayTrigger>;
    }
  }

  templateTypeText(props) {
    if(!props.column) {
      return null;
    }

    // Get Column Name and Value
    const columnName = props.column.field;
    const value = props[columnName];

    return <div className="">{value}</div>;
  }

  render() {
    let { language, itemId, itemType, blockType, editable, guestLicence, currentView, columns, rows, dataSource, modificationErrors, auditPopup, dependenciesPopup, filesPopup, rightsPopup, textEditorPopup, textFormulaEditorPopup, confirm, rowItemId, rowItemType, rowItemTitle, rowItemColumn, rowItemLabel } = this.state;
    let height, minimum, pagging;

    // Table Height
    if(itemType === 'Environment' && blockType === 'Roadmap') {
      height = (window.innerHeight - 250);
      minimum = 100;
    }
    else if(blockType === 'Decisions' || blockType === 'Earnings' || blockType === 'Issues' || blockType === 'Meetings' || blockType === 'Risks' || blockType === 'Roadmap' || blockType === 'Resources' || blockType === 'BudgetDetails' || blockType === 'Warnings' || blockType === 'Agile' || blockType === 'AxisValues' || blockType === 'CustomFields' || blockType === 'DeleteBin' || blockType === 'Users' || blockType === 'UserGroups') {
      height = (window.innerHeight - 220);
      minimum = 100;
    }
    else if(blockType === 'Dependencies' || blockType === 'Views') {
      height = '100%';
    }
    else if(blockType === 'NotificationsCenter') {
      height = (window.innerHeight - 220);
      minimum = 100;
    }
    else if(blockType === 'TimeTracking') {
      height = (window.innerHeight - 445);
      minimum = 100;
    }
    else {
      height = (window.innerHeight - 300);
      minimum = 100;
    }

    if(height > minimum) {
      height += 'px';
    }
    else {
      height = minimum + 'px';
    }

    // Table Pagging
    if(blockType === 'Dependencies' || blockType === 'Views') {
      pagging = false;
    }
    else {
      pagging = true;
    }

    // Grid Lines
    this.gridLines = 'Default';

    // Toolbar Options
    // if(editable === true) {
    //   if(itemType === 'Business_Line' && level === 'Project') {
    //     this.toolbarOptions = [{text: 'Add', tooltipText: 'Add', id: 'toolbar_Add'}, {text: 'Delete', tooltipText: 'Delete', id: 'toolbar_Delete'}, 'ColumnChooser', 'Search'];
    //   }
    //   else if(itemType === 'Project' && (level === 'Workpackage' || level === 'Action')) {
    //     this.toolbarOptions = [{text: 'Add', tooltipText: 'Add', id: 'toolbar_Add'}, {text: 'Delete', tooltipText: 'Delete', id: 'toolbar_Delete'}, 'ColumnChooser', 'Search'];
    //   }
    //   else if(itemType === 'Workpackage' && level === 'Action') {
    //     this.toolbarOptions = [{text: 'Add', tooltipText: 'Add', id: 'toolbar_Add'}, {text: 'Delete', tooltipText: 'Delete', id: 'toolbar_Delete'}, 'ColumnChooser', 'Search'];
    //   }
    //   else if(itemType === 'Action' && level === 'Task') {
    //     this.toolbarOptions = [{text: 'Add', tooltipText: 'Add', id: 'toolbar_Add'}, {text: 'Delete', tooltipText: 'Delete', id: 'toolbar_Delete'}, 'ColumnChooser', 'Search'];
    //   }
    //   else if(level === 'Decision' || level === 'Issue' || level === 'Meeting' || level === 'Risk') {
    //     this.toolbarOptions = [{text: 'Add', tooltipText: 'Add', id: 'toolbar_Add'}, {text: 'Delete', tooltipText: 'Delete', id: 'toolbar_Delete'}, 'ColumnChooser', 'Search'];
    //   }
    //   else {
    //     this.toolbarOptions = [{text: 'Delete', tooltipText: 'Delete', id: 'toolbar_Delete'}, 'ColumnChooser', 'Search'];
    //   }
    // }
    // else {
    //   this.toolbarOptions = ['ColumnChooser', 'Search'];
    // }

    // Column Menu
    this.columnMenuItems = [];
    // this.columnMenuItems = ['AutoFit', 'AutoFitAll', 'SortAscending', 'SortDescending', 'Filter'];

    // Context Menu
    if(editable && !guestLicence && itemType !== 'Environment' && blockType === 'Roadmap') {
      this.contextMenuItems = [
        { id: 'contextMenu_Duplicate', text: Traduction.translate(language, 'duplicate'), target: '.e-content' },
        { id: 'contextMenu_Delete', text: Traduction.translate(language, 'delete'), target: '.e-content' },
        { id: 'contextMenu_Dependencies', text: Traduction.translate(language, 'dependencies'), target: '.e-content' },
        { id: 'contextMenu_Audit', text: Traduction.translate(language, 'audit'), target: '.e-content' }
      ];
    }
    else if(editable && !guestLicence && itemType === 'Administration' && blockType === 'Users') {
      this.contextMenuItems = [
        { id: 'contextMenu_Reset_Password', text: Traduction.translate(language, 'reset_password'), target: '.e-content' },
        { id: 'contextMenu_Audit', text: Traduction.translate(language, 'audit'), target: '.e-content' }
      ];
    }
    else if(itemType === 'Environment' && blockType === 'NotificationsCenter') {
      this.contextMenuItems = null;
    }
    else if(editable && !guestLicence && (blockType !== 'AxisValues' && blockType !== 'CustomFields' && blockType !== 'BudgetDetails' && blockType !== 'Earnings' && blockType !== 'Resources' && blockType !== 'Dependencies' && blockType !== 'UserGroups' && blockType !== 'DeleteBin')) {
      this.contextMenuItems = [
        { id: 'contextMenu_Duplicate', text: Traduction.translate(language, 'duplicate'), target: '.e-content' },
        { id: 'contextMenu_Delete', text: Traduction.translate(language, 'delete'), target: '.e-content' },
        { id: 'contextMenu_Audit', text: Traduction.translate(language, 'audit'), target: '.e-content' }
      ];
    }
    else if(editable && !guestLicence && (blockType === 'AxisValues' || blockType === 'CustomFields' || blockType === 'BudgetDetails' ||  blockType === 'Earnings' || blockType === 'Resources' || blockType === 'UserGroups')) {
      this.contextMenuItems = [
        { id: 'contextMenu_Delete', text: Traduction.translate(language, 'delete'), target: '.e-content' },
        { id: 'contextMenu_Audit', text: Traduction.translate(language, 'audit'), target: '.e-content' }
      ];
    }
    else if(blockType === 'Dependencies') {
      this.contextMenuItems = [
        { id: 'contextMenu_Delete', text: Traduction.translate(language, 'delete'), target: '.e-content' }
      ];
    }
    else if(blockType === 'DeleteBin') {
      this.contextMenuItems = [
        { id: 'contextMenu_Restore', text: Traduction.translate(language, 'restore'), target: '.e-content' },
        { id: 'contextMenu_Delete', text: Traduction.translate(language, 'delete'), target: '.e-content' }
      ];
    }
    else if(blockType === 'Views' || blockType === 'Warnings') {
      this.contextMenuItems = null;
    }
    else {
      this.contextMenuItems = [
        { id: 'contextMenu_Audit', text: Traduction.translate(language, 'audit'), target: '.e-content' }
      ];
    }

    // Edit Options
    this.editOptions = { allowAdding: true, allowDeleting: true, allowEditing: !guestLicence, mode: 'Cell', newRowPosition: 'Top' };
    // this.editOptions = { allowAdding: true, allowDeleting: true, allowEditing: true, mode: 'Cell', newRowPosition: 'Below' };

    // Filter Settings
    this.filterSettings = { type: 'Excel', hierarchyMode: 'Parent' };

    // Validation Rules
    this.dateRules = { date: true };
    this.nameRules = { maxLength: [this.nameValidation, Traduction.translate(language, 'rules_name')], required: true };
    this.textRules = { maxLength: [this.textValidation, Traduction.translate(language, 'rules_text')], required: false };
    this.riskNameRules = { maxLength: [this.riskNameValidation, Traduction.translate(language, 'rules_risk_name')], required: true };
    this.riskCommentRules = { maxLength: [this.riskCommentValidation, Traduction.translate(language, 'rules_risk_comment')], required: false };
    this.numberRules = { min: [this.numberValidation, Traduction.translate(language, 'rules_number')], number: true };
    this.progressRules = { min: [this.progressValidation, Traduction.translate(language, 'rules_progress')], number: true };
    this.yearRules = { min: [this.yearValidation, Traduction.translate(language, 'rules_year')], number: true, required: true };

    return (
      <div className="">
        {/* Audit Popup */}
        {auditPopup && <TableAudit ItemId={rowItemId} ItemType={rowItemType} ItemTitle={rowItemTitle} BlockType={blockType} onPopupClose={this.closeAuditPopup}></TableAudit>}

        {/* Delete Confirmation Popup */}
        {confirm && <PopupConfirmation Message={this.getDeleteLabel(rowItemType)} onMessageConfirm={this.confirmDelete} onMessageCancel={this.cancelDelete}></PopupConfirmation>}

        {/* Dependencies Popup */}
        {/* {dependenciesPopup && <PopupEditDependencies ItemId={rowItemId} ItemType={rowItemType} ItemTitle={rowItemTitle} Editable={editable} GuestLicence={guestLicence} onPopupClose={this.closeDependenciesPopup}></PopupEditDependencies>} */}

        {/* Files Popup */}
        {filesPopup && <PopupEditFiles ItemId={rowItemId} ItemType={rowItemType} ItemTitle={rowItemTitle} ColumnName={rowItemColumn} ColumnLabel={rowItemLabel} onPopupClose={this.closeFilesPopup}></PopupEditFiles>}

        {/* Rights Popup */}
        {rightsPopup && <PopupEditRights ItemId={rowItemId} ItemType={rowItemType} ItemTitle={rowItemTitle} ColumnName={rowItemColumn} onPopupClose={this.closeRightsPopup}></PopupEditRights>}

        {/* Text Editor Popup */}
        {textEditorPopup && this.popupTextEditor()}

        {/* Text Formula Editor Popup */}
        {textFormulaEditorPopup && this.popupTextFormulaEditor()}

        {/* TreeGrid Component */}
        <div className="control-pane">
          <div className="control-section">
            {dataSource.length > 0 && 
              <TreeGridComponent id={'table' + currentView.ViewId} dataSource={dataSource} idMapping='Item_ID' locale={Traduction.translate(language, 'locale')} height={height} gridLines={this.gridLines} editSettings={this.editOptions} columnMenuItems={this.columnMenuItems} contextMenuItems={this.contextMenuItems} contextMenuOpen={this.contextMenuOpen} contextMenuClick={this.contextMenuClick} filterSettings={this.filterSettings} created={this.created} dataBound={this.dataBound} rowDataBound={this.rowDataBound} rowSelected={this.rowSelected} actionBegin={this.actionBegin} actionComplete={this.actionComplete} cellEdit={this.cellEdit} resizeStop={this.resizeStop} excelQueryCellInfo={this.excelQueryCellInfo} showColumnMenu={false} showColumnChooser={false} allowFiltering={false} allowPaging={pagging} pageSettings={{ pageSize: 50 }} allowExcelExport={true} allowReordering={true} allowResizing={true} allowSorting={false} allowTextWrap={true} ref={treegrid=>this.grid=treegrid}>
                <ColumnsDirective>
                  <ColumnDirective isPrimaryKey={true} field='Item_ID' headerText={'Item_ID'} visible={false} autoFit={true} allowEditing={false} lockColumn={false} showInColumnChooser={true}></ColumnDirective>
                </ColumnsDirective>
                <Inject services={[ Toolbar, ContextMenu, ColumnMenu, ColumnChooser, Edit, Filter, Sort, Page, Reorder, Resize, ExcelExport ]}/>
              </TreeGridComponent>
            }
          </div>
        </div>
      </div>
    )
  }
}

export default Table;