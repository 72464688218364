import React, {Component} from 'react';
import { Button, Form } from 'react-bootstrap';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';
import Axe from './Axe';

class PopoverEditAxe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: null,
      staticData: null,
      itemId: null,
      itemType: null,
      columnName: null,
      columnType: null,
      axeValue: null,
      restrictedValues: [],
      search: ''
    };
    
    this.searchItem = this.searchItem.bind(this);
    this.pressKey = this.pressKey.bind(this);
    this.updateAxe = this.updateAxe.bind(this);

    this.search = React.createRef();
  }

  componentDidMount() {
    const language = Authentication.getCookie('language');
    const staticData = Authentication.getCookie('staticData');
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const columnName = this.props.ColumnName;
    const columnType = this.props.ColumnType;
    const axeValue = this.props.AxeValue;
    const restrictedValues = this.props.RestrictedValues;

    // Search Auto focus
    if(columnName !== 'Status' && columnName !== 'Impact' && columnName !== 'Priority' && columnName !== 'Probability' && columnName !== 'Meteo' && columnName !== 'Trend'  && columnName !== 'Language') {
      setTimeout(() => { this.search.current.focus() }, 1);
    }

    this.setState({ language, staticData, itemId, itemType, columnName, columnType, axeValue, restrictedValues });
  }

  componentDidUpdate(prevProps) {
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const columnName = this.props.ColumnName;
    const columnType = this.props.ColumnType;
    const axeValue = this.props.AxeValue;
    const restrictedValues = this.props.RestrictedValues;

    // if(this.props.ItemId !== prevProps.ItemId || this.props.ItemType !== prevProps.ItemType) {
    //   this.setState({ itemId, itemType });
    // }
    // if(this.props.ColumnName !== prevProps.ColumnName || this.props.AxeValue !== prevProps.AxeValue || this.props.RestrictedValues !== prevProps.RestrictedValues) {
    //   this.setState({ columnName, axeValue, restrictedValues });
    // }
  }

  searchItem(event) {
    const search = event.target.value;

    this.setState({ search });
  }

  pressKey(event) {
    const { itemId, itemType, columnName, axeValue, restrictedValues } = this.state;
    const search = event.target.value;
    let newValue;

    if(event.key === 'Enter' && search !== '') {
      if(restrictedValues.find(value => value.Label.toLowerCase().includes(search.toLowerCase()))) {
        newValue = restrictedValues.find(value => value.Label.toLowerCase().includes(search.toLowerCase()));

        // Close Popover
        if(document.getElementById("popover-basic")) {
          document.getElementById("popover-basic").classList.remove("show");
        }
        
        if(newValue.Label != axeValue.Label) {
          // Call the event from the Parent component through the props
          this.props.onAxeEdit(itemId, itemType, columnName, JSON.stringify(axeValue), JSON.stringify(newValue));
        }

        this.setState({ axeValue: newValue });
      }
    }
  }

  updateAxe(itemId, itemType, columnName, oldValue, newValue) {
    // Close Popover
    if(document.getElementById("popover-basic")) {
      document.getElementById("popover-basic").classList.remove("show");
    }
    
    if(newValue != oldValue) {
      // Call the event from the Parent component through the props
      this.props.onAxeEdit(itemId, itemType, columnName, oldValue, newValue);
    }
  }

  render() {
    const { language, staticData, itemId, itemType, columnName, columnType, axeValue, restrictedValues, search } = this.state;

    return (
      <div className="axeEditPopover">
        {/* Search */}
        {(columnName !== 'Status' && columnName !== 'Impact' && columnName !== 'Priority' && columnName !== 'Probability' && columnName !== 'Meteo' && columnName !== 'Trend'  && columnName !== 'Language') && 
          <div className="axeEditSearch">
            <Form.Group className="searchBlock">
              <Form.Control type="text" id="searchFilters" name="search" value={search} placeholder={Traduction.translate(language, 'search')} autoFocus onChange={this.searchItem} onKeyPress={this.pressKey} ref={this.search}/>
            </Form.Group>
          </div>
        }
        {/* Values */}
        <div className="axeEditValues scrollbar-y">
          {/* Current Value */}
          {axeValue && <div className="axeCurrentValue" onClick={() => this.updateAxe(itemId, itemType, columnName, JSON.stringify(axeValue), JSON.stringify(axeValue))}>
            {/* <div className="axe brd-dashed very-light-grey">{axeValue.Label}</div> */}
            <Axe Axe={columnName} Type={columnType} Value={axeValue} View={'Popover'}></Axe>
          </div>}
          {/* {axeValue && axeValue.Label !== '- NA -' && <div className="axeEditValue" onClick={() => this.updateAxe(itemId.substring(1), itemType, columnName, JSON.stringify(axeValue), JSON.stringify(axeValue))}>
            <div className="axe bordered blue">{axeValue.Label}</div>
          </div>} */}
          {/* Border */}
          <div className="popoverBorder"></div>
          {/* Other Values */}
          {restrictedValues.map((value, index) => {
            if(value.Id !== axeValue.Id) {
              if(search === "" || value.Label.toLowerCase().includes(search.toLowerCase())) {
                return <div key={index} className="axeEditValue" onClick={() => this.updateAxe(itemId, itemType, columnName, JSON.stringify(axeValue), JSON.stringify(value))}>
                  <Axe Axe={columnName} Type={columnType} Value={value} View={'Popover'}></Axe>
                </div>;
                // if(value.Label === '- NA -') {
                //   return <div key={index} className="axeEditValue" onClick={() => this.updateAxe(itemId.substring(1), itemType, columnName, JSON.stringify(axeValue), JSON.stringify(value))}>
                //     <div className="axe brd-dashed very-light-grey">{value.Label}</div>
                //   </div>;
                // }
                // else {
                //   return <div key={index} className="axeEditValue" onClick={() => this.updateAxe(itemId.substring(1), itemType, columnName, JSON.stringify(axeValue), JSON.stringify(value))}>
                //     <div className="axe bordered blue">{value.Label}</div>
                //   </div>;
                // }
              }
            }
          })}
        </div>
        {/* Edit Axis Values */}
        {staticData === 'true' && columnType !== 'Resource' && columnName !== 'Status' && columnName !== 'Impact' && columnName !== 'Priority' && columnName !== 'Probability' && columnName !== 'Meteo' && columnName !== 'Trend' && columnName !== 'Sprint' && columnName !== 'Meeting' && columnName !== 'Owner' && columnName !== 'Card_Level' && columnName !== 'Language' && columnName !== 'Format_Date' && 
          <div className="axeEditValuesButton">
            <a target="_self" href={`/Environment/AxisValues?axis=${columnName}`}><Button className="axeEditButton" variant="primary">{Traduction.translate(language, 'edit_values')}</Button></a>
          </div>
        }
      </div>
    );
  }
}

export default PopoverEditAxe;