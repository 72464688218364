import React, {Component} from 'react';
import { Button, Form, FormControl, InputGroup, OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';
import PopoverEditSetting from './PopoverEditSetting';

class FiltersPlanning extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: null,
      currentView: {},
      availableAxes: [],
      startDate: null,
      endDate: null,
      currentDate: null,
      displayIndicators: false,
      editionMode: true,
      parentEdition: false,
      label: null,
      axisColor: null,
      rangeType: false,
      isBaseline: false,
      isCriticalPath: false,
      isDependency: false,
      isFrozen: false,
      planningSettingsPopup: false
    };

    // Data Structure
    this.initializeAttributes = this.initializeAttributes.bind(this);
    this.convertStringtoBoolean = this.convertStringtoBoolean.bind(this);
    this.formatDateEn = this.formatDateEn.bind(this);
    
    // Actions
    this.changeBaseline = this.changeBaseline.bind(this);
    this.changeColor = this.changeColor.bind(this);
    this.changeCriticalPath = this.changeCriticalPath.bind(this);
    this.changeCurrentDate = this.changeCurrentDate.bind(this);
    this.changeDate = this.changeDate.bind(this);
    this.changeDisplayIndicators = this.changeDisplayIndicators.bind(this);
    this.changeDependencies = this.changeDependencies.bind(this);
    this.changeEditionMode = this.changeEditionMode.bind(this);
    this.changeLabel = this.changeLabel.bind(this);
    this.changeParentEdition = this.changeParentEdition.bind(this);
    this.changeRangeType = this.changeRangeType.bind(this);
    this.applySettings = this.applySettings.bind(this);
    this.openPlanningSettingsPopup = this.openPlanningSettingsPopup.bind(this);

    // Template
    this.templatePlanningSettings = this.templatePlanningSettings.bind(this);
    this.templatePopoverEditLabel = this.templatePopoverEditLabel.bind(this);
    this.templatePopoverEditColor = this.templatePopoverEditColor.bind(this);
    this.closePlanningSettingsPopup = this.closePlanningSettingsPopup.bind(this);
  }

  componentDidMount() {
    const authId = Authentication.getCookie('authId');
    const language = Authentication.getCookie('language');
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const blockType = this.props.BlockType;
    const currentView = this.props.CurrentView;
    const availableAxes = this.props.AvailableAxes;

    // switch(label) {
    //   case '${Task_Label}':
    //     label = 'Name';
    //     break;
    //   case '${Task_Label} - ${Progress}%':
    //     label = 'Name + %';
    //     break;
    //   case '${Task_Label} - ${EndDateFormat}':
    //     label = 'Name + End Date';
    //     break;
    //   default:
    //     label = 'Name';
    //     break;
    // }

    this.setState({ authId, language, itemId, itemType, blockType, currentView, availableAxes });

    this.initializeAttributes(currentView);
  }

  componentDidUpdate(prevProps) {
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const blockType = this.props.BlockType;
    const currentView = this.props.CurrentView;
    const availableAxes = this.props.AvailableAxes;

    if(itemId !== prevProps.ItemId || itemType !== prevProps.ItemType || blockType !== prevProps.BlockType) {
      this.setState({ itemId, itemType, blockType });
    }
    if(JSON.stringify(this.props.CurrentView) !== JSON.stringify(prevProps.CurrentView)) {
      this.setState({ currentView });
    }
    if(JSON.stringify(this.props.AvailableAxes) !== JSON.stringify(prevProps.AvailableAxes)) {
      this.setState({ availableAxes });
    }
  }

  initializeAttributes(currentView) {
    let startDate, endDate, currentDate, displayIndicators, editionMode, parentEdition, label, axisColor, rangeType, isBaseline, isCriticalPath, isDependency, isFrozen;

    // Current Date
    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'DisplayToday')) {
      currentDate = currentView.Parameters.find(param => param.Name === 'DisplayToday').Value;
    }
    else {
      currentDate = "false";
      currentView.Parameters.push({ Name: 'DisplayToday', Value: currentDate });
    }

    // Indicators
    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'WithIndicators')) {
      displayIndicators = currentView.Parameters.find(param => param.Name === 'WithIndicators').Value;
    }
    else {
      displayIndicators = "false";
      currentView.Parameters.push({ Name: 'WithIndicators', Value: displayIndicators });
    }

    // Edition Mode
    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'EditionMode')) {
      editionMode = currentView.Parameters.find(param => param.Name === 'EditionMode').Value;
    }
    else {
      editionMode = "true";
      currentView.Parameters.push({ Name: 'EditionMode', Value: editionMode });
    }

    // Parent Edition (Manual/Auto)
    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'ParentEdition')) {
      parentEdition = currentView.Parameters.find(param => param.Name === 'ParentEdition').Value;
    }
    else {
      parentEdition = "false";
      currentView.Parameters.push({ Name: 'ParentEdition', Value: parentEdition });
    }

    // Label
    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'DisplayLabel')) {
      label = currentView.Parameters.find(param => param.Name === 'DisplayLabel').Value;
    }
    else {
      label = 'Name';
      currentView.Parameters.push({ Name: 'DisplayLabel', Value: label });
    }

    // Color
    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'AxisColor')) {
      axisColor = currentView.Parameters.find(param => param.Name === 'AxisColor').Value;
    }
    else {
      axisColor = 'None';
      currentView.Parameters.push({ Name: 'AxisColor', Value: axisColor });
    }

    // Baseline
    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'DisplayBaseline')) {
      isBaseline = currentView.Parameters.find(param => param.Name === 'DisplayBaseline').Value;
    }
    else {
      isBaseline = "false";
      currentView.Parameters.push({ Name: 'DisplayBaseline', Value: isBaseline });
    }

    // Critical Path
    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'DisplayCriticalPath')) {
      isCriticalPath = currentView.Parameters.find(param => param.Name === 'DisplayCriticalPath').Value;
    }
    else {
      isCriticalPath = "false";
      currentView.Parameters.push({ Name: 'DisplayCriticalPath', Value: isCriticalPath });
    }

    // Dependencies
    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'DisplayDependencies')) {
      isDependency = currentView.Parameters.find(param => param.Name === 'DisplayDependencies').Value;
    }
    else {
      isDependency = "false";
      currentView.Parameters.push({ Name: 'DisplayDependencies', Value: isDependency });
    }

    this.setState({ currentView, currentDate, displayIndicators, editionMode, parentEdition, label, axisColor, isBaseline, isCriticalPath, isDependency });
  }

  convertStringtoBoolean(string) {
    if(string === 'true') {
      return true;
    }
    else {
      return false;
    }
  }

  // Formatting Date to English format for Gantt usage
  formatDateEn(date) {
    let formattedDate;

    if(date) {
      let day = date.getDate();
      let month = date.getMonth()+1; // Between 0 and 11
      let year = date.getFullYear();

      formattedDate = month + "/" + day + "/" + year;
    }
    
    return formattedDate;
  }

  changeBaseline() {
    const isBaseline = this.state.isBaseline;

    if(isBaseline === "true") {
      this.setState({ isBaseline: "false" });
    }
    else {
      this.setState({ isBaseline: "true" });
    }
  }

  changeColor(axisColor) {
    // const axisColor = event.target.value;

    this.setState({ axisColor });
  }

  changeCriticalPath() {
    const isCriticalPath = this.state.isCriticalPath;
    
    if(isCriticalPath === "true") {
      this.setState({ isCriticalPath: "false" });
    }
    else {
      this.setState({ isCriticalPath: "true" });
    }
  }

  changeCurrentDate() {
    const currentDate = this.state.currentDate;

    if(currentDate === "true") {
      this.setState({ currentDate: "false" });
    }
    else {
      this.setState({ currentDate: "true" });
    }
  }

  changeDate(args) {
    let startDate, endDate;

    if(args.element.id === "datepickerstart") {
      startDate = args.value;

      this.setState({ startDate });
    }
    else if(args.element.id === "datepickerend") {
      endDate = args.value;

      this.setState({ endDate });
    }
  }

  changeDisplayIndicators() {
    const displayIndicators = this.state.displayIndicators;

    if(displayIndicators === "true") {
      this.setState({ displayIndicators: "false" });
    }
    else {
      this.setState({ displayIndicators: "true" });
    }
  }

  changeDependencies() {
    const isDependency = this.state.isDependency;

    if(isDependency === "true") {
      this.setState({ isDependency: "false" });
    }
    else {
      this.setState({ isDependency: "true" });
    }
  }

  changeEditionMode() {
    const editionMode = this.state.editionMode;

    if(editionMode === "true") {
      this.setState({ editionMode: "false" });
    }
    else {
      this.setState({ editionMode: "true" });
    }
  }

  changeLabel(label) {
    // const label = event.target.value;

    this.setState({ label });
  }

  changeParentEdition() {
    const parentEdition = this.state.parentEdition;

    if(parentEdition === "true") {
      this.setState({ parentEdition: "false" });
    }
    else {
      this.setState({ parentEdition: "true" });
    }
  }

  changeRangeType() {
    const rangeType = this.state.rangeType;

    this.setState({ rangeType: !rangeType });
  }

  applySettings() {
    const { currentView, startDate, endDate, currentDate, displayIndicators, editionMode, parentEdition, label, axisColor, rangeType, isBaseline, isCriticalPath, isDependency, isFrozen } = this.state;

    // Borne Sup & Borne Inf
    // if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'BorneInf')) {
    //   currentView.Parameters.find(param => param.Name === 'BorneInf').Value = this.formatDateEn(startDate);
    // }

    // if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'BorneSup')) {
    //   currentView.Parameters.find(param => param.Name === 'BorneSup').Value = this.formatDateEn(endDate);
    // }

    // Display Current Day
    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'DisplayToday')) {
      currentView.Parameters.find(param => param.Name === 'DisplayToday').Value = currentDate;
    }

    // Display Indicators
    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'WithIndicators')) {
      currentView.Parameters.find(param => param.Name === 'WithIndicators').Value = displayIndicators;
    }

    // Edition Mode
    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'EditionMode')) {
      currentView.Parameters.find(param => param.Name === 'EditionMode').Value = editionMode;
    }

    // Parent Edition
    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'ParentEdition')) {
      currentView.Parameters.find(param => param.Name === 'ParentEdition').Value = parentEdition;
    }

    // Display Label
    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'DisplayLabel')) {
      currentView.Parameters.find(param => param.Name === 'DisplayLabel').Value = label;
    }

    // Axis Color
    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'AxisColor')) {
      currentView.Parameters.find(param => param.Name === 'AxisColor').Value = axisColor;
    }

    // Range Type
    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'RangeType')) {
      if(rangeType === false) {
        currentView.Parameters.find(param => param.Name === 'RangeType').Value = "Auto";
      }
      else {
        currentView.Parameters.find(param => param.Name === 'RangeType').Value = "Custom";
      }
    }

    // Baseline
    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'DisplayBaseline')) {
      currentView.Parameters.find(param => param.Name === 'DisplayBaseline').Value = isBaseline;
    }

    // Critical path
    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'DisplayCriticalPath')) {
      currentView.Parameters.find(param => param.Name === 'DisplayCriticalPath').Value = isCriticalPath;
    }

    // Dependencies
    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'DisplayDependencies')) {
      currentView.Parameters.find(param => param.Name === 'DisplayDependencies').Value = isDependency;
    }

    // Call the event from the Parent component through the props with view value
    this.props.onSettingsChange(currentView);

    // Call the event from the Parent component through the props with view value
    // this.props.onViewChange(currentView);

    this.setState({ planningSettingsPopup: false });
  }

  openPlanningSettingsPopup() {
    this.setState({ planningSettingsPopup: true });
  }

  templatePlanningSettings() {
    const { language, currentView, availableAxes, startDate, endDate, currentDate, displayIndicators, editionMode, parentEdition, label, axisColor, rangeType, isBaseline, isCriticalPath, isDependency, isFrozen } = this.state;
    let labelData = [];
    
    // Display Name Label
    labelData = [
      { Axe: 'None', Label: Traduction.translate(language, 'none')},
      { Axe: 'Name', Label: Traduction.translate(language, 'name')},
      { Axe: 'Name + %', Label: Traduction.translate(language, 'name_percentage')},
      { Axe: 'Name + End Date', Label: Traduction.translate(language, 'name_end_date')}
    ];

    // Push None element at the begining of available Axes array
    if(!availableAxes.find(axe => axe.Axe === 'None')) {
      availableAxes.unshift({ Axe: "None", ItemType: "", Label: Traduction.translate(language, 'none') });
    }

    return (<div className="flex flex-column popupColumnChooser">
      {/* Label */}
      <div className="ganttSettingsLabel mb10">{Traduction.translate(language, 'gantt_parameters')}</div>
    
      {/* Display */}
      <div className="flex flex-column"> 
        {/* Title */}
        <div className="ganttSettingsTitle">{Traduction.translate(language, 'display')}</div>

        {/* Baseline */}
        <div className="inline-flex mv5">
          <Form.Check type="switch" id="showBaseline" checked={this.convertStringtoBoolean(isBaseline)} onChange={() => this.changeBaseline()}/>
          <div className="ganttSetting ml40">{Traduction.translate(language, 'display_baseline')}</div>
        </div>

        {/* CurrentDay */}
        <div className="inline-flex mv5">
          <Form.Check type="switch" id="showCurrentDate" checked={this.convertStringtoBoolean(currentDate)} onChange={() => this.changeCurrentDate()}/>
          <div className="ganttSetting ml40">{Traduction.translate(language, 'display_current_day')}</div>
        </div>

        {/* Gantt Indicators */}
        <div className="inline-flex mv5">
          <Form.Check type="switch" id="showIndicators" checked={this.convertStringtoBoolean(displayIndicators)} onChange={() => this.changeDisplayIndicators()}/>
          <div className="ganttSetting ml40">{Traduction.translate(language, 'display_childs')}</div>
        </div>

        {/* Critical path */}
        <div className="inline-flex mv5">
          <Form.Check type="switch" id="showCriticalPath" checked={this.convertStringtoBoolean(isCriticalPath)} onChange={() => this.changeCriticalPath()}/>
          <div className="ganttSetting ml40">{Traduction.translate(language, 'display_critical_path')}</div>
        </div>

        {/* Label */}
        <div className="mv5">
          <div className="flex align-items-center col-md-6">
            <div className="ganttSettingSubtitle mr20">{Traduction.translate(language, 'label')}</div>
            <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={this.templatePopoverEditLabel(label, labelData)}>
              <div className="cursor">
                {labelData.find(value => value.Axe === label) && <div className="selectAxe"><div className="">{labelData.find(value => value.Axe === label).Label}</div><span className="iconChevronDownGrey extrasmallIcons ml10"></span></div>}
                {!labelData.find(value => value.Axe === label) && <div className="selectAxe"><div className="">{labelData[0].Label}</div><span className="iconChevronDownGrey extrasmallIcons ml10"></span></div>}
              </div>
            </OverlayTrigger>
          </div>
        </div>

        {/* Color */}
        <div className="mv5">
          <div className="flex align-items-center col-md-6 mb10">
            <div className="ganttSettingSubtitle mr20">{Traduction.translate(language, 'color')}</div>
            <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={this.templatePopoverEditColor(axisColor, availableAxes)}>
              <div className="cursor">
                {availableAxes.find(value => value.Axe === axisColor) && <div className="selectAxe"><div className="">{availableAxes.find(value => value.Axe === axisColor).Label}</div><span className="iconChevronDownGrey extrasmallIcons ml10"></span></div>}
                {!availableAxes.find(value => value.Axe === axisColor) && <div className="selectAxe"><div className="">{availableAxes[0].Label}</div><span className="iconChevronDownGrey extrasmallIcons ml10"></span></div>}
              </div>
            </OverlayTrigger>
          </div>
        </div>
      </div>

      {/* Edition mode */}
      <div className="flex flex-column">
        {/* Title */}
        <div className="ganttSettingsTitle">{Traduction.translate(language, 'gantt_edition')}</div>
    
        {/* Active */}
        <div className="inline-flex mv5">
          <Form.Check type="switch" id="showEditionMode" checked={this.convertStringtoBoolean(editionMode)} onChange={() => this.changeEditionMode()}/>
          <div className="ganttSetting ml40">{Traduction.translate(language, 'active')}</div>
        </div>

        {/* Parent Edition */}
        <div className="inline-flex mv5">
          <Form.Check type="switch" id="showParentEdition" checked={this.convertStringtoBoolean(parentEdition)} onChange={() => this.changeParentEdition()}/>
          <div className="ganttSetting ml40">{Traduction.translate(language, 'parent_edition')}</div>
        </div>
      </div>

      {/* Button Validate or Cancel */}
      <div className="flex flex-end align-items-center">
        <Button className="fs12 bold brd-radius mr15" variant="primary" onClick={this.applySettings}>{Traduction.translate(language, 'validate')}</Button>
        <Button className="fs12 bold brd-radius" variant="warning" onClick={this.closePlanningSettingsPopup}>{Traduction.translate(language, 'cancel')}</Button>
      </div>
    </div>);
  }

  templatePopoverEditLabel(currentValue, values) {
    const itemId = this.state.itemId;
    const itemType = this.state.itemType;

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverEditSetting ItemId={itemId} ItemType={itemType} CurrentValue={currentValue} Values={values} onValueEdit={this.changeLabel}></PopoverEditSetting>
        </Popover.Content>
      </Popover>
    );

    return popover;
  }

  templatePopoverEditColor(currentValue, values) {
    const itemId = this.state.itemId;
    const itemType = this.state.itemType;

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverEditSetting ItemId={itemId} ItemType={itemType} CurrentValue={currentValue} Values={values} onValueEdit={this.changeColor}></PopoverEditSetting>
        </Popover.Content>
      </Popover>
    );

    return popover;
  }

  closePlanningSettingsPopup() {
    this.setState({ planningSettingsPopup: false });
  }

  render() {
    let { language, currentView, endDate, startDate, currentDate, displayIndicators, editionMode, parentEdition, label, axisColor, rangeType, isBaseline,isCriticalPath, isDependency, isFrozen, planningSettingsPopup } = this.state;

    return (
      <div className="">
        {/* Popup Planning Settings */}
        {(planningSettingsPopup === true) && <div className="ganttSettingsPopup">
          <div className="ganttSettingsInnerPopup">{this.templatePlanningSettings()}</div>
        </div>}

        {['top'].map(placement => (
          <OverlayTrigger key={placement} placement={placement} overlay={<Tooltip id={`tooltip-${placement}`}><strong>{Traduction.translate(language, 'gantt_settings')}</strong></Tooltip>}>
            <div className="filtersPlanningSettings" onClick={() => this.openPlanningSettingsPopup()}>
              <span className="iconParametersBlue iconsFilter cursor"/>
            </div>
          </OverlayTrigger>
        ))}
      </div>
    )
  }
}

export default FiltersPlanning;