import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';
import CardIcon from './CardIcon';
import CardName from './CardName';
import NavigationBlock from './NavigationBlock';
import Parents from './Parents';
import TableAudit from './TableAudit';
import PopupInformation from './PopupInformation';
import PopupEditRights from './PopupEditRights';

const API_favorite = '/WebAppService/SaveObjectFavorite';
const API_alerts = '/WebAppService/ComputeAndSendAlerts';
const API_planning = '/WebAppService/ApplyMassiveUpdates';
const API_critical_path = '/WebAppService/UpdateCriticalPaths';

class BlockTitle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: null,
      authId: null,
      language : null,
      itemId: null,
      itemType: null,
      itemTitle: null,
      blockType: null,
      currentView: {},
      blocks: [],
      alerts: 0,
      favorite: null,
      parents: null,
      warnings: 0,
      info: false,
      informationMessage: null,
      auditPopup: false,
      rightsPopup: false
    };

    this.changeFavorite = this.changeFavorite.bind(this);
    this.executeAlertsBatch = this.executeAlertsBatch.bind(this);
    this.recalculatePlanning = this.recalculatePlanning.bind(this);
    this.updateCriticalPaths = this.updateCriticalPaths.bind(this);
    this.displayAudit = this.displayAudit.bind(this);
    this.displayRights = this.displayRights.bind(this);
    this.validateMessage = this.validateMessage.bind(this);
    this.closeAuditPopup = this.closeAuditPopup.bind(this);
    this.closeRightsPopup = this.closeRightsPopup.bind(this);
  }

  componentDidMount() {
    const login = Authentication.getCookie('login');
    const authId = Authentication.getCookie('authId');
    const language = Authentication.getCookie('language');
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const itemTitle = this.props.ItemTitle;
    const blockType = this.props.BlockType;
    const currentView = this.props.CurrentView;
    const blocks = this.props.Blocks;
    const favorite = this.props.Favorite;
    const parents = this.props.Parents;
    const warnings = this.props.Warnings;

    this.setState({ login, authId, language, itemId, itemType, itemTitle, blockType, currentView, blocks, favorite, parents, warnings });
  }

  componentDidUpdate(prevProps) {
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const itemTitle = this.props.ItemTitle;
    const blockType = this.props.BlockType;
    const currentView = this.props.CurrentView;
    const blocks = this.props.Blocks;
    const favorite = this.props.Favorite;
    const parents = this.props.Parents;
    const warnings = this.props.Warnings;

    if(this.props.ItemId !== prevProps.ItemId || this.props.ItemType !== prevProps.ItemType || this.props.ItemTitle !== prevProps.ItemTitle || this.props.BlockType !== prevProps.BlockType) {
      this.setState({ itemId, itemType, itemTitle, blockType });
    }
    if(JSON.stringify(this.props.CurrentView) !== JSON.stringify(prevProps.CurrentView)) {
      this.setState({ currentView });
    }
    if(JSON.stringify(this.props.Blocks) !== JSON.stringify(prevProps.Blocks)) {
      this.setState({ blocks });
    }
    if(this.props.Favorite !== prevProps.Favorite || this.props.Parents !== prevProps.Parents || this.props.Warnings !== prevProps.Warnings) {
      this.setState({ favorite, parents, warnings });
    }
  }

  // Change Card Favorite (Appear on Home Page if Favorite)
  async changeFavorite() {
    const { login, authId, language, itemId, itemType, favorite } = this.state;

    // Close Popover
    if(document.getElementById("popover-basic")) {
      document.getElementById("popover-basic").classList.remove("show");
    }

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': itemType,
        'ItemId': itemId,
        'Favorite': !favorite
      })
    };

    try {
      const response = await fetch(API_favorite, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const result = data.SaveObjectFavoriteResult;

      if(result) {
        this.setState({ favorite: !favorite, isLoading: false });
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    }
    catch (error) {
      this.setState({ error, isLoading: false });
    }
  }

  async executeAlertsBatch() {
    const { login, authId, language } = this.state;

    // Close Popover
    if(document.getElementById("popover-basic")) {
      document.getElementById("popover-basic").classList.remove("show");
    }

    // Request Options and Body
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      }
    };
    
    try {
      const response = await fetch(API_alerts, requestOptions);
      
      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const alerts = data.ComputeAndSendAlertsResult;
      
      if(alerts || alerts === 0) {
        const informationMessage = alerts + " " + Traduction.translate(language, 'alerts_sent');

        this.setState({ alerts, info: true, informationMessage, isLoading: false });
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  async recalculatePlanning(itemId, itemType, currentView) {
    const { login, authId, language, blockType } = this.state;
    
    // Close Popover
    if(document.getElementById("popover-basic")) {
      document.getElementById("popover-basic").classList.remove("show");
    }

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': itemType,
        'ItemId': itemId,
        'View': currentView,
        'UpdateType': 1
      })
    };

    try{
      const response = await fetch(API_planning, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const result = data.ApplyMassiveUpdatesResult;

      if(result) {
        // Call the event from the Parent component through the props
        this.props.onDataUpdate(login, authId, itemId, itemType, blockType, currentView);
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  async updateCriticalPaths(itemId, itemType, currentView) {
    const { login, authId, language, blockType } = this.state;

    // Close Popover
    if(document.getElementById("popover-basic")) {
      document.getElementById("popover-basic").classList.remove("show");
    }

    // Request Options and Body
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      }
    };

    try {
      const response = await fetch(API_critical_path, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }
    
      const data = await response.json();
      const result = data.UpdateCriticalPathsResult;

      if(result) {
        // Call the event from the Parent component through the props
        this.props.onDataUpdate(login, authId, itemId, itemType, blockType, currentView);
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }
    } catch (error) {
      this.setState({ error, isLoading: false });
    }
  }

  displayAudit() {
    // Close Popover
    if(document.getElementById("popover-basic")) {
      document.getElementById("popover-basic").classList.remove("show");
    }

    this.setState({ auditPopup: true });
  }

  displayRights() {
    // Close Popover
    if(document.getElementById("popover-basic")) {
      document.getElementById("popover-basic").classList.remove("show");
    }
    
    this.setState({ rightsPopup: true });
  }

  validateMessage() {
    this.setState({ info: false });
  }

  closeAuditPopup() {
    this.setState({ auditPopup: false });
  }

  closeRightsPopup() {
    this.setState({ rightsPopup: false });
  }

  render() {
    let { language, itemId, itemType, itemTitle, blockType, currentView, blocks, alerts, favorite, parents, warnings, info, informationMessage, auditPopup, rightsPopup } = this.state;

    return (
      <div className="blockTitle">
        {/* Audit Popup */}
        {auditPopup && <TableAudit ItemId={itemId} ItemType={itemType} ItemTitle={itemTitle} BlockType={blockType} onPopupClose={this.closeAuditPopup}></TableAudit>}

        {/* Message Information Popup */}
        {info && <PopupInformation Message={informationMessage} onMessageValidate={this.validateMessage}></PopupInformation>}

        {/* Rights Popup */}
        {rightsPopup && <PopupEditRights ItemId={itemId} ItemType={itemType} ItemTitle={itemTitle} onPopupClose={this.closeRightsPopup}></PopupEditRights>}

        {/* Card Icon */}
        <CardIcon ItemId={itemId} ItemType={itemType} BlockType={blockType} CurrentView={currentView} Favorite={favorite} onFavoriteChange={this.changeFavorite} onAuditDisplay={this.displayAudit} onRightsDisplay={this.displayRights} onAlertsBatchExecute={this.executeAlertsBatch} onPlanningRecalculate={this.recalculatePlanning} onCriticalPathsUpdate={this.updateCriticalPaths}></CardIcon>

        <div className="flex flex-column">
          {/* Card Title */}
          <div className="cardTitle">
            {/* Card Name */}
            <CardName ItemType={itemType} BlockType={blockType} Name={itemTitle}></CardName>

            {/* Card Attributes */}
            <div className="flex align-items-center">
              {/* Favorite */}
              {itemType !== "Meeting" && (favorite === true || favorite === false) && 
                <div className={(favorite === true ? "iconStarYellow " : "iconStarGrey ") + "iconsFavorite cursor mr10"} onClick={(e) => this.changeFavorite()}></div>
              }
              {/* Warnings */}
              {warnings > 0 && <Link className="" to={`/Card/${itemType}/${itemId}/Warnings`}>
                <div className="cardWarnings cursor">
                  <span className="iconWarningWhite verysmallIcons"></span>
                  <span className="fs12 white">{warnings}</span>
                </div>
              </Link>}
              {/* Parents */}
              {Array.isArray(parents) && parents.length > 0 && <div className="labelIn">{Traduction.translate(language, 'in')}</div>}
              {Array.isArray(parents) && parents.length > 0 && <Parents Parents={parents}></Parents>}
            </div>
          </div>

          {/* Card Navigation */}
          {Array.isArray(blocks) && blocks.length > 0 && <NavigationBlock ItemId={itemId} ItemType={itemType} Blocks={blocks} BlockType={blockType}></NavigationBlock>}
        </div>
      </div>
    )
  }
}

export default BlockTitle;