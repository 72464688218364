import React, {Component} from 'react';
import { ProgressBar } from 'react-bootstrap';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';

class IndicatorsTimeTracking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: null,
      timetrackingType: null,
      columns: [],
      rows: [],
      days: [],
      weeks: {},
      progress: null,
      burned_workload: null,
      holidays: null,
      bank_holidays: null,
      occupancy: null,
      open_days: null
    };

    // Indicators functions
    this.TTmonthIndicatorsCalculation = this.TTmonthIndicatorsCalculation.bind(this);
    this.TTdayIndicatorsCalculation = this.TTdayIndicatorsCalculation.bind(this);
    // this.bankholidaysCalculation = this.bankholidaysCalculation.bind(this);
    // this.holidaysCalculation = this.holidaysCalculation.bind(this);
    // this.opendaysCalculation = this.opendaysCalculation.bind(this);
    // this.occupancyCalculation = this.occupancyCalculation.bind(this);
    // this.progressCalculation = this.progressCalculation.bind(this);
    this.refreshIndicators = this.refreshIndicators.bind(this);
  }

  async componentDidMount() {
    const language = Authentication.getCookie('language');
    const timetrackingType = this.props.TimetrackingType;
    const columns = this.props.Columns;
    const rows = this.props.Rows;
    const days = this.props.Days;
    const weeks = this.props.Weeks;

    if(timetrackingType === 'Month') {
      this.TTmonthIndicatorsCalculation(rows, days);
    }
    else if(timetrackingType === 'Day') {
      this.TTdayIndicatorsCalculation(rows, columns, weeks);
    }

    this.setState({ language, timetrackingType, columns, rows, days, weeks });

    // const burned_workload = this.burnedCalculation();
    // const holidays = this.holidaysCalculation();
    // const bank_holidays = this.bankholidaysCalculation();
    // const open_days = this.opendaysCalculation();

    // const occupancy = this.occupancyCalculation(burned_workload, holidays, open_days);
    // const progress = this.progressCalculation(burned_workload, holidays, open_days);

    // this.setState({ timetrackingType, rows, days, burned_workload, holidays, bank_holidays, occupancy, open_days, progress });
  }

  componentDidUpdate(prevProps) {
    const timetrackingType = this.props.TimetrackingType;
    const columns = this.props.Columns;
    const rows = this.props.Rows;
    const days = this.props.Days;
    const weeks = this.props.Weeks;

    if(JSON.stringify(columns) != JSON.stringify(prevProps.Columns) || JSON.stringify(rows) != JSON.stringify(prevProps.Rows) || JSON.stringify(days) != JSON.stringify(prevProps.Days) || JSON.stringify(weeks) != JSON.stringify(prevProps.Weeks)) {
      if(timetrackingType === 'Month') {
        this.TTmonthIndicatorsCalculation(rows, days);
      }
      else if(timetrackingType === 'Day') {
        this.TTdayIndicatorsCalculation(rows, columns, weeks);
      }
  
      this.setState({ timetrackingType, columns, rows, days, weeks });
      
      // const burned_workload = this.burnedCalculation();
      // const holidays = this.holidaysCalculation();
      // const bank_holidays = this.bankholidaysCalculation();
      // const open_days = this.opendaysCalculation();
      // const occupancy = this.occupancyCalculation(burned_workload, holidays, open_days);
      // const progress = this.progressCalculation(burned_workload, holidays, open_days);
  
      // this.setState({ timetrackingType, columns, rows, days, burned_workload, holidays, bank_holidays, occupancy, open_days, progress });
    }
  }

  TTmonthIndicatorsCalculation(rows, days) {
    let burned_workload = 0;
    let bank_holidays = 0;
    let holidays = 0;
    let open_days = 0;
    let occupancy = 0;
    let progress = 0;

    // Burned Workload
    if(rows) {
      rows.forEach(row => {
        let index = row.Cells.findIndex(cell => cell.ColumnName === 'Month_Burned');
  
        // Sum Burned_Workload from Table rows
        if(index !== -1) {
          burned_workload += parseFloat(row.Cells[index].Value);
        }
      });

      burned_workload = parseFloat(burned_workload.toPrecision(3));
    }

    // Days
    if(days) {
      days.forEach(day => {
        // Bank Holiday
        if(!day.Weekend && day.BankHoliday) {
          bank_holidays ++;
        }
        else if(!day.Weekend && !day.BankHoliday) {
          // Holidays
          if(day.DayOff) {
            holidays += 1;
          }
          else if(day.HalfDayOff) {
            holidays += 0.5;
          }
          // Open Days
          // else {
          //   open_days ++;
          // }

          open_days ++;
        }
      });
    }

    // Occupancy
    occupancy = parseFloat((open_days - holidays - burned_workload).toPrecision(3));

    // Progress
    if(open_days !== 0) {
      progress = (burned_workload + holidays) * 100 / open_days;
      progress = parseInt(progress.toFixed(0));
    }

    this.setState({ burned_workload, bank_holidays, holidays, open_days, occupancy, progress });
  }

  TTdayIndicatorsCalculation(rows, columns, weeks) {
    let burned_workload = 0;
    let bank_holidays = 0;
    let holidays = 0;
    let open_days = 0;
    let occupancy = 0;
    let progress = 0;

    // If specific Week is selected
    if(weeks.find(week => week.IsSelected).WeekId !== 0) {
      weeks.forEach(week => {
        // Get other Weeks values
        if(!week.IsSelected && week.WeekId) {
          burned_workload += week.Burned_Workload;
          bank_holidays += week.BankHolidays;
          holidays += week.Holidays;
          open_days += week.OpenDays;
        }
      });
    }

    // Get Tables values
    if(rows) {
      rows.forEach(row => {
        row.Cells.forEach(cell => {
          if(cell.FieldType === 'Day' && cell.Value && cell.Value > 0) {
            // Holidays
            if(row.ItemType === 'Holiday') {
              holidays += parseFloat(cell.Value);
            }
            // Burned Workload
            else if(row.ItemType === 'Task') {
              burned_workload += parseFloat(cell.Value);
            }
          }
        });
      });

      // Round Burned Workload
      burned_workload = parseFloat(burned_workload.toPrecision(3));
    }

    // Open Days & Bank Holidays
    if(columns) {
      columns.forEach(column => {
        if(column.FieldType === 'BankHoliday') {
          bank_holidays += 1;
        }
        else if(column.FieldType === 'OpenDay') {
          open_days += 1;
        }
      });
    }

    // Occupancy
    occupancy = parseFloat((open_days - holidays - burned_workload).toPrecision(4));

    // Progress
    if(open_days !== 0) {
      progress = (burned_workload + holidays) * 100 / open_days;
      progress = parseInt(progress.toFixed(0));
    }

    this.setState({ burned_workload, bank_holidays, holidays, open_days, occupancy, progress });
  }

  // bankholidaysCalculation() {
  //   const days = this.props.Days;
  //   let bank_holidays = 0;

  //   if(days) {
  //     days.forEach(day => {
  //       if(!day.Weekend && day.BankHoliday) {
  //         bank_holidays ++;
  //       }
  //     });
  //   }

  //   return bank_holidays;
  // }

  // holidaysCalculation() {
  //   const days = this.props.Days;
  //   let holidays = 0;

  //   if(days) {
  //     days.forEach(day => {
  //       if(!day.Weekend && !day.BankHoliday) {
  //         if(day.DayOff) {
  //           holidays += 1;
  //         }
  //         else if(day.HalfDayOff) {
  //           holidays += 0.5;
  //         }
  //       }
  //     });  
  //   }
    
  //   return holidays;
  // }

  // opendaysCalculation() {
  //   const days = this.props.Days;
  //   let open_days = 0;

  //   if(days) {
  //     days.forEach(day => {
  //       if(!day.Weekend && !day.BankHoliday) {
  //         open_days ++;
  //       }
  //     });
  //   }
    
  //   return open_days;
  // }

  // occupancyCalculation() {
  //   const { burned_workload, holidays, open_days } = this.state;

  //   let occupancy = 0;
    
  //   occupancy = parseFloat((open_days - holidays - burned_workload).toFixed(1));

  //   this.setState({ occupancy });
  // }

  // progressCalculation() {
  //   const { burned_workload, holidays, open_days } = this.state;

  //   let progress = 0;

  //   if(open_days !== 0) {
  //     progress = (burned_workload + holidays) * 100 / open_days;
  //     progress = parseInt(progress.toFixed(0));
  //   }

  //   this.setState({ progress });
  // }

  refreshIndicators(days, rows, weeks) {
    const timetrackingType = this.props.TimetrackingType;
    const columns = this.props.Columns;
    // const rows = this.props.Rows;
    // const days = this.props.Days;

    if(timetrackingType === 'Month') {
      this.TTmonthIndicatorsCalculation(rows, days);
    }
    else if(timetrackingType === 'Day') {
      this.TTdayIndicatorsCalculation(rows, columns, weeks);
    }

    this.setState({ timetrackingType, columns, rows, days, weeks });

    // const burned_workload = this.burnedCalculation();
    // const holidays = this.holidaysCalculation();
    // const bank_holidays = this.bankholidaysCalculation();
    // const open_days = this.opendaysCalculation();
    // const occupancy = this.occupancyCalculation(burned_workload, holidays, open_days);
    // const progress = this.progressCalculation(burned_workload, holidays, open_days);

    // this.setState({ burned_workload, holidays, bank_holidays, occupancy, open_days, progress });
  }

  render() {
    const { language, columns, rows, days, progress, burned_workload, holidays, bank_holidays, occupancy, open_days } = this.state;

    return (
      <div className="timetrackingIndicators">
        {/* Time Tracking Indicators */}
        <div className="col-md-6 pl20">
          <div className="row flex center">
            {/* Holidays */}
            <div className="flex align-items-center col-md-3 fs12 brd-left-turquoise">
              <span className="timetrackingIndicator turquoise">{holidays}</span>
              {holidays === 1 && <span className="darkblue mt5">{Traduction.translate(language, 'day_off')}</span>}
              {holidays > 1 && <span className="darkblue mt5">{Traduction.translate(language, 'days_off')}</span>}
            </div>
            {/* Occupancy */}
            <div className="flex align-items-center col-md-3 fs12 brd-left-verylightgrey">
              <span className="timetrackingIndicator very-light-grey">{occupancy}</span>
              {occupancy === 1 && <span className="darkblue mt5">{Traduction.translate(language, 'remaining')}</span>}
              {occupancy > 1 && <span className="darkblue mt5">{Traduction.translate(language, 'remainings')}</span>}
            </div>
            {/* Bank Holidays */}
            <div className="flex align-items-center col-md-3 fs12 brd-left-grey">
              <span className="timetrackingIndicator grey">{bank_holidays}</span>
              {bank_holidays === 1 && <span className="darkblue mt5">{Traduction.translate(language, 'bank_holiday')}</span>}
              {bank_holidays > 1 && <span className="darkblue mt5">{Traduction.translate(language, 'bank_holidays')}</span>}
            </div>
            {/* Burned Workload */}
            <div className="flex align-items-center col-md-3 fs12 brd-left-blue">
              <span className="timetrackingIndicator blue">{burned_workload}</span>
              {burned_workload === 1 && <span className="darkblue mt5">{Traduction.translate(language, 'burned_workload')}</span>}
              {burned_workload > 1 && <span className="darkblue mt5">{Traduction.translate(language, 'burned_workloads')}</span>}
            </div>
          </div>
        </div>
        {/* Timetracking Progress */}
        <div className="col-md-6 center ph20">
          {/* Percentage */}
          {progress <= 100 && <div className="center fs25 bold mr20">{progress}%</div>}
          {progress > 100 && <div className="center fs25 red bold mr20">{progress}%</div>}
          {/* Progress Bar */}
          <ProgressBar className="progressTimeTracking" now={progress}>
            <ProgressBar className="" variant="info" now={(holidays*100)/open_days} key={1}/>
            <ProgressBar className="" variant="primary" now={(burned_workload*100)/open_days} key={2}/>
            <ProgressBar className="" variant="light" now={(occupancy*100)/open_days} key={3}/>
          </ProgressBar>
        </div>
      </div>
    )
  }
}

export default IndicatorsTimeTracking;