import React, {Component} from 'react';
import MetaTags from 'react-meta-tags';
import { Button, Form } from 'react-bootstrap';
import queryString from 'query-string';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';
import Navbar from './Navbar';
import BlockTitle from './BlockTitle';
import FiltersView from './FiltersView';
import FiltersCurrentView from './FiltersCurrentView';
import FiltersBlock from './FiltersBlock';
import FiltersSort from './FiltersSort';
import FiltersConditionalFormatting from './FiltersConditionalFormatting';
import FiltersLevel from './FiltersLevel';
import FiltersPlanning from './FiltersPlanning';
import FiltersKanban from './FiltersKanban';
import FiltersChart from './FiltersChart';
import FiltersMap from './FiltersMap';
import FiltersColumnChooser from './FiltersColumnChooser';
import FiltersImport from './FiltersImport';
import FiltersExport from './FiltersExport';
import LoadingSpinner from './LoadingSpinner';
import ErrorModification from './ErrorModification';
import PopupConfirmation from './PopupConfirmation';
import PopupEditDependencies from './PopupEditDependencies';
import Table from './Table';
import Tree from './Tree';
import Kanban from './Kanban';
import Planning from './Planning';
import Pivot from './Pivot';
import RoadmapScheduler from './RoadmapScheduler';
import Chart from './Chart';
import Map from './Map';

const API_info = '/WebAppService/GetCardBlockInformation';
const API_templates = '/WebAppService/GetTemplates';
const API_valid = '/WebAppService/GetPropagationsAndSaveModification';
const API_shift = '/WebAppService/ShiftParent';
const API_create = '/WebAppService/InsertItemAndSave';
const API_delete = '/WebAppService/DeleteItemAndSave';
const API_cut_paste = '/WebAppService/MoveItem';

class BlockRoadMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: null,
      authId: null,
      language: null,
      itemId: null,
      itemType: null,
      itemTitle: null,
      blockType: null,
      blockInfo: {},
      blockContent: {},
      editable: null,
      guestLicence: null,
      favorite: null,
      parents: [],
      warnings: 0,
      levels: [],
      displayViews: null,
      views: [],
      defaultViewId: null,
      viewId: null,
      currentView: {},
      headers: [],
      tables: [],
      columns: [],
      rows: [],
      chart: {},
      map: {},
      parentId: null,
      addLevel: 0,
      addBLPopup: false,
      addProjectPopup: false,
      availableBLTemplates: [],
      availableProjectTemplates: [],
      newBLTemplate: null,
      newProjectTemplate: null,
      templateSelected: {},
      confirm: false,
      deleteId: null,
      deleteType: null,
      deleteMessage: null,
      dependenciesPopup: false,
      rowInformation: null,
      search: '',
      isLoading: false,
      errors: []
    };
    
    // Data Structure
    this.getCardInformation = this.getCardInformation.bind(this);
    this.getCardData = this.getCardData.bind(this);
    this.getColumnsName = this.getColumnsName.bind(this);
    this.getAvailableTemplates = this.getAvailableTemplates.bind(this);
    // this.formatDate = this.formatDate.bind(this);

    // Actions
    this.displayViews = this.displayViews.bind(this);
    this.hideViews = this.hideViews.bind(this);
    this.changeView = this.changeView.bind(this);
    this.setDefaultView = this.setDefaultView.bind(this);
    this.changeFilters = this.changeFilters.bind(this);
    this.changeSort = this.changeSort.bind(this);
    this.changeFormattings = this.changeFormattings.bind(this);
    this.changeLevel = this.changeLevel.bind(this);
    this.changeAxe = this.changeAxe.bind(this);
    this.changeKeyField = this.changeKeyField.bind(this);
    this.changeColumns = this.changeColumns.bind(this);
    this.applyPlanningSettings = this.applyPlanningSettings.bind(this);
    this.displayDependenciesPopup = this.displayDependenciesPopup.bind(this);
    this.applyChartSettings = this.applyChartSettings.bind(this);
    this.displayChartSettings = this.displayChartSettings.bind(this);
    this.closeChartSettings = this.closeChartSettings.bind(this);
    this.applyMapSettings = this.applyMapSettings.bind(this);
    this.displayMapSettings = this.displayMapSettings.bind(this);
    this.closeMapSettings = this.closeMapSettings.bind(this);
    // this.applyView = this.applyView.bind(this);
    this.filterColumn = this.filterColumn.bind(this);
    this.sortColumn = this.sortColumn.bind(this);
    this.checkModification = this.checkModification.bind(this);
    this.shiftParent = this.shiftParent.bind(this);
    this.createItem = this.createItem.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
    this.cancelDelete = this.cancelDelete.bind(this);
    this.cutPasteItem = this.cutPasteItem.bind(this);
    this.updateRowsInTable = this.updateRowsInTable.bind(this);
    this.applyPropagations = this.applyPropagations.bind(this);
    this.addRowsInTable = this.addRowsInTable.bind(this);
    this.deleteRowInTable = this.deleteRowInTable.bind(this);
    this.getChildToDelete = this.getChildToDelete.bind(this);
    this.exportCSV = this.exportCSV.bind(this);
    this.exportXLSX = this.exportXLSX.bind(this);
    this.exportJPEG = this.exportJPEG.bind(this);
    this.exportPNG = this.exportPNG.bind(this);
    this.exportPDF = this.exportPDF.bind(this);
    this.searchItem = this.searchItem.bind(this);
    this.updateView = this.updateView.bind(this);
    // this.updateTable = this.updateTable.bind(this);
    this.updateErrors = this.updateErrors.bind(this);
    this.cleanErrors = this.cleanErrors.bind(this);

    // Template Functions
    this.templateAddBLPopup = this.templateAddBLPopup.bind(this);
    this.templateAddProjectPopup = this.templateAddProjectPopup.bind(this);
    this.closeAddPopup = this.closeAddPopup.bind(this);
    this.closeDependenciesPopup = this.closeDependenciesPopup.bind(this);

    // Reference
    this.views = React.createRef();
    this.levels = React.createRef();
    this.filters = React.createRef();
    this.sort = React.createRef();
    this.formattings = React.createRef();
    this.table = React.createRef();
    this.tree = React.createRef();
    this.kanban = React.createRef();
    this.planning = React.createRef();
    this.pivot = React.createRef();
    this.chart = React.createRef();
    this.map = React.createRef();
    this.scheduler = React.createRef();
  }

  async componentDidMount() {
    const login = Authentication.getCookie('login');
    const authId = Authentication.getCookie('authId');
    const language = Authentication.getCookie('language');
    const itemId = this.props.match.params.itemId;
    const itemType = this.props.match.params.itemType;
    const path = this.props.match.path.split('/');
    const blockType = path[path.length-1];
    let displayViews;

    if(Authentication.getCookie('displayViews')) {
      displayViews = JSON.parse(Authentication.getCookie('displayViews'));
    }
    else {
      displayViews = true;
    }

    // Get Params from Query string url
    let params, viewId;

    if(this.props.location.search) {
      params = queryString.parse(this.props.location.search);
      viewId = parseInt(params.viewId);
    }

    this.setState({ login, authId, language, itemId, itemType, blockType, displayViews });

    // Get Card Information
    await this.getCardInformation(login, authId, itemId, itemType, blockType, viewId);

    const blockInfo = this.state.blockInfo;
    const views = this.state.views;
    let currentView = {};

    if(Object.entries(blockInfo).length > 0) {
      // Get Current View
      if(viewId && views.find(view => view.ViewId === viewId)) {
        currentView = views.find(view => view.ViewId === viewId);
      }
      else if(views.find(view => view.ViewId === blockInfo.DefaultViewId)) {
        currentView = views.find(view => view.ViewId === blockInfo.DefaultViewId);
      }

      if(!viewId && (currentView.ViewId && viewId !== currentView.ViewId)) {
        // Redirect with new Query params
        this.props.history.push(`/Card/${itemType}/${itemId}/${blockType}?viewId=${currentView.ViewId}`);
      }
      else {
        // Get Card Data
        await this.getCardData(login, authId, itemId, itemType, blockType, currentView);
      }
    }
    else {
      // Redirect to Login Page
      this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
    }
  }

  async componentDidUpdate(prevProps) {
    const login = Authentication.getCookie('login');
    const authId = Authentication.getCookie('authId');
    const itemId = this.props.match.params.itemId;
    const itemType = this.props.match.params.itemType;
    const path = this.props.match.path.split('/');
    const blockType = path[path.length-1];

    // Get Params from Query string url
    let params, old_params, viewId, oldViewId;

    if(this.props.location.search) {
      params = queryString.parse(this.props.location.search);
      viewId = parseInt(params.viewId);
    }
    if(prevProps.location.search) {
      old_params = queryString.parse(prevProps.location.search);
      oldViewId = parseInt(old_params.viewId);
    }

    if(itemId !== prevProps.match.params.itemId || itemType !== prevProps.match.params.itemType) {
      this.setState({ itemId, itemType });
      
      // Get Card Information
      await this.getCardInformation(login, authId, itemId, itemType, blockType, viewId);
    }
    if(viewId !== oldViewId) {
      const blockInfo = this.state.blockInfo;
      const views = this.state.views;
      let currentView = {};

      // Get Current View
      if(viewId && views.find(view => view.ViewId === viewId)) {
        currentView = views.find(view => view.ViewId === viewId);
      }
      else if(views.find(view => view.ViewId === blockInfo.DefaultViewId)) {
        currentView = views.find(view => view.ViewId === blockInfo.DefaultViewId);
      }

      // Get Card Data
      await this.getCardData(login, authId, itemId, itemType, blockType, currentView);
      
      // Refresh Filters View
      // if(this.views.current) {
      //   this.views.current.selectView(currentView);
      // }

      const blockContent = this.state.blockContent;
      const columns = this.state.columns;

      // Refresh Components with CurrentView
      if(currentView.ViewType === 0 && this.table.current) {
        this.table.current.refreshColumns(currentView);
      }
      else if(currentView.ViewType === 0 && this.tree.current) {
        this.tree.current.refreshColumns(currentView);
      }
      else if(currentView.ViewType === 1 && this.planning.current) {
        this.planning.current.refreshColumns(currentView);
      }
      else if(currentView.ViewType === 2 && this.kanban.current) {
        this.kanban.current.refreshKanban(currentView, blockContent.Kanban, columns);
      }
      else if(currentView.ViewType === 3 && this.pivot.current) {
        this.pivot.current.refreshPivot(currentView);
      }
      else if(currentView.ViewType === 7 && this.chart.current) {
        this.chart.current.applySettings(currentView);
      }
      else if(currentView.ViewType === 8 && this.map.current) {
        this.map.current.applySettings(currentView);
      }
    }
  }

  // Get Block Information from the API
  async getCardInformation(login, authId, itemId, itemType, blockType, viewId) {
    const language = this.state.language;

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': itemType,
        'ItemId': itemId,
        'BlockType': blockType,
        'ViewId': viewId,
        'WithData': false,
        'WithInformation': true,
        'InactiveData': false
      })
    };

    try{
      const response = await fetch(API_info, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const blockInfo = data.GetCardBlockInformationResult;

      if(blockInfo) {
        const itemTitle = blockInfo.ObjectName;
        const editable = blockInfo.InsertDeleteAllowed;
        const guestLicence = blockInfo.GuestLicence;
        const favorite = blockInfo.IsFavorite;
        const parents = blockInfo.Parents;
        const warnings = blockInfo.Warnings;
        const levels = blockInfo.AvailableLevels;
        const views = blockInfo.Views;
        const defaultViewId = blockInfo.DefaultViewId;
        let currentView = {};

        // Get Current View
        if(viewId && views.find(view => view.ViewId === viewId)) {
          currentView = views.find(view => view.ViewId === viewId);
        }
        else if(views.find(view => view.ViewId === blockInfo.DefaultViewId)) {
          currentView = views.find(view => view.ViewId === blockInfo.DefaultViewId);
        }

        this.setState({ blockInfo, itemTitle, editable, guestLicence, favorite, parents, warnings, levels, views, defaultViewId, viewId, currentView });
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  // Get Block Data from the API
  async getCardData(login, authId, itemId, itemType, blockType, currentView) {
    const language = this.state.language;

    this.setState({ isLoading: true });

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': itemType,
        'ItemId': itemId,
        'BlockType': blockType,
        'View': currentView,
        'WithData': true,
        'WithInformation': false,
        'InactiveData': false
      })
    };

    try{
      const response = await fetch(API_info, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const blockContent = data.GetCardBlockInformationResult;

      if(blockContent) {
        const headers = blockContent.Headers;
        const tables = blockContent.Tables;
        const chart = blockContent.Chart;
        const map = blockContent.Map;
        let columns = [], rows = [];

        // Get Tables, Rows & Columns
        if(tables.find(element => element.Level === "Tree")) {
          columns = tables.find(element => element.Level === "Tree").ColumnHeaders;
          rows = tables.find(element => element.Level === "Tree").Rows;
        }
        
        // Apply View
        // this.applyView(rows, currentView);

        this.setState({ blockContent, viewId: currentView.ViewId, currentView, headers, tables, columns, rows, chart, map, isLoading: false });
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  getColumnsName() {
    let columns = this.state.columns;
    let columnNames = [];

    columns.forEach(column => {
      // Exclude Column Index for creation (does not exist in Database)
      if(column.FieldName !== 'Index') {
        columnNames.push(column.FieldName);
      }
    });

    return columnNames;
  }

  async getAvailableTemplates(itemType) {
    const { login, authId, language } = this.state;
    
    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        ItemType: itemType
      })
    };

    try{
      const response = await fetch(API_templates, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const result = data.GetTemplatesResult;

      if(result) {
        if(itemType === 'Business_Line') {
          this.setState({ availableBLTemplates: result });
        }
        else if(itemType === 'Project') {
          this.setState({ availableProjectTemplates: result });
        }
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  // Format Date for Timeline calculation
  // formatDate(date) {
  //   let formattedDate;

  //   if(date) {
  //     const split = date.split('/');
  //     const day = split[1];
  //     const month = split[0]-1; // Between 0 and 11
  //     const year = split[2];

  //     formattedDate = new Date(Date.UTC(year, month, day)).toISOString();
  //   }

  //   return formattedDate;
  // }

  displayViews() {
    const currentView = this.state.currentView;

    Authentication.deleteCookie('displayViews');
    Authentication.createCookie('displayViews', true);

    this.setState({ displayViews: true });

    if(currentView.ViewType === 8 && this.map.current) {
      this.map.current.applySettings(currentView);
    }
  }

  hideViews() {
    const currentView = this.state.currentView;
    
    Authentication.deleteCookie('displayViews');
    Authentication.createCookie('displayViews', false);

    this.setState({ displayViews: false });

    if(currentView.ViewType === 8 && this.map.current) {
      this.map.current.applySettings(currentView);
    }
  }

  changeView(view) {
    const itemId = this.state.itemId;
    const itemType = this.state.itemType;
    const blockType = this.state.blockType;
    // const rows = this.state.rows;

    // // Apply View
    // this.applyView(rows, view);

    // Add/Remove Column from Table/Tree/Gantt/Pivot
    // if(view.ViewType === 0 && this.table.current) {
    //   this.table.current.refreshColumns(view);
    // }
    // else if(view.ViewType === 0 && this.tree.current) {
    //   this.tree.current.refreshColumns(view);
    // }
    // // else if(view.ViewType === 1 && this.planning.current) {
    // //   this.planning.current.refreshColumns(view);
    // // }
    // else if(view.ViewType === 3 && this.pivot.current) {
    //   this.pivot.current.refreshPivot(view);
    // }

    // this.setState({ currentView: view });

    // Redirect with new Query params
    this.props.history.push(`/Card/${itemType}/${itemId}/${blockType}?viewId=${view.ViewId}`);
  }

  setDefaultView(viewId) {
    this.setState({ defaultViewId: viewId });
  }

  async changeFilters(currentView) {
    const login = this.state.login;
    const authId = this.state.authId;
    const itemId = this.state.itemId;
    const itemType = this.state.itemType;
    const blockType = this.state.blockType;

    // Add Modify attribute in CurrentView
    currentView["Modify"] = true;

    // Get Card Data
    await this.getCardData(login, authId, itemId, itemType, blockType, currentView);

    // Refresh Component Columns
    if(this.table.current) {
      this.table.current.refreshColumns(currentView);
    }
    else if(this.tree.current) {
      this.tree.current.refreshColumns(currentView);
    }
    else if(this.planning.current) {
      this.planning.current.refreshColumns(currentView);
    }
  }

  async changeSort(currentView) {
    const login = this.state.login;
    const authId = this.state.authId;
    const itemId = this.state.itemId;
    const itemType = this.state.itemType;
    const blockType = this.state.blockType;

    // Add Modify attribute in CurrentView
    currentView["Modify"] = true;

    // Get Card Data
    await this.getCardData(login, authId, itemId, itemType, blockType, currentView);

    // Refresh Component Columns
    if(this.table.current) {
      this.table.current.refreshColumns(currentView);
    }
    else if(this.tree.current) {
      this.tree.current.refreshColumns(currentView);
    }
    else if(this.planning.current) {
      this.planning.current.refreshColumns(currentView);
    }
  }

  async changeFormattings(currentView) {
    const login = this.state.login;
    const authId = this.state.authId;
    const itemId = this.state.itemId;
    const itemType = this.state.itemType;
    const blockType = this.state.blockType;

    // Add Modify attribute in CurrentView
    currentView["Modify"] = true;

    // Get Card Data
    await this.getCardData(login, authId, itemId, itemType, blockType, currentView);

    // Refresh Component Columns
    if(this.table.current) {
      this.table.current.refreshColumns(currentView);
    }
    else if(this.tree.current) {
      this.tree.current.refreshColumns(currentView);
    }
    else if(this.planning.current) {
      this.planning.current.refreshColumns(currentView);
    }
    else if(this.map.current) {
      this.map.current.applySettings(currentView);
    }
  }

  async changeLevel(value) {
    const login = this.state.login;
    const authId = this.state.authId;
    const itemId = this.state.itemId;
    const itemType = this.state.itemType;
    const blockType = this.state.blockType;
    // const rows = this.state.rows;
    const currentView = this.state.currentView;
    let level;

    switch(value) {
      case "Tree":
          level = 0;
          break;
      case "Business_Line":
          level = 1;
          break;
      case "Project":
          level = 2;
          break;
      case "Workpackage":
          level = 3;
          break;
      case "Action":
          level = 4;
          break;
      case "Task":
          level = 5;
          break;
      case "Sprint":
          level = 24;
          break;
      case "AgileBoard":
          level = 25;
          break;
      default:
          break;
    }

    // Update Current View Level
    currentView.DefaultLevel = level;

    // Apply View
    // this.applyView(rows, currentView);

    // Add Modify attribute in CurrentView
    currentView["Modify"] = true;

    // Get Card Data
    await this.getCardData(login, authId, itemId, itemType, blockType, currentView);

    // Add/Remove Column from Table/Tree/Gantt
    if(this.table.current) {
      this.table.current.refreshColumns(currentView);
    }
    else if(this.tree.current) {
      this.tree.current.refreshColumns(currentView);
    }
    else if(this.planning.current) {
      this.planning.current.refreshColumns(currentView);
    }
    else if(this.chart.current) {
      this.chart.current.applySettings(currentView);
    }
    else if(this.map.current) {
      this.map.current.applySettings(currentView);
    }

    // this.setState({ currentView });
  }

  async changeAxe(axe) {
    const login = this.state.login;
    const authId = this.state.authId;
    const itemId = this.state.itemId;
    const itemType = this.state.itemType;
    const blockType = this.state.blockType;
    const currentView = this.state.currentView;

    // Update Current View Axe
    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Axe1')) {
      currentView.Parameters.find(param => param.Name === 'Axe1').Value = axe;
    }

    // Add Modify attribute in CurrentView
    currentView["Modify"] = true;

    // Get Card Data
    await this.getCardData(login, authId, itemId, itemType, blockType, currentView);

    // Update Axe in Kanban component
    // if(this.kanban.current) {
    //   this.kanban.current.updateAxe(axe);
    // }

    // this.setState({ currentView });
  }

  async changeKeyField(keyField) {
    const login = this.state.login;
    const authId = this.state.authId;
    const itemId = this.state.itemId;
    const itemType = this.state.itemType;
    const blockType = this.state.blockType;
    const currentView = this.state.currentView;

    // Update Current View KeyField
    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Axe2')) {
      currentView.Parameters.find(param => param.Name === 'Axe2').Value = keyField;
    }

    // Add Modify attribute in CurrentView
    currentView["Modify"] = true;

    // Get Card Data
    await this.getCardData(login, authId, itemId, itemType, blockType, currentView);

    // Update Axe in Kanban component
    // if(this.kanban.current) {
    //   this.kanban.current.updateKeyField(keyField);
    // }

    // this.setState({ currentView });
  }

  async changeColumns(columns) {
    const login = this.state.login;
    const authId = this.state.authId;
    const itemId = this.state.itemId;
    const itemType = this.state.itemType;
    const blockType = this.state.blockType;
    // const rows = this.state.rows;
    const currentView = this.state.currentView;

    // Update Current View Columns list
    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Columns')) {
      currentView.Parameters.find(param => param.Name === 'Columns').Value = columns;
    }

    // Apply View
    // this.applyView(rows, currentView);

    // Add Modify attribute in CurrentView
    currentView["Modify"] = true;

    // Get Card Data
    await this.getCardData(login, authId, itemId, itemType, blockType, currentView);

    // Add/Remove Column from Table/Tree/Gantt
    if(this.table.current) {
      this.table.current.refreshColumns(currentView);
    }
    else if(this.tree.current) {
      this.tree.current.refreshColumns(currentView);
    }
    else if(this.planning.current) {
      this.planning.current.refreshColumns(currentView);
    }

    // this.setState({ currentView });
  }

  async applyPlanningSettings(currentView) {
    const login = this.state.login;
    const authId = this.state.authId;
    const itemId = this.state.itemId;
    const itemType = this.state.itemType;
    const blockType = this.state.blockType;

    // Add Modify attribute in CurrentView
    currentView["Modify"] = true;

    // Get Card Data
    await this.getCardData(login, authId, itemId, itemType, blockType, currentView);

    // Apply Setting on Planning and Update CurrentView
    if(this.planning.current) {
      this.planning.current.applySettings(currentView);
    }

    this.setState({ currentView });
  }

  displayDependenciesPopup(row) {
    this.setState({ rowInformation: row, dependenciesPopup: true });
  }

  async applyChartSettings(currentView) {
    const login = this.state.login;
    const authId = this.state.authId;
    const itemId = this.state.itemId;
    const itemType = this.state.itemType;
    const blockType = this.state.blockType;

    // Add Modify attribute in CurrentView
    currentView["Modify"] = true;

    // Get Card Data
    await this.getCardData(login, authId, itemId, itemType, blockType, currentView);

    // Apply Setting on Chart and Update CurrentView
    if(this.chart.current) {
      this.chart.current.applySettings(currentView);
    }

    // this.setState({ currentView });
  }

  displayChartSettings() {
    if(this.chart.current) {
      this.chart.current.displaySettings();
    }
  }

  closeChartSettings() {
    if(this.chart.current) {
      this.chart.current.closeSettings();
    }
  }

  async applyMapSettings(currentView) {
    const login = this.state.login;
    const authId = this.state.authId;
    const itemId = this.state.itemId;
    const itemType = this.state.itemType;
    const blockType = this.state.blockType;

    // Add Modify attribute in CurrentView
    currentView["Modify"] = true;

    // Get Card Data
    await this.getCardData(login, authId, itemId, itemType, blockType, currentView);

    // Apply Setting on Map and Update CurrentView
    if(this.map.current) {
      this.map.current.applySettings(currentView);
    }

    // this.setState({ currentView });
  }

  displayMapSettings() {
    if(this.map.current) {
      this.map.current.displaySettings();
    }
  }

  closeMapSettings() {
    if(this.map.current) {
      this.map.current.closeSettings();
    }
  }

  // applyView(rows, view) {
  //   const level = view.DefaultLevel;
  //   const status = view.FilterStatus;
  //   const period = view.FilterPeriodType;
  //   let filtered = [];
  //   let startPeriod, endPeriod;

  //   // Determine StartPeriod & EndPeriod
  //   startPeriod = this.getViewStartPeriod(view);
  //   endPeriod = this.getViewEndPeriod(view);

  //   // Filter Level
  //   rows = this.filterLevel(rows, level);

  //   rows.forEach(row => {
  //     let isRowFiltered = true;

  //     // Filter Status
  //     isRowFiltered = this.filterStatus(row, status);

  //     // Filter Period
  //     if(isRowFiltered && period !== 0) {
  //       // If StartDate or EndDate is empty
  //       if((row.Cells.find(cell => cell.ColumnName === "StartDate") && !row.Cells.find(cell => cell.ColumnName === "StartDate").Value) || (row.Cells.find(cell => cell.ColumnName === "EndDate") && !row.Cells.find(cell => cell.ColumnName === "EndDate").Value)) {
  //         isRowFiltered = false;
  //       }
  //       else {
  //         let startDate = new Date(this.formatDate(row.Cells.find(cell => cell.ColumnName === "StartDate").Value));
  //         let endDate = new Date(this.formatDate(row.Cells.find(cell => cell.ColumnName === "EndDate").Value));

  //         // Set the same Hours/Minutes/Seconds/Milliseconds to compare Dates
  //         startPeriod.setHours(0,0,0,0);
  //         endPeriod.setHours(0,0,0,0);
  //         startDate.setHours(0,0,0,0);
  //         endDate.setHours(0,0,0,0);

  //         // If StartDate is after the Period or EndDate is before the Period
  //         if((startPeriod.getTime() > endDate.getTime()) || (startDate.getTime() > endPeriod.getTime())) {
  //           isRowFiltered = false;
  //         }
  //       }
  //     }

  //     // Then push the Filtered Rows inside the Filtered table 
  //     if(isRowFiltered) {
  //       filtered.push(row);

  //       // Add missing Parents of Filtered objects in the Tree
  //       // if(level === 'Tree') {
  //       //   this.addMissingParents(rowSelectedLevel, filtered, row);
  //       // }
  //     }
  //   });

  //   this.setState({ filtered, startPeriod, endPeriod });
  // }

  filterColumn(columnName) {
    if(this.filters.current) {
      this.filters.current.openFiltersPopup(columnName);
    }
  }

  sortColumn(columnName) {
    if(this.sort.current) {
      this.sort.current.openSortPopup(columnName);
    }
  }
 
  async checkModification(itemId, itemType, columnName, oldValue, newValue) {
    const { login, authId, language } = this.state;
    let additionalContext = [];
    
    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': itemType,
        'ItemId': itemId,
        'ColumnName': columnName,
        'OldValue': oldValue,
        'NewValue': newValue,
        'AdditionalContext': additionalContext
      })
    };

    try{
      const response = await fetch(API_valid, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const results = data.GetPropagationsAndSaveModificationResult;
      
      // Check request response
      if(results) {
        // For Each Modification
        results.forEach(result => {
          // Get Modifications, Propagations & Warnings
          let modification = result.Modification;
          let propagations = result.Propagations;
          let warnings = result.Warnings;

          // If the Modification is valid
          if(result.IsValid === true) {
            // Update Rows in Components DataSource
            if(this.table.current) {
              this.table.current.updateDatasource(modification, propagations, warnings);
            }
            else if(this.tree.current) {
              this.tree.current.updateDatasource(modification, propagations, warnings);
            }
            else if(this.planning.current) {
              this.planning.current.updateDatasource(modification, propagations, warnings);
            }
            else if(this.kanban.current) {
              this.kanban.current.updateDatasource(modification, propagations, warnings);
            }
            else if(this.scheduler.current) {
              // this.scheduler.current.updateDatasource(modification, propagations, warnings);
            }

            // Update Rows in Data Table
            this.updateRowsInTable(modification, propagations, warnings);
          }
          // If the Modification is not valid
          else {
            // Update Datasource with OldValue
            if(this.table.current) {
              this.table.current.cancelModification(modification);
            }
            else if(this.tree.current) {
              this.tree.current.cancelModification(modification);
            }
            else if(this.planning.current) {
              this.planning.current.cancelModification(modification);
            }
            else if(this.kanban.current) {
              // this.kanban.current.cancelModification(modification);
            }
            else if(this.scheduler.current) {
              // this.scheduler.current.cancelModification(modification);
            }

            this.updateErrors(result.Errors);
          }
        });
      }
      else {
        this.updateErrors(Traduction.translate(language, 'modification_impossible'));
      }

    } catch(error) {
      console.log(error);
    }
  }

  async shiftParent(parentItemId, parentItemType, newValue) {
    const { login, authId, language, itemId, itemType, blockType, currentView } = this.state;
    let additionalContext = [];
    
    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': parentItemType,
        'ItemId': parentItemId,
        'NewStartDate': newValue,
        'AdditionalContext': additionalContext
      })
    };

    try{
      const response = await fetch(API_shift, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const result = data.ShiftParentResult;
      
      // Check request response
      if(result) {
        // If the Modification is valid
        if(result.IsValid === true) {
          // Get Card Data
          await this.getCardData(login, authId, itemId, itemType, blockType, currentView);
        }
        else {
          // Get Card Data
          await this.getCardData(login, authId, itemId, itemType, blockType, currentView);

          this.updateErrors(result.Errors);
        }
      }
      else {
        this.updateErrors(Traduction.translate(language, 'modification_impossible'));
      }

    } catch(error) {
      console.log(error);
    }
  }

  async createItem(name, itemType, templateId, sameParent) {
    const { login, authId, language, currentView } = this.state;
    let parentType = this.state.itemType;
    let parentId = this.state.itemId;
    let columns = this.getColumnsName();
    let additionalContext = [];
    let selectedRow;

    this.setState({ isLoading: true });

    // Additional Context for Creation with Template
    if(templateId) {
      additionalContext = [{ "Key": "FromId", "Value": templateId }];
    }

    // Get Parent Type & Id for Tree
    if(currentView.DefaultLevel === 0) {
      // Get Selected Row from Treegrid
      if(this.tree.current) {
        selectedRow = this.tree.current.getSelectedRow();
      }
      else if(this.planning.current) {
        selectedRow = this.planning.current.getSelectedRow();
      }

      // One Row is selected
      if(selectedRow && selectedRow.length > 0) {
        parentType = selectedRow[0].Item_Type;
        parentId = selectedRow[0].Item_ID.substring(1);
      }
    }

    // Specific case for Duplicate
    if(templateId && sameParent) {
      parentType = '';
      parentId = 0;
    }

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': itemType,
        'ParentType': parentType,
        'ParentId': parentId,
        'Name': name,
        'NbItems': '1',
        'Fields': columns,
        'AdditionalContext': additionalContext
      })
    };
    
    try{
      const response = await fetch(API_create, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const result = data.InsertItemAndSaveResult;

      // Check request response
      if(result) {
        // Get new Rows & Propagations
        const newRows = result.InsertedItems.Rows;
        const propagations = result.Propagations;

        // If the Modification is Valid
        if(result.IsValid === true) {
          // Add Rows in Components DataSource
          if(this.table.current) {
            this.table.current.addRowsInDatasource(newRows);
          }
          else if(this.tree.current) {
            this.tree.current.addRowsInDatasource(newRows, propagations);
          }
          else if(this.planning.current) {
            this.planning.current.addRowsInDatasource(newRows, propagations);
          }
          else if(this.kanban.current) {
            this.kanban.current.addRowsInDatasource(newRows);
          }
          else if(this.scheduler.current) {
            this.scheduler.current.addRowsInDatasource(newRows);
          }

          // Add Rows in Data Table
          this.addRowsInTable(newRows, propagations);
        }
        else {
          this.updateErrors(result.Errors);
        }
      }
      else {
        this.updateErrors(Traduction.translate(language, 'creation_impossible'));
      }

      // this.setState({ rows, isLoading: false });

      // Reset variables & Close Popup
      this.setState({ addProjectPopup: false, newProjectTemplate: null, templateSelected: {}, isLoading: false });

    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  async deleteItem(itemId, itemType, isForced) {
    const { login, authId, language } = this.state;

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemId': itemId,
        'ItemType': itemType,
        'IsForced': isForced
      })
    };

    try{
      const response = await fetch(API_delete, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const result = data.DeleteItemAndSaveResult;

      // Check request response
      if(result) {
        // Get Delete & Propagations
        const modification = result.Delete;
        const propagations = result.Propagations;
  
        // If the Modification is Valid
        if(result.IsValid === true) {
          // Delete Row in Components DataSource
          if(this.table.current) {
            this.table.current.deleteRowInDatasource(modification);
          }
          else if(this.tree.current) {
            this.tree.current.deleteRowInDatasource(modification, propagations);
          }
          else if(this.planning.current && modification.ItemType !== 'Dependency') {
            this.planning.current.deleteRowInDatasource(modification, propagations);
          }
          else if(this.kanban.current) {
            // this.kanban.current.deleteRowInDatasource(modification);
          }
          else if(this.scheduler.current) {
            this.scheduler.current.deleteRowInDatasource(modification);
          }

          // Delete Row in Data Table
          this.deleteRowInTable(modification, propagations);

          // Delete Dependency in Planning
          // if(this.planning.current && modification.ItemType === 'Dependency') {
          //   this.applyView(this.state.rows, this.state.currentView);
          // }
        }
        else {
          if(result.ErrorCode === 101 || result.ErrorCode === 102) {
            this.setState({ confirm: true, deleteId: itemId, deleteType: itemType, deleteMessage: result.Errors[0] });
          }
          else {
            // Update Errors
            this.updateErrors(result.Errors);
          }
        }
      }
      else {
        this.updateErrors(Traduction.translate(language, 'deletion_impossible'));
      }

    } catch(error) {
      console.log(error);
    }
  }

  confirmDelete() {
    const deleteId = this.state.deleteId;
    const deleteType = this.state.deleteType;

    this.deleteItem(deleteId, deleteType, true);

    this.setState({ confirm: false });
  }

  cancelDelete() {
    this.setState({ confirm: false });
  }

  async cutPasteItem(itemType, itemId, destinationItemType, destinationItemId) {
    const { login, authId, language, blockType, currentView } = this.state;
    const cardItemId = this.state.itemId;
    const cardItemType = this.state.itemType;

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': itemType,
        'ItemId': itemId,
        'DestinationItemType': destinationItemType,
        'DestinationItemId': destinationItemId
      })
    };

    try{
      const response = await fetch(API_cut_paste, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const result = data.MoveItemResult;

      // Check request response
      if(result) {
        // Get Move, Propagations & Warnings
        const move = result.Move;
        const propagations = result.Propagations;
        const warnings = result.Warnings;
  
        // If the Modification is Valid
        if(result.IsValid === true) {
          // const rows = this.state.rows;
          // let updatedRows;

          // Build Updated Rows

          // Create Modification object
          // let modification = { AdditionalContext: [], AuthId: authId, ColumnName: "Parent_ID", ItemId: itemId, ItemType: itemType, NewValue: destinationItemId, OldValue: "", UserId: userId };

          // Update dataSource in Tree
          // if(this.tree.current) {
          //   this.tree.current.addRowsInDatasource(updatedRows, propagations);
          //   this.tree.current.deleteRowInDatasource(modification, propagations);
          // }

          // // Update Rows in Data Table
          // this.updateRowsInTable(modification, propagations, warnings);

          this.getCardData(login, authId, cardItemId, cardItemType, blockType, currentView);
        }
        else {
          // Update Errors
          this.updateErrors(result.Errors);
        }
      }
      else {
        this.updateErrors(Traduction.translate(language, 'deletion_impossible'));
      }

    } catch(error) {
      console.log(error);
    }
  }

  updateRowsInTable(modification, propagations, warnings) {
    let rows = this.state.rows;
    const modificationId = modification.ItemType.substring(0,1).concat(modification.ItemId);

    // Check if we find Row to apply Modification
    const currentRow = rows.find(row => row.Cells.find(cell => cell.ColumnName === 'Item_ID').Value === modificationId);

    if(currentRow) {
      // Check if we find Cell to apply Modification
      const currentCell = currentRow.Cells.find(cell => cell.ColumnName === modification.ColumnName);

      // Update Row Cell with NewValue
      if(currentCell) {
        currentCell.Value = modification.NewValue;
      }

      // Check if we find Warning Message Column
      const currentWarning = currentRow.Cells.find(cell => cell.ColumnName === 'WarningMessage');

      // Update Warnings
      if(currentWarning) {
        if(warnings.length > 0) {
          currentWarning.Value = warnings.join(' | ');
        }
        else {
          currentWarning.Value = '';
        }
      }
    }

    // Apply Propagations in Rows
    this.applyPropagations(rows, propagations);

    this.setState({ rows });
  }

  applyPropagations(rows, propagations) {
    // Update Row with Propagations NewValue
    propagations.forEach(propagation => {
      const propagationId = propagation.ToItemType.substring(0,1).concat(propagation.ToItemId);

      // Check if we find Row to apply Propagation
      const currentRow = rows.find(row => row.Cells.find(cell => cell.ColumnName === 'Item_ID').Value === propagationId);

      // Find Row to apply Propagation and Update with new Value
      if(currentRow) {
        // Check if we find Cell to apply Modification
        const currentCell = currentRow.Cells.find(cell => cell.ColumnName === propagation.ColumnName);

        // Update Row Cell with Propagation
        if(currentCell) {
          currentCell.Value = propagation.NewValue;
        }
      }
    });
  }

  addRowsInTable(newRows, propagations) {
    let rows = this.state.rows;

    // Push new Rows
    newRows.forEach(row => {
      rows.push(row);
    });

    // Apply Propagations in Rows
    this.applyPropagations(rows, propagations);

    // Refresh Buttons Level Indicators
    if(this.levels.current) {
      this.levels.current.refreshIndicators(rows);
    }

    this.setState({ rows });
  }

  // deleteRowInTable(modification, propagations) {
  //   const blockType = this.state.blockType;
  //   let rows = this.state.rows;
  //   const deleteId = modification.ItemType.substring(0,1).concat(modification.ItemId);

  //   // Check if we find Row to Delete
  //   const indexToDelete = rows.findIndex(row => row.Cells.find(cell => cell.ColumnName === 'Item_ID').Value === deleteId);

  //   // Delete Row
  //   if(indexToDelete >= 0) {
  //     rows.splice(indexToDelete, 1);
  //   }

  //   // Apply Propagations in Rows
  //   this.applyPropagations(rows, propagations);

  //   // Refresh Buttons Level Indicators
  //   if(this.levels.current) {
  //     this.levels.current.refreshIndicators(rows);
  //   }

  //   // Specific case for Dependency: Update Rows with Propagations then Refresh Planning Datasource
  //   if(this.planning.current && modification.ItemType === 'Dependency') {
  //     this.planning.current.refreshDatasource(blockType, rows);
  //   }

  //   this.setState({ rows });
  // }

  deleteRowInTable(modification, propagations) {
    const blockType = this.state.blockType;
    let rows = this.state.rows;
    const deleteId = modification.ItemType.substring(0,1).concat(modification.ItemId);
    let idToDelete = [];

    // Get list of Index to delete
    idToDelete = this.getChildToDelete(rows, deleteId);

    idToDelete.forEach(id => {
      // Get corresponding Row Index
      let rowIndex = rows.findIndex(row => row.Cells.find(cell => cell.ColumnName === 'Item_ID').Value === id);

      // Delete Row in the Datasource
      if(rowIndex >= 0) {
        rows.splice(rowIndex, 1);
      }
    });

    // Apply Propagations in Rows
    this.applyPropagations(rows, propagations);

    // Refresh Buttons Level Indicators
    if(this.levels.current) {
      this.levels.current.refreshIndicators(rows);
    }

    // Specific case for Dependency: Update Rows with Propagations then Refresh Planning Datasource
    if(this.planning.current && modification.ItemType === 'Dependency') {
      this.planning.current.refreshDatasource(blockType, rows);
    }

    this.setState({ rows });
  }

  getChildToDelete(rows, deleteId) {
    let idToDelete = [];
    let itemId;

    // Push Index of element to delete
    idToDelete.push(deleteId);

    // Find child of the element
    rows.filter(row => row.Cells.find(cell => cell.ColumnName === 'Parent_ID') && row.Cells.find(cell => cell.ColumnName === 'Parent_ID').Value === deleteId).forEach(row => {
      if(row.Cells.find(cell => cell.ColumnName === 'Item_ID') && row.Cells.find(cell => cell.ColumnName === 'Item_Type')) {
        // Get Row ItemId
        itemId = row.Cells.find(cell => cell.ColumnName === 'Item_ID').Value;

        // Call recursive function to get child ItemId
        idToDelete.push(this.getChildToDelete(rows, itemId));
      }
    });

    return idToDelete.flat();
  }

  exportCSV() {
    // Export Data from Table component
    if(this.table.current) {
      this.table.current.exportCSV();
    }
    // Export Data from Tree component
    if(this.tree.current) {
      this.tree.current.exportCSV();
    }
    // Export Data from Pivot component
    if(this.pivot.current) {
      this.pivot.current.exportCSV();
    }
    // Export Data from Chart component
    if(this.chart.current) {
      this.chart.current.exportCSV();
    }
  }

  exportXLSX() {
    // Export Data from Table component
    if(this.table.current) {
      this.table.current.exportXLSX();
    }
    // Export Data from Tree component
    if(this.tree.current) {
      this.tree.current.exportXLSX();
    }
    // Export Data from Pivot component
    if(this.pivot.current) {
      this.pivot.current.exportXLSX();
    }
    // Export Data from Chart component
    if(this.chart.current) {
      this.chart.current.exportXLSX();
    }
  }

  exportJPEG() {
    // Export Data from Map component
    if(this.map.current) {
      this.map.current.exportJPEG();
    }
  }

  exportPNG() {
    // Export Data from Map component
    if(this.map.current) {
      this.map.current.exportPNG();
    }
  }

  exportPDF() {
    // Export Data from Map component
    if(this.map.current) {
      this.map.current.exportPDF();
    }
  }

  searchItem(event) {
    const search = event.target.value;

    // Search Items in Table component
    if(this.table.current) {
      this.table.current.searchItem(search);
    }
    // Search Items in Tree component
    if(this.tree.current) {
      this.tree.current.searchItem(search);
    }
    // Search Items in Gantt component
    if(this.planning.current) {
      this.planning.current.searchItem(search);
    }
    // Search Items in Kanban component
    if(this.kanban.current) {
      this.kanban.current.searchItem(search);
    }
    // Search Items in Scheduler component
    if(this.scheduler.current) {
      this.scheduler.current.searchItem(search);
    }

    this.setState({ search });
  }

  updateView(view) {
    this.setState({ currentView: view });
  }

  // updateTable(rows) {
  //   // Refresh Buttons Level Indicators
  //   if(this.levels.current) {
  //     this.levels.current.refreshIndicators(rows);
  //   }    
  // }

  updateErrors(err) {
    let errors = [];

    // Push the new Errors in the Errors Table
    errors.push(err);

    this.setState({ errors });
  }

  cleanErrors() {
    this.setState({ errors: [] });
  }

  templateAddBLPopup() {
    const { language, newBLTemplate, availableBLTemplates, templateSelected, isLoading } = this.state;
    const itemType = 'Business_Line';

    return (<div className="popupAddProject">
      {/* Label */}
      {!newBLTemplate && <div className="addProjectLabel mb10">{Traduction.translate(language, 'create_new_business_line')}</div>}
      {/* Available Slides */}
      {!newBLTemplate && <div className="newProjectTemplates">
        <div className="newProjectEmptyTemplate" onClick={() => { this.createItem('', itemType); this.closeAddPopup() }}>
          <div className="addProjectLabel mv10">{Traduction.translate(language, 'empty_business_line')}</div>
        </div>
        <div className="newProjectTemplate" onClick={() => { this.setState({ newBLTemplate: 'template' }); this.getAvailableTemplates(itemType) }}>
          <span className="iconNewProjetTemplate veryBigIcons" alt="Template"/>
          <div className="addProjectLabel mv10">{Traduction.translate(language, 'from_template')}</div>
        </div>
      </div>}
      {newBLTemplate === 'template' && <div className="flex flex-column popupProjectTemplate">
        {/* Label */}
        <div className="addProjectLabel mb10">{Traduction.translate(language, 'choose_business_line_template')}</div>
        {/* Available Templates */}
        <div className="flex flex-column scrollbar-y overflow-y mb10">
          {availableBLTemplates.map((template, index) => {
            return (<div key={index} className={(template.ItemID === templateSelected.ItemID ? "projectTemplateSelected" : "projectTemplate")} onClick={() => this.setState({ templateSelected: template })}>
              <div className="templateIconBlue"><span className="iconBusinessLineWhite verysmallIcons" alt="Business Line"></span></div>
              <div className="templateLabel">{template.Name}</div>
            </div>)
          })}
        </div>
      </div>}
      {/* Button Validate or Cancel */}
      <div className="flex flex-end align-items-center">
        {/* Spinner Loading */}
        {isLoading && <div className="mr20"><LoadingSpinner></LoadingSpinner></div>}
        {/* Buttons */}
        {!isLoading && newBLTemplate === 'template' && Object.keys(templateSelected).length !== 0 && 
          <Button className="fs12 bold brd-radius mr15" variant="primary" onClick={() => { this.createItem('', itemType, templateSelected.ItemID); this.closeAddPopup() }}>{Traduction.translate(language, 'validate')}</Button>
        }
        <Button className="fs12 bold brd-radius" variant="warning" onClick={() => this.closeAddPopup()}>{Traduction.translate(language, 'cancel')}</Button>
      </div>
    </div>);
  }

  templateAddProjectPopup() {
    const { language, newProjectTemplate, availableProjectTemplates, templateSelected, isLoading } = this.state;
    const itemType = 'Project';

    return (<div className="popupAddProject">
      {/* Label */}
      {!newProjectTemplate && <div className="addProjectLabel mb10">{Traduction.translate(language, 'create_new_project')}</div>}
      {/* Available Slides */}
      {!newProjectTemplate && <div className="newProjectTemplates">
        <div className="newProjectEmptyTemplate" onClick={() => { this.createItem('', itemType); this.closeAddPopup() }}>
          <div className="addProjectLabel mv10">{Traduction.translate(language, 'empty_project')}</div>
        </div>
        <div className="newProjectTemplate" onClick={() => { this.setState({ newProjectTemplate: 'template' }); this.getAvailableTemplates(itemType) }}>
          <span className="iconNewProjetTemplate veryBigIcons" alt="Template"/>
          <div className="addProjectLabel mv10">{Traduction.translate(language, 'from_template')}</div>
        </div>
      </div>}
      {newProjectTemplate === 'template' && <div className="flex flex-column popupProjectTemplate">
        {/* Label */}
        <div className="addProjectLabel mb10">{Traduction.translate(language, 'choose_project_template')}</div>
        {/* Available Templates */}
        <div className="flex flex-column scrollbar-y overflow-y mb10">
          {availableProjectTemplates.map((template, index) => {
            return (<div key={index} className={(template.ItemID === templateSelected.ItemID ? "projectTemplateSelected" : "projectTemplate")} onClick={() => this.setState({ templateSelected: template })}>
              <div className="templateIconBlue"><span className="iconProjectWhite verysmallIcons" alt="Project"></span></div>
              <div className="templateLabel">{template.Name}</div>
            </div>)
          })}
        </div>
      </div>}
      {/* Button Validate or Cancel */}
      <div className="flex flex-end align-items-center">
        {/* Spinner Loading */}
        {isLoading && <div className="mr20"><LoadingSpinner></LoadingSpinner></div>}
        {/* Buttons */}
        {!isLoading && newProjectTemplate === 'template' && Object.keys(templateSelected).length !== 0 && 
          <Button className="fs12 bold brd-radius mr15" variant="primary" onClick={() => { this.createItem('', itemType, templateSelected.ItemID); this.closeAddPopup() }}>{Traduction.translate(language, 'validate')}</Button>
        }
        <Button className="fs12 bold brd-radius" variant="warning" onClick={() => this.closeAddPopup()}>{Traduction.translate(language, 'cancel')}</Button>
      </div>
    </div>);
  }

  closeAddPopup() {
    this.setState({ addBLPopup: false, addProjectPopup: false, newBLTemplate: null, newProjectTemplate: null, templateSelected: {} });
  }

  async closeDependenciesPopup(modified) {
    const login = this.state.login;
    const authId = this.state.authId;
    const itemId = this.state.itemId;
    const itemType = this.state.itemType;
    const blockType = this.state.blockType;
    const currentView = this.state.currentView;

    this.setState({ dependenciesPopup: false });

    if(modified) {
      // Get Card Data
      await this.getCardData(login, authId, itemId, itemType, blockType, currentView);
    }
  }

  render() {
    let { language, itemId, itemType, itemTitle, blockType, blockInfo, blockContent, editable, guestLicence, favorite, parents, warnings, levels, displayViews, views, defaultViewId, currentView, headers, columns, rows, chart, map, addLevel, addBLPopup, addProjectPopup, confirm, deleteMessage, dependenciesPopup, rowInformation, search, isLoading, errors } = this.state;
    let childType, labelCreate, emptyBlock;

    // Conditions on Add Button (0: add disabled, 1: popup BL, 2: popup Project, 3: Workpackage, 4: Action, 5: Task)
    if(itemType === 'Global_Business_Line' && currentView.DefaultLevel === 0) {
      addLevel = 1;
    }
    else if(currentView.DefaultLevel === 0) {
      if(itemType === 'Business_Line') {
        addLevel = 2;
      }
      else if(itemType === 'Project') {
        addLevel = 3;
      }
      else if(itemType === 'Workpackage') {
        addLevel = 4;
      }
      else if(itemType === 'Action') {
        addLevel = 5;
      }
      else if(itemType === 'AgileBoard') {
        addLevel = 24;
      }
    }
    else if(currentView.DefaultLevel === 1) {
      addLevel = 1;
    }
    else if(itemType === 'Business_Line' && currentView.DefaultLevel === 2) {
      addLevel = 2;
    }
    else if(itemType === 'Project' && currentView.DefaultLevel === 3) {
      addLevel = 3;
    }
    else if((itemType === 'Project' || itemType === 'Workpackage') && currentView.DefaultLevel === 4) {
      addLevel = 4;
    }
    else if (itemType === 'Action' && currentView.DefaultLevel === 5) {
      addLevel = 5;
    }
    else if(itemType === 'AgileBoard' && currentView.DefaultLevel === 24) {
      addLevel = 24;
    }

    // Determine Item Type's Child
    if(itemType === 'Business_Line') {
      childType = 'Project';
      labelCreate = Traduction.translate(language, 'create_project');
    }
    else if(itemType === 'Project') {
      childType = 'Workpackage';
      labelCreate = Traduction.translate(language, 'create_workpackage');
    }
    else if(itemType === 'Workpackage') {
      childType = 'Action';
      labelCreate = Traduction.translate(language, 'create_action');
    }
    else if(itemType === 'Action') {
      childType = 'Task';
      labelCreate = Traduction.translate(language, 'create_task');
    }
    else if(itemType === 'AgileBoard') {
      childType = 'Sprint';
      labelCreate = Traduction.translate(language, 'create_sprint');
    }

    // Empty Block
    if(headers.length > 0 && headers.find(header => header.FieldName === 'EmptyBlock')) {
      emptyBlock = headers.find(header => header.FieldName === 'EmptyBlock').FieldValue;
    }

    return (
      <div className="blockContainer">
        {/* Title */}
        <MetaTags><title>{itemTitle} • {Traduction.translate(language, 'roadmap')}</title></MetaTags>

        {/* Navbar */}
        <Navbar Selected={this.props.match.url}></Navbar>

        {/* HasRightOnItem = false */}
        {blockInfo.HasRightOnItem === false && <div className="block">
          <div className="blockHeader">
            <div className="blockTitle">
              <div className="cardIcon"><div className="cardIconGrey"><span className="iconNoRights iconsCard"></span></div></div>
              <div className="flex"><span className="cardTitle">{Traduction.translate(language, 'no_sufficient_rights')}</span></div>
            </div>
          </div>
        </div>}
        
        {/* Block */}
        {blockInfo.HasRightOnItem === true && <div className="block">
          {/* Card Block Header */}
          <div className="blockHeader">
            {/* Title & Parents */}
            <BlockTitle ItemId={itemId} ItemType={itemType} ItemTitle={itemTitle} BlockType={blockType} CurrentView={currentView} Blocks={blockInfo.BlockNames} Favorite={favorite} Parents={parents} Warnings={warnings} onDataUpdate={this.getCardData}></BlockTitle>
            {/* Border */}
            <div className="blockBorder"></div>
          </div>

          {/* Card Block Body */}
          <div className="blockBody">
            {/* Filters Views */}
            {displayViews && <FiltersView ref={this.views} ItemId={itemId} ItemType={itemType} BlockType={blockType} GuestLicence={guestLicence} DefaultViewId={defaultViewId} CurrentView={currentView} Views={views} onViewsHide={this.hideViews} onViewChange={this.changeView} onSetDefaultView={this.setDefaultView} onErrorsUpdate={this.updateErrors}></FiltersView>}

            {/* Card Block Content */}
            <div className={!displayViews ? "blockContent" : "blockContentViews"}>
              {/* Filters */}
              <div className="blockFilters">
                {/* Current View */}
                {!displayViews && <FiltersCurrentView ItemId={itemId} ItemType={itemType} BlockType={blockType} CurrentView={currentView} onDisplayViews={this.displayViews} onErrorsUpdate={this.updateErrors}></FiltersCurrentView>}

                {/* Add Buttons */}
                {addLevel === 1 && editable && !guestLicence && currentView.ViewType !== 3 && currentView.ViewType !== 7 && currentView.ViewType !== 8 && 
                  <span className="iconButtonPlus mediumIcons mh10 cursor" onClick={() => this.setState({ addBLPopup: true })}/>
                }
                {addLevel === 2 && editable && !guestLicence && currentView.ViewType !== 3 && currentView.ViewType !== 7 && currentView.ViewType !== 8 && 
                  <span className="iconButtonPlus mediumIcons mh10 cursor" onClick={() => this.setState({ addProjectPopup: true })}/>
                }
                {addLevel === 3 && editable && !guestLicence && currentView.ViewType !== 3 && currentView.ViewType !== 7 && currentView.ViewType !== 8 && 
                  <span className="iconButtonPlus mediumIcons mh10 cursor" onClick={(e) => this.createItem('', 'Workpackage')}/>
                }
                {addLevel === 4 && editable && !guestLicence && currentView.ViewType !== 3 && currentView.ViewType !== 7 && currentView.ViewType !== 8 && 
                  <span className="iconButtonPlus mediumIcons mh10 cursor" onClick={(e) => this.createItem('', 'Action')}/>
                }
                {addLevel === 5 && editable && !guestLicence && currentView.ViewType !== 3 && currentView.ViewType !== 7 && currentView.ViewType !== 8 && 
                  <span className="iconButtonPlus mediumIcons mh10 cursor" onClick={(e) => this.createItem('', 'Task')}/>
                }
                {addLevel === 24 && editable && !guestLicence && currentView.ViewType !== 3 && currentView.ViewType !== 7 && currentView.ViewType !== 8 && 
                  <span className="iconButtonPlus mediumIcons mh10 cursor" onClick={(e) => this.createItem('', 'Sprint')}/>
                }

                {/* Level Buttons */}
                {levels && levels.length > 1 && 
                  <FiltersLevel ref={this.levels} ItemId={itemId} ItemType={itemType} BlockType={blockType} CurrentView={currentView} Views={views} Levels={levels} Rows={rows} onLevelChange={this.changeLevel}></FiltersLevel>
                }

                {/* Block Filters */}
                <FiltersBlock ref={this.filters} ItemId={itemId} ItemType={itemType} BlockType={blockType} CurrentView={currentView} onFiltersChange={this.changeFilters}></FiltersBlock>

                {/* Sort Filters */}
                <FiltersSort ref={this.sort} ItemId={itemId} ItemType={itemType} BlockType={blockType} CurrentView={currentView} onSortChange={this.changeSort}></FiltersSort>

                {/* Conditional Formatting Filters */}
                {currentView.ViewType !== 3 && 
                  <FiltersConditionalFormatting ref={this.formattings} ItemId={itemId} ItemType={itemType} BlockType={blockType} CurrentView={currentView} onFormattingsChange={this.changeFormattings}></FiltersConditionalFormatting>
                }

                {/* Planning Settings */}
                {currentView.ViewType === 1 && !guestLicence && 
                  <FiltersPlanning ItemId={itemId} ItemType={itemType} BlockType={blockType} CurrentView={currentView} AvailableAxes={blockContent.AvailableAxes} onViewChange={this.updateView} onSettingsChange={this.applyPlanningSettings}></FiltersPlanning>
                }

                {/* Kanban Filters */}
                {currentView.ViewType === 2 && !guestLicence && 
                  <FiltersKanban BlockType={blockType} CurrentView={currentView} Axes={blockContent.AvailableAxes} onAxeChange={this.changeAxe} onKeyFieldChange={this.changeKeyField}></FiltersKanban>
                }

                {/* Chart Settings */}
                {currentView.ViewType === 7 && !guestLicence && 
                  <FiltersChart onChartSettingsDisplay={this.displayChartSettings} onChartSettingsClose={this.closeChartSettings}></FiltersChart>
                }

                {/* Map Settings */}
                {currentView.ViewType === 8 && !guestLicence && 
                  <FiltersMap onMapSettingsDisplay={this.displayMapSettings} onMapSettingsClose={this.closeMapSettings}></FiltersMap>
                }

                {/* Columns Chooser */}
                {(currentView.ViewType === 0 || currentView.ViewType === 1 || currentView.ViewType === 2) && 
                  <FiltersColumnChooser ItemId={itemId} ItemType={itemType} BlockType={blockType} CurrentView={currentView} onColumnsChange={this.changeColumns}></FiltersColumnChooser>
                }

                {/* Search input */}
                {(currentView.ViewType !== 1 && currentView.ViewType !== 3 && currentView.ViewType !== 7 && currentView.ViewType !== 8) && 
                  <div className="filtersSearch">
                    <Form.Group className="searchBlock">
                      <Form.Control type="text" id="searchFilters" name="search" value={search} placeholder={Traduction.translate(language, 'search')} onChange={this.searchItem} />
                    </Form.Group>
                  </div>
                }

                {/* Import */}
                {currentView.ViewType === 0 && !guestLicence && 
                  <FiltersImport ItemId={itemId} ItemType={itemType} BlockType={blockType} CurrentView={currentView} onDataImport={this.getCardData} onXLSXExport={this.exportXLSX}></FiltersImport>
                }

                {/* Export */}
                {(currentView.ViewType === 0 || currentView.ViewType === 3 || currentView.ViewType === 7 || currentView.ViewType === 8) && 
                  <FiltersExport ItemId={itemId} ItemType={itemType} BlockType={blockType} GuestLicence={guestLicence} CurrentView={currentView} onCSVExport={this.exportCSV} onXLSXExport={this.exportXLSX} onJPEGExport={this.exportJPEG} onPNGExport={this.exportPNG} onPDFExport={this.exportPDF} onErrorsUpdate={this.updateErrors}></FiltersExport>
                }
              </div>

              {/* Card Block Component */}
              <div className="blockComponent">
                {/* Errors */}
                {errors.length > 0 && 
                  <ErrorModification Errors={errors} Open={true} onErrorsClean={this.cleanErrors}></ErrorModification>
                }

                {/* Loading Spinner */}
                {isLoading && <div className="center mt30 mb20">
                  <span className=""><LoadingSpinner></LoadingSpinner></span>
                  <span className="bold ml30">{Traduction.translate(language, 'data_loading')}</span>
                </div>}

                {/* Add Business Line Popup */}
                {addBLPopup === true && <div className="addProjectPopup">
                  <div className="addProjectInnerPopup">{this.templateAddBLPopup()}</div>
                </div>}

                {/* Add Project Popup */}
                {addProjectPopup === true && <div className="addProjectPopup">
                  <div className="addProjectInnerPopup">{this.templateAddProjectPopup()}</div>
                </div>}

                {/* Delete Confirmation Popup */}
                {confirm && <PopupConfirmation Message={deleteMessage} onMessageConfirm={this.confirmDelete} onMessageCancel={this.cancelDelete}></PopupConfirmation>}

                {/* Dependencies Popup */}
                {dependenciesPopup === true && rowInformation && 
                  <PopupEditDependencies ItemId={rowInformation.Item_ID.substring(1)} ItemType={rowInformation.Item_Type} ItemTitle={rowInformation.Name} Editable={editable} GuestLicence={guestLicence} onPopupClose={this.closeDependenciesPopup}></PopupEditDependencies>
                }

                {/* View Components */}
                {currentView.ViewType === 0 && currentView.DefaultLevel === 0 && rows.length > 0 && <Tree ref={this.tree} ItemId={itemId} ItemType={itemType} Title={itemTitle} BlockType={blockType} Editable={editable} GuestLicence={guestLicence} CurrentView={currentView} Columns={columns} Rows={rows} onViewChange={this.updateView} onColumnsFilter={this.filterColumn} onColumnsSort={this.sortColumn} onColumnSort={this.changeSort} onDependenciesDisplay={this.displayDependenciesPopup} onItemCreate={this.createItem} onItemDelete={this.deleteItem} onItemCutPaste={this.cutPasteItem} onTableUpdate={this.checkModification}></Tree>}
                {currentView.ViewType === 0 && currentView.DefaultLevel !== 0 && rows.length > 0 && <Table ref={this.table} ItemId={itemId} ItemType={itemType} Title={itemTitle} BlockType={blockType} Editable={editable} GuestLicence={guestLicence} CurrentView={currentView} Columns={columns} Rows={rows} onViewChange={this.updateView} onColumnsFilter={this.filterColumn} onColumnsSort={this.sortColumn} onColumnSort={this.changeSort} onDependenciesDisplay={this.displayDependenciesPopup} onItemCreate={this.createItem} onItemDelete={this.deleteItem} onTableUpdate={this.checkModification}></Table>}
                {currentView.ViewType === 1 && rows.length > 0 && <Planning ref={this.planning} ItemId={itemId} ItemType={itemType} BlockType={blockType} Editable={editable} GuestLicence={guestLicence} CurrentView={currentView} Columns={columns} Rows={rows} onViewChange={this.updateView} onColumnsFilter={this.filterColumn} onColumnsSort={this.sortColumn} onColumnSort={this.changeSort} onDependenciesDisplay={this.displayDependenciesPopup} onItemCreate={this.createItem} onItemDelete={this.deleteItem} onTableUpdate={this.checkModification} onParentShift={this.shiftParent}></Planning>}
                {currentView.ViewType === 2 && rows.length > 0 && <Kanban ref={this.kanban} ItemId={itemId} ItemType={itemType} BlockType={blockType} Editable={editable} GuestLicence={guestLicence} CurrentView={currentView} Kanban={blockContent.Kanban} Columns={columns} Rows={rows} Axes={blockContent.AvailableAxes} onTableUpdate={this.checkModification}></Kanban>}
                {currentView.ViewType === 3 && rows.length > 0 && <Pivot ref={this.pivot} ItemId={itemId} ItemType={itemType} Title={itemTitle} BlockType={blockType} Editable={editable} GuestLicence={guestLicence} CurrentView={currentView} Columns={columns} Rows={rows} onViewChange={this.updateView}></Pivot>}
                {currentView.ViewType === 6 && rows.length > 0 && <RoadmapScheduler ref={this.scheduler} ItemId={itemId} ItemType={itemType} BlockType={blockType} Editable={editable} GuestLicence={guestLicence} CurrentView={currentView} Columns={columns} Rows={rows} onViewChange={this.updateView} onItemCreate={this.createItem} onItemDelete={this.deleteItem} onTableUpdate={this.checkModification} onSchedulerChange={this.updateTable}></RoadmapScheduler>}
                {currentView.ViewType === 7 && <Chart ref={this.chart} ItemId={itemId} ItemType={itemType} Title={itemTitle} BlockType={blockType} GuestLicence={guestLicence} CurrentView={currentView} Chart={chart} IsLoading={isLoading} onViewChange={this.updateView} onSettingsChange={this.applyChartSettings}></Chart>}
                {currentView.ViewType === 8 && <Map ref={this.map} ItemId={itemId} ItemType={itemType} Title={itemTitle} BlockType={blockType} GuestLicence={guestLicence} CurrentView={currentView} Map={map} IsLoading={isLoading} onViewChange={this.updateView} onSettingsChange={this.applyMapSettings}></Map>}

                {/* Empty Block */}
                {/* {!isLoading && (currentView.ViewType !== 3 && currentView.ViewType !== 7) && (rows && rows.length === 0) &&  */}
                  {/* <div className="line-height25"> */}
                    {/* Empty Message */}
                    {/* <div className="" dangerouslySetInnerHTML={{ __html: emptyBlock }}></div> */}
                    {/* Create Button */}
                    {/* {!isLoading && itemType !== 'Entity' && itemType !== 'Resource' && currentView.ViewType !== 3 && currentView.ViewType !== 7 && (addLevel === 1 || addLevel === 2 || addLevel === 3 || addLevel === 4 || addLevel === 5 || addLevel === 24) && editable && !guestLicence && 
                      <Button className="filterButton" variant="primary" onClick={(e) => this.createItem('', childType)}>{labelCreate}</Button>
                    } */}
                  {/* </div> */}
                {/* } */}
              </div>
            </div>
          </div>
        </div>}
      </div>
    )
  }
}

export default BlockRoadMap;