import React, {Component} from 'react';
import { Button, Form } from 'react-bootstrap';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';

class PopoverEditMapLayer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: null,
      itemId: null,
      itemType: null,
      mapLayer: null,
      availableMapLayers: [],
      search: ''
    };

    this.searchItem = this.searchItem.bind(this);
    this.pressKey = this.pressKey.bind(this);
    this.updateMapLayer = this.updateMapLayer.bind(this);

    this.search = React.createRef();
  }

  componentDidMount() {
    const language = Authentication.getCookie('language');
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const mapLayer = this.props.MapLayer;
    const availableMapLayers = this.props.AvailableMapLayers;

    // Search Auto focus
    setTimeout(() => { this.search.current.focus() }, 1);

    this.setState({ language, itemId, itemType, mapLayer, availableMapLayers });
  }

  componentDidUpdate(prevProps) {
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const mapLayer = this.props.MapLayer;
    const availableMapLayers = this.props.AvailableMapLayers;
  }

  searchItem(event) {
    const search = event.target.value;

    this.setState({ search });
  }

  pressKey(event) {
    const availableMapLayers = this.state.availableMapLayers;
    const search = event.target.value;
    let mapLayer;

    if(event.key === 'Enter' && search !== '') {
      if(availableMapLayers.find(value => value.Label.toLowerCase().includes(search.toLowerCase()))) {
        mapLayer = availableMapLayers.find(value => value.Label.toLowerCase().includes(search.toLowerCase()));

        // Close Popover
        if(document.getElementById("popover-basic")) {
          document.getElementById("popover-basic").classList.remove("show");
        }

        // Call the event from the Parent component through the props
        this.props.onMapLayerEdit(mapLayer);

        this.setState({ mapLayer });
      }
    }
  }

  updateMapLayer(mapLayer) {
    // Close Popover
    if(document.getElementById("popover-basic")) {
      document.getElementById("popover-basic").classList.remove("show");
    }

    // Call the event from the Parent component through the props
    this.props.onMapLayerEdit(mapLayer);
  }

  render() {
    const { language, itemId, itemType, mapLayer, availableMapLayers, search } = this.state;

    return (
      <div className="chartTypeEditPopover">
        {/* Search */}
        <div className="chartTypeEditSearch">
          <Form.Group className="searchBlock">
            <Form.Control type="text" id="searchFilters" name="search" value={search} placeholder={Traduction.translate(language, 'search')} autoFocus onChange={this.searchItem} onKeyPress={this.pressKey} ref={this.search}/>
          </Form.Group>
        </div>
        {/* Map Layer */}
        <div className="chartTypeEditValues scrollbar-y">
          {/* Current Value */}
          {mapLayer && <div className="chartTypeCurrentValue">
            <span className="">{mapLayer.Label}</span>
          </div>}
          {/* Border */}
          <div className="popoverBorder"></div>
          {/* Other Values */}
          {availableMapLayers.map((type, index) => {
            if(type.LayerId !== mapLayer.LayerId) {
              if(search === "" || type.Label.toLowerCase().includes(search.toLowerCase())) {
                return <div key={index} className="chartTypeEditValue" onClick={() => this.updateMapLayer(type)}>
                  <span className="">{type.Label}</span>
                </div>;
              }
            }
          })}
        </div>
      </div>
    );
  }
}

export default PopoverEditMapLayer;