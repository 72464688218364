import React, {Component} from 'react';
import { Button, Form, OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';
import ErrorMessage from './ErrorMessage';
import LoadingSpinner from './LoadingSpinner';
import PopoverEditFilter from './PopoverEditFilter';
import PopoverEditColorFormatting from './PopoverEditColorFormatting';

const API = '/WebAppService/GetCardFormatings';

class FiltersConditionalFormatting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: null,
      authId: null,
      language: null,
      itemId: null,
      itemType: null,
      blockType: null,
      currentView: {},
      availableFormatings: [],
      currentFormatings: [],
      columns: [],
      columnName: null,
      search: [],
      formattingsPopup: false,
      isLoading: false,
      errors: []
    };
    
    // Data Structure
    this.getAvailableFormattings = this.getAvailableFormattings.bind(this);
    this.getCurrentViewColumns = this.getCurrentViewColumns.bind(this);
    this.getOperatorIcon = this.getOperatorIcon.bind(this);

    // Actions
    this.addConditionalFormatting = this.addConditionalFormatting.bind(this);
    this.deleteConditionalFormatting = this.deleteConditionalFormatting.bind(this);
    this.updateConditionalFormatting = this.updateConditionalFormatting.bind(this);
    this.updateOperator = this.updateOperator.bind(this);
    this.updateValue1 = this.updateValue1.bind(this);
    this.updateValue2 = this.updateValue2.bind(this);
    this.updateBackColor = this.updateBackColor.bind(this);
    this.updateForeColor = this.updateForeColor.bind(this);
    this.saveConditionalFormatting = this.saveConditionalFormatting.bind(this);
    this.openFormattingPopup = this.openFormattingPopup.bind(this);

    // Template
    this.templateFormattingPopup = this.templateFormattingPopup.bind(this);
    this.templateConditionalFormatting = this.templateConditionalFormatting.bind(this);
    this.closeFormattingPopup = this.closeFormattingPopup.bind(this);
  }

  componentDidMount() {
    const login = Authentication.getCookie('login');
    const authId = Authentication.getCookie('authId');
    const language = Authentication.getCookie('language');
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const blockType = this.props.BlockType;
    const currentView = this.props.CurrentView;
    
    this.setState({ login, authId, language, itemId, itemType, blockType, currentView });
  }

  componentDidUpdate(prevProps) {
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const blockType = this.props.BlockType;
    const currentView = this.props.CurrentView;

    if(itemId !== prevProps.ItemId || itemType !== prevProps.ItemType || blockType !== prevProps.BlockType) {
      this.setState({ itemId, itemType, blockType });
    }
    if(JSON.stringify(this.props.CurrentView) !== JSON.stringify(prevProps.CurrentView)) {
      this.setState({ currentView });
    }
  }

  // Get Available Formattings from the API
  async getAvailableFormattings(login, authId, itemId, itemType, blockType, view) {
    const language = this.state.language;
    
    this.setState({ isLoading: true });
    
    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': itemType,
        'ItemId': itemId,
        'BlockType': blockType,
        'View': view,
        'WithData': false,
        'WithInformation': true,
        'InactiveData': false
      })
    };

    try{
      const response = await fetch(API, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const result = data.GetCardFormatingsResult;

      if(result) {
        const availableFormatings = result.AvailableFormatings;
        const currentFormatings = result.CurrentFormatings;
        let search = [];

        // currentFormatings.forEach(filter => {
        //   search.push({ Name: filter.FieldObject.Name, Value: "" });
        // });

        this.setState({ availableFormatings, currentFormatings, search, isLoading: false });
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    } catch(error) {
      this.setState({ error });
    }
  }

  getCurrentViewColumns(currentView) {
    let columns = [];
    
    // Current View Table/Tree/Planning
    if(currentView.ViewType === 0 || currentView.ViewType === 1) {
      if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Columns')) {
        columns = (currentView.Parameters.find(param => param.Name === 'Columns').Value).split(',');
      }
    }
    // Current View Kanban
    else if(currentView.ViewType === 2) {
      if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Axe1') && currentView.Parameters.find(param => param.Name === 'Axe1').Value !== 'None') {
        columns.push(currentView.Parameters.find(param => param.Name === 'Axe1').Value);
      }
      if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Axe2') && currentView.Parameters.find(param => param.Name === 'Axe2').Value !== 'None') {
        columns.push(currentView.Parameters.find(param => param.Name === 'Axe2').Value);
      }
      if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Columns')) {
        currentView.Parameters.find(param => param.Name === 'Columns').Value.split(',').forEach(column => {
          columns.push(column);
        });
      }
    }
    // Current View Pivot/Chart/Map
    else if(currentView.ViewType === 3 || currentView.ViewType === 7 || currentView.ViewType === 8) {
      if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Columns')) {
        currentView.Parameters.find(param => param.Name === 'Columns').Value.split(',').forEach(column => {
          columns.push(column);
        });
        // columns.push(currentView.Parameters.find(param => param.Name === 'Columns').Value);
      }
      if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Rows')) {
        currentView.Parameters.find(param => param.Name === 'Rows').Value.split(',').forEach(row => {
          columns.push(row);
        });
        // columns.push(currentView.Parameters.find(param => param.Name === 'Rows').Value);
      }
      if(currentView.Values && currentView.Values.length > 0) {
        currentView.Values.forEach(value => {
          columns.push(value.Field);
        });
      }
    }

    return columns;
  }

  getOperatorIcon(formatting, operator) {
    switch(operator.Operator) {
      case 0:
          return <span className={"iconFilterOperatorEqual iconsFilter" + (formatting.ConditionOperator.Operator === operator.Operator ? "" : " opacity50")}></span>;
      case 1:
          return <span className={"iconFilterOperatorNotEqual iconsFilter" + (formatting.ConditionOperator.Operator === operator.Operator ? "" : " opacity50")}></span>;
      case 2:
          return <span className={"iconFilterOperatorStartWith iconsFilter" + (formatting.ConditionOperator.Operator === operator.Operator ? "" : " opacity50")}></span>;
      case 3:
          return <span className={"iconFilterOperatorEndWith iconsFilter" + (formatting.ConditionOperator.Operator === operator.Operator ? "" : " opacity50")}></span>;
      case 4:
          return <span className={"iconFilterOperatorContains iconsFilter" + (formatting.ConditionOperator.Operator === operator.Operator ? "" : " opacity50")}></span>;
      case 5:
          return <span className={"iconFilterOperatorNotContains iconsFilter" + (formatting.ConditionOperator.Operator === operator.Operator ? "" : " opacity50")}></span>;
      case 6:
          return <span className={"iconFilterOperatorGreaterThan iconsFilter" + (formatting.ConditionOperator.Operator === operator.Operator ? "" : " opacity50")}></span>;
      case 7:
          return <span className={"iconFilterOperatorGreaterOrEqualThan iconsFilter" + (formatting.ConditionOperator.Operator === operator.Operator ? "" : " opacity50")}></span>;
      case 8:
          return <span className={"iconFilterOperatorLessThan iconsFilter" + (formatting.ConditionOperator.Operator === operator.Operator ? "" : " opacity50")}></span>;
      case 9:
          return <span className={"iconFilterOperatorLessOrEqualThan iconsFilter" + (formatting.ConditionOperator.Operator === operator.Operator ? "" : " opacity50")}></span>;
      case 10:
          return <span className={"iconFilterOperatorCustom iconsFilter" + (formatting.ConditionOperator.Operator === operator.Operator ? "" : " opacity50")}></span>;
      case 11:
          return <span className={"iconFilterOperatorNull iconsFilter" + (formatting.ConditionOperator.Operator === operator.Operator ? "" : " opacity50")}></span>;
      case 12:
          return <span className={"iconFilterOperatorNotNull iconsFilter" + (formatting.ConditionOperator.Operator === operator.Operator ? "" : " opacity50")}></span>;
      case 13:
          return <span className={"iconFilterOperatorBetween iconsFilter" + (formatting.ConditionOperator.Operator === operator.Operator ? "" : " opacity50")}></span>;
      default:
          break;
    }
  }

  addConditionalFormatting() {
    const { language, currentView, availableFormatings, currentFormatings, search } = this.state;
    const columns = this.getCurrentViewColumns(currentView);
    const length = currentFormatings.length;
    let newFormatting, viewFormatSettingId = -1;
    let errors = [];

    // Get Minimum ViewFormatSettingId
    currentFormatings.forEach(formatting => {
      if(formatting.ViewFormatSettingId <= viewFormatSettingId) {
        viewFormatSettingId = formatting.ViewFormatSettingId - 1;
      }
    });

    // Get first available Formatting corresponding to CurrentView Columns
    if(availableFormatings.find(formatting => columns.includes(formatting.FieldObject.Name))) {
      newFormatting = availableFormatings.find(formatting => columns.includes(formatting.FieldObject.Name));

      // Add new Formatting in Current Formattings
      currentFormatings.push(JSON.parse(JSON.stringify(newFormatting)));
      // currentFormatings.push(JSON.parse(JSON.stringify(availableFormatings[0])));

      // Update ViewFormatSettingId
      currentFormatings[length].ViewFormatSettingId = viewFormatSettingId;

      // Update Search
      search.push({ Name: newFormatting.FieldObject.Name, Value: "" });
      // search.push({ Name: availableFormatings[0].FieldObject.Name, Value: "" });

      this.setState({ currentFormatings, search });
    }
    else {
      errors.push(Traduction.translate(language, 'conditional_formatting_impossible'));
      // newFormatting = availableFormatings[0];

      this.setState({ errors });
    }
  }

  deleteConditionalFormatting(formatting) {
    const currentFormatings = this.state.currentFormatings;
    const search = this.state.search;
    let indexToDelete = -1;

    // Find Current Formatting
    if(currentFormatings.find(currentFormating => currentFormating.ViewFormatSettingId === formatting.ViewFormatSettingId)) {
      indexToDelete = currentFormatings.findIndex(currentFormating => currentFormating.ViewFormatSettingId === formatting.ViewFormatSettingId);
    }

    // Delete Current Formatting & Search
    if(indexToDelete >= 0) {
      currentFormatings.splice(indexToDelete, 1);
      search.splice(indexToDelete, 1);
    }

    this.setState({ currentFormatings, search });
  }

  updateConditionalFormatting(oldFormatting, newFormatting) {
    const availableFormatings = this.state.availableFormatings;
    const currentFormatings = this.state.currentFormatings;
    const search = this.state.search;
    let currentFormating;
    let indexToUpdate = -1;
    let viewFormatSettingId = -1;

    // Find Current Formatting
    if(currentFormatings.find(currentFormating => currentFormating.ViewFormatSettingId === oldFormatting.ViewFormatSettingId)) {
      indexToUpdate = currentFormatings.findIndex(currentFormating => currentFormating.ViewFormatSettingId === oldFormatting.ViewFormatSettingId);
    }

    // Get Minimum ViewFormatSettingId
    currentFormatings.forEach(filter => {
      if(filter.ViewFormatSettingId <= viewFormatSettingId) {
        viewFormatSettingId = filter.ViewFormatSettingId - 1;
      }
    });

    // Update Current Formatting
    if(indexToUpdate >= 0 && availableFormatings.find(availableFormating => availableFormating.FieldObject.Name === newFormatting.FieldObject.Name)) {
      currentFormating = JSON.parse(JSON.stringify(availableFormatings.find(availableFormating => availableFormating.FieldObject.Name === newFormatting.FieldObject.Name)));

      // Update Current Formatting
      currentFormatings[indexToUpdate] = currentFormating;
      currentFormatings[indexToUpdate].ViewFormatSettingId = viewFormatSettingId;
      
      // Reset Search
      search[indexToUpdate] = { Name: newFormatting.FieldObject.Name, Value: "" };
    }

    this.setState({ currentFormatings, search });
  }

  updateOperator(formatting, newOperator) {
    const currentFormatings = this.state.currentFormatings;
    let indexToUpdate = -1;

    // Find Current Formatting
    if(currentFormatings.find(currentFormating => currentFormating.ViewFormatSettingId === formatting.ViewFormatSettingId)) {
      indexToUpdate = currentFormatings.findIndex(currentFormating => currentFormating.ViewFormatSettingId === formatting.ViewFormatSettingId);
    }

    // Update Current Filter
    if(indexToUpdate >= 0 && currentFormatings[indexToUpdate].AvailableOperators.find(operator => operator.Operator === newOperator.Operator)) {
      currentFormatings[indexToUpdate].ConditionOperator = currentFormatings[indexToUpdate].AvailableOperators.find(operator => operator.Operator === newOperator.Operator);
    }

    this.setState({ currentFormatings });
  }

  updateValue1(formatting, event) {
    const currentFormatings = this.state.currentFormatings;

    // Find Current Formatting
    if(currentFormatings.find(currentFormating => currentFormating.ViewFormatSettingId === formatting.ViewFormatSettingId)) {
      currentFormatings.find(currentFormating => currentFormating.ViewFormatSettingId === formatting.ViewFormatSettingId).Value1 = event.target.value;
    }

    this.setState({ currentFormatings });
  }

  updateValue2(formatting, event) {
    const currentFormatings = this.state.currentFormatings;

    // Find Current Formatting
    if(currentFormatings.find(currentFormating => currentFormating.ViewFormatSettingId === formatting.ViewFormatSettingId)) {
      currentFormatings.find(currentFormating => currentFormating.ViewFormatSettingId === formatting.ViewFormatSettingId).Value2 = event.target.value;
    }

    this.setState({ currentFormatings });
  }

  updateBackColor(formatting, color) {
    const currentFormatings = this.state.currentFormatings;

    // Find Current Formatting
    if(currentFormatings.find(currentFormating => currentFormating.ViewFormatSettingId === formatting.ViewFormatSettingId)) {
      currentFormatings.find(currentFormating => currentFormating.ViewFormatSettingId === formatting.ViewFormatSettingId).BackGroundColor = color;
    }

    this.setState({ currentFormatings });
  }

  updateForeColor(formatting, color) {
    const currentFormatings = this.state.currentFormatings;

    // Find Current Formatting
    if(currentFormatings.find(currentFormating => currentFormating.ViewFormatSettingId === formatting.ViewFormatSettingId)) {
      currentFormatings.find(currentFormating => currentFormating.ViewFormatSettingId === formatting.ViewFormatSettingId).Color = color;
    }

    this.setState({ currentFormatings });
  }

  saveConditionalFormatting() {
    const currentView = this.state.currentView;
    const currentFormatings = this.state.currentFormatings;

    // Clear Current View Formatting
    currentView.ConditionalFormatSettings = [];

    // Update Current View with Current Formatting
    currentFormatings.forEach(formatting => {
      currentView.ConditionalFormatSettings.push({ Field: formatting.FieldObject.Name, Conditions: formatting.ConditionOperator.OperatorName, Value1: formatting.Value1, Value2: formatting.Value2, BackGroundColor: formatting.BackGroundColor, Color: formatting.Color, ViewFormatSettingId: formatting.ViewFormatSettingId, ViewId: currentView.ViewId });
    });

    // Call the event from the Parent component through the props with updated Current View
    this.props.onFormattingsChange(currentView);

    // Close Popup
    this.setState({ formattingsPopup: false, errors: [] });
  }

  async openFormattingPopup() {
    const { login, authId, itemId, itemType, blockType, currentView } = this.state;
    
    // Get View Available Formattings
    await this.getAvailableFormattings(login, authId, itemId, itemType, blockType, currentView);

    this.setState({ formattingsPopup: true });
  }

  templateFormattingPopup() {
    const { language, availableFormatings, currentFormatings, isLoading, errors } = this.state;

    return (<div className="popupConditionalFormatting">
      {/* Loading Spinner */}
      {isLoading && <div className="center mt30 mb20">
        <span className=""><LoadingSpinner></LoadingSpinner></span>
        <span className="bold ml30">{Traduction.translate(language, 'data_loading')}</span>
      </div>}
      {/* Title */}
      {!isLoading && <div className="flex align-items-center mb10">
        {/* Label */}
        <div className="conditionalFormattingLabel">{Traduction.translate(language, 'conditional_formattings')}</div>
        {/* Add Filter */}
        <div className="cursor" onClick={() => this.addConditionalFormatting()}>
          <span className="iconButtonPlusGreen iconsFilter"></span>
          <span className="addFormattingLabel">{Traduction.translate(language, 'add_conditional_formatting')}</span>
        </div>
      </div>}
      {/* Error Message */}
      {errors.length > 0 && <ErrorMessage Errors={errors}></ErrorMessage>}
      {/* Filters */}
      {!isLoading && <div className="conditionalFormattingContent scrollbar-miniblock">
        {currentFormatings.map((formatting, index) => {
          return (<div key={index} className="filtersColumn">{this.templateConditionalFormatting(formatting)}</div>)
        })}
      </div>}
      {/* Button Validate or Cancel */}
      {!isLoading && <div className="flex flex-end align-items-center">
        <Button className="fs12 bold brd-radius mr15" variant="primary" onClick={() => this.saveConditionalFormatting()}>{Traduction.translate(language, 'validate')}</Button>
        <Button className="fs12 bold brd-radius" variant="warning" onClick={() => this.closeFormattingPopup()}>{Traduction.translate(language, 'cancel')}</Button>
      </div>}
    </div>);
  }

  templateConditionalFormatting(formatting) {
    const { language, itemId, itemType, currentView, availableFormatings, currentFormatings, search } = this.state;
    let value = "";

    // Define Popover template
    const popoverColumn = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverEditFilter ItemId={itemId} ItemType={itemType} Display={'ConditionalFormatting'} CurrentView={currentView} AvailableFilters={availableFormatings} CurrentFilters={currentFormatings} Filter={formatting} onFilterEdit={this.updateConditionalFormatting}></PopoverEditFilter>
        </Popover.Content>
      </Popover>
    );

    // Define Popover template
    const popoverBackColor = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverEditColorFormatting ItemId={itemId} ItemType={itemType} Formatting={formatting} ColorValue={formatting.BackGroundColor} onColorEdit={this.updateBackColor}></PopoverEditColorFormatting>
        </Popover.Content>
      </Popover>
    );

    // Define Popover template
    const popoverForeColor = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverEditColorFormatting ItemId={itemId} ItemType={itemType} Formatting={formatting} ColorValue={formatting.Color} onColorEdit={this.updateForeColor}></PopoverEditColorFormatting>
        </Popover.Content>
      </Popover>
    );

    return (<div className="conditionalFormattingValue">
      <div className="filtersColumnTitle">
        {/* Delete Formatting */}
        <span className="iconLess iconsPopover mr10 cursor" onClick={() => this.deleteConditionalFormatting(formatting)}/>
        {/* Filter Select */}
        <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popoverColumn}>
          <div className="filtersColumnName">
            <div className="filtersColumnLabel">{formatting.FieldObject.Label}</div>
            <div className="flex flex-end"><span className="iconChevronDownGrey iconsPopover"></span></div>
          </div>
        </OverlayTrigger>
      </div>
      {/* Operators */}
      <div className="filtersOperators">
        {formatting.AvailableOperators.map((operator, index) => {
          return <div key={index} className="filtersOperator" onClick={() => this.updateOperator(formatting, operator)}>
            {['top'].map(placement => (
              <OverlayTrigger key={placement} placement={placement} overlay={<Tooltip id={`tooltip-${placement}`}><strong>{operator.OperatorLabel}</strong></Tooltip>}>
                {this.getOperatorIcon(formatting, operator)}
              </OverlayTrigger>
            ))}
          </div>
        })}
      </div>
      {/* Formatting Values */}
      <div className="formattingValues">
        <Form.Group className="filterTypeString">
          <Form.Control id="" className="filterTypeStringValue" as="textarea" rows="1" placeholder={Traduction.translate(language, 'enter_value')} value={formatting.Value1} onChange={(e) => this.updateValue1(formatting, e)} />
        </Form.Group>
        {formatting.ConditionOperator.Operator === 13 && 
          <Form.Group className="filterTypeString">
            <Form.Control id="" className="filterTypeStringValue" as="textarea" rows="1" placeholder={Traduction.translate(language, 'enter_value')} value={formatting.Value2} onChange={(e) => this.updateValue2(formatting, e)} />
          </Form.Group>
        }
      </div>
      {/* Formatting Colors */}
      <div className="formattingColors">
        <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popoverBackColor}>
          <div className="cursor mr10"><div className="axisColor" style={{ background: formatting.BackGroundColor, border: '1px solid', borderColor: '#8E8E90' }}></div></div>
        </OverlayTrigger>
        <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popoverForeColor}>
          <div className="cursor ml10"><div className="axisColor" style={{ background: formatting.Color, border: '1px solid', borderColor: '#8E8E90' }}></div></div>
        </OverlayTrigger>
      </div>
      {/* Preview */}
      <div className="formattingPreview">
        <span className="fs12 width100p center" style={{ background: formatting.BackGroundColor, color: formatting.Color, border: '1px solid', borderColor: '#8E8E90', borderRadius: '5px' }}>{Traduction.translate(language, 'preview')}</span>
      </div>
    </div>);
  }

  closeFormattingPopup() {
    this.setState({ formattingsPopup: false, errors: [] });
  }
  
  render() {
    const { language, currentView, currentFormatings, formattingsPopup, errors } = this.state;
    let label;

    // Label
    if(currentView.ConditionalFormatSettings && currentView.ConditionalFormatSettings.length === 0) {
      label = Traduction.translate(language, 'conditional_formatting');
    }
    else if(currentView.ConditionalFormatSettings && currentView.ConditionalFormatSettings.length === 1) {
      label = currentView.ConditionalFormatSettings.length + " " + Traduction.translate(language, 'conditional_formatting');
    }
    else if(currentView.ConditionalFormatSettings && currentView.ConditionalFormatSettings.length > 1) {
      label = currentView.ConditionalFormatSettings.length + " " + Traduction.translate(language, 'conditional_formattings');
    }

    return (
      <div className="">
        {/* Popup Formattings */}
        {formattingsPopup && <div className="conditionalFormattingPopup">
          <div className="conditionalFormattingInnerPopup">{this.templateFormattingPopup()}</div>
        </div>}

        {['top'].map(placement => (
          <OverlayTrigger key={placement} placement={placement} overlay={<Tooltip id={`tooltip-${placement}`}><strong>{label}</strong></Tooltip>}>
            <div className={(currentView.ConditionalFormatSettings && currentView.ConditionalFormatSettings.length === 0 ? "filtersBlock" : "filtersBlockActive") + " cursor"} onClick={() => this.openFormattingPopup()}>
              <span className="iconConditionalFormattingBlue iconsFilter"/>
              <span className="fs12 bold ml10">{label}</span>
            </div>
          </OverlayTrigger>
        ))}
      </div>
    )
  }
}

export default FiltersConditionalFormatting;