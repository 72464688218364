import React, {Component} from 'react';
import { Form, OverlayTrigger, Popover } from 'react-bootstrap';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';
import LoadingSpinner from './LoadingSpinner';
import Table from './Table';

const API = '/WebAppService/GetAllUnpinnedDashboardViews';

class PopupAddDashboardView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: null,
      authId: null,
      language: null,
      itemId: null,
      itemType: null,
      blockType: null,
      guestLicence: null,
      views: [],
      genericViews: [],
      genericViewsView: {},
      genericViewsColumns: [],
      genericViewsRows: [],
      genericViewsDatasource: [],
      userViews: [],
      userViewsView: {},
      userViewsColumns: [],
      userViewsRows: [],
      userViewsDatasource: [],
      isLoading: false,
      errors: []
    };

    // Data Structure
    this.getAllUnpinnedViews = this.getAllUnpinnedViews.bind(this);
    this.dataStructure = this.dataStructure.bind(this);

    // Actions
    this.addGenericView = this.addGenericView.bind(this);
    this.addUserView = this.addUserView.bind(this);
    this.closePopup = this.closePopup.bind(this);
  }

  componentDidMount() {
    const login = Authentication.getCookie('login');
    const authId = Authentication.getCookie('authId');
    const language = Authentication.getCookie('language');
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const blockType = this.props.BlockType;
    const guestLicence = this.props.GuestLicence;

    // Get List of Views
    this.getAllUnpinnedViews(login, authId, itemType, blockType);

    this.setState({ login, authId, language, itemId, itemType, blockType, guestLicence });
  }

  componentDidUpdate(prevProps) {
    const login = Authentication.getCookie('login');
    const authId = Authentication.getCookie('authId');
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const blockType = this.props.BlockType;
    const guestLicence = this.props.GuestLicence;

    if(this.props.ItemId !== prevProps.ItemId || this.props.ItemType !== prevProps.ItemType || this.props.BlockType !== prevProps.BlockType) {
      // Get List of Views
      this.getAllUnpinnedViews(login, authId, itemType, blockType);
    
      this.setState({ itemId, itemType, blockType });
    }
    if(this.props.GuestLicence !== prevProps.GuestLicence) {
      this.setState({ guestLicence });
    }
  }

  async getAllUnpinnedViews(login, authId, itemType, blockType) {
    const language = this.state.language;

    this.setState({ isLoading: true });

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': itemType,
        'BlockName': blockType
      })
    };

    try{
      const response = await fetch(API, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const result = data.GetAllUnpinnedDashboardViewsResult;

      if(result) {
        const genericViews = result.ListGenericDashboardViews;
        const genericViewsView = result.TableGenericDashboardView;
        const genericViewsColumns = result.TableGenericDashboardViews.ColumnHeaders;
        const genericViewsRows = result.TableGenericDashboardViews.Rows;

        const userViews = result.ListUserDashboardViews;
        const userViewsView = result.TableUserDashboardView;
        const userViewsColumns = result.TableUserDashboardViews.ColumnHeaders;
        const userViewsRows = result.TableUserDashboardViews.Rows;
        
        // Build Generic Views Datasource
        const genericViewsDatasource = this.dataStructure(genericViewsRows);
        
        // Build User Views Datasource
        const userViewsDatasource = this.dataStructure(userViewsRows);

        this.setState({ views: result, genericViews, genericViewsView, genericViewsColumns, genericViewsRows, genericViewsDatasource, userViews, userViewsView, userViewsColumns, userViewsRows, userViewsDatasource, isLoading: false });
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    } catch(error) {
      this.setState({ error });
    }
  }

  dataStructure(rows) {
    let dataSource = [];

    if(rows.length > 0) {
      dataSource = rows.map(row => {
        return row.Cells.reduce((acc, item) => {
          // Format Dates
          if(item.FieldType === 'Date' && item.Value) {
            acc[item.ColumnName] = new Date(item.Value);
          }
          // Format Numbers
          else if(item.FieldType === 'Double' && item.Value) {
            acc[item.ColumnName] = parseFloat(item.Value);
          }
          // Add the Column Name/Values to the reduced Table
          // Exclude Parent_ID for Table
          if(item.ColumnName !== 'Parent_ID') {
            acc[item.ColumnName] = item.Value;
          }
          
          return acc;
        }, {});
      });
    }

    return dataSource;
  }

  addGenericView(viewDashboardId) {
    const genericViews = this.state.genericViews;
    let view;

    if(genericViews.find(view => view.ViewDashboardId == viewDashboardId)) {
      view = genericViews.find(view => view.ViewDashboardId == viewDashboardId);
    }

    // Call the event from the Parent component through the props
    this.props.onViewAdd(view);
  }

  addUserView(viewDashboardId) {
    const userViews = this.state.userViews;
    let view;

    if(userViews.find(view => view.ViewDashboardId == viewDashboardId)) {
      view = userViews.find(view => view.ViewDashboardId == viewDashboardId);
    }

    // Call the event from the Parent component through the props
    this.props.onViewAdd(view);
  }

  closePopup() {
    // Call the event from the Parent component through the props
    this.props.onPopupClose();
  }

  render() {
    const { language, itemId, itemType, blockType, guestLicence, views, genericViewsView, genericViewsColumns, genericViewsRows, genericViewsDatasource, userViewsView, userViewsColumns, userViewsRows, userViewsDatasource, isLoading } = this.state;

    return (
      <div className="viewsPopup">
        <div className="viewsInnerPopup">
          <div className="popupAddView">
            {/* Views Title */}
            <div className="flex mb10">
              {/* Label */}
              <Form.Label className="popupViewsLabel">
                <div className="popupViewsLabelBlue">{Traduction.translate(language, 'pin_new_view')}</div>
              </Form.Label>

              {/* Close Popup */}
              <div className="flex-end align-items-center"><span className="iconClear icons cursor" onClick={() => this.closePopup()}></span></div>
            </div>
            <div className="addViewContent">
              <div className="dashboardViewsTables scrollbar-y">
                {/* Loading Spinner */}
                {isLoading && <div className="center mt30 mb20">
                  <span className=""><LoadingSpinner></LoadingSpinner></span>
                  <span className="bold ml30">{Traduction.translate(language, 'data_loading')}</span>
                </div>}

                {/* Generic Views */}
                {genericViewsRows.length > 0 && <div className="genericViews">
                  {/* Generic Views Title */}
                  <div className="genericViewsTitle">
                    <div className="genericViewsLabel">{Traduction.translate(language, 'generic_views')}</div>
                  </div>
                  {/* Generic Views Table */}
                  <Table ref={this.genericViewsTable} ItemId={itemId} ItemType={itemType} BlockType={'Views'} Editable={false} GuestLicence={guestLicence} CurrentView={genericViewsView} Columns={genericViewsColumns} Rows={genericViewsRows} onViewAdd={this.addGenericView}></Table>
                </div>}
                {/* User Views */}
                {userViewsRows.length > 0 && <div className="userViews">
                  {/* User Views Title */}
                  <div className="userViewsTitle">
                    <div className="userViewsLabel">{Traduction.translate(language, 'user_views')}</div>
                  </div>
                  {/* User Views Table */}
                  <Table ref={this.userViewsTable} ItemId={itemId} ItemType={itemType} BlockType={'Views'} Editable={false} GuestLicence={guestLicence} CurrentView={userViewsView} Columns={userViewsColumns} Rows={userViewsRows} onViewAdd={this.addUserView}></Table>
                </div>}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PopupAddDashboardView;