import React, {Component} from 'react';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';

class PopoverColumnActions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: null,
      itemId: null,
      itemType: null,
      columnName: null
    };

    this.filterColumn = this.filterColumn.bind(this);
    this.sortColumn= this.sortColumn.bind(this);
    this.autofitColumn = this.autofitColumn.bind(this);
    this.autofitAllColumns = this.autofitAllColumns.bind(this);
  }

  componentDidMount() {
    const language = Authentication.getCookie('language');
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const columnName = this.props.ColumnName;

    this.setState({ language, itemId, itemType, columnName });
  }

  componentDidUpdate(prevProps) {
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const columnName = this.props.ColumnName;
  }

  filterColumn(columnName) {
    // Close Popover
    if(document.getElementById("popover-basic")) {
      document.getElementById("popover-basic").classList.remove("show");
    }

    // Call the event from the Parent component through the props
    this.props.onColumnsFilter(columnName);
  }

  sortColumn(columnName) {
    // Close Popover
    if(document.getElementById("popover-basic")) {
      document.getElementById("popover-basic").classList.remove("show");
    }

    // Call the event from the Parent component through the props
    this.props.onColumnsSort(columnName);
  }

  autofitColumn(columnName) {
    // Close Popover
    if(document.getElementById("popover-basic")) {
      document.getElementById("popover-basic").classList.remove("show");
    }

    // Call the event from the Parent component through the props
    this.props.onColumnAutofit(columnName);
  }

  autofitAllColumns() {
    // Close Popover
    if(document.getElementById("popover-basic")) {
      document.getElementById("popover-basic").classList.remove("show");
    }

    // Call the event from the Parent component through the props
    this.props.onColumnsAutofit();
  }

  render() {
    const { language, itemId, itemType, columnName } = this.state;

    return (
      <div className="columnActionsPopover">
        <div className="columnActionValue" onClick={() => this.filterColumn(columnName)}>
          <span className="iconFiltersGrey iconsFilter mr10" alt="Filters"/>
          <span className="fs12">{Traduction.translate(language, 'filter_data')}</span>
        </div>
        <div className="columnActionValue" onClick={() => this.sortColumn(columnName)}>
          <span className="iconSortGrey iconsFilter mr10" alt="Sort"/>
          <span className="fs12">{Traduction.translate(language, 'sort_data')}</span>
        </div>
        {/* <div className="columnActionValue" onClick={() => this.autofitColumn(columnName)}>
          <span className="iconAutofitGrey iconsFilter mr10" alt="Autofit"/>
          <span className="fs12">{Traduction.translate(language, 'autofit_column')}</span>
        </div> */}
        <div className="columnActionValue" onClick={() => this.autofitAllColumns()}>
          <span className="iconAutofitGrey iconsFilter mr10" alt="Autofit"/>
          <span className="fs12">{Traduction.translate(language, 'autofit_all_columns')}</span>
        </div>
      </div>
    );
  }
}

export default PopoverColumnActions;