import React, {Component} from 'react';
import MetaTags from 'react-meta-tags';
import queryString from 'query-string';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';
import Navbar from './Navbar';
import BlockTitle from './BlockTitle';
import FiltersView from './FiltersView';
import FiltersCurrentView from './FiltersCurrentView';
import ButtonsOccupationLevel from './ButtonsOccupationLevel';
import ButtonsOccupationMetric from './ButtonsOccupationMetric';
import FiltersDisplayComponent from './FiltersDisplayComponent';
import FiltersChart from './FiltersChart';
import LoadingSpinner from './LoadingSpinner';
import ErrorModification from './ErrorModification';
import WorkloadHeatmap from './WorkloadHeatmap';
import Pivot from './Pivot';
import Chart from './Chart';

const API_info = '/WebAppService/GetCardBlockInformation';

class BlockWorkload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: null,
      authId: null,
      language: null,
      itemId: null,
      itemType: null,
      itemTitle: null,
      blockType: null,
      blockInfo: {},
      editable: null,
      guestLicence: null,
      favorite: null,
      parents: [],
      warnings: 0,
      displayViews: true,
      views: [],
      defaultViewId: null,
      viewId: null,
      currentView: {},
      heatmaps: [],
      heatmap: {},
      tables: [],
      columns: [],
      rows: [],
      chart: {},
      filtered: [],
      occupationLevel: 'Month',
      occupationMetric: 'Occupancy',
      selectedDates: [],
      selectedResources: [],
      visibleHeatmap: true,
      visibleGraph: true,
      isLoading: false,
      errors: []
    };

    // Data Structure
    this.getCardInformation = this.getCardInformation.bind(this);
    this.getCardData = this.getCardData.bind(this);
    this.getSelectedDates = this.getSelectedDates.bind(this);
    this.convertStringtoBoolean = this.convertStringtoBoolean.bind(this);
    this.formatDate = this.formatDate.bind(this);

    // Actions
    this.displayViews = this.displayViews.bind(this);
    this.hideViews = this.hideViews.bind(this);
    this.changeView = this.changeView.bind(this);
    this.setDefaultView = this.setDefaultView.bind(this);
    this.changeOccupationLevel = this.changeOccupationLevel.bind(this);
    this.changeOccupationMetric = this.changeOccupationMetric.bind(this);
    this.changeDisplay = this.changeDisplay.bind(this);
    this.applyChartSettings = this.applyChartSettings.bind(this);
    this.displayChartSettings = this.displayChartSettings.bind(this);
    this.closeChartSettings = this.closeChartSettings.bind(this);
    this.updateView = this.updateView.bind(this);
    this.updateHeatmap = this.updateHeatmap.bind(this);
    this.updateSelection = this.updateSelection.bind(this);
    this.clearSelection = this.clearSelection.bind(this);
    this.selectionFilter = this.selectionFilter.bind(this);
    // this.updateTable = this.updateTable.bind(this);
    this.updateErrors = this.updateErrors.bind(this);

    this.views = React.createRef();
    this.workloadHeatmap = React.createRef();
    this.workloadPivot = React.createRef();
    this.workloadChart = React.createRef();
  }

  async componentDidMount() {
    const login = Authentication.getCookie('login');
    const authId = Authentication.getCookie('authId');
    const language = Authentication.getCookie('language');
    const itemId = this.props.match.params.itemId;
    const itemType = this.props.match.params.itemType;
    const path = this.props.match.path.split('/');
    const blockType = path[path.length-1];
    let displayViews;

    if(Authentication.getCookie('displayViews')) {
      displayViews = JSON.parse(Authentication.getCookie('displayViews'));
    }
    else {
      displayViews = true;
    }

    // Get Params from Query string url
    let params, viewId;

    if(this.props.location.search) {
      params = queryString.parse(this.props.location.search);
      viewId = parseInt(params.viewId);
    }

    this.setState({ login, authId, language, itemId, itemType, blockType, displayViews });

    // Get Card Information
    await this.getCardInformation(login, authId, itemId, itemType, blockType, viewId);

    const blockInfo = this.state.blockInfo;
    const views = this.state.views;
    let currentView = {};

    if(Object.entries(blockInfo).length > 0) {
      // Get Current View
      if(viewId && views.find(view => view.ViewId === viewId)) {
        currentView = views.find(view => view.ViewId === viewId);
      }
      else if(views.find(view => view.ViewId === blockInfo.DefaultViewId)) {
        currentView = views.find(view => view.ViewId === blockInfo.DefaultViewId);
      }

      if(!viewId && (currentView.ViewId && viewId !== currentView.ViewId)) {
        // Redirect with new Query params
        this.props.history.push(`/Card/${itemType}/${itemId}/${blockType}?viewId=${currentView.ViewId}`);
      }
      else {
        // Get Card Data
        await this.getCardData(login, authId, itemId, itemType, blockType, currentView);
      }
    }
    else {
      // Redirect to Login Page
      this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
    }
  }

  async componentDidUpdate(prevProps) {
    const login = Authentication.getCookie('login');
    const authId = Authentication.getCookie('authId');
    const itemId = this.props.match.params.itemId;
    const itemType = this.props.match.params.itemType;
    const path = this.props.match.path.split('/');
    const blockType = path[path.length-1];
    
    // Get Params from Query string url
    let params, old_params, viewId, oldViewId;

    if(this.props.location.search) {
      params = queryString.parse(this.props.location.search);
      viewId = parseInt(params.viewId);
    }
    if(prevProps.location.search) {
      old_params = queryString.parse(prevProps.location.search);
      oldViewId = parseInt(old_params.viewId);
    }

    if(itemId !== prevProps.match.params.itemId || itemType !== prevProps.match.params.itemType) {
      this.setState({ itemId, itemType });
      
      // Get Card Information
      await this.getCardInformation(login, authId, itemId, itemType, blockType, viewId);
    }
    if(viewId !== oldViewId) {
      const blockInfo = this.state.blockInfo;
      const views = this.state.views;
      let currentView = {};

      // Get Current View
      if(viewId && views.find(view => view.ViewId === viewId)) {
        currentView = views.find(view => view.ViewId === viewId);
      }
      else if(views.find(view => view.ViewId === blockInfo.DefaultViewId)) {
        currentView = views.find(view => view.ViewId === blockInfo.DefaultViewId);
      }

      // Get Card Data
      await this.getCardData(login, authId, itemId, itemType, blockType, currentView);

      // Refresh Filters View
      // if(this.views.current) {
      //   this.views.current.selectView(currentView);
      // }
      
      // Refresh Components with CurrentView
      if(currentView.ViewType === 3 && this.workloadPivot.current) {
        this.workloadPivot.current.refreshPivot(currentView);
      }
      else if(currentView.ViewType === 7 && this.workloadChart.current) {
        this.workloadChart.current.applySettings(currentView);
      }
    }
  }

  // Get Block Information from the API
  async getCardInformation(login, authId, itemId, itemType, blockType, viewId) {
    const language = this.state.language;

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': itemType,
        'ItemId': itemId,
        'BlockType': blockType,
        'ViewId': viewId,
        'WithData': false,
        'WithInformation': true,
        'InactiveData': false
      })
    };

    try{
      const response = await fetch(API_info, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const blockInfo = data.GetCardBlockInformationResult;

      if(blockInfo) {
        const itemTitle = blockInfo.ObjectName;
        const editable = blockInfo.InsertDeleteAllowed;
        const guestLicence = blockInfo.GuestLicence;
        const favorite = blockInfo.IsFavorite;
        const parents = blockInfo.Parents;
        const warnings = blockInfo.Warnings;
        const views = blockInfo.Views;
        const defaultViewId = blockInfo.DefaultViewId;
        let currentView = {};

        // Get Current View
        if(viewId && views.find(view => view.ViewId === viewId)) {
          currentView = views.find(view => view.ViewId === viewId);
        }
        else if(views.find(view => view.ViewId === blockInfo.DefaultViewId)) {
          currentView = views.find(view => view.ViewId === blockInfo.DefaultViewId);
        }

        this.setState({ blockInfo, itemTitle, editable, guestLicence, favorite, parents, warnings, views, defaultViewId, viewId, currentView });
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  // Get Block Data from the API
  async getCardData(login, authId, itemId, itemType, blockType, currentView) {
    const language = this.state.language;

    this.setState({ isLoading: true });

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': itemType,
        'ItemId': itemId,
        'BlockType': blockType,
        'View': currentView,
        'WithData': true,
        'WithInformation': false,
        'InactiveData': false
      })
    };

    try{
      const response = await fetch(API_info, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const blockContent = data.GetCardBlockInformationResult;

      if(blockContent) {
        const heatmaps = blockContent.HeatMaps;
        const tables = blockContent.Tables;
        const chart = blockContent.Chart;
        let heatmap = {}, columns = [], rows = [], filtered = [];
        let occupationLevel = 'Month', occupationMetric = 'Occupancy';
        let visibleGraph, visibleHeatmap;

        // Get Heatmap
        if(heatmaps && heatmaps.length > 0) {
          heatmap = heatmaps[0];
        }
        // if(heatmaps.find(element => element.Name === "Workload")) {
        //   heatmap = heatmaps.find(element => element.Name === "Workload");
        // }

        // Get Tables, Rows & Columns
        if(tables.find(element => element.Level === "Workload")) {
          columns = tables.find(element => element.Level === "Workload").ColumnHeaders;
          rows = tables.find(element => element.Level === "Workload").Rows;
          filtered = tables.find(element => element.Level === "Workload").Rows;
        }

        // Initialise Occupation Level & Metric
        if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'OccupationLevel')) {
          occupationLevel = currentView.Parameters.find(param => param.Name === 'OccupationLevel').Value;
        }
        if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'OccupationMetric')) {
          occupationMetric = currentView.Parameters.find(param => param.Name === 'OccupationMetric').Value;
        }

        // Initialise Visible Component
        if(currentView.Parameters && currentView.Parameters.find(param => param.Name === "VisibleComponent")) {
          // Display Graph
          if(currentView.Parameters.find(param => param.Name === "VisibleComponent").Value === 'Graph') {
            visibleGraph = true;
            visibleHeatmap = false;
          }
          // Display Heatmap
          else if(currentView.Parameters.find(param => param.Name === "VisibleComponent").Value === 'Heatmap') {
            visibleGraph = false;
            visibleHeatmap = true;
          }
        }
        // Display Both
        else {
          visibleGraph = true;
          visibleHeatmap = true;
        }

        this.setState({ viewId: currentView.ViewId, currentView, heatmaps, heatmap, tables, columns, rows, chart, filtered, occupationLevel, occupationMetric, visibleGraph, visibleHeatmap, isLoading: false });
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  getSelectedDates(datesLabel) {
    const columns = this.state.heatmap.Columns;
    let dates = [];

    datesLabel.forEach(date => {
      if(columns.find(column => column.Label === date)) {
        dates.push(this.formatDate(columns.find(column => column.Label === date).Name));
      }
    })

    return dates;
  }

  convertStringtoBoolean(string) {
    if(string === 'true') {
      return true;
    }
    else {
      return false;
    }
  }

  formatDate(date) {
    let formattedDate;

    if(date) {
      const split = date.split('/');
      const month = split[0];
      const year = split[2];

      // Get first day of the month
      formattedDate = month + '/01/' + year;
    }

    return formattedDate;
  }

  displayViews() {
    Authentication.deleteCookie('displayViews');
    Authentication.createCookie('displayViews', true);

    this.setState({ displayViews: true });
  }

  hideViews() {
    Authentication.deleteCookie('displayViews');
    Authentication.createCookie('displayViews', false);

    this.setState({ displayViews: false });
  }

  changeView(view) {
    const itemId = this.state.itemId;
    const itemType = this.state.itemType;
    const blockType = this.state.blockType;

    // Redirect with new Query params
    this.props.history.push(`/Card/${itemType}/${itemId}/${blockType}?viewId=${view.ViewId}`);
  }

  setDefaultView(viewId) {
    this.setState({ defaultViewId: viewId });
  }

  async changeOccupationLevel(value) {
    const login = this.state.login;
    const authId = this.state.authId;
    const itemId = this.state.itemId;
    const itemType = this.state.itemType;
    const blockType = this.state.blockType;
    const currentView = this.state.currentView;

    // Update Current View Axe
    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'OccupationLevel')) {
      currentView.Parameters.find(param => param.Name === 'OccupationLevel').Value = value;
    }
    else if(currentView.Parameters) {
      currentView.Parameters.push({ Name: "OccupationLevel", Value: value });
    }

    // Add Modify attribute in CurrentView
    currentView["Modify"] = true;

    // Get Card Data
    await this.getCardData(login, authId, itemId, itemType, blockType, currentView);

    this.setState({ occupationLevel: value });
  }

  async changeOccupationMetric(value) {
    const login = this.state.login;
    const authId = this.state.authId;
    const itemId = this.state.itemId;
    const itemType = this.state.itemType;
    const blockType = this.state.blockType;
    const currentView = this.state.currentView;

    // Update Current View Axe
    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'OccupationMetric')) {
      currentView.Parameters.find(param => param.Name === 'OccupationMetric').Value = value;
    }
    else if(currentView.Parameters) {
      currentView.Parameters.push({ Name: "OccupationMetric", Value: value });
    }

    // Add Modify attribute in CurrentView
    currentView["Modify"] = true;

    // Get Card Data
    await this.getCardData(login, authId, itemId, itemType, blockType, currentView);

    this.setState({ occupationMetric: value });
  }

  async changeDisplay() {
    const { login, authId, itemId, itemType, blockType, currentView, year } = this.state;
    let visibleHeatmap, visibleGraph;

    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === "VisibleComponent")) {
      // Display Both
      if(currentView.Parameters.find(param => param.Name === "VisibleComponent").Value === 'Graph') {
        currentView.Parameters.splice(currentView.Parameters.findIndex(param => param.Name === "VisibleComponent"), 1);
        visibleGraph = true;
        visibleHeatmap = true;
      }
      // Display Graph
      else if(currentView.Parameters.find(param => param.Name === "VisibleComponent").Value === 'Heatmap') {
        currentView.Parameters.find(param => param.Name === "VisibleComponent").Value = "Graph";
        visibleGraph = true;
        visibleHeatmap = false;
      }
    }
    // Display Heatmap
    else {
      currentView.Parameters.push({ Name: "VisibleComponent", Value: "Heatmap" });
      visibleHeatmap = true;
      visibleGraph = false;
    }

    // Add Modify attribute in CurrentView
    currentView["Modify"] = true;

    // Get Card Data
    await this.getCardData(login, authId, itemId, itemType, blockType, currentView, year);

    this.setState({ visibleGraph, visibleHeatmap });
  }

  async applyChartSettings(currentView) {
    const login = this.state.login;
    const authId = this.state.authId;
    const itemId = this.state.itemId;
    const itemType = this.state.itemType;
    const blockType = this.state.blockType;

    // Add Modify attribute in CurrentView
    currentView["Modify"] = true;

    // Get Card Data
    await this.getCardData(login, authId, itemId, itemType, blockType, currentView);

    // Apply Setting on Chart and Update CurrentView
    if(this.workloadChart.current) {
      this.workloadChart.current.applySettings(currentView);
    }

    // this.setState({ currentView });
  }

  displayChartSettings() {
    if(this.workloadChart.current) {
      this.workloadChart.current.displaySettings();
    }
  }

  closeChartSettings() {
    if(this.workloadChart.current) {
      this.workloadChart.current.closeSettings();
    }
  }

  updateView(view) {
    this.setState({ currentView: view });
  }

  updateHeatmap(period) {
    const heatmaps = this.state.heatmaps;

    // Get corresponding Heatmap
    const heatmap = heatmaps.find(element => element.Name === period);

    this.setState({ heatmap });
  }

  updateSelection(selectedDatesLabel, selectedResources) {
    const rows = this.state.rows;
    let selectedDates = [];
    let filtered = [];

    // Update selection
    if(selectedDatesLabel.length > 0 || selectedResources.length > 0) {
      // Get selected Dates
      selectedDates = this.getSelectedDates(selectedDatesLabel);

      rows.forEach(row => {
        const asOfDate = row.Cells.find(cell => cell.ColumnName === "AsOfDate").Value;
        const resource = row.Cells.find(cell => cell.ColumnName === "Resource").Value;

        // If Row is selected
        if(this.selectionFilter(asOfDate, resource, selectedDates, selectedResources)) {
          filtered.push(row);
        }
      });
  
      // Refresh Component Data
      if(this.workloadPivot.current) {
        this.workloadPivot.current.updatePivot(filtered);
      }
      else if(this.workloadChart.current) {
        this.workloadChart.current.updateChart(filtered);
      }

      this.setState({ filtered, selectedDates: selectedDatesLabel, selectedResources });
    }
    // Clear selection
    else {
      // Refresh Component Data
      if(this.workloadPivot.current) {
        this.workloadPivot.current.updatePivot(rows);
      }
      else if(this.workloadChart.current) {
        this.workloadChart.current.updateChart(rows);
      }
    }
  }

  clearSelection() {
    const rows = this.state.rows;

    if(this.workloadHeatmap.current) {
      this.workloadHeatmap.current.clearSelection();
    }

    this.setState({ filtered: rows, selectedDates: [], selectedResources: [] });
  }

  selectionFilter(asOfDate, resource, selectedDates, selectedResources) {
    if(selectedResources.includes(resource)) {
      if(selectedDates.includes(this.formatDate(asOfDate))) {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      return false;
    }
  }

  // updateTable(rows) {

  // }

  updateErrors(err) {
    let errors = [];

    // Push the new Errors in the Errors Table
    errors.push(err);

    this.setState({ errors });
  }
  
  render() {
    const { language, itemId, itemType, itemTitle, blockType, blockInfo, editable, guestLicence, favorite, parents, warnings, displayViews, views, defaultViewId, currentView, heatmap, tables, columns, rows, chart, filtered, occupationLevel, occupationMetric, selectedDates, selectedResources, visibleHeatmap, visibleGraph, isLoading, errors } = this.state;

    return (
      <div className="blockContainer">
        {/* Title */}
        <MetaTags><title>{itemTitle} • {Traduction.translate(language, 'workload')}</title></MetaTags>

        {/* Navbar */}
        <Navbar Selected={this.props.match.url}></Navbar>

        {/* HasRightOnItem = false */}
        {blockInfo.HasRightOnItem === false && <div className="block">
          <div className="blockHeader">
            <div className="blockTitle">
              <div className="cardIcon"><div className="cardIconGrey"><span className="iconNoRights iconsCard"></span></div></div>
              <div className="flex"><span className="cardTitle">{Traduction.translate(language, 'no_sufficient_rights')}</span></div>
            </div>
          </div>
        </div>}

        {/* Block */}
        {blockInfo.HasRightOnItem === true && <div className="block">
          {/* Card Block Header */}
          <div className="blockHeader">
            {/* Title & Parents */}
            <BlockTitle ItemId={itemId} ItemType={itemType} ItemTitle={itemTitle} BlockType={blockType} CurrentView={currentView} Blocks={blockInfo.BlockNames} Favorite={favorite} Parents={parents} Warnings={warnings} onDataUpdate={this.getCardData}></BlockTitle>
            {/* Border */}
            <div className="blockBorder"></div>
          </div>

          {/* Card Block Body */}
          <div className="blockBody">
            {/* Filters Views */}
            {displayViews && <FiltersView ref={this.views} ItemId={itemId} ItemType={itemType} BlockType={blockType} GuestLicence={guestLicence} DefaultViewId={defaultViewId} CurrentView={currentView} Views={views} onViewsHide={this.hideViews} onViewChange={this.changeView} onSetDefaultView={this.setDefaultView} onErrorsUpdate={this.updateErrors}></FiltersView>}

            {/* Card Block Content */}
            <div className={!displayViews ? "blockContent" : "blockContentViews"}>
              {/* Filters */}
              <div className="blockFilters">
                {/* Current View */}
                {!displayViews && <FiltersCurrentView ItemId={itemId} ItemType={itemType} BlockType={blockType} CurrentView={currentView} onDisplayViews={this.displayViews} onErrorsUpdate={this.updateErrors}></FiltersCurrentView>}

                <div className="views">
                  {/* Buttons Occupation Metric */}
                  <ButtonsOccupationMetric BlockType={blockType} CurrentView={currentView} OccupationMetric={occupationMetric} onOccupationMetricChange={this.changeOccupationMetric}></ButtonsOccupationMetric>

                  <div className="flex align-items-center"><span className="fs14 bold blue mh10">{Traduction.translate(language, 'by')}</span></div>

                  {/* Buttons Occupation Level */}
                  <ButtonsOccupationLevel BlockType={blockType} CurrentView={currentView} OccupationLevel={occupationLevel} onOccupationLevelChange={this.changeOccupationLevel}></ButtonsOccupationLevel>                
                </div>
                <div className="filters">
                  {/* Visible Component */}
                  {!guestLicence && <FiltersDisplayComponent BlockType={blockType} CurrentView={currentView} DisplayHeatmap={visibleHeatmap} DisplayGraph={visibleGraph} onDisplayChange={this.changeDisplay}></FiltersDisplayComponent>}

                  {/* Chart Settings */}
                  {currentView.ViewType === 7 && !guestLicence && 
                    <FiltersChart onChartSettingsDisplay={this.displayChartSettings} onChartSettingsClose={this.closeChartSettings}></FiltersChart>
                  }

                  {selectedDates.length === 1 && selectedResources.length === 1 && 
                    <div className="selectionHeatmap">
                      {/* Clear Selection */}
                      <span className="clearHeatmapIcon iconClear icons cursor" onClick={(e) => this.clearSelection()}/>
                      {/* Selection Details */}
                      <div className="selectionDetails fs12">
                        <span className="bold">{Traduction.translate(language, 'workload_breakdown')}</span>
                        <span className="ml5 mr5">{Traduction.translate(language, 'of')}</span>
                        <span className="turquoise bold">{selectedResources.join(', ')}</span>
                        <span className="ml5 mr5">{Traduction.translate(language, 'on')}</span>
                        <span className="bold">{selectedDates[0]}</span>
                      </div>
                    </div>
                  }
                  {selectedDates.length === 1 && selectedResources.length > 1 && 
                    <div className="selectionHeatmap">
                      {/* Clear Selection */}
                      <span className="clearHeatmapIcon iconClear icons cursor" onClick={(e) => this.clearSelection()}/>
                      {/* Selection Details */}
                      <div className="selectionDetails fs12">
                        <span className="bold">{Traduction.translate(language, 'workload_breakdown')}</span>
                        <span className="ml5 mr5">{Traduction.translate(language, 'of')}</span>
                        <span className="turquoise bold">{selectedResources.join(', ')}</span>
                        <span className="ml5 mr5">{Traduction.translate(language, 'on')}</span>
                        <span className="bold">{selectedDates[0]}</span>
                      </div>
                    </div>
                  }
                  {selectedDates.length > 1 && selectedResources.length >= 1 && 
                    <div className="selectionHeatmap">
                      {/* Clear Selection */}
                      <span className="clearHeatmapIcon iconClear icons cursor" onClick={(e) => this.clearSelection()}/>
                      {/* Selection Details */}
                      <div className="selectionDetails fs12">
                        <span className="bold">{Traduction.translate(language, 'workload_breakdown')}</span>
                        <span className="ml5 mr5">{Traduction.translate(language, 'of')}</span>
                        <span className="turquoise bold">{selectedResources.join(', ')}</span>
                        <span className="ml5 mr5">{Traduction.translate(language, 'on')}</span>
                        <span className="bold">{selectedDates.join(', ')}</span>
                      </div>
                    </div>
                  }
                </div>
              </div>

              {/* Card Block Component */}
              <div className="blockComponent">
                <div className="blockWorkload">
                  {/* Errors */}
                  {errors.length > 0 && 
                    <ErrorModification Errors={errors} Open={true} onErrorsClean={this.cleanErrors}></ErrorModification>
                  }

                  {/* Loading Spinner */}
                  {isLoading && <div className="center mt30 mb20">
                    <span className=""><LoadingSpinner></LoadingSpinner></span>
                    <span className="bold ml30">{Traduction.translate(language, 'data_loading')}</span>
                  </div>}

                  {/* Heatmap */}
                  {visibleHeatmap && <WorkloadHeatmap ref={this.workloadHeatmap} ItemType={itemType} Title={heatmap.Title} Columns={heatmap.Columns} Rows={heatmap.Rows} ChartData={heatmap.ChartData} OccupationLevel={occupationLevel} OccupationMetric={occupationMetric} VisibleHeatmap={visibleHeatmap} VisibleGraph={visibleGraph} onHeatmapSelection={this.updateSelection}></WorkloadHeatmap>}

                  {/* Pivot */}
                  {visibleGraph && currentView.ViewType === 3 && filtered.length > 0 && <div className="workloadPivot">
                    <Pivot ref={this.workloadPivot} ItemId={itemId} ItemType={itemType} BlockType={blockType} Editable={editable} GuestLicence={guestLicence} CurrentView={currentView} Columns={columns} Rows={rows} Filtered={filtered} onViewChange={this.updateView} VisibleHeatmap={visibleHeatmap} VisibleGraph={visibleGraph}></Pivot>
                  </div>}

                  {/* Chart */}
                  {visibleGraph && currentView.ViewType === 7 && <div className="workloadPivot">
                    <Chart ref={this.workloadChart} ItemId={itemId} ItemType={itemType} Title={itemTitle} BlockType={blockType} GuestLicence={guestLicence} CurrentView={currentView} Chart={chart} IsLoading={isLoading} onViewChange={this.updateView} onSettingsChange={this.applyChartSettings}></Chart>
                  </div>}
                </div>
              </div>
            </div>
          </div>
        </div>}
      </div>
    )
  }
}

export default BlockWorkload;