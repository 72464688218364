import React, {Component} from 'react';
import { Button, Form } from 'react-bootstrap';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';

const API_get_axes = '/WebAppService/GetAvailableAxesOfSlide';

class PopoverLoadAxes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: null,
      authId: null,
      language: null,
      staticData: null,
      itemId: null,
      itemType: null,
      slideId: null,
      columnName: null,
      axeId: null,
      currentValue: null,
      values: [],
      search: ''
    };

    this.getAvailableAxes = this.getAvailableAxes.bind(this);
    this.searchItem = this.searchItem.bind(this);
    this.pressKey = this.pressKey.bind(this);
    this.updateValue = this.updateValue.bind(this);

    this.search = React.createRef();
  }

  componentDidMount() {
    const login = Authentication.getCookie('login');
    const authId = Authentication.getCookie('authId');
    const language = Authentication.getCookie('language');
    const staticData = Authentication.getCookie('staticData');
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const slideId = this.props.SlideId;
    const axeId = this.props.AxeId;
    const currentValue = this.props.CurrentValue;

    // Search Auto focus
    setTimeout(() => { this.search.current.focus() }, 1);

    this.setState({ login, authId, language, staticData, itemId, itemType, slideId, axeId, currentValue });

    this.getAvailableAxes(login, authId, slideId);
  }

  componentDidUpdate(prevProps) {
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const columnName = this.props.ColumnName;
    const currentValue = this.props.CurrentValue;
    const values = this.props.Values;

    // if(this.props.ItemId !== prevProps.ItemId || this.props.ItemType !== prevProps.ItemType) {
    //   this.setState({ itemId, itemType });
    // }
    // if(this.props.ColumnName !== prevProps.ColumnName || this.props.AxeValue !== prevProps.AxeValue || this.props.RestrictedValues !== prevProps.RestrictedValues) {
    //   this.setState({ columnName, axeValue, restrictedValues });
    // }
  }

  async getAvailableAxes(login, authId, slideId) {
    const language = this.state.language;

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'SlideId': slideId
      })
    };

    try{
      const response = await fetch(API_get_axes, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }
      
      const data = await response.json();
      const result = data.GetAvailableAxesOfSlideResult;

      if(result) {
        this.setState({ values: result });
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  searchItem(event) {
    const search = event.target.value;

    this.setState({ search });
  }

  pressKey(event) {
    const { slideId, values } = this.state;
    const search = event.target.value;
    let newValue;

    if(event.key === 'Enter' && search !== '') {
      if(values.find(value => value.Axe.toLowerCase().includes(search.toLowerCase()))) {
        newValue = values.find(value => value.Axe.toLowerCase().includes(search.toLowerCase()));

        // Close Popover
        if(document.getElementById("popover-basic")) {
          document.getElementById("popover-basic").classList.remove("show");
        }

        // Call the event from the Parent component through the props
        this.props.onAxeEdit(slideId, newValue);

        this.setState({ currentValue: newValue.Axe });
      }
    }
  }

  updateValue(slideId, value) {
    // Close Popover
    if(document.getElementById("popover-basic")) {
      document.getElementById("popover-basic").classList.remove("show");
    }
    
    // Call the event from the Parent component through the props
    this.props.onAxeEdit(slideId, value);
  }

  render() {
    const { language, staticData, itemId, itemType, slideId, axeId, currentValue, values, search } = this.state;

    return (
      <div className="axeEditPopover">
        {/* Search */}
        <div className="axeEditSearch">
          <Form.Group className="searchBlock">
            <Form.Control type="text" id="searchFilters" name="search" value={search} placeholder={Traduction.translate(language, 'search')} autoFocus onChange={this.searchItem} onKeyPress={this.pressKey} ref={this.search}/>
          </Form.Group>
        </div>
        {/* Values */}
        <div className="axeEditValues scrollbar-y">
          {/* Current Value */}
          {currentValue && <div className="axeCurrentValue">
            <div className="axe bordered blue">{currentValue}</div>
            {/* <Axe Axe={columnName} Value={currentValue} View={'Popover'}></Axe> */}
          </div>}
          {/* Border */}
          <div className="popoverBorder"></div>
          {/* Other Values */}
          {values.map((value, index) => {
            if(value !== currentValue) {
              if(search === "" || value.Axe.toLowerCase().includes(search.toLowerCase())) {
                return <div key={index} className="axeEditValue" onClick={() => this.updateValue(slideId, value)}>
                  <div className="axe bordered blue">{value.Axe}</div>
                  {/* <Axe Axe={columnName} Value={value} View={'Popover'}></Axe> */}
                </div>;
              }
            }
          })}
        </div>
      </div>
    );
  }
}

export default PopoverLoadAxes;