import React, {Component} from 'react';
import MetaTags from 'react-meta-tags';
import { Button, Form } from 'react-bootstrap';
import queryString from 'query-string';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';
import Navbar from './Navbar';
import BlockTitle from './BlockTitle';
import FiltersView from './FiltersView';
import FiltersCurrentView from './FiltersCurrentView';
import FiltersLevel from './FiltersLevel';
import FiltersBlock from './FiltersBlock';
import FiltersSort from './FiltersSort';
import FiltersConditionalFormatting from './FiltersConditionalFormatting';
import FiltersKanban from './FiltersKanban';
import FiltersChart from './FiltersChart';
import FiltersColumnChooser from './FiltersColumnChooser';
import FiltersImport from './FiltersImport';
import FiltersExport from './FiltersExport';
import LoadingSpinner from './LoadingSpinner';
import ErrorModification from './ErrorModification';
import PopupConfirmation from './PopupConfirmation';
import PopupInformation from './PopupInformation';
import Tree from './Tree';
import Table from './Table';
import Kanban from './Kanban';
import Pivot from './Pivot';
import Chart from './Chart';

const API_info = '/WebAppService/GetCardBlockInformation';
const API_templates = '/WebAppService/GetTemplates';
const API_valid = '/WebAppService/GetPropagationsAndSaveModification';
const API_create = '/WebAppService/InsertItemAndSave';
const API_delete = '/WebAppService/DeleteItemAndSave';
const API_cut_paste = '/WebAppService/MoveItem';

class EnvironmentRoadmap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: null,
      authId: null,
      language: null,
      itemId: null,
      itemType: null,
      itemTitle: null,
      blockType: null,
      blockInfo: {},
      blockContent: {},
      editable: null,
      guestLicence: null,
      levels: [],
      displayViews: null,
      views: [],
      defaultViewId: null,
      currentView: {},
      tables: [],
      columns: [],
      rows: [],
      chart: {},
      parentId: null,
      addBLPopup: false,
      addProjectPopup: false,
      availableBLTemplates: [],
      availableProjectTemplates: [],
      newBLTemplate: null,
      newProjectTemplate: null,
      templateSelected: {},
      confirm: false,
      deleteId: null,
      deleteType: null,
      deleteMessage: null,
      info: false,
      informationMessage: null,
      active: true,
      nbActive: 0,
      nbInactive: 0,
      search: '',
      isLoading: false,
      errors: []
    };
    
    // Data Structure
    this.getCardInformation = this.getCardInformation.bind(this);
    this.getCardData = this.getCardData.bind(this);
    this.convertStringtoBoolean = this.convertStringtoBoolean.bind(this);
    this.getColumnsName = this.getColumnsName.bind(this);
    this.getAvailableTemplates = this.getAvailableTemplates.bind(this);

    // Actions
    this.displayViews = this.displayViews.bind(this);
    this.hideViews = this.hideViews.bind(this);
    this.changeActive = this.changeActive.bind(this);
    this.changeView = this.changeView.bind(this);
    this.setDefaultView = this.setDefaultView.bind(this);
    this.changeLevel = this.changeLevel.bind(this);
    this.changeFilters = this.changeFilters.bind(this);
    this.changeSort = this.changeSort.bind(this);
    this.changeFormattings = this.changeFormattings.bind(this);
    this.changeAxe = this.changeAxe.bind(this);
    this.changeKeyField = this.changeKeyField.bind(this);
    this.changeColumns = this.changeColumns.bind(this);
    this.displayChartSettings = this.displayChartSettings.bind(this);
    this.closeChartSettings = this.closeChartSettings.bind(this);
    this.applyChartSettings = this.applyChartSettings.bind(this);
    this.filterColumn = this.filterColumn.bind(this);
    this.sortColumn = this.sortColumn.bind(this);
    this.checkModification = this.checkModification.bind(this);
    this.createItem = this.createItem.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
    this.cancelDelete = this.cancelDelete.bind(this);
    this.cutPasteItem = this.cutPasteItem.bind(this);
    this.updateRowsInTable = this.updateRowsInTable.bind(this);
    this.applyPropagations = this.applyPropagations.bind(this);
    this.addRowsInTable = this.addRowsInTable.bind(this);
    this.deleteRowInTable = this.deleteRowInTable.bind(this);
    this.getChildToDelete = this.getChildToDelete.bind(this);
    this.exportCSV = this.exportCSV.bind(this);
    this.exportXLSX = this.exportXLSX.bind(this);
    this.searchItem = this.searchItem.bind(this);
    this.updateView = this.updateView.bind(this);
    // this.updateTable = this.updateTable.bind(this);
    this.validateMessage = this.validateMessage.bind(this);
    this.updateErrors = this.updateErrors.bind(this);
    this.cleanErrors = this.cleanErrors.bind(this);

    // Template Functions
    // this.triggerAddBLPopup = this.triggerAddBLPopup.bind(this);
    // this.triggerAddProjectPopup = this.triggerAddProjectPopup.bind(this);
    this.templateAddBLPopup = this.templateAddBLPopup.bind(this);
    this.templateAddProjectPopup = this.templateAddProjectPopup.bind(this);
    this.closePopup = this.closePopup.bind(this);

    // Reference
    this.views = React.createRef();
    this.filters = React.createRef();
    this.sort = React.createRef();
    this.formattings = React.createRef();
    this.table = React.createRef();
    this.tree = React.createRef();
    this.levels = React.createRef();
    this.kanban = React.createRef();
    this.pivot = React.createRef();
    this.chart = React.createRef();
  }

  async componentDidMount() {
    const login = Authentication.getCookie('login');
    const authId = Authentication.getCookie('authId');
    const language = Authentication.getCookie('language');
    const itemId = 0;
    const itemType = 'Environment';
    const path = this.props.match.path.split('/');
    const blockType = path[path.length-1];
    let displayViews;

    if(Authentication.getCookie('displayViews')) {
      displayViews = JSON.parse(Authentication.getCookie('displayViews'));
    }
    else {
      displayViews = true;
    }

    // Get Params from Query string url
    let params, viewId, active = true;

    if(this.props.location.search) {
      params = queryString.parse(this.props.location.search);

      if(params.viewId) {
        viewId = parseInt(params.viewId);
      }
      
      active = this.convertStringtoBoolean(params.active);
    }

    this.setState({ login, authId, language, itemId, itemType, blockType, displayViews });

    // Get Card Information
    await this.getCardInformation(login, authId, itemId, itemType, blockType, viewId, active);

    const blockInfo = this.state.blockInfo;
    const views = this.state.views;
    let currentView = {};

    if(Object.entries(blockInfo).length > 0) {
      // Get Current View
      if(viewId && views.find(view => view.ViewId === viewId)) {
        currentView = views.find(view => view.ViewId === viewId);
      }
      else if(views.find(view => view.ViewId === blockInfo.DefaultViewId)) {
        currentView = views.find(view => view.ViewId === blockInfo.DefaultViewId);
      }

      if(!viewId && (currentView.ViewId && viewId !== currentView.ViewId)) {
        // Redirect with new Query params
        this.props.history.push(`/Environment/${blockType}?viewId=${currentView.ViewId}&active=${active}`);
      }
      else {
        // Get Card Data
        await this.getCardData(login, authId, itemId, itemType, blockType, currentView, active);
      }
    }
    else {
      // Redirect to Login Page
      this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
    }
  }

  async componentDidUpdate(prevProps) {
    const login = Authentication.getCookie('login');
    const authId = Authentication.getCookie('authId');
    const itemId = 0;
    const itemType = 'Environment';
    const path = this.props.match.path.split('/');
    const blockType = path[path.length-1];

    // Get Params from Query string url
    let params, old_params, viewId, oldViewId, active, oldActive;

    if(this.props.location.search) {
      params = queryString.parse(this.props.location.search);

      if(params.viewId) {
        viewId = parseInt(params.viewId);
      }
      
      active = this.convertStringtoBoolean(params.active);
    }
    if(prevProps.location.search) {
      old_params = queryString.parse(prevProps.location.search);

      if(old_params.viewId) {
        oldViewId = parseInt(old_params.viewId);
      }
      
      oldActive = this.convertStringtoBoolean(old_params.active);
    }

    if(viewId !== oldViewId || active !== oldActive) {
      const blockInfo = this.state.blockInfo;
      const views = this.state.views;
      let currentView = {};

      // Get Current View
      if(viewId && views.find(view => view.ViewId === viewId)) {
        currentView = views.find(view => view.ViewId === viewId);
      }
      else if(views.find(view => view.ViewId === blockInfo.DefaultViewId)) {
        currentView = views.find(view => view.ViewId === blockInfo.DefaultViewId);
      }

      // Get Card Data
      await this.getCardData(login, authId, itemId, itemType, blockType, currentView, active);

      // Refresh Filters View
      // if(this.views.current) {
      //   this.views.current.selectView(currentView);
      // }

      const blockContent = this.state.blockContent;
      const columns = this.state.columns;

      // Refresh Components with CurrentView
      if(currentView.ViewType === 0 && this.table.current) {
        this.table.current.refreshColumns(currentView);
      }
      else if(currentView.ViewType === 0 && this.tree.current) {
        this.tree.current.refreshColumns(currentView);
      }
      else if(currentView.ViewType === 2 && this.kanban.current) {
        this.kanban.current.refreshKanban(currentView, blockContent.Kanban, columns);
      }
      else if(currentView.ViewType === 3 && this.pivot.current) {
        this.pivot.current.refreshPivot(currentView);
      }
      else if(currentView.ViewType === 7 && this.chart.current) {
        this.chart.current.applySettings(currentView);
      }
    }
  }

  // Get Block Information from the API
  async getCardInformation(login, authId, itemId, itemType, blockType, viewId, active) {
    const language = this.state.language;

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': itemType,
        'ItemId': itemId,
        'BlockType': blockType,
        'ViewId': viewId,
        'WithData': false,
        'WithInformation': true,
        'InactiveData': false
      })
    };

    try{
      const response = await fetch(API_info, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const blockInfo = data.GetCardBlockInformationResult;

      if(blockInfo) {
        const itemTitle = blockInfo.ObjectName;
        const editable = blockInfo.InsertDeleteAllowed;
        const guestLicence = blockInfo.GuestLicence;
        const levels = blockInfo.AvailableLevels;
        const views = blockInfo.Views;
        const defaultViewId = blockInfo.DefaultViewId;
        let currentView = {};

        // Get Current View
        if(viewId && views.find(view => view.ViewId === viewId)) {
          currentView = views.find(view => view.ViewId === viewId);
        }
        else if(views.find(view => view.ViewId === blockInfo.DefaultViewId)) {
          currentView = views.find(view => view.ViewId === blockInfo.DefaultViewId);
        }

        this.setState({ blockInfo, itemTitle, editable, guestLicence, levels, views, defaultViewId, viewId, currentView, active });
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  // Get Block Data from the API
  async getCardData(login, authId, itemId, itemType, blockType, currentView, active) {
    const language = this.state.language;
    let additionalContext = [{ Key: "isInactive", Value: !active }];

    this.setState({ isLoading: true });

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': itemType,
        'ItemId': itemId,
        'BlockType': blockType,
        'View': currentView,
        'WithData': true,
        'WithInformation': false,
        'InactiveData': false,
        'AdditionalContext': additionalContext
      })
    };

    try{
      const response = await fetch(API_info, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const blockContent = data.GetCardBlockInformationResult;

      if(blockContent) {
        const headers = blockContent.Headers;
        const tables = blockContent.Tables;
        const chart = blockContent.Chart;
        let columns = [], rows = [];

        // Get Tables, Rows & Columns
        if(tables.find(element => element.Level === "Tree")) {
          columns = tables.find(element => element.Level === "Tree").ColumnHeaders;
          rows = tables.find(element => element.Level === "Tree").Rows;
        }

        const nbActive = blockContent.ActiveItems;
        const nbInactive = blockContent.InactiveItems;

        this.setState({ blockContent, viewId: currentView.ViewId, currentView, headers, tables, columns, rows, chart, active, nbActive, nbInactive, isLoading: false });
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  convertStringtoBoolean(string) {
    if(string === 'true') {
      return true;
    }
    else {
      return false;
    }
  }

  getColumnsName() {
    let columns = this.state.columns;
    let columnNames = [];

    columns.forEach(column => {
      columnNames.push(column.FieldName);
    });

    return columnNames;
  }

  async getAvailableTemplates(itemType) {
    const login = this.state.login;
    const authId = this.state.authId;
    const language = this.state.language;
    
    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': itemType
      })
    };

    try{
      const response = await fetch(API_templates, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const result = data.GetTemplatesResult;

      if(result) {
        if(itemType === 'Business_Line') {
          this.setState({ availableBLTemplates: result });
        }
        else if(itemType === 'Project') {
          this.setState({ availableProjectTemplates: result });
        }
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  displayViews() {
    Authentication.deleteCookie('displayViews');
    Authentication.createCookie('displayViews', true);

    this.setState({ displayViews: true });
  }

  hideViews() {
    Authentication.deleteCookie('displayViews');
    Authentication.createCookie('displayViews', false);

    this.setState({ displayViews: false });
  }

  changeActive(i) {
    const blockType = this.state.blockType;
    const viewId = this.state.viewId;
    const active = this.state.active;

    if((!active && i === 0) || (active && i === 1)) {
      // Redirect with new Query params
      this.props.history.push(`/Environment/${blockType}?viewId=${viewId}&active=${!active}`);

      // this.setState({ active: !active });
    }
  }

  changeView(view) {
    const blockType = this.state.blockType;
    const active = this.state.active;

    // Redirect with new Query params
    this.props.history.push(`/Environment/${blockType}?viewId=${view.ViewId}&active=${active}`);
  }

  setDefaultView(viewId) {
    this.setState({ defaultViewId: viewId });
  }

  async changeLevel(value) {
    const login = this.state.login;
    const authId = this.state.authId;
    const itemId = this.state.itemId;
    const itemType = this.state.itemType;
    const blockType = this.state.blockType;
    // const rows = this.state.rows;
    const currentView = this.state.currentView;
    const active = this.state.active;
    let level;

    switch(value) {
      case "Tree":
          level = 0;
          break;
      case "Business_Line":
          level = 1;
          break;
      case "Project":
          level = 2;
          break;
      case "Workpackage":
          level = 3;
          break;
      case "Action":
          level = 4;
          break;
      case "Task":
          level = 5;
          break;
      default:
          break;
    }

    // Update Current View Level
    currentView.DefaultLevel = level;

    // Add Modify attribute in CurrentView
    currentView["Modify"] = true;

    // Get Card Data
    await this.getCardData(login, authId, itemId, itemType, blockType, currentView, active);

    // Add/Remove Column from Table/Tree/Gantt
    if(this.table.current) {
      this.table.current.refreshColumns(currentView);
    }
    else if(this.tree.current) {
      this.tree.current.refreshColumns(currentView);
    }
    else if(this.chart.current) {
      this.chart.current.applySettings(currentView);
    }

    // this.setState({ currentView });
  }

  async changeFilters(currentView) {
    const login = this.state.login;
    const authId = this.state.authId;
    const itemId = this.state.itemId;
    const itemType = this.state.itemType;
    const blockType = this.state.blockType;
    const active = this.state.active;

    // Add Modify attribute in CurrentView
    currentView["Modify"] = true;

    // Get Card Data
    await this.getCardData(login, authId, itemId, itemType, blockType, currentView, active);

    // Refresh Component Columns
    if(this.table.current) {
      this.table.current.refreshColumns(currentView);
    }
    else if(this.tree.current) {
      this.tree.current.refreshColumns(currentView);
    }
  }

  async changeSort(currentView) {
    const login = this.state.login;
    const authId = this.state.authId;
    const itemId = this.state.itemId;
    const itemType = this.state.itemType;
    const blockType = this.state.blockType;
    const active = this.state.active;

    // Add Modify attribute in CurrentView
    currentView["Modify"] = true;

    // Get Card Data
    await this.getCardData(login, authId, itemId, itemType, blockType, currentView, active);

    // Refresh Component Columns
    if(this.table.current) {
      this.table.current.refreshColumns(currentView);
    }
    else if(this.tree.current) {
      this.tree.current.refreshColumns(currentView);
    }
  }

  async changeFormattings(currentView) {
    const login = this.state.login;
    const authId = this.state.authId;
    const itemId = this.state.itemId;
    const itemType = this.state.itemType;
    const blockType = this.state.blockType;

    // Add Modify attribute in CurrentView
    currentView["Modify"] = true;

    // Get Card Data
    await this.getCardData(login, authId, itemId, itemType, blockType, currentView);

    // Refresh Component Columns
    if(this.table.current) {
      this.table.current.refreshColumns(currentView);
    }
    else if(this.tree.current) {
      this.tree.current.refreshColumns(currentView);
    }
  }

  async changeAxe(axe) {
    const login = this.state.login;
    const authId = this.state.authId;
    const itemId = this.state.itemId;
    const itemType = this.state.itemType;
    const blockType = this.state.blockType;
    const currentView = this.state.currentView;
    const active = this.state.active;

    // Update Current View Axe
    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Axe1')) {
      currentView.Parameters.find(param => param.Name === 'Axe1').Value = axe;
    }

    // Add Modify attribute in CurrentView
    currentView["Modify"] = true;

    // Get Card Data
    await this.getCardData(login, authId, itemId, itemType, blockType, currentView, active);

    // Update Axe in Kanban component
    // if(this.kanban.current) {
    //   this.kanban.current.updateAxe(axe);
    // }

    // this.setState({ currentView });
  }

  async changeKeyField(keyField) {
    const login = this.state.login;
    const authId = this.state.authId;
    const itemId = this.state.itemId;
    const itemType = this.state.itemType;
    const blockType = this.state.blockType;
    const currentView = this.state.currentView;
    const active = this.state.active;

    // Update Current View KeyField
    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Axe2')) {
      currentView.Parameters.find(param => param.Name === 'Axe2').Value = keyField;
    }

    // Add Modify attribute in CurrentView
    currentView["Modify"] = true;

    // Get Card Data
    await this.getCardData(login, authId, itemId, itemType, blockType, currentView, active);

    // Update Axe in Kanban component
    // if(this.kanban.current) {
    //   this.kanban.current.updateKeyField(keyField);
    // }

    // this.setState({ currentView });
  }

  async changeColumns(columns) {
    const login = this.state.login;
    const authId = this.state.authId;
    const itemId = this.state.itemId;
    const itemType = this.state.itemType;
    const blockType = this.state.blockType;
    // const rows = this.state.rows;
    const currentView = this.state.currentView;
    const active = this.state.active;

    // Update Current View Columns list
    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Columns')) {
      currentView.Parameters.find(param => param.Name === 'Columns').Value = columns;
    }

    // Add Modify attribute in CurrentView
    currentView["Modify"] = true;

    // Get Card Data
    await this.getCardData(login, authId, itemId, itemType, blockType, currentView, active);

    // Add/Remove Column from Table/Tree
    if(this.table.current) {
      this.table.current.refreshColumns(currentView);
    }
    else if(this.tree.current) {
      this.tree.current.refreshColumns(currentView);
    }

    // this.setState({ currentView });
  }

  displayChartSettings() {
    if(this.chart.current) {
      this.chart.current.displaySettings();
    }
  }

  closeChartSettings() {
    if(this.chart.current) {
      this.chart.current.closeSettings();
    }
  }

  async applyChartSettings(currentView) {
    const login = this.state.login;
    const authId = this.state.authId;
    const itemId = this.state.itemId;
    const itemType = this.state.itemType;
    const blockType = this.state.blockType;

    // Add Modify attribute in CurrentView
    currentView["Modify"] = true;

    // Get Card Data
    await this.getCardData(login, authId, itemId, itemType, blockType, currentView);

    // Apply Setting on Chart and Update CurrentView
    if(this.chart.current) {
      this.chart.current.applySettings(currentView);
    }

    // this.setState({ currentView });
  }

  filterColumn(columnName) {
    if(this.filters.current) {
      this.filters.current.openFiltersPopup(columnName);
    }
  }

  sortColumn(columnName) {
    if(this.sort.current) {
      this.sort.current.openSortPopup(columnName);
    }
  }

  async checkModification(itemId, itemType, columnName, oldValue, newValue) {
    const login = this.state.login;
    const authId = this.state.authId;
    const language = this.state.language;
    let additionalContext = [];

    if(columnName === 'Active') {
      this.setState({ isLoading: true });
    }
    
    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': itemType,
        'ItemId': itemId,
        'ColumnName': columnName,
        'OldValue': oldValue,
        'NewValue': newValue,
        'AdditionalContext': additionalContext
      })
    };

    try{
      const response = await fetch(API_valid, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const results = data.GetPropagationsAndSaveModificationResult;
      
      // Check request response
      if(results) {
        // For Each Modification
        results.forEach(result => {
          // Get Modifications, Propagations & Warnings
          let modification = result.Modification;
          let propagations = result.Propagations;
          let warnings = result.Warnings;
          
          // If the Modification is valid
          if(result.IsValid === true) {
            // Update Rows in Components DataSource for Active column
            if(columnName === 'Active') {
              let nbActive = this.state.nbActive;
              let nbInactive = this.state.nbInactive;

              if(this.table.current) {
                this.table.current.updateDatasource(modification, propagations, warnings);
                this.table.current.deleteRowInDatasource(modification, propagations);
              }
              else if(this.tree.current) {
                this.tree.current.updateDatasource(modification, propagations, warnings);
                this.tree.current.deleteRowInDatasource(modification, propagations);
              }
              else if(this.kanban.current) {
                // this.kanban.current.updateDatasource(modification, propagations, warnings);
                // this.table.current.removeFromDatasource(modification, propagations, warnings);
              }

              // Display confirmation message that BL/Project has been activated/desactivated
              if(modification.ItemType === 'Business_Line') {
                if(newValue === 'True') {
                  this.setState({ info: true, informationMessage: Traduction.translate(language, 'business_line_activated') });
                }
                else {
                  this.setState({ info: true, informationMessage: Traduction.translate(language, 'business_line_desactivated') });
                }
              }
              else if(modification.ItemType === 'Project') {
                if(newValue === 'True') {
                  this.setState({ info: true, informationMessage: Traduction.translate(language, 'project_activated') });
                }
                else {
                  this.setState({ info: true, informationMessage: Traduction.translate(language, 'project_desactivated') });
                }
              }

              // Update Active & Inactive Objects counter
              if(newValue === 'True') {
                this.setState({ nbActive: nbActive+1, nbInactive: nbInactive-1 });
              }
              else {
                this.setState({ nbActive: nbActive-1, nbInactive: nbInactive+1 });
              }

              // Remove row in data table
              this.deleteRowInTable(modification, propagations);
            }
            else {
              // Update Rows in Components DataSource for others columns
              if(this.table.current) {
                this.table.current.updateDatasource(modification, propagations, warnings);
              }
              else if(this.tree.current) {
                this.tree.current.updateDatasource(modification, propagations, warnings);
              }
              else if(this.kanban.current) {
                this.kanban.current.updateDatasource(modification, propagations, warnings);
              }
            }

            // Update Rows in Data Table
            this.updateRowsInTable(modification, propagations, warnings);
          }
          // If the Modification is not valid
          else {
            // Update Datasource with OldValue
            if(this.table.current) {
              this.table.current.cancelModification(modification);
            }
            else if(this.tree.current) {
              this.tree.current.cancelModification(modification);
            }
            else if(this.kanban.current) {
              // this.kanban.current.cancelModification(modification);
            }

            this.updateErrors(result.Errors);
          }
        });

        this.setState({ isLoading: false });
      }
      else {
        this.updateErrors(Traduction.translate(language, 'modification_impossible'));
      }

    } catch(error) {
      console.log(error);
    }
  }

  async createItem(name, itemType, templateId, sameParent) {
    const login = this.state.login;
    const authId = this.state.authId;
    const language = this.state.language;
    const currentView = this.state.currentView;
    let parentType = this.state.itemType;
    let parentId = this.state.itemId;
    let columns = this.getColumnsName();
    let additionalContext = [];
    let selectedRow;

    this.setState({ isLoading: true });

    // Additional Context for Creation with Template
    if(templateId) {
      additionalContext = [{"Key": "FromId", "Value": templateId}];
    }

    // Get Parent Type & Id for Tree
    if(currentView.DefaultLevel === 0) {
      // Get Selected Row from Treegrid
      if(this.tree.current) {
        selectedRow = this.tree.current.getSelectedRow();
      }

      // One Row is selected
      if(selectedRow && selectedRow.length > 0) {
        parentType = selectedRow[0].Item_Type;
        parentId = selectedRow[0].Item_ID.substring(1);

        // if(!templateId) {
        //   itemType = this.getChildType(parentType);
        // }
      }
      // else {
      //   itemType = this.getChildType(parentType);
      // }
    }

    // Specific case for Duplicate
    if(templateId && sameParent) {
      parentType = '';
      parentId = 0;
    }

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': itemType,
        'ParentType': parentType,
        'ParentId': parentId,
        'Name': name,
        'NbItems': '1',
        'Fields': columns,
        'AdditionalContext': additionalContext
      })
    };
    
    try{
      const response = await fetch(API_create, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const result = data.InsertItemAndSaveResult;
      // let rows = this.state.rows;
      let nbActive = this.state.nbActive;

      // Check request response
      if(result) {
        // Get new Rows & Propagations
        const newRows = result.InsertedItems.Rows;
        const propagations = result.Propagations;

        // If the Modification is Valid
        if(result.IsValid === true) {
          let itemId;

          if(result.InsertedItems.Rows[0].Cells.find(element => element.ColumnName === 'Item_ID')) {
            itemId = result.InsertedItems.Rows[0].Cells.find(element => element.ColumnName === 'Item_ID').Value;
          }

          // Update Counter and reset Template
          if(itemType === 'Business_Line') {
            this.setState({ nbActive: nbActive + 1, newBLTemplate: null, templateSelected: {} });
          }
          else if(itemType === 'Project') {
            this.setState({ newProjectTemplate: null, templateSelected: {} });
          }

          // Redirect to Object Details Page
          this.props.history.push(`/Card/${itemType}/${itemId.substring(1)}/Details`);

          // Add Rows in Components DataSource
          // if(this.table.current) {
          //   this.table.current.addRowsInDatasource(newRows, propagations);
          // }
          // else if(this.tree.current) {
          //   this.tree.current.addRowsInDatasource(newRows, propagations);
          // }
          // else if(this.kanban.current) {
          //   this.kanban.current.addRowsInDatasource(newRows);
          // }

          // // Add Rows in Data Table
          // this.addRowsInTable(newRows, propagations);
        }
        else {
          this.updateErrors(result.Errors);
        }
      }
      else {
        this.updateErrors(Traduction.translate(language, 'creation_impossible'));
      }

      // Reset variables & Close Popup
      this.setState({ addProjectPopup: false, newProjectTemplate: null, templateSelected: {}, isLoading: false });

    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  async deleteItem(itemId, itemType, isForced) {
    const login = this.state.login;
    const authId = this.state.authId;
    const language = this.state.language;

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemId': itemId,
        'ItemType': itemType,
        'IsForced': isForced
      })
    };

    try{
      const response = await fetch(API_delete, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const result = data.DeleteItemAndSaveResult;

      // Check request response
      if(result) {
        // Get Delete & Propagations
        const modification = result.Delete;
        const propagations = result.Propagations;
  
        // If the Modification is Valid
        if(result.IsValid === true) {
          // Delete Row in Components DataSource
          if(this.table.current) {
            this.table.current.deleteRowInDatasource(modification);
          }
          else if(this.tree.current) {
            this.tree.current.deleteRowInDatasource(modification, propagations);
          }
          else if(this.kanban.current) {
            // this.kanban.current.deleteRowInDatasource(modification);
          }

          // Delete Row in Data Table
          this.deleteRowInTable(modification, propagations);
        }
        else {
          if(result.ErrorCode === 101 || result.ErrorCode === 102) {
            this.setState({ confirm: true, deleteId: itemId, deleteType: itemType, deleteMessage: result.Errors[0] });
          }
          else {
            // Update Errors
            this.updateErrors(result.Errors);
          }
        }
      }
      else {
        this.updateErrors(Traduction.translate(language, 'deletion_impossible'));
      }

    } catch(error) {
      console.log(error);
    }
  }

  confirmDelete() {
    const deleteId = this.state.deleteId;
    const deleteType = this.state.deleteType;

    this.deleteItem(deleteId, deleteType, true);
    
    this.setState({ confirm: false });
  }

  cancelDelete() {
    this.setState({ confirm: false });
  }

  async cutPasteItem(itemType, itemId, destinationItemType, destinationItemId) {
    const login = this.state.login;
    const authId = this.state.authId;
    const language = this.state.language;
    const cardItemId = this.state.itemId;
    const cardItemType = this.state.itemType;
    const blockType = this.state.blockType;
    const currentView = this.state.currentView;
    const active = this.state.active;

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': itemType,
        'ItemId': itemId,
        'DestinationItemType': destinationItemType,
        'DestinationItemId': destinationItemId
      })
    };

    try{
      const response = await fetch(API_cut_paste, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const result = data.MoveItemResult;

      // Check request response
      if(result) {
        // Get Move, Propagations & Warnings
        const move = result.Move;
        const propagations = result.Propagations;
        const warnings = result.Warnings;
  
        // If the Modification is Valid
        if(result.IsValid === true) {
          // const rows = this.state.rows;
          // let updatedRows;

          // Build Updated Rows

          // Create Modification object
          // let modification = { AdditionalContext: [], AuthId: authId, ColumnName: "Parent_ID", ItemId: itemId, ItemType: itemType, NewValue: destinationItemId, OldValue: "", UserId: userId };

          // Update dataSource in Tree
          // if(this.tree.current) {
          //   this.tree.current.addRowsInDatasource(updatedRows, propagations);
          //   this.tree.current.deleteRowInDatasource(modification, propagations);
          // }

          // // Update Rows in Data Table
          // this.updateRowsInTable(modification, propagations, warnings);

          this.getCardData(login, authId, cardItemId, cardItemType, blockType, currentView, active);
        }
        else {
          // Update Errors
          this.updateErrors(result.Errors);
        }
      }
      else {
        this.updateErrors(Traduction.translate(language, 'deletion_impossible'));
      }

    } catch(error) {
      console.log(error);
    }
  }

  updateRowsInTable(modification, propagations, warnings) {
    let rows = this.state.rows;
    const modificationId = modification.ItemType.substring(0,1).concat(modification.ItemId);

    // Check if we find Row to apply Modification
    const currentRow = rows.find(row => row.Cells.find(cell => cell.ColumnName === 'Item_ID').Value === modificationId);

    if(currentRow) {
      // Check if we find Cell to apply Modification
      const currentCell = currentRow.Cells.find(cell => cell.ColumnName === modification.ColumnName);

      // Update Row Cell with NewValue
      if(currentCell) {
        currentCell.Value = modification.NewValue;
      }

      // Check if we find Warning Message Column
      const currentWarning = currentRow.Cells.find(cell => cell.ColumnName === 'WarningMessage');

      // Update Warnings
      if(currentWarning) {
        if(warnings.length > 0) {
          currentWarning.Value = warnings.join(' | ');
        }
        else {
          currentWarning.Value = '';
        }
      }
    }

    // Apply Propagations in Rows
    this.applyPropagations(rows, propagations);

    this.setState({ rows });
  }

  applyPropagations(rows, propagations) {
    // Update Row with Propagations NewValue
    propagations.forEach(propagation => {
      const propagationId = propagation.ToItemType.substring(0,1).concat(propagation.ToItemId);

      // Check if we find Row to apply Propagation
      const currentRow = rows.find(row => row.Cells.find(cell => cell.ColumnName === 'Item_ID').Value === propagationId);

      // Find Row to apply Propagation and Update with new Value
      if(currentRow) {
        // Check if we find Cell to apply Modification
        const currentCell = currentRow.Cells.find(cell => cell.ColumnName === propagation.ColumnName);

        // Update Row Cell with Propagation
        if(currentCell) {
          currentCell.Value = propagation.NewValue;
        }
      }
    });
  }

  addRowsInTable(newRows, propagations) {
    let rows = this.state.rows;

    // Push new Rows
    newRows.forEach(row => {
      rows.push(row);
    });

    // Apply Propagations in Rows
    this.applyPropagations(rows, propagations);

    // Refresh Buttons Level Indicators
    if(this.levels.current) {
      this.levels.current.refreshIndicators(rows);
    }

    this.setState({ rows });
  }

  // deleteRowInTable(modification, propagations) {
  //   let rows = this.state.rows;
  //   const deleteId = modification.ItemType.substring(0,1).concat(modification.ItemId);

  //   // Check if we find Row to Delete
  //   const indexToDelete = rows.findIndex(row => row.Cells.find(cell => cell.ColumnName === 'Item_ID').Value === deleteId);

  //   // Delete Row
  //   if(indexToDelete >= 0) {
  //     rows.splice(indexToDelete, 1);
  //   }

  //   // Apply Propagations in Rows
  //   this.applyPropagations(rows, propagations);

  //   // Refresh Buttons Level Indicators
  //   if(this.levels.current) {
  //     this.levels.current.refreshIndicators(rows);
  //   }

  //   this.setState({ rows });
  // }

  deleteRowInTable(modification, propagations) {
    let rows = this.state.rows;
    const deleteId = modification.ItemType.substring(0,1).concat(modification.ItemId);
    let idToDelete = [];

    // Get list of Index to delete
    idToDelete = this.getChildToDelete(rows, deleteId);

    idToDelete.forEach(id => {
      // Get corresponding Row Index
      let rowIndex = rows.findIndex(row => row.Cells.find(cell => cell.ColumnName === 'Item_ID').Value === id);

      // Delete Row in the Datasource
      if(rowIndex >= 0) {
        rows.splice(rowIndex, 1);
      }
    });

    // Apply Propagations in Rows
    this.applyPropagations(rows, propagations);

    // Refresh Buttons Level Indicators
    if(this.levels.current) {
      this.levels.current.refreshIndicators(rows);
    }

    this.setState({ rows });
  }

  getChildToDelete(rows, deleteId) {
    let idToDelete = [];
    let itemId;

    // Push Index of element to delete
    idToDelete.push(deleteId);

    // Find child of the element
    rows.filter(row => row.Cells.find(cell => cell.ColumnName === 'Parent_ID') && row.Cells.find(cell => cell.ColumnName === 'Parent_ID').Value === deleteId).forEach(row => {
      if(row.Cells.find(cell => cell.ColumnName === 'Item_ID') && row.Cells.find(cell => cell.ColumnName === 'Item_Type')) {
        // Get Row ItemId
        itemId = row.Cells.find(cell => cell.ColumnName === 'Item_ID').Value;

        // Call recursive function to get child ItemId
        idToDelete.push(this.getChildToDelete(rows, itemId));
      }
    });

    return idToDelete.flat();
  }

  exportCSV() {
    // Export Data from Table component
    if(this.table.current) {
      this.table.current.exportCSV();
    }
    // Export Data from Tree component
    if(this.tree.current) {
      this.tree.current.exportCSV();
    }
  }

  exportXLSX() {
    // Export Data from Table component
    if(this.table.current) {
      this.table.current.exportXLSX();
    }
    // Export Data from Tree component
    if(this.tree.current) {
      this.tree.current.exportXLSX();
    }
  }

  searchItem(event) {
    const search = event.target.value;

    // Search Items in Table component
    if(this.table.current) {
      this.table.current.searchItem(search);
    }
    else if(this.tree.current) {
      this.tree.current.searchItem(search);
    }
    // Search Items in Kanban component
    if(this.kanban.current) {
      this.kanban.current.searchItem(search);
    }

    this.setState({ search });
  }

  updateView(view) {
    this.setState({ currentView: view });
  }

  // updateTable(rows) {
  //   // Refresh Buttons Level Indicators
  //   if(this.levels.current) {
  //     this.levels.current.refreshIndicators(rows);
  //   }    
  // }

  validateMessage() {
    this.setState({ info: false });
  }

  updateErrors(err) {
    let errors = [];

    // Push the new Errors in the Errors Table
    errors.push(err);

    this.setState({ errors });
  }

  cleanErrors() {
    this.setState({ errors: [] });
  }

  // triggerAddBLPopup(parentId) {
  //   this.setState({ parentId, addBLPopup: true });
  // }

  // triggerAddProjectPopup(parentId) {
  //   this.setState({ parentId, addProjectPopup: true });
  // }

  templateAddBLPopup() {
    const { language, parentId, newBLTemplate, availableBLTemplates, templateSelected, isLoading } = this.state;
    const itemType = 'Business_Line';

    return (<div className="popupAddProject">
      {/* Label */}
      {!newBLTemplate && <div className="addProjectLabel mb10">{Traduction.translate(language, 'create_new_business_line')}</div>}
      {/* Available Slides */}
      {!newBLTemplate && <div className="newProjectTemplates">
        <div className="newProjectEmptyTemplate" onClick={() => { this.setState({ newBLTemplate: 'empty' }); this.createItem('', itemType) }}>
          <div className="addProjectLabel mv10">{Traduction.translate(language, 'empty_business_line')}</div>
        </div>
        <div className="newProjectTemplate" onClick={() => { this.setState({ newBLTemplate: 'template' }); this.getAvailableTemplates(itemType) }}>
          <span className="iconNewProjetTemplate veryBigIcons" alt="Template"/>
          <div className="addProjectLabel mv10">{Traduction.translate(language, 'from_template')}</div>
        </div>
      </div>}
      {newBLTemplate === 'template' && <div className="flex flex-column popupProjectTemplate">
        {/* Label */}
        <div className="addProjectLabel mb10">{Traduction.translate(language, 'choose_business_line_template')}</div>
        {/* Available Templates */}
        <div className="flex flex-column scrollbar-y overflow-y mb10">
          {availableBLTemplates.map((template, index) => {
            return (<div key={index} className={(template.ItemID === templateSelected.ItemID ? "projectTemplateSelected" : "projectTemplate")} onClick={() => this.setState({ templateSelected: template })}>
              <div className="templateIconBlue"><span class="iconBusinessLineWhite verysmallIcons" alt="Project"></span></div>
              <div className="templateLabel">{template.Name}</div>
            </div>)
          })}
        </div>
      </div>}
      {/* Button Validate or Cancel */}
      <div className="flex flex-end align-items-center">
        {/* Spinner Loading */}
        {isLoading && <div className="mr20"><LoadingSpinner></LoadingSpinner></div>}
        {/* Buttons */}
        {!isLoading && newBLTemplate === 'template' && Object.keys(templateSelected).length !== 0 && 
          <Button className="fs12 bold brd-radius mr15" variant="primary" onClick={() => this.createItem('', itemType, templateSelected.ItemID)}>{Traduction.translate(language, 'validate')}</Button>
        }
        <Button className="fs12 bold brd-radius" variant="warning" onClick={() => { this.setState({ newBLTemplate: null, templateSelected: {} }); this.closePopup() }}>{Traduction.translate(language, 'cancel')}</Button>
      </div>
    </div>);
  }

  templateAddProjectPopup() {
    const { language, parentId, newProjectTemplate, availableProjectTemplates, templateSelected, isLoading } = this.state;
    const itemType = 'Project';

    return (<div className="popupAddProject">
      {/* Label */}
      {!newProjectTemplate && <div className="addProjectLabel mb10">{Traduction.translate(language, 'create_new_project')}</div>}
      {/* Available Slides */}
      {!newProjectTemplate && <div className="newProjectTemplates">
        <div className="newProjectEmptyTemplate" onClick={() => { this.setState({ newProjectTemplate: 'empty' }); this.createItem('', itemType) }}>
          <div className="addProjectLabel mv10">{Traduction.translate(language, 'empty_project')}</div>
        </div>
        <div className="newProjectTemplate" onClick={() => { this.setState({ newProjectTemplate: 'template' }); this.getAvailableTemplates(itemType) }}>
          <span className="iconNewProjetTemplate veryBigIcons" alt="Template"/>
          <div className="addProjectLabel mv10">{Traduction.translate(language, 'from_template')}</div>
        </div>
      </div>}
      {newProjectTemplate === 'template' && <div className="flex flex-column popupProjectTemplate">
        {/* Label */}
        <div className="addProjectLabel mb10">{Traduction.translate(language, 'choose_project_template')}</div>
        {/* Available Templates */}
        <div className="flex flex-column scrollbar-y overflow-y mb10">
          {availableProjectTemplates.map((template, index) => {
            return (<div key={index} className={(template.ItemID === templateSelected.ItemID ? "projectTemplateSelected" : "projectTemplate")} onClick={() => this.setState({ templateSelected: template })}>
              <div className="templateIconBlue"><span class="iconProjectWhite verysmallIcons" alt="Project"></span></div>
              <div className="templateLabel">{template.Name}</div>
            </div>)
          })}
        </div>
      </div>}
      {/* Button Validate or Cancel */}
      <div className="flex flex-end align-items-center">
        {/* Spinner Loading */}
        {isLoading && <div className="mr20"><LoadingSpinner></LoadingSpinner></div>}
        {/* Buttons */}
        {!isLoading && newProjectTemplate === 'template' && Object.keys(templateSelected).length !== 0 && 
          <Button className="fs12 bold brd-radius mr15" variant="primary" onClick={() => this.createItem('', itemType, templateSelected.ItemID)}>{Traduction.translate(language, 'validate')}</Button>
        }
        <Button className="fs12 bold brd-radius" variant="warning" onClick={() => { this.setState({ newProjectTemplate: null, templateSelected: {} }); this.closePopup() }}>{Traduction.translate(language, 'cancel')}</Button>
      </div>
    </div>);
  }

  closePopup() {
    this.setState({ addBLPopup: false, addProjectPopup: false });
  }

  render() {
    const { language, itemId, itemType, itemTitle, blockType, blockInfo, blockContent, editable, guestLicence, levels, displayViews, views, defaultViewId, currentView, tables, columns, rows, chart, addBLPopup, addProjectPopup, confirm, deleteMessage, info, informationMessage, active, nbActive, nbInactive, search, isLoading, errors } = this.state;
    let activeNumber, activeLabel, navName = [];

    // Set Navigation variables
    activeNumber = [nbActive, nbInactive];
    activeLabel = active ? "actif" : "inactif";

    navName.push({ Name: "actif", Label: Traduction.translate(language, 'item_active') });
    navName.push({ Name: "inactif", Label: Traduction.translate(language, 'item_inactive') });

    return (
      <div className="blockContainer">
        {/* Title */}
        <MetaTags><title>{Traduction.translate(language, 'environment_roadmap')}</title></MetaTags>

        {/* Navbar */}
        <Navbar Selected={this.props.match.url}></Navbar>

        {/* HasRightOnItem = false */}
        {blockInfo.HasRightOnItem === false && <div className="block">
          <div className="blockHeader">
            <div className="blockTitle">
              <div className="cardIcon"><div className="cardIconGrey"><span className="iconNoRights iconsCard"></span></div></div>
              <div className="flex"><span className="cardTitle">{Traduction.translate(language, 'no_sufficient_rights')}</span></div>
            </div>
          </div>
        </div>}

        {/* Block */}
        {blockInfo.HasRightOnItem === true && <div className="block">
          {/* Environment Block Header */}
          <div className="blockHeader">
            {/* Title */}
            <BlockTitle ItemId={itemId} ItemType={itemType} ItemTitle={itemTitle} BlockType={blockType} CurrentView={currentView} Blocks={blockInfo.BlockNames}></BlockTitle>
            {/* Border */}
            <div className="blockBorder"></div>
          </div>

          {/* Active/Inactive Navigation */}
          <div className="navigationActive">
            {navName && navName.map((block, i) => (
              <div key={i} className={(block.Name === activeLabel ? "navigationItemSelected" : "navigationItem")} onClick={() => this.changeActive(i)}>
                <div className="cursor">{activeNumber[i]} {block.Label}{activeNumber[i] > 1 ? 's': ''}</div>
              </div>
            ))}
          </div>

          {/* Environment Block Body */}
          <div className="blockBodyEnvironmentRoadmap">
            {/* Filters Views */}
            {displayViews && <FiltersView ref={this.views} ItemId={itemId} ItemType={itemType} BlockType={blockType} GuestLicence={guestLicence} DefaultViewId={defaultViewId} CurrentView={currentView} Views={views} onViewsHide={this.hideViews} onViewChange={this.changeView} onSetDefaultView={this.setDefaultView} onErrorsUpdate={this.updateErrors}></FiltersView>}

            {/* Card Block Content */}
            <div className={!displayViews ? "blockContent" : "blockContentViews"}>
              {/* Filters */}
              <div className="blockFilters">
                {/* Current View */}
                {!displayViews && <FiltersCurrentView ItemId={itemId} ItemType={itemType} BlockType={blockType} CurrentView={currentView} onDisplayViews={this.displayViews} onErrorsUpdate={this.updateErrors}></FiltersCurrentView>}

                {/* Add Button */}
                {active && editable && currentView.ViewType !== 3 && currentView.ViewType !== 7 && 
                  <span className="iconButtonPlus mediumIcons mh10 cursor" onClick={() => this.setState({ addBLPopup: true })}></span>
                }
                
                {/* Level Buttons */}
                {levels && levels.length > 1 && 
                  <FiltersLevel ref={this.levels} ItemId={itemId} ItemType={itemType} BlockType={blockType} CurrentView={currentView} Views={views} Levels={levels} Rows={rows} onLevelChange={this.changeLevel}></FiltersLevel>
                }

                {/* Block Filters */}
                <FiltersBlock ref={this.filters} ItemId={itemId} ItemType={itemType} BlockType={blockType} CurrentView={currentView} onFiltersChange={this.changeFilters}></FiltersBlock>

                {/* Sort Filters */}
                <FiltersSort ref={this.sort} ItemId={itemId} ItemType={itemType} BlockType={blockType} CurrentView={currentView} onSortChange={this.changeSort}></FiltersSort>

                {/* Conditional Formatting Filters */}
                {currentView.ViewType !== 3 && 
                  <FiltersConditionalFormatting ref={this.formattings} ItemId={itemId} ItemType={itemType} BlockType={blockType} CurrentView={currentView} onFormattingsChange={this.changeFormattings}></FiltersConditionalFormatting>
                }

                {/* Kanban Filters */}
                {currentView.ViewType === 2 && 
                  <FiltersKanban BlockType={blockType} CurrentView={currentView} Axes={blockContent.AvailableAxes} onAxeChange={this.changeAxe} onKeyFieldChange={this.changeKeyField}></FiltersKanban>
                }

                {/* Chart Settings */}
                {currentView.ViewType === 7 && 
                  <FiltersChart onChartSettingsDisplay={this.displayChartSettings} onChartSettingsClose={this.closeChartSettings}></FiltersChart>
                }

                {/* Columns Chooser */}
                {(currentView.ViewType === 0 || currentView.ViewType === 1 || currentView.ViewType === 2) && 
                  <FiltersColumnChooser ItemId={itemId} ItemType={itemType} Columns={columns} CurrentView={currentView} onColumnsChange={this.changeColumns}></FiltersColumnChooser>
                }

                {/* Search input */}
                {currentView.ViewType !== 3 && currentView.ViewType !== 7 && 
                  <div className="filtersSearch">
                    <Form.Group className="searchBlock">
                      <Form.Control type="text" id="searchFilters" name="search" value={search} placeholder={Traduction.translate(language, 'search')} onChange={this.searchItem} />
                    </Form.Group>
                  </div>
                }

                {/* Import */}
                {currentView.ViewType === 0 && 
                  <FiltersImport ItemId={itemId} ItemType={itemType} BlockType={blockType} CurrentView={currentView} onDataImport={this.getCardData} onXLSXExport={this.exportXLSX}></FiltersImport>
                }
                
                {/* Export */}
                {(currentView.ViewType === 0 || currentView.ViewType === 7) && 
                  <FiltersExport ItemId={itemId} ItemType={itemType} BlockType={blockType} CurrentView={currentView} onCSVExport={this.exportCSV} onXLSXExport={this.exportXLSX} onErrorsUpdate={this.updateErrors}></FiltersExport>
                }
              </div>
              
              {/* Card Block Component */}
              <div className="blockComponent">
                {/* Errors */}
                {errors.length > 0 && 
                  <ErrorModification Errors={errors} Open={true} onErrorsClean={this.cleanErrors}></ErrorModification>
                }

                {/* Loading Spinner */}
                {isLoading && <div className="center mt30 mb20">
                  <span className=""><LoadingSpinner></LoadingSpinner></span>
                  <span className="bold ml30">{Traduction.translate(language, 'data_loading')}</span>
                </div>}

                {/* Popup Add Business Line */}
                {(addBLPopup === true) && <div className="addProjectPopup">
                  <div className="addProjectInnerPopup">{this.templateAddBLPopup()}</div>
                </div>}

                {/* Popup Add Project */}
                {(addProjectPopup === true) && <div className="addProjectPopup">
                  <div className="addProjectInnerPopup">{this.templateAddProjectPopup()}</div>
                </div>}

                {/* Delete Confirmation Popup */}
                {confirm && <PopupConfirmation Message={deleteMessage} onMessageConfirm={this.confirmDelete} onMessageCancel={this.cancelDelete}></PopupConfirmation>}

                {/* Message Information Popup */}
                {info && <PopupInformation Message={informationMessage} onMessageValidate={this.validateMessage}></PopupInformation>}

                {/* View Components */}
                {currentView.ViewType === 0 && currentView.DefaultLevel === 0 && rows.length > 0 && <Tree ref={this.tree} ItemId={itemId} ItemType={itemType} Title={itemTitle} BlockType={blockType} Editable={editable} CurrentView={currentView} Columns={columns} Rows={rows} onViewChange={this.updateView} onColumnsFilter={this.filterColumn} onColumnsSort={this.sortColumn} onColumnSort={this.changeSort} onItemCreate={this.createItem} onItemDelete={this.deleteItem} onItemCutPaste={this.cutPasteItem} onTableUpdate={this.checkModification}></Tree>}
                {currentView.ViewType === 0 && currentView.DefaultLevel !== 0 && rows.length > 0 && <Table ref={this.table} ItemId={itemId} ItemType={itemType} Title={itemTitle} BlockType={blockType} Editable={editable} CurrentView={currentView} Columns={columns} Rows={rows} onViewChange={this.updateView} onColumnsFilter={this.filterColumn} onColumnsSort={this.sortColumn} onColumnSort={this.changeSort} onItemCreate={this.createItem} onItemDelete={this.deleteItem} onTableUpdate={this.checkModification}></Table>}
                {currentView.ViewType === 2 && rows.length > 0 && <Kanban ref={this.kanban} ItemId={itemId} ItemType={itemType} BlockType={blockType} Editable={editable} CurrentView={currentView} Kanban={blockContent.Kanban} Columns={columns} Rows={rows} Axes={blockContent.AvailableAxes} onTableUpdate={this.checkModification}></Kanban>}
                {currentView.ViewType === 3 && rows.length > 0 && <Pivot ref={this.pivot} ItemId={itemId} ItemType={itemType} Title={itemTitle} BlockType={blockType} Editable={editable} CurrentView={currentView} Columns={columns} Rows={rows} onViewChange={this.updateView}></Pivot>}
                {currentView.ViewType === 7 && <Chart ref={this.chart} ItemId={itemId} ItemType={itemType} Title={itemTitle} BlockType={blockType} CurrentView={currentView} Chart={chart} IsLoading={isLoading} onViewChange={this.updateView} onSettingsChange={this.applyChartSettings}></Chart>}
              </div>
            </div>
          </div>
        </div>}
      </div>
    );
  }
}

export default EnvironmentRoadmap;