import React, {Component} from 'react';
import { HeatMapComponent, Legend, Tooltip, Adaptor, Inject, HeatMap } from '@syncfusion/ej2-react-heatmap';
import '../Css/App.css';
import MiniHeatmap from './MiniHeatmap';
import MiniPivot from './MiniPivot';

class MiniBlockBurned extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itemId: null,
      itemType: null,
      itemTitle: null,
      blockType: null,
      blockLabel: null,
      blockContent: {},
      filters: null,
      currentView: {},
      views: [],
      title: null,
      tables: [],
      columns: [],
      rows: [],
      chartData: [],
      heatmap: {}
    };
    
    this.buildBlockData = this.buildBlockData.bind(this);
    this.dataStructure = this.dataStructure.bind(this);
    this.getColumnsLabel = this.getColumnsLabel.bind(this);
    this.getRowsLabel = this.getRowsLabel.bind(this);
  }

  componentDidMount() {
    const itemId = this.props.Id;
    const itemType = this.props.Type;
    const itemTitle = this.props.Title;
    const blockType = this.props.BlockType;
    const blockLabel = this.props.BlockLabel;
    const blockContent = this.props.BlockContent;
    const filters = this.props.Filters;

    // Get Block Content Data
    this.buildBlockData(blockContent);

    this.setState({ itemId, itemType, itemTitle, blockType, blockLabel, blockContent, filters });
  }

  componentDidUpdate(prevProps) {
    const itemId = this.props.Id;
    const itemType = this.props.Type;
    const blockContent = this.props.BlockContent;

    // if(this.props.BlockContent !== prevProps.BlockContent) {
    //   // Get Block Content Data
    //   this.buildBlockData(blockContent);

    //   this.setState({ blockContent });
    // }
  }

  buildBlockData(blockContent) {
    let views = [], currentView = {}, columns = [], rows = [];
    let title, columnsHeatmap= [], rowsHeatmap= [], chartData = [], heatmap;

    if(blockContent) {
      // Views
      if(blockContent.Views) {
        views = blockContent.Views;

        // Find CurrentView in View list
        if(views.find(view => view.ViewId === blockContent.DefaultViewId)) {
          currentView = views.find(view => view.ViewId === blockContent.DefaultViewId);
        }
      }

      // Tables
      if(blockContent.Tables && blockContent.Tables.length > 0) {
        columns = blockContent.Tables[0].ColumnHeaders;
        rows = blockContent.Tables[0].Rows;
      }
      
      if(blockContent.HeatMaps.length > 0) {
        title = blockContent.HeatMaps[0].Title;
        columnsHeatmap = blockContent.HeatMaps[0].Columns;
        rowsHeatmap = blockContent.HeatMaps[0].Rows;
        chartData = blockContent.HeatMaps[0].ChartData;

        // Build Heatmap Datasource
        heatmap = this.dataStructure(columnsHeatmap, rowsHeatmap, chartData);
      }

      this.setState({ currentView, views, title, columns, rows, chartData, heatmap });
    }
  }

  dataStructure(columns, rows, chartData) {
    let heatmap = {};

    heatmap.dataSource = [];
    heatmap.xAis = [];
    heatmap.yAis = [];

    if(chartData) {
      for(let x = 0; x < chartData.length; x++) {
        heatmap.dataSource.push([]);

        for(let y = 0; y < chartData[x].length; y++) {
          // Push ChartData corresponding cell (format value => Progress)
          heatmap.dataSource[x].push(chartData[x][y]);
          // heatmap.dataSource[x].push((chartData[x][y] * 100).toFixed(0));
        }
      }

      heatmap.xAis = this.getColumnsLabel(columns);
      heatmap.yAis = this.getRowsLabel(rows);
    }

    return heatmap;
  }

  getColumnsLabel(columns) {
    let labels = [];

    columns.forEach(column => {
      labels.push(column.Label);
    });
    
    return labels;
  }

  getRowsLabel(rows) {
    let labels = [];

    rows.forEach(row => {
      labels.push(row.Label);
    });

    return labels;
  }

  render() {
    const { itemId, itemType, itemTitle, blockType, blockLabel, blockContent, currentView, views, title, columns, rows, chartData, heatmap } = this.state;
    let visibleComponent, height;

    // Initialise Visible Component
    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'VisibleComponent')) {
      visibleComponent = currentView.Parameters.find(param => param.Name === 'VisibleComponent').Value;
    }
    else {
      visibleComponent = "Heatmap";
    }

    // Heatmap height
    height = ((window.innerHeight - 160) / (12 / blockContent.Height)).toString();
    
    // Cell Settings
    const cellSettings = { textStyle: { color: 'black' }, border: { width: 4, radius: 5, color: 'white' }, format: '{value} %' };

    // Palette Settings
    const paletteSettings = { palette: [{ value: 0, color: '#EAFCFF' }, { value: 99, color: '#95B0B5' }, { value: 100, color: '#A1E981' }, { value: 101, color: '#FFD589' }, { value: 500, color: '#FF9052' }] };
    // const paletteSettings = { palette: [{ value: 0, color: '#EEEEEE' }, { value: 99, color: '#B1B2BE' }, { value: 100, color: '#00C77A' }, { value: 101, color: '#F57C12' }, { value: 500, color: '#F57C12' }] };

    return (
      <div className="miniblock">
        <div className="miniblockBurned">
          {heatmap && heatmap.dataSource && heatmap.dataSource.length > 0 && <div className="miniblockHeatmap scrollbar-miniblock">
            {/* Heatmap */}
            {visibleComponent === 'Heatmap' && <HeatMapComponent id={blockContent.ViewDashboardBlockId.toString()} height={height} width={'100%'} dataSource={heatmap.dataSource} xAxis={{ labels: heatmap.xAis }} yAxis={{ labels: heatmap.yAis }} cellSettings={cellSettings} paletteSettings={paletteSettings} legendSettings={{ visible: false }} showTooltip={false} ref={heatmap=>this.heatmap=heatmap}>
              <Inject services={[ Legend, Tooltip, Adaptor ]} />
            </HeatMapComponent>}

            {/* <div className="control-pane"> */}
              {/* <div className="control-section"> */}
                {/* <MiniHeatmap ItemId={itemId} ItemType={itemType} Title={itemTitle} BlockType={blockType} ChartData={chartData} Heatmap={heatmap}></MiniHeatmap> */}
              {/* </div> */}
            {/* </div> */}

            {/* Mini Pivot */}
            {visibleComponent === 'Graph' && <MiniPivot ref={this.workloadPivot} ItemId={itemId} ItemType={itemType} BlockType={blockType} Editable={false} CurrentView={currentView} Columns={columns} Rows={rows}></MiniPivot>}
          </div>}
        </div>
      </div>
    )
  }
}

export default MiniBlockBurned;