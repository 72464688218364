import React, {Component} from 'react';
import { Form } from 'react-bootstrap';
import { TreeGridComponent, ColumnsDirective, ColumnDirective, ColumnMenu } from '@syncfusion/ej2-react-treegrid';
import { Inject, Toolbar, ColumnChooser, ContextMenu, Filter, Sort, Page, Reorder, Resize, ExcelExport } from '@syncfusion/ej2-react-treegrid';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';
import FiltersExport from './FiltersExport';
import ErrorModification from './ErrorModification';
import LoadingSpinner from './LoadingSpinner';

// API
const API_Audit = '/WebAppService/GetAudit';

class TableAudit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: null,
      authId: null,
      language: null,
      formatDate: null,
      itemId: null,
      itemType: null,
      itemTitle: null,
      blockType: null,
      columns: [],
      rows: [],
      dataSource: [],
      search: '',
      isLoading: false,
      errors: []
    };

    // Table Structure
    this.getAudit = this.getAudit.bind(this);
    this.created = this.created.bind(this);
    this.createColumn = this.createColumn.bind(this);
    this.dataStructure = this.dataStructure.bind(this);
    this.rowDataBound = this.rowDataBound.bind(this);
    this.getColumnType = this.getColumnType.bind(this);
    this.formatDateKr = this.formatDateKr.bind(this);

    // Actions
    this.excelQueryCellInfo = this.excelQueryCellInfo.bind(this);
    this.exportCSV = this.exportCSV.bind(this);
    this.exportXLSX = this.exportXLSX.bind(this);
    this.searchItem = this.searchItem.bind(this);
    this.updateErrors = this.updateErrors.bind(this);
    this.cleanErrors = this.cleanErrors.bind(this);
    this.closePopup = this.closePopup.bind(this);

    // Template Functions
    this.templateColumnChangeDateTime = this.templateColumnChangeDateTime.bind(this);
    this.templateColumnEventType = this.templateColumnEventType.bind(this);
    this.templateColumnLastModifyUser = this.templateColumnLastModifyUser.bind(this);
    this.templateTypeHTML = this.templateTypeHTML.bind(this);
    this.templateTypeText = this.templateTypeText.bind(this);
  }
  
  async componentDidMount() {
    const login = Authentication.getCookie('login');
    const authId = Authentication.getCookie('authId');
    const language = Authentication.getCookie('language');
    const formatDate = Authentication.getCookie('formatDate');
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const itemTitle = this.props.ItemTitle;
    const blockType = this.props.BlockType;

    this.setState({ login, authId, language, formatDate, itemId, itemType, itemTitle, blockType });

    await this.getAudit(login, authId, itemId, itemType);
  }

  async componentDidUpdate(prevProps) {
    const login = Authentication.getCookie('login');
    const authId = Authentication.getCookie('authId');
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const itemTitle = this.props.ItemTitle;
    const blockType = this.props.BlockType;

    if(this.props.ItemId !== prevProps.ItemId || this.props.ItemType !== prevProps.ItemType || this.props.ItemTitle !== prevProps.ItemTitle || this.props.BlockType !== prevProps.BlockType) {
      this.setState({ itemId, itemType, itemTitle, blockType });

      await this.getAudit(login, authId, itemId, itemType);
    }
  }

  async getAudit(login, authId, itemId, itemType) {
    const language = this.state.language;

    this.setState({ isLoading: true });
    
    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': itemType,
        'ItemId': itemId
      })
    };

    try{
      const response = await fetch(API_Audit, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const result = data.GetAuditResult;

      // Check request response
      if(result) {
        let rows = [], columns = [];

        if(result.AuditTable) {
          rows = result.AuditTable.Rows;
          columns = result.AuditTable.ColumnHeaders;
        }
        
        // Build Audit Table Datasource
        let dataSource = this.dataStructure(rows);

        this.setState({ rows, columns, dataSource, isLoading: false });
      }
      else {
        this.setState({ isLoading: false });

        this.updateErrors(Traduction.translate(language, 'display_audit_impossible'));
      }

    } catch(error) {
      console.log(error);
    }
  }

  created() {
    const columns = this.state.columns;

    // Auto Generated Columns
    if(this.gridAudit) {
      // Clean Grid columns
      this.gridAudit.columns = [];
      
      // Add others columns
      columns.forEach(column => {
        let colObj;

        // Create Column object
        colObj = this.createColumn(column);

        this.gridAudit.columns.push(colObj);
      });

      this.gridAudit.refreshColumns();
    }
  }

  createColumn(columnHeader) {
    // const language = this.state.language;
    // const formatDate = this.state.formatDate;
    let type, format, template, textAlign, visible, width;

    // Type
    if(columnHeader.FieldType === 'String' || columnHeader.FieldType === 'Date' || columnHeader.FieldName === 'Meteo' || columnHeader.FieldName === 'Trend') {
      type = 'string';
    }
    else if(columnHeader.FieldType === 'Double' || columnHeader.FieldType === 'Percentage') {
      type = 'number';
    }
    else if(columnHeader.FieldType === 'Date') {
      type = 'date';
    }
    else {
      type = null;
    }

    // Format
    // if(columnHeader.FieldType === 'Date') {
    //   if(formatDate === 'MM/DD/YYYY') {
    //     format = { type: 'date', format: 'MM/dd/yyyy' };
    //   }
    //   else if(formatDate === 'DD/MM/YYYY') {
    //     format = { type: 'date', format: 'dd/MM/yyyy' };
    //   }
    //   else if(formatDate === 'YYYY-MM-DD') {
    //     format = { type: 'date', format: 'yyyy-MM-dd' };
    //   }
    // }
    // else {
    //   format = null;
    // }

    // Template functions
    if(columnHeader.FieldName === 'ChangeDateTime') {
      template = this.templateColumnChangeDateTime;
    }
    else if(columnHeader.FieldName === 'Event_Type') {
      template = this.templateColumnEventType;
    }
    else if(columnHeader.FieldName === 'LastModifyUser') {
      template = this.templateColumnLastModifyUser;
    }
    else if(columnHeader.FieldName === 'Previous_Value' || columnHeader.FieldName === 'New_Value') {
      template = this.templateTypeHTML;
    }
    else {
      template = this.templateTypeText;
    }

    // Align
    if(columnHeader.FieldName === 'Event_Type') {
      textAlign = 'center';
    }
    else {
      textAlign = 'left';
    }

    // Width
    if(columnHeader.FieldName === 'LastModifyUser') {
      width = 140;
    }
    else {
      width = 100;
    }

    // Visible
    if(columnHeader.FieldName === 'Item_ID' || columnHeader.FieldName === 'Item_Type') {
      visible = false;
    }
    else {
      visible = true;
    }

    // Define Column object for Grid
    var colObj = {
      field: columnHeader.FieldName,
      // format: format,
      headerText: columnHeader.Label,
      headerTextAlign: 'left',
      lockColumn: false,
      showInColumnChooser: false,
      template: template,
      textAlign: textAlign,
      type: type,
      visible: visible,
      width: width
    };

    return colObj;
  }

  dataStructure(rows) {
    let dataSource = [];

    if(rows.length > 0) {
      dataSource = rows.map(row => {
        return row.Cells.reduce((acc, item) => {
          // Format Dates
          if(item.FieldType === 'Date' && item.Value) {
            acc[item.ColumnName] = new Date(item.Value);
          }
          // Format Numbers
          else if(item.FieldType === 'Double' && item.Value) {
            acc[item.ColumnName] = parseFloat(item.Value);
          }
          // Format Objects
          else if(item.FieldType === 'Object' && item.Value) {
            acc[item.ColumnName] = JSON.parse(item.Value);
          }
          // Add the Column Name/Values to the reduced Table
          // Exclude Parent_ID for Table
          else if(item.ColumnName !== 'Parent_ID') {
            acc[item.ColumnName] = item.Value;
          }
          
          return acc;
        }, {});
      });
    }

    return dataSource;
  }

  rowDataBound(args) {
    args.rowHeight = 40;
  }

  getColumnType(columnName) {
    const columns = this.state.columns;

    if(columns.find(column => column.FieldName === columnName)) {
      return columns.find(column => column.FieldName === columnName).FieldType;
    }
    else {
      return null;
    }
  }

  // Formatting Date to Korean format
  formatDateKr(date) {
    let formattedDate;

    if(date) {
      // let dateFr = new Date(date).toLocaleString().split(' ')[0];
      let dateFr = new Date(date).toLocaleString("en-GB").split(/,| /)[0];

      const splitDate = dateFr.split('/');
      const day = splitDate[0];
      const month = splitDate[1];
      const year = splitDate[2];
      
      const splitHour = new Date(date).toLocaleString("en-GB").split(' ');
      const hour = splitHour[1];

      formattedDate = year + "-" + month + "-" + day + " " + hour;
    }

    return formattedDate;
  }

  // Format Cells value for Export
  excelQueryCellInfo(args) {
    // Dates
    if(args.value && this.getColumnType(args.column.field) === 'Date') {
      args.value = new Date(args.value).toLocaleDateString();
    }
    // Percentage
    else if(this.getColumnType(args.column.field) === 'Percentage') {
      if(args.value) {
        args.value = args.value.toFixed(0) + "%";
      }
      else {
        args.value = "0%";
      }
    }
    // Objects
    else if(args.value && ((this.getColumnType(args.column.field) === 'Axe' && args.column.field !== 'HashTag') || this.getColumnType(args.column.field) === 'Resource' || this.getColumnType(args.column.field) === 'Location' || args.column.field === 'Meteo' || args.column.field === 'Trend' || args.column.field === 'Business_Line' || args.column.field === 'Project' || args.column.field === 'Workpackage' || args.column.field === 'Action' || args.column.field === 'Task' || args.column.field === 'Entity')) {
      args.value = args.value.Label;
    }
    // Table Objects
    else if(args.value && (this.getColumnType(args.column.field) === 'AxisTable' || this.getColumnType(args.column.field) === 'ResourceTable' || this.getColumnType(args.column.field) === 'Files' || args.column.field === 'HashTag' || args.column.field === 'Rights')) {
      let string = "";

      args.value.forEach((value, index) => {
        if(index < args.value.length - 1) {
          string = string.concat(value.Label, ", ");
        }
        else {
          string = string.concat(value.Label);
        }
      });

      args.value = string;
    }
  }

  exportCSV() {
    const language = this.state.language;
    const itemTitle = this.state.itemTitle;
    const fileName = Traduction.translate(language, 'export_audit') + " " + itemTitle + ".csv";

    if(this.gridAudit) {
      // Define Export File Name
      let csvExportProperties = { fileName: fileName };

      this.gridAudit.csvExport(csvExportProperties);
    }
  }

  exportXLSX() {
    const language = this.state.language;
    const itemTitle = this.state.itemTitle;
    const fileName = Traduction.translate(language, 'export_audit') + " " + itemTitle + ".csv";

    if(this.gridAudit) {
      // Define Export File Name
      let excelExportProperties = { fileName: fileName };
      
      this.gridAudit.excelExport(excelExportProperties);
    }
  }

  searchItem(event) {
    const search = event.target.value;

    if(this.gridAudit) {
      this.gridAudit.search(search);
    }

    this.setState({ search });
  }

  updateErrors(err) {
    let errors = [];

    // Push the new Errors in the Errors Table
    errors.push(err);

    this.setState({ errors });
  }

  cleanErrors() {
    this.setState({ errors: [] });
  }

  closePopup() {
    // Call the event from the Parent component through the props
    this.props.onPopupClose();
  }

  templateColumnChangeDateTime(props) {
    // const language = this.state.language;
    const formatDate = this.state.formatDate;
    let date;
    
    if(!props.column) {
      return null;
    }

    // Get Column Field and Value
    const field = props.column.field;
    const value = props[field];

    const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', hour12: false, minute: '2-digit', second: '2-digit' };

    if(value) {
      if(formatDate === 'MM/DD/YYYY') {
        date = new Date(value).toLocaleString("en-US", options);
      }
      else if(formatDate === 'DD/MM/YYYY') {
        date = new Date(value).toLocaleString("fr-FR", options);
      }
      else if(formatDate === 'YYYY-MM-DD') {
        date = this.formatDateKr(new Date(value));
      }
      // if(language === 'English') {
      //   date = new Date(value).toLocaleDateString("en-US", { year: 'numeric', month: '2-digit', day: '2-digit' });
      // }
      // else if(language === 'Français') {
      //   date = new Date(value).toLocaleDateString("fr-FR");
      // }

      // date = new Date(value).toLocaleDateString();
      // formattedDate = date.split(/,| /)[0];
    }

    return <div className="">{date}</div>;
  }

  templateColumnEventType(props) {
    const event = props.Event_Type;

    if(event === 'Added') {
      return <div className="auditEventTypeAdded">{event}</div>;
    }
    else if(event === 'Deleted') {
      return <div className="auditEventTypeDeleted">{event}</div>;
    }
    else if(event === 'Modified') {
      return <div className="auditEventTypeModified">{event}</div>;
    }
    else {
      return null;
    }
  }

  templateColumnLastModifyUser(props) {
    const value = props.LastModifyUser;

    // if(!props.column) {
    //   return null;
    // }

    // Get Column Field and Value
    // const field = props.column.field.replace(".Label", "");
    // const value = props[field];

    if(value) {
      if(value.Label === '- Not assigned -') {
        let firstname = 'N';
        let lastname = 'A';

        return <div className="align-baseline">
          <span className="iconEmptyResourceTable icons flex aligncenter blue">{firstname + lastname}</span>
          <span className="ml10 grey">{value.Label}</span>
        </div>;
      }
      else if(value.Label === '- To be assigned -') {
        let firstname = 'T';
        let lastname = 'B';

        return <div className="align-baseline">
          <span className="iconEmptyResourceTable icons flex aligncenter blue">{firstname + lastname}</span>
          <span className="ml10 grey">{value.Label}</span>
        </div>;
      }
      else if(value.Id && value.Label) {
        let firstname, lastname;
        let split = value.Label.split(' ');

        if(split.length === 1) {
          firstname = value.Label.split(' ')[0].substring(0,1);
          lastname = '';
        }
        else if(split.length === 2) {
          firstname = value.Label.split(' ')[0].substring(0,1);
          lastname = value.Label.split(' ')[1].substring(0,1);
        }
        else {
          firstname = value.Label.split(' ')[0].substring(0,1);
          lastname = value.Label.split(' ')[split.length-1].substring(0,1);
        }

        return <div className="align-baseline">
          <span className="iconResourceTable icons flex aligncenter white">{firstname + lastname}</span>
          <span className="ml10 grey">{value.Label}</span>
        </div>;
      }
      else {
        return null;
      }
    }
    else {
      return null;
    }
  }

  templateTypeHTML(props) {
    if(!props.column) {
      return null;
    }
    
    // Get Column Name and Value
    const columnName = props.column.field;
    const value = props[columnName];

    return <div dangerouslySetInnerHTML={{ __html: value }}/>;
  }

  templateTypeText(props) {
    if(!props.column) {
      return null;
    }

    // Get Column Field and Value
    const field = props.column.field;
    const value = props[field];

    return <div className="">{value}</div>;
  }

  render() {
    const { language, itemId, itemType, itemTitle, blockType, columns, rows, dataSource, search, isLoading, errors } = this.state;

    // Grid Lines
    this.gridLines = 'Default';

    // Column Menu
    this.columnMenuItems = ['AutoFit', 'AutoFitAll'];
    // this.columnMenuItems = ['AutoFit', 'AutoFitAll', 'SortAscending', 'SortDescending', 'Filter'];

    // Toolbar Options
    // this.toolbarOptions = ['Search'];

    return (
      <div className="auditPopup">
        <div className="auditInnerPopup">
          <div className="flex flex-column">
            <div className="inline-flex">
              {/* Label */}
              <div className="flex-start inline-flex mb10 width100p">
                <div className="auditLabel">{Traduction.translate(language, 'audit')} {Traduction.translate(language, 'of')}</div>
                <div className="auditLabelYellow ml5">{itemTitle}</div>
              </div>

              {/* Close Popup */}
              <div className="flex-end">
                <div className="iconClear icons cursor" onClick={(e) => this.closePopup()}></div>
              </div>
            </div>
          
            <div className="flex mv5">
              <div className="flex-end inline-flex align-items-center width100p">
                {/* Search */}
                <div className="filtersSearch">
                  <Form.Group className="searchBlock">
                    <Form.Control type="text" id="searchFilters" name="search" value={search} placeholder={Traduction.translate(language, 'search')} onChange={this.searchItem} />
                  </Form.Group>
                </div>

                {/* Export */}
                <FiltersExport ItemId={itemId} ItemType={itemType} BlockType={blockType} onCSVExport={this.exportCSV} onXLSXExport={this.exportXLSX} onErrorsUpdate={this.updateErrors}></FiltersExport>
              </div>
            </div>

            {/* Errors */}
            {errors.length > 0 && 
              <ErrorModification Errors={errors} Open={true} onErrorsClean={this.cleanErrors}></ErrorModification>
            }

            {/* Loading Spinner */}
            {isLoading && <div className="center mt20 mb20">
              <span className=""><LoadingSpinner></LoadingSpinner></span>
              <span className="bold ml30">{Traduction.translate(language, 'data_loading')}</span>
            </div>}

            {/* TreeGrid Component */}
            <div className="control-pane">
              <div className="control-section">
                {dataSource && dataSource.length > 0 && 
                  <TreeGridComponent dataSource={dataSource} idMapping='Item_ID' height='450' gridLines={this.gridLines} columnMenuItems={this.columnMenuItems} created={this.created} rowDataBound={this.rowDataBound} excelQueryCellInfo={this.excelQueryCellInfo} showColumnMenu={false} showColumnChooser={false} allowPaging={true} pageSettings={{ pageSize: 50 }} allowFiltering={false} allowExcelExport={true} allowReordering={true} allowResizing={true} allowSorting={false} allowTextWrap={true} ref={grid=>this.gridAudit=grid}>
                    <Inject services={[ Toolbar, ContextMenu, ColumnMenu, ColumnChooser, Filter, Sort, Page, Reorder, Resize, ExcelExport ]}/>
                  </TreeGridComponent>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default TableAudit;