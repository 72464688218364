import React, {Component} from 'react';
import { Card, OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../Css/App.css';
import 'react-sweet-progress/lib/style.css';
import Authentication from '../Authentication';
import Axe from './Axe';
import DatePickerCalendar from './DatePickerCalendar';
import Progress from './Progress';
import Timeline from './Timeline';

class MiniCardKanban extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: null,
      formatDate: null,
      itemId: null,
      itemType: null,
      currentView: {},
      columns: [],
      rows: [],
      content: {},
      hover: false
    };

    this.formatDate = this.formatDate.bind(this);
    this.formatDateEn = this.formatDateEn.bind(this);
    this.formatDateFr = this.formatDateFr.bind(this);
    this.formatDateKr = this.formatDateKr.bind(this);
    this.getColumnConditionalFormattings = this.getColumnConditionalFormattings.bind(this);
    this.getCellConditionalFormatting = this.getCellConditionalFormatting.bind(this);
    this.isConditionalFormattingRespected = this.isConditionalFormattingRespected.bind(this);
    this.displayMinicardIcon = this.displayMinicardIcon.bind(this);
    this.displayMinicardTitle = this.displayMinicardTitle.bind(this);
    this.displayMinicardContent = this.displayMinicardContent.bind(this);
  }

  componentDidMount() {
    const language = Authentication.getCookie('language');
    const formatDate = Authentication.getCookie('formatDate');
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const currentView = this.props.CurrentView;
    const columns = this.props.Columns;
    const rows = this.props.Rows;
    const content = this.props.Content;

    this.setState({ language, formatDate, itemId, itemType, currentView, columns, rows, content });  }

  componentDidUpdate(prevProps) {
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const currentView = this.props.CurrentView;
    const columns = this.props.Columns;
    const rows = this.props.Rows;
    const content = this.props.Content;

    if(this.props.ItemId !== prevProps.ItemId || this.props.ItemType !== prevProps.ItemType) {
      this.setState({ itemId, itemType });
    }
    if(JSON.stringify(this.props.CurrentView) !== JSON.stringify(prevProps.CurrentView)) {
      this.setState({ currentView });
    }
    if(this.props.Columns !== prevProps.Columns) {
      this.setState({ columns });
    }
    if(JSON.stringify(this.props.Content) !== JSON.stringify(prevProps.Content)) {
      this.setState({ content });
    }
  }

  formatDate(date) {
    const formatDate = this.state.formatDate;

    if(formatDate === 'MM/DD/YYYY') {
      return this.formatDateEn(date);
    }
    else if(formatDate === 'DD/MM/YYYY') {
      return this.formatDateFr(date);
    }
    else if(formatDate === 'YYYY-MM-DD') {
      return this.formatDateKr(date);
    }
  }

  // Formatting Date to English format for Database
  formatDateEn(date) {
    let formattedDate;

    if(date) {
      // let dateFr = new Date(date).toLocaleString().split(' ')[0];
      let dateFr = new Date(date).toLocaleString("en-GB").split(/,| /)[0];

      const split = dateFr.split('/');
      const day = split[0];
      const month = split[1];
      const year = split[2];

      formattedDate = month + "/" + day + "/" + year;
    }

    return formattedDate;
  }

  // Formatting Date to French format
  formatDateFr(date) {
    let formattedDate;

    if(date) {
      // let dateFr = new Date(date).toLocaleString().split(' ')[0];
      let dateFr = new Date(date).toLocaleString("en-GB").split(/,| /)[0];

      const split = dateFr.split('/');
      const day = split[0];
      const month = split[1];
      const year = split[2];

      formattedDate = day + "/" + month + "/" + year;
    }

    return formattedDate;
  }

  // Formatting Date to Korean format
  formatDateKr(date) {
    let formattedDate;

    if(date) {
      // let dateFr = new Date(date).toLocaleString().split(' ')[0];
      let dateFr = new Date(date).toLocaleString("en-GB").split(/,| /)[0];

      const split = dateFr.split('/');
      const day = split[0];
      const month = split[1];
      const year = split[2];

      formattedDate = year + "-" + month + "-" + day;
    }

    return formattedDate;
  }

  getColumnConditionalFormattings(columnName) {
    const columns = this.state.columns;

    if(columns.find(column => column.FieldName === columnName)) {
      return columns.find(column => column.FieldName === columnName).ConditionalFormattings;
    }
    else {
      return [];
    }
  }

  getCellConditionalFormatting(conditionalFormattings, value) {
    if(conditionalFormattings) {
      for(let i = 0; i < conditionalFormattings.length; i++) {
        if(this.isConditionalFormattingRespected(conditionalFormattings[i], value)) {
          return conditionalFormattings[i];
        }
      }
    }
    else {
      return null;
    }
  }

  isConditionalFormattingRespected(conditionalFormatting, value) {
    if(conditionalFormatting) {
      switch(conditionalFormatting.Conditions) {
        case 'equal':
            if(conditionalFormatting.Value1 == value) {
              return true;
            }
            else {
              return false;
            }
        case 'notequal':
            if(conditionalFormatting.Value1 != value) {
              return true;
            }
            else {
              return false;
            }
        case 'greaterthan':
            if(conditionalFormatting.Value1 < value) {
              return true;
            }
            else {
              return false;
            }
        case 'greaterthanorequal':
            if(conditionalFormatting.Value1 <= value) {
              return true;
            }
            else {
              return false;
            }
        case 'lessthan':
            if(conditionalFormatting.Value1 > value) {
              return true;
            }
            else {
              return false;
            }
        case 'lessthanorequal':
            if(conditionalFormatting.Value1 >= value) {
              return true;
            }
            else {
              return false;
            }
        case 'between':
            if(conditionalFormatting.Value1 <= value && conditionalFormatting.Value2 >= value) {
              return true;
            }
            else {
              return false;
            }
        default:
            return false;
      }
    }
    else {
      return false;
    }
  }

  // Display Minicard Icon depending on the ItemType
  displayMinicardIcon(type) {
    switch(type) {
      case "AgileBoard":
          return <div className="minicardKanbanIcon"><span className="iconAgileBoard iconsMinicard" alt="Agile Board"/></div>;
      case "Sprint":
          return <div className="minicardKanbanIcon"><span className="iconSprint iconsMinicard" alt="Sprint"/></div>;
      case "Business_Line":
          return <div className="minicardKanbanIcon"><span className="iconBusinessLine iconsMinicard" alt="Business Line"/></div>;
      case "Project":
          return <div className="minicardKanbanIcon"><span className="iconProject iconsMinicard" alt="Project"/></div>;
      case "Workpackage":
          return <div className="minicardKanbanIcon"><span className="iconWorkpackage iconsMinicard" alt="Workpackage"/></div>;
      case "Action":
          return <div className="minicardKanbanIcon"><span className="iconAction iconsMinicard" alt="Action"/></div>;
      case "Task":
          return <div className="minicardKanbanIcon"><span className="iconTask iconsMinicard" alt="Task"/></div>;
      case "Decision":
          return <div className="minicardKanbanIcon"><span className="iconDecisions iconsMinicard" alt="Decision"/></div>;
      case "Issue":
          return <div className="minicardKanbanIcon"><span className="iconIssues iconsMinicard" alt="Issue"/></div>;
      case "Meeting":
          return <div className="minicardKanbanIcon"><span className="iconMeetings iconsMinicard" alt="Meeting"/></div>;
      case "Risk":
          return <div className="minicardKanbanIcon"><span className="iconRisks iconsMinicard flex" alt="Risk"/></div>;
      case "BudgetCode":
          return <div className="minicardKanbanIcon"><span className="iconBudgetCode iconsMinicard" alt="Budget Code"/></div>;
      case "Entity":
          return <div className="minicardKanbanIcon"><span className="iconEntity iconsMinicard" alt="Entity"/></div>;
      case "Resource":
          return <div className="minicardKanbanIcon"><span className="iconResource iconsMinicard" alt="Resource"/></div>;
      case "User":
          return <div className="minicardKanbanIcon"><span className="iconUser iconsMinicard" alt="User"/></div>;
      default:
          return <div className="minicardKanbanIcon"><span className="iconAxe iconsMinicard" alt="Axe"/></div>;
    }
  }

  // Display Minicard Title
  displayMinicardTitle(name, title) {
    return <div className="minicardKanbanTitle" title={title}>{name}</div>;
  }

  // Display Minicard Attributes depending on the CurrentView
  displayMinicardContent(currentView, columnHeaders, content) {
    let columns = [];

    // Get Current View Columns
    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Columns')) {
      columns = (currentView.Parameters.find(param => param.Name === 'Columns').Value).split(',');
    }

    return <div className="minicardKanbanContent">
      {columns.map((column, index) => {
        let columnHeader;

        // Get Column corresponding Column Header
        if(columnHeaders.find(columnHeader => columnHeader.FieldName === column)) {
          columnHeader = columnHeaders.find(columnHeader => columnHeader.FieldName === column);
        }

        if((columnHeader && content[column]) || columnHeader.FieldType === 'Rating') {
          // Axe
          if(columnHeader.FieldType === 'Axe') {
            return <div key={index} className="minicardKanbanField">
              <Axe Axe={column} Value={JSON.parse(content[column])} View={'Minicard'}></Axe>
            </div>;
          }
          else if(columnHeader.FieldType === 'AxisTable') {
            return <div key={index} className="minicardKanbanField">
              <Axe Axe={column} Type={columnHeader.FieldType} Value={JSON.parse(content[column])} View={'Minicard'}></Axe>
            </div>;
          }
          // Business_Line / Project / Workpackage / Action / Task / Entity
          else if(columnHeader.FieldName === 'Business_Line' || columnHeader.FieldName === 'Project' || columnHeader.FieldName === 'Workpackage' || columnHeader.FieldName === 'Action' || columnHeader.FieldName === 'Task' || columnHeader.FieldName === 'Entity') {
            return <div key={index} className="minicardKanbanField">
              <Axe Axe={column} Value={JSON.parse(content[column])} View={'Minicard'}></Axe>
            </div>;
          }
          // Boolean
          else if(columnHeader.FieldType === 'Boolean') {
            // Administrator / Cost_Following / Reporting / Static_Data / Users_Config
            if(columnHeader.FieldName === 'Administrator' || columnHeader.FieldName === 'Cost_Following' || columnHeader.FieldName === 'Reporting' || columnHeader.FieldName === 'Static_Data' || columnHeader.FieldName === 'Users_Config') {
              if(content[column] === 'True') {
                return <div key={index} className="minicardKanbanField">
                  <div className="iconBooleanTrue iconShieldGreen iconsMinicardKanban"></div>
                </div>;
              }
              else {
                return <div key={index} className="minicardKanbanField">
                  <div className="iconBooleanFalse iconShieldGrey iconsMinicardKanban"></div>
                </div>;
              }
            }
            // Active
            else if(columnHeader.FieldName === 'Active') {
              if(content[column] === 'True') {
                return <div key={index} className="minicardKanbanField">
                  <div className="iconBooleanTrue iconActiveTrue iconsMinicardKanban"></div>
                </div>;
              }
              else {
                return <div key={index} className="minicardKanbanField">
                  <div className="iconBooleanFalse iconActiveFalse iconsMinicardKanban"></div>
                </div>;
              }
            }
            // External / Internal
            else if(columnHeader.FieldName === 'External') {
              if(content[column] === 'True') {
                return <div key={index} className="minicardKanbanField">
                  <div className="iconBooleanTrue iconExternal iconsMinicardKanban"></div>
                </div>;
              }
              else {
                return <div key={index} className="minicardKanbanField">
                  <div className="iconBooleanFalse iconInternal iconsMinicardKanban"></div>
                </div>;
              }
            }
            // Hightlighted
            else if(columnHeader.FieldName === 'Highlighted') {
              if(content[column] === 'True') {
                return <div key={index} className="minicardKanbanField">
                  <div className="iconBooleanTrue iconHighlighted iconsMinicardKanban"></div>
                </div>;
              }
              else {
                return <div key={index} className="minicardKanbanField">
                  <div className="iconBooleanFalse iconNotHighlighted iconsMinicardKanban"></div>
                </div>;
              }
            }
            // Other Boolean
            else {
              if(content[column] === 'True') {
                return <div key={index} className="minicardKanbanField">
                  <div className="iconBooleanTrue iconCheck iconsMinicardKanban"></div>
                </div>;
              }
              else {
                return <div key={index} className="minicardKanbanField">
                  <div className="iconBooleanFalse iconCircleGrey iconsMinicardKanban"></div>
                </div>;
              }
            }
          }
          // Date
          else if(columnHeader.FieldType === 'Date') {
            return <div key={index} className="minicardKanbanField">
              <DatePickerCalendar Date={content[column]} FieldName={columnHeader.FieldName} Display={'Custom'} Editable={false}></DatePickerCalendar>
            </div>;
          }
          // Double
          else if(columnHeader.FieldType === 'Double' && columnHeader.FieldName !== 'Meteo' && columnHeader.FieldName !== 'Trend') {
            const conditionalFormattings = this.getColumnConditionalFormattings(columnHeader.FieldName);
            const conditionalFormatting = this.getCellConditionalFormatting(conditionalFormattings, content[column]);
            let backgroundColor = '#E7E7E7';
            let color = '#000000';

            if(conditionalFormatting) {
              backgroundColor = conditionalFormatting.BackGroundColor;
              color = conditionalFormatting.Color;
            }

            return <div key={index} className="minicardKanbanField">
              <div className="flex center"><div className="minicardKanbanDouble" style={{ background: backgroundColor, color: color, borderColor: color }}>{content[column]}</div></div>
            </div>;
          }
          // Meteo / Trend
          else if(columnHeader.FieldName === 'Meteo' || columnHeader.FieldName === 'Trend') {
            return <div key={index} className="minicardKanbanField">
              <Axe Axe={column} Value={JSON.parse(content[column])} View={'Minicard'}></Axe>
            </div>;
          }
          // Data Freshness / Meteo Freshness
          else if(columnHeader.FieldName === 'Data_Freshness' || columnHeader.FieldName === 'Meteo_Freshness') {
            return <div key={index} className="minicardKanbanField">
              <Axe Axe={column} Value={JSON.parse(content[column])} View={'Minicard'}></Axe>
            </div>;
          }
          // Percentage
          else if(columnHeader.FieldType === 'Percentage') {
            return <div key={index} className="minicardKanbanProgress">
              <Progress Progress={content[column]}></Progress>
            </div>;
          }
          // Rating
          else if(columnHeader.FieldType === 'Rating') {
            return <div key={index} className="minicardKanbanField">
              <Axe Axe={column} Type={columnHeader.FieldType} Value={content[column]} View={'Minicard'}></Axe>
            </div>;
          }
          // Ressource
          else if(columnHeader.FieldType === 'Resource' || columnHeader.FieldName === 'Project_Manager') {
            return <div key={index} className="minicardKanbanField">
              <Axe Axe={column} Type={columnHeader.FieldType} Value={JSON.parse(content[column])} View={'Minicard'}></Axe>
            </div>;
          }
          // Ressource Table
          else if(columnHeader.FieldType === 'ResourceTable') {
            return <div key={index} className="minicardKanbanField">
              <Axe Axe={column} Type={columnHeader.FieldType} Value={JSON.parse(content[column])} View={'Minicard'}></Axe>
            </div>;
          }
          else if(columnHeader.FieldType === 'Timeline') {
            return <div key={index} className="minicardKanbanTimeline">
              <Timeline View={'MinicardKanban'} StartDate={content['StartDate']} EndDate={content['EndDate']} Status={JSON.parse(content['Status'])}></Timeline>
            </div>;
          }
          else {
            return <div key={index} className="">{content[column]}</div>;
            // return null;
          }
        }
      })}
    </div>;
  }

  render() {
    let { itemId, itemType, currentView, columns, rows, content, hover } = this.state;
    let meetingName, meetingTitle;

    // Meetings Name & Title
    if(itemType === 'Meeting') {
      if(content.EndDate) {
        if(content.Meeting_Type && JSON.parse(content.Meeting_Type).Label.length <= 35) {
          meetingName = JSON.parse(content.Meeting_Type).Label + " - " + this.formatDate(content.EndDate);
          meetingTitle = JSON.parse(content.Meeting_Type).Label + " - " + this.formatDate(content.EndDate);
        }
        else if(content.Meeting_Type && JSON.parse(content.Meeting_Type).Label.length > 35) {
          meetingName = JSON.parse(content.Meeting_Type).Label.substring(0,35) + " - " + this.formatDate(content.EndDate);
          meetingTitle = JSON.parse(content.Meeting_Type).Label + " - " + this.formatDate(content.EndDate);
        }
      }
      else {
        if(content.Meeting_Type && JSON.parse(content.Meeting_Type).Label.length <= 48) {
          meetingName = JSON.parse(content.Meeting_Type).Label;
          meetingTitle = JSON.parse(content.Meeting_Type).Label;
        }
        else if(content.Meeting_Type && JSON.parse(content.Meeting_Type).Label.length > 48) {
          meetingName = JSON.parse(content.Meeting_Type).Label.substring(0,48);
          meetingTitle = JSON.parse(content.Meeting_Type).Label;
        }
      }
    }

    return (
      <div className="">
        {Object.entries(content).length > 0 && itemType && 
          <Card className="minicardKanban">
            {/* MiniCard Header Business_Line/Project/Workpackage/Action/Entity/Resource/AgileBoard/Budget/Axe */}
            {(itemType !== 'Task' && itemType !== 'Decision' && itemType !== 'Issue' && itemType !== 'Meeting' && itemType !== 'Risk') && <Card.Header className="minicardKanbanHeader">
              <Link className="flex" to={`/Card/${itemType}/${itemId}/Home`}>
                {this.displayMinicardIcon(itemType)}
                {this.displayMinicardTitle(content.Name, content.Name)}
              </Link>
            </Card.Header>}
            {/* MiniCard Header Task */}
            {itemType === 'Task' && <Card.Header className="minicardKanbanHeader">
              <Link className="flex" to={`/Card/${itemType}/${itemId}/Details`}>
                {this.displayMinicardIcon(itemType)}
                {this.displayMinicardTitle(content.Name, content.Name)}
              </Link>
            </Card.Header>}
            {/* MiniCard Header Meetings */}
            {itemType === 'Meeting' && <Card.Header className="minicardKanbanHeader">
              <Link className="flex" to={`/Card/${itemType}/${itemId}/Home`}>
                {this.displayMinicardIcon(itemType)}
                {this.displayMinicardTitle(meetingName, meetingTitle)}
              </Link>
            </Card.Header>}
            {/* MiniCard Header Decisions/Issues/Risks */}
            {(itemType === 'Decision' || itemType === 'Issue' || itemType === 'Risk') && <Card.Header className="minicardKanbanHeader">
              {this.displayMinicardIcon(itemType)}
              {this.displayMinicardTitle(content.Name, content.Name)}
            </Card.Header>}
            {/* MiniCard Content */}
            <Card.Body className="minicardKanbanBody">
              {this.displayMinicardContent(currentView, columns, content)}
            </Card.Body>
          </Card>
        }
      </div>
    )
  }
}

export default MiniCardKanban;