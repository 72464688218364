import React, {Component} from 'react';
import { Link } from 'react-router-dom';
// import { MenuComponent } from '@syncfusion/ej2-react-navigations';
import '../Css/App.css';

class NavigationBlock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itemId: null,
      itemType: null,
      blocks: [],
      blockType: null,
      selectedBlock: null,
      dataSource: []
    };

    this.created = this.created.bind(this);
    this.dataStructure = this.dataStructure.bind(this);
    this.menuTemplate = this.menuTemplate.bind(this);
  }

  componentDidMount() {
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const blocks = this.props.Blocks;
    const blockType = this.props.BlockType;

    let selectedBlock = 0;

    if(blocks.findIndex(block => block.Name === blockType)) {
      selectedBlock = blocks.findIndex(block => block.Name === blockType);
    }

    // Build Menu Datasource
    let dataSource = this.dataStructure(blocks);

    this.setState({ itemId, itemType, blocks, blockType, selectedBlock, dataSource });
  }

  componentDidUpdate(prevProps) {
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const blocks = this.props.Blocks;
    const blockType = this.props.BlockType;
    
    if(this.props.ItemId !== prevProps.ItemId || this.props.ItemType !== prevProps.ItemType || this.props.Blocks !== prevProps.Blocks || this.props.BlockType !== prevProps.BlockType) {
      let selectedBlock = 0;

      if(blocks.findIndex(block => block.Name === blockType)) {
        selectedBlock = blocks.findIndex(block => block.Name === blockType);
      }

      // Build Menu Datasource
      let dataSource = this.dataStructure(blocks);

      this.setState({ itemId, itemType, blocks, blockType, selectedBlock, dataSource });
    }
  }

  created() {
    const blocks = this.state.blocks;
    const selectedBlock = this.state.selectedBlock;

    if(this.menu && document.getElementsByClassName("e-menu-item")) {
      for(let i = 0; i < blocks.length; i++) {
        // Menu Item selected
        if(i === selectedBlock) {
          document.getElementsByClassName("e-menu-item")[i].classList.add("navigationItemSelected");
        }
        // Other Menu Items
        else {
          document.getElementsByClassName("e-menu-item")[i].classList.add("navigationItem");
        }
      }
    }
  }

  dataStructure(blocks) {
    const blockType = this.state.blockType;
    let dataSource = [];
    
    if(blocks) {
      blocks.forEach(block => {
        dataSource.push({ label: `${block.Label}`, name: `${block.Name}` });
      });
    }

    // dataSource = [{ text: `Home`, label: `Home`, name: `Home` }, { text: `Details`, label: `Details`, name: `Details` }, { text: `Roadmap`, label: `Roadmap`, name: `Roadmap` }, { text: `Meteo`, label: `Meteo`, name: `Qualitative` }, { text: `Risks`, label: `Risks`, name: `Risks` }, { text: `Issues`, label: `Issues`, name: `Issues` }, { text: `Decisions`, label: `Decisions`, name: `Decisions` }, { text: `Meetings`, label: `Meetings`, name: `Meetings` }, { text: `Budget`, label: `Budget`, name: `Budget` }, { text: `Communication`, label: `Communication`, name: `Communication` }];

    return dataSource;
  }

  menuTemplate(data) {
    const blockType = this.state.blockType;

    // Get current Date for Holidays
    const date = new Date();
    const month = (date.getMonth())+1;
    const year = date.getFullYear();

    if(data.name === blockType) {
      if(data.name !== 'Burned' && data.name !== 'Holidays') {
        return <Link className="navigationLinkSelected" to={`${data.name}`}>{data.label}</Link>;
      }
      else if(data.name === 'Burned') {
        return <Link className="navigationLinkSelected" to={`${data.name}?year=${year}`}>{data.label}</Link>;
      }
      else if(data.name === 'Holidays') {
        return <Link className="navigationLinkSelected" to={`${data.name}?year=${year}&month=${month}`}>{data.label}</Link>;
      }
    }
    else {
      if(data.name !== 'Burned' && data.name !== 'Holidays') {
        return <Link className="navigationLink" to={`${data.name}`}>{data.label}</Link>;
      }
      else if(data.name === 'Burned') {
        return <Link className="navigationLink" to={`${data.name}?year=${year}`}>{data.label}</Link>;
      }
      else if(data.name === 'Holidays') {
        return <Link className="navigationLink" to={`${data.name}?year=${year}&month=${month}`}>{data.label}</Link>;
      }
    }
  }

  render() {
    const { itemId, itemType, blocks, blockType, selectedBlock, dataSource } = this.state;

    // Get current Date for Holidays
    const date = new Date();
    const month = (date.getMonth())+1;
    const year = date.getFullYear();

    return (
      <div className="navigationBlocks">
        {/* <div className="scrollable-menu-control">
          <MenuComponent items={dataSource} template={this.menuTemplate} cssClass="e-custom-scroll" hoverDelay="500" created={this.created} enableScrolling={true} ref={menu=>this.menu=menu}/>
        </div> */}

        {blocks && blocks.map((block, i) => (
          <div key={i} className={(block.Name === blockType ? "navigationItemSelected" : "navigationItem")}>
            {/* Administration & Environment */}
            {(itemType === 'Administration' || itemType === 'Environment') && block.Name !== 'Roadmap' && 
              <Link className={(block.Name === blockType ? "navigationLinkSelected" : "navigationLink")} to={`/${itemType}/${block.Name}`}>{block.Label}</Link>
            }
            {/* Environment Roadmap */}
            {itemType === 'Environment' && block.Name === 'Roadmap' && 
              <Link className={(block.Name === blockType ? "navigationLinkSelected" : "navigationLink")} to={`/${itemType}/${block.Name}?active=${true}`}>{block.Label}</Link>
            }
            {/* Link for all Blocks except Burned & Holidays */}
            {(itemType !== 'Administration' && itemType !== 'Environment') && (block.Name !== 'Burned' && block.Name !== 'Holidays') && 
              <Link className={(block.Name === blockType ? "navigationLinkSelected" : "navigationLink")} to={`/Card/${itemType}/${itemId}/${block.Name}`}>{block.Label}</Link>
            }
            {/* Link for Block Burned (add month & year params) */}
            {(itemType !== 'Administration' && itemType !== 'Environment') && block.Name === 'Burned' && 
              <Link className={(block.Name === blockType ? "navigationLinkSelected" : "navigationLink")} to={`/Card/${itemType}/${itemId}/${block.Name}?year=${year}`}>{block.Label}</Link>
            }
            {/* Link for Block Holidays (add month & year params) */}
            {(itemType !== 'Administration' && itemType !== 'Environment') && block.Name === 'Holidays' && 
              <Link className={(block.Name === blockType ? "navigationLinkSelected" : "navigationLink")} to={`/Card/${itemType}/${itemId}/${block.Name}?year=${year}&month=${month}`}>{block.Label}</Link>
            }
          </div>
        ))}
      </div>
    )
  }
}

export default NavigationBlock;