import React, {Component} from 'react';
import MetaTags from 'react-meta-tags';
import { Button, Form, OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { L10n } from '@syncfusion/ej2-base';
import { ListBoxComponent, Inject, CheckBoxSelection } from '@syncfusion/ej2-react-dropdowns';
import { HeatMapComponent, Inject as HeatMapInject, Adaptor, Legend, Tooltip as HeatMapTooltip } from '@syncfusion/ej2-react-heatmap';
import * as ej2FRlocale from './EJ2_LOCALE/ej2FRlocale.json';
import * as ej2ESlocale from './EJ2_LOCALE/ej2ESlocale.json';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';
import Navbar from './Navbar';
import BlockTitle from './BlockTitle';
import IndicatorsRights from './IndicatorsRights';
import LoadingSpinner from './LoadingSpinner';
import ErrorModification from './ErrorModification';
import PopoverEditValue from './PopoverEditValue';
import PopoverLoadAxes from './PopoverLoadAxes';
import PopoverLoadScopes from './PopoverLoadScopes';
import DatePickerCalendar from './DatePickerCalendar';
import FiltersViewPopup from './FiltersViewPopup';
import FiltersViewPopupDashboard from './FiltersViewPopupDashboard';
import MiniDashboard from './MiniDashboard';
import MiniTree from './MiniTree';
import MiniTable from './MiniTable';
import Kanban from './Kanban';
import MiniPlanning from './MiniPlanning';
import MiniPivot from './MiniPivot';
import MiniChart from './MiniChart.js';
import MiniMap from './MiniMap.js';
import MiniBlockDetails from './MiniBlockDetails';

// Slides Images Import
// import slideBudget from '../Images/Slides_image/SlideBudget.png';
// import slideCard from '../Images/Slides_image/SlideCard.png';
// import slideDashboard from '../Images/Slides_image/SlideDashboard.png';
// import slideHistogramme from '../Images/Slides_image/SlideHistogramme.png';
// import slideIntercalaire from '../Images/Slides_image/SlideIntercalaire.png';
// import slideList from '../Images/Slides_image/SlideListe.png';
// import slideMatrix from '../Images/Slides_image/SlideMatrix.png';
// import slideMatrix_1 from '../Images/Slides_image/SlideMatrix-1.png';
// import slideMeetings from '../Images/Slides_image/SlideMeetings.png';
// import slideOnePager from '../Images/Slides_image/SlideOnePager.png';
// import slidePlanning from '../Images/Slides_image/SlidePlanning.png';
// import slideRadar from '../Images/Slides_image/SlideRadar.png';
// import slideRisks from '../Images/Slides_image/SlideRisks.png';
// import slideRisksArray from '../Images/Slides_image/SlideRisksArray.png';
// import slideRisksArray_1 from '../Images/Slides_image/SlideRisksArray – 1.png';

const API = '/WebAppService/GetCardBlock';
const API_dashboard_info = '/WebAppService/GetCardHomeInformation';
const API_info = '/WebAppService/GetCardBlockInformation';
const API_details = '/WebAppService/GetCommunicationDetails';
const API_unpinned_views = '/WebAppService/GetAllUnpinnedViews';
const API_unpinned_dashboard_views = '/WebAppService/GetAllUnpinnedDashboardViews';
const API_create = '/WebAppService/AddNewPresentation';
const API_build = '/WebAppService/BuildPowerPointPresentation';
const API_get_axes = '/WebAppService/GetAvailableAxesOfSlide';
const API_get_fields = '/WebAppService/GetAvailableFieldsOfSlide';
const API_get_indicators = '/WebAppService/GetAvailableIndicatorsOfSlide';
const API_get_filters = '/WebAppService/GetAvailableFiltersOfSlide';
const API_get_parameters = '/WebAppService/GetAvailableParametersOfSlide';
const API_get_scopes = '/WebAppService/GetAvailableScopesOfSlide';
const API_get_web_view_dashboard = '/WebAppService/GetAvailableWebViewDashboardsOfSlide';
const API_get_web_view = '/WebAppService/GetAvailableWebViewsOfSlide';
const API_save = '/WebAppService/SaveCommunication';
const API_get_slides = '/WebAppService/GetAvailableSlides';
const API_add_slides = '/WebAppService/AddNewSlide';

// Traductions
L10n.load({ fr: ej2FRlocale.fr, es: ej2ESlocale.es });

class BlockCommunicationEdition extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: null,
      authId: null,
      language: null,
      itemId: null,
      itemType: null,
      itemTitle: null,
      blockType: null,
      blockContent: {},
      editable: null,
      favorite: null,
      warnings: 0,
      parents: [],
      communications: [],
      configId: null,
      presentation: {},
      presentationAvailableSlides: [],
      newSlideSelected: {},
      newSlideType: null,
      newSlideWebappView: true,
      slideConfigId: null,
      slideModificationPopup: false,
      slideViewModificationPopup: false,
      slideModificationType: null,
      slideAxes: null,
      slideDateRange: {},
      slideFields: null,
      slideFieldsSelected: null,
      slideFieldsSelectedIds: null,
      slideFieldsSelectedLabels: null,
      slideFilterModif: null,
      slideParamModif: null,
      slideSortModif: null,
      slideIndicators: null,
      slideIndicatorsSelected: null,
      slideIndicatorsValue: null,
      slideFilters: null,
      slideFiltersOperator: "0",
      slideFiltersSelected: [],
      slideFiltersValue: null,
      slideParameters: null,
      slideParameterSelected: null,
      slideParameterValue: null,
      slideScope: null,
      slideScopeValue : {},
      slideAvailableSorts: null,
      slideSort: null,
      slideSortSelected: null,
      slideSortValue: 0,
      slideDashboardViews: [],
      slideViews: [],
      slideUnpinnedDictDashboardViews: {},
      slideUnpinnedDashboardViews: [],
      slideUnpinnedDictViews: {},
      slideUnpinnedViews: [],
      slideViewDashboardSelected: null,
      slideViewDashboardBlocks: null,
      slideViewSelected: null,
      slideViewIdSelected: null,
      slideWebViewBlockInfo: null,
      slideWebViewBlockContent: null,
      slideWebViewFields: null,
      slideWebViewTables: null,
      slideWebViewColumns: null, 
      slideWebViewRows: null,
      slideWebChart: null,
      slideWebKanban: null,
      slideWebMap: null,
      isLoading: false,
      errors: []
    };

    // Data Structure
    this.getData = this.getData.bind(this);
    this.getCommunicationDetails = this.getCommunicationDetails.bind(this);
    this.getUnpinnedDashboardViews = this.getUnpinnedDashboardViews.bind(this);
    this.getUnpinnedViews = this.getUnpinnedViews.bind(this);
    this.dataStructureHeatmap = this.dataStructureHeatmap.bind(this);
    this.getColumnsLabel = this.getColumnsLabel.bind(this);
    this.getRowsLabel = this.getRowsLabel.bind(this);
    this.convertStringtoBoolean = this.convertStringtoBoolean.bind(this);
    
    // Presentation Functions
    this.createPresentation = this.createPresentation.bind(this);
    this.buildPresentation = this.buildPresentation.bind(this);
    this.base64ToArrayBuffer = this.base64ToArrayBuffer.bind(this);
    this.downloadBlobFile = this.downloadBlobFile.bind(this);
    this.updatePresentationName = this.updatePresentationName.bind(this);
    this.updatePresentationTitle = this.updatePresentationTitle.bind(this);
    this.updatePresentationSubtitle = this.updatePresentationSubtitle.bind(this);
    this.updatePresentationTemplate = this.updatePresentationTemplate.bind(this);
    this.updateSlideTitle = this.updateSlideTitle.bind(this);
    this.saveCommunication = this.saveCommunication.bind(this);

    // Slides Functions
    this.getAvailableSlides = this.getAvailableSlides.bind(this);
    this.getAvailableAxes = this.getAvailableAxes.bind(this);
    this.getAvailableFields = this.getAvailableFields.bind(this);
    this.getAvailableIndicators = this.getAvailableIndicators.bind(this);
    this.getAvailableFilters = this.getAvailableFilters.bind(this);
    this.getAvailableParameters = this.getAvailableParameters.bind(this);
    this.getAvailableScopes = this.getAvailableScopes.bind(this);
    this.getAvailableDashboardWebView = this.getAvailableDashboardWebView.bind(this);
    this.getAvailableWebView = this.getAvailableWebView.bind(this);
    this.addSlide = this.addSlide.bind(this);
    this.deleteSlide = this.deleteSlide.bind(this);
    this.duplicateSlide = this.duplicateSlide.bind(this);
    this.previousSlide = this.previousSlide.bind(this);
    this.nextSlide = this.nextSlide.bind(this);
    this.change = this.change.bind(this);
    this.drop = this.drop.bind(this);
    this.slideTemplate = this.slideTemplate.bind(this);
    this.newSlideTemplate = this.newSlideTemplate.bind(this);
    this.getItemTypeLabel = this.getItemTypeLabel.bind(this);
    this.getNewSlideTypeIcon = this.getNewSlideTypeIcon.bind(this);
    this.getSlideCodeLink = this.getSlideCodeLink.bind(this);
    this.getSlideItemTypeIcon = this.getSlideItemTypeIcon.bind(this);
    this.getSlideNumber = this.getSlideNumber.bind(this);

    // Template Functions
    this.triggerSlideModificationPopup = this.triggerSlideModificationPopup.bind(this);
    this.triggerSlideViewPopup = this.triggerSlideViewPopup.bind(this);
    this.templateModificationPopup = this.templateModificationPopup.bind(this);
    this.templateSlideViewPopup = this.templateSlideViewPopup.bind(this);
    this.popupSlideAddDateRange = this.popupSlideAddDateRange.bind(this);
    this.popupSlideAddField = this.popupSlideAddField.bind(this);
    this.popupSlideAddIndicator = this.popupSlideAddIndicator.bind(this);
    this.popupSlideAddFilter = this.popupSlideAddFilter.bind(this);
    this.popupSlideEditFilter = this.popupSlideEditFilter.bind(this);
    this.popupSlideAddParameter = this.popupSlideAddParameter.bind(this);
    this.popupSlideEditParameter = this.popupSlideEditParameter.bind(this);
    // this.popupScopeModification = this.popupScopeModification.bind(this);
    this.popupSlideAddSort = this.popupSlideAddSort.bind(this);
    this.popupSlideEditSort = this.popupSlideEditSort.bind(this);
    this.popupWebViewDashboard = this.popupWebViewDashboard.bind(this);
    this.popupWebViewBlock = this.popupWebViewBlock.bind(this);
    this.closePopup = this.closePopup.bind(this);
    this.closePopupView = this.closePopupView.bind(this);

    // Axe Functions
    this.updateAxe1 = this.updateAxe1.bind(this);
    this.updateAxe2 = this.updateAxe2.bind(this);

    // Dates Functions
    this.initialiseDate = this.initialiseDate.bind(this);
    this.changeDateBornes = this.changeDateBornes.bind(this);
    this.changeDatePeriod = this.changeDatePeriod.bind(this);
    this.changeDateSpotLag = this.changeDateSpotLag.bind(this);
    this.changeDateType = this.changeDateType.bind(this);
    this.deleteDateRange = this.deleteDateRange.bind(this);
    this.formatDate = this.formatDate.bind(this);
    this.formatDateEn = this.formatDateEn.bind(this);
    this.formatDateFr = this.formatDateFr.bind(this);
    this.saveDate = this.saveDate.bind(this);

    // Field Functions
    this.initialiseFieldsSelected = this.initialiseFieldsSelected.bind(this);
    this.createdFields = this.createdFields.bind(this);
    this.changeField = this.changeField.bind(this);
    this.dropFields = this.dropFields.bind(this);
    this.saveFields = this.saveFields.bind(this);

    // Indicators Functions
    this.changeIndicators = this.changeIndicators.bind(this);
    this.deleteIndicator = this.deleteIndicator.bind(this);
    this.saveIndicators = this.saveIndicators.bind(this);

    // Filters Functions
    this.createdFilters = this.createdFilters.bind(this);
    this.createdFiltersModif = this.createdFiltersModif.bind(this);
    this.templateFilters = this.templateFilters.bind(this);
    this.changeFilters = this.changeFilters.bind(this);
    this.changeFiltersValue = this.changeFiltersValue.bind(this);
    this.changeFiltersOperator = this.changeFiltersOperator.bind(this);
    this.triggerSlideEditFilter = this.triggerSlideEditFilter.bind(this);
    this.deleteFilter = this.deleteFilter.bind(this);
    this.saveFilters = this.saveFilters.bind(this);
    this.saveFilterEdit = this.saveFilterEdit.bind(this);

    // Parameters Functions
    this.changeParameters = this.changeParameters.bind(this);
    this.changeParameterSelected = this.changeParameterSelected.bind(this);
    this.deleteParameter = this.deleteParameter.bind(this);
    this.saveParameters = this.saveParameters.bind(this);
    this.saveParameterEdit = this.saveParameterEdit.bind(this);
    this.triggerSlideEditParameter = this.triggerSlideEditParameter.bind(this);

    // Scope Functions
    // this.updateScope = this.updateScope.bind(this);
    this.changeScope = this.changeScope.bind(this);
    this.saveScope = this.saveScope.bind(this);
    
    // Sort Functions
    this.changeSortValue = this.changeSortValue.bind(this);
    this.changeSortSelected = this.changeSortSelected.bind(this);
    this.deleteSort = this.deleteSort.bind(this);
    this.saveSort = this.saveSort.bind(this);
    this.saveSortEdit = this.saveSortEdit.bind(this);
    this.triggerSlideEditSort = this.triggerSlideEditSort.bind(this);

    // Web Views Functions
    this.getDashboardData = this.getDashboardData.bind(this);
    this.getBlockData = this.getBlockData.bind(this);
    // this.getBlockInformation = this.getBlockInformation.bind(this);
    this.getIconView = this.getIconView.bind(this);
    this.getIconBlockType = this.getIconBlockType.bind(this);
    this.changeFontSize = this.changeFontSize.bind(this);
    this.changeViewDashboardSelected = this.changeViewDashboardSelected.bind(this);
    this.changeView = this.changeView.bind(this);
    this.saveViewDashboard = this.saveViewDashboard.bind(this);
    this.saveViewBlock = this.saveViewBlock.bind(this);

    this.updateErrors = this.updateErrors.bind(this);
    this.cleanErrors = this.cleanErrors.bind(this);

    // Template Popover
    this.templatePopoverAxe1 = this.templatePopoverAxe1.bind(this);
    this.templatePopoverAxe2 = this.templatePopoverAxe2.bind(this);
    this.templatePopoverScope = this.templatePopoverScope.bind(this);

    this.table = React.createRef();
    this.tree = React.createRef();
    this.kanban = React.createRef();
    this.planning = React.createRef();
    this.pivot = React.createRef();
    this.chart = React.createRef();
    this.map = React.createRef();
    this.startDate = React.createRef();
    this.endDate = React.createRef();
  }

  async componentDidMount() {
    const login = Authentication.getCookie('login');
    const authId = Authentication.getCookie('authId');
    const language = Authentication.getCookie('language');
    const itemId = this.props.match.params.itemId;
    const itemType = this.props.match.params.itemType;
    const path = this.props.match.path.split('/');
    const blockType = path[path.length-2];
    const configId = this.props.match.params.configId;

    this.setState({ login, authId, language, itemId, itemType, blockType, isLoading: true });

    await this.getData(login, authId, itemId, itemType, blockType, configId);
  }

  async componentDidUpdate(prevProps) {
    const login = Authentication.getCookie('login');
    const authId = Authentication.getCookie('authId');
    const itemId = this.props.match.params.itemId;
    const itemType = this.props.match.params.itemType;
    const path = this.props.match.path.split('/');
    const blockType = path[path.length-2];
    const configId = this.props.match.params.configId;

    if(itemId !== prevProps.match.params.itemId || itemType !== prevProps.match.params.itemType || configId !== prevProps.match.params.configId) {
      this.setState({ itemId, itemType, configId });

      await this.getData(login, authId, itemId, itemType, blockType, configId);
    }
  }

  // Get Block Content from the API
  async getData(login, authId, itemId, itemType, blockType, configId) {
    const language = this.state.language;

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': itemType,
        'ItemId': itemId,
        'BlockType': blockType
      })
    };

    try{
      const response = await fetch(API, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }
      
      const data = await response.json();
      const blockContent = data.GetCardBlockResult;

      if(blockContent) {
        const itemTitle = blockContent.ObjectName;
        const editable = blockContent.InsertDeleteAllowed;
        const favorite = blockContent.IsFavorite;
        const warnings = blockContent.Warnings;
        const parents = blockContent.Parents;
        const communications = blockContent.Communications;

        this.setState({ blockContent, itemTitle, editable, favorite, parents, warnings, configId, communications });

        if(configId != -1) {
          await this.getCommunicationDetails(login, authId, configId);
        }
        else {
          this.createPresentation(login, authId, itemId, itemType);
        }
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  async getCommunicationDetails(login, authId, configId) {
    const language = this.state.language;

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'CommunicationId': configId
      })
    };

    try{
      const response = await fetch(API_details, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }
      
      const data = await response.json();
      const presentation = data.GetCommunicationDetailsResult;
      let slideConfigId = null;

      if(presentation) {
        this.setState({ presentation, isLoading: false });

        if(presentation.Slides) {
          slideConfigId = presentation.Slides[0].ConfigId;

          this.setState({ slideConfigId });
        }

        // Initialise selected Slide adding CSS class
        if(document.getElementById(slideConfigId)) {
          document.getElementById(slideConfigId).parentElement.classList.add("e-selected");
        }
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  async getUnpinnedDashboardViews(login, authId, itemType, blockType, slideSelected) {
    const language = this.state.language;

    this.setState({ isLoading: true });

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': itemType,
        'BlockName': 'Home',
        'CurrentViewId': slideSelected.WebViewDashboardId
      })
    };

    try{
      const response = await fetch(API_unpinned_dashboard_views, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const result = data.GetAllUnpinnedDashboardViewsResult;

      if(result) {
        const slideUnpinnedDictDashboardViews = result;
        let slideUnpinnedDashboardViews = [];

        slideUnpinnedDictDashboardViews.ListGenericDashboardViews.forEach(view => {
          slideUnpinnedDashboardViews.push(view);
        });
  
        slideUnpinnedDictDashboardViews.ListUserDashboardViews.forEach(view => {
          slideUnpinnedDashboardViews.push(view);
        });
        
        this.setState({ slideUnpinnedDictDashboardViews, slideUnpinnedDashboardViews });
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    } catch(error) {
      this.setState({ error });
    }
  }

  async getUnpinnedViews(login, authId, itemType, blockType, slideSelected) {
    const language = this.state.language;

    this.setState({ isLoading: true });

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': itemType,
        'BlockName': blockType,
        'CurrentViewId': slideSelected.WebViewId
      })
    };

    try{
      const response = await fetch(API_unpinned_views, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const result = data.GetAllUnpinnedViewsResult;

      if(result) {
        const slideUnpinnedDictViews = result.dictViews;
        let slideUnpinnedViews = [];

        slideUnpinnedDictViews.forEach((viewType, index) => {
          viewType.Value.ListGenericViews.forEach(view => {
            slideUnpinnedViews.push(view);
          });
    
          viewType.Value.ListUserViews.forEach(view => {
            slideUnpinnedViews.push(view);
          });
        });

        this.setState({ slideUnpinnedDictViews, slideUnpinnedViews, slideSelected });
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    } catch(error) {
      this.setState({ error });
    }
  }

  dataStructureHeatmap(columns, rows, chartData) {
    let heatmap = {};

    heatmap.dataSource = [];
    heatmap.xAis = [];
    heatmap.yAis = [];

    if(chartData) {
      for(let x = 0; x < chartData.length; x++) {
        heatmap.dataSource.push([]);

        for(let y = 0; y < chartData[x].length; y++) {
          // Push ChartData corresponding cell (format value => Progress)
          heatmap.dataSource[x].push(chartData[x][y]);
          // heatmap.dataSource[x].push((chartData[x][y] * 100).toFixed(0));
        }
      }

      heatmap.xAis = this.getColumnsLabel(columns);
      heatmap.yAis = this.getRowsLabel(rows);
    }

    return heatmap;
  }

  getColumnsLabel(columns) {
    let labels = [];

    columns.forEach(column => {
      labels.push(column.Label);
    });
    
    return labels;
  }

  getRowsLabel(rows) {
    let labels = [];

    rows.forEach(row => {
      labels.push(row.Label);
    });

    return labels;
  }

  convertStringtoBoolean(string) {
    if(string === 'true') {
      return true;
    }
    else {
      return false;
    }
  }

  async createPresentation(login, authId, itemId, itemType) {
    const language = this.state.language;

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': itemType,
        'ItemId': itemId,
      })
    };

    try{
      const response = await fetch(API_create, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const result = data.AddNewPresentationResult;

      if(result) {
        this.setState({ presentation: result, isLoading: false });
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }
      
    } catch(error) {
      this.setState({ error });
    }
  }

  async buildPresentation(configId) {
    const { login, authId, language } = this.state;

    this.setState({ isLoading: true });
    
    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'PresentationConfigID': configId
      }),
      timeout: 600000
    };

    try{
      const response = await fetch(API_build, requestOptions);
      
      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const result = data.BuildPowerPointPresentationResult;

      if(result) {
        if(result.IsValid === true) {
          const url = result.Url;
          const fileBase64 = result.FileBase64;
          const fileContent = result.FileContents;
          const type = 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
          const fileName = result.FileName;

          // Convert Base64 File to ArrayBuffer
          // const arrayBuffer = this.base64ToArrayBuffer(fileBase64);

          // Create and Download Blob File
          this.downloadBlobFile(fileContent, type, fileName);
        }
        else {
          this.setState({ errors: result.Errors });
        }

        this.setState({ isLoading: false });
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  base64ToArrayBuffer(fileBase64) {
    const binaryString = window.atob(fileBase64);
    const bytes = new Uint8Array(fileBase64.length);

    return bytes.map((byte, i) => binaryString.charCodeAt(i));
  }

  downloadBlobFile(arrayBuffer, type, fileName) {
    // Convert Bytes Array to Bytes
    const bytes = new Uint8Array(arrayBuffer);

    // Create Blob from Bytes
    const blob = new Blob([bytes], { type: type });
    
    // Create File link
    const link = document.createElement('a');

    if(link.download !== undefined) {
      // Define File link attributes
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      link.style.visibility = 'hidden';

      // Simulate click
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  updatePresentationName(event) {
    const presentation = this.state.presentation;
    const value = event.target.value;

    // Update Presentation name
    presentation.Name = value;

    this.setState({ presentation });
  }

  updatePresentationTitle(event) {
    const presentation = this.state.presentation;
    const value = event.target.value;

    // Update Presentation title
    presentation.Title = value;

    this.setState({ presentation });
  }

  updatePresentationSubtitle(event) {
    const presentation = this.state.presentation;
    const value = event.target.value;

    // Update Presentation subtitle
    presentation.Sub_Title = value;

    this.setState({ presentation });
  }

  updatePresentationTemplate(value) {
    const presentation = this.state.presentation;

    // Update Presentation template
    presentation.Template = value;

    this.setState({ presentation });
  }

  updateSlideTitle(event) {
    const presentation = this.state.presentation;
    const slideConfigId = this.state.slideConfigId;
    const value = event.target.value;

    if(presentation.Slides) {
      if(presentation.Slides.find(item => item.ConfigId === slideConfigId)) {
        // Update Slide field with Input value
        presentation.Slides.find(item => item.ConfigId === slideConfigId)['Title'] = value;
      }
    }

    this.setState({ presentation });
  }

  async saveCommunication(presentation) {
    const { login, authId, language, itemType, itemId, blockType } = this.state;
    let slideConfigId = this.state.slideConfigId;
    let index = 0;

    if(presentation.Slides.findIndex(slide => slide.ConfigId === slideConfigId)) {
      index = presentation.Slides.findIndex(slide => slide.ConfigId === slideConfigId);
    }

    this.setState({ isLoading: true });

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'Communication': presentation
      })
    };

    try{
      const response = await fetch(API_save, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }
      
      const data = await response.json();
      const result = data.SaveCommunicationResult;

      if(result) {
        if(result.IsValid === true) {
          // Redirect to new ConfigId
          if(presentation.ConfigID == -1) {
            this.props.history.push(`/Card/${itemType}/${itemId}/${blockType}/${result.SavedPresentation.ConfigID}`);
          }

          // Update selected Slide ConfigId
          if(result.SavedPresentation.Slides[index]) {
            slideConfigId = result.SavedPresentation.Slides[index].ConfigId;
          }

          this.setState({ presentation: result.SavedPresentation, slideConfigId });

          // Select Current Slide
          if(document.getElementById(slideConfigId)) {
            // Remove e-selected Class in others li elements
            if(document.getElementById("_parent").hasChildNodes()) {
              let children = document.getElementById("_parent").childNodes;
  
              for(var i = 0; i < children.length; i++) {
                children[i].ariaSelected = "false";
                children[i].classList.remove("e-selected");
              }
            }
  
            // Add selected Class to selected slide
            document.getElementById(slideConfigId).parentElement.classList.add("e-selected");
            document.getElementById(slideConfigId).parentElement.ariaSelected = "true";
  
            let id = document.getElementById(slideConfigId).parentElement.id;
            document.getElementById(id).parentElement.parentElement.setAttribute('aria-activedescendant', id);
          }
        }
        else {
          // Update Errors
          this.updateErrors(result.Errors);
        }

        this.setState({ isLoading: false });
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  async getAvailableSlides(login, authId, itemType) {
    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': itemType
      })
    };

    try{
      const response = await fetch(API_get_slides, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }
      
      const data = await response.json();
      const result = data.GetAvailableSlidesResult;
      let presentationAvailableSlides = [];

      if(result) {
        presentationAvailableSlides = result;

        this.setState({ presentationAvailableSlides });
      }
      else {
        // Update Errors
        this.updateErrors(result.Errors);
      }

    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  async getAvailableAxes(login, authId, slideId) {
    const language = this.state.language;

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'SlideId': slideId
      })
    };

    try{
      const response = await fetch(API_get_axes, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }
      
      const data = await response.json();
      const slideAxes = data.GetAvailableAxesOfSlideResult;

      if(slideAxes) {
        this.setState({ slideAxes });
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  async getAvailableFields(login, authId, slideId) {
    const language = this.state.language;
    const presentation = this.state.presentation;
    const slideConfigId = this.state.slideConfigId;

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'SlideId': slideId
      })
    };

    try{
      const response = await fetch(API_get_fields, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }
      
      const data = await response.json();
      const slideFields = data.GetAvailableFieldsOfSlideResult;

      if(slideFields) {
        let slideSelected, slideSortSelected, slideAvailableSorts = [];

        // Find selected Slide
        if(presentation.Slides) {
          if(presentation.Slides.find(item => item.ConfigId === slideConfigId)) {
            slideSelected = presentation.Slides.find(item => item.ConfigId === slideConfigId);
          }
        }

        // Get list of Sort not already selected
        slideFields.forEach(field => {
          if(!slideSelected.ArraySorts.find(sort => sort.FieldId === field.FieldId)) {
            slideAvailableSorts.push(field);
          }
        });

        // Get first element of Sort list
        slideSortSelected = slideAvailableSorts[0];

        this.setState({ slideFields, slideAvailableSorts, slideSortSelected });
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  async getAvailableIndicators(login, authId, slideId) {
    const language = this.state.language;

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'SlideId': slideId
      })
    };

    try{
      const response = await fetch(API_get_indicators, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }
      
      const data = await response.json();
      const slideIndicators = data.GetAvailableIndicatorsOfSlideResult;

      if(slideIndicators) {
        const slideIndicatorsValue = slideIndicators[0];

        this.setState({ slideIndicators, slideIndicatorsValue });
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  async getAvailableFilters(login, authId, itemId, itemType, slideItemId, slideItemType, slideId, filterId) {
    const language = this.state.language;

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemId': itemId,
        'ItemType': itemType,
        'SlideItemId': slideItemId,
        'SlideItemType': slideItemType,
        'SlideId': slideId
      })
    };

    try{
      const response = await fetch(API_get_filters, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }
      
      const data = await response.json();
      const slideFilters = data.GetAvailableFiltersOfSlideResult;
      
      if(slideFilters) {
        let slideFiltersValue = {};

        if(filterId) {
          slideFiltersValue = slideFilters.find(filter => filter.FieldId === filterId);
        }
        else {
          slideFiltersValue = slideFilters[0];
        }

        this.setState({ slideFilters, slideFiltersValue });
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  async getAvailableParameters(login, authId, slideId, paramId) {
    const language = this.state.language;
    
    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'SlideId': slideId
      })
    };

    try{
      const response = await fetch(API_get_parameters, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }
      
      const data = await response.json();
      const slideParameters = data.GetAvailableParametersOfSlideResult;

      if(slideParameters) {
        let slideParamModif = this.state.slideParamModif;
        let slideParameterValue, slideParameterSelected;
        
        if(paramId) {
          slideParameterValue = slideParameters.find(param => param.ParameterId === paramId);
          slideParameterSelected = slideParamModif.Value;
        }
        else {
          slideParameterValue = slideParameters[0];
          slideParameterSelected = slideParameterValue.AvailableValues[0];
        }

        this.setState({ slideParameters, slideParameterValue, slideParameterSelected });
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  async getAvailableScopes(login, authId, itemType, itemId, slideId) {
    const language = this.state.language;

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': itemType,
        'ItemId': itemId,
        'SlideId': slideId
      })
    };

    try{
      const response = await fetch(API_get_scopes, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }
      
      const data = await response.json();
      const slideScope = data.GetAvailableScopesOfSlideResult;

      if(slideScope) {
        this.setState({ slideScope });
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  async getAvailableDashboardWebView(login, authId, itemType) {
    const language = this.state.language;

    this.setState({ isLoading: true });

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': itemType
      })
    };

    try{
      const response = await fetch(API_get_web_view_dashboard, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }
      
      const data = await response.json();
      const slideDashboardViews = data.GetAvailableWebViewDashboardsOfSlideResult;

      if(slideDashboardViews) {
        // if(slideSelected.WebViewDashboardId && slideDashboardViews.find( view => view.ViewDashboardId === slideSelected.WebViewDashboardId)) {
        //   slideViewDashboardSelected = slideDashboardViews.find( view => view.ViewDashboardId === slideSelected.WebViewDashboardId);
        // }
        // else {
        //   slideViewDashboardSelected = slideDashboardViews[0];
        // }

        this.setState({ slideDashboardViews });
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  async getAvailableWebView(login, authId, itemType, slideId) {
    const language = this.state.language;

    this.setState({ isLoading: true });

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'SlideId': slideId,
        'ItemType': itemType
      })
    };

    try{
      const response = await fetch(API_get_web_view, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }
      
      const data = await response.json();
      const slideViews = data.GetAvailableWebViewsOfSlideResult;

      if(slideViews) {
        // if(slideSelected.WebViewId && slideViews.find(view => view.ViewId === slideSelected.WebViewId)) {
        //   slideViewSelected = slideViews.find(view => view.ViewId === slideSelected.WebViewId);
        // }
        // else {
        //   slideViewSelected = slideViews[0];
        // }

        this.setState({ slideViews });
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  async addSlide(presentation, slideId) {
    const { login, authId, itemId, itemType, slideConfigId } = this.state;

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'Presentation': presentation,
        'SlideId': slideId,
        'CardItemType': itemType,
        'CardItemId': itemId
      })
    };

    try{
      const response = await fetch(API_add_slides, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }
      
      const data = await response.json();
      const result = data.AddNewSlideResult;

      if(result) {
        // Push new slide
        presentation.Slides.push(result);

        this.setState({ presentation, slideConfigId: result.ConfigId });

        // Remove selected Class from selected Slide
        if(document.getElementById(slideConfigId)) {
          // Remove e-selected Class in others li elements
          if(document.getElementById("_parent").hasChildNodes()) {
            let children = document.getElementById("_parent").childNodes;
            
            for(var i = 0; i < children.length; i++) {
              children[i].ariaSelected = "false";
              children[i].classList.remove("e-selected");
            }
          }
        }

        // Add selected Class to new Slide
        if(document.getElementById(result.ConfigId)) {
          document.getElementById(result.ConfigId).parentElement.classList.add("e-selected");
          document.getElementById(result.ConfigId).parentElement.ariaSelected = "true";

          let id = document.getElementById(result.ConfigId).parentElement.id;
          document.getElementById(id).parentElement.parentElement.setAttribute('aria-activedescendant', id); 
        }
      }
      else {
        // Update Errors
        this.updateErrors(result.Errors);
      }

    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  deleteSlide() {
    const { presentation, slideConfigId }  = this.state;
    let slideIndex;

    this.setState({ isLoading: true });

    // Find selected Slide index
    if(presentation.Slides) {
      if(presentation.Slides.find(item => item.ConfigId === slideConfigId)) {
        slideIndex = presentation.Slides.findIndex(item => item.ConfigId === slideConfigId);
      }
    }

    // Delete Slide in list
    presentation.Slides.splice(slideIndex, 1);

    // Remove selected Slide
    this.listBox.listData.splice(slideIndex, 1);

    // Update selected Slide on ListBox
    // selected = this.listBox.listData[0].id;

    // if(document.getElementById(selected)) {
    //   document.getElementById(selected).parentElement.classList.add("e-selected");
    // }

    this.setState({ presentation, isLoading: false });
  }

  duplicateSlide() {
    const { language, presentation, slideConfigId }  = this.state;
    let slideIndex, slideDuplicated;
    let minConfigId = 0;

    this.setState({ isLoading: true });

    // Find selected Slide index
    if(presentation.Slides) {
      if(presentation.Slides.find(item => item.ConfigId === slideConfigId)) {
        slideIndex = presentation.Slides.findIndex(item => item.ConfigId === slideConfigId);
      }
    }

    // Copy Slide to duplicate
    slideDuplicated = JSON.parse(JSON.stringify(presentation.Slides[slideIndex]));
    
    // Update Title
    if(slideDuplicated.Title.length <= 18) {
      slideDuplicated.Title = slideDuplicated.Title + " - " + Traduction.translate(language, 'copy');
    }
    else {
      slideDuplicated.Title = slideDuplicated.Title.substring(0,18) + " ..." + " - " + Traduction.translate(language, 'copy');
    }

    // Set ConfigId
    presentation.Slides.forEach(slide => {
      if(minConfigId >= slide.ConfigId) {
        minConfigId = slide.ConfigId;
      }
    });
    
    if(minConfigId < 0) {
      slideDuplicated.ConfigId = minConfigId - 1;
    }
    else {
      slideDuplicated.ConfigId = -1;
    }

    // Add dupliated Slide
    presentation.Slides.splice(slideIndex + 1, 0, slideDuplicated);

    this.setState({ presentation, isLoading: false });
  }

  previousSlide(index) {
    let slideConfigId = this.state.slideConfigId;

    // Remove selected Class from selected Slide
    if(document.getElementById(slideConfigId)) {
      document.getElementById(slideConfigId).parentElement.classList.remove("e-selected");
    }

    // Get previous Slide configId
    if(this.listBox.listData[index-1]) {
      slideConfigId = this.listBox.listData[index-1].id;
    }

    // Add selected Class to previous Slide
    if(document.getElementById(slideConfigId)) {
      document.getElementById(slideConfigId).parentElement.classList.add("e-selected");
    }
  
    this.setState({ slideConfigId });
  }

  nextSlide(index) {
    let slideConfigId = this.state.slideConfigId;

    // Remove selected Class from selected Slide
    if(document.getElementById(slideConfigId)) {
      document.getElementById(slideConfigId).parentElement.classList.remove("e-selected");
    }
    
    // Get next Slide configId
    if(this.listBox.listData[index+1]) {
      slideConfigId = this.listBox.listData[index+1].id;
    }

    // Add selected Class to next slide
    if(document.getElementById(slideConfigId)) {
      document.getElementById(slideConfigId).parentElement.classList.add("e-selected");
    }
    
    this.setState({ slideConfigId });
  }

  change(args) {
    let slideConfigId;

    if(args.items) {
      slideConfigId = args.items[0].id;

      this.setState({ slideConfigId });
    }
  }

  drop(args) {
    let presentation = this.state.presentation;
    const slideConfigId = this.state.slideConfigId;

    // Create temporary Array to store new order
    let slidesTemp = [];

    // Loop through the ListBox Slides
    this.listBox.listData.forEach((slide, index) => {
      if(presentation.Slides.find(item => item.ConfigId === slide.id)) {
        // Update Order_ID
        presentation.Slides.find(item => item.ConfigId === slide.id).Order_ID = index + 1;

        // Update Slides position after Drag&Drop
        slidesTemp[index] = presentation.Slides.find(item => item.ConfigId === slide.id);
      }
    });

    // Update Presentation Slides with temporary Array
    presentation.Slides = slidesTemp;

    this.setState({ presentation });

    // Add selected Class to selected Slide
    if(document.getElementById(slideConfigId)) {
      document.getElementById(slideConfigId).parentElement.classList.add("e-selected");
    }
  }

  slideTemplate(args) {
    return (<div id={args.id} className="slide flex-column cursor">
      {/* Slide Title */}
      {(args.title && args.title.length <= 30) && <Form.Label className="slideTitle grey ml5 cursor">{args.title}</Form.Label>}
      {(args.title && args.title.length > 30) && <Form.Label title={args.title} className="slideTitle grey ml5 cursor">{args.title.substring(0,30) + "..."}</Form.Label>}
      {(!args.title) && <Form.Label className="slideTitle grey ml5 cursor"></Form.Label>}
      {/* Slide item Label*/}
      {(args.itemLabel && args.itemLabel.length <= 30 ) && <Form.Label title={args.itemLabel} className="slideItemLabel grey ml5 cursor">{args.itemLabel}</Form.Label>}
      {(args.itemLabel && args.itemLabel.length > 30) && <Form.Label title={args.itemLabel} className="slideItemLabel grey ml5 cursor">{args.itemLabel.substring(0,30) + "..."}</Form.Label>}
      {(!args.itemLabel) && <Form.Label className="slideItemLabel grey ml5 cursor"></Form.Label>} 
      {/* Slide Image*/}
      <div className="flex justify-content-center">
        <div className={this.getSlideCodeLink(args.codeLink) + " iconsSlide"}></div>
      </div>
    </div>);
  }

  newSlideTemplate(slide) {
    const language = this.state.language;

    return (<div className="inline-flex align-items-center width100p mv10">
      {/* Slide Image */}
      <div className="">
        <div className={"slide slideBorder " + this.getSlideCodeLink(slide.CodeLink) + " iconsSlide"}></div>
      </div>
      {/* Name, Description & Information */}
      <div className="flex flex-column justify-content-center ml20">
        <div className="slideLabel">{slide.Name}</div>
        <div className="">{slide.Description}</div>
        <div className="flex">
          <a target="_self" href={"https://help.tabsters.fr/support/" + Traduction.translate(language, 'locale') + "/communication/" + slide.SlideId}>
            <div className="italic blue">{Traduction.translate(language, 'learn_more')}</div>
          </a> 
        </div>
      </div>
    </div>);
  }

  getItemTypeLabel(itemType) {
    const language = this.state.language;

    switch(itemType) {
      case "Business_Line":
          return Traduction.translate(language, 'business_line');
      case "Project":
          return Traduction.translate(language, 'project');
      case "Workpackage":
          return Traduction.translate(language, 'workpackage');
      case "Global":
          return Traduction.translate(language, 'global');
      case "Title":
          return Traduction.translate(language, 'title');
      default:
          return itemType;
    }
  }

  getNewSlideTypeIcon(itemType) {
    switch(itemType) {
      case "Business_Line":
          return <span className="iconBusinessLine veryBigIcons" alt="Business Line"/>;
      case "Project":
          return <span className="iconProject veryBigIcons" alt="Project"/>;
      case "Workpackage":
          return <span className="iconWorkpackage veryBigIcons" alt="Workpackage"/>;
      case "Action":
          return <span className="iconAction veryBigIcons" alt="Action"/>;
      case "Task":
          return <span className="iconTask veryBigIcons" alt="Task"/>;
      case "Meeting":
          return <span className="iconMeeting veryBigIcons" alt="Meeting"/>;
      case "Entity":
          return <span className="iconEntityBlue veryBigIcons" alt="Entity"/>;
      case "Resource":
          return <span className="iconResource veryBigIcons" alt="Resource"/>;
      case "Title":
          return <span className="iconSlideTitle veryBigIcons" alt="Title"/>;
      case "Global" :
          return <span className="iconAxeBlue veryBigIcons" alt="Global"/>;
      default:
          break;
    }
  }

  getSlideCodeLink(codeLink) {
    switch(codeLink) {
      // Slide Card
      case "BL_Axis_CARD":
      case "Project_Axis_CARD":
      case "Workpackage_Axis_CARD":
      case "Action_Axis_CARD":
      case "Project_CARD":
      case "Project_CARD_From_BL":
      case "PROJECT_CARD_CACEIS":
      case "Workpackage_CARD":
      case "Meeting_CARD":
      case "W_CARD":
      case "P_Card":
      case "P_Card_2":
      case "W_View_Dashboard":
          return "slideCard";
      // Slide List
      case "BL_List":
      case "Projects_List":
      case "W_List":
      case "A_List":
      case "T_List":
      case "Qualitative_Meetings":
      case "W_View_Meetings":
      case "W_View_Roadmap":
      case "W_View_Resources":
          return "slideList";
      // Slide Planning
      case "Business_Lines_Planning":
      case "Projects_Planning":
      case "WP_Planning":
      case "Actions_Planning":
      case "Tasks_Planning":
      case "P_Planning":
      case "Planning_Summary":
          return "slidePlanning";
      // Slide Histogramme
      case "Budget_Metric_1Axe":
      case "Histo_Chart":
      case "Tag_Chart":
          return "slideHistogramme";
      // Slide Dashboard
      case "BL_Dashboard":
      case "P_Dashboard":
          return "slideDashboard";
      // Slide Matrix
      case "A_Progress_Map": 
      case "Breakdown_2Axes":
      case "W_View_Earnings":
      case "W_View_Burned":
      case "W_View_Workload":
          return "slideMatrix";
      // Slide Radar
      case "Performance_Details":
      case "Progress_Details":
          return "slideRadar";
      // Slide Intercalaire
      case "Title":
          return "slideIntercalaire";
      // Slide One Pager
      case "BL_Visual":
      case "P_Visual":
      case "P_Visual_2":
          return "slideOnePager";
      // Slide Budget
      case "Budget":
      case "Budget_Details":
      case "W_View_BudgetDetails":
          return "slideBudget";
      // Slide Meeting
      case "Meetings_Planning":
          return "slideMeetings";
      // Slide Risks
      case "Risks_Matrix":
      case "W_View_Risks":
          return "slideRisks";
      // Slide Risks Array
      case "Qua_Array":
      case "W_View_Issues":
      case "W_View_Decisions":
          return "slideRisksArray";
      default:
          return "slideCard";
    }
  }

  getSlideNumber(slide) {
    // Get Slide index in List of Slides
    if(this.listBox.listData.find(item => item.id === slide.ConfigId)) {
      return this.listBox.listData.findIndex((item) => item.id === slide.ConfigId) + 1;
    }
    // After Save Presentation
    else if(this.listBox.listData[this.listBox.listData.length - 1] && this.listBox.listData[this.listBox.listData.length - 1].id == -1) {
      return this.listBox.listData.length;
    }
    // New Slide return the last index
    else {
      return this.listBox.listData.length + 1;
    }
  }

  getSlideItemTypeIcon(itemType) {
    if(itemType.includes('CustomField')) {
      return <span className="iconAxeDark iconsSlideParameters" alt="CustomField"/>;
    }
    else {
      switch(itemType) {
        case "Business_Line":
            return <span className="iconBusinessLineDark iconsSlideParameters" alt="Business Line"/>;
        case "Project":
            return <span className="iconProjectDark iconsSlideParameters" alt="Project"/>;
        case "Workpackage":
            return <span className="iconWorkpackageDark iconsSlideParameters" alt="Workpackage"/>;
        case "Action":
            return <span className="iconActionDark iconsSlideParameters" alt="Action"/>;
        case "Task":
            return <span className="iconTaskDark iconsSlideParameters" alt="Task"/>;
        case "Meeting":
            return <span className="iconMeetingDark iconsSlideParameters" alt="Meeting"/>;
        case "Entity":
            return <span className="iconEntityDark iconsSlideParameters" alt="Global"/>;
        case "Resource":
            return <span className="iconResourceDark iconsSlideParameters" alt="Global"/>;
        case "Title":
            return <span className="iconSlideTitleDark iconsSlideParameters" alt="Title"/>;
        case "Global":
            return <span className="iconAxeDark iconsSlideParameters" alt="Global"/>;
        default:
            break;
      }
    }
  }

  // Trigger the Popup by type of Slide modification
  triggerSlideModificationPopup(type) {
    const { login, authId, itemId, itemType, presentation, slideConfigId, slideModificationPopup } = this.state;
    let slideItemType, slideItemId, slideId;
    let slideModificationType;
    
    if(presentation.Slides) {
      if(presentation.Slides.find(slide => slide.ConfigId === slideConfigId)) {
        slideItemId = presentation.Slides.find(slide => slide.ConfigId === slideConfigId).ItemId;
        slideItemType = presentation.Slides.find(slide => slide.ConfigId === slideConfigId).ItemType;
        slideId = presentation.Slides.find(slide => slide.ConfigId === slideConfigId).SlideId;
      }
    }

    if(!slideModificationPopup) {
      switch(type) {
        case "AddSlide":
            slideModificationType = "AddSlide";
            this.getAvailableSlides(login, authId, itemType);
            break;
        // case "Axes":
        //     this.getAvailableAxes(login, authId, slideId);
        //     slideModificationType = "Axes";
        //     break;
        case "AddDateRange":
            slideModificationType = "AddDateRange";
            this.initialiseDate();
            break;
        case "AddField":
            slideModificationType = "AddField";
            this.getAvailableFields(login, authId, slideId);
            this.initialiseFieldsSelected();
            break;
        case "AddFilter":
            slideModificationType = "AddFilter";
            this.getAvailableFilters(login, authId, itemId, itemType, slideItemId, slideItemType, slideId);
            break;
        case "AddIndicator":
            slideModificationType = "AddIndicator";
            this.getAvailableIndicators(login, authId, slideId);
            break;
        case "AddParameter":
            slideModificationType = "AddParameter";
            this.getAvailableParameters(login, authId, slideId);
            break;
        // case "Scopes":
        //     this.getAvailableScopes(login, authId, itemType, itemId, slideId);
        //     slideModificationType = "Scopes";
        //     break;
        case "AddSort":
            slideModificationType = "AddSort";
            this.getAvailableFields(login, authId, slideId);          
            break;
        default:
            break;
      }

      this.setState({ slideModificationPopup: true, slideModificationType });
    }
  }

  // Trigger the Popup by type of Slide View modification
  async triggerSlideViewPopup(type) {
    const { login, authId, itemType, itemId, presentation, slideConfigId, slideViewModificationPopup } = this.state;
    let slideSelected, slideItemType, slideItemId, slideId, slideWebViewBlockType, slideModificationType;
    
    if(presentation.Slides) {
      if(presentation.Slides.find(slide => slide.ConfigId === slideConfigId)) {
        slideSelected = presentation.Slides.find(slide => slide.ConfigId === slideConfigId);

        slideItemType = slideSelected.ItemType;
        slideItemId = slideSelected.ItemId;
        slideId = slideSelected.SlideId;

        if(slideSelected.CodeLink.includes("W_View")) {
          slideWebViewBlockType = slideSelected.CodeLink.substring(7);
        }
      }
    }

    if(!slideViewModificationPopup) {
      switch(type) {
        case 'WebViewDashboard':
            slideModificationType = "WebViewDashboard";

            // Get Available Web View
            await this.getAvailableDashboardWebView(login, authId, slideItemType);

            // Get Unpinned Views
            await this.getUnpinnedDashboardViews(login, authId, itemType, slideWebViewBlockType, slideSelected);

            const slideDashboardViews = this.state.slideDashboardViews;
            const slideUnpinnedDashboardViews = this.state.slideUnpinnedDashboardViews;
            let slideViewDashboardSelected;

            // Get Slide View selected Info & Data
            if(slideSelected.WebViewDashboardId && slideDashboardViews.find(view => view.ViewDashboardId === slideSelected.WebViewDashboardId)) {
              slideViewDashboardSelected = slideDashboardViews.find(view => view.ViewDashboardId === slideSelected.WebViewDashboardId);
            }
            else if(slideSelected.WebViewDashboardId && slideUnpinnedDashboardViews.find(view => view.ViewDashboardId === slideSelected.WebViewDashboardId)) {
              slideViewDashboardSelected = slideUnpinnedDashboardViews.find(view => view.ViewDashboardId === slideSelected.WebViewDashboardId);
            }
            else {
              slideViewDashboardSelected = slideDashboardViews[0];
            }
            
            await this.getDashboardData(login, authId, slideItemId, slideItemType, slideWebViewBlockType, slideViewDashboardSelected);
            break;
        case 'WebView':
            slideModificationType = "WebView";
            
            // Get Available Web View
            await this.getAvailableWebView(login, authId, slideItemType, slideId);

            // Get Unpinned Views
            await this.getUnpinnedViews(login, authId, itemType, slideWebViewBlockType, slideSelected);

            const slideViews = this.state.slideViews;
            const slideUnpinnedViews = this.state.slideUnpinnedViews;
            let slideViewSelected;

            // Get Slide View selected Info & Data
            if(slideSelected.WebViewId && slideViews.find(view => view.ViewId === slideSelected.WebViewId)) {
              slideViewSelected = slideViews.find(view => view.ViewId === slideSelected.WebViewId);
            }
            else if(slideSelected.WebViewId && slideUnpinnedViews.find(view => view.ViewId === slideSelected.WebViewId)) {
              slideViewSelected = slideUnpinnedViews.find(view => view.ViewId === slideSelected.WebViewId);
            }
            else {
              slideViewSelected = slideViews[0];
            }

            // Get View Data
            // await this.getBlockInformation(login, authId, slideItemId, slideItemType, slideWebViewBlockType, slideViewSelected.ViewId);
            await this.getBlockData(login, authId, slideItemId, slideItemType, slideWebViewBlockType, slideViewSelected);
            break;
        default:
            break;
      }

      this.setState({ slideViewModificationPopup: true, slideModificationType });
    }
  }

  // Template of Slide modification Popup
  templateModificationPopup() {
    const slideModificationType = this.state.slideModificationType;

    if(slideModificationType === "AddSlide") {
      return this.popupAddSlide();
    }
    else if(slideModificationType === "AddDateRange") {
      return this.popupSlideAddDateRange();
    }
    else if(slideModificationType === "AddField") {
      return this.popupSlideAddField();
    }
    else if(slideModificationType === "AddIndicator") {
      return this.popupSlideAddIndicator();
    }
    else if(slideModificationType === "AddFilter") {
      return this.popupSlideAddFilter();
    }
    else if(slideModificationType === "EditFilter") {
      return this.popupSlideEditFilter();
    }
    else if(slideModificationType === "AddParameter") {
      return this.popupSlideAddParameter();
    }
    else if(slideModificationType === "EditParameter") {
      return this.popupSlideEditParameter();
    }
    // else if(slideModificationType === "Scopes") {
    //   return this.popupScopeModification();
    // }
    else if(slideModificationType === "AddSort") {
      return this.popupSlideAddSort();
    }
    else if(slideModificationType === "EditSort") {
      return this.popupSlideEditSort();
    }
    else {
      return;
    }
  }
  
  // Template of Slide View modification Popup
  templateSlideViewPopup() {
    const { login, authId, itemType, blockType, slideModificationType } = this.state;

    if(slideModificationType === 'WebViewDashboard') {
      return this.popupWebViewDashboard();
    }
    else if(slideModificationType === 'WebView') {
      return this.popupWebViewBlock();
    }
  }

  // Add new Slide Popup
  popupAddSlide() {
    const { language, presentation, presentationAvailableSlides, newSlideType, newSlideSelected, newSlideWebappView } = this.state;

    return (<div className="flex flex-column popupAddSlide">
      {/* Available Slides */}
      {!newSlideType && <div className="">
        {/* Label */}
        <div className="slideLabel mb10">{Traduction.translate(language, 'add_new_slide')}</div>

        <div className="newSlideType">
          {presentationAvailableSlides.map((type, index) => {
            return <div key={index} className="flex flex-column align-items-center cursor" onClick={() => this.setState({ newSlideType: type.Key })}>
              <div className="">{this.getNewSlideTypeIcon(type.Key)}</div>
              <div className="slideLabel mv10">{this.getItemTypeLabel(type.Key)}</div>
            </div>
          })}
        </div>
      </div>}

      {newSlideType && <div className="">
        {/* Navbar */}
        <div className="flex align-items-center width100p h30">
          <div className={(newSlideWebappView ? "slidetypeSelected" : "slidetype")} onClick={() => this.setState({ newSlideWebappView: true })}>{Traduction.translate(language, 'add_from_webapp_view')}</div>
          <div className={(!newSlideWebappView ? "slidetypeSelected" : "slidetype") + " ml10"} onClick={() => this.setState({ newSlideWebappView: false })}>{Traduction.translate(language, 'add_from_template')}</div>
        </div>
        
        {/* Web view slide */}
        {newSlideWebappView && <div className="flex flex-column newSlideInner">
          <div className="flex flex-column scrollbar-y overflow-y">
            {presentationAvailableSlides.find(type => type.Key === newSlideType).Value.filter(value => value.CodeLink.includes("W_View")).map((value, index) => {
              return <div key={index} className={"flex " + (value.SlideId === newSlideSelected.SlideId ? "newSlideCodeLinkBordered" : "newSlideCodeLink")} onClick={() => this.setState({ newSlideSelected: value })}>{this.newSlideTemplate(value)}</div>
            })}
          </div>
        </div>}

        {/* Template slide */}
        {!newSlideWebappView && <div className="flex flex-column newSlideInner">
          <div className="flex flex-column scrollbar-y overflow-y">
            {presentationAvailableSlides.find(type => type.Key === newSlideType).Value.filter(value => !value.CodeLink.includes("W_View")).map((value, index) => {
              return <div key={index} className={"flex " + (value.SlideId === newSlideSelected.SlideId ? "newSlideCodeLinkBordered" : "newSlideCodeLink")} onClick={() => this.setState({ newSlideSelected: value })}>{this.newSlideTemplate(value)}</div>
            })}
          </div>
        </div>}
      </div>}

      {/* Button Validate or Cancel */}
      <div className="flex flex-end">
        {newSlideType && 
          <Button className="fs12 bold brd-radius mr15" variant="primary" onClick={() => { this.addSlide(presentation, newSlideSelected.SlideId); this.setState({ newSlideType: null, newSlideSelected: {} }); this.closePopup() }}>{Traduction.translate(language, 'validate')}</Button>
        }
        <Button className="fs12 bold brd-radius" variant="warning" onClick={() => { this.setState({ newSlideType: null, newSlideSelected: {} }); this.closePopup() }}>{Traduction.translate(language, 'cancel')}</Button>
      </div>
    </div>);
  }

  // Date Range Modification Popup
  popupSlideAddDateRange() {
    const { language, presentation, slideConfigId, slideDateRange } = this.state;
    let slideSelected;

    // Find selected Slide
    if(presentation.Slides) {
      if(presentation.Slides.find(item => item.ConfigId === slideConfigId)) {
        slideSelected = presentation.Slides.find(item => item.ConfigId === slideConfigId);
      }
    }

    return (<div className="flex flex-column">
      {/* RangeType is 1 */}
      <div className={`inline-flex cursor mh5 mv5 ${slideDateRange.RangeType === 1 ? "slideDateRangeSelected" : "slideDateRangeLabel"}`} onClick={() => this.changeDateType(1)}>
        <div className="slideLabel mr10">{Traduction.translate(language, 'filter_between')}</div>
        <div className="communicationSlideDateRange"><DatePickerCalendar ref={this.startDate} Date={slideDateRange.BorneInf} FieldName={"StartDate"} Limitation={slideDateRange.BorneSup} Display={'Datepicker'} Editable={true} onDateChange={this.changeDateBornes}></DatePickerCalendar></div>
        <div className="slideLabel mh10">{Traduction.translate(language, 'and')}</div>
        <div className="communicationSlideDateRange"><DatePickerCalendar ref={this.endDate} Date={slideDateRange.BorneSup} FieldName={"EndDate"} Limitation={slideDateRange.BorneInf} Display={'Datepicker'} Editable={true} onDateChange={this.changeDateBornes}></DatePickerCalendar></div>
      </div>

      {/* RangeType is 2 */}
      <div className={`inline-flex align-items-center cursor mh5 mt10 mb20 ${slideDateRange.RangeType === 2 ? "slideDateRangeSelected" : "slideDateRangeLabel"}`} onClick={() => this.changeDateType(2)}>
        <div className="slideLabel mr10">{Traduction.translate(language, 'filter_on_period')}</div>
        
        {/* Period select */}
        <Form.Control className="slideParametersSelect mh10" as="select" value={slideDateRange.Period} onChange={(e) => this.changeDatePeriod(e)}>
          <option key={0} value={7}>{Traduction.translate(language, 'a_week')}</option>
          <option key={1} value={30}>{Traduction.translate(language, 'a_month')}</option>
          <option key={2} value={365}>{Traduction.translate(language, 'a_year')}</option>
        </Form.Control>

        <div className="slideLabel mh10">{Traduction.translate(language, 'with_lag_of')}</div>

        {/* Lag select */}
        <Form.Control id="spotlag" className="slideSpotLagTextArea communicationSubtitle orange-light" as="textarea" rows="1" placeholder="" value={slideDateRange.SpotLag} onChange={(e) => this.changeDateSpotLag(e)}/>
        
        <div className="slideLabel mh10">{Traduction.translate(language, 'days')}</div>
      </div>
      {/* Button Validate or Cancel */}
      <div className="flex flex-end">
        <Button className="fs12 bold brd-radius mr15" variant="primary" onClick={() => { this.closePopup(); this.saveDate() }}>{Traduction.translate(language, 'validate')}</Button>
        <Button className="fs12 bold brd-radius" variant="warning" onClick={() => this.closePopup()}>{Traduction.translate(language, 'cancel')}</Button>
      </div>
    </div>);
  }

  // Fields Modification Popup
  popupSlideAddField() {
    const { language, presentation, slideConfigId, slideFields, slideFieldsSelectedIds, slideFieldsSelectedLabels } = this.state;
    let slideSelected, checkedIds, checkedLabels, checkedFields = [], columnList = [];

    this.selectionSettingsField = { showCheckbox: true };

    // Define Fields datasource attributes for ListBox
    if(presentation.Slides) {
      if(presentation.Slides.find(item => item.ConfigId === slideConfigId)) {
        // Get selected Slide
        slideSelected = presentation.Slides.find(item => item.ConfigId === slideConfigId);

        // Get Ids & Labels from selected Fields
        if(slideFieldsSelectedIds) {
          checkedIds = slideFieldsSelectedIds.split(',');
          checkedLabels = slideFieldsSelectedLabels.split(', ');
          // checkedIds = slideSelected.ArrayFields.ArrayFieldIds.split(',');
          // checkedLabels = slideSelected.ArrayFields.Labels.split(', ');

          checkedLabels.forEach((label, index) => {
            checkedFields.push({ id: checkedIds[index], label: label });
          });
        }
      }
    }

    // Add checked Fields
    checkedFields.forEach(field => {
      columnList.push({ id: field.id, text: field.label, value: field.label });
    });

    // Add other Fields
    if(slideFields) {
      slideFields.forEach(field => {
        // Check if Column is not already include in Checkbox list
        if(!columnList.find(column => column.id == field.FieldId)) {
          columnList.push({ id: field.FieldId, text: field.Label, value: field.Label });
        }
      });
    }

    return (<div className="">
      {slideFields && <div className="">
        <div className="slideLabel">{Traduction.translate(language, 'define_and_reorder_columns')}</div>

        <div className="mv20">
          <ListBoxComponent locale={Traduction.translate(language, 'locale')} dataSource={columnList} height={'400px'} selectionSettings={this.selectionSettingsField} created={this.createdFields} change={this.changeField} drop={this.dropFields} allowDragAndDrop={true} ref={listBoxField=>this.listBoxField=listBoxField}>
            <Inject services={[ CheckBoxSelection ]}/>
          </ListBoxComponent>
        </div>
      </div>}
      <div className="flex flex-end">
        <Button className="fs12 bold brd-radius mr15" variant="primary" onClick={() => { this.closePopup(); this.saveFields() }}>{Traduction.translate(language, 'validate')}</Button>
        <Button className="fs12 bold brd-radius" variant="warning" onClick={() => this.closePopup()}>{Traduction.translate(language, 'cancel')}</Button>
      </div>
    </div>);
  }

  // Indicators Modification Popup
  popupSlideAddIndicator() {
    const { language, slideIndicators, slideIndicatorsValue } = this.state;

    return (<div className="">
      {slideIndicators && <div className="inline-flex align-items-center">
        <div className="slideLabel">{Traduction.translate(language, 'add_following_indicator')}</div>

        {/* Indicator select */}
        {slideIndicatorsValue && <Form.Control className="slideParametersSelect mh10" as="select" value={slideIndicatorsValue.FieldId} onChange={(e) => this.changeIndicators(e)}>
          {slideIndicators.map(item => {
            return <option key={item.FieldId} value={item.FieldId}>{item.Label}</option>
          })}
        </Form.Control>}
      </div>}

      {/* Button Validate or Cancel */}
      <div className="flex flex-end">
        <Button className="fs12 bold brd-radius mr15" variant="primary" onClick={() => { this.closePopup(); this.saveIndicators()} }>{Traduction.translate(language, 'validate')}</Button>
        <Button className="fs12 bold brd-radius" variant="warning" onClick={() => this.closePopup()}>{Traduction.translate(language, 'cancel')}</Button>
      </div>
    </div>);
  }

  // Filters Modification Popup
  popupSlideAddFilter() {
    const { language, slideFilters, slideFiltersValue, slideFiltersOperator } = this.state;
    let columnList = [];

    this.selectionSettingsFilter = { showCheckbox: true };

    // Define Filters datasource attributes for ListBox
    if(slideFiltersValue) {
      slideFiltersValue.AvailableValues.forEach(filter => {
        columnList.push({ text: filter.Key, value: filter.Value, key: filter.Key });
      });
    }

    return (<div className="">
      {slideFilters && <div className="inline-flex align-items-center">
        <div className="slideLabel">{Traduction.translate(language, 'field')}</div>

        {/* Field select */}
        {slideFiltersValue && <Form.Control className="slideParametersSelect mh10" as="select" value={slideFiltersValue.FieldId} onChange={(e) => this.changeFiltersValue(e)}>
          {slideFilters.map(item => {
            return <option key={item.FieldId} value={item.FieldId}>{item.Field}</option>
          })}
        </Form.Control>}

        <div className="slideLabel">{Traduction.translate(language, 'must')}</div>

        {/* Operator value select */}
        <Form.Control  className="slideParametersSelect mh10" as="select" value={slideFiltersOperator} onChange={(e) => this.changeFiltersOperator(e)}>
          <option key={0} value={0}>{Traduction.translate(language, 'be_included')}</option>
          <option key={1} value={1}>{Traduction.translate(language, 'not_be_included')}</option>
          <option key={2} value={2}>{Traduction.translate(language, 'include_all_values')}</option>
        </Form.Control>

        <div className="slideLabel">{Traduction.translate(language, 'in_list')}</div>
      </div>}

      {/* Filter Value select */}
      <div className="mv20">
        {slideFiltersValue && <ListBoxComponent locale={Traduction.translate(language, 'locale')} dataSource={columnList} height={'300px'} selectionSettings={this.selectionSettingsFilter} itemTemplate={this.templateFilters} created={this.createdFilters} change={this.changeFilters} allowDragAndDrop={false} ref={listBoxFilter=>this.listBoxFilter=listBoxFilter}>
          <Inject services={[ CheckBoxSelection ]}/>
        </ListBoxComponent>}
      </div>
      
      {/* Button Validate or Cancel */}
      <div className="flex flex-end">
        <Button className="fs12 bold brd-radius mr15" variant="primary" onClick={() => { this.closePopup(); this.saveFilters() }}>{Traduction.translate(language, 'validate')}</Button>
        <Button className="fs12 bold brd-radius" variant="warning" onClick={() => this.closePopup()}>{Traduction.translate(language, 'cancel')}</Button>
      </div>
    </div>);
  }

  // Filter Edit Modification Popup
  popupSlideEditFilter() {
    const { language, slideFilters, slideFiltersValue, slideFiltersOperator, slideFilterModif } = this.state;
    let labelOperator, columnList = [];

    switch(slideFilterModif.Operator) {
      case "OR": 
          labelOperator = Traduction.translate(language, 'be_included');
          break;
      case "AND": 
          labelOperator = Traduction.translate(language, 'include_all_values');
          break;
      case "NOT": 
          labelOperator = Traduction.translate(language, 'not_be_included');
          break;
    }

    this.selectionSettingsFilter = { showCheckbox: true };

    // Define Filters datasource attributes for ListBox
    if(slideFiltersValue) {
      // Add checked values
      slideFiltersValue.AvailableValues.forEach(filter => {
        if(slideFilterModif.Filter.includes(filter.Key)) {
          columnList.push({ text: filter.Key, value: filter.Value, key: filter.Key });
        }
      });

      // Add other values
      slideFiltersValue.AvailableValues.forEach(filter => {
        if(!slideFilterModif.Filter.includes(filter.Key)) {
          columnList.push({ text: filter.Key, value: filter.Value, key: filter.Key });
        }
      });
    }

    return (<div className="">
      {slideFilters && <div className="inline-flex align-items-center">
        <div className="slideLabel">{Traduction.translate(language, 'field')}</div>

        {/* Field select */}
        <Form.Label className="slideParametersSelect mh10">{slideFilterModif.Field}</Form.Label>

        <div className="slideLabel">{Traduction.translate(language, 'must')}</div>

        {/* Operator value select */}
        <Form.Label className="slideParametersSelect mh10">{labelOperator}</Form.Label>

        <div className="slideLabel">{Traduction.translate(language, 'in_list')}</div>
      </div>}

      {/* Filter Value select */}
      <div className="mv20">
        {slideFiltersValue && <ListBoxComponent dataSource={columnList} height={'300px'} created={this.createdFiltersModif} selectionSettings={this.selectionSettingsFilter} itemTemplate={this.templateFilters} change={this.changeFilters} allowDragAndDrop={false} ref={listBoxFilter2=>this.listBoxFilter2=listBoxFilter2}>
          <Inject services={[ CheckBoxSelection ]}/>
        </ListBoxComponent>}
      </div>
      
      {/* Button Validate or Cancel */}
      <div className="flex flex-end">
        <Button className="fs12 bold brd-radius mr15" variant="primary" onClick={() => { this.closePopup(); this.saveFilterEdit() }}>{Traduction.translate(language, 'validate')}</Button>
        <Button className="fs12 bold brd-radius" variant="warning" onClick={() => this.closePopup()}>{Traduction.translate(language, 'cancel')}</Button>
      </div>
    </div>);
  }

  // Parameters Modification Popup
  popupSlideAddParameter() {
    const { language, slideParameters, slideParameterValue, slideParameterSelected } = this.state;

    return (<div className="">
      {slideParameters && <div className="inline-flex align-items-center">
        <div className="slideLabel">{Traduction.translate(language, 'parameter')}</div>

        {/* Parameter select */}
        {slideParameterValue && <Form.Control className="slideParametersSelect mh10" as="select" value={slideParameterValue.ParameterId} onChange={(e) => this.changeParameters(e)}>
          {slideParameters.map(item => {
            return <option key={item.ParameterId} value={item.ParameterId}>{item.ParameterLabel}</option>
          })}
        </Form.Control>}
        
        <div className="slideLabel">{Traduction.translate(language, 'is_equal_to')}</div>

        {/* Parameter Value select */}
        {slideParameterValue.AvailableValues && <Form.Control className="slideParametersSelect mh10" as="select" value={slideParameterSelected} onChange={(e) => this.changeParameterSelected(e)}>
          {slideParameterValue.AvailableValues.map(item => {
            return <option key={item} value={item}>{item}</option>
          })}
        </Form.Control>}
      </div>}

      {/* Validate & Cancel Buttons */}
      <div className="flex flex-end">
        <Button className="fs12 bold brd-radius mr15" variant="primary" onClick={() => { this.closePopup(); this.saveParameters() }}>{Traduction.translate(language, 'validate')}</Button>
        <Button className="fs12 bold brd-radius" variant="warning" onClick={() => this.closePopup()}>{Traduction.translate(language, 'cancel')}</Button>
      </div>
    </div>);
  }

  popupSlideEditParameter() {
    const { language, slideParameters, slideParameterValue, slideParameterSelected, slideParamModif } = this.state;

    return (<div className="">
      {slideParameters && <div className="inline-flex align-items-center">
        <div className="slideLabel">{Traduction.translate(language, 'parameter')}</div>

        {/* Parameter select */}
        <Form.Label className="slideParametersSelect mh10">{slideParamModif.Parameter}</Form.Label>

        <div className="slideLabel">{Traduction.translate(language, 'is_equal_to')}</div>

        {/* Parameter Value select */}
        {slideParameterValue.AvailableValues && <Form.Control className="slideParametersSelect mh10" as="select" value={slideParameterSelected} onChange={(e) => this.changeParameterSelected(e)}>
          {slideParameterValue.AvailableValues.map(item => {
            return <option key={item} value={item}>{item}</option>
          })}
        </Form.Control>}
      </div>}

      {/* Validate & Cancel Buttons */}
      <div className="flex flex-end">
        <Button className="fs12 bold brd-radius mr15" variant="primary" onClick={() => { this.closePopup(); this.saveParameterEdit() }}>{Traduction.translate(language, 'validate')}</Button>
        <Button className="fs12 bold brd-radius" variant="warning" onClick={() => this.closePopup()}>{Traduction.translate(language, 'cancel')}</Button>
      </div>
    </div>);
  }

  // Scopes Modification Popup
  // popupScopeModification() {
  //   const { presentation, language, slideScope, slideConfigId } = this.state;
  //   let slideScopeValue = this.state.slideScopeValue;
  //   let label, labelCancel, labelValidate;

  //   if(language === 'Français') {
  //     label = (<div className="inline-flex">
  //       <div className="slideLabel mr5">Séléctionnez le</div>
  //       <div className="communicationSubtitle">périmètre</div>
  //       <div className="slideLabel ml5">du diapositive</div>
  //     </div>);
  //     labelValidate = "Valider";
  //     labelCancel = "Annuler";
  //   }
  //   else if(language === 'English') {
  //     label = (<div className="inline-flex">
  //       <div className="slideLabel mr5">Select slide</div>
  //       <div className="communicationSubtitle">scope</div>
  //     </div>);
  //     labelValidate = "Ok";
  //     labelCancel = "Cancel";
  //   }

  //   return (<div className="">
  //     {slideScope && <div className="">
  //       <div className="slideLabel">{label}</div>
  //       {/* Scope select */}
  //       <Form.Control className="slideParametersSelect mh10" as="select" value={slideScopeValue.ItemId} onChange={(e) => this.updateScope(e)}>
  //         {slideScope.map(item => {
  //           return <option key={item.ItemId} value={item.ItemId}>{item.ItemType} {item.Name}</option>
  //         })}
  //       </Form.Control>
  //     </div>}
  //     {/* Button Validate or Cancel */}
  //     <div className="flex flex-end">
  //       <Button className="fs12 bold brd-radius mr15" variant="primary" onClick={() => { this.closePopup(); this.saveScope()} }>{labelValidate}</Button>
  //       <Button className="fs12 bold brd-radius" variant="warning" onClick={() => this.closePopup()}>{labelCancel}</Button>
  //     </div>
  //   </div>);
  // }

  // Sort Modification Popup
  popupSlideAddSort() {
    const { language, presentation, slideConfigId, slideFields, slideAvailableSorts, slideSortSelected, slideSortValue } = this.state;
    let slideSorts = [], indexToDelete = -1;

    // Get Slide selected Sorts
    if(presentation.Slides.find(slide => slide.ConfigId === slideConfigId)) {
      slideSorts = presentation.Slides.find(slide => slide.ConfigId === slideConfigId).ArraySorts;
    }
    
    // Delete existing Sort from the list (not possible to Sort twice on the same column)
    // slideSorts.forEach(sort => {
    //   if(slideFields.find(field => field.FieldId === sort.FieldId)) {
    //     indexToDelete = slideFields.findIndex(field => field.FieldId === sort.FieldId);
    //   }

    //   if(indexToDelete >= 0) {
    //     slideFields.splice(indexToDelete, 1);
    //   }
    // });

    return (<div className="">
      {slideAvailableSorts && slideAvailableSorts.length > 0 && <div className="inline-flex align-items-center">
        <div className="slideLabel">{Traduction.translate(language, 'sort_field')}</div>

        {/* Parameter select */}
        {slideSortSelected && 
          <Form.Control className="slideParametersSelect mh10" as="select" value={slideSortSelected.FieldId} onChange={(e) => this.changeSortSelected(e)}>
            {slideAvailableSorts.map(item => {
              return <option key={item.FieldId} value={item.FieldId}>{item.Label}</option>
            })}
          </Form.Control>
        }

        <div className="slideLabel">{Traduction.translate(language, 'order_by')}</div>
        
        {/* Parameter Value select */}
        <Form.Control className="slideParametersSelect mh10" as="select" value={slideSortValue} onChange={(e) => this.changeSortValue(e)}>
          <option key={0} value={0}>{Traduction.translate(language, 'ascending_order')}</option>
          <option key={1} value={1}>{Traduction.translate(language, 'descending_order')}</option>
        </Form.Control>
      </div>}
      {/* Button Validate or Cancel */}
      <div className="flex flex-end">
        {slideAvailableSorts && slideAvailableSorts.length > 0 && 
          <Button className="fs12 bold brd-radius mr15" variant="primary" onClick={() => { this.closePopup(); this.saveSort() }}>{Traduction.translate(language, 'validate')}</Button>
        }
        <Button className="fs12 bold brd-radius" variant="warning" onClick={() => this.closePopup()}>{Traduction.translate(language, 'cancel')}</Button>
      </div>
    </div>);
  }

  popupSlideEditSort() {
    const { language, slideFields, slideAvailableSorts, slideSortSelected, slideSortValue, slideSortModif } = this.state;

    return (<div className="">
      {slideAvailableSorts && slideAvailableSorts.length > 0 && <div className="inline-flex align-items-center">
        <div className="slideLabel">{Traduction.translate(language, 'sort_field')}</div>

        {/* Parameter Field */}
        <Form.Label className="slideParametersSelect mh10">{slideSortModif.Field}</Form.Label>

        <div className="slideLabel">{Traduction.translate(language, 'order_by')}</div>

        {/* Parameter Value select */}
        <Form.Control  className="slideParametersSelect mh10" as="select" value={slideSortValue} onChange={(e) => this.changeSortValue(e)}>
          <option key={0} value={0}>{Traduction.translate(language, 'ascending_order')}</option>
          <option key={1} value={1}>{Traduction.translate(language, 'descending_order')}</option>
        </Form.Control>
      </div>}
      {/* Button Validate or Cancel */}
      <div className="flex flex-end">
        {slideAvailableSorts && slideAvailableSorts.length > 0 && 
          <Button className="fs12 bold brd-radius mr15" variant="primary" onClick={() => { this.closePopup(); this.saveSortEdit() }}>{Traduction.translate(language, 'validate')}</Button>
        }
        <Button className="fs12 bold brd-radius" variant="warning" onClick={() => this.closePopup()}>{Traduction.translate(language, 'cancel')}</Button>
      </div>
    </div>);
  }

  popupWebViewDashboard() {
    const { language, itemType, itemId, slideDashboardViews, slideUnpinnedDashboardViews, slideViewDashboardSelected, slideViewDashboardBlocks, isLoading } = this.state;
    // let views = [];

    // Build Views for FiltersViewDashboard
    // if(slideDashboardViews) {
    //   views.push({ Key: 5, Value: slideDashboardViews });
    // }

    return (<div className="popupEditPanel">
      <div className="panelBody">
        {/* Panel Views */}
        <FiltersViewPopupDashboard ref={this.views} CurrentView={slideViewDashboardSelected} Views={slideDashboardViews} OtherViews={slideUnpinnedDashboardViews} onViewChange={this.changeViewDashboardSelected}></FiltersViewPopupDashboard>

        <div className="panelContent">
          {/* Loading Spinner */}
          {isLoading && <div className="center mt30 mb20">
            <span className=""><LoadingSpinner></LoadingSpinner></span>
            <span className="bold ml30">{Traduction.translate(language, 'data_loading')}</span>
          </div>}

          {/* Dashboard Preview */}
          <div className="dashboardContent scrollbar-miniblock">
            <MiniDashboard ref={this.minidashboard} ItemId={itemId} ItemType={itemType} Editable={false} BlocksContent={slideViewDashboardBlocks} CurrentView={slideViewDashboardSelected} Views={slideDashboardViews}></MiniDashboard>
          </div>
        </div>
      </div>
      
      {/* Button Validate or Cancel */}
      <div className="flex flex-end">
        <Button className="fs12 bold brd-radius mr15" variant="primary" onClick={() => { this.closePopupView(); this.saveViewDashboard() }}>{Traduction.translate(language, 'validate')}</Button>
        <Button className="fs12 bold brd-radius" variant="warning" onClick={() => this.closePopupView()}>{Traduction.translate(language, 'cancel')}</Button>
      </div>
    </div>);
  }

  popupWebViewBlock() {
    let { language, slideViews, slideUnpinnedViews, slideViewSelected, presentation, slideConfigId, slideWebViewBlockContent, slideWebViewColumns, slideWebViewRows, slideWebChart, slideWebHeatMaps, slideWebKanban, slideWebMap, isLoading } = this.state;
    let slideSelected, slideItemType, slideItemId, slideId, slideWebViewBlockType, slideViewsReduced;
    let heatmap, occupationLevel, occupationMetric, visibleComponent;

    // Find selected Slide
    if(presentation.Slides) {
      if(presentation.Slides.find(item => item.ConfigId === slideConfigId)) {
        slideSelected = presentation.Slides.find(item => item.ConfigId === slideConfigId);
      }
    }

    // Set Slide data
    slideItemType = slideSelected.ItemType;
    slideItemId = slideSelected.ItemId;
    slideId = slideSelected.SlideId;
    slideWebViewBlockType = slideSelected.CodeLink.substring(7);

    // Initialise Heatmap Data
    if(slideWebViewBlockType === 'Burned' || slideWebViewBlockType === 'Workload') {
      heatmap = this.dataStructureHeatmap(slideWebHeatMaps.Columns, slideWebHeatMaps.Rows, slideWebHeatMaps.ChartData);
    }

    // Initialise Occupation Level & Metric
    if(slideViewSelected.Parameters && slideViewSelected.Parameters.find(param => param.Name === 'OccupationLevel')) {
      occupationLevel = slideViewSelected.Parameters.find(param => param.Name === 'OccupationLevel').Value;
    }
    else {
      occupationLevel = 'Month';
    }
    if(slideViewSelected.Parameters && slideViewSelected.Parameters.find(param => param.Name === 'OccupationMetric')) {
      occupationMetric = slideViewSelected.Parameters.find(param => param.Name === 'OccupationMetric').Value;
    }
    else {
      occupationMetric = 'Occupancy';
    }

    // Initialise Visible Component
    if(slideWebViewBlockType === 'BudgetDetails' || slideWebViewBlockType === 'Meetings' || slideWebViewBlockType === 'Risks') {
      if(slideViewSelected.Parameters && slideViewSelected.Parameters.find(param => param.Name === 'VisibleComponent')) {
        visibleComponent = this.convertStringtoBoolean(slideViewSelected.Parameters.find(param => param.Name === 'VisibleComponent').Value);
      }
      else {
        visibleComponent = true;
      }
    }
    else if(slideWebViewBlockType === 'Burned' || slideWebViewBlockType === 'Workload') {
      if(slideViewSelected.Parameters && slideViewSelected.Parameters.find(param => param.Name === 'VisibleComponent')) {
        visibleComponent = slideViewSelected.Parameters.find(param => param.Name === 'VisibleComponent').Value;
      }
      else {
        visibleComponent = "Heatmap";
      }
    }
    else {
      visibleComponent = false;
    }

    // Cell Settings
    if(slideWebViewBlockType === 'Workload') {
      if(occupationMetric === 'Occupancy') {
        this.cellSettings = { textStyle: { color: 'black' }, border: { width: 4, radius: 5, color: 'white' }, format: '{value} %' };
      }
      else if(occupationMetric === 'Availability') {
        this.cellSettings = { textStyle: { color: 'black' }, border: { width: 4, radius: 5, color: 'white' }, format: '{value}' };
      }
      else if(occupationMetric === 'Workload') {
        this.cellSettings = { textStyle: { color: 'black' }, border: { width: 4, radius: 5, color: 'white' }, format: '{value}' };
      }
    }
    else {
      this.cellSettings = { textStyle: { color: 'black' }, border: { width: 4, radius: 5, color: 'white' }, format: '{value} %' };
    }

    // Palette Settings
    if(slideWebViewBlockType === 'Workload') {
      if(occupationMetric === 'Occupancy') {
        this.paletteSettings = { palette: [{ value: 0, color: '#EAFCFF' }, { value: 99, color: '#95B0B5' }, { value: 100, color: '#A1E981' }, { value: 101, color: '#FFD589' }, { value: 500, color: '#FF9052' }] };
      }
      else if(occupationMetric === 'Availability') {
        if(occupationLevel === 'Month') {
          this.paletteSettings = { palette: [{ startValue: -2000000, endValue: -20, minColor: '#E21313', maxColor: '#FF9052' }, { startValue: -20, endValue: 0, minColor: '#FF9052', maxColor: '#EAFCFF' }, { startValue: 0, endValue: 20, minColor: '#EAFCFF', maxColor: '#A1E981' }, { startValue: 20, endValue: 2000000, minColor: '#A1E981', maxColor: '#A1E981' }] };
        }
        else if(occupationLevel === 'Week') {
          this.paletteSettings = { palette: [{ startValue: -2000000, endValue: -5, minColor: '#E21313', maxColor: '#FF9052' }, { startValue: -5, endValue: 0, minColor: '#FF9052', maxColor: '#EAFCFF' }, { startValue: 0, endValue: 5, minColor: '#EAFCFF', maxColor: '#A1E981' }, { startValue: 5, endValue: 2000000, minColor: '#A1E981', maxColor: '#A1E981' }] };
        }
        else if(occupationLevel === 'Day') {
          this.paletteSettings = { palette: [{ startValue: -2000000, endValue: -1, minColor: '#E21313', maxColor: '#FF9052' }, { startValue: -1, endValue: 0, minColor: '#FF9052', maxColor: '#EAFCFF' }, { startValue: 0, endValue: 1, minColor: '#EAFCFF', maxColor: '#A1E981' }, { startValue: 1, endValue: 2000000, minColor: '#A1E981', maxColor: '#A1E981' }] };
        }
      }
      else if(occupationMetric === 'Workload') {
        if(occupationLevel === 'Month') {
          this.paletteSettings = { palette: [{ value: 0, color: '#EAFCFF' }, { value: 20, color: '#95B0B5' }, { value: 40, color: '#FFD589' }, { value: 60, color: '#FF9052' }] };
        }
        else if(occupationLevel === 'Week') {
          this.paletteSettings = { palette: [{ value: 0, color: '#EAFCFF' }, { value: 5, color: '#95B0B5' }, { value: 10, color: '#FFD589' }, { value: 15, color: '#FF9052' }] };
        }
        else if(occupationLevel === 'Day') {
          this.paletteSettings = { palette: [{ value: 0, color: '#EAFCFF' }, { value: 1, color: '#95B0B5' }, { value: 2, color: '#FFD589' }, { value: 3, color: '#FF9052' }] };
        }
      }
    }
    else {
      this.paletteSettings = { palette: [{ value: 0, color: '#EAFCFF' }, { value: 99, color: '#95B0B5' }, { value: 100, color: '#A1E981' }, { value: 101, color: '#FFD589' }, { value: 500, color: '#FF9052' }] };
    }

    // Filter Views without Kanban
    slideViewsReduced = slideViews.filter(views => views.ViewType !== 2);
    
    return (<div className="popupEditPanel">
      <div className="panelBody">
        {/* Panel Views */}
        <FiltersViewPopup ref={this.dashboardviews} BlockType={slideWebViewBlockType} CurrentView={slideViewSelected} Views={slideViewsReduced} OtherViews={slideUnpinnedViews} onViewChange={this.changeView}></FiltersViewPopup>

        <div className="panelContent">
          {/* Panel Label */}
          <div className="blockCommunicationPreviewHeader">
            <div className="">{this.getIconBlockType(slideWebViewBlockType, "iconsPopup")}</div>
            <div className="blockCommunicationPreviewLabel">{slideWebViewBlockType}</div>
          </div>

          {/* Loading Spinner */}
          {isLoading && <div className="center mt30 mb20">
            <span className=""><LoadingSpinner></LoadingSpinner></span>
            <span className="bold ml30">{Traduction.translate(language, 'data_loading')}</span>
          </div>}

          {/* Panel Preview */}
          {slideViewSelected && (slideWebViewBlockType !== 'Burned' && slideWebViewBlockType !== 'Workload') && 
            <div className={(slideViewSelected.ViewType !== 1 ? "panelComponent scrollbar-miniblock" : "panelComponentNoScroll")}>
              {slideViewSelected.ViewType === 0 && slideViewSelected.DefaultLevel === 0 && <MiniTree ref={this.tree} ItemId={slideItemId} ItemType={slideItemType} BlockType={slideWebViewBlockType} Editable={false} Pagging={false} CurrentView={slideViewSelected} Columns={slideWebViewColumns} Rows={slideWebViewRows}></MiniTree>}
              {slideViewSelected.ViewType === 0 && slideViewSelected.DefaultLevel !== 0 && <MiniTable ref={this.table} ItemId={slideItemId} ItemType={slideItemType} BlockType={slideWebViewBlockType} Editable={false} Pagging={false} CurrentView={slideViewSelected} Columns={slideWebViewColumns} Rows={slideWebViewRows}></MiniTable>}
              {slideViewSelected.ViewType === 1 && <MiniPlanning ref={this.planning} ItemId={slideItemId} ItemType={slideItemType} BlockType={slideWebViewBlockType} Editable={false} CurrentView={slideViewSelected} Columns={slideWebViewColumns} Rows={slideWebViewRows}></MiniPlanning>}
              {/* {slideViewSelected.ViewType === 2 && <Kanban ref={this.kanban} ItemId={slideItemId} ItemType={slideItemType} BlockType={slideWebViewBlockType} Editable={false} CurrentView={slideViewSelected} Kanban={slideWebKanban} Columns={slideWebViewColumns} Rows={slideWebViewRows} Axes={slideWebViewBlockContent.AvailableAxes}></Kanban>} */}
              {slideViewSelected.ViewType === 3 && <MiniPivot ref={this.pivot} ItemId={slideItemId} ItemType={slideItemType} BlockType={slideWebViewBlockType} Editable={false} CurrentView={slideViewSelected} Columns={slideWebViewColumns} Rows={slideWebViewRows}></MiniPivot>}
              {slideViewSelected.ViewType === 4 && <MiniBlockDetails Id={slideItemId} Type={slideItemType} BlockContent={slideWebViewBlockContent} BlockType={slideWebViewBlockType} BlockLabel={slideWebViewBlockContent.BlockLabel}></MiniBlockDetails>}
              {slideViewSelected.ViewType === 7 && <MiniChart ref={this.chart} ItemId={slideItemId} ItemType={slideItemType} BlockType={slideWebViewBlockType} CurrentView={slideViewSelected} Chart={slideWebChart} IsLoading={isLoading}></MiniChart>}
              {slideViewSelected.ViewType === 8 && <MiniMap ref={this.map} ItemId={slideItemId} ItemType={slideItemType} BlockType={slideWebViewBlockType} CurrentView={slideViewSelected} Map={slideWebMap} IsLoading={isLoading}></MiniMap>}

              {slideViewSelected.ViewType === 2 && <div className="bold">{Traduction.translate(language, 'available_later_ppt')}</div>}
              {/* {slideViewSelected.ViewType === 8 && <div className="bold">{Traduction.translate(language, 'available_later_ppt')}</div>} */}
            </div>
          }
          {slideViewSelected && (slideWebViewBlockType === 'Burned' || slideWebViewBlockType === 'Workload') && 
            <div className={(slideViewSelected.ViewType !== 1 ? "panelComponent scrollbar-miniblock" : "panelComponentNoScroll")}>
              {/* Mini Heatmap */}
              {visibleComponent === 'Heatmap' && slideViewSelected.ViewType === 3 && 
                <HeatMapComponent id="heatmapWorkload-container" height={'100%'} width={'100%'} dataSource={heatmap.dataSource} xAxis={{ labels: heatmap.xAis }} yAxis={{ labels: heatmap.yAis }} cellSettings={this.cellSettings} paletteSettings={this.paletteSettings} legendSettings={{ visible: false }} showTooltip={false} ref={heatmap=>this.heatmap=heatmap}>
                  <HeatMapInject services={[ Legend, HeatMapTooltip, Adaptor ]} />
                </HeatMapComponent>
              }
              {/* Mini Pivot */}
              {visibleComponent === 'Graph' && slideViewSelected.ViewType === 3 && <MiniPivot ref={this.pivot} ItemId={slideItemId} ItemType={slideItemType} BlockType={slideWebViewBlockType} Editable={false} CurrentView={slideViewSelected} Columns={slideWebViewColumns} Rows={slideWebViewRows}></MiniPivot>}
            </div>
          }
        </div>
      </div>
      
      {/* Button Validate or Cancel */}
      <div className="flex flex-end">
        <Button className="fs12 bold brd-radius mr15" variant="primary" onClick={() => { this.closePopupView(); this.saveViewBlock() }}>{Traduction.translate(language, 'validate')}</Button>
        <Button className="fs12 bold brd-radius" variant="warning" onClick={() => this.closePopupView()}>{Traduction.translate(language, 'cancel')}</Button>
      </div>
    </div>);
  }

  closePopup() {
    this.setState({ slideModificationPopup: false, slideFiltersValue: null, slideSortModif: null, slideParamModif: null, slideParameterSelected: null, slideParameterValue: {} });
  }

  closePopupView() {
    this.setState({ slideViewModificationPopup: false });
  }

  // ----- ----- Axes ----- -----
  updateAxe1(slideId, axe) {
    const presentation = this.state.presentation;
    const slideConfigId = this.state.slideConfigId;
    let slideSelected;

    // Find selected Slide
    if(presentation.Slides) {
      if(presentation.Slides.find(item => item.ConfigId === slideConfigId)) {
        slideSelected = presentation.Slides.find(item => item.ConfigId === slideConfigId);
      }
    }

    // Update Axe1
    if(slideSelected) {
      slideSelected.Axes.Axe1 = axe.Axe;
      slideSelected.Axes.Axe1Id = axe.AxeId;
    }

    // Find then Update Axe1
    // if(availableAxes) {
    //   if(availableAxes.find(item => item.AxeId == axe.AxeId)) {
    //     slideSelected.Axes.Axe1 = availableAxes.find(item => item.AxeId == axe.AxeId).Axe;
    //     slideSelected.Axes.Axe1Id = availableAxes.find(item => item.AxeId == axe.AxeId).AxeId;
    //   }
    // }
    
    this.setState({ presentation });
  }

  updateAxe2(slideId, axe) {
    const presentation = this.state.presentation;
    const slideConfigId = this.state.slideConfigId;
    let slideSelected;

    // Find selected Slide
    if(presentation.Slides) {
      if(presentation.Slides.find(item => item.ConfigId === slideConfigId)) {
        slideSelected = presentation.Slides.find(item => item.ConfigId === slideConfigId);
      }
    }

    // Update Axe2
    if(slideSelected) {
      slideSelected.Axes.Axe2 = axe.Axe;
      slideSelected.Axes.Axe2Id = axe.AxeId;
    }

    // Find then Update Axe2
    // if(availableAxes) {
    //   if(availableAxes.find(item => item.AxeId == axe.AxeId)) {
    //     slideSelected.Axes.Axe2 = availableAxes.find(item => item.AxeId == axe.AxeId).Axe;
    //     slideSelected.Axes.Axe2Id = availableAxes.find(item => item.AxeId == axe.AxeId).AxeId;
    //   }
    // }

    this.setState({ presentation });
  }

  // ----- ----- Dates ----- -----
  // Function that initialise the date range
  initialiseDate() {
    const { language, slideConfigId, presentation } = this.state;
    let slideSelected, slideDateRange = {};

    // Find selected Slide
    if(presentation.Slides) {
      if(presentation.Slides.find(item => item.ConfigId === slideConfigId)) {
        slideSelected = presentation.Slides.find(item => item.ConfigId === slideConfigId);
      }
    }

    // Get selected Slide Date Range
    if(slideSelected.DateRange.BorneInf && slideSelected.DateRange.BorneSup) {
      // Clone DateRange Object
      slideDateRange = JSON.parse(JSON.stringify(slideSelected.DateRange));
    }
    // Initialise Date
    else {
      slideDateRange.BorneInf = "01/01/" + new Date().getFullYear();
      slideDateRange.BorneSup = "12/31/" + new Date().getFullYear();
      slideDateRange.Period = 7;
      slideDateRange.RangeType = 0;
      slideDateRange.SpotLag = 0;

      if(language === "English") {
        slideDateRange.RangeLabel = "From " + "01/01/" + new Date().getFullYear() + " to " + "31/12/" + new Date().getFullYear();
      }
      else if(language === "Français") {
        slideDateRange.RangeLabel = "Du " + "01/01/" + new Date().getFullYear() + " au " + "31/12/" + new Date().getFullYear();
      }
    }

    this.setState({ slideDateRange });
  }

  // Update Date on DatePicker
  changeDateBornes(field, date) {
    const slideDateRange = this.state.slideDateRange;

    // Set borneInf and borneSup
    if(field === "StartDate") {
      if(new Date(date) <= new Date(slideDateRange.BorneSup)) {
        slideDateRange.BorneInf = date;
      }
      else {
        if(this.startDate.current) {
          this.startDate.current.cancelModification({ NewValue: date, OldValue: slideDateRange.BorneInf });
        }
      }
    }
    else if (field === "EndDate") {
      if(new Date(date) >= new Date(slideDateRange.BorneInf)) {
        slideDateRange.BorneSup = date;
      }
      else {
        if(this.endDate.current) {
          this.endDate.current.cancelModification({ NewValue: date, OldValue: slideDateRange.BorneSup });
        }
      }
    }

    this.setState({ slideDateRange });
  }

  changeDatePeriod(event) {
    let slideDateRange = this.state.slideDateRange;
    const value = parseInt(event.target.value);

    // Period Week 7 Days
    if(value === 7) {
      slideDateRange.Period = 7;
      slideDateRange.BorneInf = this.formatDateEn(new Date(new Date().setDate(new Date().getDate() + Math.abs(slideDateRange.SpotLag))));
      slideDateRange.BorneSup = this.formatDateEn(new Date(new Date(slideDateRange.BorneInf).setDate(new Date(slideDateRange.BorneInf).getDate() + 7)));
    }
    // Period Month 30 Days
    else if(value === 30) {
      slideDateRange.Period = 30;
      slideDateRange.BorneInf = this.formatDateEn(new Date(new Date().setDate(new Date().getDate() + Math.abs(slideDateRange.SpotLag))));
      slideDateRange.BorneSup = this.formatDateEn(new Date(new Date(slideDateRange.BorneInf).setDate(new Date(slideDateRange.BorneInf).getDate() + 30)));
    }
    // Period Year 365 Days
    else if(value === 365) {
      slideDateRange.Period = 365;
      slideDateRange.BorneInf = this.formatDateEn(new Date(new Date().setDate(new Date().getDate() + Math.abs(slideDateRange.SpotLag))));
      slideDateRange.BorneSup = this.formatDateEn(new Date(new Date(slideDateRange.BorneInf).setDate(new Date(slideDateRange.BorneInf).getDate() + 365)));
    }

    this.setState({ slideDateRange });
  }

  changeDateSpotLag(event) {
    let slideDateRange = this.state.slideDateRange;
    let value = event.target.value;

    // Check if value is an Integer (positive or negative)
    if(Number.isInteger(parseInt(value))) {
      value = parseInt(value);
    }
    else if(value === '-') {
      value = -0;
    }
    else {
      value = 0;
    }

    // Update Date borneInf & borneSup
    slideDateRange.BorneInf = this.formatDateEn(new Date(new Date().setDate(new Date().getDate() + value)));
    slideDateRange.BorneSup = this.formatDateEn(new Date(new Date(slideDateRange.BorneInf).setDate(new Date(slideDateRange.BorneInf).getDate() + slideDateRange.Period)));

    // Update Date SpotLag
    slideDateRange.SpotLag = value;

    this.setState({ slideDateRange });
  }

  changeDateType(value) {
    let slideDateRange = this.state.slideDateRange;

    slideDateRange.RangeType = value;

    this.setState({ slideDateRange });
  }

  deleteDateRange() {
    let presentation = this.state.presentation;
    const slideConfigId = this.state.slideConfigId;

    if(presentation.Slides) {
      if(presentation.Slides.find(item => item.ConfigId === slideConfigId)) {
        // Reset DateRange RangeType to 0
        presentation.Slides.find(item => item.ConfigId === slideConfigId).DateRange.RangeType = 0;
      }
    }

    this.setState({ presentation });
  }

  // Format Date
  formatDate(date) {
    const language = this.state.language;

    if(language === 'Français') {
      return this.formatDateFr(date);
    }
    else if(language === 'English') {
      return this.formatDateEn(date);
    }
  }

  // Format Date to English format for Backend
  formatDateEn(date) {
    let formattedDate;

    if(date) {
      let day = date.getDate();
      let month = date.getMonth()+1; // Between 0 and 11
      let year = date.getFullYear();

      if(day < 10) {
        day = "0" + day;
      }
      if(month < 10) {
        month = "0" + month;
      }

      formattedDate = month + "/" + day + "/" + year;
    }
    
    return formattedDate;
  }

  // Format Date to French format for Display
  formatDateFr(date) {
    let formattedDate;

    if(date) {
      let day = date.getDate();
      let month = date.getMonth()+1; // Between 0 and 11
      let year = date.getFullYear();

      if(day < 10) {
        day = "0" + day;
      }
      if(month < 10) {
        month = "0" + month;
      }

      formattedDate = day + "/" + month + "/" + year;
    }
    
    return formattedDate;
  }

  saveDate() {
    const { language, presentation, slideConfigId, slideDateRange } = this.state;
    let slideSelected;

    // Find selected Slide
    if(presentation.Slides) {
      if(presentation.Slides.find(item => item.ConfigId === slideConfigId)) {
        slideSelected = presentation.Slides.find(item => item.ConfigId === slideConfigId);
      }
    }

    if(slideDateRange && slideDateRange.RangeType !== 0) {
      // Update RangeType, borneInf & borneSup
      slideSelected.DateRange.RangeType = slideDateRange.RangeType;
      slideSelected.DateRange.BorneInf = slideDateRange.BorneInf;
      slideSelected.DateRange.BorneSup = slideDateRange.BorneSup;
      slideSelected.DateRange.RangeLabel = Traduction.translate(language, 'from') + " " + this.formatDate(new Date(slideDateRange.BorneInf)) + Traduction.translate(language, 'to') + " " + this.formatDate(new Date(slideDateRange.BorneSup));

      if(slideSelected.DateRange.RangeType === 1) {
        // Update Date Period & SpotLag
        // const periodTime = Math.abs(new Date(slideDateRange.BorneSup) - new Date(slideDateRange.BorneInf));
        // const periodDays = Math.ceil(periodTime / (1000 * 60 * 60 * 24));
        // slideSelected.DateRange.Period = periodDays;
        
        // const diffTime = new Date(slideDateRange.BorneInf) - new Date();
        // const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        // slideSelected.DateRange.SpotLag = diffDays;
      }

      if(slideSelected.DateRange.RangeType === 2) {
        // Update Date Period & SpotLag
        slideSelected.DateRange.Period = slideDateRange.Period;
        slideSelected.DateRange.SpotLag = slideDateRange.SpotLag;
      }

      this.setState({ presentation });
    }
  }

  // ----- ----- Fields ----- -----
  // Initialisation of Field ListBox
  initialiseFieldsSelected() {
    let { presentation, slideConfigId, slideFieldsSelectedIds, slideFieldsSelectedLabels } = this.state;
    let slideSelected, slideFieldsSelected = [], checkedIds = [], checkedLabels = [];

    // Define Fields datasource attributes for ListBox
    if(presentation.Slides) {
      if(presentation.Slides.find(item => item.ConfigId === slideConfigId)) {
        // Get selected Slide
        slideSelected = presentation.Slides.find(item => item.ConfigId === slideConfigId);

        // Get Ids & Labels from selected Fields
        if(slideSelected.ArrayFields.ArrayFieldIds && slideSelected.ArrayFields.Labels) {
          checkedIds = slideSelected.ArrayFields.ArrayFieldIds.split(',');
          checkedLabels = slideSelected.ArrayFields.Labels.split(', ');

          // Update Slide Fields selected
          checkedIds.forEach((field, index) => {
            slideFieldsSelected.push({ id: field, text: checkedLabels[index], value: checkedLabels[index] });
          })

          // Update Slide Fields selected Ids & Labels
          slideFieldsSelectedIds = slideSelected.ArrayFields.ArrayFieldIds;
          slideFieldsSelectedLabels = slideSelected.ArrayFields.Labels;
        }
        else {
          slideFieldsSelected = [];
          slideFieldsSelectedIds = "";
          slideFieldsSelectedLabels = "";
        }
      }
    }

    this.setState({ slideFieldsSelected, slideFieldsSelectedIds, slideFieldsSelectedLabels });
  }

  createdFields() {
    const { presentation, slideConfigId, slideFields, slideFieldsSelectedIds, slideFieldsSelectedLabels } = this.state;
    let slideSelected, checkedIds, checkedLabels, checkedFields = [];
    let columnList = [];

    // Define Fields datasource attributes for ListBox
    if(presentation.Slides) {
      if(presentation.Slides.find(item => item.ConfigId === slideConfigId)) {
        // Get selected Slide
        slideSelected = presentation.Slides.find(item => item.ConfigId === slideConfigId);

        // Get Ids & Labels from selected Fields
        if(slideFieldsSelectedIds && slideFieldsSelectedLabels) {
          checkedIds = slideFieldsSelectedIds.split(',');
          checkedLabels = slideFieldsSelectedLabels.split(', ');
          // checkedIds = slideSelected.ArrayFields.ArrayFieldIds.split(',');
          // checkedLabels = slideSelected.ArrayFields.Labels.split(', ');

          checkedLabels.forEach((label, index) => {
            checkedFields.push({ id: checkedIds[index], label: label });
          });
        }
      }
    }

    // Add checked Fields
    checkedFields.forEach(field => {
      columnList.push({ id: field.id, text: field.label, value: field.label });
    });

    // Add other Fields
    if(slideFields) {
      slideFields.forEach(field => {
        // Check if Column is not already include in Checkbox list
        if(!columnList.find(column => column.id == field.FieldId)) {
          columnList.push({ id: field.FieldId, text: field.Label, value: field.Label });
        }
      });
    }

    if(this.listBoxField) {
      this.listBoxField.dataSource = columnList;
      this.listBoxField.value = checkedLabels;
    }
  }

  // Change Field ListBox
  changeField(args) {
    let { presentation, slideConfigId, slideFieldsSelectedIds, slideFieldsSelectedLabels } = this.state;
    const items = args.items;
    let slideSelected, slideFieldsSelected = [];
    let values = '', valuesLabel = '';

    if(this.listBoxField) {
      // Get Columns List from ListBox checked
      items.forEach((item, index) => {
        if(index < items.length - 1) {
          values = values.concat(item.id, ',');
          valuesLabel = valuesLabel.concat(item.value, ', ');
        }
        else {
          values = values.concat(item.id);
          valuesLabel = valuesLabel.concat(item.value);
        }
      });
    }

    // Find selected Slide
    // if(presentation.Slides) {
    //   if(presentation.Slides.find(item => item.ConfigId === slideConfigId)) {
    //     slideSelected = presentation.Slides.find(item => item.ConfigId === slideConfigId);
    //   }
    // }

    // Selected Slide ArrayField & Labels modification
    slideFieldsSelectedIds = values;
    slideFieldsSelectedLabels = valuesLabel;
    // slideSelected.ArrayFields.ArrayFieldIds = values;
    // slideSelected.ArrayFields.Labels = valuesLabel;

    // Update Slide selected Fields
    slideFieldsSelected = items;

    this.setState({ presentation, slideFieldsSelected, slideFieldsSelectedIds, slideFieldsSelectedLabels });
  }

  dropFields(args) {
    let { presentation, slideConfigId, slideFieldsSelected, slideFieldsSelectedIds, slideFieldsSelectedLabels } = this.state;
    const items = args.source.currentData;
    let slideSelected;
    let values = '', valuesLabel = '';

    // Get Columns List order from ListBox
    items.forEach((item, index) => {
      // Take only selected Fields
      if(index < slideFieldsSelected.length) {
        if(index < slideFieldsSelected.length - 1) {
          values = values.concat(item.id, ',');
          valuesLabel = valuesLabel.concat(item.value, ', ');
        }
        else {
          values = values.concat(item.id);
          valuesLabel = valuesLabel.concat(item.value);
        }
      }
    });

    // Find selected Slide
    // if(presentation.Slides) {
    //   if(presentation.Slides.find(item => item.ConfigId === slideConfigId)) {
    //     slideSelected = presentation.Slides.find(item => item.ConfigId === slideConfigId);
    //   }
    // }

    // Selected Slide ArrayField & Labels modification
    slideFieldsSelectedIds = values;
    slideFieldsSelectedLabels = valuesLabel;
    // slideSelected.ArrayFields.ArrayFieldIds = values;
    // slideSelected.ArrayFields.Labels = valuesLabel;

    this.setState({ presentation, slideFieldsSelectedIds, slideFieldsSelectedLabels });
  }

  saveFields() {
    let { presentation, slideConfigId, slideFieldsSelectedIds, slideFieldsSelectedLabels } = this.state;
    let slideSelected;

    // Find selected Slide
    if(presentation.Slides) {
      if(presentation.Slides.find(item => item.ConfigId === slideConfigId)) {
        slideSelected = presentation.Slides.find(item => item.ConfigId === slideConfigId);
      }
    }

    // Update selected Slide Fields
    slideSelected.ArrayFields.ArrayFieldIds = slideFieldsSelectedIds;
    slideSelected.ArrayFields.Labels = slideFieldsSelectedLabels;

    this.setState({ presentation });
  }

  // ----- ----- Indicators ----- -----
  changeIndicators(event) {
    const slideIndicators = this.state.slideIndicators;
    const slideIndicatorsSelected = event.target.value;
    let slideIndicatorsValue;

    // Find Indicators associated to selected Id
    if(slideIndicators.find(indicator => indicator.FieldId == slideIndicatorsSelected)) {
      slideIndicatorsValue = slideIndicators.find(indicator => indicator.FieldId == slideIndicatorsSelected);
    }
    
    this.setState({ slideIndicatorsSelected, slideIndicatorsValue });
  }

  deleteIndicator(index) {
    let presentation = this.state.presentation;
    const slideConfigId = this.state.slideConfigId;

    if(presentation.Slides) {
      if(presentation.Slides.find(item => item.ConfigId === slideConfigId)) {
        // Remove Filter from the Slide
        presentation.Slides.find(item => item.ConfigId === slideConfigId).SlideIndicators.splice(index, 1);
      }
    }

    this.setState({ presentation });
  }

  saveIndicators() {
    let { presentation, slideConfigId, slideIndicators, slideIndicatorsValue } = this.state;
    let slideSelected;

    // Find selected Slide
    if(presentation.Slides) {
      if(presentation.Slides.find(item => item.ConfigId === slideConfigId)) {
        slideSelected = presentation.Slides.find(item => item.ConfigId === slideConfigId);
      }
    }

    // Add the new Parameter
    slideSelected.SlideIndicators.push({ Indicator: slideIndicatorsValue.Label, IndicatorId: slideIndicatorsValue.FieldId });

    this.setState({ presentation });
  }

  // ----- ----- Filters ----- -----
  // Initialisation of Filters ListBox
  createdFilters() {
    const slideFilters = this.state.slideFilters;
    let checkedLabels = [];
    
    if(this.listBoxFilter) {
      slideFilters.forEach(field => {
        checkedLabels.push(field.value);
      });

      this.listBoxFilter.value = checkedLabels;
    }
  }

  createdFiltersModif() {
    const slideFilters = this.state.slideFilters;
    const slideFilterModif = this.state.slideFilterModif;
    let checkedLabels = [];
    let slideFilter;

    // Available Filters
    if(slideFilters.find(item => item.FieldId == slideFilterModif.FieldId)) {
      slideFilter = slideFilters.find(item => item.FieldId == slideFilterModif.FieldId);
    }

    // Slide Filters
    let filterSelected = slideFilterModif.Filter.split(',');

    if(this.listBoxFilter2) {
      if(slideFilter) {
        slideFilter.AvailableValues.forEach(value => {
          if(filterSelected.includes(value.Key.toString())) {
            checkedLabels.push(value.Key);
          }
        });
  
        this.listBoxFilter2.value = checkedLabels;
      }
    }
  }

  templateFilters(args) {
    return <span className="listBoxFilters">{args.value}</span>;
  }

  // Change Filters ListBox
  changeFilters(args) {
    const items = args.items;
    let slideFiltersSelected = this.state.slideFiltersSelected;

    // Get Columns Name corresponding to checked Labels
    slideFiltersSelected = items;

    this.setState({ slideFiltersSelected });
  }

  changeFiltersValue(event) {
    const idSelected = event.target.value;
    const slideFilters = this.state.slideFilters;
    let slideFiltersValue = this.state.slideFiltersValue;

    if(slideFilters.find(item => item.FieldId.toString() === idSelected)) {
      slideFiltersValue = slideFilters.find(item => item.FieldId.toString() === idSelected);
    }
     
    this.setState({ slideFiltersValue });
  }

  changeFiltersOperator(event) {
    const slideFiltersOperator = event.target.value;
    
    this.setState({ slideFiltersOperator });
  }

  deleteFilter(index) {
    let presentation = this.state.presentation;
    const slideConfigId = this.state.slideConfigId;

    if(presentation.Slides) {
      if(presentation.Slides.find(item => item.ConfigId === slideConfigId)) {
        // Remove Filter from the Slide
        presentation.Slides.find(item => item.ConfigId === slideConfigId).SlideFilters.splice(index, 1);
      }
    }

    this.setState({ presentation });
  }

  saveFilters() {
    let { presentation, slideConfigId, slideFiltersValue, slideFiltersSelected, slideFiltersOperator } = this.state;
    let checkedValuesKey = [], checkedValuesFilterString = [];
    let valuesKey = '', valuesFilterString = '';
    let slideSelected, operator;

    // Get Columns Name corresponding to checked Labels
    slideFiltersSelected.forEach(item => {
      checkedValuesKey.push(item.key);
      checkedValuesFilterString.push(item.value);
    });

    if(this.listBoxFilter) {
      // Get Columns List from ListBox checked
      checkedValuesKey.forEach((value, index) => {
        if(index < checkedValuesKey.length - 1) {
          valuesKey = valuesKey.concat(value, ',');
          valuesFilterString = valuesFilterString.concat(checkedValuesFilterString[index], ',');
        }
        else {
          valuesKey = valuesKey.concat(value);
          valuesFilterString = valuesFilterString.concat(checkedValuesFilterString[index]);
        }
      });
    }

    // Find selected Slide
    if(presentation.Slides) {
      if(presentation.Slides.find(item => item.ConfigId === slideConfigId)) {
        slideSelected = presentation.Slides.find(item => item.ConfigId === slideConfigId);
      }
    }

    // Set Operator value
    if(slideFiltersOperator === "0") {
      operator = "OR";
    }
    else if(slideFiltersOperator === "1") {
      operator = "NOT";
    }
    else if(slideFiltersOperator === "2") {
      operator = "AND";
    }

    // Add Filter in Slide Filters
    slideSelected.SlideFilters.push({ Field: slideFiltersValue.Field, FieldId: slideFiltersValue.FieldId, Filter: valuesKey, FilterString: valuesFilterString, Operator: operator });

    this.setState({ presentation });
  }

  saveFilterEdit() {
    let { presentation, slideConfigId, slideFiltersValue, slideFiltersSelected, slideFilterModif } = this.state;
    let checkedValuesKey = [], checkedValuesFilterString = [];
    let valuesKey = '', valuesFilterString = '';
    let slideSelected, operator;

    // Get Columns Name corresponding to checked Labels
    slideFiltersSelected.forEach(item => {
      checkedValuesKey.push(item.key);
      checkedValuesFilterString.push(item.value);
    });

    if(this.listBoxFilter2) {
      // Get Columns List from ListBox checked
      checkedValuesKey.forEach((value, index) => {
        if(index < checkedValuesKey.length - 1) {
          valuesKey = valuesKey.concat(value, ',');
          valuesFilterString = valuesFilterString.concat(checkedValuesFilterString[index], ',');
        }
        else {
          valuesKey = valuesKey.concat(value);
          valuesFilterString = valuesFilterString.concat(checkedValuesFilterString[index]);
        }
      });
    }

    // Find selected Slide
    if(presentation.Slides) {
      if(presentation.Slides.find(item => item.ConfigId === slideConfigId)) {
        slideSelected = presentation.Slides.find(item => item.ConfigId === slideConfigId);
      }
    }

    // Find and modif Filter in Slide Filters 
    let filterModif = slideSelected.SlideFilters.find(filter => filter.FieldId === slideFilterModif.FieldId)
    filterModif.Filter = valuesKey;
    filterModif.FilterString = valuesFilterString;
    // slideSelected.SlideFilters.push({ Field: slideFiltersValue.Field, FieldId: slideFiltersValue.FieldId, Filter: valuesKey, FilterString: valuesFilterString, Operator: operator });

    this.setState({ presentation });
  }

  triggerSlideEditFilter(filter) {
    const { login, authId, itemId, itemType, presentation, slideConfigId, slideModificationPopup } = this.state;
    let slideModificationType = "EditFilter";
    let slideItemType, slideItemId, slideId;
    
    if(presentation.Slides) {
      if(presentation.Slides.find(slide => slide.ConfigId === slideConfigId)) {
        slideItemType = presentation.Slides.find(slide => slide.ConfigId === slideConfigId).ItemType;
        slideItemId = presentation.Slides.find(slide => slide.ConfigId === slideConfigId).ItemId;
        slideId = presentation.Slides.find(slide => slide.ConfigId === slideConfigId).SlideId;
      }
    }

    this.getAvailableFilters(login, authId, itemId, itemType, slideItemId, slideItemType, slideId, filter.FieldId);

    this.setState({ slideModificationPopup: true, slideModificationType, slideFilterModif: filter });
  }

  // ----- ----- Parameters ----- -----
  changeParameters(event) {
    const slideParameters = this.state.slideParameters;
    const idSelected = event.target.value;
    let slideParameterValue;
    let slideParameterSelected = null;

    // Get selected Parameter in list
    if(slideParameters.find(item => item.ParameterId.toString() === idSelected)) {
      slideParameterValue = slideParameters.find(item => item.ParameterId.toString() === idSelected);
    }

    // Initialise Parameter Value selected with first value in list
    if(slideParameterValue.AvailableValues) {
      slideParameterSelected = slideParameterValue.AvailableValues[0];
    }
     
    this.setState({ slideParameterSelected, slideParameterValue });
  }

  changeParameterSelected(event) {
    const slideParameterSelected = event.target.value;
    
    this.setState({ slideParameterSelected });
  }

  deleteParameter(index) {
    let presentation = this.state.presentation;
    const slideConfigId = this.state.slideConfigId;

    if(presentation.Slides) {
      if(presentation.Slides.find(item => item.ConfigId === slideConfigId)) {
        // Remove Parameter from the Slide
        presentation.Slides.find(item => item.ConfigId === slideConfigId).Parameters.splice(index, 1);
      }
    }

    this.setState({ presentation });
  }

  saveParameters() {
    let { presentation, slideConfigId, slideParameterValue, slideParameterSelected } = this.state;
    let slideSelected;

    // Find selected Slide
    if(presentation.Slides) {
      if(presentation.Slides.find(item => item.ConfigId === slideConfigId)) {
        slideSelected = presentation.Slides.find(item => item.ConfigId === slideConfigId);
      }
    }

    // Add the new Parameter
    slideSelected.Parameters.push({ ParameterId: slideParameterValue.ParameterId, Parameter: slideParameterValue.ParameterLabel, ValueType: slideParameterValue.ValueType, Value: slideParameterSelected });

    this.setState({ presentation });
  }

  saveParameterEdit() {
    let { presentation, slideConfigId, slideParameterValue, slideParameterSelected, slideParamModif } = this.state;
    let slideSelected;

    // Find selected Slide
    if(presentation.Slides) {
      if(presentation.Slides.find(item => item.ConfigId === slideConfigId)) {
        slideSelected = presentation.Slides.find(item => item.ConfigId === slideConfigId);
      }
    }

    // modif the Parameter
    let paramModif = slideSelected.Parameters.find(param => param.ParameterId === slideParamModif.ParameterId);
    paramModif.Value = slideParameterSelected;

    this.setState({ presentation });
  }

  triggerSlideEditParameter(param) {
    const { login, authId, presentation, slideConfigId, slideModificationPopup } = this.state;
    let slideModificationType = "EditParameter";
    let slideItemType, slideItemId, slideId;
    
    if(presentation.Slides) {
      if(presentation.Slides.find(slide => slide.ConfigId === slideConfigId)) {
        slideItemType = presentation.Slides.find(slide => slide.ConfigId === slideConfigId).ItemType;
        slideItemId = presentation.Slides.find(slide => slide.ConfigId === slideConfigId).ItemId;
        slideId = presentation.Slides.find(slide => slide.ConfigId === slideConfigId).SlideId;
      }
    }

    this.getAvailableParameters(login, authId, slideId, param.ParameterId);

    this.setState({ slideModificationPopup: true, slideModificationType, slideParamModif: param });
  }
  
  // ----- ----- Scope ----- -----
  // updateScope(event) {
  //   const slideScope = this.state.slideScope;
  //   let slideScopeValue;

  //   if(slideScope.find(scope => scope.ItemId == event.target.value)) {
  //     slideScopeValue = slideScope.find(scope => scope.ItemId == event.target.value);
  //   }

  //   this.setState({ slideScopeValue });
  // }

  changeScope(slideId, scope) {
    const presentation = this.state.presentation;
    const slideConfigId = this.state.slideConfigId;
    let slideSelected;

    // Find selected Slide
    if(presentation.Slides) {
      if(presentation.Slides.find(item => item.ConfigId === slideConfigId)) {
        slideSelected = presentation.Slides.find(item => item.ConfigId === slideConfigId);
      }
    }

    // Update Scope
    if(slideSelected) {
      slideSelected.ItemId = scope.ItemId;
      slideSelected.ItemType = scope.ItemType;
      slideSelected.ItemLabel = scope.Name;
    }

    // Find then Update Scope
    // if(availableScopes) {
    //   if(availableScopes.find(item => item.ItemId == scope.ItemId)) {
    //     slideSelected.ItemId = availableScopes.find(item => item.ItemId == scope.ItemId).ItemId;
    //     slideSelected.ItemType = availableScopes.find(item => item.ItemId == scope.ItemId).ItemType;
    //     slideSelected.ItemLabel = availableScopes.find(item => item.ItemId == scope.ItemId).Name;
    //   }
    // }

    this.setState({ presentation });
  }

  saveScope() {
    let { presentation, slideConfigId, slideScopeValue } = this.state;

    // Find selected Slide
    if(presentation.Slides) {
      if(presentation.Slides.find(item => item.ConfigId === slideConfigId)) {
        // Update Slide Scope ItemId, ItemType & ItemLabel
        presentation.Slides.find(item => item.ConfigId === slideConfigId).ItemId = slideScopeValue.ItemId;
        presentation.Slides.find(item => item.ConfigId === slideConfigId).ItemType = slideScopeValue.ItemType;
        presentation.Slides.find(item => item.ConfigId === slideConfigId).ItemLabel = slideScopeValue.Name;
      }
    }

    this.setState({ presentation });
  }

  // ----- ----- Sort ----- -----
  changeSortSelected(event) {
    const slideAvailableSorts = this.state.slideAvailableSorts;
    let slideSortSelected = this.state.slideSortSelected;
    let idSelected = event.target.value;

    // Update selected Field
    if(slideAvailableSorts.find(item => item.FieldId.toString() === idSelected)) {
      slideSortSelected = slideAvailableSorts.find(item => item.FieldId.toString() === idSelected);
    }
     
    this.setState({ slideSortSelected });
  }

  changeSortValue(event) {
    const slideSortValue = event.target.value;

    this.setState({ slideSortValue });
  }

  deleteSort(index) {
    let presentation = this.state.presentation;
    const slideConfigId = this.state.slideConfigId;

    if(presentation.Slides) {
      if(presentation.Slides.find(item => item.ConfigId === slideConfigId)) {
        // Remove Sort from the Slide
        presentation.Slides.find(item => item.ConfigId === slideConfigId).ArraySorts.splice(index,1);
      }
    }

    this.setState({ presentation });
  }

  saveSort() {
    let { presentation, slideConfigId, slideSortSelected, slideSortValue } = this.state;
    let slideSelected, sortOrder;

    // Find selected Slide
    if(presentation.Slides) {
      if(presentation.Slides.find(item => item.ConfigId === slideConfigId)) {
        slideSelected = presentation.Slides.find(item => item.ConfigId === slideConfigId);
      }
    }

    // Define Sort order
    sortOrder = slideSelected.ArraySorts.length + 1;

    // Add new Sort
    slideSelected.ArraySorts.push({ FieldId: slideSortSelected.FieldId, Field: slideSortSelected.Label, SortOrder: sortOrder, SortType: parseInt(slideSortValue, 10) });

    this.setState({ presentation });
  }

  saveSortEdit() {
    let { presentation, slideConfigId, slideSortValue, slideSortModif } = this.state;
    let slideSelected;

    // Find selected Slide
    if(presentation.Slides) {
      if(presentation.Slides.find(item => item.ConfigId === slideConfigId)) {
        slideSelected = presentation.Slides.find(item => item.ConfigId === slideConfigId);
      }
    }

    // Get Slide Sort selected
    let sortModif = slideSelected.ArraySorts[slideSortModif.SortOrder - 1];

    // Update Sort Type
    sortModif.SortType = parseInt(slideSortValue, 10);

    this.setState({ presentation });
  }

  triggerSlideEditSort(field) {
    const { login, authId, presentation, slideConfigId } = this.state;
    let slideModificationType = "EditSort";
    let slideId;
    
    if(presentation.Slides) {
      if(presentation.Slides.find(slide => slide.ConfigId === slideConfigId)) {
        slideId = presentation.Slides.find(slide => slide.ConfigId === slideConfigId).SlideId;
      }
    }

    this.getAvailableFields(login, authId, slideId);

    this.setState({ slideModificationPopup: true, slideModificationType, slideSortModif: field });
  }

  // ----- ----- Web Views ----- -----
  // Get Block Information from the API
  async getDashboardData(login, authId, slideItemId, slideItemType, blockType, currentView) {
    const language = this.state.language;

    this.setState({ isLoading: true });

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': slideItemType,
        'ItemId': slideItemId,
        'BlockType': blockType,
        'View': currentView,
        'WithData': true,
        'WithInformation': false,
        'InactiveData': false,
        'AdditionalContext': [{ "Key": "PresentationParentId", "Value": this.state.itemId }, { "Key": "PresentationParentType", "Value": this.state.itemType }]
      })
    };

    try{
      const response = await fetch(API_dashboard_info, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const cardContent = data.GetCardHomeInformationResult;

      if(cardContent) {
        const slideViewDashboardBlocks = cardContent.Blocks;

        this.setState({ slideViewDashboardSelected: currentView, slideViewDashboardBlocks, isLoading: false });
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  // Get Block Data from the API
  async getBlockData(login, authId, slideItemId, slideItemType, blockType, currentView) {
    const language = this.state.language;
    let forDashboard;

    // Specific case for BudgetDetails
    if(blockType === 'BudgetDetails') {
      forDashboard = false;
    }
    else {
      forDashboard = true;
    }

    this.setState({ isLoading: true });

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': slideItemType,
        'ItemId': slideItemId,
        'BlockType': blockType,
        'View': currentView,
        'WithData': true,
        'WithInformation': false,
        'InactiveData': false,
        'ForDashboard': forDashboard,
        'AdditionalContext': [{ "Key": "PresentationParentId", "Value": this.state.itemId }, { "Key": "PresentationParentType", "Value": this.state.itemType }]
      })
    };

    try{
      const response = await fetch(API_info, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const slideWebViewBlockContent = data.GetCardBlockInformationResult;

      if(slideWebViewBlockContent) {
        const slideWebViewFields = slideWebViewBlockContent.Fields;
        const slideWebViewTables = slideWebViewBlockContent.Tables;
        const slideWebChart = slideWebViewBlockContent.Chart;
        const slideWebHeatMaps = slideWebViewBlockContent.HeatMaps[0];
        const slideWebKanban = slideWebViewBlockContent.Kanban;
        const slideWebMap = slideWebViewBlockContent.Map;
        let slideWebViewColumns = [], slideWebViewRows = [];

        // Get Tables, Rows & Columns
        if(blockType === 'BudgetDetails' && currentView.ViewType === 3 && slideWebViewTables.find(element => element.Level === "Details")) {
          slideWebViewColumns = slideWebViewTables.find(element => element.Level === "Details").ColumnHeaders;
          slideWebViewRows = slideWebViewTables.find(element => element.Level === "Details").Rows;
        }
        else if(slideWebViewTables.find(element => element.Level === "Tree")) {
          slideWebViewColumns = slideWebViewTables.find(element => element.Level === "Tree").ColumnHeaders;
          slideWebViewRows = slideWebViewTables.find(element => element.Level === "Tree").Rows;
        }
        else if(slideWebViewTables.find(element => element.Level === "Workload")) {
          slideWebViewColumns = slideWebViewTables.find(element => element.Level === "Workload").ColumnHeaders;
          slideWebViewRows = slideWebViewTables.find(element => element.Level === "Workload").Rows;
        }

        this.setState({ slideViewSelected: currentView, slideWebViewBlockContent, slideWebViewFields, slideWebViewTables, slideWebViewColumns, slideWebViewRows, slideWebChart, slideWebHeatMaps, slideWebKanban, slideWebMap, isLoading: false });

        // Refresh Component Columns
        if(this.table.current) {
          this.table.current.refreshColumns(currentView, slideWebViewColumns);
        }
        else if(this.tree.current) {
          this.tree.current.refreshColumns(currentView, slideWebViewColumns);
        }
        else if(this.planning.current) {
          this.planning.current.refreshColumns(currentView, slideWebViewColumns);
        }
        // else if(this.chart.current) {
        //   this.chart.current.applySettings(view);
        // }
        // else if(this.map.current) {
        //   this.map.current.applySettings(view);
        // }
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  // Get Block Information from the API
  // async getBlockInformation(login, authId, itemId, itemType, blockType, viewId) {
  //   const language = this.state.language;

  //   // Request Options and Body
  //   const requestOptions = {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'Mode': 'Login',
  //       'Login': login,
  //       'Token': authId
  //     },
  //     body: JSON.stringify({
  //       'ItemType': itemType,
  //       'ItemId': itemId,
  //       'BlockType': blockType,
  //       'ViewId': viewId,
  //       'WithData': false,
  //       'WithInformation': true,
  //       'InactiveData': false,
  //       'ForDashboard': true
  //     })
  //   };

  //   try{
  //     const response = await fetch(API_info, requestOptions);

  //     if(!response.ok) {
  //       throw new Error('Something went wrong ...');
  //     }

  //     const data = await response.json();
  //     const slideWebViewBlockInfo = data.GetCardBlockInformationResult;

  //     if(slideWebViewBlockInfo) {
  //       const slideViews = slideWebViewBlockInfo.Views;

  //       this.setState({ slideViews });
  //     }
  //     else {
  //       // Create Cookie with the current URL
  //       Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

  //       // Redirect to Login Page
  //       this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
  //     }

  //   } catch(error) {
  //     this.setState({ error, isLoading: false });
  //   }
  // }

  getIconView(viewType) {
    switch(viewType) {
      case 0:
          return <span className="iconViewTypeTable iconsPopover mr10"></span>;
      case 1:
          return <span className="iconViewTypePlanning iconsPopover mr10"></span>;
      case 2:
          return <span className="iconViewTypeKanban iconsPopover mr10"></span>;
      case 3:
          return <span className="iconViewTypePivot iconsPopover mr10"></span>;
      case 4:
          return <span className="iconViewTypeList iconsPopover mr10"></span>;
      case 6:
          return <span className="iconViewTypeScheduler iconsPopover mr10"></span>;
    }
  }

  getIconBlockType(blockType, className) {
    switch(blockType) {
      case "Details":
          return <span className={"iconDetails " + className}></span>;
      case "Roadmap":
          return <span className={"iconRoadMap " + className}></span>;
      case "Qualitative Data":
          return <span className={"iconQualitativeData " + className}></span>;
      case "Qualitative":
          return <span className={"iconQualitativeData " + className}></span>;
      case "Risks":
          return <span className={"iconRisks " + className}></span>;
      case "Issues":
          return <span className={"iconIssues " + className}></span>;
      case "Decisions":
          return <span className={"iconDecisions " + className}></span>;
      case "Meetings":
          return <span className={"iconMeetings " + className}></span>;
      case "BudgetDetails":
          return <span className={"iconBudget " + className}></span>;
      case "Resources":
          return <span className={"iconResourceBlueLight " + className}></span>;
      case "Holidays":
          return <span className={"iconHolidaysBlue " + className}></span>;
      case "Burned":
          return<span className={"iconTimeTrackingBlueLight " + className}></span>;
      case "Workload":
          return <span className={"iconWorkload " + className}></span>;
      case "Communication":
          return <span className={"iconCommunication " + className}></span>;
      case "Earnings":
          return <span className={"iconEarnings " + className}></span>;
      default:
          break;
    }
  }

  changeFontSize(value) {
    const { presentation, slideConfigId } = this.state;
    let slideSelected;

    // Find selected Slide
    if(presentation.Slides) {
      if(presentation.Slides.find(item => item.ConfigId === slideConfigId)) {
        slideSelected = presentation.Slides.find(item => item.ConfigId === slideConfigId);
      }
    }

    // Update Slide Font Size
    slideSelected.FontSizeSetting = value;

    this.setState({ presentation });
  }

  async changeViewDashboardSelected(view) {
    const { login, authId, presentation, slideConfigId } = this.state;
    let slideSelected, slideItemType, slideItemId, slideId, slideWebViewBlockType;

    // Find selected Slide
    if(presentation.Slides) {
      if(presentation.Slides.find(item => item.ConfigId === slideConfigId)) {
        slideSelected = presentation.Slides.find(item => item.ConfigId === slideConfigId);
      }
    }

    // Set slide datas
    slideItemType = slideSelected.ItemType;
    slideItemId = slideSelected.ItemId;
    slideWebViewBlockType = slideSelected.CodeLink.substring(7);

    // Get Block Datas
    await this.getDashboardData(login, authId, slideItemId, slideItemType, slideWebViewBlockType, view);

    this.setState({ slideViewDashboardSelected: view });
  }

  async changeView(view) {
    const { login, authId, presentation, slideConfigId, slideViewSelected } = this.state;
    let slideSelected, slideItemType, slideItemId, slideId, slideWebViewBlockType;

    // Find selected Slide
    if(presentation.Slides) {
      if(presentation.Slides.find(item => item.ConfigId === slideConfigId)) {
        slideSelected = presentation.Slides.find(item => item.ConfigId === slideConfigId);
      }
    }

    // Set slide datas
    slideItemType = slideSelected.ItemType;
    slideItemId = slideSelected.ItemId;
    slideId = slideSelected.SlideId;
    slideWebViewBlockType = slideSelected.CodeLink.substring(7);

    // Get Block Datas
    await this.getBlockData(login, authId, slideItemId, slideItemType, slideWebViewBlockType, view);

    this.setState({ slideViewIdSelected: view.viewId });
  }

  saveViewDashboard() {
    const { slideViewDashboardSelected, slideDashboardViews, presentation, slideConfigId }  = this.state;
    let slideSelected;

    // Find selected Slide
    if(presentation.Slides) {
      if(presentation.Slides.find(item => item.ConfigId === slideConfigId)) {
        slideSelected = presentation.Slides.find(item => item.ConfigId === slideConfigId);
      }
    }

    // Set the new view dashboard Id
    slideSelected.WebViewDashboardId = slideViewDashboardSelected.ViewDashboardId;
    slideSelected.WebViewName = slideViewDashboardSelected.Name;
    
    this.setState({ presentation });
  }

  saveViewBlock() {
    const { presentation, slideViewSelected, slideConfigId }  = this.state;
    let slideSelected;

    // Find selected Slide
    if(presentation.Slides) {
      if(presentation.Slides.find(item => item.ConfigId === slideConfigId)) {
        slideSelected = presentation.Slides.find(item => item.ConfigId === slideConfigId);
      }
    }

    // Set the view Id and view Name
    slideSelected.WebViewId = slideViewSelected.ViewId;
    slideSelected.WebViewName = slideViewSelected.Name;

    this.setState({ presentation });
  }

  templatePopoverTemplate(currentValue, values) {
    const itemId = this.state.itemId;
    const itemType = this.state.itemType;

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverEditValue ItemId={itemId} ItemType={itemType} CurrentValue={currentValue} Values={values} onValueEdit={this.updatePresentationTemplate}></PopoverEditValue>
        </Popover.Content>
      </Popover>
    );

    return popover;
  }

  templatePopoverAxe1(slideSelected, axeId, axe) {
    const itemId = this.state.itemId;
    const itemType = this.state.itemType;

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverLoadAxes ItemId={itemId} ItemType={itemType} SlideId={slideSelected.SlideId} AxeId={axeId} CurrentValue={axe} onAxeEdit={this.updateAxe1}></PopoverLoadAxes>
        </Popover.Content>
      </Popover>
    );

    return popover;
  }

  templatePopoverAxe2(slideSelected, axeId, axe) {
    const itemId = this.state.itemId;
    const itemType = this.state.itemType;

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverLoadAxes ItemId={itemId} ItemType={itemType} SlideId={slideSelected.SlideId} AxeId={axeId} CurrentValue={axe} onAxeEdit={this.updateAxe2}></PopoverLoadAxes>
        </Popover.Content>
      </Popover>
    );

    return popover;
  }

  templatePopoverScope(slideSelected) {
    const language = this.state.language;
    const itemId = this.state.itemId;
    const itemType = this.state.itemType;
    let currentValue;

    if(slideSelected.ItemId === 0) {
      currentValue = Traduction.translate(language, 'select_your') + " " + slideSelected.ItemType;
    }
    else {
      currentValue = slideSelected.ItemLabel;
    }

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverLoadScopes ItemId={itemId} ItemType={itemType} SlideItemId={slideSelected.ItemId} SlideItemType={slideSelected.ItemType} SlideId={slideSelected.SlideId} CurrentValue={currentValue} onScopeEdit={this.changeScope}></PopoverLoadScopes>
        </Popover.Content>
      </Popover>
    );

    return popover;
  }

  updateErrors(err) {
    let errors = [];

    // Push the new Errors in the Errors Table
    errors.push(err);

    this.setState({ errors });
  }

  cleanErrors() {
    this.setState({ errors: [] });
  }

  render() {
    const { language, itemId, itemType, itemTitle, blockType, blockContent, editable, favorite, warnings, parents, communications, presentation, slideConfigId, slideModificationPopup, slideViewModificationPopup, isLoading, errors } = this.state;
    let name, title, subtitle, template, description, slides, configId, authorizedUsers, availableTemplate;

    let columnList = [];
    let slideSelected = null;

    // Presentation attributs
    if(presentation) {
      name = presentation.Name;
      title = presentation.Title;
      subtitle = presentation.Sub_Title;
      template = presentation.Template;
      description = presentation.Description;
      slides = presentation.Slides;
      configId = presentation.ConfigID;
      authorizedUsers = presentation.AuthorizedUsers;
      availableTemplate = presentation.AvailableTemplates;
    }

    // Define Slides datasource attributes for ListBox
    if(slides) {
      slides.forEach(slide => {
        columnList.push({ id: slide.ConfigId, text: slide.ConfigId, title: slide.Title, itemLabel: slide.ItemLabel, codeLink: slide.CodeLink });
      });
    }

    // Initialise selected Slide
    if(slides) {
      if(slides.find(item => item.ConfigId === slideConfigId)) {
        slideSelected = presentation.Slides.find(item => item.ConfigId === slideConfigId);
      }
    }

    // ListBox selection Settings
    this.selectionSettings = { mode: "single" };

    return (
      <div className="blockContainer">
        {/* Title */}
        <MetaTags><title>{presentation.Name} • {itemTitle} • {Traduction.translate(language, 'communication')}</title></MetaTags>

        {/* Navbar */}
        <Navbar Selected={this.props.match.url}></Navbar>

        {/* HasRightOnItem = false */}
        {blockContent.HasRightOnItem === false && <div className="block">
          <div className="blockHeader">
            <div className="blockTitle">
              <div className="cardIcon"><div className="cardIconGrey"><span className="iconNoRights iconsCard"></span></div></div>
              <div className="flex"><span className="cardTitle">{Traduction.translate(language, 'no_sufficient_rights')}</span></div>
            </div>
          </div>
        </div>}

        {/* Block */}
        {blockContent.HasRightOnItem === true && <div className="block">
          {/* Card Block Header */}
          <div className="blockHeader">
            {/* Title & Parents */}
            <BlockTitle ItemId={itemId} ItemType={itemType} ItemTitle={itemTitle} BlockType={blockType} Blocks={blockContent.BlockNames} Favorite={favorite} Parents={parents} Warnings={warnings}></BlockTitle>
            {/* Border */}
            <div className="blockBorder"></div>
          </div>

          {/* Card Block Body */}
          <div className="blockBody">
            {/* Card Block Content */}
            <div className="blockContent">
              {/* Errors */}
              {errors.length > 0 && 
                <ErrorModification Errors={errors} Open={true} onErrorsClean={this.cleanErrors}></ErrorModification>
              }

              {/* Popup Form */}
              {(slideModificationPopup === true) && <div className="slideModificationPopup">
                <div className="slideInnerModificationPopup">{this.templateModificationPopup()}</div>
              </div>}

              {/* Popup View Form */}
              {(slideViewModificationPopup === true) && <div className="slideModificationPopup">
                <div className="editPanelInnerPopup">{this.templateSlideViewPopup()}</div>
              </div>}

              {/* Navigation */}
              <div className="blockCommunicationNavigation">
                {/* Presentations List Link */}
                <Link to={`/Card/${itemType}/${itemId}/Communication`}><span className="iconHome icons cursor ml10 mr15 mvauto"></span></Link>
                {/* Icon Arrow */}
                <div className="iconArrowRightBlue icons mt5 mr15"></div>
                {/* Presentation Name */}
                <div className="inline-flex align-items-center width100p">
                  <div className="iconPPT iconsCard mt5"></div>
                  <div className="flex align-items-center width100p">
                    <Form.Group className="width100p mb0">
                      <Form.Control id="name" className="blockCommunicationFields slideLabelName" placeholder={Traduction.translate(language, 'add_presentation_name')} as="textarea" rows="1" value={name} 
                        onChange={(e) => this.updatePresentationName(e)}/>
                    </Form.Group>
                  </div>
                </div>
                <div className="flex-end inline-flex justify-content-center align-items-center mh10">
                  {/* Build & Save Presentation Buttons */}
                  {!isLoading && <div className="flex">
                    <Button className="fs12 bold brd-radius" variant="primary" onClick={(e) => this.buildPresentation(configId)}>{Traduction.translate(language, 'build')}</Button>
                    <Button className="fs12 bold brd-radius ml10" variant="success" onClick={(e) => this.saveCommunication(presentation)}>{Traduction.translate(language, 'save')}</Button>
                  </div>}
                  {/* Spinner Loading */}
                  {isLoading && <div className="spinnerCommunicationEdition"><LoadingSpinner></LoadingSpinner></div>}
                </div>
              </div>

              {/* Header */}
              <div className="blockCommunicationHeader">
                {/* Title and Subtitle */}
                <div className="col-md-4 flex flex-column">
                  {/* Title */}
                  <Form.Group className="width100p mb5">
                    <Form.Control id="title" className="blockCommunicationFields slideLabel" as="textarea" rows="1" placeholder={Traduction.translate(language, 'add_title')} value={title} 
                      onChange={(e) => this.updatePresentationTitle(e)}/>
                  </Form.Group>
                  {/* SubTitle */}
                  <Form.Group className="width100p mb5">
                    <Form.Control id="subtitle" className="blockCommunicationFields communicationSubtitle orange-light" as="textarea" rows="1" placeholder={Traduction.translate(language, 'add_subtitle')} value={subtitle} 
                      onChange={(e) => this.updatePresentationSubtitle(e)}/>
                  </Form.Group>
                </div>
                {/* Template */}
                <div className="col-md-4 inline-flex align-items-center justify-content-center">
                  <Form.Label className="blockCommunicationLabel mr20">{Traduction.translate(language, 'communication_template')}</Form.Label>
                  <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={this.templatePopoverTemplate(template, availableTemplate)}>
                    <div className="selectTemplate cursor">
                      <div className="">{template}</div>
                      <span className="iconChevronDownGrey extrasmallIcons ml10"></span>
                    </div>
                  </OverlayTrigger>
                </div>
                {/* Rights */}
                <div className="col-md-4 inline-flex align-items-center flex-end">
                  {configId != -1 && <IndicatorsRights ItemId={configId} ItemType={'PowerpointPresentation'} ItemTitle={presentation.Name} Rights={authorizedUsers}></IndicatorsRights>}
                </div>
              </div>

              {/* Border */}
              <div className="blockCommunicationBorder"></div>

              {/* Content */}
              <div className="blockCommunicationContent">
                {/* Slides */}
                <div className="col-md-2 slidesList">
                  <div className="slideLabel">
                    {/* Label */}
                    <Form.Label className="blockCommunicationLabel">{Traduction.translate(language, 'slides')}</Form.Label>
                    {/* Add Slide */}
                    <span className="iconButtonPlus iconsFilter mh10 cursor" onClick={() => this.triggerSlideModificationPopup("AddSlide")}/>
                  </div>
                  {/* Slides list */}
                  <div className={(errors.length > 0 ? "communicationSlideListErrors" : "communicationSlideList") + " communicationListBox"}>
                    <ListBoxComponent locale={Traduction.translate(language, 'locale')} dataSource={columnList} selectionSettings={this.selectionSettings} drop={this.drop} change={this.change} itemTemplate={this.slideTemplate} allowDragAndDrop={true} ref={listBox=>this.listBox=listBox}></ListBoxComponent>
                  </div>
                </div>
                      
                {/* Slide selected */}
                {slideSelected && <div className="col-md-7">
                  <div className={errors.length > 0 ? "communicationCentralBlockErrors" : "communicationCentralBlock"}>
                    <div className="flex-end">
                      {/* Duplicate & Delete Buttons */}
                      {!isLoading && <div className="flex">
                        <Button className="fs12 bold brd-radius" variant="secondary" onClick={() => this.duplicateSlide()}>{Traduction.translate(language, 'duplicate')}</Button>
                        <Button className="fs12 bold brd-radius ml10" variant="danger" onClick={() => this.deleteSlide()}>{Traduction.translate(language, 'delete')}</Button>
                      </div>}
                      {/* Spinner Loading */}
                      {isLoading && <div className="spinnerSlideEdition"><LoadingSpinner></LoadingSpinner></div>}
                    </div>

                    {/* Slide Preview */}
                    <div className="flex flex-column communicationCentralSlide">
                      <div className="flex flex-column width100p">
                        {/* Slide Title */}
                        <Form.Group className="width100p mb5">
                          <Form.Control id="slide_title" className="blockCommunicationFields slideLabel" as="textarea" rows="1" placeholder={Traduction.translate(language, 'add_title')} value={slideSelected.Title} 
                            onChange={(e) => this.updateSlideTitle(e)}/>
                        </Form.Group>
                        {/* Slide Item Type */}
                        <div className="ml10">
                          <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={this.templatePopoverScope(slideSelected)}>
                            <div className="cursor">
                              {slideSelected.ItemId !== 0 && <div className="communicationSubtitle orange-light">{slideSelected.ItemLabel}</div>}
                              {slideSelected.ItemId === 0 && slideSelected.ItemType !== 'Title' && <div className="communicationSubtitle red underline">{Traduction.translate(language, 'select_your') + " " + slideSelected.ItemType}</div>}
                            </div>
                          </OverlayTrigger>
                        </div>
                        {/* <Form.Group className="width100p mb3">
                          <Form.Control id="slide_Label" className="blockCommunicationFields communicationSubtitle" as="textarea" rows="1" value={slideSelected.ItemLabel} readOnly={true}/>
                        </Form.Group> */}
                      </div>
                      {/* Slide Image */}
                      <div className="flex align-items-center justify-content-center">
                        <div className={this.getSlideCodeLink(slideSelected.CodeLink) + " iconsBigSlides"}></div>
                      </div>
                    </div>

                    {/* Slide Number */}
                    <div className="flex flex-end align-items-center mv10">
                      {this.getSlideNumber(slideSelected) === 1  && <span className="icons"></span>}
                      {this.getSlideNumber(slideSelected) !== 1  && <span className="iconPrevious icons cursor" onClick={(e) => this.previousSlide(this.getSlideNumber(slideSelected)-1)}></span>}
                      <div className="axe bordered grey">{Traduction.translate(language, 'slide') + " " + this.getSlideNumber(slideSelected) + "/" + slides.length}</div>
                      {this.getSlideNumber(slideSelected) === slides.length && <span className="icons"></span>}
                      {this.getSlideNumber(slideSelected) !== slides.length && <span className="iconNext icons cursor" onClick={(e) => this.nextSlide(this.getSlideNumber(slideSelected)-1)}></span>}
                    </div>
                  </div>
                </div>}

                {/* Slide Parameters */}
                {slideSelected && <div className="col-md-3 slideParameters">
                  <div className="flex flex-column">
                    {slideSelected.SlideType && <div className="flex inline-flex align-items-center">
                      {/* Label */}
                      <Form.Label className="blockCommunicationLabel">{Traduction.translate(language, 'parameters') + " " + Traduction.translate(language, 'of') + " " + slideSelected.SlideType}</Form.Label>
                      {/* Support Link Info */}
                      <a target="_self" href={"https://help.tabsters.fr/support/" + Traduction.translate(language, 'locale') + "/communication/" + slideSelected.SlideId}>
                        {['top'].map(placement => (
                          <OverlayTrigger key={placement} placement={placement} overlay={<Tooltip id={`tooltip-${placement}`}><strong>{Traduction.translate(language, 'information')}</strong></Tooltip>}>
                            <span className="ml10 iconInfo iconsSlideParameters cursor"></span>
                          </OverlayTrigger>
                        ))}
                      </a>
                    </div>}

                    {/* Title */}
                    <div className="slideFieldsParameters">
                      {/* Icon */}
                      {['top'].map(placement => (
                        <OverlayTrigger key={placement} placement={placement} overlay={<Tooltip id={`tooltip-${placement}`}><strong>{Traduction.translate(language, 'title')}</strong></Tooltip>}>
                          <div className="communicationSlideIconParameters">
                            <div className="flex align-items-center"><span className="iconSlideTitleDark iconsSlideParameters" alt="Global"/></div>
                          </div>
                        </OverlayTrigger>
                      ))}
                      {/* Value */}
                      <Form.Group className="width100p mb0 mt5">
                        <Form.Control id="slide_title" className="blockCommunicationFields slideFieldsLabel blue" as="textarea" rows="1" placeholder={Traduction.translate(language, 'add_title')} value={slideSelected.Title} 
                          onChange={(e) => this.updateSlideTitle(e)}/>
                      </Form.Group>
                    </div>

                    {/* Item Type */}
                    {slideSelected.ItemType !== 'Title' && <div className="slideFieldsParameters">
                      {/* Icon */}
                      {['top'].map(placement => (
                        <OverlayTrigger key={placement} placement={placement} overlay={<Tooltip id={`tooltip-${placement}`}><strong>{Traduction.translate(language, 'scope')}</strong></Tooltip>}>
                          <div className="communicationSlideIconParameters">
                            <div className="flex align-items-center">{this.getSlideItemTypeIcon(slideSelected.ItemType)}</div>
                          </div>
                        </OverlayTrigger>
                      ))}
                      {/* Value */}
                      <div className="inline-flex align-items-center">
                        <div className="slideFieldsLabel blue ml10">{slideSelected.ItemTypeLabel}</div>
                        <div className="ml10">
                          <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={this.templatePopoverScope(slideSelected)}>
                            <div className="cursor">
                              {slideSelected.ItemId !== 0 && <div className="slideFieldsLabel orange-light">{slideSelected.ItemLabel}</div>}
                              {slideSelected.ItemId === 0 && <div className="slideFieldsLabel red underline">{Traduction.translate(language, 'select_your') + " " + slideSelected.ItemType}</div>}
                            </div>
                          </OverlayTrigger>
                        </div>
                      </div>
                    </div>}

                    {/* Dashboard View */}
                    {slideSelected.CodeLink.includes("W_View_Dashboard") && <div className="slideFieldsParameters">
                      {/* Icon */}
                      {['top'].map(placement => (
                        <OverlayTrigger key={placement} placement={placement} overlay={<Tooltip id={`tooltip-${placement}`}><strong>{Traduction.translate(language, 'parameter')}</strong></Tooltip>}>
                          <div className="communicationSlideIconParameters cursor" onClick={(slideSelected.ItemId !== 0) ? () => this.triggerSlideViewPopup("WebViewDashboard") : null}>
                            <div className="iconViewTypeDashboardBlack iconsSlideParameters" title="Dashboard"></div>
                            {/* <span className="iconColumns iconsFilter cursor"/> */}
                          </div>
                        </OverlayTrigger>
                      ))}
                      {/* Dashboard View Name */}
                      <div className="flex inline-flex align-items-center">
                        <div className="flex inline-flex cursor" onClick={(slideSelected.ItemId !== 0) ? () => this.triggerSlideViewPopup("WebViewDashboard") : null}>
                          <div className="slideFieldsLabel blue ml10">{Traduction.translate(language, 'dashboard')}</div>
                          {slideSelected.WebViewDashboardId !== 0 && <div className="slideFieldsLabel orange-light ml10">{slideSelected.WebViewName}</div>}
                        </div>
                        {/* Icon View Selected */}
                        {slideSelected.WebViewDashboardId !== 0 && slideSelected.ItemId !== -1 && !isLoading && <a className="flex" target="_self" href={`/Card/${slideSelected.ItemType}/${slideSelected.ItemId}/Home?viewId=${slideSelected.WebViewDashboardId}`}><div className="iconEye iconsSlideParameters cursor ml10"></div></a>}
                        {slideSelected.WebViewDashboardId !== 0 && slideSelected.ItemId !== -1 && isLoading && <div className="spinnerSlideViewLoading"><LoadingSpinner></LoadingSpinner></div>}
                        {/* Icon View Selection */}
                        {slideSelected.WebViewDashboardId !== 0 && slideSelected.ItemId === -1 && !isLoading && <div className="iconEyeGrey iconsSlideParameters cursor ml10"></div>}
                        {slideSelected.WebViewDashboardId !== 0 && slideSelected.ItemId === -1 && isLoading && <div className="spinnerSlideViewLoading"><LoadingSpinner></LoadingSpinner></div>}
                        {/* Edition icon */}
                        {slideSelected.WebViewDashboardId === 0 && slideSelected.ItemId !== 0 && !isLoading && <div className="iconEdit verysmallIcons cursor ml10" onClick={() => this.triggerSlideViewPopup("WebViewDashboard")}></div>}
                        {slideSelected.WebViewDashboardId === 0 && isLoading && <div className="spinnerSlideViewLoading"><LoadingSpinner></LoadingSpinner></div>}
                      </div>
                    </div>}

                    {/* Web View Block */}
                    {slideSelected.CodeLink.includes("W_View") && slideSelected.CodeLink !== "W_View_Dashboard" && slideSelected.CodeLink !== "W_View_Qualitative" && <div className="slideFieldsParameters">
                      {/* Icon */}
                      {['top'].map(placement => (
                        <OverlayTrigger key={placement} placement={placement} overlay={<Tooltip id={`tooltip-${placement}`}><strong>{Traduction.translate(language, 'parameter')}</strong></Tooltip>}>
                          <div className="communicationSlideIconParameters cursor" onClick={(slideSelected.ItemId !== 0) ? () => this.triggerSlideViewPopup("WebView") : null}>
                            <div className="">{this.getIconBlockType(slideSelected.CodeLink.substring(7), "iconsSlideParameters")}</div>
                            {/* <div className="iconViewTypeDashboard iconsSlideParameters" title="Dashboard"></div> */}
                            {/* <span className="iconColumns iconsFilter cursor"/> */}
                          </div>
                        </OverlayTrigger>
                      ))}
                      {/* Web View Name */}
                      <div className="flex inline-flex align-items-center">
                        <div className="flex inline-flex cursor" onClick={(slideSelected.ItemId !== 0) ? () => this.triggerSlideViewPopup("WebView") : null}>
                          <div className="slideFieldsLabel blue ml10">{slideSelected.CodeLink.substring(7)}</div>
                          {slideSelected.WebViewId !== 0 && <div className="slideFieldsLabel orange-light ml10">{slideSelected.WebViewName}</div>}
                        </div>
                        {/* Icon View Selected */}
                        {slideSelected.WebViewId !== 0 && slideSelected.ItemId !== -1 && !isLoading && <a target="_self" href={`/Card/${slideSelected.ItemType}/${slideSelected.ItemId}/${slideSelected.CodeLink.substring(7)}?viewId=${slideSelected.WebViewId}`}><div className="iconEye iconsSlideParameters ml10 cursor"></div></a>}
                        {slideSelected.WebViewId !== 0 && slideSelected.ItemId !== -1 && isLoading && <div className="spinnerSlideViewLoading"><LoadingSpinner></LoadingSpinner></div>}
                        {/* Icon View Selection */}
                        {slideSelected.WebViewId !== 0 && slideSelected.ItemId === -1 && !isLoading && <div className="iconEyeGrey iconsSlideParameters ml10 cursor"></div>}
                        {slideSelected.WebViewId !== 0 && slideSelected.ItemId === -1 && isLoading && <div className="spinnerSlideViewLoading"><LoadingSpinner></LoadingSpinner></div>}
                        {/* Edition icon */}
                        {slideSelected.WebViewId === 0 && slideSelected.ItemId !== 0 && !isLoading && <div className="iconEdit verysmallIcons cursor ml10" onClick={() => this.triggerSlideViewPopup("WebView")}></div>}
                        {slideSelected.WebViewId === 0 && isLoading && <div className="spinnerSlideViewLoading"><LoadingSpinner></LoadingSpinner></div>}
                      </div>
                    </div>}

                    {/* Web View Meteo */}
                    {slideSelected.CodeLink === "W_View_Qualitative" && <div className="slideFieldsParameters">
                      {/* Icon */}
                      {['top'].map(placement => (
                        <OverlayTrigger key={placement} placement={placement} overlay={<Tooltip id={`tooltip-${placement}`}><strong>{Traduction.translate(language, 'parameter')}</strong></Tooltip>}>
                          <div className="communicationSlideIconParameters">
                            <div className="">{this.getIconBlockType(slideSelected.CodeLink.substring(7), "iconsSlideParameters")}</div>
                            {/* <div className="iconViewTypeDashboard iconsSlideParameters" title="Dashboard"></div> */}
                            {/* <span className="iconColumns iconsFilter cursor"/> */}
                          </div>
                        </OverlayTrigger>
                      ))}
                      {/* Web View Name */}
                      <div className="flex inline-flex align-items-center">
                        <div className="flex inline-flex">
                          <div className="slideFieldsLabel blue ml10">{slideSelected.CodeLink.substring(7)}</div>
                          {slideSelected.WebViewId !== 0 && <div className="slideFieldsLabel orange-light ml10">{slideSelected.WebViewName}</div>}
                        </div>
                        {/* Icon View */}
                        {!isLoading && <a target="_self" href={`/Card/${slideSelected.ItemType}/${slideSelected.ItemId}/${slideSelected.CodeLink.substring(7)}`}><div className="iconEye iconsSlideParameters ml10 cursor"></div></a>}
                        {isLoading && <div className="spinnerSlideViewLoading"><LoadingSpinner></LoadingSpinner></div>}
                      </div>
                    </div>}

                    {/* Web View Font Size */}
                    {slideSelected.CodeLink.includes("W_View") && <div className="slideFieldsParameters">
                      {/* Icon */}
                      {['top'].map(placement => (
                        <OverlayTrigger key={placement} placement={placement} overlay={<Tooltip id={`tooltip-${placement}`}><strong>{Traduction.translate(language, 'font_size')}</strong></Tooltip>}>
                          <div className="communicationSlideIconParameters">
                            <span className="iconFontSizeSetting iconsSlideParameters"></span>
                          </div>
                        </OverlayTrigger>
                      ))}
                      {/* Values */}
                      <div className="flex inline-flex align-items-center">
                        <span className={"iconFontSize1 iconsSlideFontSize cursor ml10 mr5" + (slideSelected.FontSizeSetting === 1 ? "" : " opacity30")} onClick={() => this.changeFontSize(1)}></span>
                        <span className={"iconFontSize2 iconsSlideFontSize cursor mh5" + (slideSelected.FontSizeSetting === 2 ? "" : " opacity30")} onClick={() => this.changeFontSize(2)}></span>
                        <span className={"iconFontSize3 iconsSlideFontSize cursor mh5" + (slideSelected.FontSizeSetting === 3 ? "" : " opacity30")} onClick={() => this.changeFontSize(3)}></span>
                        <span className={"iconFontSize4 iconsSlideFontSize cursor mh5" + (slideSelected.FontSizeSetting === 4 ? "" : " opacity30")} onClick={() => this.changeFontSize(4)}></span>
                        <span className={"iconFontSize5 iconsSlideFontSize cursor mh5" + (slideSelected.FontSizeSetting === 5 ? "" : " opacity30")} onClick={() => this.changeFontSize(5)}></span>
                      </div>
                    </div>}

                    {/* Fields */}
                    {slideSelected.WithArrayFields && <div className="slideFieldsParameters">
                      {/* Icon */}
                      {['top'].map(placement => (
                        <OverlayTrigger key={placement} placement={placement} overlay={<Tooltip id={`tooltip-${placement}`}><strong>{Traduction.translate(language, 'field')}</strong></Tooltip>}>
                          <div className="communicationSlideIconParameters cursor" onClick={() => this.triggerSlideModificationPopup("AddField")}>
                            {(slideSelected.ArrayFields && slideSelected.ArrayFields.ArrayFieldIds) && <div className="iconArrayFields iconsSlideParameters"></div>}
                            {(slideSelected.ArrayFields && !slideSelected.ArrayFields.ArrayFieldIds) && <div className="iconArrayFieldsGrey iconsSlideParameters"></div>}
                          </div>
                        </OverlayTrigger>
                      ))}
                      {/* Values */}
                      {slideSelected.ArrayFields && slideSelected.ArrayFields.Labels && slideSelected.ArrayFields.Labels.length <= 25 && 
                        <div className="slideFieldsLabel blue cursor ml10" onClick={() => this.triggerSlideModificationPopup("AddField")}>{slideSelected.ArrayFields.Labels}</div>
                      }
                      {slideSelected.ArrayFields && slideSelected.ArrayFields.Labels && slideSelected.ArrayFields.Labels.length > 25 && 
                        <div className="slideFieldsLabel blue cursor ml10" onClick={() => this.triggerSlideModificationPopup("AddField")} title={slideSelected.ArrayFields.Labels}>{slideSelected.ArrayFields.Labels.substring(0,25) + "..."}</div>
                      }
                      {/* Add Field */}
                      {slideSelected.ArrayFields && !slideSelected.ArrayFields.ArrayFieldIds && <div className="slideAddParametersLabel" onClick={() => this.triggerSlideModificationPopup("AddField")}>{Traduction.translate(language, 'define_fields')}</div>}
                      {/* Edition icon */}
                      {/* <div className="ml10 iconEdit iconsSlideParameters cursor" onClick={() => this.triggerSlideModificationPopup("Fields")}></div> */}
                    </div>}

                    {/* Indicators */}
                    {slideSelected.NbIndicators > 0 && <div className="slideFieldsParameters">
                      {/* Icon */}
                      {['top'].map(placement => (
                        <OverlayTrigger key={placement} placement={placement} overlay={<Tooltip id={`tooltip-${placement}`}><strong>{Traduction.translate(language, 'indicators')}</strong></Tooltip>}>
                          <div className="communicationSlideIconParameters cursor" onClick={() => this.triggerSlideModificationPopup("AddIndicator")}>
                            {(slideSelected.SlideIndicators && slideSelected.SlideIndicators.length !== 0) && <div className="iconIndicators iconsSlideParameters"></div>}
                            {(slideSelected.SlideIndicators && slideSelected.SlideIndicators.length === 0) && <div className="iconIndicatorsGrey iconsSlideParameters"></div>}
                          </div>
                        </OverlayTrigger>
                      ))}
                      {/* Values */}
                      <div className="flex flex-column">
                        {slideSelected.SlideIndicators && <div className="flex flex-column ml10">
                          {slideSelected.SlideIndicators.map((item, index) => {
                            return(<div key={index} className="inline-flex align-items-center mb5">
                              {/* Indicator Value */}
                              <div className="slideFieldsLabel blue">{item.Indicator}</div>
                              {/* Delete icon */}
                              <div className="iconClearRed verysmallIcons cursor ml5" onClick={() => this.deleteIndicator(index)}></div>
                            </div>);
                          })} 
                        </div>}
                        {/* Add Indicator */}
                        {slideSelected.SlideIndicators && (slideSelected.SlideIndicators.length < slideSelected.NbIndicators) && 
                          <div className="slideAddParametersLabel" onClick={() => this.triggerSlideModificationPopup("AddIndicator")}>{Traduction.translate(language, 'add_indicator')}</div>
                        }
                      </div>
                    </div>}

                    {/* Filters */}
                    {slideSelected.WithFilters && <div className="slideFieldsParameters">
                      {/* Icon */}
                      {['top'].map(placement => (
                        <OverlayTrigger key={placement} placement={placement} overlay={<Tooltip id={`tooltip-${placement}`}><strong>{Traduction.translate(language, 'filter')}</strong></Tooltip>}>
                          <div className="communicationSlideIconParameters cursor" onClick={() => this.triggerSlideModificationPopup("AddFilter")}>
                            {(slideSelected.SlideFilters && slideSelected.SlideFilters.length !== 0)  && <div className="iconFilters iconsSlideParameters"></div>}
                            {(slideSelected.SlideFilters && slideSelected.SlideFilters.length === 0) && <div className="iconFiltersGrey iconsSlideParameters"></div>}
                          </div>
                        </OverlayTrigger>
                      ))}
                      {/* Values */}
                      <div className="flex flex-column">
                        {slideSelected.SlideFilters && <div className="ml10 flex flex-column">
                          {slideSelected.SlideFilters.map((filter, index) => {
                            return(<div key={index} className="inline-flex align-items-center cursor mb5">
                              {/* Filter Value */}
                              <div className="inline-flex" onClick={() => this.triggerSlideEditFilter(filter)}>
                                <div className="slideFieldsLabel blue">{filter.Field}</div>
                                {filter.FilterString && filter.FilterString.length < 25 && <div className="slideFieldsLabel orange-light ml5">{filter.FilterString}</div>}
                                {filter.FilterString && filter.FilterString.length >= 25 && <div className="slideFieldsLabel orange-light ml5" title={filter.FilterString}>{filter.FilterString.substring(0,25) + "..."}</div>}
                              </div>
                              {/* Delete icon */}
                              <div className="iconClearRed verysmallIcons cursor ml5" onClick={() => this.deleteFilter(index)}></div>
                            </div>);
                          })} 
                        </div>}
                        {/* Add Filter */}
                        <div className="slideAddParametersLabel" onClick={() => this.triggerSlideModificationPopup("AddFilter")}>{Traduction.translate(language, 'add_filter')}</div>
                      </div>
                    </div>}

                    {/* Date Range */}
                    {slideSelected.WithDateRange && <div className="slideFieldsParameters">
                      {/* Icon */}
                      {['top'].map(placement => (
                        <OverlayTrigger key={placement} placement={placement} overlay={<Tooltip id={`tooltip-${placement}`}><strong>{Traduction.translate(language, 'date_range')}</strong></Tooltip>}>
                          <div className="communicationSlideIconParameters cursor" onClick={() => this.triggerSlideModificationPopup("AddDateRange")}>
                            {(slideSelected.DateRange && slideSelected.DateRange.RangeType !== 0) && <div className="iconDateRange iconsSlideParameters"></div>}
                            {(slideSelected.DateRange && slideSelected.DateRange.RangeType === 0) && <div className="iconDateRangeGrey iconsSlideParameters"></div>}
                          </div>
                        </OverlayTrigger>
                      ))}
                      {/* Values */}
                      {(slideSelected.DateRange && slideSelected.DateRange.RangeType !== 0) && <div className="slideFieldsLabel blue cursor ml10 mr5" onClick={() => this.triggerSlideModificationPopup("AddDateRange")}>{Traduction.translate(language, 'filter')}</div>}
                      {(slideSelected.DateRange && slideSelected.DateRange.RangeType !== 0 && slideSelected.DateRange.BorneInf && slideSelected.DateRange.BorneSup) && 
                        <div className="inline-flex align-items-center mb5">
                          {/* Date Range Value */}
                          <div className="slideFieldsLabel orange-light cursor" onClick={() => this.triggerSlideModificationPopup("AddDateRange")}>{slideSelected.DateRange.RangeLabel}</div>
                          {/* Delete icon */}
                          <div className="iconClearRed verysmallIcons cursor ml5" onClick={() => this.deleteDateRange()}></div>
                        </div>
                      }
                      {/* Add Date Range */}
                      {(slideSelected.DateRange && slideSelected.DateRange.RangeType === 0) && <div className="slideAddParametersLabel" onClick={() => this.triggerSlideModificationPopup("AddDateRange")}>{Traduction.translate(language, 'add_date_range')}</div>}
                      {/* Edition icon */}
                      {/* <div className="ml10 iconEdit iconsSlideParameters cursor" onClick={() => this.triggerSlideModificationPopup("DateRange")}></div> */}
                    </div>}

                    {/* Sort */}
                    {slideSelected.WithArrayFields && <div className="slideFieldsParameters">
                      {/* Icon */}
                      {['top'].map(placement => (
                        <OverlayTrigger key={placement} placement={placement} overlay={<Tooltip id={`tooltip-${placement}`}><strong>{Traduction.translate(language, 'sort')}</strong></Tooltip>}>
                          <div className="communicationSlideIconParameters cursor" onClick={() => this.triggerSlideModificationPopup("AddSort")}>
                            {(slideSelected.ArraySorts && slideSelected.ArraySorts.length !== 0) && <div className="iconSort iconsSlideParameters"></div>}
                            {(slideSelected.ArraySorts && slideSelected.ArraySorts.length === 0) && <div className="iconSortGrey iconsSlideParameters"></div>}
                          </div>
                        </OverlayTrigger>
                      ))}
                      {/* Values */}
                      <div className="flex flex-column">
                        {slideSelected.ArraySorts && <div className="flex flex-column ml10">
                          {slideSelected.ArraySorts.map((item,index) => {
                            return(<div key={index} className="inline-flex align-items-center cursor mb5">
                              {/* Sort Value */}
                              <div className="inline-flex align-items-center" onClick={() => this.triggerSlideEditSort(item)}>
                                {item.Field && item.Field.length < 25 && <div className="slideFieldsLabel blue">{item.Field}</div>}
                                {item.Field && item.Field.length >= 25 && <div className="slideFieldsLabel blue" title={item.Field}>{item.Field.substring(0,25) + "..."}</div>}
                                <div className="fs12 ml5">{Traduction.translate(language, 'order_by')}</div>
                                {item.SortType === 0 && <div className="slideFieldsLabel orange-light ml5">{Traduction.translate(language, 'ascending_order')}</div>}
                                {item.SortType === 1 && <div className="slideFieldsLabel orange-light ml5">{Traduction.translate(language, 'descending_order')}</div>}
                              </div>
                              {/* Delete icon */}
                              <div className="iconClearRed verysmallIcons cursor ml5" onClick={() => this.deleteSort(index)}></div>
                            </div>);
                          })}
                        </div>}
                        {/* Add Sort */}
                        <div className="slideAddParametersLabel" onClick={() => this.triggerSlideModificationPopup("AddSort")}>{Traduction.translate(language, 'add_sort')}</div>
                      </div>
                    </div>}

                    {/* Axes */}
                    {slideSelected.NbAxes > 0 && <div className="slideFieldsParameters align-items-center">
                      {/* Icon */}
                      {['top'].map(placement => (
                        <OverlayTrigger key={placement} placement={placement} overlay={<Tooltip id={`tooltip-${placement}`}><strong>{"Axe"}</strong></Tooltip>}>
                          <div className="communicationSlideIconParameters cursor">
                            {(slideSelected.Axes && (slideSelected.Axes.Axe1 || slideSelected.Axes.Axe2)) && <div className="iconAxeSlide iconsSlideParameters"></div>}
                            {(slideSelected.Axes && (!slideSelected.Axes.Axe1 && !slideSelected.Axes.Axe2)) && <div className="iconAxeSlideGrey iconsSlideParameters"></div>}
                            {/* <span className="iconColumns iconsFilter cursor"/> */}
                          </div>
                        </OverlayTrigger>
                      ))}
                      {/* Values */}
                      <div className="inline-flex ml10">
                        <OverlayTrigger trigger="click" rootClose placement="right-end" overlay={this.templatePopoverAxe1(slideSelected, slideSelected.Axes.Axe1Id, slideSelected.Axes.Axe1)}>
                          <div className="cursor">
                            {slideSelected.Axes && slideSelected.Axes.Axe1 && <div className="axe bordered blue">{slideSelected.Axes.Axe1}</div>}
                            {slideSelected.Axes && !slideSelected.Axes.Axe1 && <div className="axe bordered grey"> -- </div>}
                          </div>
                        </OverlayTrigger>
                        <OverlayTrigger trigger="click" rootClose placement="right-end" overlay={this.templatePopoverAxe2(slideSelected, slideSelected.Axes.Axe2Id, slideSelected.Axes.Axe2)}>
                          <div className="cursor ml10">
                            {slideSelected.Axes && slideSelected.Axes.Axe2 && <div className="axe bordered blue">{slideSelected.Axes.Axe2}</div>}
                            {slideSelected.Axes && !slideSelected.Axes.Axe2 && <div className="axe bordered grey"> -- </div>}
                          </div>
                        </OverlayTrigger>
                      </div>
                    </div>}

                    {/* Parameters */}
                    {slideSelected.WithParameters && <div className="slideFieldsParameters">
                      {/* Icon */}
                      {['top'].map(placement => (
                        <OverlayTrigger key={placement} placement={placement} overlay={<Tooltip id={`tooltip-${placement}`}><strong>{Traduction.translate(language, 'parameter')}</strong></Tooltip>}>
                          <div className="communicationSlideIconParameters cursor" onClick={() => this.triggerSlideModificationPopup("AddParameter")}>
                            {/* <span className="iconColumns iconsFilter cursor"/> */}
                            {(slideSelected.Parameters && slideSelected.Parameters.length !== 0) && <div className="iconParameters iconsSlideParameters" title={Traduction.translate(language, 'parameter')}></div>}
                            {(slideSelected.Parameters && slideSelected.Parameters.length === 0) && <div className="iconParametersGrey iconsSlideParameters" title={Traduction.translate(language, 'parameter')}></div>}
                          </div>
                        </OverlayTrigger>
                      ))}
                      {/* Values */}
                      <div className="flex flex-column">
                        {slideSelected.Parameters && <div className="flex flex-column ml10">
                          {slideSelected.Parameters.map((param, index) => {
                            return(<div key={index} className="inline-flex align-items-center cursor">
                              {/* Parameter Value */}
                              <div className="inline-flex" onClick={() => this.triggerSlideEditParameter(param)}>
                                {param.Parameter && param.Parameter.length < 25 && <div className="slideFieldsLabel blue">{param.Parameter}</div>}
                                {param.Parameter && param.Parameter.length >= 25 && <div className="slideFieldsLabel blue" title={param.Parameter}>{param.Parameter.substring(0,25) + "..."}</div>}
                                <div className="slideFieldsLabel orange-light ml5">{param.Value}</div>
                              </div>
                              {/* Delete icon */}
                              <div className="iconClearRed verysmallIcons cursor ml5" onClick={() => this.deleteParameter(index)}></div>
                            </div>);
                          })}
                        </div>}
                        {/* Add Parameter */}
                        <div className="slideAddParametersLabel" onClick={() => this.triggerSlideModificationPopup("AddParameter")}>{Traduction.translate(language, 'add_parameter')}</div>
                      </div>
                    </div>} 
                  </div> 
                </div>}
              </div>
            </div>
          </div>
        </div>}
      </div>
    )
  }
}

export default BlockCommunicationEdition;