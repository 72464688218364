import React, { Component } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { DashboardLayoutComponent } from '@syncfusion/ej2-react-layouts';
import { HeatMapComponent, Legend, Tooltip, Adaptor, Inject } from '@syncfusion/ej2-react-heatmap';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';
import MiniBlockDetails from './MiniBlockDetails';
import MiniBlockRoadMap from './MiniBlockRoadMap';
import MiniBlockBudget from './MiniBlockBudget';
import MiniBlockBurned from './MiniBlockBurned';
import MiniBlockCommunication from './MiniBlockCommunication';
import MiniBlockDecisions from './MiniBlockDecisions';
import MiniBlockEarnings from './MiniBlockEarnings';
import MiniBlockIssues from './MiniBlockIssues';
import MiniBlockHolidays from './MiniBlockHolidays';
import MiniBlockMeetings from './MiniBlockMeetings';
import MiniBlockMeteo from './MiniBlockMeteo';
import MiniBlockResources from './MiniBlockResources';
import MiniBlockRisks from './MiniBlockRisks';
import MiniBlockWorkload from './MiniBlockWorkload';
import FiltersViewPopup from './FiltersViewPopup';
import MiniTree from './MiniTree';
import MiniTable from './MiniTable';
import MiniTableHolidays from './MiniTableHolidays';
import Kanban from './Kanban';
import MiniPlanning from './MiniPlanning';
import MiniPivot from './MiniPivot';
import MiniChart from './MiniChart.js';
import MiniMap from './MiniMap.js';
import MiniDoughnut from './MiniDoughnut';
import MiniRiskMatrix from './MiniRiskMatrix';
import MiniMeetingCalendar from './MiniMeetingCalendar';
import LoadingSpinner from './LoadingSpinner';

const API_info = '/WebAppService/GetCardBlockInformation';
const API_available_miniblocks = '/WebAppService/GetAvailableMiniBlocks';
const API_unpinned_views = '/WebAppService/GetAllUnpinnedViews';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: null,
      authId: null,
      language: null,
      itemId: null,
      itemType: null,
      editable: null,
      guestLicence: null,
      displayViews: true,
      currentView: {},
      views: [],
      blocksContent: [],
      blocks: [],
      panels: [],
      availableMiniblocks: [],
      panelBlockInfo: {},
      panelBlockContent: {},
      panelBlockId: null,
      panelBlockLabel: null,
      panelBlockType: null,
      panelViews: [],
      panelViewId: null,
      panelCurrentView: {},
      panelUnpinnedDictViews: {},
      panelUnpinnedViews: [],
      panelApplicationParameters: [],
      panelChart: {},
      panelFields: [],
      panelHeatmaps: [],
      panelKanban: {},
      panelMap: {},
      panelTables: [],
      panelColumns: [],
      panelRows: [],
      addPanelPopup: false,
      editPanelPopup: false,
      isLoading: false,
      error: {},
    };

    // Dashboard Structure
    this.getBlockInformation = this.getBlockInformation.bind(this);
    this.getBlockData = this.getBlockData.bind(this);
    this.getMiniblockData = this.getMiniblockData.bind(this);
    this.getUnpinnedViews = this.getUnpinnedViews.bind(this);
    this.dataStructure = this.dataStructure.bind(this);
    this.dataStructureHeatmap = this.dataStructureHeatmap.bind(this);
    this.convertStringtoBoolean = this.convertStringtoBoolean.bind(this);
    this.getAvailableMiniblocks = this.getAvailableMiniblocks.bind(this);
    this.getColumnsLabel = this.getColumnsLabel.bind(this);
    this.getRowsLabel = this.getRowsLabel.bind(this);
    this.getImpactValues = this.getImpactValues.bind(this);
    this.getProbabilityValues = this.getProbabilityValues.bind(this);
    this.getMiniblockTypeIcon = this.getMiniblockTypeIcon.bind(this);
    this.getPanelBlockIcon = this.getPanelBlockIcon.bind(this);

    // Actions
    this.addPanelPopup = this.addPanelPopup.bind(this);
    this.editDashboard = this.editDashboard.bind(this);
    this.editPanel = this.editPanel.bind(this);
    this.changePanelView = this.changePanelView.bind(this);
    this.applyPanelView = this.applyPanelView.bind(this);
    this.dragStart = this.dragStart.bind(this);
    this.dragStop = this.dragStop.bind(this);
    this.resizeStart = this.resizeStart.bind(this);
    this.resizeStop = this.resizeStop.bind(this);
    this.addListBlock = this.addListBlock.bind(this);
    this.addMiniblock = this.addMiniblock.bind(this);
    this.addPanel = this.addPanel.bind(this);
    this.removePanel = this.removePanel.bind(this);
    this.updateBlocks = this.updateBlocks.bind(this);
    this.updatePanelLabel = this.updatePanelLabel.bind(this);
    this.updatePanels = this.updatePanels.bind(this);
    this.updateDashboardView = this.updateDashboardView.bind(this);
    this.updateView = this.updateView.bind(this);

    // Template Functions
    this.templateHeader = this.templateHeader.bind(this);
    this.templateContent = this.templateContent.bind(this);
    this.templateAddPanel = this.templateAddPanel.bind(this);
    this.templateEditPanel = this.templateEditPanel.bind(this);
    this.closeAddPanelPopup = this.closeAddPanelPopup.bind(this);
    this.closeEditPanelPopup = this.closeEditPanelPopup.bind(this);

    this.table = React.createRef();
    this.tree = React.createRef();
    this.kanban = React.createRef();
    this.planning = React.createRef();
    this.pivot = React.createRef();
    this.chart = React.createRef();
    this.map = React.createRef();
  }

  componentDidMount() {
    const login = Authentication.getCookie('login');
    const authId = Authentication.getCookie('authId');
    const language = Authentication.getCookie('language');
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const editable = this.props.Editable;
    const guestLicence = this.props.GuestLicence;
    const currentView = this.props.CurrentView;
    const views = this.props.Views;
    const blocksContent = this.props.BlocksContent;

    // Build Dahsboard Blocks & Panels
    this.dataStructure(blocksContent, currentView);

    this.setState({ login, authId, language, itemId, itemType, editable, guestLicence, currentView, views, blocksContent });
  }

  componentDidUpdate(prevProps) {
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const editable = this.props.Editable;
    const guestLicence = this.props.GuestLicence;
    const currentView = this.props.CurrentView;
    const views = this.props.Views;
    const blocksContent = this.props.BlocksContent;

    if(this.props.ItemId !== prevProps.ItemId || this.props.ItemType !== prevProps.ItemType) {
      this.setState({ itemId, itemType });
    }
    if(this.props.Editable !== prevProps.Editable) {
      this.setState({ editable });
    }
    if(this.props.GuestLicence !== prevProps.GuestLicence) {
      this.setState({ guestLicence });
    }
    if(this.props.BlocksContent !== prevProps.BlocksContent || JSON.stringify(this.props.CurrentView) !== JSON.stringify(prevProps.CurrentView) || this.props.Views !== prevProps.Views) {
      // Build Dahsboard Blocks & Panels
      this.dataStructure(blocksContent, currentView);

      this.setState({ currentView, views, blocksContent });
    }
  }

  // Get Block Information from the API
  async getBlockInformation(login, authId, itemId, itemType, blockType, viewId) {
    const language = this.state.language;

    this.setState({ isLoading: true });

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': itemType,
        'ItemId': itemId,
        'BlockType': blockType,
        'ViewId': viewId,
        'WithData': false,
        'WithInformation': true,
        'InactiveData': false,
        'ForDashboard': true
      })
    };

    try{
      const response = await fetch(API_info, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const panelBlockInfo = data.GetCardBlockInformationResult;

      if(panelBlockInfo) {
        this.setState({ panelBlockInfo });
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  // Get Block Data from the API
  async getBlockData(login, authId, itemId, itemType, blockType, currentView) {
    const language = this.state.language;

    this.setState({ isLoading: true });

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': itemType,
        'ItemId': itemId,
        'BlockType': blockType,
        'View': currentView,
        'WithData': true,
        'WithInformation': false,
        'InactiveData': false,
        'ForDashboard': true
      })
    };

    try{
      const response = await fetch(API_info, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const panelBlockContent = data.GetCardBlockInformationResult;

      if(panelBlockContent) {
        const panelChart = panelBlockContent.Chart;
        const panelFields = panelBlockContent.Fields;
        const panelHeatmaps = panelBlockContent.HeatMaps[0];
        const panelKanban = panelBlockContent.Kanban;
        const panelMap = panelBlockContent.Map;
        const panelTables = panelBlockContent.Tables;
        let panelColumns = [], panelRows = [];

        // Get Tables, Rows & Columns
        if(panelTables.find(element => element.Level === "Tree")) {
          panelColumns = panelTables.find(element => element.Level === "Tree").ColumnHeaders;
          panelRows = panelTables.find(element => element.Level === "Tree").Rows;
        }

        // Get Tables, Rows & Columns for Budget
        if(panelTables.find(element => element.Level === "Details")) {
          panelColumns = panelTables.find(element => element.Level === "Details").ColumnHeaders;
          panelRows = panelTables.find(element => element.Level === "Details").Rows;
        }

        // Get Tables, Rows & Columns for Burned and Workload
        if(panelTables.find(element => element.Level === "Workload")) {
          panelColumns = panelTables.find(element => element.Level === "Workload").ColumnHeaders;
          panelRows = panelTables.find(element => element.Level === "Workload").Rows;
        }

        this.setState({ panelBlockContent, panelCurrentView: currentView, panelChart, panelFields, panelHeatmaps, panelKanban, panelMap, panelTables, panelColumns, panelRows, isLoading: false });

        // Refresh Component Columns
        if(this.table.current) {
          this.table.current.refreshColumns(currentView, panelColumns);
        }
        else if(this.tree.current) {
          this.tree.current.refreshColumns(currentView, panelColumns);
        }
        else if(this.planning.current) {
          this.planning.current.refreshColumns(currentView, panelColumns);
        }
        // else if(this.chart.current) {
        //   this.chart.current.applySettings(view);
        // }
        // else if(this.map.current) {
        //   this.map.current.applySettings(view);
        // }
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  async getMiniblockData(login, authId, itemId, itemType, miniblock) {
    const language = this.state.language;

    this.setState({ isLoading: true });

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': itemType,
        'ItemId': itemId,
        'BlockType': miniblock.BlockName,
        'WithData': true,
        'WithInformation': false,
        'InactiveData': false,
        'ForDashboard': true
      })
    };

    try{
      const response = await fetch(API_info, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const result = data.GetCardBlockInformationResult;

      if(result) {
        // miniblock.MiniBlock = result;
        miniblock.MiniBlock.ApplicationParameters = result.ApplicationParameters;
        miniblock.MiniBlock.Communications = result.Communications;
        miniblock.MiniBlock.Fields = result.Fields;
        miniblock.MiniBlock.Headers = result.Headers;
        miniblock.MiniBlock.HeatMaps = result.HeatMaps;
        miniblock.MiniBlock.Tables = result.Tables;
        miniblock.MiniBlock.Views = result.Views;
        miniblock.MiniBlock.DefaultViewId = result.DefaultViewId;

        this.setState({ isLoading: false });
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  async getUnpinnedViews(login, authId, itemType, blockType, viewId) {
    const language = this.state.language;

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': itemType,
        'BlockName': blockType,
        'CurrentViewId': viewId
      })
    };

    try{
      const response = await fetch(API_unpinned_views, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const result = data.GetAllUnpinnedViewsResult;

      if(result) {
        const panelUnpinnedDictViews = result.dictViews;
        const panelUnpinnedViews = [];

        panelUnpinnedDictViews.forEach((viewType, index) => {
          viewType.Value.ListGenericViews.forEach(view => {
            panelUnpinnedViews.push(view);
          });
    
          viewType.Value.ListUserViews.forEach(view => {
            panelUnpinnedViews.push(view);
          });
        });

        this.setState({ panelUnpinnedDictViews, panelUnpinnedViews });
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    } catch(error) {
      this.setState({ error });
    }
  }

  dataStructure(blocksContent, currentView) {
    let blocks = [];
    let panels = [];

    // Get dashboard parameters for each block
    if(currentView.ListBlocks) {
      currentView.ListBlocks.map(listBlock => {
        if(blocksContent.find(blockContent => blockContent.Key === listBlock.ViewDashboardBlockId)) {
          let blockContent = blocksContent.find(blockContent => blockContent.Key === listBlock.ViewDashboardBlockId).Value;
  
          // Merge BlockContent & ListBlock
          let merged = {...blockContent, ...listBlock};
  
          blocks.push(merged);
        }
      });
    }

    // blocksContent.forEach(blockContent => {
    //   if(currentView.ListBlocks.find(element => element.Block === blockContent.Block)) {
    //     let listBlock = currentView.ListBlocks.find(element => element.Block === blockContent.Block);
        
    //     // Merge BlockContent & ListBlock
    //     let merged = {...blockContent, ...listBlock};
        
    //     blocks.push(merged);
    //   }
    // });

    for(let i = 0; i < blocks.length; i++) {
      let panel;

      // Define Panel object for Dashboard
      panel = {
        id: blocks[i].ViewDashboardBlockId.toString(),
        // id: 'Panel'.concat(i),
        col: blocks[i].Left,
        row: blocks[i].Top,
        sizeX: blocks[i].Width,
        sizeY: blocks[i].Height,
        header: () => this.templateHeader(blocks[i]),
        content: () => this.templateContent(blocks[i])
      };

      panels.push(panel);
    }

    this.setState({ blocks, panels });
  }

  dataStructureHeatmap(columns, rows, chartData) {
    let heatmap = {};

    heatmap.dataSource = [];
    heatmap.xAis = [];
    heatmap.yAis = [];

    if(chartData) {
      for(let x = 0; x < chartData.length; x++) {
        heatmap.dataSource.push([]);

        for(let y = 0; y < chartData[x].length; y++) {
          // Push ChartData corresponding cell (format value => Progress)
          heatmap.dataSource[x].push(chartData[x][y]);
          // heatmap.dataSource[x].push((chartData[x][y] * 100).toFixed(0));
        }
      }

      heatmap.xAis = this.getColumnsLabel(columns);
      heatmap.yAis = this.getRowsLabel(rows);
    }

    return heatmap;
  }

  convertStringtoBoolean(string) {
    if(string === 'true') {
      return true;
    }
    else {
      return false;
    }
  }

  async getAvailableMiniblocks(login, authId, itemType, itemId) {
    const language = this.state.language;
    
    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': itemType,
        'ItemId': itemId
      })
    };

    try{
      const response = await fetch(API_available_miniblocks, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }
      
      const data = await response.json();
      const result = data.GetAvailableMiniBlocksResult;
      let availableMiniblocks;

      if(result) {
        availableMiniblocks = result;

        this.setState({ availableMiniblocks });
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  getColumnsLabel(columns) {
    let labels = [];

    columns.forEach(column => {
      labels.push(column.Label);
    });
    
    return labels;
  }

  getRowsLabel(rows) {
    let labels = [];

    rows.forEach(row => {
      labels.push(row.Label);
    });

    return labels;
  }

  getImpactValues(columns) {
    if(columns.find(column => column.FieldName === 'Impact')) {
      return columns.find(column => column.FieldName === 'Impact').RestrictedValues;
    }
    else {
      return [];
    }
  }

  getProbabilityValues(columns) {
    if(columns.find(column => column.FieldName === 'Probability')) {
      return columns.find(column => column.FieldName === 'Probability').RestrictedValues;
    }
    else {
      return [];
    }
  }

  getMiniblockTypeIcon(itemType) {
    switch(itemType) {
      case "Details":
          return <span className="iconDetails veryBigIcons" alt="Details"></span>;
      case "Roadmap":
          return <span className="iconRoadMap veryBigIcons" alt="Roadmap"></span>;
      case "Qualitative":
          return <span className="iconQualitativeData veryBigIcons" alt="Qualitative Data"></span>;
      case "Risks":
          return <span className="iconRisks veryBigIcons" alt="Risks"></span>;
      case "Issues":
          return <span className="iconIssues veryBigIcons" alt="Issues"></span>;
      case "Decisions":
          return <span className="iconDecisions veryBigIcons" alt="Decisions"></span>;
      case "Meetings":
          return <span className="iconMeetings veryBigIcons" alt="Meetings"></span>;
      case "BudgetDetails":
          return <span className="iconBudget veryBigIcons" alt="Budget"></span>;
      case "Resources":
          return <span className="iconResourceBlueLight veryBigIcons" alt="Resources"></span>;
      case "Holidays":
          return <span className="iconHolidaysBlue veryBigIcons" alt="Holidays"></span>;
      case "Burned":
          return <span className="iconTimeTrackingBlueLight veryBigIcons" alt="Burned"></span>;
      case "Workload":
          return <span className="iconWorkload veryBigIcons" alt="Workload"></span>;
      case "Communication":
          return <span className="iconCommunication veryBigIcons" alt="Communication"></span>;
      case "Earnings":
          return <span className="iconEarnings veryBigIcons" alt="Earnings"></span>;
      default:
          break;
    }
  }

  getPanelBlockIcon(itemType) {
    switch(itemType) {
      case "Details":
          return <span className="iconDetails iconsPopup"></span>;
      case "Roadmap":
          return <span className="iconRoadMap iconsPopup mr5"></span>;
      case "Qualitative Data":
          return <span className="iconQualitativeData iconsPopup"></span>;
      case "Risks":
          return <span className="iconRisks iconsPopup mr5"></span>;
      case "Issues":
          return <span className="iconIssues iconsPopup mr5"></span>;
      case "Decisions":
          return <span className="iconDecisions iconsPopup mr5"></span>;
      case "Meetings":
          return <span className="iconMeetings iconsPopup mr5"></span>;
      case "BudgetDetails":
          return <span className="iconBudget iconsPopup mr5"></span>;
      case "Resources":
          return <span className="iconResourceBlueLight iconsPopup mr5"></span>;
      case "Holidays":
          return <span className="iconHolidaysBlue iconsPopup mr5"></span>;
      case "Burned":
          return <span className="iconTimeTrackingBlueLight iconsPopup mr5"></span>;
      case "Workload":
          return <span className="iconWorkload iconsPopup mr5"></span>;
      case "Communication":
          return <span className="iconCommunication iconsPopup mr5"></span>;
      case "Earnings":
          return <span className="iconEarnings iconsPopup mr5"></span>;
      default:
          break;
    }
  }

  async addPanelPopup() {
    const login = this.state.login;
    const authId = this.state.authId;
    const itemType = this.state.itemType;
    const itemId = this.state.itemId;

    // Get Available Miniblocks
    await this.getAvailableMiniblocks(login, authId, itemType, itemId);

    this.setState({ addPanelPopup: true });
  }

  editDashboard(editable) {
    if(editable) {
      this.dashboard.allowDragging = true;
      this.dashboard.allowResizing = true;
    }
    else {
      this.dashboard.allowDragging = false;
      this.dashboard.allowResizing = false;
    }
  }

  async editPanel(id) {
    const { login, authId, itemId, itemType, blocks } = this.state;
    let panelBlockId, panelBlockLabel, panelBlockType, panelViewId, panelApplicationParameters;
    let block = {};

    if(blocks.find(block => block.ViewDashboardBlockId === id)) {
      block = blocks.find(block => block.ViewDashboardBlockId === id);

      panelBlockId = id;
      panelBlockLabel = block.BlockLabel;
      panelBlockType = block.BlockName;
      panelViewId = block.ViewId;
      panelApplicationParameters = block.ApplicationParameters;
      // panelHeatmaps = block.HeatMaps[0];
    }

    // Get Block Information
    await this.getBlockInformation(login, authId, itemId, itemType, panelBlockType, panelViewId);

    const panelBlockInfo = this.state.panelBlockInfo;
    const panelViews = panelBlockInfo.Views;
    let panelCurrentView = {};

    // Get Current View
    if(panelViewId && panelViews.find(view => view.ViewId === panelViewId)) {
      panelCurrentView = panelViews.find(view => view.ViewId === panelViewId);
    }
    else if(panelViews.find(view => view.ViewId === panelBlockInfo.DefaultViewId)) {
      panelCurrentView = panelViews.find(view => view.ViewId === panelBlockInfo.DefaultViewId);
    }

    // Get Block Data
    await this.getBlockData(login, authId, itemId, itemType, panelBlockType, panelCurrentView);

    // Get Block Unpinned Views
    await this.getUnpinnedViews(login, authId, itemType, panelBlockType, panelViewId);

    const panelUnpinnedViews = this.state.panelUnpinnedViews;

    // Check if Current View is included in Pinned & Unpinned Views list
    if(panelViews && panelViews.find(view => view.ViewId === panelViewId) && panelUnpinnedViews.find(view => view.ViewId === panelViewId)) {
      let index = panelViews.findIndex(view => view.ViewId === panelViewId);

      panelViews.splice(index, 1);
    }

    this.setState({ panelBlockId: id, panelBlockLabel, panelBlockType, panelViews, panelViewId, panelApplicationParameters, editPanelPopup: true });
  }

  async changePanelView(view) {
    const { login, authId, itemId, itemType, panelBlockType } = this.state;

    // Get Block Data
    await this.getBlockData(login, authId, itemId, itemType, panelBlockType, view);
    
    this.setState({ panelViewId: view.ViewId });
  }

  applyPanelView(id, label, panelCurrentView, chart, fields, heatmaps, kanban, map, tables) {
    const blocks = this.state.blocks;
    const panels = this.state.panels;
    const panelBlockId = this.state.panelBlockId;
    let currentView = this.state.currentView;
    let block, panel;

    // Update Blocks
    if(blocks.find(block => block.ViewDashboardBlockId === id)) {
      block = blocks.find(block => block.ViewDashboardBlockId === id);

      block.BlockLabel = label;
      block.Views[0] = panelCurrentView;
      // block.Views[0] = { "Key": panelCurrentView.ViewType, "Value": [panelCurrentView] };
      block.DefaultViewId = panelCurrentView.ViewId;
      block.ViewId = panelCurrentView.ViewId;
      block.Chart = chart;
      block.Fields = fields;
      block.HeatMaps[0] = heatmaps;
      block.Kanban = kanban;
      block.Map = map;
      block.Tables = tables;
    }

    // Update Dashboard Panel
    if(panels.find(panel => panel.id == panelBlockId)) {
      panel = panels.find(panel => panel.id == panelBlockId);

      // Refresh Dashboard
      this.dashboard.removePanel(panel.id);

      // Define Panel object for Dashboard
      panel = {
        id: block.ViewDashboardBlockId.toString(),
        col: panel.col,
        row: panel.row,
        sizeX: panel.sizeX,
        sizeY: panel.sizeY,
        header: () => this.templateHeader(block),
        content: () => this.templateContent(block)
      };

      this.dashboard.addPanel(panel);

      // this.dashboard.updatePanel(panel);
    }

    // Update Dashboard View ListBlock
    this.updateDashboardView(id, label, panelCurrentView);

    // Add Modify attribute in CurrentView
    currentView["Modify"] = true;

    // Call the event from the Parent component through the props
    this.props.onViewChange(currentView);

    this.setState({ panelBlockInfo: {}, panelBlockId: null, panelBlockLabel: null, panelBlockType: null, panelViews: [], panelViewId: null, panelCurrentView: {}, panelChart: {}, panelKanban: {}, panelMap: {}, panelTables: [], panelColumns: [], panelRows: [], editPanelPopup: false });
  }

  dragStart(args) {
    // console.log(args);
  }

  dragStop(args) {
    let panels = this.state.panels;

    // Update Panels with Dashboard
    this.updatePanels(panels);

    // Update CurrentView with Panels
    this.updateView(panels);

    // this.setState({ panels });
  }

  resizeStart(args) {
    // console.log(args);
  }

  resizeStop(args) {
    let panels = this.state.panels;

    // Update Panels with Dashboard
    this.updatePanels(panels);

    // Update CurrentView with Panels
    this.updateView(panels);

    // Refresh Chart Component
    if(args.element && args.element.querySelector('chartComponent') && args.element.querySelector('chartComponent').ej2_instances) {
      let chartObj = (args.element.querySelector('chartComponent')).ej2_instances[0];
      chartObj.height = '100%';
      chartObj.width = '100%';
      chartObj.refresh();
    }

    // this.setState({ panels });
  }

  addListBlock(miniblock) {
    let currentView = this.state.currentView;
    let panels = this.state.panels;
    let listBlock, viewDashboardBlockId = -1;

    // Add Modify attribute in CurrentView
    currentView["Modify"] = true;

    // Call the event from the Parent component through the props
    this.props.onViewChange(currentView);

    currentView.ListBlocks.forEach(listBlock => {
      // If we create multiple MiniBlockIssues, decrement viewDashboardBlockId
      if(listBlock.ViewDashboardBlockId <= viewDashboardBlockId) {
        viewDashboardBlockId = viewDashboardBlockId -1;
      }

      // Get Blocks on the left
      if(listBlock.Left === 0) {
        // Move down other Blocks
        listBlock.Top = listBlock.Top + 4;

        // Update Panels Row
        if(panels.find(panel => panel.id == listBlock.ViewDashboardBlockId)) {
          panels.find(panel => panel.id == listBlock.ViewDashboardBlockId).row = panels.find(panel => panel.id == listBlock.ViewDashboardBlockId).row + 4;
        }
      }      
    });

    // Create new ListBlock object
    listBlock = { "Block": miniblock.Type, "FieldId": 0, "Height": 4, "Label": miniblock.BlockLabel, "Left": 0, "Top": 0, "ViewDashboardBlockId": viewDashboardBlockId, "ViewDashboardId": currentView.ViewDashboardId, "ViewId": miniblock.DefaultViewId, "Width": 4 };

    // Push new Block inside CurrentView ListBlocks
    currentView.ListBlocks.push(listBlock);

    // Add Miniblock
    this.addMiniblock(miniblock, listBlock);

    // Close Popup
    this.setState({ panels, addPanelPopup: false });
  }

  async addMiniblock(miniblock, listBlock) {
    const login = this.state.login;
    const authId = this.state.authId;
    const itemId = this.state.itemId;
    const itemType = this.state.itemType;
    const blocks = this.state.blocks;

    // Get MiniBlock Data
    await this.getMiniblockData(login, authId, itemId, itemType, miniblock);

    // Update ListBlock ViewId
    listBlock.ViewId = miniblock.MiniBlock.DefaultViewId;

    // Merge BlockContent & ListBlock
    let merged = {...miniblock.MiniBlock, ...listBlock};

    // Increment other Blocks Order
    // blocks.forEach(block => {
    //   block.BlockOrder = block.BlockOrder + 1;
    // });

    // Push new Miniblock inside list
    blocks.push(merged);

    // Add Panel inside Dashboard
    this.addPanel(merged);
  }

  addPanel(miniblock) {
    const panels = this.state.panels;
    let panel;

    // Define Panel object for Dashboard
    panel = {
      id: miniblock.ViewDashboardBlockId.toString(),
      col: miniblock.Left,
      row: miniblock.Top,
      sizeX: miniblock.Width,
      sizeY: miniblock.Height,
      header: () => this.templateHeader(miniblock),
      content: () => this.templateContent(miniblock)
    };

    // Add Panel to Dashboard Component
    this.dashboard.addPanel(panel);

    // Push Panel into Panels
    panels.push(panel);
  }

  removePanel(id) {
    const panels = this.state.panels;

    if(this.dashboard) {
      // Remove Panel from Dashboard
      this.dashboard.removePanel(id.toString());

      // Update Blocks
      this.updateBlocks(id);

      // Update Panels with Dashboard
      this.updatePanels(panels);

      // Update CurrentView with Panels
      this.updateView(panels);
    }
  }

  updateBlocks(id) {
    const blocks = this.state.blocks;

    // Remove corresponding Block
    if(blocks.find(block => block.ViewDashboardBlockId === id)) {
      blocks.splice(blocks.findIndex(block => block.ViewDashboardBlockId === id), 1);

      // Update Blocks Position
      blocks.forEach(block => {
        if(this.dashboard.panels.find(element => element.id == block.ViewDashboardBlockId)) {
          let dashboardPanel = this.dashboard.panels.find(element => element.id == block.ViewDashboardBlockId);

          block.Left = dashboardPanel.col;
          block.Top = dashboardPanel.row;

          block.Width = dashboardPanel.sizeX;
          block.Height = dashboardPanel.sizeY;
        }
      });
    }

    this.setState({ blocks });
  }

  updatePanelLabel(event) {
    let panelBlockLabel = this.state.panelBlockLabel;

    panelBlockLabel = event.target.value;
    
    this.setState({ panelBlockLabel });
  }

  updatePanels(panels) {
    panels.forEach((panel, index) => {
      if(this.dashboard.panels.find(element => element.id === panel.id)) {
        let dashboardPanel = this.dashboard.panels.find(element => element.id === panel.id);

        // Update Panel with Dashboard Settings
        panel.col = dashboardPanel.col;
        panel.row = dashboardPanel.row;

        panel.sizeX = dashboardPanel.sizeX;
        panel.sizeY = dashboardPanel.sizeY;
      }
      else {
        panels.splice(index, 1);
      }
    });

    // this.dashboard.panels.forEach(element => {
    //   if(panels.find(panel => panel.id === element.id)) {
    //     // Update Panel with Dashboard Settings
    //     panels.find(panel => panel.id === element.id).col = element.col;
    //     panels.find(panel => panel.id === element.id).row = element.row;

    //     panels.find(panel => panel.id === element.id).sizeX = element.sizeX;
    //     panels.find(panel => panel.id === element.id).sizeY = element.sizeY;
    //   }
    //   else {
    //     // Get Panel's index to remove
    //     let index = panels.findIndex(panel => panel.id === element.id);

    //     panels.splice(index, 1);
    //   }
    // });

    this.setState({ panels });
  }

  updateDashboardView(id, label, view) {
    const currentView = this.state.currentView;
    let listBlock;

    if(currentView.ListBlocks.find(listBlock => listBlock.ViewDashboardBlockId === id)) {
      listBlock = currentView.ListBlocks.find(listBlock => listBlock.ViewDashboardBlockId === id);

      // Update CurrentView ListBlocks based on Dashboard Panels
      listBlock.Label = label;
      listBlock.ViewId = view.ViewId;
    }
  }

  updateView(panels) {
    let currentView = this.state.currentView;
    
    // Add Modify attribute in CurrentView
    currentView["Modify"] = true;

    // Call the event from the Parent component through the props
    this.props.onViewChange(currentView);

    currentView.ListBlocks.forEach((listBlock, index) => {
      if(panels.find(panel => panel.id == listBlock.ViewDashboardBlockId)) {
        let panel = panels.find(panel => panel.id == listBlock.ViewDashboardBlockId);

        // Update CurrentView ListBlocks based on Dashboard Panels
        listBlock.Left = panel.col;
        listBlock.Top = panel.row;

        listBlock.Width = panel.sizeX;
        listBlock.Height = panel.sizeY;
      }
      else {
        currentView.ListBlocks.splice(index, 1);
      }
    });

    // panels.forEach(panel => {
    //   if(currentView.ListBlocks.find(listBlock => listBlock.ViewDashboardBlockId == panel.id)) {
    //     // Update CurrentView ListBlocks based on Dashboard Panels
    //     currentView.ListBlocks.find(listBlock => listBlock.ViewDashboardBlockId == panel.id).Left = panel.col;
    //     currentView.ListBlocks.find(listBlock => listBlock.ViewDashboardBlockId == panel.id).Top = panel.row;
        
    //     currentView.ListBlocks.find(listBlock => listBlock.ViewDashboardBlockId == panel.id).Width = panel.sizeX;
    //     currentView.ListBlocks.find(listBlock => listBlock.ViewDashboardBlockId == panel.id).Height = panel.sizeY;
    //   }
    //   else {
    //     // Get ListBlock's index to remove
    //     let index = currentView.ListBlocks.findIndex(listBlock => listBlock.ViewDashboardBlockId == panel.id);

    //     currentView.ListBlocks.splice(index, 1);
    //   }
    // });

    this.setState({ currentView });
  }

  templateHeader(block) {
    const { language, itemId, itemType, editable } = this.state;
    const id = block.ViewDashboardBlockId;
    const viewId = block.ViewId;

    // Get current Year for Burned
    const date = new Date();
    const month = (date.getMonth())+1;
    const year = date.getFullYear();

    // Miniblock Header Indicators
    const fields = block.Fields;
    let totalRows, displayedRows, filters, labelTotal, labelDisplayed;

    if(fields.find(field => field.FieldName === 'TotalRows')) {
      totalRows = fields.find(field => field.FieldName === 'TotalRows').FieldValue;
    }
    if(fields.find(field => field.FieldName === 'DisplayedRows')) {
      displayedRows = fields.find(field => field.FieldName === 'DisplayedRows').FieldValue;
    }
    if(fields.find(field => field.FieldName === 'Filters')) {
      filters = fields.find(field => field.FieldName === 'Filters').FieldValue;
    }

    // Labels
    if(totalRows === "1") {
      labelDisplayed = Traduction.translate(language, 'displayed');
      labelTotal = Traduction.translate(language, 'item');
    }
    else {
      labelDisplayed = Traduction.translate(language, 'displayed');
      labelTotal = Traduction.translate(language, 'items');
    }

    switch(block.BlockName) {
      case "Details":
          return <div className="flex mh15">
            <Link className="linkMiniblock" to={`/Card/${itemType}/${itemId}/${block.BlockName}?viewId=${viewId}`}>
              <div className="miniblockHeader"><span className="iconDetails iconsDashboard mr5"/><span className="miniblockTitle">{block.BlockLabel}</span></div>
            </Link>
            <div className="flex-end">
              {/* Edition & Delete */}
              <span className={"iconEdit iconsMiniblock cursor mr15" + (editable ? "" : " hidden")} onClick={(e) => this.editPanel(id)}></span>
              <span className={"iconClear iconsMiniblock cursor" + (editable ? "" : " hidden")} onClick={(e) => this.removePanel(id)}/>
            </div>
          </div>;
      case "Roadmap":
          return <div className="flex mh15">
            <Link className="linkMiniblock" to={`/Card/${itemType}/${itemId}/${block.BlockName}?viewId=${viewId}`}>
              <div className="miniblockHeader"><span className="iconRoadMap iconsDashboard mr10"></span><span className="miniblockTitle">{block.BlockLabel}</span></div>
            </Link>
            <div className="flex-end">
              {/* Indicators */}
              {totalRows && <div className={"miniblockTotalRows" + (editable ? " hidden" : "")}>{totalRows} {labelTotal}</div>}
              {/* <div className="miniblockDisplayedRows">{displayedRows} {labelDisplayed}</div> */}
              {filters > 0 && <div className={"miniblockFilters" + (editable ? " hidden" : "")}><span className="iconFilterKanban iconsFilterMiniblock"></span><span className="miniblockFiltersNumber">{filters}</span></div>}
              {/* Edition & Delete */}
              <span className={"iconEdit iconsMiniblock cursor mr15" + (editable ? "" : " hidden")} onClick={(e) => this.editPanel(id)}></span>
              <span className={"iconClear iconsMiniblock cursor" + (editable ? "" : " hidden")} onClick={(e) => this.removePanel(id)}/>
            </div>
          </div>;
      case "Qualitative":
      case "Qualitative Data":
          return <div className="flex mh15">
            <Link className="linkMiniblock" to={`/Card/${itemType}/${itemId}/Qualitative`}>
              <div className="miniblockHeader"><span className="iconQualitativeData iconsDashboard mr5"></span><span className="miniblockTitle">{block.BlockLabel}</span></div>
            </Link>
            <div className="flex-end">
              <span className={"iconClear iconsMiniblock cursor" + (editable ? "" : " hidden")} onClick={(e) => this.removePanel(id)}/>
            </div>
          </div>;
      case "Risks":
          return <div className="flex mh15">
            <Link className="linkMiniblock" to={`/Card/${itemType}/${itemId}/${block.BlockName}?viewId=${viewId}`}>
              <div className="miniblockHeader"><span className="iconRisks iconsDashboard mr10"></span><span className="miniblockTitle">{block.BlockLabel}</span></div>
            </Link>
            <div className="flex-end">
              {/* Indicators */}
              {totalRows && <div className={"miniblockTotalRows" + (editable ? " hidden" : "")}>{totalRows} {labelTotal}</div>}
              {/* <div className="miniblockDisplayedRows">{displayedRows} {labelDisplayed}</div> */}
              {filters > 0 && <div className={"miniblockFilters" + (editable ? " hidden" : "")}><span className="iconFilterKanban iconsFilterMiniblock"></span><span className="miniblockFiltersNumber">{filters}</span></div>}
              {/* Edition & Delete */}
              <span className={"iconEdit iconsMiniblock cursor mr15" + (editable ? "" : " hidden")} onClick={(e) => this.editPanel(id)}></span>
              <span className={"iconClear iconsMiniblock cursor" + (editable ? "" : " hidden")} onClick={(e) => this.removePanel(id)}/>
            </div>
          </div>;
      case "Issues":
          return <div className="flex mh15">
            <Link className="linkMiniblock" to={`/Card/${itemType}/${itemId}/${block.BlockName}?viewId=${viewId}`}>
              <div className="miniblockHeader"><span className="iconIssues iconsDashboard mr10"></span><span className="miniblockTitle">{block.BlockLabel}</span></div>
            </Link>
            <div className="flex-end">
              {/* Indicators */}
              {totalRows && <div className={"miniblockTotalRows" + (editable ? " hidden" : "")}>{totalRows} {labelTotal}</div>}
              {/* <div className="miniblockDisplayedRows">{displayedRows} {labelDisplayed}</div> */}
              {filters > 0 && <div className={"miniblockFilters" + (editable ? " hidden" : "")}><span className="iconFilterKanban iconsFilterMiniblock"></span><span className="miniblockFiltersNumber">{filters}</span></div>}
              {/* Edition & Delete */}
              <span className={"iconEdit iconsMiniblock cursor mr15" + (editable ? "" : " hidden")} onClick={(e) => this.editPanel(id)}></span>
              <span className={"iconClear iconsMiniblock cursor" + (editable ? "" : " hidden")} onClick={(e) => this.removePanel(id)}/>
            </div>
          </div>;
      case "Decisions":
          return <div className="flex mh15">
            <Link className="linkMiniblock" to={`/Card/${itemType}/${itemId}/${block.BlockName}?viewId=${viewId}`}>
              <div className="miniblockHeader"><span className="iconDecisions iconsDashboard mr10"></span><span className="miniblockTitle">{block.BlockLabel}</span></div>
            </Link>
            <div className="flex-end">
              {/* Indicators */}
              {totalRows && <div className={"miniblockTotalRows" + (editable ? " hidden" : "")}>{totalRows} {labelTotal}</div>}
              {/* <div className="miniblockDisplayedRows">{displayedRows} {labelDisplayed}</div> */}
              {filters > 0 && <div className={"miniblockFilters" + (editable ? " hidden" : "")}><span className="iconFilterKanban iconsFilterMiniblock"></span><span className="miniblockFiltersNumber">{filters}</span></div>}
              {/* Edition & Delete */}
              <span className={"iconEdit iconsMiniblock cursor mr15" + (editable ? "" : " hidden")} onClick={(e) => this.editPanel(id)}></span>
              <span className={"iconClear iconsMiniblock cursor" + (editable ? "" : " hidden")} onClick={(e) => this.removePanel(id)}/>
            </div>
          </div>;
      case "Meetings":
          return <div className="flex mh15">
            <Link className="linkMiniblock" to={`/Card/${itemType}/${itemId}/${block.BlockName}?viewId=${viewId}`}>
              <div className="miniblockHeader"><span className="iconMeetings iconsDashboard mr10"></span><span className="miniblockTitle">{block.BlockLabel}</span></div>
            </Link>
            <div className="flex-end">
              {/* Indicators */}
              {totalRows && <div className={"miniblockTotalRows" + (editable ? " hidden" : "")}>{totalRows} {labelTotal}</div>}
              {/* <div className="miniblockDisplayedRows">{displayedRows} {labelDisplayed}</div> */}
              {filters > 0 && <div className={"miniblockFilters" + (editable ? " hidden" : "")}><span className="iconFilterKanban iconsFilterMiniblock"></span><span className="miniblockFiltersNumber">{filters}</span></div>}
              {/* Edition & Delete */}
              <span className={"iconEdit iconsMiniblock cursor mr15" + (editable ? "" : " hidden")} onClick={(e) => this.editPanel(id)}></span>
              <span className={"iconClear iconsMiniblock cursor" + (editable ? "" : " hidden")} onClick={(e) => this.removePanel(id)}/>
            </div>
          </div>;
      case "BudgetDetails":
          return <div className="flex mh15">
            <Link className="linkMiniblock" to={`/Card/${itemType}/${itemId}/${block.BlockName}?viewId=${viewId}`}>
              <div className="miniblockHeader"><span className="iconBudget iconsDashboard mr10"></span><span className="miniblockTitle">{block.BlockLabel}</span></div>
            </Link>
            <div className="flex-end">
              {/* Indicators */}
              {totalRows && <div className={"miniblockTotalRows" + (editable ? " hidden" : "")}>{totalRows} {labelTotal}</div>}
              {/* <div className="miniblockDisplayedRows">{displayedRows} {labelDisplayed}</div> */}
              {filters > 0 && <div className={"miniblockFilters" + (editable ? " hidden" : "")}><span className="iconFilterKanban iconsFilterMiniblock"></span><span className="miniblockFiltersNumber">{filters}</span></div>}
              {/* Edition & Delete */}
              <span className={"iconEdit iconsMiniblock cursor mr15" + (editable ? "" : " hidden")} onClick={(e) => this.editPanel(id)}></span>
              <span className={"iconClear iconsMiniblock cursor" + (editable ? "" : " hidden")} onClick={(e) => this.removePanel(id)}/>
            </div>
          </div>;
      case "Resources":
          return <div className="flex mh15">
            <Link className="linkMiniblock" to={`/Card/${itemType}/${itemId}/${block.BlockName}?viewId=${viewId}`}>
              <div className="miniblockHeader"><span className="iconResourceBlueLight iconsDashboard mr10"></span><span className="miniblockTitle">{block.BlockLabel}</span></div>
            </Link>
            <div className="flex-end">
              {/* Indicators */}
              {totalRows && <div className={"miniblockTotalRows" + (editable ? " hidden" : "")}>{totalRows} {labelTotal}</div>}
              {/* <div className="miniblockDisplayedRows">{displayedRows} {labelDisplayed}</div> */}
              {filters > 0 && <div className={"miniblockFilters" + (editable ? " hidden" : "")}><span className="iconFilterKanban iconsFilterMiniblock"></span><span className="miniblockFiltersNumber">{filters}</span></div>}
              {/* Edition & Delete */}
              <span className={"iconEdit iconsMiniblock cursor mr15" + (editable ? "" : " hidden")} onClick={(e) => this.editPanel(id)}></span>
              <span className={"iconClear iconsMiniblock cursor" + (editable ? "" : " hidden")} onClick={(e) => this.removePanel(id)}/>
            </div>
          </div>;
      case "Holidays":
          return <div className="flex mh15">
            <Link className="linkMiniblock" to={`/Card/${itemType}/${itemId}/${block.BlockName}?year=${year}&month=${month}`}>
              <div className="miniblockHeader"><span className="iconHolidaysBlue iconsDashboard mr10"></span><span className="miniblockTitle">{block.BlockLabel}</span></div>
            </Link>
            <div className="flex-end">
              <span className={"iconClear iconsMiniblock cursor" + (editable ? "" : " hidden")} onClick={(e) => this.removePanel(id)}/>
            </div>
          </div>;
      case "Burned":
          return <div className="flex mh15">
            <Link className="linkMiniblock" to={`/Card/${itemType}/${itemId}/${block.BlockName}?viewId=${viewId}&year=${year}`}>
              <div className="miniblockHeader"><span className="iconTimeTrackingBlueLight iconsDashboard mr10"></span><span className="miniblockTitle">{block.BlockLabel}</span></div>
            </Link>
            <div className="flex-end">
              {/* Indicators */}
              {/* <div className={"miniblockTotalRows" + (editable ? " hidden" : "")}>{totalRows} {labelTotal}</div> */}
              {/* <div className="miniblockDisplayedRows">{displayedRows} {labelDisplayed}</div> */}
              {/* {filters > 0 && <div className={"miniblockFilters" + (editable ? " hidden" : "")}><span className="iconFilterKanban iconsFilterMiniblock"></span><span className="miniblockFiltersNumber">{filters}</span></div>} */}
              {/* Edition & Delete */}
              <span className={"iconEdit iconsMiniblock cursor mr15" + (editable ? "" : " hidden")} onClick={(e) => this.editPanel(id)}></span>
              <span className={"iconClear iconsMiniblock cursor" + (editable ? "" : " hidden")} onClick={(e) => this.removePanel(id)}/>
            </div>
          </div>;
      case "Workload":
          return <div className="flex mh15">
            <Link className="linkMiniblock" to={`/Card/${itemType}/${itemId}/${block.BlockName}?viewId=${viewId}`}>
              <div className="miniblockHeader"><span className="iconWorkload iconsDashboard mr10"></span><span className="miniblockTitle">{block.BlockLabel}</span></div>
            </Link>
            <div className="flex-end">
              {/* Indicators */}
              {/* <div className={"miniblockTotalRows" + (editable ? " hidden" : "")}>{totalRows} {labelTotal}</div> */}
              {/* <div className="miniblockDisplayedRows">{displayedRows} {labelDisplayed}</div> */}
              {/* {filters > 0 && <div className={"miniblockFilters" + (editable ? " hidden" : "")}><span className="iconFilterKanban iconsFilterMiniblock"></span><span className="miniblockFiltersNumber">{filters}</span></div>} */}
              {/* Edition & Delete */}
              <span className={"iconEdit iconsMiniblock cursor mr15" + (editable ? "" : " hidden")} onClick={(e) => this.editPanel(id)}></span>
              <span className={"iconClear iconsMiniblock cursor" + (editable ? "" : " hidden")} onClick={(e) => this.removePanel(id)}/>
            </div>
          </div>;
      case "Communication":
          return <div className="flex mh15">
            <Link className="linkMiniblock" to={`/Card/${itemType}/${itemId}/${block.BlockName}`}>
              <div className="miniblockHeader"><span className="iconCommunication iconsDashboard mr10"></span><span className="miniblockTitle">{block.BlockLabel}</span></div>
            </Link>
            <div className="flex-end">
              <span className={"iconClear iconsMiniblock cursor" + (editable ? "" : " hidden")} onClick={(e) => this.removePanel(id)}/>
            </div>
          </div>;
      case "Earnings":
          return <div className="flex mh15">
            <Link className="linkMiniblock" to={`/Card/${itemType}/${itemId}/${block.BlockName}?viewId=${viewId}`}>
              <div className="miniblockHeader"><span className="iconEarnings iconsDashboard mr10"></span><span className="miniblockTitle">{block.BlockLabel}</span></div>
            </Link>
            <div className="flex-end">
              {/* Indicators */}
              {totalRows && <div className={"miniblockTotalRows" + (editable ? " hidden" : "")}>{totalRows} {labelTotal}</div>}
              {/* <div className="miniblockDisplayedRows">{displayedRows} {labelDisplayed}</div> */}
              {filters > 0 && <div className={"miniblockFilters" + (editable ? " hidden" : "")}><span className="iconFilterKanban iconsFilterMiniblock"></span><span className="miniblockFiltersNumber">{filters}</span></div>}
              {/* Edition & Delete */}
              <span className={"iconEdit iconsMiniblock cursor mr15" + (editable ? "" : " hidden")} onClick={(e) => this.editPanel(id)}></span>
              <span className={"iconClear iconsMiniblock cursor" + (editable ? "" : " hidden")} onClick={(e) => this.removePanel(id)}/>
            </div>
          </div>;
      default:
          break;
    }
  }

  templateContent(block) {
    const { itemId, itemType, guestLicence } = this.state;
    const fields = block.Fields;
    let filters;

    if(fields.find(field => field.FieldName === 'Filters')) {
      filters = fields.find(field => field.FieldName === 'Filters').FieldValue;
    }

    switch(block.BlockName) {
      case "Details":
          return <MiniBlockDetails Id={itemId} Type={itemType} GuestLicence={guestLicence} BlockContent={block} BlockType={block.BlockName} BlockLabel={block.Label} Filters={filters}></MiniBlockDetails>;
      case "Roadmap":
          return <MiniBlockRoadMap Id={itemId} Type={itemType} GuestLicence={guestLicence} BlockContent={block} BlockType={block.BlockName} BlockLabel={block.BlockLabel} Filters={filters}></MiniBlockRoadMap>;
      case "Qualitative":
      case "Qualitative Data":
          return <MiniBlockMeteo Id={itemId} Type={itemType} GuestLicence={guestLicence} BlockContent={block} BlockType={block.BlockName} BlockLabel={block.BlockLabel} Filters={filters}></MiniBlockMeteo>;
      case "Risks":
          return <MiniBlockRisks Id={itemId} Type={itemType} GuestLicence={guestLicence} BlockContent={block} BlockType={block.BlockName} BlockLabel={block.BlockLabel} Filters={filters}></MiniBlockRisks>;
      case "Issues":
          return <MiniBlockIssues Id={itemId} Type={itemType} GuestLicence={guestLicence} BlockContent={block} BlockType={block.BlockName} BlockLabel={block.BlockLabel} Filters={filters}></MiniBlockIssues>;
      case "Decisions":
          return <MiniBlockDecisions Id={itemId} Type={itemType} GuestLicence={guestLicence} BlockContent={block} BlockType={block.BlockName} BlockLabel={block.BlockLabel} Filters={filters}></MiniBlockDecisions>;
      case "Meetings":
          return <MiniBlockMeetings Id={itemId} Type={itemType} GuestLicence={guestLicence} BlockContent={block} BlockType={block.BlockName} BlockLabel={block.BlockLabel} Filters={filters}></MiniBlockMeetings>;
      case "BudgetDetails":
          return <MiniBlockBudget Id={itemId} Type={itemType} GuestLicence={guestLicence} BlockContent={block} BlockType={block.BlockName} BlockLabel={block.BlockLabel} Filters={filters}></MiniBlockBudget>;
      case "Resources":
          return <MiniBlockResources Id={itemId} Type={itemType} GuestLicence={guestLicence} BlockContent={block} BlockType={block.BlockName} BlockLabel={block.BlockLabel} Filters={filters}></MiniBlockResources>;
      case "Holidays":
          return <MiniBlockHolidays Id={itemId} Type={itemType} GuestLicence={guestLicence} BlockContent={block} BlockType={block.BlockName} BlockLabel={block.BlockLabel} Filters={filters}></MiniBlockHolidays>;
      case "Burned":
          return <MiniBlockBurned Id={itemId} Type={itemType} GuestLicence={guestLicence} BlockContent={block} BlockType={block.BlockName} BlockLabel={block.BlockLabel} Filters={filters}></MiniBlockBurned>;
      case "Workload":
          return <MiniBlockWorkload Id={itemId} Type={itemType} GuestLicence={guestLicence} BlockContent={block} BlockType={block.BlockName} BlockLabel={block.BlockLabel} Filters={filters}></MiniBlockWorkload>;
      case "Communication":
          return <MiniBlockCommunication Id={itemId} Type={itemType} GuestLicence={guestLicence} BlockContent={block} BlockType={block.BlockName} BlockLabel={block.BlockLabel} Filters={filters}></MiniBlockCommunication>;
      case "Earnings":
          return <MiniBlockEarnings Id={itemId} Type={itemType} GuestLicence={guestLicence} BlockContent={block} BlockType={block.BlockName} BlockLabel={block.BlockLabel} Filters={filters}></MiniBlockEarnings>;
      default:
          break;
    }
  }

  templateAddPanel() {
    const { language, availableMiniblocks } = this.state;

    return (<div className="flex flex-column popupAddPanel">
      {/* Label */}
      <div className="addPanelLabel mb10">{Traduction.translate(language, 'add_new_miniblock')}</div>
      {/* Available Miniblocks */}
      <div className="availableMiniblocks">
        <div className="row flex width100p">
          {availableMiniblocks.map((miniblock, index) => {
            return <div key={index} className="miniblocksType col-md-2" onClick={() => this.addListBlock(miniblock)}>
              <div className="">{this.getMiniblockTypeIcon(miniblock.BlockName)}</div>
              <div className="miniblocksTypeLabel">{miniblock.BlockLabel}</div>
            </div>
          })}
        </div>
      </div>
      {/* Button Validate or Cancel */}
      <div className="flex flex-end align-items-center">
        {/* <Button className="fs12 bold brd-radius mr15" variant="primary" onClick={() => this.closeAddPanelPopup()}>{Traduction.translate(language, 'validate')}</Button> */}
        <Button className="fs12 bold brd-radius" variant="warning" onClick={() => this.closeAddPanelPopup()}>{Traduction.translate(language, 'cancel')}</Button>
      </div>
    </div>);
  }

  templateEditPanel() {
    let { language, itemId, itemType, guestLicence, displayViews, blocks, panels, panelBlockInfo, panelBlockContent, panelBlockId, panelBlockLabel, panelBlockType, panelViewId, panelCurrentView, panelViews, panelUnpinnedViews, panelApplicationParameters, panelChart, panelMap, panelFields, panelHeatmaps, panelKanban, panelTables, panelColumns, panelRows, isLoading } = this.state;
    let heatmap, visibleComponent, occupationLevel, occupationMetric;

    // Initialise Heatmap Data
    if(panelBlockType === 'Burned' || panelBlockType === 'Workload') {
      heatmap = this.dataStructureHeatmap(panelHeatmaps.Columns, panelHeatmaps.Rows, panelHeatmaps.ChartData);
    }

    // Initialise Occupation Level & Metric
    if(panelCurrentView.Parameters && panelCurrentView.Parameters.find(param => param.Name === 'OccupationLevel')) {
      occupationLevel = panelCurrentView.Parameters.find(param => param.Name === 'OccupationLevel').Value;
    }
    else {
      occupationLevel = 'Month';
    }
    if(panelCurrentView.Parameters && panelCurrentView.Parameters.find(param => param.Name === 'OccupationMetric')) {
      occupationMetric = panelCurrentView.Parameters.find(param => param.Name === 'OccupationMetric').Value;
    }
    else {
      occupationMetric = 'Occupancy';
    }

    // Initialise Visible Component
    if(panelBlockType === 'BudgetDetails' || panelBlockType === 'Meetings' || panelBlockType === 'Risks') {
      if(panelCurrentView.Parameters && panelCurrentView.Parameters.find(param => param.Name === 'VisibleComponent')) {
        visibleComponent = this.convertStringtoBoolean(panelCurrentView.Parameters.find(param => param.Name === 'VisibleComponent').Value);
      }
      else {
        visibleComponent = true;
      }
    }
    else if(panelBlockType === 'Burned' || panelBlockType === 'Workload') {
      if(panelCurrentView.Parameters && panelCurrentView.Parameters.find(param => param.Name === 'VisibleComponent')) {
        visibleComponent = panelCurrentView.Parameters.find(param => param.Name === 'VisibleComponent').Value;
      }
      else {
        visibleComponent = "Heatmap";
      }
    }
    else {
      visibleComponent = false;
    }

    // Cell Settings
    if(panelBlockType === 'Workload') {
      if(occupationMetric === 'Occupancy') {
        this.cellSettings = { textStyle: { color: 'black' }, border: { width: 4, radius: 5, color: 'white' }, format: '{value} %' };
      }
      else if(occupationMetric === 'Availability') {
        this.cellSettings = { textStyle: { color: 'black' }, border: { width: 4, radius: 5, color: 'white' }, format: '{value}' };
      }
      else if(occupationMetric === 'Workload') {
        this.cellSettings = { textStyle: { color: 'black' }, border: { width: 4, radius: 5, color: 'white' }, format: '{value}' };
      }
    }
    else {
      this.cellSettings = { textStyle: { color: 'black' }, border: { width: 4, radius: 5, color: 'white' }, format: '{value} %' };
    }

    // Palette Settings
    if(panelBlockType === 'Workload') {
      if(occupationMetric === 'Occupancy') {
        this.paletteSettings = { palette: [{ value: 0, color: '#EAFCFF' }, { value: 99, color: '#95B0B5' }, { value: 100, color: '#A1E981' }, { value: 101, color: '#FFD589' }, { value: 500, color: '#FF9052' }] };
      }
      else if(occupationMetric === 'Availability') {
        if(occupationLevel === 'Month') {
          this.paletteSettings = { palette: [{ startValue: -2000000, endValue: -20, minColor: '#E21313', maxColor: '#FF9052' }, { startValue: -20, endValue: 0, minColor: '#FF9052', maxColor: '#EAFCFF' }, { startValue: 0, endValue: 20, minColor: '#EAFCFF', maxColor: '#A1E981' }, { startValue: 20, endValue: 2000000, minColor: '#A1E981', maxColor: '#A1E981' }] };
        }
        else if(occupationLevel === 'Week') {
          this.paletteSettings = { palette: [{ startValue: -2000000, endValue: -5, minColor: '#E21313', maxColor: '#FF9052' }, { startValue: -5, endValue: 0, minColor: '#FF9052', maxColor: '#EAFCFF' }, { startValue: 0, endValue: 5, minColor: '#EAFCFF', maxColor: '#A1E981' }, { startValue: 5, endValue: 2000000, minColor: '#A1E981', maxColor: '#A1E981' }] };
        }
        else if(occupationLevel === 'Day') {
          this.paletteSettings = { palette: [{ startValue: -2000000, endValue: -1, minColor: '#E21313', maxColor: '#FF9052' }, { startValue: -1, endValue: 0, minColor: '#FF9052', maxColor: '#EAFCFF' }, { startValue: 0, endValue: 1, minColor: '#EAFCFF', maxColor: '#A1E981' }, { startValue: 1, endValue: 2000000, minColor: '#A1E981', maxColor: '#A1E981' }] };
        }
      }
      else if(occupationMetric === 'Workload') {
        if(occupationLevel === 'Month') {
          this.paletteSettings = { palette: [{ value: 0, color: '#EAFCFF' }, { value: 20, color: '#95B0B5' }, { value: 40, color: '#FFD589' }, { value: 60, color: '#FF9052' }] };
        }
        else if(occupationLevel === 'Week') {
          this.paletteSettings = { palette: [{ value: 0, color: '#EAFCFF' }, { value: 5, color: '#95B0B5' }, { value: 10, color: '#FFD589' }, { value: 15, color: '#FF9052' }] };
        }
        else if(occupationLevel === 'Day') {
          this.paletteSettings = { palette: [{ value: 0, color: '#EAFCFF' }, { value: 1, color: '#95B0B5' }, { value: 2, color: '#FFD589' }, { value: 3, color: '#FF9052' }] };
        }
      }
    }
    else {
      this.paletteSettings = { palette: [{ value: 0, color: '#EAFCFF' }, { value: 99, color: '#95B0B5' }, { value: 100, color: '#A1E981' }, { value: 101, color: '#FFD589' }, { value: 500, color: '#FF9052' }] };
    }
    
    return (<div className="popupEditPanel">
      <div className="panelBody">
        {/* Panel Views */}
        <FiltersViewPopup ItemId={itemId} ItemType={itemType} BlockType={panelBlockType} CurrentView={panelCurrentView} Views={panelViews} OtherViews={panelUnpinnedViews} onViewChange={this.changePanelView}></FiltersViewPopup>

        <div className="panelContent">
          {/* Panel Label */}
          <div className="panelBlockHeader">
            <div className="">{this.getPanelBlockIcon(panelBlockType)}</div>
            <Form.Group className="panelBlockTitle">
              <Form.Control id="" className="panelBlockLabel" as="textarea" rows="1" placeholder={Traduction.translate(language, 'add_miniblock_title')} value={panelBlockLabel} onChange={(e) => this.updatePanelLabel(e)}/>
            </Form.Group>
          </div>

          {/* Loading Spinner */}
          {isLoading && <div className="center mt30 mb20">
            <span className=""><LoadingSpinner></LoadingSpinner></span>
            <span className="bold ml30">{Traduction.translate(language, 'data_loading')}</span>
          </div>}
          
          {/* Panel Preview */}
          {(panelBlockType !== 'BudgetDetails' && panelBlockType !== 'Meetings' && panelBlockType !== 'Risks' && panelBlockType !== 'Burned' && panelBlockType !== 'Workload') && 
            <div className={((panelCurrentView.ViewType !== 1 && panelCurrentView.ViewType !== 4) ? "panelComponent scrollbar-miniblock" : "panelComponentNoScroll")}>
              {/* Mini Components */}
              {panelCurrentView.ViewType === 0 && panelCurrentView.DefaultLevel === 0 && <MiniTree ref={this.tree} ItemId={itemId} ItemType={itemType} BlockType={panelBlockType} Editable={false} Pagging={false} CurrentView={panelCurrentView} Columns={panelColumns} Rows={panelRows}></MiniTree>}
              {panelCurrentView.ViewType === 0 && panelCurrentView.DefaultLevel !== 0 && <MiniTable ref={this.table} ItemId={itemId} ItemType={itemType} BlockType={panelBlockType} Editable={false} Pagging={false} CurrentView={panelCurrentView} Columns={panelColumns} Rows={panelRows}></MiniTable>}
              {panelCurrentView.ViewType === 1 && <MiniPlanning ref={this.planning} ItemId={itemId} ItemType={itemType} BlockType={panelBlockType} Editable={false} CurrentView={panelCurrentView} Columns={panelColumns} Rows={panelRows}></MiniPlanning>}
              {panelCurrentView.ViewType === 2 && <Kanban ref={this.kanban} ItemId={itemId} ItemType={itemType} BlockType={panelBlockType} Editable={false} GuestLicence={guestLicence} CurrentView={panelCurrentView} Kanban={panelKanban} Columns={panelColumns} Rows={panelRows} Axes={panelBlockContent.AvailableAxes}></Kanban>}
              {panelCurrentView.ViewType === 3 && <MiniPivot ref={this.pivot} ItemId={itemId} ItemType={itemType} BlockType={panelBlockType} Editable={false} CurrentView={panelCurrentView} Columns={panelColumns} Rows={panelRows}></MiniPivot>}
              {panelCurrentView.ViewType === 4 && <MiniBlockDetails Id={itemId} Type={itemType} BlockContent={panelBlockContent} BlockType={panelBlockType} BlockLabel={panelBlockLabel}></MiniBlockDetails>}
              {panelCurrentView.ViewType === 7 && <MiniChart ref={this.chart} ItemId={itemId} ItemType={itemType} BlockType={panelBlockType} CurrentView={panelCurrentView} Chart={panelChart} IsLoading={isLoading}></MiniChart>}
              {panelCurrentView.ViewType === 8 && <MiniMap ref={this.map} ItemId={itemId} ItemType={itemType} BlockType={panelBlockType} CurrentView={panelCurrentView} Map={panelMap} IsLoading={isLoading}></MiniMap>}
            </div>
          }
          {(panelBlockType === 'BudgetDetails' || panelBlockType === 'Meetings' || panelBlockType === 'Risks') && 
            <div className={(panelCurrentView.ViewType !== 1 ? "panelComponent scrollbar-miniblock" : "panelComponentNoScroll")}>
              {/* Mini Components */}
              {visibleComponent && panelCurrentView.ViewType === 0 && panelBlockType === 'BudgetDetails' && <MiniDoughnut ref={this.doughnut} CurrentView={panelCurrentView} Display={'Miniblock'} Columns={panelColumns} Rows={panelRows}></MiniDoughnut>}
              {visibleComponent && panelCurrentView.ViewType === 0 && panelBlockType === 'Meetings' && <MiniMeetingCalendar ref={this.calendar} Rows={panelRows} FilterPeriod={panelCurrentView.FilterPeriodType}></MiniMeetingCalendar>}
              {visibleComponent && panelCurrentView.ViewType === 0 && panelBlockType === 'Risks' && <MiniRiskMatrix ref={this.matrix} ApplicationParameters={panelApplicationParameters} Impact={this.getImpactValues(panelColumns)} Probability={this.getProbabilityValues(panelColumns)} Rows={panelRows}></MiniRiskMatrix>}
              {!visibleComponent && panelCurrentView.ViewType === 0 && panelCurrentView.DefaultLevel !== 0 && <MiniTable ref={this.table} ItemId={itemId} ItemType={itemType} BlockType={panelBlockType} Editable={false} CurrentView={panelCurrentView} Columns={panelColumns} Rows={panelRows}></MiniTable>}
              {panelCurrentView.ViewType === 1 && <MiniPlanning ref={this.planning} ItemId={itemId} ItemType={itemType} BlockType={panelBlockType} Editable={false} CurrentView={panelCurrentView} Columns={panelColumns} Rows={panelRows}></MiniPlanning>}
              {panelCurrentView.ViewType === 2 && <Kanban ref={this.kanban} ItemId={itemId} ItemType={itemType} BlockType={panelBlockType} Editable={false} GuestLicence={guestLicence} CurrentView={panelCurrentView} Kanban={panelKanban} Columns={panelColumns} Rows={panelRows} Axes={panelBlockContent.AvailableAxes}></Kanban>}
              {panelCurrentView.ViewType === 3 && <MiniPivot ref={this.pivot} ItemId={itemId} ItemType={itemType} BlockType={panelBlockType} Editable={false} CurrentView={panelCurrentView} Columns={panelColumns} Rows={panelRows}></MiniPivot>}
              {panelCurrentView.ViewType === 7 && <MiniChart ref={this.chart} ItemId={itemId} ItemType={itemType} BlockType={panelBlockType} CurrentView={panelCurrentView} Chart={panelChart} IsLoading={isLoading}></MiniChart>}
              {panelCurrentView.ViewType === 8 && <MiniMap ref={this.map} ItemId={itemId} ItemType={itemType} BlockType={panelBlockType} CurrentView={panelCurrentView} Map={panelMap} IsLoading={isLoading}></MiniMap>}
            </div>
          }
          {(panelBlockType === 'Burned' || panelBlockType === 'Workload') && 
            <div className={(panelCurrentView.ViewType !== 1 ? "panelComponent scrollbar-miniblock" : "panelComponentNoScroll")}>
              {/* Mini Heatmap */}
              {visibleComponent === 'Heatmap' && panelCurrentView.ViewType === 3 && 
                <HeatMapComponent id="heatmapWorkload-container" height={'100%'} width={'100%'} dataSource={heatmap.dataSource} xAxis={{ labels: heatmap.xAis }} yAxis={{ labels: heatmap.yAis }} cellSettings={this.cellSettings} paletteSettings={this.paletteSettings} legendSettings={{ visible: false }} showTooltip={false} ref={heatmap=>this.heatmap=heatmap}>
                  <Inject services={[ Legend, Tooltip, Adaptor ]} />
                </HeatMapComponent>
              }
              {/* Mini Pivot */}
              {visibleComponent === 'Graph' && panelCurrentView.ViewType === 3 && <MiniPivot ItemId={itemId} ItemType={itemType} BlockType={panelBlockType} Editable={false} CurrentView={panelCurrentView} Columns={panelColumns} Rows={panelRows}></MiniPivot>}
            </div>
          }
        </div>
      </div>

      {/* Button Validate or Cancel */}
      <div className="flex flex-end align-items-center">
        <Button className="fs12 bold brd-radius mr15" variant="primary" onClick={() => this.applyPanelView(panelBlockId, panelBlockLabel, panelCurrentView, panelChart, panelFields, panelHeatmaps, panelKanban, panelMap, panelTables)}>{Traduction.translate(language, 'validate')}</Button>
        <Button className="fs12 bold brd-radius" variant="warning" onClick={() => this.closeEditPanelPopup()}>{Traduction.translate(language, 'cancel')}</Button>
      </div>
    </div>);
  }

  closeAddPanelPopup() {
    this.setState({ addPanelPopup: false });
  }

  closeEditPanelPopup() {
    this.setState({ panelBlockInfo: {}, panelBlockId: null, panelBlockLabel: null, panelBlockType: null, panelViews: [], panelViewId: null, panelCurrentView: {}, panelTables: [], panelColumns: [], panelRows: [], editPanelPopup: false });
  }

  render() {
    let { language, itemId, itemType, editable, blocks, panels, addPanelPopup, editPanelPopup, isLoading } = this.state;

    // Cell Spacing
    this.cellSpacing = [ 20, 20 ];

    return (
      <div className="cardDashboard">
        {/* Loading Spinner */}
        {isLoading && <div className="center mb20">
          <span className=""><LoadingSpinner></LoadingSpinner></span>
          <span className="bold ml30">{Traduction.translate(language, 'data_loading')}</span>
        </div>}

        {/* Popup Add Panel */}
        {(addPanelPopup === true) && <div className="addPanelPopup">
          <div className="addPanelInnerPopup">{this.templateAddPanel()}</div>
        </div>}

        {/* Popup Edit Panel */}
        {(editPanelPopup === true) && <div className="editPanelPopup">
          <div className="editPanelInnerPopup">{this.templateEditPanel()}</div>
        </div>}

        {/* Dashboard Component */}
        <div className="control-pane">
          <DashboardLayoutComponent id='defaultLayout' panels={panels} columns={12} cellSpacing={this.cellSpacing} cellAspectRatio={3} dragStart={this.dragStart} dragStop={this.dragStop} resizeStart={this.resizeStart} resizeStop={this.resizeStop} allowDragging={false} allowResizing={false} ref={dashboard=>this.dashboard=dashboard}/>
        </div>
      </div>
    )
  }
}

export default Dashboard;