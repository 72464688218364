import React, {Component} from 'react';
import { Button, OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';
import PopoverEditFilter from './PopoverEditFilter';

const API = '/WebAppService/GetCardSorts';

class FiltersSort extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: null,
      authId: null,
      language: null,
      itemId: null,
      itemType: null,
      blockType: null,
      currentView: {},
      availableSorts: [],
      currentSorts: [],
      columns: [],
      columnName: null,
      sortPopup: false
    };
    
    // Data Structure
    this.getAvailableSorts = this.getAvailableSorts.bind(this);
    this.getCurrentViewColumns = this.getCurrentViewColumns.bind(this);

    // Actions
    this.addSort = this.addSort.bind(this);
    this.addColumnSort = this.addColumnSort.bind(this);
    this.deleteSort = this.deleteSort.bind(this);
    this.updateSort = this.updateSort.bind(this);
    this.updateValue = this.updateValue.bind(this);
    this.saveSort = this.saveSort.bind(this);
    this.openSortPopup = this.openSortPopup.bind(this);

    // Template
    this.templateSortPopup = this.templateSortPopup.bind(this);
    this.templateFilterSort = this.templateFilterSort.bind(this);
    this.closeSortPopup = this.closeSortPopup.bind(this);
  }

  componentDidMount() {
    const login = Authentication.getCookie('login');
    const authId = Authentication.getCookie('authId');
    const language = Authentication.getCookie('language');
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const blockType = this.props.BlockType;
    const currentView = this.props.CurrentView;
    
    this.setState({ login, authId, language, itemId, itemType, blockType, currentView });
  }

  componentDidUpdate(prevProps) {
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const blockType = this.props.BlockType;
    const currentView = this.props.CurrentView;

    if(itemId !== prevProps.ItemId || itemType !== prevProps.ItemType || blockType !== prevProps.BlockType) {
      this.setState({ itemId, itemType, blockType });
    }
    if(JSON.stringify(this.props.CurrentView) !== JSON.stringify(prevProps.CurrentView)) {
      this.setState({ currentView });
    }
  }

  // Get Available Sorts from the API
  async getAvailableSorts(login, authId, itemId, itemType, blockType, view) {
    const language = this.state.language;
    
    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': itemType,
        'ItemId': itemId,
        'BlockType': blockType,
        'View': view,
        'WithData': false,
        'WithInformation': true,
        'InactiveData': false
      })
    };

    try{
      const response = await fetch(API, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const result = data.GetCardSortsResult;

      if(result) {
        const availableSorts = result.AvailableSorts;
        const currentSorts = result.CurrentSorts;

        this.setState({ availableSorts, currentSorts });
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    } catch(error) {
      this.setState({ error });
    }
  }

  getCurrentViewColumns(currentView) {
    let columns = [];
    
    // Current View Table/Tree/Planning
    if(currentView.ViewType === 0 || currentView.ViewType === 1) {
      if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Columns')) {
        columns = (currentView.Parameters.find(param => param.Name === 'Columns').Value).split(',');
      }
    }
    // Current View Kanban
    else if(currentView.ViewType === 2) {
      if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Axe1') && currentView.Parameters.find(param => param.Name === 'Axe1').Value !== 'None') {
        columns.push(currentView.Parameters.find(param => param.Name === 'Axe1').Value);
      }
      if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Axe2') && currentView.Parameters.find(param => param.Name === 'Axe2').Value !== 'None') {
        columns.push(currentView.Parameters.find(param => param.Name === 'Axe2').Value);
      }
      if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Columns')) {
        currentView.Parameters.find(param => param.Name === 'Columns').Value.split(',').forEach(column => {
          columns.push(column);
        });
      }
    }
    // Current View Pivot/Chart/Map
    else if(currentView.ViewType === 3 || currentView.ViewType === 7 || currentView.ViewType === 8) {
      if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Columns')) {
        currentView.Parameters.find(param => param.Name === 'Columns').Value.split(',').forEach(column => {
          columns.push(column);
        });
        // columns.push(currentView.Parameters.find(param => param.Name === 'Columns').Value);
      }
      if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Rows')) {
        currentView.Parameters.find(param => param.Name === 'Rows').Value.split(',').forEach(row => {
          columns.push(row);
        });
        // columns.push(currentView.Parameters.find(param => param.Name === 'Rows').Value);
      }
      if(currentView.Values && currentView.Values.length > 0) {
        currentView.Values.forEach(value => {
          columns.push(value.Field);
        });
      }
    }

    return columns;
  }

  addSort() {
    const { currentView, availableSorts, currentSorts } = this.state;
    const columns = this.getCurrentViewColumns(currentView);
    let newSort;

    // Get first available Sort corresponding to CurrentView Columns
    if(availableSorts.find(sort => columns.includes(sort.FieldObject.Name))) {
      newSort = availableSorts.find(sort => columns.includes(sort.FieldObject.Name));
    }

    // Add new Filter in Current Sorts
    currentSorts.push(JSON.parse(JSON.stringify(newSort)));

    this.setState({ currentSorts });

    // const availableSorts = this.state.availableSorts;
    // const currentSorts = this.state.currentSorts;

    // // Add new Sort in Current Sorts
    // for(let i = 0; i < availableSorts.length; i++) {
    //   if(!currentSorts.find(sort => sort.FieldObject.Name === availableSorts[i].FieldObject.Name)) {
    //     currentSorts.push(JSON.parse(JSON.stringify(availableSorts[i])));
    //     break;
    //   }
    // }

    // this.setState({ currentSorts });
  }

  addColumnSort(columnName) {
    const availableSorts = this.state.availableSorts;
    const currentSorts = this.state.currentSorts;
    let newSort;

    // Find Sort corresponding to ColumnName in Available Sorts
    if(availableSorts.find(sort => sort.FieldObject.Name === columnName)) {
      newSort = availableSorts.find(sort => sort.FieldObject.Name === columnName);
    }

    // Add new Sort in Current Sorts
    currentSorts.push(JSON.parse(JSON.stringify(newSort)));

    this.setState({ currentSorts });
  }

  deleteSort(sort) {
    const currentSorts = this.state.currentSorts;
    let indexToDelete = -1;

    // Find Current Sort
    if(currentSorts.find(currentSort => currentSort.FieldObject.Name === sort.FieldObject.Name)) {
      indexToDelete = currentSorts.findIndex(currentSort => currentSort.FieldObject.Name === sort.FieldObject.Name);
    }

    // Delete Current Sort
    if(indexToDelete >= 0) {
      currentSorts.splice(indexToDelete, 1);
    }

    this.setState({ currentSorts });
  }

  updateSort(oldSort, newSort) {
    const availableSorts = this.state.availableSorts;
    const currentSorts = this.state.currentSorts;
    let indexToUpdate = -1;

    // Find Current Sort
    if(currentSorts.find(currentSort => currentSort.FieldObject.Name === oldSort.FieldObject.Name)) {
      indexToUpdate = currentSorts.findIndex(currentSort => currentSort.FieldObject.Name === oldSort.FieldObject.Name);
    }

    // Update Current Sort (if it doesn't already exists)
    // if(!currentSorts.find(sort => sort.FieldObject.Name === newSort.FieldObject.Name)) {
      if(indexToUpdate >= 0 && availableSorts.find(availableSort => availableSort.FieldObject.Name === newSort.FieldObject.Name)) {
        currentSorts[indexToUpdate] = availableSorts.find(availableSort => availableSort.FieldObject.Name === newSort.FieldObject.Name);
      }
    // }

    this.setState({ currentSorts });
  }

  updateValue(sort, value) {
    const currentSorts = this.state.currentSorts;

    // Find Current Sort
    if(currentSorts.find(currentSort => currentSort.FieldObject.Name === sort.FieldObject.Name)) {
      currentSorts.find(currentSort => currentSort.FieldObject.Name === sort.FieldObject.Name).Way = value;
    }

    this.setState({ currentSorts });
  }

  async saveSort() {
    const currentView = this.state.currentView;
    const currentSorts = this.state.currentSorts;
    let items = [], result = "";

    // Update Current View with Current Sorts
    currentSorts.forEach(sort => {
      // Push Sort Name
      if(sort.Way === 0) {
        items.push(sort.FieldObject.Name);
      }
      else if(sort.Way === 1) {
        items.push(sort.FieldObject.Name + " DESC");
      }
    });

    // Build Total Sort Value
    result = items.join(",");

    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Sort')) {
      currentView.Parameters.find(param => param.Name === 'Sort').Value = result;
    }
    else if(currentView.Parameters && !currentView.Parameters.find(param => param.Name === 'Sort')) {
      currentView.Parameters.push({ Name: "Sort", Value: result, ViewId: currentView.ViewId });
    }

    // Call the event from the Parent component through the props with updated Current View
    this.props.onSortChange(currentView);

    // Close Popup
    this.setState({ sortPopup: false });
  }

  async openSortPopup(columnName) {
    const { login, authId, itemId, itemType, blockType, currentView } = this.state;

    // Get View Available Sorts
    await this.getAvailableSorts(login, authId, itemId, itemType, blockType, currentView);

    this.setState({ sortPopup: true });

    // Add Sort on specific column
    if(columnName) {
      this.addColumnSort(columnName);
    }
  }

  templateSortPopup() {
    const { language, availableSorts, currentSorts } = this.state;

    return (<div className="popupFiltersSort">
      {/* Title */}
      <div className="flex align-items-center">
        {/* Label */}
        <div className="filtersSortTitle">{Traduction.translate(language, 'sort')}</div>
        {/* Add Filter */}
        <div className="cursor" onClick={() => this.addSort()}>
          <span className="iconButtonPlusGreen iconsFilter"></span>
          <span className="addSortLabel">{Traduction.translate(language, 'add_sort')}</span>
        </div>
      </div>
      {/* Sorts */}
      <div className="filtersSortContent scrollbar-miniblock">
        {currentSorts.map((sort, index) => {
          return (<div key={index} className="filtersSortRow">{this.templateFilterSort(sort)}</div>)
        })}
      </div>
      {/* Button Validate or Cancel */}
      <div className="flex flex-end align-items-center">
        <Button className="fs12 bold brd-radius mr15" variant="primary" onClick={() => this.saveSort()}>{Traduction.translate(language, 'validate')}</Button>
        <Button className="fs12 bold brd-radius" variant="warning" onClick={() => this.closeSortPopup()}>{Traduction.translate(language, 'cancel')}</Button>
      </div>
    </div>);
  }

  templateFilterSort(sort) {
    const { language, itemId, itemType, currentView, availableSorts, currentSorts } = this.state;

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
        <PopoverEditFilter ItemId={itemId} ItemType={itemType} Display={'Sort'} CurrentView={currentView} AvailableFilters={availableSorts} CurrentFilters={currentSorts} Filter={sort} onFilterEdit={this.updateSort}></PopoverEditFilter>
        </Popover.Content>
      </Popover>
    );

    return (<div className="flex width100p">
      <div className="filtersSort">
        {/* Delete Filter */}
        <span className="iconLess iconsPopover mr10 cursor" onClick={() => this.deleteSort(sort)}/>
        {/* Filter Select */}
        <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
          <div className="filtersSortName">
            <div className="filtersSortLabel">{sort.FieldObject.Label}</div>
            <div className="flex flex-end"><span className="iconChevronDownGrey iconsPopover"></span></div>
          </div>
        </OverlayTrigger>
      </div>
      {/* Sort Ascending */}
      <div className={"filtersSortValue" + (sort.Way === 0 ? "" : " opacity50")} onClick={() => this.updateValue(sort, 0)}>
        {['top'].map(placement => (
          <OverlayTrigger key={placement} placement={placement} overlay={<Tooltip id={`tooltip-${placement}`}><strong>{Traduction.translate(language, 'ascending')}</strong></Tooltip>}>
            <span className="iconSortAscending iconsPopover"></span>
          </OverlayTrigger>
        ))}
      </div>
      {/* Sort Descending */}
      <div className={"filtersSortValue" + (sort.Way === 1 ? "" : " opacity50")} onClick={() => this.updateValue(sort, 1)}>
        {['top'].map(placement => (
          <OverlayTrigger key={placement} placement={placement} overlay={<Tooltip id={`tooltip-${placement}`}><strong>{Traduction.translate(language, 'descending')}</strong></Tooltip>}>
            <span className="iconSortDescending iconsPopover"></span>
          </OverlayTrigger>
        ))}
      </div>
    </div>);
  }

  closeSortPopup() {
    this.setState({ sortPopup: false });
  }
  
  render() {
    const { language, currentView, sortPopup } = this.state;
    let label;

    // Label
    if(currentView.Parameters && !currentView.Parameters.find(param => param.Name === 'Sort')) {
      label = Traduction.translate(language, 'sort');
    }
    else if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Sort')) {
      if(currentView.Parameters.find(param => param.Name === 'Sort').Value === '') {
        label = Traduction.translate(language, 'sort');
      }
      else if(currentView.Parameters.find(param => param.Name === 'Sort').Value.split(',').length === 1) {
        label = currentView.Parameters.find(param => param.Name === 'Sort').Value.split(',').length + " " + Traduction.translate(language, 'sort');
      }
      else {
        label = currentView.Parameters.find(param => param.Name === 'Sort').Value.split(',').length + " " + Traduction.translate(language, 'sorts');
      }
    }

    return (
      <div className="">
        {/* Popup Sort */}
        {sortPopup && <div className="filtersSortPopup">
          <div className="filtersSortInnerPopup">{this.templateSortPopup()}</div>
        </div>}

        {['top'].map(placement => (
          <OverlayTrigger key={placement} placement={placement} overlay={<Tooltip id={`tooltip-${placement}`}><strong>{label}</strong></Tooltip>}>
            <div className={(currentView.Parameters && (!currentView.Parameters.find(param => param.Name === 'Sort') || currentView.Parameters.find(param => param.Name === 'Sort').Value === '') ? "filtersBlock" : "filtersBlockActive") + " cursor"} onClick={() => this.openSortPopup()}>
              <span className="iconSortBlue iconsFilter"/>
              <span className="fs12 bold ml10">{label}</span>
            </div>
          </OverlayTrigger>
        ))}
      </div>
    )
  }
}

export default FiltersSort;