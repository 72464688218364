import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import { Form } from 'react-bootstrap';
import queryString from 'query-string';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';
import Navbar from './Navbar';
import BlockTitle from './BlockTitle';
import IndicatorsTimeTracking from './IndicatorsTimeTracking';
import TimetrackingMonth from './TimetrackingMonth';
import FiltersView from './FiltersView';
import FiltersCurrentView from './FiltersCurrentView';
import FiltersBlock from './FiltersBlock';
import FiltersSort from './FiltersSort';
import FiltersConditionalFormatting from './FiltersConditionalFormatting';
import FiltersColumnChooser from './FiltersColumnChooser';
import FiltersExport from './FiltersExport';
import LoadingSpinner from './LoadingSpinner';
import ErrorModification from './ErrorModification';
import Table from './Table';
import TableTimeTracking from './TableTimeTracking';

const API_info = '/WebAppService/GetCardBlockInformation';
const API_saveHolidays = '/WebAppService/SaveHolidayModifications';
const API_valid = '/WebAppService/GetPropagationsAndSaveModification';

class TimeTracking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: null,
      authId: null,
      resourceId: null,
      language: null,
      itemId: null,
      itemType: null,
      itemTitle: null,
      blockType: null,
      blockInfo: {},
      blockContent: {},
      editable: null,
      guestLicence: null,
      month: null,
      year: null,
      timetrackingType: null,
      displayViews: null,
      views: [],
      defaultViewId: null,
      currentView: {},
      days: [],
      weeks: [],
      tables: [],
      columns: [],
      rows: [],
      selectedWeek: {},
      search: '',
      isLoading: false,
      errors: []
    };

    // Data Structure
    this.getCardInformation = this.getCardInformation.bind(this);
    this.getCardData = this.getCardData.bind(this);
    this.buildDays = this.buildDays.bind(this);
    this.buildWeeks = this.buildWeeks.bind(this);
    this.formatDate = this.formatDate.bind(this);

    // Actions
    this.displayViews = this.displayViews.bind(this);
    this.hideViews = this.hideViews.bind(this);
    this.changeMonth = this.changeMonth.bind(this);
    this.changeView = this.changeView.bind(this);
    this.setDefaultView = this.setDefaultView.bind(this);
    this.changeFilters = this.changeFilters.bind(this);
    this.changeSort = this.changeSort.bind(this);
    this.changeFormattings = this.changeFormattings.bind(this);
    this.changePeriod = this.changePeriod.bind(this);
    this.changeActiveTasks = this.changeActiveTasks.bind(this);
    this.changeColumns = this.changeColumns.bind(this);
    this.filterColumn = this.filterColumn.bind(this);
    this.sortColumn = this.sortColumn.bind(this);
    this.updateHolidays = this.updateHolidays.bind(this);
    this.saveHolidays = this.saveHolidays.bind(this);
    this.checkModificationTTDay = this.checkModificationTTDay.bind(this);
    this.checkModificationTTMonth = this.checkModificationTTMonth.bind(this);
    this.updateRowsInTable = this.updateRowsInTable.bind(this);
    this.applyPropagations = this.applyPropagations.bind(this);
    this.exportCSV = this.exportCSV.bind(this);
    this.exportXLSX = this.exportXLSX.bind(this);
    this.searchItem = this.searchItem.bind(this);
    this.updateView = this.updateView.bind(this);
    this.updateWeeks = this.updateWeeks.bind(this);
    this.updateTable = this.updateTable.bind(this);
    this.updateErrors = this.updateErrors.bind(this);
    this.cleanErrors = this.cleanErrors.bind(this);

    // Reference
    this.indicators = React.createRef();
    this.views = React.createRef();
    this.filters = React.createRef();
    this.sort = React.createRef();
    this.formattings = React.createRef();
    this.timetracking = React.createRef();
    this.table = React.createRef();
  }

  async componentDidMount() {
    const login = Authentication.getCookie('login');
    const authId = Authentication.getCookie('authId');
    let resourceId = Authentication.getCookie('resourceId');
    const language = Authentication.getCookie('language');
    const itemType = 'Resource';
    const path = this.props.match.path.split('/');
    const blockType = path[path.length-1];
    let displayViews;

    if(Authentication.getCookie('displayViews')) {
      displayViews = JSON.parse(Authentication.getCookie('displayViews'));
    }
    else {
      displayViews = true;
    }

    // Get Params from Query string url
    // const params = queryString.parse(this.props.location.search);
    // const month = parseInt(params.month);
    // const year = parseInt(params.year);

    // if(params.resourceId) {
    //   resourceId = parseInt(params.resourceId);
    // }

    // Get Params from Query string url
    let params, viewId, month, year;

    if(this.props.location.search) {
      params = queryString.parse(this.props.location.search);
      
      if(params.viewId) {
        viewId = parseInt(params.viewId);
      }

      month = parseInt(params.month);
      year = parseInt(params.year);

      if(params.resourceId) {
        resourceId = parseInt(params.resourceId);
      }
    }

    this.setState({ login, authId, resourceId, language, displayViews, itemType, blockType });

    // Get Card Information
    await this.getCardInformation(login, authId, itemType, resourceId, blockType, viewId);

    const blockInfo = this.state.blockInfo;
    const views = this.state.views;
    let currentView = {};
    
    if(Object.entries(blockInfo).length > 0) {
      // Get Current View
      if(viewId && views.find(view => view.ViewId === viewId)) {
        currentView = views.find(view => view.ViewId === viewId);
      }
      else if(views.find(view => view.ViewId === blockInfo.DefaultViewId)) {
        currentView = views.find(view => view.ViewId === blockInfo.DefaultViewId);
      }

      if(!viewId && (currentView.ViewId && viewId !== currentView.ViewId)) {
        // Redirect with new Query params
        this.props.history.push(`/TimeTracking?viewId=${currentView.ViewId}&year=${year}&month=${month}&resourceId=${resourceId}`);
      }
      else {
        // Get Card Data
        await this.getCardData(login, authId, itemType, resourceId, blockType, currentView, year, month);
      }
    }
    else {
      // Redirect to Login Page
      this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
    }
  }

  async componentDidUpdate(prevProps) {
    const login = Authentication.getCookie('login');
    const authId = Authentication.getCookie('authId');
    let resourceId = Authentication.getCookie('resourceId');
    const itemType = 'Resource';
    const path = this.props.match.path.split('/');
    const blockType = path[path.length-1];

    // Get old Params from Query string url
    // const old_params = queryString.parse(prevProps.location.search);
    // const old_month = parseInt(old_params.month);
    // const old_year = parseInt(old_params.year);

    // Get Params from Query string url
    // const params = queryString.parse(this.props.location.search);
    // const month = parseInt(params.month);
    // const year = parseInt(params.year);

    // if(params.resourceId) {
    //   resourceId = parseInt(params.resourceId);
    // }

    // Get Params from Query string url
    let params, old_params, viewId, oldViewId, month, oldMonth, year, oldYear, oldResourceId;

    if(this.props.location.search) {
      params = queryString.parse(this.props.location.search);

      if(params.viewId) {
        viewId = parseInt(params.viewId);
      }

      month = parseInt(params.month);
      year = parseInt(params.year);

      if(params.resourceId) {
        resourceId = parseInt(params.resourceId);
      }
    }
    if(prevProps.location.search) {
      old_params = queryString.parse(prevProps.location.search);

      if(old_params.viewId) {
        oldViewId = parseInt(old_params.viewId);
      }

      oldMonth = parseInt(old_params.month);
      oldYear = parseInt(old_params.year);

      if(old_params.resourceId) {
        oldResourceId = parseInt(old_params.resourceId);
      }
    }

    if(resourceId !== oldResourceId) {
      // Get Card Information
      await this.getCardInformation(login, authId, itemType, resourceId, blockType, viewId);
    }
    if(viewId !== oldViewId || month !== oldMonth || year !== oldYear || resourceId !== oldResourceId) {
      const blockInfo = this.state.blockInfo;
      const views = this.state.views;
      let currentView = {};

      // Get Current View
      if(viewId && views.find(view => view.ViewId === viewId)) {
        currentView = views.find(view => view.ViewId === viewId);
      }
      else if(views.find(view => view.ViewId === blockInfo.DefaultViewId)) {
        currentView = views.find(view => view.ViewId === blockInfo.DefaultViewId);
      }

      // Get Card Data
      await this.getCardData(login, authId, itemType, resourceId, blockType, currentView, year, month);

      // Refresh Filters View
      // if(this.views.current) {
      //   this.views.current.selectView(currentView);
      // }

      // Refresh Components with CurrentView
      if(this.table.current) {
        this.table.current.refreshColumns(currentView);
      }
      else if(this.timetracking.current) {
        this.timetracking.current.refreshColumns(currentView);
      }
    }
  }

  // Get Block Information from the API
  async getCardInformation(login, authId, itemType, resourceId, blockType, viewId) {
    const language = this.state.language;
    let additionalContext = [];
    
    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': itemType,
        'ItemId': resourceId,
        'BlockType': blockType,
        'ViewId': viewId,
        'WithData': false,
        'WithInformation': true,
        'InactiveData': false,
        'AdditionalContext': additionalContext
      })
    };

    try{
      const response = await fetch(API_info, requestOptions);
  
      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }
  
      const data = await response.json();
      const blockInfo = data.GetCardBlockInformationResult;
  
      if(blockInfo) {
        const itemTitle = blockInfo.ObjectName;
        const editable = blockInfo.InsertDeleteAllowed;
        const guestLicence = blockInfo.GuestLicence;
        const views = blockInfo.Views;
        const defaultViewId = blockInfo.DefaultViewId;
        let currentView = {};

        // Get Current View
        if(viewId && views.find(view => view.ViewId === viewId)) {
          currentView = views.find(view => view.ViewId === viewId);
        }
        else if(views.find(view => view.ViewId === blockInfo.DefaultViewId)) {
          currentView = views.find(view => view.ViewId === blockInfo.DefaultViewId);
        }

        this.setState({ blockInfo, itemTitle, editable, guestLicence, views, defaultViewId, viewId, currentView });
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  // Get Block Data from the API
  async getCardData(login, authId, itemType, resourceId, blockType, currentView, year, month, weekId) {
    const language = this.state.language;
    let additionalContext = [];
    
    if(month && year) {
      additionalContext = [{ Key: "Month", Value: month }, { Key: "Year", Value: year }];
    }
    if(!isNaN(weekId)) {
      additionalContext.push({ Key: "WeekId", Value: weekId });
    }

    this.setState({ isLoading: true });

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': itemType,
        'ItemId': resourceId,
        'BlockType': blockType,
        'View': currentView,
        'WithData': true,
        'WithInformation': false,
        'InactiveData': false,
        'AdditionalContext': additionalContext
      })
    };

    try{
      const response = await fetch(API_info, requestOptions);
  
      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }
  
      const data = await response.json();
      const blockContent = data.GetCardBlockInformationResult;
  
      if(blockContent) {
        const timetrackingType = blockContent.TimeTrackingType;
        let days = [], weeks = [], selectedWeek = {};
        
        // Days
        if(blockContent.Days) {
          days = blockContent.Days;
        }

        // Weeks
        if(blockContent.Weeks) {
          weeks = this.buildWeeks(blockContent.Weeks).weeks;
          selectedWeek = this.buildWeeks(blockContent.Weeks).selectedWeek;
        }

        const tables = blockContent.Tables;
        let columns = [], rows = [];

        // Get Tables, Rows & Columns
        if(tables.find(element => element.Level === "Tree")) {
          columns = tables.find(element => element.Level === "Tree").ColumnHeaders;
          rows = tables.find(element => element.Level === "Tree").Rows;
        }

        this.setState({ blockContent, month, year, timetrackingType, viewId: currentView.ViewId, currentView, days, weeks, tables, columns, rows, selectedWeek, isLoading: false });
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  buildDays(days) {
    let timetrackingDays = [];

    // Build Days Off/Holidays
    if(days) {
      timetrackingDays = days.map(day => {
        let icon = "iconCircle";
        let colorCircle = "bg-grey";
        let colorLabel = "black";

        // Week End
        if(day.Weekend) {
          colorCircle = "bg-very-light-grey";
          colorLabel = "light-grey";
        }
        // Bank Holiday
        else if(day.BankHoliday) {
          icon = "iconFullCircle";
          colorCircle = "bg-dark-grey";
        }
        // Day Off
        else if(day.DayOff) { 
          icon = "iconFullCircle";
          colorCircle = "bg-turquoise";
        }
        // Half Day Off
        else if(day.HalfDayOff) {
          icon = "iconHalfCircle";
          colorCircle = "bg-turquoise";
        }
        
        // Day of Week Label
        if(day.DayOfWeek) {
          day.DayOfWeek = day.DayOfWeek.substr(0,3);
        }

        return <div key={day.Day} className="holidays" onClick={(e) => this.updateHolidays(day.Day-1)}>
          <div className={'fs11 ' + colorLabel}>{day.DayOfWeek} {day.Day}</div>
          <div className={'iconsTimeTracking ' + icon + ' ' + colorCircle}></div>
        </div>;
      });
    }

    return timetrackingDays;
  }

  buildWeeks(weeks) {
    let selectedWeek;
    let today = new Date();

    // Define Weeks Display
    if(weeks) {
      weeks.map(week => {
        // Selected Week
        if(week.IsSelected) {
          selectedWeek = week;
        }

        if(week.WeekId === 0) {
          week.Color = "displayAllWeeks";
        }
        else {
          // Get Week Start & End Date
          let start = new Date(this.formatDate(week.borneInf));
          let end = new Date(this.formatDate(week.borneSup));
  
          // Set the same Hours/Minutes/Seconds/Milliseconds to compare Dates
          start.setHours(0,0,0,0);
          end.setHours(0,0,0,0);
          today.setHours(0,0,0,0);

          if(week.Completion < 1) {
            // If Today is before Week borneInf
            if(today.getTime() < start.getTime()) {
              week.Color = "weekColorWhite";
            }
            // If Today is between borneInf & borneSup
            else if(today.getTime() >= start.getTime() && today.getTime() <= end.getTime()) {
              week.Color = "weekColorBlue";
            }
            // If Today is after Week borneSup
            else if(today.getTime() > end.getTime()) {
              week.Color = "weekColorOrange";
            }
          }
          else if(week.Completion === 1) {
            week.Color = "weekColorGreen";
          }
          else {
            week.Color = "weekColorRed";
          }
        }
      });
    }

    return { weeks, selectedWeek };
  }

  // Format Date for Timeline calculation
  formatDate(date) {
    let formattedDate;

    if(date) {
      const split = date.split('/');
      const day = split[1];
      const month = split[0]-1; // Between 0 and 11
      const year = split[2];

      formattedDate = new Date(Date.UTC(year, month, day)).toISOString();
    }

    return formattedDate;
  }

  displayViews() {
    Authentication.deleteCookie('displayViews');
    Authentication.createCookie('displayViews', true);

    this.setState({ displayViews: true });
  }

  hideViews() {
    Authentication.deleteCookie('displayViews');
    Authentication.createCookie('displayViews', false);

    this.setState({ displayViews: false });
  }

  changeMonth(month, year) {
    const resourceId = this.state.resourceId;
    const viewId = this.state.viewId;

    // Redirect to TimeTracking Page with new Query params
    if(viewId) {
      this.props.history.push(`/TimeTracking?viewId=${viewId}&year=${year}&month=${month}&resourceId=${resourceId}`);
    }
    else {
      this.props.history.push(`/TimeTracking?year=${year}&month=${month}&resourceId=${resourceId}`);
    }
  }

  changeView(view) {
    const year = this.state.year;
    const month = this.state.month;
    const resourceId = this.state.resourceId;

    // Redirect to TimeTracking Page with new Query params
    this.props.history.push(`/TimeTracking?viewId=${view.ViewId}&year=${year}&month=${month}&resourceId=${resourceId}`);
  }

  setDefaultView(viewId) {
    this.setState({ defaultViewId: viewId });
  }

  async changeFilters(currentView) {
    const login = this.state.login;
    const authId = this.state.authId;
    const itemType = this.state.itemType;
    const resourceId = this.state.resourceId;
    const blockType = this.state.blockType;
    const year = this.state.year;
    const month = this.state.month;

    // Add Modify attribute in CurrentView
    currentView["Modify"] = true;

    // Get Card Data
    await this.getCardData(login, authId, itemType, resourceId, blockType, currentView, year, month);

    // Refresh Component Columns
    if(this.table.current) {
      this.table.current.refreshColumns(currentView);
    }
    else if(this.timetracking.current) {
      this.timetracking.current.refreshColumns(currentView);
    }
  }

  async changeSort(currentView) {
    const login = this.state.login;
    const authId = this.state.authId;
    const itemType = this.state.itemType;
    const resourceId = this.state.resourceId;
    const blockType = this.state.blockType;
    const year = this.state.year;
    const month = this.state.month;

    // Add Modify attribute in CurrentView
    currentView["Modify"] = true;

    // Get Card Data
    await this.getCardData(login, authId, itemType, resourceId, blockType, currentView, year, month);

    // Refresh Component Columns
    if(this.table.current) {
      this.table.current.refreshColumns(currentView);
    }
    else if(this.timetracking.current) {
      this.timetracking.current.refreshColumns(currentView);
    }
  }

  async changeFormattings(currentView) {
    const login = this.state.login;
    const authId = this.state.authId;
    const itemId = this.state.itemId;
    const itemType = this.state.itemType;
    const blockType = this.state.blockType;
    const year = this.state.year;
    const month = this.state.month;

    // Add Modify attribute in CurrentView
    currentView["Modify"] = true;

    // Get Card Data
    await this.getCardData(login, authId, itemId, itemType, blockType, currentView, year, month);

    // Refresh Component Columns
    if(this.table.current) {
      this.table.current.refreshColumns(currentView);
    }
    else if(this.tree.current) {
      this.timetracking.current.refreshColumns(currentView);
    }
  }

  async changePeriod(week) {
    const login = this.state.login;
    const authId = this.state.authId;
    const itemType = this.state.itemType;
    const resourceId = this.state.resourceId;
    const blockType = this.state.blockType;
    const currentView = this.state.currentView;
    const year = this.state.year;
    const month = this.state.month;

    // Get Card Data
    await this.getCardData(login, authId, itemType, resourceId, blockType, currentView, year, month, week.WeekId);

    // Refresh Component Columns
    if(this.timetracking.current) {
      this.timetracking.current.refreshColumns(currentView);
    }
  }

  async changeActiveTasks(activeTasks) {
    const login = this.state.login;
    const authId = this.state.authId;
    const itemType = this.state.itemType;
    const resourceId = this.state.resourceId;
    const blockType = this.state.blockType;
    const currentView = this.state.currentView;
    const year = this.state.year;
    const month = this.state.month;

    // Update Current View Columns list
    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'OnlyActive')) {
      currentView.Parameters.find(param => param.Name === 'OnlyActive').Value = activeTasks;
    }

    // Add Modify attribute in CurrentView
    currentView["Modify"] = true;

    // Get Card Data
    await this.getCardData(login, authId, itemType, resourceId, blockType, currentView, year, month);

    // Refresh Component Columns
    if(this.table.current) {
      this.table.current.refreshColumns(currentView);
    }
    else if(this.timetracking.current) {
      this.timetracking.current.refreshColumns(currentView);
    }
  }

  async changeColumns(columns) {
    const login = this.state.login;
    const authId = this.state.authId;
    const itemType = this.state.itemType;
    const resourceId = this.state.resourceId;
    const blockType = this.state.blockType;
    const year = this.state.year;
    const month = this.state.month;
    // const rows = this.state.rows;
    const currentView = this.state.currentView;

    // Update Current View Columns list
    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Columns')) {
      currentView.Parameters.find(param => param.Name === 'Columns').Value = columns;
    }

    // Add Modify attribute in CurrentView
    currentView["Modify"] = true;

    // Get Card Data
    await this.getCardData(login, authId, itemType, resourceId, blockType, currentView, year, month);

    // Add/Remove Column from Table
    if(this.table.current) {
      this.table.current.refreshColumns(currentView);
    }
    else if(this.timetracking.current) {
      this.timetracking.current.refreshColumns(currentView);
    }

    // this.setState({ currentView });
  }

  filterColumn(columnName) {
    if(this.filters.current) {
      this.filters.current.openFiltersPopup(columnName);
    }
  }

  sortColumn(columnName) {
    if(this.sort.current) {
      this.sort.current.openSortPopup(columnName);
    }
  }

  async updateHolidays(index) {
    const { rows, days, weeks } = this.state;
    let day = days[index];
    let weight;

    // Update Day Dayoff & HalfDayOff
    if(!day.Weekend && !day.BankHoliday) {
      if(day.DayOff) {
        day.DayOff = false;
        day.HalfDayOff = true;
      }
      else if(day.HalfDayOff) {
        day.HalfDayOff = false;
      }
      else {
        day.DayOff = true;
      }
    }

    // Get Day Weight
    if(day.DayOff) {
      weight = 1.0;
    }
    else if(day.HalfDayOff) {
      weight = 0.5;
    }
    else {
      weight = 0;
    }

    // Save Holiday Changes
    await this.saveHolidays(day.Year, day.Month, day.Day, weight);

    // Update Indicators
    if(this.indicators.current) {
      this.indicators.current.refreshIndicators(days, rows, weeks);
    }

    this.setState({ days });
  }

  async saveHolidays(year, month, day, weight) {
    const { login, authId, resourceId } = this.state;
    
    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify([{
        'Year': year,
        'Month': month,
        'Day': day,
        'ResourceId': parseInt(resourceId),
        'Weight': parseFloat(weight)
      }])
    };
    
    try{
      const response = await fetch(API_saveHolidays, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const result = data.SaveHolidayModificationsResult;

      // if(result.IsValid === true) {
        
      // }
      // else {
      //   this.updateErrors(result.Errors);
      // }

    } catch(error) {
      this.setState({ error });
    }
  }

  async checkModificationTTDay(itemId, itemType, columnName, date, oldValue, newValue) {
    const { login, authId, language, month, year } = this.state;
    let additionalContext = [];

    // Get Day of the Month
    const day = new Date(date).getDate();

    if(month && year) {
      additionalContext = [{ "Key": "Day", "Value": day }, { "Key": "Month", "Value": month }, { "Key": "Year", "Value": year }];
    }
    
    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': itemType,
        'ItemId': itemId,
        'ColumnName': columnName,
        'OldValue': oldValue,
        'NewValue': newValue,
        'AdditionalContext': additionalContext
      })
    };

    try{
      const response = await fetch(API_valid, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const results = data.GetPropagationsAndSaveModificationResult;
      
      // Check request response
      if(results) {
        // For Each Modification
        results.forEach(result => {
          // Get Modifications, Propagations & Warnings
          let modification = result.Modification;
          let propagations = result.Propagations;
          let warnings = result.Warnings;

          // If the Modification is valid
          if(result.IsValid === true) {
            // Update Rows in Components DataSource
            if(this.table.current) {
              this.table.current.updateDatasource(modification, propagations, warnings);
            }
            else if(this.timetracking.current) {
              this.timetracking.current.updateDatasource(modification, propagations, warnings);
            }

            // Update Rows in Data Table
            this.updateRowsInTable(modification, propagations, warnings);
          }
          // If the Modification is not valid
          else {
            // Update Datasource with OldValue
            if(this.table.current) {
              this.table.current.cancelModification(modification);
            }
            else if(this.timetracking.current) {
              this.timetracking.current.cancelModification(modification);
            }

            this.updateErrors(result.Errors);
          }
        });
      }
      else {
        this.updateErrors(Traduction.translate(language, 'modification_impossible'));
      }

    } catch(error) {
      console.log(error);
    }
  }

  async checkModificationTTMonth(itemId, itemType, columnName, oldValue, newValue) {
    const { login, authId, language, month, year } = this.state;
    let additionalContext = [];

    if(month && year) {
      additionalContext = [{ "Key": "Month", "Value": month }, { "Key": "Year", "Value": year }];
    }
    
    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': itemType,
        'ItemId': itemId,
        'ColumnName': columnName,
        'OldValue': oldValue,
        'NewValue': newValue,
        'AdditionalContext': additionalContext
      })
    };

    try{
      const response = await fetch(API_valid, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const results = data.GetPropagationsAndSaveModificationResult;
      
      // Check request response
      if(results) {
        // For Each Modification
        results.forEach(result => {
          // Get Modifications, Propagations & Warnings
          let modification = result.Modification;
          let propagations = result.Propagations;
          let warnings = result.Warnings;
          
          // If the Modification is valid
          if(result.IsValid === true) {
            // Update Rows in Components DataSource
            if(this.table.current) {
              this.table.current.updateDatasource(modification, propagations, warnings);
            }
            else if(this.timetracking.current) {
              this.timetracking.current.updateDatasource(modification, propagations, warnings);
            }

            // Update Rows in Data Table
            this.updateRowsInTable(modification, propagations, warnings);
          }
          // If the Modification is not valid
          else {
            // Update Datasource with OldValue
            if(this.table.current) {
              this.table.current.cancelModification(modification);
            }
            else if(this.timetracking.current) {
              this.timetracking.current.cancelModification(modification);
            }

            this.updateErrors(result.Errors);
          }
        });
      }
      else {
        this.updateErrors(Traduction.translate(language, 'modification_impossible'));
      }

    } catch(error) {
      console.log(error);
    }
  }

  updateRowsInTable(modification, propagations, warnings) {
    let rows = this.state.rows;
    const days = this.state.days;
    const weeks = this.state.weeks;
    
    const modificationId = modification.ItemType.substring(0,1).concat(modification.ItemId);
    let columnName = modification.ColumnName;
    let day, month, year;

    if(modification.ColumnName === 'Day_Burned') {
      if(modification.AdditionalContext.find(value => value.Key === 'Day')) {
        day = modification.AdditionalContext.find(value => value.Key === 'Day').Value;
      }
      if(modification.AdditionalContext.find(value => value.Key === 'Month')) {
        month = modification.AdditionalContext.find(value => value.Key === 'Month').Value;
      }
      if(modification.AdditionalContext.find(value => value.Key === 'Year')) {
        year = modification.AdditionalContext.find(value => value.Key === 'Year').Value;
      }

      // Format Date to MM/DD/YYYY
      columnName = new Date(year, month-1, day).toLocaleString("en-US", {year: "numeric", month: "2-digit", day: "2-digit"}).split(' ')[0];
    }

    // Check if we find Row to apply Modification
    const currentRow = rows.find(row => row.Cells.find(cell => cell.ColumnName === 'Item_ID').Value === modificationId);

    if(currentRow) {
      // Check if we find Cell to apply Modification
      const currentCell = currentRow.Cells.find(cell => cell.ColumnName === columnName);

      // Update Row Cell with NewValue
      if(currentCell) {
        currentCell.Value = modification.NewValue;
      }

      // Check if we find Warning Message Column
      const currentWarning = currentRow.Cells.find(cell => cell.ColumnName === 'WarningMessage');

      // Update Warnings
      if(currentWarning) {
        if(warnings.length > 0) {
          currentWarning.Value = warnings.join(' | ');
        }
        else {
          currentWarning.Value = '';
        }
      }
    }

    // Apply Propagations in Rows
    this.applyPropagations(rows, propagations);

    // Update Indicators
    if(this.indicators.current) {
      this.indicators.current.refreshIndicators(days, rows, weeks);
    }

    // Update Week Indicators
    this.updateWeeks(rows);

    this.setState({ rows });
  }

  applyPropagations(rows, propagations) {
    // Update Row with Propagations NewValue
    propagations.forEach(propagation => {
      const propagationId = propagation.ToItemType.substring(0,1).concat(propagation.ToItemId);

      // Check if we find Row to apply Propagation
      const currentRow = rows.find(row => row.Cells.find(cell => cell.ColumnName === 'Item_ID').Value === propagationId);

      // Find Row to apply Propagation and Update with new Value
      if(currentRow) {
        // Check if we find Cell to apply Modification
        const currentCell = currentRow.Cells.find(cell => cell.ColumnName === propagation.ColumnName);

        // Update Row Cell with Propagation
        if(currentCell) {
          currentCell.Value = propagation.NewValue;
        }
      }
    });
  }

  exportCSV() {
    // Export Data from Table component
    if(this.timetracking.current) {
      this.timetracking.current.exportCSV();
    }
    if(this.table.current) {
      this.table.current.exportCSV();
    }
  }

  exportXLSX() {
    // Export Data from Table component
    if(this.timetracking.current) {
      this.timetracking.current.exportXLSX();
    }
    if(this.table.current) {
      this.table.current.exportXLSX();
    }
  }

  searchItem(event) {
    const search = event.target.value;

    // Search Items in Table component
    if(this.timetracking.current) {
      this.timetracking.current.searchItem(search);
    }
    else if(this.table.current) {
      this.table.current.searchItem(search);
    }

    this.setState({ search });
  }

  updateView(view) {
    this.setState({ currentView: view });
  }

  updateWeeks(rows) {
    const weeks = this.state.weeks;

    let selectedWeek, burned_workload, holidays, occupancy, progress;
    let today = new Date();
    let date, start, end;

    // Get selected Week
    if(weeks.find(week => week.IsSelected)) {
      selectedWeek = weeks.find(week => week.IsSelected);
    }

    // Loop through the Weeks
    weeks.forEach(week => {
      // If Week is selected or Display All Weeks
      if(week.WeekId === selectedWeek.WeekId || selectedWeek.WeekId === 0) {
        // Get Week Start & End Date
        start = new Date(this.formatDate(week.borneInf));
        end = new Date(this.formatDate(week.borneSup));

        // Set the same Hours/Minutes/Seconds/Milliseconds to compare Dates
        start.setHours(0,0,0,0);
        end.setHours(0,0,0,0);
        today.setHours(0,0,0,0);

        burned_workload = 0;
        holidays = 0;
        occupancy = 0;
        progress = 0;

        rows.forEach(row => {
          row.Cells.forEach(cell => {
            if(cell.FieldType === 'Day' && cell.Value && cell.Value > 0) {
              // Get Cell Date
              date = new Date(this.formatDate(cell.ColumnName));

              // Set the same Hours/Minutes/Seconds/Milliseconds to compare Dates
              date.setHours(0,0,0,0);

              // If Cell Date is between Week borneInf & borneSup
              if((date.getTime() >= start.getTime() && date.getTime() <= end.getTime())) {
                // Holidays
                if(row.ItemType === 'Holiday') {
                  holidays += parseFloat(cell.Value);
                }
                // Burned Workload
                else if(row.ItemType === 'Task') {
                  burned_workload += parseFloat(cell.Value);
                }
              }
            }
          });
        });

        // Round Burned Workload
        burned_workload = parseFloat(burned_workload.toPrecision(3));

        // Occupancy
        occupancy = parseFloat((week.OpenDays - holidays - burned_workload).toPrecision(4));

        // Progress
        if(week.OpenDays !== 0) {
          progress = (burned_workload + holidays) / (week.OpenDays - week.BankHolidays);
          progress = parseFloat(progress.toPrecision(3));
          // progress = parseInt(progress.toFixed(0));
        }
        else {
          if(burned_workload > 0) {
            progress = 2;
          }
          else {
            progress = 1;
          }
        }

        // Update Week Completion, Burned & Holidays
        week.Completion = progress;
        week.Burned_Workload = burned_workload;
        week.Holidays = holidays;

        // Define Weeks Color
        if(week.WeekId === 0) {
          week.Color = "displayAllWeeks";
        }
        else {
          if(week.Completion < 1) {
            // If Today is before Week borneInf
            if(today.getTime() < start.getTime()) {
              week.Color = "weekColorWhite";
            }
            // If Today is between borneInf & borneSup
            else if(today.getTime() >= start.getTime() && today.getTime() <= end.getTime()) {
              week.Color = "weekColorBlue";
            }
            // If Today is after Week borneSup
            else if(today.getTime() > end.getTime()) {
              week.Color = "weekColorOrange";
            }
          }
          else if(week.Completion === 1) {
            week.Color = "weekColorGreen";
          }
          else {
            week.Color = "weekColorRed";
          }
        }
      }
    });

    this.setState({ weeks });
  }

  updateTable(rows) {
    const days = this.state.days;
    const weeks = this.state.weeks;

    // Update Indicators
    if(this.indicators.current) {
      this.indicators.current.refreshIndicators(days, rows, weeks);
    }

    // Update Weeks
    this.updateWeeks(rows);
  }

  updateErrors(err) {
    let errors = [];

    // Push the new Errors in the Errors Table
    errors.push(err);

    this.setState({ errors });
  }

  cleanErrors() {
    this.setState({ errors: [] });
  }

  render() {
    let { language, itemId, itemType, itemTitle, resourceId, blockType, blockContent, editable, guestLicence, month, year, timetrackingType, displayViews, views, defaultViewId, currentView, days, weeks, columns, rows, activeTasks, selectedWeek, search, isLoading, errors } = this.state;
    let onlyActive;

    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'OnlyActive')) {
      onlyActive = currentView.Parameters.find(param => param.Name === 'OnlyActive').Value;
    }

    return (
      <div className="blockContainer">
        {/* Title */}
        <MetaTags><title>{Traduction.translate(language, 'timesheet')}</title></MetaTags>

        {/* Navbar */}
        <Navbar Selected={this.props.match.url}></Navbar>

        {/* Block */}
        <div className="block">
          {/* TimeTracking Block Header */}
          <div className="blockHeader row">
            {/* Resource Title */}
            <BlockTitle ItemId={resourceId} ItemType={itemType} ItemTitle={itemTitle} BlockType={blockType} CurrentView={currentView}></BlockTitle>
            {/* Timesheet */}
            <div className="flex align-items-center">
              <span className="fs16 pt4 mh10">{Traduction.translate(language, 'timesheet')} {Traduction.translate(language, 'of')}</span>
              <TimetrackingMonth ResourceId={resourceId} Month={month} Year={year} onMonthChange={this.changeMonth}></TimetrackingMonth>
            </div>
          </div>
          
          {/* Indicators */}
          {timetrackingType !== 'None' && <IndicatorsTimeTracking ref={this.indicators} TimetrackingType={timetrackingType} Days={days} Weeks={weeks} Columns={columns} Rows={rows}></IndicatorsTimeTracking>}

          {/* Timetracking Block Body */}
          <div className="blockBodyTimetracking">
            {/* ----- TimeTracking Day ----- */}
            {timetrackingType === 'Day' && <div className="blockTimetrackingDay">
              {/* Weeks */}
              <div className="timetrackingWeeks">
                {weeks.map((week, index) => 
                  <div key={index} className="grid">
                    {/* Week Label & Progress */}
                    <div className={week.Color + (week.WeekId !== selectedWeek.WeekId ? " timetrackingWeek " : " timetrackingCurrentWeek ") + "align-items-center cursor"} onClick={(e) => this.changePeriod(week)}>
                      <span className="center">{week.WeekLabel}</span>
                      {week.WeekId !== 0 && <span className="bold center">{(week.Completion * 100).toFixed(0)} %</span>}
                    </div>
                    <div className={(week.WeekId === selectedWeek.WeekId ? "timetrackingSelectedWeek" : "timetrackingNotSelectedWeek")}></div>
                  </div>
                )}
              </div>

              <div className="timetrackingDayContent">
                {/* Filters Views */}
                {displayViews && <FiltersView ref={this.views} ItemId={resourceId} ItemType={itemType} BlockType={'TimeTrackingDay'} GuestLicence={guestLicence} DefaultViewId={defaultViewId} CurrentView={currentView} Views={views} onViewsHide={this.hideViews} onViewChange={this.changeView} onSetDefaultView={this.setDefaultView} onErrorsUpdate={this.updateErrors}></FiltersView>}

                {/* Card Block Content */}
                <div className={!displayViews ? "blockContentTimetracking" : "blockContentTimetrackingViews"}>
                  {/* Filters */}
                  <div className="blockFilters">
                    {/* Current View */}
                    {!displayViews && <FiltersCurrentView ItemId={itemId} ItemType={itemType} BlockType={blockType} CurrentView={currentView} onDisplayViews={this.displayViews} onErrorsUpdate={this.updateErrors}></FiltersCurrentView>}

                    <div className="filtersTasksTimetracking">
                      <div className={"cursor mh5 " + (onlyActive === 'False' ? "timetrackingAllTasks" : "")} onClick={(e) => this.changeActiveTasks('False')}>{Traduction.translate(language, 'all_tasks')}</div>
                      <div className={"cursor mh5 " + (onlyActive === 'True' ? "timetrackingOnlyActive" : "")} onClick={(e) => this.changeActiveTasks('True')}>{Traduction.translate(language, 'only_active_tasks')}</div>
                    </div>

                    {/* Block Filters */}
                    <FiltersBlock ref={this.filters} ItemId={resourceId} ItemType={itemType} BlockType={blockType} CurrentView={currentView} onFiltersChange={this.changeFilters}></FiltersBlock>

                    {/* Sort Filters */}
                    <FiltersSort ref={this.sort} ItemId={resourceId} ItemType={itemType} BlockType={blockType} CurrentView={currentView} onSortChange={this.changeSort}></FiltersSort>

                    {/* Conditional Formatting Filters */}
                    <FiltersConditionalFormatting ref={this.formattings} ItemId={itemId} ItemType={itemType} BlockType={blockType} CurrentView={currentView} onFormattingsChange={this.changeFormattings}></FiltersConditionalFormatting>

                    {/* Columns Chooser */}
                    {(currentView.ViewType === 0 || currentView.ViewType === 1) && 
                      <FiltersColumnChooser ItemId={resourceId} ItemType={itemType} BlockType={'Roadmap'} Columns={columns} CurrentView={currentView} onColumnsChange={this.changeColumns}></FiltersColumnChooser>
                    }

                    {/* Search input */}
                    {currentView.ViewType !== 3 && 
                      <div className="filtersSearch">
                        <Form.Group className="searchBlock">
                          <Form.Control type="text" id="searchFilters" name="search" value={search} placeholder={Traduction.translate(language, 'search')} onChange={this.searchItem} />
                        </Form.Group>
                      </div>
                    }

                    {/* Export */}
                    {/* <FiltersExport onCSVExport={this.exportCSV} onXLSXExport={this.exportXLSX} onErrorsUpdate={this.updateErrors}></FiltersExport> */}
                  </div>

                  {/* Card Block Component */}
                  <div className="blockComponent">
                    {/* Errors */}
                    {errors.length > 0 && 
                      <ErrorModification Errors={errors} Open={true} onErrorsClean={this.cleanErrors}></ErrorModification>
                    }

                    {/* Loading Spinner */}
                    {isLoading && <div className="center mt30 mb20">
                      <span className=""><LoadingSpinner></LoadingSpinner></span>
                      <span className="bold ml30">{Traduction.translate(language, 'data_loading')}</span>
                    </div>}

                    {/* TimeTracking Table */}
                    {columns && rows && <TableTimeTracking ref={this.timetracking} ItemId={resourceId} ItemType={itemType} Title={itemTitle} BlockType={blockType} GuestLicence={guestLicence} CurrentView={currentView} Columns={columns} Rows={rows} Week={selectedWeek} Month={month} Year={year} onViewChange={this.updateView} onColumnsFilter={this.filterColumn} onColumnsSort={this.sortColumn} onColumnSort={this.changeSort} onTableChange={this.updateTable} onTableUpdate={this.checkModificationTTDay}></TableTimeTracking>}

                    {/* Legend */}
                    <div className="mt20 mb20">
                      <div className="mv10 fs11 grey">
                        <span className="fs12 blue bold">XX : </span>
                        <span className="">{Traduction.translate(language, 'task_workload_corresponding')}</span>
                      </div>
                      <div className="mv10 fs11 grey">
                        <span className="iconsCard iconCircle bg-grey"></span>
                        <span className="ml10">{Traduction.translate(language, 'open_day')}</span>
                        <span className="iconsCard iconFullCircle bg-smoke-grey ml20"></span>
                        <span className="ml10">{Traduction.translate(language, 'week_end')}</span>
                        <span className="iconsCard iconFullCircle bg-dark-grey ml20"></span>
                        <span className="ml10">{Traduction.translate(language, 'bank_holiday')}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>}

            {/* ----- TimeTracking Month ----- */}
            {timetrackingType === 'Month' && <div className="blockTimetrackingMonth">
              {/* Holidays and Days off */}
              <div className="timetrackingHolidays">
                {/* Title */}
                <div className="flex align-items-center">
                  <span className="iconHolidays icons mh10"></span>
                  <span className="fs14 turquoise bold">{Traduction.translate(language, 'your_days_off')}</span>
                </div>
                {/* Holidays / Days off */}
                <div className="holidaysList">{this.buildDays(days)}</div>
              </div>

              <div className="timetrackingMonthContent">
                {/* Filters Views */}
                {displayViews && <FiltersView ref={this.views} ItemId={resourceId} ItemType={itemType} BlockType={'TimeTrackingMonth'} GuestLicence={guestLicence} DefaultViewId={defaultViewId} CurrentView={currentView} Views={views} onViewsHide={this.hideViews} onViewChange={this.changeView} onSetDefaultView={this.setDefaultView} onErrorsUpdate={this.updateErrors}></FiltersView>}

                {/* Card Block Content */}
                <div className={!displayViews ? "blockContentTimetracking" : "blockContentTimetrackingViews"}>
                  {/* Filters */}
                  <div className="blockFilters">
                    {/* Current View */}
                    {!displayViews && <FiltersCurrentView ItemId={itemId} ItemType={itemType} BlockType={blockType} CurrentView={currentView} onDisplayViews={this.displayViews} onErrorsUpdate={this.updateErrors}></FiltersCurrentView>}

                    {/* Block Filters */}
                    <FiltersBlock ref={this.filters} ItemId={resourceId} ItemType={itemType} BlockType={blockType} CurrentView={currentView} onFiltersChange={this.changeFilters}></FiltersBlock>

                    {/* Sort Filters */}
                    <FiltersSort ref={this.sort} ItemId={resourceId} ItemType={itemType} BlockType={blockType} CurrentView={currentView} onSortChange={this.changeSort}></FiltersSort>

                    {/* Conditional Formatting Filters */}
                    <FiltersConditionalFormatting ref={this.formattings} ItemId={itemId} ItemType={itemType} BlockType={blockType} CurrentView={currentView} onFormattingsChange={this.changeFormattings}></FiltersConditionalFormatting>

                    {/* Columns Chooser */}
                    {(currentView.ViewType === 0 || currentView.ViewType === 1) && 
                      <FiltersColumnChooser ItemId={resourceId} ItemType={itemType} BlockType={'Roadmap'} Columns={columns} CurrentView={currentView} onColumnsChange={this.changeColumns}></FiltersColumnChooser>
                    }

                    {/* Search input */}
                    {currentView.ViewType !== 3 && 
                      <div className="filtersSearch">
                        <Form.Group className="searchBlock">
                          <Form.Control type="text" id="searchFilters" name="search" value={search} placeholder={Traduction.translate(language, 'search')} onChange={this.searchItem} />
                        </Form.Group>
                      </div>
                    }

                    {/* Export */}
                    <FiltersExport ItemId={resourceId} ItemType={itemType} BlockType={blockType} GuestLicence={guestLicence} CurrentView={currentView} onCSVExport={this.exportCSV} onXLSXExport={this.exportXLSX} onErrorsUpdate={this.updateErrors}></FiltersExport>
                  </div>

                  {/* Card Block Component */}
                  <div className="blockComponent">
                    {/* Errors */}
                    {errors.length > 0 && 
                      <ErrorModification Errors={errors} Open={true} onErrorsClean={this.cleanErrors}></ErrorModification>
                    }

                    {/* Loading Spinner */}
                    {isLoading && <div className="center mt30 mb20">
                      <span className=""><LoadingSpinner></LoadingSpinner></span>
                      <span className="bold ml30">{Traduction.translate(language, 'data_loading')}</span>
                    </div>}

                    {/* TimeTracking Table */}
                    {currentView.ViewType === 0 && currentView.DefaultLevel !== 0 && rows.length > 0 && <Table ref={this.table} ItemId={resourceId} ItemType={itemType} Title={itemTitle} BlockType={blockType} Editable={true} GuestLicence={guestLicence} CurrentView={currentView} Columns={columns} Rows={rows} Month={month} Year={year} onViewChange={this.updateView} onColumnsFilter={this.filterColumn} onColumnsSort={this.sortColumn} onColumnSort={this.changeSort} onTableUpdate={this.checkModificationTTMonth}></Table>}
                  </div>
                </div>
              </div>
            </div>}

            {/* Empty Block */}
            {/* {!isLoading && (rows && rows.length === 0) && 
              <div className="mt20 pb20 line-height25">
                <span className="row bold">{Traduction.translate(language, 'no_timetracking_tasks')}</span>
                <span className="row">{Traduction.translate(language, 'find_timetracking_tasks')}</span>
                <span className="row">{Traduction.translate(language, 'assign_timetracking_tasks')}</span>
              </div>
            } */}
          </div>
        </div>
      </div>
    )
  }
}

export default TimeTracking;