import React, {Component} from 'react';
import { Button, Form } from 'react-bootstrap';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';

class PopoverEditMapIndicator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: null,
      itemId: null,
      itemType: null,
      index: null,
      mapIndicator: null,
      availableFields: [],
      search: ''
    };

    this.searchItem = this.searchItem.bind(this);
    this.pressKey = this.pressKey.bind(this);
    this.addMapIndicator = this.addMapIndicator.bind(this);
    this.updateMapIndicator = this.updateMapIndicator.bind(this);

    this.search = React.createRef();
  }

  componentDidMount() {
    const language = Authentication.getCookie('language');
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const index = this.props.Index;
    const mapIndicator = this.props.MapIndicator;
    const availableFields = this.props.AvailableFields;

    // Search Auto focus
    setTimeout(() => { this.search.current.focus() }, 1);

    this.setState({ language, itemId, itemType, index, mapIndicator, availableFields });
  }

  componentDidUpdate(prevProps) {
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const index = this.props.Index;
    const mapIndicator = this.props.MapIndicator;
    const availableFields = this.props.AvailableFields;
  }

  searchItem(event) {
    const search = event.target.value;

    this.setState({ search });
  }

  pressKey(event) {
    const index = this.state.index;
    const availableFields = this.state.availableFields;
    const search = event.target.value;
    let mapIndicator;

    if(event.key === 'Enter' && search !== '') {
      if(availableFields.find(value => value.Label.toLowerCase().includes(search.toLowerCase()))) {
        mapIndicator = availableFields.find(value => value.Label.toLowerCase().includes(search.toLowerCase()));

        // Close Popover
        if(document.getElementById("popover-basic")) {
          document.getElementById("popover-basic").classList.remove("show");
        }

        // Call the event from the Parent component through the props
        this.props.onMapIndicatorEdit(mapIndicator, index);

        this.setState({ mapIndicator });
      }
    }
  }

  addMapIndicator(mapIndicator) {
    // Close Popover
    if(document.getElementById("popover-basic")) {
      document.getElementById("popover-basic").classList.remove("show");
    }

    // Call the event from the Parent component through the props
    this.props.onMapIndicatorAdd(mapIndicator);
  }

  updateMapIndicator(mapIndicator) {
    const index = this.state.index;
    
    // Close Popover
    if(document.getElementById("popover-basic")) {
      document.getElementById("popover-basic").classList.remove("show");
    }

    // Call the event from the Parent component through the props
    this.props.onMapIndicatorEdit(mapIndicator, index);
  }

  render() {
    const { language, itemId, itemType, index, mapIndicator, availableFields, search } = this.state;

    return (
      <div className="chartTypeEditPopover">
        {/* Search */}
        <div className="chartTypeEditSearch">
          <Form.Group className="searchBlock">
            <Form.Control type="text" id="searchFilters" name="search" value={search} placeholder={Traduction.translate(language, 'search')} autoFocus onChange={this.searchItem} onKeyPress={this.pressKey} ref={this.search}/>
          </Form.Group>
        </div>
        {/* Map Indicator */}
        <div className="chartTypeEditValues scrollbar-y">
          {/* Current Value */}
          {mapIndicator && <div className="chartTypeCurrentValue">{mapIndicator.Label}</div>}
          {/* Border */}
          <div className="popoverBorder"></div>
          {/* Other Values */}
          {availableFields.map((field, index) => {
            if(mapIndicator && mapIndicator.Id !== field.Id) {
              if(search === "" || field.Label.toLowerCase().includes(search.toLowerCase())) {
                return <div key={index} className="chartTypeEditValue" onClick={() => this.updateMapIndicator(field)}>
                  <span className="">{field.Label}</span>
                </div>;
              }
            }
            // else {
            //   if(search === "" || field.Label.toLowerCase().includes(search.toLowerCase())) {
            //     return <div key={index} className="chartTypeEditValue" onClick={() => this.addMapIndicator(field)}>
            //       <span className="">{field.Label}</span>
            //     </div>;
            //   }
            // }
          })}
        </div>
      </div>
    );
  }
}

export default PopoverEditMapIndicator;