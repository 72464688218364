import React, {Component} from 'react';
import { getObject } from '@syncfusion/ej2-grids';
import { TreeGridComponent, ColumnDirective, ColumnsDirective, ColumnMenu } from '@syncfusion/ej2-react-treegrid';
import { Inject, ContextMenu, Freeze, ExcelExport } from '@syncfusion/ej2-react-treegrid';
import '../Css/App.css';
import Authentication from '../Authentication';
// import ErrorModification from './ErrorModification';
import PopupInformation from './PopupInformation';

class TableHolidays extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authId: null,
      itemId: null,
      itemType: null,
      itemTitle: null,
      blockType: null,
      guestLicence: null,
      columns: [],
      rows: [],
      dataSource: [],
      month: null,
      year: null,
      info: false,
      informationMessage: null,
      modificationErrors: [],
      error: {}
    };

    this.tableClicked = false;

    // Table Structure
    this.created = this.created.bind(this);
    this.dataStructure = this.dataStructure.bind(this);
    this.dataBound = this.dataBound.bind(this);
    this.rowDataBound = this.rowDataBound.bind(this);
    this.rowSelected = this.rowSelected.bind(this);
    this.toolbarClick = this.toolbarClick.bind(this);
    this.contextMenuOpen = this.contextMenuOpen.bind(this);
    this.contextMenuClick = this.contextMenuClick.bind(this);
    this.showColumns = this.showColumns.bind(this);
    this.hideColumns = this.hideColumns.bind(this);
    this.getColumns = this.getColumns.bind(this);
    this.getColumnChooser = this.getColumnChooser.bind(this);
    this.getColumnEditable = this.getColumnEditable.bind(this);
    this.getColumnLabel = this.getColumnLabel.bind(this);
    this.getColumnType = this.getColumnType.bind(this);
    this.getColumnVisible = this.getColumnVisible.bind(this);

    // Actions
    this.actionBegin = this.actionBegin.bind(this);
    this.actionComplete = this.actionComplete.bind(this);
    this.onHolidayClick = this.onHolidayClick.bind(this);
    this.onHolidayDoubleClick = this.onHolidayDoubleClick.bind(this);
    this.refreshColumns = this.refreshColumns.bind(this);
    this.updateDatasource = this.updateDatasource.bind(this);
    this.excelQueryCellInfo = this.excelQueryCellInfo.bind(this);
    this.exportCSV = this.exportCSV.bind(this);
    this.exportXLSX = this.exportXLSX.bind(this);
    this.validateMessage = this.validateMessage.bind(this);
    this.updateErrors = this.updateErrors.bind(this);
    // this.cleanErrors = this.cleanErrors.bind(this);

    // Template Functions
    this.templateHeaderDay = this.templateHeaderDay;
    this.templateName = this.templateName;
    this.templateHoliday = this.templateHoliday;
    this.templateBankHoliday = this.templateBankHoliday;
    this.templateWeekend = this.templateWeekend;

    this.errors = React.createRef();
  }
  
  componentDidMount() {
    const authId = Authentication.getCookie('authId');
    const resourceId = this.props.ResourceId;
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const itemTitle = this.props.Title;
    const blockType = this.props.BlockType;
    const guestLicence = this.props.GuestLicence;
    const columns = this.props.Columns;
    const rows = this.props.Rows;
    const month = this.props.Month;
    const year = this.props.Year;

    // Build Table Datasource
    let dataSource = this.dataStructure(rows);

    this.setState({ authId, resourceId, itemId, itemType, itemTitle, blockType, guestLicence, columns, rows, dataSource, month, year });
  }

  componentDidUpdate(prevProps) {
    const resourceId = this.props.ResourceId;
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const itemTitle = this.props.Title;
    const blockType = this.props.BlockType;
    const guestLicence = this.props.GuestLicence;
    const columns = this.props.Columns;
    const rows = this.props.Rows;
    const month = this.props.Month;
    const year = this.props.Year;

    if(this.props.ItemId !== prevProps.ItemId || this.props.ItemType !== prevProps.ItemType || this.props.Title !== prevProps.Title || this.props.BlockType !== prevProps.BlockType) {
      this.setState({ itemId, itemType, itemTitle, blockType });
    }
    if(this.props.GuestLicence !== prevProps.GuestLicence) {
      this.setState({ guestLicence });
    }
    if(this.props.Columns !== prevProps.Columns || this.props.Rows !== prevProps.Rows) {
      // Build Table Datasource
      let dataSource = this.dataStructure(rows);

      this.setState({ columns, rows, dataSource });
    }
    if(this.props.Month !== prevProps.Month || this.props.Year !== prevProps.Year) {
      this.setState({ month, year });
    }
  }

  created() {
    const columns = this.getColumns();

    // Auto Generated Columns
    if(this.grid) {
      // Clean Grid columns
      this.grid.columns = [];

      // Clean Grid aggregate
      // this.grid.aggregates = [];

      // Loop through the columns to build and add them to the Grid
      for(let i=0; i < columns.length; i++) {
        let type, header, headerTextAlign, template, primaryKey, width;

        // Type
        if(columns[i].FieldType === 'String') {
          type = 'string';
        }
        else if(columns[i].FieldType === 'Double' || columns[i].FieldType === 'OpenDay' || columns[i].FieldType === 'BankHoliday' || columns[i].FieldType === 'Weekend') {
          type = 'number';
        }
        else if(columns[i].FieldType === 'Date') {
          type = 'date';
        }
        else {
          type = null;
        }

        // Header
        if(columns[i].FieldType === 'OpenDay' || columns[i].FieldType === 'BankHoliday' || columns[i].FieldType === 'Weekend') {
          header = this.templateHeaderDay;
        }

        // Header Text Align
        if(columns[i].FieldType === 'OpenDay' || columns[i].FieldType === 'BankHoliday' || columns[i].FieldType === 'Weekend') {
          headerTextAlign = 'center';
        }
        else {
          headerTextAlign = 'left';
        }

        // Template
        if(columns[i].FieldName === 'Name') {
          template = this.templateName;
        }
        else if(columns[i].FieldType === 'OpenDay') {
          template = this.templateHoliday;
        }
        // else if(columns[i].FieldType === 'BankHoliday') {
        //   template = this.templateBankHoliday;
        // }
        else if(columns[i].FieldType === 'Weekend') {
          template = this.templateWeekend;
        }

        // Primary Key
        if(columns[i].FieldName === 'Item_ID') {
          primaryKey = true;
        }
        else {
          primaryKey = false;
        }

        // Width
        if(columns[i].FieldName === 'Name') {
          width = 240;
        }
        else if(columns[i].FieldType === 'OpenDay' || columns[i].FieldType === 'BankHoliday') {
          width = 55;
        }
        else if(columns[i].FieldType === 'Weekend') {
          width = 55;
        }
        
        // Define Column object for Grid
        var colObj = {
          allowEditing: this.getColumnEditable(columns[i].FieldName),
          field: columns[i].FieldName,
          headerText: this.getColumnLabel(columns[i].FieldName),
          headerTextAlign: headerTextAlign,
          headerTemplate: header,
          isPrimaryKey: primaryKey,
          lockColumn: false,
          showInColumnChooser: false,
          // showInColumnChooser: this.getColumnChooser(columns[i].FieldName),
          template: template,
          type: type,
          visible: this.getColumnVisible(columns[i].FieldName),
          width: width
        };

        // Add the Column in the Grid
        this.grid.columns.push(colObj);
      }

      this.grid.refreshColumns();
    }
  }

  dataStructure(rows) {
    let dataSource = [];

    if(rows.length > 0) {
      dataSource = rows.map(row => {
        return row.Cells.reduce((acc, item) => {
          // Format Dates
          if(item.FieldType === 'Date' && item.Value) {
            acc[item.ColumnName] = new Date(item.Value);
          }
          // Format Numbers
          else if((item.FieldType === 'Double' || item.FieldType === 'Day') && item.Value) {
            acc[item.ColumnName] = parseFloat(item.Value);
          }
          // Add the Column Name/Values to the reduced Table
          // Exclude Parent_ID for Table
          else if(item.ColumnName !== 'Parent_ID') {
            acc[item.ColumnName] = item.Value;
          }
          
          return acc;
        }, {});
      });
    }

    return dataSource;
  }

  dataBound(args) {
    if(this.grid) {
      // Autofit Columns width
      // this.grid.autoFitColumns();

      // Table Event Listener
      if(!this.tableClicked) {
        this.grid.element.addEventListener('click', this.onHolidayClick);
        this.grid.element.addEventListener('dblclick', this.onHolidayDoubleClick);
        this.tableClicked = true;
      }
    }
  }

  // Row Formatting design
  rowDataBound(args) {
    // Define styles for Objects rows
    if(getObject('Item_Type', args.data) === 'Resource') {
      args.rowHeight = 30;
      args.row.style.fontWeight = '100';
    }
  }

  rowSelected(args) {
    // console.log(args);
  }

  // Toolbar Click Events
  toolbarClick(args) {
    // console.log(args);
  }

  contextMenuOpen(args) {
    // console.log(args);
  }

  // Right Click Events
  contextMenuClick(args) {
    // console.log(args);
  }

  showColumns(columnName) {
    if(this.grid) {
      this.grid.showColumns([columnName]);
    }
  }

  hideColumns(columnName) {
    if(this.grid) {
      this.grid.hideColumns([columnName]);
    }
  }

  getColumns() {
    return this.state.columns;
  }

  getColumnChooser(columnName) {
    const columns = this.state.columns;
    let chooser = false;

    if(columns.find(column => column.FieldName === columnName)) {
      chooser = columns.find(column => column.FieldName === columnName).Visible;
    }

    return chooser;
  }

  getColumnEditable(columnName) {
    const columns = this.state.columns;
    let editionItems = "";

    if(columns.find(column => column.FieldName === columnName).EditionItemTypes) {
      editionItems = columns.find(column => column.FieldName === columnName).EditionItemTypes;
    }

    if(editionItems.includes('Resource')) {
      return true;
    }
    else {
      return false;
    }
  }

  getColumnLabel(columnName) {
    const columns = this.state.columns;
    let label = "";

    if(columns.find(column => column.FieldName === columnName)) {
      label = columns.find(column => column.FieldName === columnName).Label;
    }

    if(columnName === 'WarningMessage') {
      label = 'W';
    }
    
    return label;
  }

  getColumnType(columnName) {
    const columns = this.state.columns;

    if(columns.find(column => column.FieldName === columnName)) {
      return columns.find(column => column.FieldName === columnName).FieldType;
    }
    else {
      return null;
    }
  }

  getColumnVisible(columnName) {
    const columns = this.state.columns;
    let editionItemTypes = "";

    if(columns.find(column => column.FieldName === columnName)) {
      editionItemTypes = columns.find(column => column.FieldName === columnName).VisibleItemTypes;
    }

    if(editionItemTypes.includes('Resource')) {
      return true;
    }
    else {
      return false;
    }
  }

  actionBegin(args) {
    // console.log(args);
  }

  actionComplete(args) {
    // console.log(args);
  }

  onHolidayClick(args) {
    const guestLicence = this.state.guestLicence;

    if(!guestLicence) {
      // Click on Table element e-rowcell
      if(args.target.closest('.e-rowcell')) {
        const classList = args.target.classList;
        const field = args.target.id;

        const resourceId = field.split(',')[0];
        const date = field.split(',')[1];
        let value;
        
        // Check if Click on Holiday element
        if(classList.contains('iconsTimeTrackingHoliday')) {
          // Get Holiday new value
          if(classList.contains('iconFullCircle')) {
            value = 0.5;
          }
          else if(classList.contains('iconHalfCircle')) {
            value = 0;
          }
          else if(classList.contains('iconCircle')) {
            value = 1;
          }

          // Call the event from the Parent component through the props
          this.props.onTableUpdate(resourceId, date, value);

          // Save Holiday
          // this.saveHolidays(resourceId, date, value);
        }

        this.tableClicked = false;
      }
    }
  }

  onHolidayDoubleClick(args) {
    const guestLicence = this.state.guestLicence;

    if(!guestLicence) {
      // Double Click on Table element
      if(args.target) {
        const classList = args.target.classList;

        try{
          // Check if Double Click on Holiday element
          if(classList.contains('e-rowcell') || classList.contains('iconsTimeTrackingHoliday')) {
            this.grid.closeEdit();
            this.grid.endEdit();
          }
          // Check if Double Click on Weekend Holiday element
          else if(classList.contains('e-rowcell') || classList.contains('iconsTimeTrackingWeekendHoliday')) {
            this.grid.closeEdit();
            this.grid.endEdit();
          }

          this.tableClicked = false;
        }
        catch(e) {
          
        }
      }
    }
  }

  refreshColumns() {
    const columns = this.getColumns();

    // Auto Generated Columns
    if(this.grid) {
      // Clean Grid columns
      this.grid.columns = [];

      // Clean Grid aggregate
      // this.grid.aggregates = [];

      // Loop through the columns to build and add them to the Grid
      for(let i=0; i < columns.length; i++) {
        let type, header, headerTextAlign, template, primaryKey, width;

        // Type
        if(columns[i].FieldType === 'String') {
          type = 'string';
        }
        else if(columns[i].FieldType === 'Double' || columns[i].FieldType === 'OpenDay' || columns[i].FieldType === 'BankHoliday' || columns[i].FieldType === 'Weekend') {
          type = 'number';
        }
        else if(columns[i].FieldType === 'Date') {
          type = 'date';
        }
        else {
          type = null;
        }

        // Header
        if(columns[i].FieldType === 'OpenDay' || columns[i].FieldType === 'BankHoliday' || columns[i].FieldType === 'Weekend') {
          header = this.templateHeaderDay;
        }

        // Header Text Align
        if(columns[i].FieldType === 'OpenDay' || columns[i].FieldType === 'BankHoliday' || columns[i].FieldType === 'Weekend') {
          headerTextAlign = 'center';
        }
        else {
          headerTextAlign = 'left';
        }

        // Template
        if(columns[i].FieldName === 'Name') {
          template = this.templateName;
        }
        else if(columns[i].FieldType === 'OpenDay') {
          template = this.templateHoliday;
        }
        // else if(columns[i].FieldType === 'BankHoliday') {
        //   template = this.templateBankHoliday;
        // }
        else if(columns[i].FieldType === 'Weekend') {
          template = this.templateWeekend;
        }

        // Primary Key
        if(columns[i].FieldName === 'Item_ID') {
          primaryKey = true;
        }
        else {
          primaryKey = false;
        }

        // Width
        if(columns[i].FieldName === 'Name') {
          width = 240;
        }
        else if(columns[i].FieldType === 'OpenDay' || columns[i].FieldType === 'BankHoliday') {
          width = 55;
        }
        else if(columns[i].FieldType === 'Weekend') {
          width = 55;
        }
        
        // Define Column object for Grid
        var colObj = {
          allowEditing: this.getColumnEditable(columns[i].FieldName),
          field: columns[i].FieldName,
          headerText: this.getColumnLabel(columns[i].FieldName),
          headerTextAlign: headerTextAlign,
          headerTemplate: header,
          isPrimaryKey: primaryKey,
          lockColumn: false,
          showInColumnChooser: false,
          // showInColumnChooser: this.getColumnChooser(columns[i].FieldName),
          template: template,
          type: type,
          visible: this.getColumnVisible(columns[i].FieldName),
          width: width
        };

        // Add the Column in the Grid
        this.grid.columns.push(colObj);
      }

      this.grid.refreshColumns();
    }
  }

  updateDatasource(resourceId, date, value) {
    // Clone Datasource
    let dataSource = JSON.parse(JSON.stringify(this.grid.dataSource));

    // Check if we find Row to apply Modification
    const currentRow = dataSource.find(row => row['Resource_ID'] === resourceId);

    if(currentRow) {
      currentRow[date] = value;
    }

    // Update Datasource
    this.grid.dataSource = dataSource;
  }

  // Format Cells value for Export
  excelQueryCellInfo(args) {
    // Bank Holiday
    if(args.value == -1) {
      args.value = '';
      args.style = { backColor: '#777777' };
    }
    // Day Off
    else if(args.value == 1) {
      args.style = { backColor: '#26A599', fontColor: '#FFFFFF' };
    }
    // Half Day Off
    else if(args.value == 0.5) {
      args.style = { backColor: '#26A599', fontColor: '#FFFFFF' };
    }
    // Weekend
    else if(args.column.template && args.column.template.name === 'templateWeekend') {
      args.style = { backColor: '#CECCCC' };
    }
    // Ressource Name
    else if(args.column.field === 'Name') {
      args.value = args.value;
    }
  }

  exportCSV() {
    const itemTitle = this.state.itemTitle;
    const blockType = this.state.blockType;
    const fileName = "Export " + blockType + " " + itemTitle + ".csv";

    if(this.grid) {
      // Define Export File Name
      let csvExportProperties = { fileName: fileName };

      this.grid.csvExport(csvExportProperties);
    }
  }

  exportXLSX() {
    const itemTitle = this.state.itemTitle;
    const blockType = this.state.blockType;
    const fileName = "Export " + blockType + " " + itemTitle + ".xlsx";

    if(this.grid) {
      // Define Export File Name
      let excelExportProperties = { fileName: fileName };
      
      this.grid.excelExport(excelExportProperties);
    }
  }

  validateMessage() {
    this.setState({ info: false });
  }

  updateErrors(error) {
    // Display Popup with Error message
    this.setState({ info: true, informationMessage: error });

    // let modificationErrors = this.state.modificationErrors;

    // Push the new Errors in the Errors Table
    // modificationErrors.push(error);

    // this.setState({ modificationErrors });
  }

  // cleanErrors() {
  //   this.setState({ modificationErrors: [] });
  // }

  // ----- ----- Format Functions ----- -----
  templateHeaderDay(props) {
    const label = props.headerText;
    const field = props.field;
    const date = new Date(field);
    const day = date.getDay();

    const today = new Date();

    // Weekends
    if(day === 0 || day === 6) {
      // Today
      if(date.toDateString() === today.toDateString()) {
        return <div className="headerToday">
          <span className="white">{label}</span>
        </div>;
      }
      else {
        return <div className="smoke-grey">{label}</div>;
      }
    }
    else {
      // Today
      if(date.toDateString() === today.toDateString()) {
        return <div className="headerToday">
          <span className="white">{label}</span>
        </div>;
      }
      else {
        return <div className="">{label}</div>
      }
    }
  }

  templateName(props) {
    const itemId = props.Item_ID;
    const itemType = props.Item_Type;
    let name = props.Name;

    if(name === '- Not assigned -') {
      let firstname = 'N';
      let lastname = 'A';

      return <div className="align-baseline">
        <span className="iconEmptyResourceTable icons aligncenter blue">{firstname + lastname}</span>
        <span className="ml10 grey">{name}</span>
      </div>;
    }
    else if(name === '- To be assigned -') {
      let firstname = 'T';
      let lastname = 'B';

      return <div className="align-baseline">
        <span className="iconEmptyResourceTable icons aligncenter blue">{firstname + lastname}</span>
        <span className="ml10 grey">{name}</span>
      </div>;
    }
    else if(name) {
      let firstname, lastname;
      let split = name.split(' ');

      if(split.length === 1) {
        firstname = name.split(' ')[0].substring(0,1);
        lastname = '';
      }
      else if(split.length === 2) {
        firstname = name.split(' ')[0].substring(0,1);
        lastname = name.split(' ')[1].substring(0,1);
      }
      else {
        firstname = name.split(' ')[0].substring(0,1);
        lastname = name.split(' ')[split.length-1].substring(0,1);
      }

      return <div className="align-baseline">
        <a target="_self" href={`/Card/${itemType}/${itemId.substring(1)}/Home`}><div className="iconResourceTable icons aligncenter"><span className="white">{firstname + lastname}</span></div></a>
        <span className="ml10 grey">{name}</span>
      </div>;
    }
    else {
      return null;
    }
  }

  templateHoliday(props) {
    const resourceId = props.Resource_ID;
    const date = props.column.field;
    const value = props[date];
    const field = resourceId + ',' + date;

    // Bank Holiday
    if(value == -1) {
      return <div className="holidaysTable">
      <div className="iconsTimeTrackingBankHoliday iconFullCircle bg-dark-grey"></div>
    </div>;
    }
    // Open Day
    else if(value == 1) {
      return <div className="holidaysTable">
        <div id={field} className="iconsTimeTrackingHoliday iconFullCircle bg-turquoise"></div>
      </div>;
    }
    else if(value == 0.5) {
      return <div className="holidaysTable">
        <div id={field} className="iconsTimeTrackingHoliday iconHalfCircle bg-turquoise"></div>
      </div>;
    }
    else {
      return <div className="holidaysTable">
        <div id={field} className="iconsTimeTrackingHoliday iconCircle bg-grey"></div>
      </div>;
    }
  }

  templateBankHoliday(props) {
    return <div className="holidaysTable">
      <div className="iconsTimeTrackingBankHoliday iconFullCircle bg-dark-grey"></div>
    </div>;
  }

  templateWeekend(props) {
    const resourceId = props.Resource_ID;
    const date = props.column.field;

    const value = props[date];
    const field = resourceId + ',' + date;

    return <div className="holidaysTable">
      <div id={field} className="iconsTimeTrackingWeekendHoliday iconFullCircle bg-smoke-grey"></div>
    </div>;
  }
  
  render() {
    let { itemId, itemType, columns, rows, dataSource, info, informationMessage, modificationErrors } = this.state;

    // Table Height
    let height = (window.innerHeight - 190);
    let minimum = 100;

    if(height > minimum) {
      height += 'px';
    }
    else {
      height = minimum + 'px';
    }

    // Grid Lines
    this.gridLines = 'Both';

    // Toolbar Options
    this.toolbarOptions = [];

    // Context Menu
    this.contextMenuItems = [];

    // Column Menu
    this.columnMenu = [];

    // Edit Options
    this.editOptions = { allowAdding: false, allowDeleting: false, allowEditing: true, mode: 'Cell' };

    // Filter Settings
    this.filterSettings = { type: 'Excel', hierarchyMode: 'Parent' };

    // frozenColumns={1}

    return (
      <div className="">
        {/* Errors */}
        {/* {this.state.modificationErrors.length > 0 && 
          <ErrorModification ref={this.errors} Open={true} Errors={this.state.modificationErrors} onErrorsClean={this.cleanErrors}></ErrorModification>
        } */}

        {/* Message Information Popup */}
        {info && <PopupInformation Message={informationMessage} onMessageValidate={this.validateMessage}></PopupInformation>}

        {/* TreeGrid Component */}
        <div className="control-pane">
          <div className="control-section">
            {dataSource.length > 0 && 
              <TreeGridComponent dataSource={dataSource} idMapping='Item_ID' height={height} gridLines={this.gridLines} editSettings={this.editOptions} filterSettings={this.filterSettings} created={this.created} dataBound={this.dataBound} rowDataBound={this.rowDataBound} rowSelected={this.rowSelected} actionBegin={this.actionBegin} actionComplete={this.actionComplete} excelQueryCellInfo={this.excelQueryCellInfo} showColumnMenu={false} showColumnChooser={false} allowFiltering={false} allowPaging={false} pageSettings={{pageSize: 50}} allowExcelExport={true} allowReordering={false} allowResizing={false} allowSorting={false} allowTextWrap={true} ref={treegrid=>this.grid=treegrid}>
                {/* Columns */}
                <ColumnsDirective>
                  <ColumnDirective field='Item_ID' headerText={this.getColumnLabel('Item_ID')} isPrimaryKey={true} visible={false} autoFit={true} allowEditing={this.getColumnEditable('Item_ID')} lockColumn={false} showInColumnChooser={this.getColumnVisible('Item_ID')}></ColumnDirective>
                </ColumnsDirective>
                {/* Services */}
                <Inject services={[ Freeze, ExcelExport ]}/>
              </TreeGridComponent>
            }
          </div>
        </div>
      </div>
    )
  }
}

export default TableHolidays;