import React, {Component} from 'react';
import { Button, Form } from 'react-bootstrap';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';

class PopoverEditFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: null,
      itemId: null,
      itemType: null,
      display: null,
      currentView: {},
      availableFilters: [],
      currentFilters: [],
      columns: [],
      filter: {},
      displayColumns: false,
      search: ''
    };

    this.displayColumns = this.displayColumns.bind(this);
    this.searchItem = this.searchItem.bind(this);
    this.pressKey = this.pressKey.bind(this);
    this.updateFilter = this.updateFilter.bind(this);

    this.search = React.createRef();
  }

  componentDidMount() {
    const language = Authentication.getCookie('language');
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const display = this.props.Display;
    const currentView = this.props.CurrentView;
    const availableFilters = this.props.AvailableFilters;
    const currentFilters = this.props.CurrentFilters;
    const filter = this.props.Filter;
    let columns = [];

    // Current View Table/Tree/Planning
    if(currentView.ViewType === 0 || currentView.ViewType === 1) {
      if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Columns')) {
        columns = (currentView.Parameters.find(param => param.Name === 'Columns').Value).split(',');
      }
    }
    // Current View Kanban
    else if(currentView.ViewType === 2) {
      if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Axe1') && currentView.Parameters.find(param => param.Name === 'Axe1').Value !== 'None') {
        columns.push(currentView.Parameters.find(param => param.Name === 'Axe1').Value);
      }
      if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Axe2') && currentView.Parameters.find(param => param.Name === 'Axe2').Value !== 'None') {
        columns.push(currentView.Parameters.find(param => param.Name === 'Axe2').Value);
      }
      if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Columns')) {
        currentView.Parameters.find(param => param.Name === 'Columns').Value.split(',').forEach(column => {
          columns.push(column);
        });
      }
    }
    // Current View Pivot/Chart/Map
    else if(currentView.ViewType === 3 || currentView.ViewType === 7 || currentView.ViewType === 8) {
      if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Columns')) {
        currentView.Parameters.find(param => param.Name === 'Columns').Value.split(',').forEach(column => {
          columns.push(column);
        });
        // columns.push(currentView.Parameters.find(param => param.Name === 'Columns').Value);
      }
      if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Rows')) {
        currentView.Parameters.find(param => param.Name === 'Rows').Value.split(',').forEach(row => {
          columns.push(row);
        });
        // columns.push(currentView.Parameters.find(param => param.Name === 'Rows').Value);
      }
      if(currentView.Values && currentView.Values.length > 0) {
        currentView.Values.forEach(value => {
          columns.push(value.Field);
        });
      }
    }

    // Search Auto focus
    setTimeout(() => { this.search.current.focus() }, 1);

    this.setState({ language, itemId, itemType, display, currentView, availableFilters, currentFilters, columns, filter });
  }

  componentDidUpdate(prevProps) {
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const display = this.props.Display;
    const currentView = this.props.CurrentView;
    const availableFilters = this.props.AvailableFilters;
    const currentFilters = this.props.CurrentFilters;
    const filter = this.props.Filter;
  }

  displayColumns() {
    const displayColumns = this.state.displayColumns;

    this.setState({ displayColumns: !displayColumns });
  }

  searchItem(event) {
    const search = event.target.value;

    this.setState({ search });
  }

  pressKey(event) {
    const { availableFilters, filter } = this.state;
    const search = event.target.value;
    let newFilter;

    if(event.key === 'Enter' && search !== '') {
      if(availableFilters.find(value => value.FieldObject.Label.toLowerCase().includes(search.toLowerCase()))) {
        newFilter = availableFilters.find(value => value.FieldObject.Label.toLowerCase().includes(search.toLowerCase()));

        // Close Popover
        if(document.getElementById("popover-basic")) {
          document.getElementById("popover-basic").classList.remove("show");
        }

        // Call the event from the Parent component through the props
        this.props.onFilterEdit(filter, newFilter);

        this.setState({ filter: newFilter });
      }
    }
  }

  updateFilter(newFilter) {
    const filter = this.state.filter;

    // Close Popover
    if(document.getElementById("popover-basic")) {
      document.getElementById("popover-basic").classList.remove("show");
    }

    // Call the event from the Parent component through the props
    this.props.onFilterEdit(filter, newFilter);
  }

  render() {
    const { language, itemId, itemType, display, currentView, availableFilters, currentFilters, columns, filter, displayColumns, search } = this.state;

    return (
      <div className="filtersEditPopover">
        {/* Search */}
        <div className="filtersEditSearch">
          <Form.Group className="searchBlock">
            <Form.Control type="text" id="searchFilters" name="search" value={search} placeholder={Traduction.translate(language, 'search')} autoFocus onChange={this.searchItem} onKeyPress={this.pressKey} ref={this.search}/>
          </Form.Group>
        </div>
        {/* Columns */}
        <div className="filtersEditColumns scrollbar-y">
          {/* View olumns */}
          {availableFilters.map((filter, index) => {
            if(display === 'Filter' || display === 'ConditionalFormatting' || (display === 'Sort' && !currentFilters.find(currentFilter => currentFilter.FieldObject.Name === filter.FieldObject.Name))) {
              if(columns.includes(filter.FieldObject.Name)) {
                if(search === "" || filter.FieldObject.Label.toLowerCase().includes(search.toLowerCase())) {
                  return <div key={index} className="filtersEditColumn" onClick={() => this.updateFilter(filter)}>{filter.FieldObject.Label}</div>;
                }
              }
            }
          })}
          {/* Other Columns */}
          {displayColumns && availableFilters.map((filter, index) => {
            if(display === 'Filter' || display === 'ConditionalFormatting' || (display === 'Sort' && !currentFilters.find(currentFilter => currentFilter.FieldObject.Name === filter.FieldObject.Name))) {
              if(!columns.includes(filter.FieldObject.Name)) {
                if(search === "" || filter.FieldObject.Label.toLowerCase().includes(search.toLowerCase())) {
                  return <div key={index} className="filtersEditColumn" onClick={() => this.updateFilter(filter)}>{filter.FieldObject.Label}</div>;
                }
              }
            }
          })}
        </div>
        {/* Display More Columns Filters */}
        {!displayColumns && display !== 'ConditionalFormatting' && <div className="filtersDisplayColumnsButton">
          <Button className="filtersColumnsButton" variant="primary" onClick={() => this.displayColumns()}>{Traduction.translate(language, 'display_more')}</Button>
        </div>}
      </div>
    );
  }
}

export default PopoverEditFilter;