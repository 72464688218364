import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import { Button, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';
import ErrorMessage from './ErrorMessage';
import LoadingSpinner from './LoadingSpinner';
import LogoTabsters from '../Images/LogoTabsters.png';
import IconFrance from '../Images/IconFrance.png';
import IconEnglish from '../Images/IconEnglish.png';
import IconSpain from '../Images/IconSpain.png';
import LoginImage from '../Images/LoginPage.png';

// Article Images (height 376px width 376 px)
import TabstersBlogArticle1 from '../Images/TabstersBlogArticle1.png';
import TabstersBlogArticle2 from '../Images/TabstersBlogArticle2.png';
import TabstersBlogArticle3 from '../Images/TabstersBlogArticle3.png';
import TabstersBlogArticle4 from '../Images/TabstersBlogArticle4.png';
import TabstersBlogArticle5 from '../Images/TabstersBlogArticle5.png';
import TabstersBlogArticle6 from '../Images/TabstersBlogArticle6.png';
import TabstersBlogArticle7 from '../Images/TabstersBlogArticle7.png';
import TabstersBlogArticle8 from '../Images/TabstersBlogArticle8.png';
import TabstersBlogArticle9 from '../Images/TabstersBlogArticle9.png';

const API_environment_design = '/WebAppService/GetEnvironmentDesign';
const API_server_authentication = '/WebAppService/GetAuthenticationMethodOfServer';
const API_user_authentication = '/WebAppService/GetAuthenticationMethodOfUser';
const API_reset_password = '/WebAppService/AskResetPassword';
const API_login = '/WebAppService/ConnectUser';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authenticationMode: null,
      environmentDesign: null,
      user: null,
      language: null,
      login: '',
      loginIcon: null,
      article1: {},
      article2: {},
      article3: {},
      article4: {},
      helpMode: null,
      password: '',
      passwordInformation: {},
      passwordExpiredPopup: false,
      passwordExpiringPopup: false,
      uniquePassword: null,
      token: {},
      version: '7.12',
      isLoading: false,
      errors: [],
      error: null
    };

    // Data Structure
    this.getEnvironmentDesign = this.getEnvironmentDesign.bind(this);
    this.getServerAuthenticationMethod = this.getServerAuthenticationMethod.bind(this);
    this.getUserAuthenticationMethod = this.getUserAuthenticationMethod.bind(this);
    this.getUser = this.getUser.bind(this);

    // Actions
    this.connectionOkta = this.connectionOkta.bind(this);
    this.createCookies = this.createCookies.bind(this);
    this.changeAuthenticationMode = this.changeAuthenticationMode.bind(this);
    this.changeLanguage = this.changeLanguage.bind(this);
    this.changeLogin = this.changeLogin.bind(this);
    this.changePassword = this.changePassword.bind(this);
    this.handleSubmitPassword = this.handleSubmitPassword.bind(this);
    this.handleSubmitOkta = this.handleSubmitOkta.bind(this);
    this.encryptPassword = this.encryptPassword.bind(this);
    this.forgotPassword = this.forgotPassword.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
    // this.sleep = this.sleep.bind(this);

    // Templates
    this.templatePasswordExpiredPopup = this.templatePasswordExpiredPopup.bind(this);
    this.templatePasswordExpiringPopup = this.templatePasswordExpiringPopup.bind(this);
    this.closePopup = this.closePopup.bind(this);
  }

  async componentDidMount() {
    let params, language;

    // Get Environment Design
    await this.getEnvironmentDesign();

    // Get Authentication Method of Server
    await this.getServerAuthenticationMethod();

    // Get Params from Query string url
    if(this.props.location.search) {
      params = queryString.parse(this.props.location.search);
      language = params.language;
    }

    if(language === 'en') {
      language = 'English';
    }
    else if(language === 'es') {
      language = 'Spanish';
    }
    else {
      language = 'Français';
    }

    this.setState({ language });

    if(params && params.login) {
      this.connectionOkta(params.login);
    }
  }

  // async componentDidMount(prevProps) {
  //   let params, old_params;

  //   // Get Authentication Method of Server
  //   await this.getServerAuthenticationMethod();

  //   // Get old Params from Query string url
  //   if(prevProps.location.search) {
  //     old_params = queryString.parse(prevProps.location.search);
  //   }

  //   // Get Params from Query string url
  //   if(this.props.location.search) {
  //     params = queryString.parse(this.props.location.search);
  //   }

  //   if(params && params.login) {
  //     this.connectionOkta(params.login);
  //   }
  // }

  async getEnvironmentDesign() {
    const language = this.state.language;
    let version = this.state.version;

    try {
      const response = await fetch(API_environment_design);
      let errors = [];

      if(!response.ok || response.status !== 200) {
        errors.push(Traduction.translate(language, 'server_not_responding'));
      }

      const data = await response.json();
      const result = data.GetEnvironmentDesignResult;

      // Initialize Articles
      let article1 = {
        IconLink: "https://dl.tabsters.fr/ClientAssets/General/imageLink1.png",
        PageLink: "https://help.tabsters.fr/blog/pilotagestratgiqueetoprationnel",
        Text: "Pilotage stratégique et opérationnel"
      };

      let article2 = {
        IconLink: "https://dl.tabsters.fr/ClientAssets/General/imageLink2.png",
        PageLink: "https://help.tabsters.fr/webapp-lastnews",
        Text: "Nouvelle version Tabsters " + version
      };

      let article3 = {
        IconLink: "https://dl.tabsters.fr/ClientAssets/General/imageLink3.png",
        PageLink: "https://help.tabsters.fr/blog/le-temps-principal-enjeu-de-votre-transformation",
        Text: "Le temps, principal enjeu de votre transformation"
      };

      let article4 = {
        IconLink: "https://dl.tabsters.fr/ClientAssets/General/imageLink4.png",
        PageLink: "https://help.tabsters.fr/blog/agilite-a-l-echelle",
        Text: "L'agilité à l'échelle d'une entreprise"
      };

      if(result) {
        const environmentDesign = result;
        let loginIcon;

        if(result.IconLink) {
          loginIcon = result.IconLink;
        }

        if(result.LoginLinks) {
          if(result.LoginLinks[0]) {
            article1 = result.LoginLinks[0];
          }
          if(result.LoginLinks[1]) {
            article2 = result.LoginLinks[1];
          }
          if(result.LoginLinks[2]) {
            article3 = result.LoginLinks[2];
          }
          if(result.LoginLinks[3]) {
            article4 = result.LoginLinks[3];
          }
        }

        if(result.Version) {
          version = result.Version;
        }

        this.setState({ environmentDesign, loginIcon, version });
      }

      this.setState({ article1, article2, article3, article4 });

    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  async getServerAuthenticationMethod() {
    const language = this.state.language;

    try {
      const response = await fetch(API_server_authentication);
      let errors = [];

      if(!response.ok || response.status !== 200) {
        errors.push(Traduction.translate(language, 'server_not_responding'));
      }

      const data = await response.json();
      const result = data.GetAuthenticationMethodOfServerResult;

      if(result) {
        const uri = result.Uri;
        const authenticationType = result.authenticationType;
        let authenticationMode;

        if(authenticationType === 2) {
          authenticationMode = "okta";
        }
        else {
          authenticationMode = "password";
        }

        this.setState({ authenticationMode });
      }

    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  async getUserAuthenticationMethod(login) {
    const language = this.state.language;

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        'Login': login
      })
    };

    try {
      const response = await fetch(API_user_authentication, requestOptions);
      let errors = [];

      if(!response.ok || response.status !== 200) {
        errors.push(Traduction.translate(language, 'server_not_responding'));
      }

      const data = await response.json();
      const result = data.GetAuthenticationMethodOfUserResult;

      if(result) {
        const uri = result.Uri;
        const authenticationType = result.authenticationType;

        if(authenticationType === 2) {
          window.location.replace(uri);
          // window.open(uri, 'Okta', 'height=800,width=800');
        }
        else {
          errors.push(Traduction.translate(language, 'okta_not_available'));
        }

        this.setState({ errors });

        return authenticationType;
      }

    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  async getUser(login, password) {
    const language = this.state.language;

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        'Login': login,
        'PasswordHash': password
      })
    };

    try{
      const response = await fetch(API_login, requestOptions);
      let errors = [];

      if(!response.ok || response.status !== 200) {
        errors.push(Traduction.translate(language, 'server_not_responding'));
      }

      const data = await response.json();
      const result = data.ConnectUserResult;

      if(result) {
        const user = result.LoggedUser;
        const helpMode = result.HelpMode;
        const passwordInformation = result.PasswordInformation;
        const token = result.Token;
        let uniquePassword;
        
        // Check if the User returned is valid
        if(result.IsValid === false) {
          errors.push(result.Error);
        }
        else {
          uniquePassword = user.UniquePassword;
        }
  
        this.setState({ user, helpMode, passwordInformation, uniquePassword, token });
      }
      
      this.setState({ errors });

    } catch(error) {
      this.setState({ error });
    }
  }

  async connectionOkta(login) {
    const language = this.state.language;
    let errors = [];

    this.setState({ isLoading: true });

    // Authentication Type Okta
    // if(authenticationType === 2) {
      // let count = 0;

      // Try to Log User while waiting for response from Okta
      // while(!this.state.user && count < 50) {
      //   await this.getUser(login, login);

      //   this.sleep(1000);

      //   count ++;
      // }

      if(login) {
        await this.getUser(login, login);
      }

      // Create Cookies with User informations and redirect to Home Page
      if(this.state.user) {
        if(Object.keys(this.state.user).length) {
          this.createCookies();

          const lastUrl = Authentication.getCookie('lastUrl');

          // Rebuild Url for Timetracking
          // if(lastUrl === '/TimeTracking') {
          //   const resourceId = Authentication.getCookie('resourceId');
  
          //   // Get current Date for Timetracking
          //   const date = new Date();
          //   const month = (date.getMonth())+1;
          //   const year = date.getFullYear();
  
          //   lastUrl = `/TimeTracking?year=${year}&month=${month}&resourceId=${resourceId}`;
          // }

          // Delete lastUrl cookie after User is logged in
          Authentication.deleteCookie('lastUrl');

          this.setState({ isLoading: false });

          // Redirect to the last Url if it exists, else redirect to the Home Page
          if(lastUrl !== '' && lastUrl !== '/' && lastUrl !== '/Login?language=en' && lastUrl !== '/Login?language=es' && lastUrl !== '/Login?language=fr') {
            this.props.history.push(lastUrl);
          }
          else {
            this.props.history.push("/Home");
          }
        }
      }
      else {
        errors.push(Traduction.translate(language, 'okta_no_response'));

        this.setState({ isLoading: false, errors });
      }
    // }
  }

  createCookies() {
    if(this.state.environmentDesign) {
      // Navbar Color
      if(this.state.environmentDesign.BarBackColor) {
        Authentication.createCookie('navbarColor', this.state.environmentDesign.BarBackColor);
      }
      // Navbar Icon
      if(this.state.environmentDesign.BarIconLink) {
        Authentication.createCookie('navbarIcon', this.state.environmentDesign.BarIconLink);
      }
      // Empty Week Icon
      if(this.state.environmentDesign.EmptyWeekIconeLink) {
        Authentication.createCookie('emptyWeekIcon', this.state.environmentDesign.EmptyWeekIconeLink);
      }
    }
    else {
      Authentication.createCookie('navbarColor', "#0088C7");
    }

    if(this.state.user) {
      Authentication.createCookie('userId', this.state.user.UserId);
      Authentication.createCookie('login', this.state.user.Login);
      Authentication.createCookie('firstName', this.state.user.FirstName);
      Authentication.createCookie('lastName', this.state.user.LastName);
      Authentication.createCookie('email', this.state.user.Email);
      Authentication.createCookie('resourceId', this.state.user.CorrespondingResourceId);
      Authentication.createCookie('language', this.state.user.Language);
      Authentication.createCookie('reporting', this.state.user.Reporting);
      Authentication.createCookie('staticData', this.state.user.StaticData);
      Authentication.createCookie('usersConfig', this.state.user.UsersConfig);
      Authentication.createCookie('formatDate', this.state.user.FormatDate);
    }
    if(this.state.helpMode) {
      Authentication.createCookie('helpMode', this.state.helpMode);
    }
    if(this.state.token) {
      Authentication.createCookie('authId', this.state.token.Key);
    }

    Authentication.createCookie('displayViews', true);
  }

  changeAuthenticationMode(mode) {
    this.setState({ authenticationMode: mode });
  }

  changeLanguage(language) {
    let params;

    // Get Params from Query string url
    if(this.props.location.search) {
      params = queryString.parse(this.props.location.search);
    }

    // Redirect with new Query params
    if(language === 'English') {
      if(params && params.login) {
        this.props.history.push(`/Login?login=${params.login}&language=en`);
      }
      else {
        this.props.history.push(`/Login?language=en`);
      }
    }
    else if(language === 'Spanish') {
      if(params && params.login) {
        this.props.history.push(`/Login?login=${params.login}&language=es`);
      }
      else {
        this.props.history.push(`/Login?language=es`);
      }
    }
    else {
      if(params && params.login) {
        this.props.history.push(`/Login?login=${params.login}&language=fr`);
      }
      else {
        this.props.history.push(`/Login?language=fr`);
      }
    }

    this.setState({ language });
  }

  changeLogin(event) {
    this.setState({ login: event.target.value });
  }

  changePassword(event) {
    this.setState({ password: event.target.value });
  }

  // Password Authentication
  async handleSubmitPassword(event) {
    const login = this.state.login;
    const password = this.state.password;

    this.setState({ isLoading: true });
    
    // Encrypt Password
    const encryptedPassword = this.encryptPassword(password);

    event.preventDefault();

    await this.getUser(login, encryptedPassword);

    // Create Cookies with User informations and redirect
    if(this.state.user) {
      if(Object.keys(this.state.user).length) {
        this.createCookies();

        // Redirect to Change Password page if Password is unique
        if(this.state.uniquePassword == true) {
          this.props.history.push("/ChangePassword");
        }
        else {
          const passwordInformation = this.state.passwordInformation;

          // Expired Password
          if(passwordInformation.Expired) {
            this.setState({ passwordExpiredPopup: true, isLoading: false });
          }
          // Required Message
          else if(!passwordInformation.Expired && passwordInformation.RequiredMessage) {
            this.setState({ passwordExpiringPopup: true, isLoading: false });
          }
          // Log User
          else {
            let lastUrl = Authentication.getCookie('lastUrl');

            // Rebuild Url for Timetracking
            // if(lastUrl === '/TimeTracking') {
            //   const resourceId = Authentication.getCookie('resourceId');
    
            //   // Get current Date for Timetracking
            //   const date = new Date();
            //   const month = (date.getMonth())+1;
            //   const year = date.getFullYear();
    
            //   lastUrl = `/TimeTracking?year=${year}&month=${month}&resourceId=${resourceId}`;
            // }
    
            // Delete lastUrl cookie after User is logged in
            Authentication.deleteCookie('lastUrl');

            this.setState({ isLoading: false });
    
            // Redirect to the last Url if it exists, else redirect to the Home Page
            if(lastUrl !== '' && lastUrl !== '/' && lastUrl !== '/Login?language=en' && lastUrl !== '/Login?language=es' && lastUrl !== '/Login?language=fr') {
				      this.props.history.push(lastUrl);
            }
            else {
				      this.props.history.push("/Home");
            }
          }
        }
      }
    }
    else {
      this.setState({ isLoading: false });
    }
  }

  // Okta Authentication
  async handleSubmitOkta(event) {
    const login = this.state.login;

    event.preventDefault();

    await this.getUserAuthenticationMethod(login);

    // let authenticationType = await this.getUserAuthenticationMethod(login);

    // Authentication Type Okta
    // if(authenticationType === 2) {
    //   let count = 0;

    //   // Try to Log User while waiting for response from Okta
    //   while(!this.state.user && count < 50) {
    //     await this.getUser(login, login);

    //     this.sleep(1000);

    //     count ++;
    //   }

    //   // Create Cookies with User informations and redirect to Home Page
    //   if(this.state.user) {
    //     if(Object.keys(this.state.user).length) {
    //       this.createCookies();

    //       const lastUrl = Authentication.getCookie('lastUrl');

    //       // Delete lastUrl cookie after User is logged in
    //       Authentication.deleteCookie('lastUrl');

    //       // Redirect to the last Url if it exists, else redirect to the Home Page
    //       if(lastUrl !== '' && lastUrl !== '/') {
    //         this.props.history.push(lastUrl);
    //       }
    //       else {
    //         this.props.history.push("/Home");
    //       }
    //     }
    //   }
    //   else {
    //     this.state.errors.push('Could not receive successfull response from Okta');
    //   }
    // }
  }

  encryptPassword(password) {
    const CryptoJS = require("crypto-js");
    
    // Define key
    const key = CryptoJS.enc.Utf8.parse('7P*LDKxy6q5@l%rS');

    // Define vector
    const vector = CryptoJS.enc.Utf8.parse('U7@A7tb$2QuJ5^#9');

    // Encrypt Password with key & vector
    const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(password), key, { keySize: 128 / 8, iv: vector, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
    
    // Convert Encrypted PAssword to Hexadecimal
    const buffer = Buffer.from(encrypted.toString(), 'utf8');

    return buffer.toString('hex');
  }

  forgotPassword() {
    const language = this.state.language;
    const login = this.state.login;
    let errors = [];

    if(login.length === 0) {
      errors.push(Traduction.translate(language, 'enter_login'));

      this.setState({ errors });
    }
    else {
      this.resetPassword(login);
    }
  }

  async resetPassword(login) {
    const language = this.state.language;

    this.setState({ isLoading: true });

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        'Login': login
      })
    };

    try{
      const response = await fetch(API_reset_password, requestOptions);
      let errors = [];

      if(!response.ok || response.status !== 200) {
        errors.push(Traduction.translate(language, 'server_not_responding'));
      }

      const data = await response.json();

      errors.push(Traduction.translate(language, 'email_reset_password'));
      
      this.setState({ isLoading: false, errors });

    } catch(error) {
	    this.setState({ isLoading: false, error });
    }
  }

  // sleep(milliseconds) {
  //   return new Promise(resolve => setTimeout(resolve, milliseconds));
  // }

  templatePasswordExpiredPopup() {
    const language = this.state.language;

    return (<div className="flex flex-column popupPasswordExpired">
      {/* Label */}
      <div className="passwordExpiredLabel mb10">{Traduction.translate(language, 'your_password_expired')}</div>

      {/* Button Validate or Cancel */}
      <div className="flex flex-end align-items-center">
        <Link to="/ChangePassword"><Button className="fs12 bold brd-radius mr15" variant="primary">{Traduction.translate(language, 'change_password')}</Button></Link>
        <Button className="fs12 bold brd-radius" variant="warning" onClick={() => this.closePopup()}>{Traduction.translate(language, 'cancel')}</Button>
      </div>
    </div>);
  }

  templatePasswordExpiringPopup() {
    let lastUrl = Authentication.getCookie('lastUrl');
    const language = this.state.language;
    const passwordInformation = this.state.passwordInformation;

    // Redirect to the last Url if it exists, else redirect to the Home Page
    if(lastUrl === '' || lastUrl === '/' || lastUrl === '/Login?language=en' || lastUrl === '/Login?language=es' || lastUrl === '/Login?language=fr') {
      lastUrl = "/Home";
    }

    return (<div className="flex flex-column popupPasswordExpired">
      {/* Label */}
      <div className="passwordExpiredLabel mb20">
        <span className="">{Traduction.translate(language, 'your_password_expires_in')}</span>
        <span className="yellow ml5">{passwordInformation.DaysBeforeExpiration} {Traduction.translate(language, 'days')}</span>
        <span className="ml5">{Traduction.translate(language, 'would_you_like_to_change')}</span>
      </div>

      {/* Button Validate or Cancel */}
      <div className="flex flex-end align-items-center">
        <Link to="/ChangePassword"><Button className="fs12 bold brd-radius mr15" variant="primary">{Traduction.translate(language, 'change_password')}</Button></Link>
        <Link to={lastUrl}><Button className="fs12 bold brd-radius" variant="warning">{Traduction.translate(language, 'cancel')}</Button></Link>
      </div>
    </div>);
  }

  closePopup() {
    this.setState({ passwordExpiredPopup: false });
  }
  
  render() {
    let { authenticationMode, language, environmentDesign, loginIcon, article1, article2, article3, article4, passwordExpiredPopup, passwordExpiringPopup, version, errors, isLoading } = this.state;
    
    return (
      <div className="row">
        {/* Title */}
        <MetaTags><title>{Traduction.translate(language, 'tabsters')}</title></MetaTags>

        {/* Tabsters WebApp version */}
        <div className="tabstersWebappVersion">
          <span className="poweredByTabsters">{Traduction.translate(language, 'version')} {version} {Traduction.translate(language, 'powered_by')}</span>
          <img className="miniLogoTabsters" src={LogoTabsters} alt="Tabsters"/>
        </div>

        <div className="loginForm center">
          <div className="col-md-8">
            <div className="inline-flex mb30">
              {/* Tabsters Logo */}
              {loginIcon && <img className="loginLogo" src={loginIcon} alt="LoginIcon"/>}
              {!loginIcon && <img className="loginLogo" src={LogoTabsters} alt="Tabsters"/>}
              {/* <div className="flex"><img className="loginLogo" src={LogoTabsters} alt="Tabsters"/></div> */}
            </div>

            {/* Popup Password Expired */}
            {(passwordExpiredPopup === true) && <div className="passwordExpiredPopup">
              <div className="passwordExpiredInnerPopup">{this.templatePasswordExpiredPopup()}</div>
            </div>}

            {/* Popup Password Expiring */}
            {(passwordExpiringPopup === true) && <div className="passwordExpiredPopup">
              <div className="passwordExpiredInnerPopup">{this.templatePasswordExpiringPopup()}</div>
            </div>}

            {/* Authentication Mode Password */}
            {authenticationMode === 'password' && <Form onSubmit={this.handleSubmitPassword}>
              <Form.Group controlId="login">
                <Form.Control type="text" value={this.state.login} placeholder={Traduction.translate(language, 'login')} onChange={this.changeLogin} required/>
              </Form.Group>
              <Form.Group controlId="password">
                <Form.Control type="password" value={this.state.password} placeholder={Traduction.translate(language, 'password')} onChange={this.changePassword} required/>
              </Form.Group>

              {/* Errors */}
              {errors.length > 0 && <ErrorMessage Errors={this.state.errors}></ErrorMessage>}

              {/* Forgot Password */}
              <div className="row"><Button className="forgotPasswordButton" variant="link" onClick={(e) => this.forgotPassword()}>{Traduction.translate(language, 'forgot_password')}</Button></div>

              <div className="loginPasswordButtons">
                {/* Loading Spinner */}
                {isLoading && <div className="loginSpinner"><LoadingSpinner></LoadingSpinner></div>}

                {/* Connexion Button */}
				        {!isLoading && <Button className="loginButton" type="submit">{Traduction.translate(language, 'connect')}</Button>}

                {/* Authentication Mode Button */}
                <Button className="authenticationOktaButton" variant="link" onClick={(e) => this.changeAuthenticationMode('okta')}>{Traduction.translate(language, 'authentication_okta')}</Button>
              </div>
            </Form>}

            {/* Authentication Mode Okta */}
            {authenticationMode === 'okta' && <Form onSubmit={this.handleSubmitOkta}>
              <Form.Group controlId="login">
                <Form.Control type="text" value={this.state.login} placeholder={Traduction.translate(language, 'login')} onChange={this.changeLogin} required/>
              </Form.Group>

              {/* Errors */}
              {errors.length > 0 && <ErrorMessage Errors={this.state.errors}></ErrorMessage>}

              <div className="loginOktaButtons">
                {/* Loading Spinner */}
                {isLoading && <div className="loginSpinner"><LoadingSpinner></LoadingSpinner></div>}

                {/* Connexion Button */}
				        {!isLoading && <Button className="loginButton" type="submit">{Traduction.translate(language, 'connect')}</Button>}
                
                {/* Authentication Mode Button */}
                <Button className="authenticationPasswordButton" variant="link" onClick={(e) => this.changeAuthenticationMode('password')}>{Traduction.translate(language, 'authentication_password')}</Button>
              </div>
            </Form>}
          </div>
        </div>

        <div className="loginImages">
          {/* Language */}
          <div className="loginLanguages">
            {language === 'English' && <div className="flex">
              <img className="iconsLoginLanguage mh5 cursor" src={IconFrance} alt="French" onClick={() => this.changeLanguage('Français')}/>
              <img className="iconsLoginLanguage mh5 cursor" src={IconSpain} alt="Spain" onClick={() => this.changeLanguage('Spanish')}/>
            </div>}
            {language === 'Français' && <div className="flex">
              <img className="iconsLoginLanguage mh5 cursor" src={IconEnglish} alt="English" onClick={() => this.changeLanguage('English')}/>
              <img className="iconsLoginLanguage mh5 cursor" src={IconSpain} alt="Spain" onClick={() => this.changeLanguage('Spanish')}/>
            </div>}
            {language === 'Spanish' && <div className="flex">
              <img className="iconsLoginLanguage mh5 cursor" src={IconFrance} alt="French" onClick={() => this.changeLanguage('Français')}/>
              <img className="iconsLoginLanguage mh5 cursor" src={IconEnglish} alt="English" onClick={() => this.changeLanguage('English')}/>
            </div>}
          </div>

          {/* Login Image */}
          {/* <img className="loginImage" src={LoginImage} alt=""/> */}

          {/* Label */}
          {/* <Form.Label className="tabstersBlogLabel">Nos actualités</Form.Label> */}

          {/* Blog Articles */}
          <div className="row height50p">
            {article1 && <div className="tabstersBlogArticleLeft">
              {/* Link to external Blog Page */}
              <a target="_self" href={article1.PageLink} className="">
              {/* <a target="_self" href="https://www.tabsters.fr/blog/pilotagestratgiqueetoprationnel" className=""> */}
                <img className="tabstersBlogImage" src={article1.IconLink} alt="Tabsters Blog Article"/>
                {/* <img className="tabstersBlogImage" src={TabstersBlogArticle9} alt="Tabsters Blog Article"/> */}
                <div className="tabstersBlogArticleName center">{article1.Text}</div>
                {/* <div className="tabstersBlogArticleName center">Pilotage stratégique et opérationnel</div> */}
              </a>
            </div>}
            {article2 && <div className="tabstersBlogArticleRight">
              {/* Link to external Blog Page */}
              <a target="_self" href={article2.PageLink} className="">
              {/* <a target="_self" href="https://www.tabsters.fr/webapp-lastnews" className=""> */}
                <img className="tabstersBlogImage" src={article2.IconLink} alt="Tabsters Blog Article"/>
                {/* <img className="tabstersBlogImage" src={TabstersBlogArticle6} alt="Tabsters Blog Article"/> */}
                <div className="tabstersBlogArticleName center">{article2.Text}</div>
                {/* <div className="tabstersBlogArticleName center">Nouvelle version Tabsters {version}</div> */}
              </a>
            </div>}
          </div>
          <div className="row height50p">
            {article3 && <div className="tabstersBlogArticleLeft">
              {/* Link to external Blog Page */}
              <a target="_self" href={article3.PageLink} className="">
              {/* <a target="_self" href="https://www.tabsters.fr/blog/le-temps-principal-enjeu-de-votre-transformation" className=""> */}
                <img className="tabstersBlogImage" src={article3.IconLink} alt="Tabsters Blog Article"/>
                {/* <img className="tabstersBlogImage" src={TabstersBlogArticle8} alt="Tabsters Blog Article"/> */}
                <div className="tabstersBlogArticleName center">{article3.Text}</div>
                {/* <div className="tabstersBlogArticleName center">Le temps, principal enjeu de votre transformation</div> */}
              </a>
            </div>}
            {article4 && <div className="tabstersBlogArticleRight">
              {/* Link to external Blog Page */}
              <a target="_self" href={article4.PageLink} className="">
              {/* <a target="_self" href="https://www.tabsters.fr/blog/agilite-a-l-echelle" className=""> */}
                <img className="tabstersBlogImage" src={article4.IconLink} alt="Tabsters Blog Article"/>
                {/* <img className="tabstersBlogImage" src={TabstersBlogArticle7} alt="Tabsters Blog Article"/> */}
                <div className="tabstersBlogArticleName center">{article4.Text}</div>
                {/* <div className="tabstersBlogArticleName center">L'agilité à l'échelle d'une entreprise</div> */}
              </a>
            </div>}
            {/* <a target="_self" href="https://www.tabsters.fr/blog/quitter-nos-schmas-de-pense-simplifis" className="">
              <img className="tabstersBlogImage" src={TabstersBlogArticle4} alt="Tabsters Blog Article"/>
              <div className="tabstersBlogArticleName center">Quitter nos schémas de pensée simplifiés</div>
            </a> */}
            {/* <a target="_self" href="https://www.tabsters.fr/blog/vos-dashboards-vous-mentent" className="">
              <img className="tabstersBlogImage" src={TabstersBlogArticle5} alt="Tabsters Blog Article"/>
              <div className="tabstersBlogArticleName center">Vos dashboards vous mentent</div>
            </a> */}
            {/* <a target="_self" href="https://www.tabsters.fr/blog/comment-le-confinement-a-t-il-t-vcu-chez-tabsters-et-chez-nos-clients-" className="">
              <img className="tabstersBlogImage" src={TabstersBlogArticle3} alt="Tabsters Blog Article"/>
              <div className="tabstersBlogArticleName center">Comment le confinement a-t-il été vécu chez Tabsters et chez nos clients ?</div>
            </a> */}
            {/* <a target="_self" href="https://www.tabsters.fr/blog/mthode-agile" className="">
              <img className="tabstersBlogImage" src={TabstersBlogArticle2} alt="Tabsters Blog Article"/>
              <div className="tabstersBlogArticleName center">Méthodologie Agile</div>
            </a> */}
            {/* <a target="_self" href="https://www.tabsters.fr/blog/2015/10/14/preserved-lemons-rl3kz" className="">
              <img className="tabstersBlogImage" src={TabstersBlogArticle1} alt="Tabsters Blog Article"/>
              <div className="tabstersBlogArticleName center">Télétravail : Les avantages d'une solution de gestion de projet flexible</div>
            </a> */}
          </div>
        </div>
      </div>
    )
  }
}

export default Login;