import React, {Component} from 'react';
import { Badge, Card, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Progress } from 'react-sweet-progress';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';
import IconCloud from '../Images/IconCloud.png';
import IconMoon from '../Images/IconMoon.png';
import IconRain from '../Images/IconRain.png';
import IconSun from '../Images/IconSun.png';
import IconThunder from '../Images/IconThunder.png';
import IconFrance from '../Images/IconFrance.png';
import IconEnglish from '../Images/IconEnglish.png';
import IconSpain from '../Images/IconSpain.png';

class Axe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: null,
      axe: null,
      type: null,
      value: null,
      view: null
    };

    this.buildAxe = this.buildAxe.bind(this);
  }

  componentDidMount() {
    const language = Authentication.getCookie('language');
    const axe = this.props.Axe;
    const type = this.props.Type;
    const value = this.props.Value;
    const view = this.props.View;

    this.setState({ language, axe, type, value, view });
  }

  componentDidUpdate(prevProps) {
    const axe = this.props.Axe;
    const type = this.props.Type;
    const value = this.props.Value;
    const view = this.props.View;

    if(this.props.Axe !== prevProps.Axe || this.props.Type !== prevProps.Type || this.props.Value !== prevProps.Value || this.props.View !== prevProps.View) {
      this.setState({ axe, type, value, view });
    }
  }

  buildAxe(axe, type, value, view) {
    const language = this.state.language;

    if(value) {
      // Status
      if(axe === 'Status') {
        // Status (1: To be Done, 2: In Progress, 3: On Hold, 4: Cancelled, 5: Completed, 6: Open, 7: Closed)
        // Status (8: Realised, 9: To be Scheduled, 10: Scheduled, 11: Taken, 12: To be Taken)
        switch(value.Id) {
          case 1:
          case 9:
              if(view === 'Block') {
                return <div className="axe brd-radius bg-grey white">{value.Label}</div>;
              }
              else if(view === 'Kanban' || view === 'Popover') {
                return <div className="axe brd-radius bg-grey white">{value.Label}</div>;
              }
              else if(view === 'Miniblock') {
                return <div className="miniblockStatus bg-grey white">{value.Label}</div>;
              }
              else if(view === 'Minicard') {
                return <div className="minicardStatus brd-radius bg-grey white bold">{value.Label}</div>;
              }
          case 2:
          case 10:
              if(view === 'Block') {
                return <div className="axe brd-radius bg-blue white">{value.Label}</div>;
              }
              else if(view === 'Kanban' || view === 'Popover') {
                return <div className="axe brd-radius bg-blue white">{value.Label}</div>;
              }
              else if(view === 'Miniblock') {
                return <div className="miniblockStatus bg-blue white">{value.Label}</div>;
              }
              else if(view === 'Minicard') {
                return <div className="minicardStatus brd-radius bg-blue white bold">{value.Label}</div>;
              }
          case 3:
              if(view === 'Block') {
                return <div className="axe bordered orange">{value.Label}</div>;
              }
              else if(view === 'Kanban' || view === 'Popover') {
                return <div className="axe bordered orange">{value.Label}</div>;
              }
              else if(view === 'Miniblock') {
                return <div className="miniblockStatus orange">{value.Label}</div>;
              }
              else if(view === 'Minicard') {
                return <div className="minicardStatus bordered orange bold">{value.Label}</div>;
              }
          case 4:
              if(view === 'Block') {
                return <div className="axe bordered grey">{value.Label}</div>;
              }
              else if(view === 'Kanban' || view === 'Popover') {
                return <div className="axe bordered grey">{value.Label}</div>;
              }
              else if(view === 'Miniblock') {
                return <div className="miniblockStatus grey">{value.Label}</div>;
              }
              else if(view === 'Minicard') {
                return <div className="minicardStatus bordered grey bold">{value.Label}</div>;
              }
          case 5:
          case 7:
          case 11:
              if(view === 'Block') {
                return <div className="axe brd-radius bg-green white">{value.Label}</div>;
              }
              else if(view === 'Kanban' || view === 'Popover') {
                return <div className="axe brd-radius bg-green white">{value.Label}</div>;
              }
              else if(view === 'Miniblock') {
                return <div className="miniblockStatus bg-green white">{value.Label}</div>;
              }
              else if(view === 'Minicard') {
                return <div className="minicardStatus brd-radius bg-green white bold">{value.Label}</div>;
              }
          case 6:
          case 12:
              if(view === 'Block') {
                return <div className="axe brd-radius bg-orange white">{value.Label}</div>;
              }
              else if(view === 'Kanban' || view === 'Popover') {
                return <div className="axe brd-radius bg-orange white">{value.Label}</div>;
              }
              else if(view === 'Miniblock') {
                return <div className="miniblockStatus bg-orange white">{value.Label}</div>;
              }
              else if(view === 'Minicard') {
                return <div className="minicardStatus brd-radius bg-orange white bold">{value.Label}</div>;
              }
          case 8:
              if(view === 'Block') {
                return <div className="axe brd-radius bg-red white">{value.Label}</div>;
              }
              else if(view === 'Kanban' || view === 'Popover') {
                return <div className="axe brd-radius bg-red white">{value.Label}</div>;
              }
              else if(view === 'Miniblock') {
                return <div className="miniblockStatus bg-red white">{value.Label}</div>;
              }
              else if(view === 'Minicard') {
                return <div className="minicardStatus brd-radius bg-red white bold">{value.Label}</div>;
              }
          default:
              return null;
        }
      }
      // Priority, Probability or Impact
      else if(axe === 'Impact' || axe === 'Priority' || axe === 'Probability') {
        // (0: Very Low, 1: Low, 2: Medium, 3: High, 4: Critical)
        switch(value.Id) {
          case 0:
              if(view === 'Block' || view === 'Miniblock' || view === 'Kanban' || view === 'Minicard' || view === 'Popover') {
                return <li className="fs18 green"><span className="minicardPriority black">{value.Label}</span></li>;
              }
          case 1:
              if(view === 'Block' || view === 'Miniblock' || view === 'Kanban' || view === 'Minicard' || view === 'Popover') {
                return <li className="fs18 green"><span className="minicardPriority black">{value.Label}</span></li>;
              }
          case 2:
              if(view === 'Block' || view === 'Miniblock' || view === 'Kanban' || view === 'Minicard' || view === 'Popover') {
                return <li className="fs18 orange-light"><span className="minicardPriority black">{value.Label}</span></li>;
              }
          case 3:
              if(view === 'Block' || view === 'Miniblock' || view === 'Kanban' || view === 'Minicard' || view === 'Popover') {
                return <li className="fs18 orange"><span className="minicardPriority black">{value.Label}</span></li>;
              }
          case 4:
              if(view === 'Block' || view === 'Miniblock' || view === 'Kanban' || view === 'Minicard' || view === 'Popover') {
                return <li className="fs18 black"><span className="minicardPriority red">{value.Label}</span></li>;
              }
          default:
              return null;
        }
      }
      // Meteo (1: Sun ☀, 2: Cloud ⛅, 3: Rain 🌧, 4: Thunder 🌩, 5: Moon ☾)
      else if(axe === 'Meteo') {
        switch(value.Id) {
          case 0:
              if(view === 'Block') {
                return <span className="iconEmpty iconsPopover" alt="Empty"></span>;
              }
              else if(view === 'Popover') {
                return <span className="iconEmpty iconsPopover" alt="Empty"></span>;
              }
              else if(view === 'Kanban') {
                return <span className="iconEmpty iconsFilter" alt="Empty"></span>;
              }
              else if(view === 'Miniblock') {
                return <div className=""></div>;
              }
              else if(view === 'Minicard') {
                return <div className=""></div>;
              }
          case 1:
              if(view === 'Block') {
                return <div className=""><img className="icons" src={IconSun} alt="Sun"/></div>;
              }
              else if(view === 'Popover') {
                return <div className=""><img className="iconsPopover nav icon" src={IconSun} alt="Sun"/></div>;
              }
              else if(view === 'Miniblock') {
                return <div className="flex width100p center"><img className="icons" src={IconSun} alt="Sun"/></div>;
              }
              else if(view === 'Kanban' || view === 'Minicard') {
                return <div className=""><img className="mediumIcons nav icon" src={IconSun} alt="Sun"/></div>;
              }
          case 2:
              if(view === 'Block') {
                return <div className=""><img className="icons" src={IconCloud} alt="Cloud"/></div>;
              }
              else if(view === 'Popover') {
                return <div className=""><img className="iconsPopover nav icon" src={IconCloud} alt="Cloud"/></div>;
              }
              else if(view === 'Miniblock') {
                return <div className="flex width100p center"><img className="icons" src={IconCloud} alt="Cloud"/></div>;
              }
              else if(view === 'Kanban' || view === 'Minicard') {
                return <div className=""><img className="mediumIcons nav icon" src={IconCloud} alt="Cloud"/></div>;
              }
          case 3:
              if(view === 'Block') {
                return <div className=""><img className="icons" src={IconRain} alt="Rain"/></div>;
              }
              else if(view === 'Popover') {
                return <div className=""><img className="iconsPopover nav icon" src={IconRain} alt="Rain"/></div>;
              }
              else if(view === 'Miniblock') {
                return <div className="flex width100p center"><img className="icons" src={IconRain} alt="Rain"/></div>;
              }
              else if(view === 'Kanban' || view === 'Minicard') {
                return <div className=""><img className="mediumIcons nav icon" src={IconRain} alt="Rain"/></div>;
              }
          case 4:
              if(view === 'Block') {
                return <div className=""><img className="icons" src={IconThunder} alt="Thunder"/></div>;
              }
              else if(view === 'Popover') {
                return <div className=""><img className="iconsPopover nav icon" src={IconThunder} alt="Thunder"/></div>;
              }
              else if(view === 'Miniblock') {
                return <div className="flex width100p center"><img className="icons" src={IconThunder} alt="Thunder"/></div>;
              }
              else if(view === 'Kanban' || view === 'Minicard') {
                return <div className=""><img className="mediumIcons nav icon" src={IconThunder} alt="Thunder"/></div>;
              }
          case 5:
              if(view === 'Block') {
                return <div className=""><img className="icons" src={IconMoon} alt="Moon"/></div>;
              }
              else if(view === 'Popover') {
                return <div className=""><img className="iconsPopover nav icon" src={IconMoon} alt="Moon"/></div>;
              }
              else if(view === 'Miniblock') {
                return <div className="flex width100p center"><img className="icons" src={IconMoon} alt="Moon"/></div>;
              }
              else if(view === 'Kanban' || view === 'Minicard') {
                return <div className=""><img className="mediumIcons nav icon" src={IconMoon} alt="Moon"/></div>;
              }
          default:
              return null;
        }
      }
      // Trend
      else if(axe === 'Trend') {
        switch(value.Id) {
          case 0:
              if(view === 'Block') {
                return <span className="iconEmpty iconsPopover"></span>;
              }
              else if(view === 'Popover') {
                return <span className="iconEmpty iconsPopover"></span>;
              }
              else if(view === 'Kanban') {
                return <span className="iconEmpty iconsFilter"></span>;
              }
              else if(view === 'Miniblock') {
                return <div className=""></div>;
              }
              else if(view === 'Minicard') {
                return <div className=""></div>;
              }
          case 1:
              if(view === 'Block') {
                return <span className="iconTrendDown icons"></span>;
              }
              else if(view === 'Popover') {
                return <span className="iconTrendDown iconsPopover"></span>;
              }
              else if(view === 'Kanban') {
                return <span className="iconTrendDown iconsFilter"></span>;
              }
              else if(view === 'Miniblock') {
                return <div className="flex width100p center"><span className="iconTrendDown icons"></span></div>;
              }
              else if(view === 'Minicard') {
                return <span className="iconTrendDown iconsMinicardKanban"></span>;
              }
          case 2:
              if(view === 'Block') {
                return <span className="iconTrendRight icons"></span>;
              }
              else if(view === 'Popover') {
                return <span className="iconTrendRight iconsPopover"></span>;
              }
              else if(view === 'Kanban') {
                return <span className="iconTrendRight iconsFilter"></span>;
              }
              else if(view === 'Miniblock') {
                return <div className="flex width100p center"><span className="iconTrendRight icons"></span></div>;
              }
              else if(view === 'Minicard') {
                return <span className="iconTrendRight iconsMinicardKanban"></span>;
              }
          case 3:
              if(view === 'Block') {
                return <span className="iconTrendUp icons"></span>;
              }
              else if(view === 'Popover') {
                return <span className="iconTrendUp iconsPopover"></span>;
              }
              else if(view === 'Kanban') {
                return <span className="iconTrendUp iconsFilter"></span>;
              }
              else if(view === 'Miniblock') {
                return <div className="flex width100p center"><span className="iconTrendUp icons"></span></div>;
              }
              else if(view === 'Minicard') {
                return <span className="iconTrendUp iconsMinicardKanban"></span>;
              }
          default:
              return null;
        }
      }
      // Axis Table/HashTag(s)
      else if(type === 'AxisTable' || axe === 'HashTag') {
        // Type Array
        if(Array.isArray(value)) {
          if(view === 'Block') {
            // Specific Case for empty Hashtags
            if(value.length === 0) {
              return <div className="fieldDetails"></div>;
            }
            else {
              return <div className="">
                {value.map((hashTag, index) => {
                  let backColor, foreColor, borderColor;

                  // Back, Fore & Border Color
                  if(!hashTag.BackColor) {
                    backColor = '#FFFFFF';
                  }
                  else {
                    backColor = hashTag.BackColor;
                  }
                  if(!hashTag.ForeColor) {
                    foreColor = '#0088C7';
                    borderColor = '#0088C7';
                  }
                  else {
                    if((hashTag.ForeColor === '#FFFFFF' || hashTag.ForeColor === '#ffffff')) {
                      borderColor = hashTag.BackColor;
                    }
                    else {
                      borderColor = hashTag.ForeColor;
                    }

                    foreColor = hashTag.ForeColor;
                  }

                  return <div key={index} className={"axe bordered mb5" + (index < value.length - 1 ? " mr10" : "")} style={{ background: backColor, color: foreColor, borderColor: borderColor }}>{hashTag.Label}</div>
                })}
              </div>;
            }
          }
          else if(view === 'Miniblock') {
            return <div className="miniblockWrap">
              {value.map((hashTag, index) => {
                let backColor, foreColor, borderColor;

                // Back, Fore & Border Color
                if(!hashTag.BackColor) {
                  backColor = '#FFFFFF';
                }
                else {
                  backColor = hashTag.BackColor;
                }
                if(!hashTag.ForeColor) {
                  foreColor = '#0088C7';
                  borderColor = '#0088C7';
                }
                else {
                  if((hashTag.ForeColor === '#FFFFFF' || hashTag.ForeColor === '#ffffff')) {
                    borderColor = hashTag.BackColor;
                  }
                  else {
                    borderColor = hashTag.ForeColor;
                  }

                  foreColor = hashTag.ForeColor;
                }

                return <div key={index} className={"miniblockAxisTable bordered" + (index < value.length - 1 ? " mr10" : "")} style={{ background: backColor, color: foreColor, borderColor: borderColor }}>{hashTag.Label}</div>
              })}
            </div>;
          }
          else if(view === 'Minicard') {
            return <div className="miniblockWrap">
              {value.map((hashTag, index) => {
                let backColor, foreColor, borderColor;

                // Back, Fore & Border Color
                if(!hashTag.BackColor) {
                  backColor = '#FFFFFF';
                }
                else {
                  backColor = hashTag.BackColor;
                }
                if(!hashTag.ForeColor) {
                  foreColor = '#0088C7';
                  borderColor = '#0088C7';
                }
                else {
                  if((hashTag.ForeColor === '#FFFFFF' || hashTag.ForeColor === '#ffffff')) {
                    borderColor = hashTag.BackColor;
                  }
                  else {
                    borderColor = hashTag.ForeColor;
                  }

                  foreColor = hashTag.ForeColor;
                }

                return <div key={index} className={"miniblockAxisTable bordered" + (index < value.length - 1 ? " mr10" : "")} style={{ background: backColor, color: foreColor, borderColor: borderColor }}>{hashTag.Label}</div>
              })}
            </div>;
          }
          else if(view === 'Popover') {
            return <div className="inline-flex">
              {value.map((hashTag, index) => {
                let backColor, foreColor, borderColor;

                // Back, Fore & Border Color
                if(!hashTag.BackColor) {
                  backColor = '#FFFFFF';
                }
                else {
                  backColor = hashTag.BackColor;
                }
                if(!hashTag.ForeColor) {
                  foreColor = '#0088C7';
                  borderColor = '#0088C7';
                }
                else {
                  if((hashTag.ForeColor === '#FFFFFF' || hashTag.ForeColor === '#ffffff')) {
                    borderColor = hashTag.BackColor;
                  }
                  else {
                    borderColor = hashTag.ForeColor;
                  }

                  foreColor = hashTag.ForeColor;
                }

                return <div key={index} className={"axe bordered" + (index < value.length - 1 ? " mr10" : "")} style={{ background: backColor, color: foreColor, borderColor: borderColor }}>{hashTag.Label}</div>
              })}
            </div>;
          }
        }
        // Type Object
        else if(Object.entries(value).length > 0) {
          if(view === 'Block') {
            return <div className="inline-flex">
              {value.map((hashTag, index) => {
                let backColor, foreColor, borderColor;

                // Back, Fore & Border Color
                if(!hashTag.BackColor) {
                  backColor = '#FFFFFF';
                }
                else {
                  backColor = hashTag.BackColor;
                }
                if(!hashTag.ForeColor) {
                  foreColor = '#0088C7';
                  borderColor = '#0088C7';
                }
                else {
                  if((hashTag.ForeColor === '#FFFFFF' || hashTag.ForeColor === '#ffffff')) {
                    borderColor = hashTag.BackColor;
                  }
                  else {
                    borderColor = hashTag.ForeColor;
                  }

                  foreColor = hashTag.ForeColor;
                }

                return <div key={index} className={"axe bordered" + (index < value.length - 1 ? " mr10" : "")} style={{ background: backColor, color: foreColor, borderColor: borderColor }}>{hashTag.Label}</div>
              })}
            </div>;
          }
          else if(view === 'Miniblock') {
            return <div className="miniblockWrap">
              {value.map((hashTag, index) => {
                let backColor, foreColor, borderColor;

                // Back, Fore & Border Color
                if(!hashTag.BackColor) {
                  backColor = '#FFFFFF';
                }
                else {
                  backColor = hashTag.BackColor;
                }
                if(!hashTag.ForeColor) {
                  foreColor = '#0088C7';
                  borderColor = '#0088C7';
                }
                else {
                  if((hashTag.ForeColor === '#FFFFFF' || hashTag.ForeColor === '#ffffff')) {
                    borderColor = hashTag.BackColor;
                  }
                  else {
                    borderColor = hashTag.ForeColor;
                  }

                  foreColor = hashTag.ForeColor;
                }

                return <div key={index} className={"miniblockAxisTable bordered" + (index < value.length - 1 ? " mr10" : "")} style={{ background: backColor, color: foreColor, borderColor: borderColor }}>{hashTag.Label}</div>
              })}
            </div>;
          }
          else if(view === 'Minicard') {
            return <div className="miniblockWrap">
              {value.map((hashTag, index) => {
                let backColor, foreColor, borderColor;

                // Back, Fore & Border Color
                if(!hashTag.BackColor) {
                  backColor = '#FFFFFF';
                }
                else {
                  backColor = hashTag.BackColor;
                }
                if(!hashTag.ForeColor) {
                  foreColor = '#0088C7';
                  borderColor = '#0088C7';
                }
                else {
                  if((hashTag.ForeColor === '#FFFFFF' || hashTag.ForeColor === '#ffffff')) {
                    borderColor = hashTag.BackColor;
                  }
                  else {
                    borderColor = hashTag.ForeColor;
                  }

                  foreColor = hashTag.ForeColor;
                }

                return <div key={index} className={"miniblockAxisTable bordered" + (index < value.length - 1 ? " mr10" : "")} style={{ background: backColor, color: foreColor, borderColor: borderColor }}>{hashTag.Label}</div>
              })}
            </div>;
          }
          else if(view === 'Popover') {
            let backColor, foreColor, borderColor;

            // Back, Fore & Border Color
            if(!value.BackColor) {
              backColor = '#FFFFFF';
            }
            else {
              backColor = value.BackColor;
            }
            if(!value.ForeColor) {
              foreColor = '#0088C7';
              borderColor = '#0088C7';
            }
            else {
              if((value.ForeColor === '#FFFFFF' || value.ForeColor === '#ffffff')) {
                borderColor = value.BackColor;
              }
              else {
                borderColor = value.ForeColor;
              }

              foreColor = value.ForeColor;
            }

            return <div className="axe bordered" style={{ background: backColor, color: foreColor, borderColor: borderColor }}>{value.Label}</div>;
          }
        }
        // Type String
        // if(typeof value === 'string') {
        //   if(view === 'Block') {
        //     return <div className="inline-flex emptyCellTable">
        //       {value.map((hashTag, index) => {
        //         let backColor, foreColor;

        //         // Back & Fore Color
        //         if(!value.BackColor) {
        //           backColor = '#FFFFFF';
        //         }
        //         else {
        //           backColor = value.BackColor;
        //         }
        //         if(!value.ForeColor) {
        //           foreColor = '#0088C7';
        //         }
        //         else {
        //           foreColor = value.ForeColor;
        //         }

        //         return <div key={index} className={"axe bordered" + (index < value.length - 1 ? " mr10" : "")} style={{ background: backColor, color: foreColor }}>{hashTag.Label}</div>
        //       })}
        //     </div>;
        //   }
        // }
      }
      // Files
      else if(type === 'Files') {
        if(view === 'Block') {
          // Specific Case for empty Files
          if(value.length === 0) {
            return <div className="fieldDetails" onClick={() => this.props.onFilesPopupOpen(axe)}></div>;
          }
          else {
            return <div className="flex flex-column" onClick={() => this.props.onFilesPopupOpen(axe)}>
              {value.map((file, index) => {
                if(file && file.Label) {
                  let split = file.Label.split('.');
                  let extension = split[split.length - 1];
    
                  if(extension === 'docx') {
                    return <div key={index} className="inline-flex align-items-center">
                      <span className="iconWord iconsTable mr10"></span>
                      <span className="fs12">{file.Label}</span>
                    </div>;
                  }
                  else if(extension === 'pdf') {
                    return <div key={index} className="inline-flex align-items-center">
                      <span className="iconPDF iconsTable mr10"></span>
                      <span className="fs12">{file.Label}</span>
                    </div>;
                  }
                  else if(extension === 'pptx') {
                    return <div key={index} className="inline-flex align-items-center">
                      <span className="iconPPT iconsTable mr10"></span>
                      <span className="fs12">{file.Label}</span>
                    </div>;
                  }
                  else if(extension === 'xlsx') {
                    return <div key={index} className="inline-flex align-items-center">
                      <span className="iconExcel iconsTable mr10"></span>
                      <span className="fs12">{file.Label}</span>
                    </div>;
                  }
                  else {
                    return <div key={index} className="inline-flex align-items-center">
                      <span className="iconFile iconsTable mr10"></span>
                      <span className="fs12">{file.Label}</span>
                    </div>;
                  }
                }
              })}
            </div>;
          }
        }
        else if(view === 'Miniblock') {
          return <div className="flex flex-column">
            {value.map((file, index) => {
              if(file && file.Label) {
                let split = file.Label.split('.');
                let extension = split[split.length - 1];
  
                if(extension === 'docx') {
                  return <div key={index} className="inline-flex align-items-center">
                    <span className="iconWord iconsTable mr10"></span>
                    <span className="fs12">{file.Label}</span>
                  </div>;
                }
                else if(extension === 'pdf') {
                  return <div key={index} className="inline-flex align-items-center">
                    <span className="iconPDF iconsTable mr10"></span>
                    <span className="fs12">{file.Label}</span>
                  </div>;
                }
                else if(extension === 'pptx') {
                  return <div key={index} className="inline-flex align-items-center">
                    <span className="iconPPT iconsTable mr10"></span>
                    <span className="fs12">{file.Label}</span>
                  </div>;
                }
                else if(extension === 'xlsx') {
                  return <div key={index} className="inline-flex align-items-center">
                    <span className="iconExcel iconsTable mr10"></span>
                    <span className="fs12">{file.Label}</span>
                  </div>;
                }
                else {
                  return <div key={index} className="inline-flex align-items-center">
                    <span className="iconFile iconsTable mr10"></span>
                    <span className="fs12">{file.Label}</span>
                  </div>;
                }
              }
            })}
          </div>;
        }
      }
      // Location
      else if(type === 'Location') {
        if(view === 'Block') {
          if(value.Label === '- NA -') {
            return <div className="axe brd-dashed very-light-grey">{value.Label}</div>;
          }
          else {
            return <div className="inline-flex align-items-center">
              <span className="iconMap icons"></span>
              <span className="fs12">{value.Label}</span>
            </div>;
          }
        }
        else if(view === 'Kanban') {
          if(value.Label === '- NA -' || value.Label === '- Not assigned -') {
            return <div className="axe brd-dashed very-light-grey">{value.Label}</div>;
          }
          else {
            return <div className="inline-flex align-items-center">
              <span className="iconMap iconsFilter"></span>
              <span className="fs12">{value.Label}</span>
            </div>;
          }
        }
        else if(view === 'Popover') {
          if(value.Label === '- NA -' || value.Label === '- Not assigned -') {
            return <div className="axe brd-dashed very-light-grey">{value.Label}</div>;
          }
          else {
            return <div className="inline-flex align-items-center">
              <span className="iconMap iconsPopover"></span>
              <span className="fs12">{value.Label}</span>
            </div>;
          }
        }
        else if(view === 'Miniblock') {
          if(value.Label === '- NA -') {
            return <div className="miniblockAxe brd-dashed very-light-grey">{value.Label}</div>;
          }
          else {
            return <div className="inline-flex align-items-center">
              <span className="iconMap iconsMiniblock"></span>
              <span className="fs12">{value.Label}</span>
            </div>;
          }
        }
        else if(view === 'Minicard') {
          if(value.Label === '- NA -') {
            return <div className="axe brd-dashed very-light-grey">{value.Label}</div>;
          }
          else {
            return <div className="inline-flex align-items-center">
              <span className="iconMap iconsMinicardKanban"></span>
              <span className="">{value.Label}</span>
            </div>;
          }
        }
      }
      // Progress
      else if(axe === 'Progress') {
        if(value) {
          // Format value as Percentage
          // value = (value * 100).toFixed(0);
          
          if(value === '100') {
            return <Progress percent={value} type="circle" width={35} theme={{ active: {color: '#00C77A'}}}/>;
          }
          else {
            return <Progress percent={value} type="circle" width={35} theme={{ active: {color: '#0088C7'}}}/>;
          }
        }
        else {
          value = 0;

          return <Progress percent={value} type="circle" width={35} theme={{ active: {color: '#0088C7'}}}/>;
        }
      }
      // Rating
      else if(type === 'Rating') {
        if(value === "1") {
          if(view === 'Block') {
            return <div className=""></div>;
          }
          else if(view === 'Kanban' || view === 'Popover') {
            return <div className="">
              <span className="iconRating iconsFilter mr10"></span>
              <span className="iconRatingEmpty iconsFilter mr10"></span>
              <span className="iconRatingEmpty iconsFilter mr10"></span>
              <span className="iconRatingEmpty iconsFilter mr10"></span>
              <span className="iconRatingEmpty iconsFilter mr10"></span>
            </div>;
          }
          else if(view === 'Miniblock') {
            return <div className="">
              <span className="iconRating iconsMiniblock mr10"></span>
              <span className="iconRatingEmpty iconsMiniblock mr10"></span>
              <span className="iconRatingEmpty iconsMiniblock mr10"></span>
              <span className="iconRatingEmpty iconsMiniblock mr10"></span>
              <span className="iconRatingEmpty iconsMiniblock mr10"></span>
            </div>;
          }
          else if(view === 'Minicard') {
            return <div className="">
              <span className="iconRating iconsMinicardKanban mr10"></span>
              <span className="iconRatingEmpty iconsMinicardKanban mr10"></span>
              <span className="iconRatingEmpty iconsMinicardKanban mr10"></span>
              <span className="iconRatingEmpty iconsMinicardKanban mr10"></span>
              <span className="iconRatingEmpty iconsMinicardKanban mr10"></span>
            </div>;
          }
        }
        else if(value === "2") {
          if(view === 'Block') {
            return <div className=""></div>;
          }
          else if(view === 'Kanban' || view === 'Popover') {
            return <div className="">
              <span className="iconRating iconsFilter mr10"></span>
              <span className="iconRating iconsFilter mr10"></span>
              <span className="iconRatingEmpty iconsFilter mr10"></span>
              <span className="iconRatingEmpty iconsFilter mr10"></span>
              <span className="iconRatingEmpty iconsFilter mr10"></span>
            </div>;
          }
          else if(view === 'Miniblock') {
            return <div className="">
              <span className="iconRating iconsMiniblock mr10"></span>
              <span className="iconRating iconsMiniblock mr10"></span>
              <span className="iconRatingEmpty iconsMiniblock mr10"></span>
              <span className="iconRatingEmpty iconsMiniblock mr10"></span>
              <span className="iconRatingEmpty iconsMiniblock mr10"></span>
            </div>;
          }
          else if(view === 'Minicard') {
            return <div className="">
              <span className="iconRating iconsMinicardKanban mr10"></span>
              <span className="iconRating iconsMinicardKanban mr10"></span>
              <span className="iconRatingEmpty iconsMinicardKanban mr10"></span>
              <span className="iconRatingEmpty iconsMinicardKanban mr10"></span>
              <span className="iconRatingEmpty iconsMinicardKanban mr10"></span>
            </div>;
          }
        }
        else if(value === "3") {
          if(view === 'Block') {
            return <div className=""></div>;
          }
          else if(view === 'Kanban' || view === 'Popover') {
            return <div className="">
              <span className="iconRating iconsFilter mr10"></span>
              <span className="iconRating iconsFilter mr10"></span>
              <span className="iconRating iconsFilter mr10"></span>
              <span className="iconRatingEmpty iconsFilter mr10"></span>
              <span className="iconRatingEmpty iconsFilter mr10"></span>
            </div>;
          }
          else if(view === 'Miniblock') {
            return <div className="">
              <span className="iconRating iconsMiniblock mr10"></span>
              <span className="iconRating iconsMiniblock mr10"></span>
              <span className="iconRating iconsMiniblock mr10"></span>
              <span className="iconRatingEmpty iconsMiniblock mr10"></span>
              <span className="iconRatingEmpty iconsMiniblock mr10"></span>
            </div>;
          }
          else if(view === 'Minicard') {
            return <div className="">
              <span className="iconRating iconsMinicardKanban mr10"></span>
              <span className="iconRating iconsMinicardKanban mr10"></span>
              <span className="iconRating iconsMinicardKanban mr10"></span>
              <span className="iconRatingEmpty iconsMinicardKanban mr10"></span>
              <span className="iconRatingEmpty iconsMinicardKanban mr10"></span>
            </div>;
          }
        }
        else if(value === "4") {
          if(view === 'Block') {
            return <div className=""></div>;
          }
          else if(view === 'Kanban' || view === 'Popover') {
            return <div className="">
              <span className="iconRating iconsFilter mr10"></span>
              <span className="iconRating iconsFilter mr10"></span>
              <span className="iconRating iconsFilter mr10"></span>
              <span className="iconRating iconsFilter mr10"></span>
              <span className="iconRatingEmpty iconsFilter mr10"></span>
            </div>;
          }
          else if(view === 'Miniblock') {
            return <div className="">
              <span className="iconRating iconsMiniblock mr10"></span>
              <span className="iconRating iconsMiniblock mr10"></span>
              <span className="iconRating iconsMiniblock mr10"></span>
              <span className="iconRating iconsMiniblock mr10"></span>
              <span className="iconRatingEmpty iconsMiniblock mr10"></span>
            </div>;
          }
          else if(view === 'Minicard') {
            return <div className="">
              <span className="iconRating iconsMinicardKanban mr10"></span>
              <span className="iconRating iconsMinicardKanban mr10"></span>
              <span className="iconRating iconsMinicardKanban mr10"></span>
              <span className="iconRating iconsMinicardKanban mr10"></span>
              <span className="iconRatingEmpty iconsMinicardKanban mr10"></span>
            </div>;
          }
        }
        else if(value === "5") {
          if(view === 'Block') {
            return <div className=""></div>;
          }
          else if(view === 'Kanban' || view === 'Popover') {
            return <div className="">
              <span className="iconRating iconsFilter mr10"></span>
              <span className="iconRating iconsFilter mr10"></span>
              <span className="iconRating iconsFilter mr10"></span>
              <span className="iconRating iconsFilter mr10"></span>
              <span className="iconRating iconsFilter mr10"></span>
            </div>;
          }
          else if(view === 'Miniblock') {
            return <div className="">
              <span className="iconRating iconsMiniblock mr10"></span>
              <span className="iconRating iconsMiniblock mr10"></span>
              <span className="iconRating iconsMiniblock mr10"></span>
              <span className="iconRating iconsMiniblock mr10"></span>
              <span className="iconRating iconsMiniblock mr10"></span>
            </div>;
          }
          else if(view === 'Minicard') {
            return <div className="">
              <span className="iconRating iconsMinicardKanban mr10"></span>
              <span className="iconRating iconsMinicardKanban mr10"></span>
              <span className="iconRating iconsMinicardKanban mr10"></span>
              <span className="iconRating iconsMinicardKanban mr10"></span>
              <span className="iconRating iconsMinicardKanban mr10"></span>
            </div>;
          }
        }
        else {
          if(view === 'Block') {
            return <div className=""></div>;
          }
          else if(view === 'Kanban' || view === 'Popover') {
            return <div className="">
              <span className="iconRatingEmpty iconsFilter mr10"></span>
              <span className="iconRatingEmpty iconsFilter mr10"></span>
              <span className="iconRatingEmpty iconsFilter mr10"></span>
              <span className="iconRatingEmpty iconsFilter mr10"></span>
              <span className="iconRatingEmpty iconsFilter mr10"></span>
            </div>;
          }
          else if(view === 'Miniblock') {
            return <div className="">
              <span className="iconRatingEmpty iconsMiniblock mr10"></span>
              <span className="iconRatingEmpty iconsMiniblock mr10"></span>
              <span className="iconRatingEmpty iconsMiniblock mr10"></span>
              <span className="iconRatingEmpty iconsMiniblock mr10"></span>
              <span className="iconRatingEmpty iconsMiniblock mr10"></span>
            </div>;
          }
          else if(view === 'Minicard') {
            return <div className="">
              <span className="iconRatingEmpty iconsMinicardKanban mr10"></span>
              <span className="iconRatingEmpty iconsMinicardKanban mr10"></span>
              <span className="iconRatingEmpty iconsMinicardKanban mr10"></span>
              <span className="iconRatingEmpty iconsMinicardKanban mr10"></span>
              <span className="iconRatingEmpty iconsMinicardKanban mr10"></span>
            </div>;
          }
        }
      }
      // Resource
      else if(type === 'Resource' || axe === 'Resource' || axe === 'Resources' || axe === 'Creator' || axe === 'LastModifyUser' || axe === 'Project_Manager' || axe === 'Owner') {
        if(value.Label === '- Not assigned -') {
          let firstname = 'N';
          let lastname = 'A';

          if(view === 'Block') {
            return <div className="align-baseline">
              <span className="iconHollowCircle icons aligncenter fs13 blue">{firstname + lastname}</span>
              <span className="fs12 grey ml10">{value.Label}</span>
            </div>;
          }
          else if(view === 'Kanban' || view === 'Miniblock' || view === 'Popover') {
            return <div className="align-baseline">
              <span className="iconEmptyResourceTable icons aligncenter fs12 blue">{firstname + lastname}</span>
              <span className="fs12 grey ml10">{value.Label}</span>
            </div>;
          }
          else if(view === 'Minicard') {
            return <div className="iconHollowCircle icons fs13">
              {['right'].map(placement => (
                <OverlayTrigger key={placement} placement={placement} overlay={<Tooltip id={`tooltip-${placement}`}><strong>{value.Label}</strong></Tooltip>} style={{padding: '2px 10px', color: 'black', borderRadius: 3}}>
                  <Card.Text className="blue">{firstname + lastname}</Card.Text>
                </OverlayTrigger>
              ))}
            </div>;
          }
        }
        else if(value.Label === '- To be assigned -') {
          let firstname = 'T';
          let lastname = 'B';

          if(view === 'Block') {
            return <div className="align-baseline">
              <span className="iconHollowCircle icons aligncenter fs13 blue">{firstname + lastname}</span>
              <span className="fs12 grey ml10">{value.Label}</span>
            </div>;
          }
          else if(view === 'Kanban' || view === 'Miniblock' || view === 'Popover') {
            return <div className="align-baseline">
              <span className="iconEmptyResourceTable icons aligncenter fs12 blue">{firstname + lastname}</span>
              <span className="fs12 grey ml10">{value.Label}</span>
            </div>;
          }
          else if(view === 'Minicard') {
            return <div className="iconHollowCircle icons fs13">
              {['right'].map(placement => (
                <OverlayTrigger key={placement} placement={placement} overlay={<Tooltip id={`tooltip-${placement}`}><strong>{value.Label}</strong></Tooltip>} style={{padding: '2px 10px', color: 'black', borderRadius: 3}}>
                  <Card.Text className="blue">{firstname + lastname}</Card.Text>
                </OverlayTrigger>
              ))}
            </div>;
          }
        }
        else if(value.Label) {
          let firstname, lastname;
          let split = value.Label.split(' ');

          if(split.length === 1) {
            firstname = value.Label.split(' ')[0].substring(0,1);
            lastname = '';
          }
          else if(split.length === 2) {
            firstname = value.Label.split(' ')[0].substring(0,1);
            lastname = value.Label.split(' ')[1].substring(0,1);
          }
          else {
            firstname = value.Label.split(' ')[0].substring(0,1);
            lastname = value.Label.split(' ')[split.length-1].substring(0,1);
          }

          if(view === 'Block') {
            return <div className="align-baseline">
              <span className="iconFullCircleBlue icons aligncenter fs13 white">{firstname + lastname}</span>
              <span className="fs12 grey ml10">{value.Label}</span>
            </div>;
          }
          else if(view === 'Kanban' || view === 'Miniblock' || view === 'Popover') {
            return <div className="align-baseline">
              <span className="iconResourceTable icons aligncenter fs12 white">{firstname + lastname}</span>
              <span className="fs12 grey ml10">{value.Label}</span>
            </div>;
          }
          else if(view === 'Minicard') {
            return <div className="iconFullCircleBlue icons fs13">
              {['right'].map(placement => (
                <OverlayTrigger key={placement} placement={placement} overlay={<Tooltip id={`tooltip-${placement}`}><strong>{value.Label}</strong></Tooltip>} style={{padding: '2px 10px', color: 'white', borderRadius: 3}}>
                  <Card.Text className="white">{firstname + lastname}</Card.Text>
                </OverlayTrigger>
              ))}
            </div>;
          }
        }
      }
      // Resource Table
      else if(type === 'ResourceTable') {
        if(view === 'Block') {
          // Specific Case for empty Resources
          if(value.length === 0) {
            return <div className="fieldDetails"></div>;
          }
          else {
            return <div className="flex flex-column">
              {value.map((resource, index) => {
                let firstname, lastname;

                if(resource.Label === '- Not assigned -') {
                  firstname = 'N';
                  lastname = 'A';

                  return <div key={index} className="detailsMultiResource mr10">
                    <span className="iconHollowCircle icons aligncenter fs13 blue">{firstname + lastname}</span>
                    <span className="fs12 grey ml10">{resource.Label}</span>
                  </div>;
                }
                else if(resource.Label === '- To be assigned -') {
                  firstname = 'T';
                  lastname = 'B';

                  return <div key={index} className="detailsMultiResource mr10">
                    <span className="iconHollowCircle icons aligncenter fs13 blue">{firstname + lastname}</span>
                    <span className="fs12 grey ml10">{resource.Label}</span>
                  </div>;
                }
                else if(resource.Label) {
                  const split = resource.Label.split(' ');
          
                  if(split.length === 1) {
                    firstname = resource.Label.split(' ')[0].substring(0,1);
                    lastname = '';
                  }
                  else if(split.length === 2) {
                    firstname = resource.Label.split(' ')[0].substring(0,1);
                    lastname = resource.Label.split(' ')[1].substring(0,1);
                  }
                  else {
                    firstname = resource.Label.split(' ')[0].substring(0,1);
                    lastname = resource.Label.split(' ')[split.length-1].substring(0,1);
                  }

                  if(resource.Id === 0) {
                    return <div key={index} className="detailsMultiResource mr10">{resource.Label}</div>;
                  }
                  else {
                    return <div key={index} className="detailsMultiResource mr10">
                      <span className="iconFullCircleBlue icons aligncenter fs13 white">{firstname + lastname}</span>
                      <span className="fs12 grey ml10">{resource.Label}</span>
                    </div>;
                  }
                }
              })}
            </div>
          }
        }
        else if(view === 'Miniblock') {
          return <div className="flex flex-column">
            {value.map((resource, index) => {
              let firstname, lastname;

              if(resource.Label === '- Not assigned -') {
                firstname = 'N';
                lastname = 'A';

                return <div key={index} className="detailsMultiResource mr10">
                  <span className="iconEmptyResourceTable icons aligncenter fs12 blue">{firstname + lastname}</span>
                  <span className="fs12 grey ml10">{resource.Label}</span>
                </div>;
              }
              else if(resource.Label === '- To be assigned -') {
                firstname = 'T';
                lastname = 'B';

                return <div key={index} className="detailsMultiResource mr10">
                  <span className="iconEmptyResourceTable icons aligncenter fs12 blue">{firstname + lastname}</span>
                  <span className="fs12 grey ml10">{resource.Label}</span>
                </div>;
              }
              else if(resource.Label) {
                const split = resource.Label.split(' ');
        
                if(split.length === 1) {
                  firstname = resource.Label.split(' ')[0].substring(0,1);
                  lastname = '';
                }
                else if(split.length === 2) {
                  firstname = resource.Label.split(' ')[0].substring(0,1);
                  lastname = resource.Label.split(' ')[1].substring(0,1);
                }
                else {
                  firstname = resource.Label.split(' ')[0].substring(0,1);
                  lastname = resource.Label.split(' ')[split.length-1].substring(0,1);
                }

                if(resource.Id === 0) {
                  return <div key={index} className="detailsMultiResource mr10">{resource.Label}</div>;
                }
                else {
                  return <div key={index} className="detailsMultiResource mr10">
                    <span className="iconResourceTable icons aligncenter fs12 white">{firstname + lastname}</span>
                    <span className="fs12 grey ml10">{resource.Label}</span>
                  </div>;
                }
              }
            })}
          </div>
        }
        else if(view === 'Popover') {
          let firstname;
          let lastname;

          if(value.Label === '- Not assigned -') {
            firstname = 'N';
            lastname = 'A';

            return <div className="align-baseline">
              <span className="iconEmptyResourceTable icons aligncenter fs12 blue">{firstname + lastname}</span>
              <span className="fs12 grey ml10">{value.Label}</span>
            </div>;
          }
          else if(value.Label === '- To be assigned -') {
            firstname = 'T';
            lastname = 'B';

            return <div className="align-baseline">
              <span className="iconEmptyResourceTable icons aligncenter fs12 blue">{firstname + lastname}</span>
              <span className="fs12 grey ml10">{value.Label}</span>
            </div>;
          }
          else if(value.Label) {
            let split = value.Label.split(' ');

            if(split.length === 1) {
              firstname = value.Label.split(' ')[0].substring(0,1);
              lastname = '';
            }
            else if(split.length === 2) {
              firstname = value.Label.split(' ')[0].substring(0,1);
              lastname = value.Label.split(' ')[1].substring(0,1);
            }
            else {
              firstname = value.Label.split(' ')[0].substring(0,1);
              lastname = value.Label.split(' ')[split.length-1].substring(0,1);
            }

            return <div className="align-baseline">
              <span className="iconResourceTable icons aligncenter fs12 white">{firstname + lastname}</span>
              <span className="fs12 grey ml10">{value.Label}</span>
            </div>;
          }
        }
        else if(view === 'Minicard') {
          return <div className="inline-flex">
            {value.map((resource, index) => {
              let firstname, lastname;

              if(resource.Label === '- Not assigned -') {
                firstname = 'N';
                lastname = 'A';

                return <div className="iconHollowCircle fs13 mr10">
                  {['right'].map(placement => (
                    <OverlayTrigger key={placement} placement={placement} overlay={<Tooltip id={`tooltip-${placement}`}><strong>{value.Label}</strong></Tooltip>} style={{padding: '2px 10px', color: 'black', borderRadius: 3}}>
                      <Card.Text className="blue">{firstname + lastname}</Card.Text>
                    </OverlayTrigger>
                  ))}
                </div>;
              }
              else if(resource.Label === '- To be assigned -') {
                firstname = 'T';
                lastname = 'B';

                return <div className="iconHollowCircle fs13 mr10">
                  {['right'].map(placement => (
                    <OverlayTrigger key={placement} placement={placement} overlay={<Tooltip id={`tooltip-${placement}`}><strong>{value.Label}</strong></Tooltip>} style={{padding: '2px 10px', color: 'black', borderRadius: 3}}>
                      <Card.Text className="blue">{firstname + lastname}</Card.Text>
                    </OverlayTrigger>
                  ))}
                </div>;
              }
              else if(resource.Label) {
                const split = resource.Label.split(' ');
        
                if(split.length === 1) {
                  firstname = resource.Label.split(' ')[0].substring(0,1);
                  lastname = '';
                }
                else if(split.length === 2) {
                  firstname = resource.Label.split(' ')[0].substring(0,1);
                  lastname = resource.Label.split(' ')[1].substring(0,1);
                }
                else {
                  firstname = resource.Label.split(' ')[0].substring(0,1);
                  lastname = resource.Label.split(' ')[split.length-1].substring(0,1);
                }

                if(resource.Id === 0) {
                  return <div key={index} className="detailsMultiResource mr10">{resource.Label}</div>;
                }
                else {
                  return <div className="iconFullCircleBlue fs13 mr10">
                    {['right'].map(placement => (
                      <OverlayTrigger key={placement} placement={placement} overlay={<Tooltip id={`tooltip-${placement}`}><strong>{resource.Label}</strong></Tooltip>} style={{padding: '2px 10px', color: 'white', borderRadius: 3}}>
                        <Card.Text className="white">{firstname + lastname}</Card.Text>
                      </OverlayTrigger>
                    ))}
                  </div>;
                }
              }
            })}
          </div>
        }
      }
      // Card Level/Levels
      else if(axe === 'Card_Level' || axe === 'Levels') {
        switch(value.Id) {
          // Business Line
          case 1:
              return <div className="axeTable">
                <span className="iconBusinessLine iconsPopover mr5" alt="Business Line"/>
                <span className="">{value.Label}</span>
              </div>;
          // Project
          case 2:
              return <div className="axeTable">
                <span className="iconProject iconsPopover mr5" alt="Project"/>
                <span className="">{value.Label}</span>
              </div>;
          // Workpackage
          case 3:
              return <div className="axeTable">
                <span className="iconWorkpackage iconsPopover mr5" alt="Workpackage"/>
                <span className="">{value.Label}</span>
              </div>;
          // Action
          case 4:
              return <div className="axeTable">
                <span className="iconAction iconsPopover mr5" alt="Action"/>
                <span className="">{value.Label}</span>
              </div>;
          // Task
          case 5:
              return <div className="axeTable">
                <span className="iconTask iconsPopover mr5" alt="Task"/>
                <span className="">{value.Label}</span>
              </div>;
          // Risk
          case 6:
              return <div className="axeTable">
                <span className="iconRisks iconsPopover mr5" alt="Risk"/>
                <span className="">{value.Label}</span>
              </div>;
          // Issue
          case 7:
              return <div className="axeTable">
                <span className="iconIssues iconsPopover mr5" alt="Issue"/>
                <span className="">{value.Label}</span>
              </div>;
          // Decision
          case 8:
              return <div className="axeTable">
                <span className="iconDecisions iconsPopover mr5" alt="Decision"/>
                <span className="">{value.Label}</span>
              </div>;
          // Meeting
          case 9:
              return <div className="axeTable">
                <span className="iconMeetings iconsPopover mr5" alt="Meeting"/>
                <span className="">{value.Label}</span>
              </div>;
          // Resource
          case 12:
              return <div className="axeTable">
                <span className="iconResource iconsPopover mr5" alt="Resource"/>
                <span className="">{value.Label}</span>
              </div>;
          // Entity
          case 13:
              return <div className="axeTable">
                <span className="iconEntity iconsPopover mr5" alt="Entity"/>
                <span className="">{value.Label}</span>
              </div>;
          // Budget Code
          case 17:
              return <div className="axeTable">
                <span className="iconBudgetCode iconsPopover mr5" alt="BudgetCode"/>
                <span className="">{value.Label}</span>
              </div>;
          // None
          case 29:
            return <div className="axeTable">
              <span className="iconEmpty iconsPopover mr5" alt="None"/>
              <span className="">{value.Label}</span>
            </div>;
          default:
              return null;
        }
      }
      // Data & Meteo Freshness
      else if(axe === 'Data_Freshness' || axe === 'Meteo_Freshness') {
        if(value === "1") {
          if(view === 'Block') {
            return <div className="fs12 green bold"><span className="iconFullCircleGreen icons mr10"></span>{Traduction.translate(language, 'week')}</div>;
          }
          else if(view === 'Miniblock') {
            return <div className="fs12 green bold"><span className="iconFullCircleGreen iconsMiniblock mr10"></span>{Traduction.translate(language, 'week')}</div>;
          }
        }
        else if(value === "2") {
          if(view === 'Block') {
            return <div className="fs12 orange bold"><span className="iconFullCircleOrange icons mr10"></span>{Traduction.translate(language, 'month')}</div>;
          }
          else if(view === 'Miniblock') {
            return <div className="fs12 orange bold"><span className="iconFullCircleOrange iconsMiniblock mr10"></span>{Traduction.translate(language, 'month')}</div>;
          }
        } 
        else if(value === "3") {
          if(view === 'Block') {
            return <div className="fs12 red bold"><span className="iconFullCircleRed icons mr10"></span>{Traduction.translate(language, 'more_than_a_month')}</div>;
          }
          else if(view === 'Miniblock') {
            return <div className="fs12 red bold"><span className="iconFullCircleRed iconsMiniblock mr10"></span>{Traduction.translate(language, 'more_than_a_month')}</div>;
          }
        }
        else if(value === "4") {
          if(view === 'Block') {
            return <div className="fs12 grey bold"><span className="iconCircleGrey icons mr10"></span></div>;
          }
          else if(view === 'Miniblock') {
            return <div className="fs12 grey bold"><span className="iconCircleGrey iconsMiniblock mr10"></span></div>;
          }
        }
      }
      // Internal/External
      else if(axe === 'External') {
        if(value === 'False') {
          return <div className="axe bordered blue">{Traduction.translate(language, 'internal')}</div>;
        }
        else if(value === 'True') {
          return <div className="axe bordered blue">{Traduction.translate(language, 'external')}</div>;
        }
      }
      // Language
      else if(axe === 'Language') {
        if(value.Label === 'English') {
          return <div className="axe bordered blue brd-blue bg-white align-items-center"><img className="iconsLanguage mr5" src={IconEnglish} alt="English"/><div>{value.Label}</div></div>;
        }
        else if(value.Label === 'Français') {
          return <div className="axe bordered blue brd-blue bg-white align-items-center"><img className="iconsLanguage mr5" src={IconFrance} alt="France"/><div>{value.Label}</div></div>;
        }
        else if(value.Label === 'Spanish') {
          return <div className="axe bordered blue brd-blue bg-white align-items-center"><img className="iconsLanguage mr5" src={IconSpain} alt="Spain"/><div>{value.Label}</div></div>;
        }
      }
      // Timeline
      else if(axe === 'Timeline') {
        // Status (0: Completed, 1: In Progress, 2: Not Started, 3: Delayed)
        switch(value.Id) {
          case 0:
            if(view === 'Popover') {
              return <div className="axe brd-radius bg-green white">{value.Label}</div>;
            }
          case 1:
              if(view === 'Popover') {
                return <div className="axe brd-radius bg-blue white">{value.Label}</div>;
              }
          case 2:
              if(view === 'Popover') {
                return <div className="axe brd-radius bg-grey white">{value.Label}</div>;
              }
          case 3:
              if(view === 'Popover') {
                return <div className="axe brd-radius bg-red white">{value.Label}</div>;
              }
          case 4:
              if(view === 'Popover') {
                return <div className="axe brd-radius bordered grey">{value.Label}</div>;
              }
          case 5:
              if(view === 'Popover') {
                return <div className="axe brd-radius bordered orange">{value.Label}</div>;
              }
          default:
              return null;
        }
      }
      // Warnings
      else if(axe === 'Warning') {
        if(value > 0) {
          return <Badge className="smallBadge bg-red" pill><i className="iconWarningWhite verysmallIcons"/><span className="minicardWarning white bold">{value}</span></Badge>;
        }
        else {
          return <Badge className="smallBadge bg-white brd-grey" pill><i className="iconWarningGrey verysmallIcons"/><span className="minicardWarning grey bold">{value}</span></Badge>;
        }
      }
      else {
        let backColor, foreColor, borderColor;

        // Back, Fore & Border Color
        if(!value.BackColor) {
          backColor = '#FFFFFF';
        }
        else {
          backColor = value.BackColor;
        }
        if(!value.ForeColor) {
          foreColor = '#0088C7';
          borderColor = '#0088C7';
        }
        else {
          if((value.ForeColor === '#FFFFFF' || value.ForeColor === '#ffffff')) {
            borderColor = value.BackColor;
          }
          else {
            borderColor = value.ForeColor;
          }

          foreColor = value.ForeColor;
        }

        // Type String
        if(typeof value === 'string') {
          if(view === 'Block') {
            if(value === '- NA -') {
              return <div className="axe brd-dashed very-light-grey bold">{value}</div>;
            }
            else {
              return <div className="axe bordered bold" style={{ background: backColor, color: foreColor, borderColor: borderColor }}>{value}</div>;
            }
          }
          else if(view === 'Kanban' || view === 'Popover') {
            return <div className="axe bordered" style={{ background: backColor, color: foreColor, borderColor: borderColor }}>{value}</div>;
          }
          else if(view === 'Miniblock') {
            return <div className="miniblockAxe">{value}</div>;
          }
          else if(view === 'Minicard') {
            if(value === '- NA -') {
              return <div className="axe brd-dashed very-light-grey">{value}</div>;
            }
            else {
              return <div className="axe bordered" style={{ background: backColor, color: foreColor, borderColor: borderColor }}>{value}</div>;
            }
          }
        }
        // Type Object
        else if(Object.entries(value).length > 0) {
          if(view === 'Block') {
            if(value.Label === '- NA -') {
              return <div className="axe brd-dashed very-light-grey">{value.Label}</div>;
            }
            else {
              return <div className="axe bordered" style={{ background: backColor, color: foreColor, borderColor: borderColor }}>{value.Label}</div>;
            }
          }
          else if(view === 'Kanban' || view === 'Popover') {
            if(value.Label === '- NA -' || value.Label === '- Not assigned -') {
              return <div className="axe brd-dashed very-light-grey">{value.Label}</div>;
            }
            else {
              return <div className="axe bordered" style={{ background: backColor, color: foreColor, borderColor: borderColor }}>{value.Label}</div>;
            }
          }
          else if(view === 'Miniblock') {
            if(value.Label === '- NA -') {
              return <div className="miniblockAxe brd-dashed very-light-grey">{value.Label}</div>;
            }
            else {
              return <div className="miniblockAxe bordered" style={{ background: backColor, color: foreColor, borderColor: borderColor }}>{value.Label}</div>;
            }
          }
          else if(view === 'Minicard') {
            if(value.Label === '- NA -') {
              return <div className="axe brd-dashed very-light-grey">{value.Label}</div>;
            }
            else {
              let text = value.Label;

              if(value.Label.length > 30) {
                text = value.Label.substring(0, 30) + '...';
              }

              return <div className="axe bordered" style={{ background: backColor, color: foreColor, borderColor: borderColor }}>{text}</div>;
            }
          }
        }
      }
    }
    else {
      if(type === 'Rating') {
        if(view === 'Block') {
          return <div className=""></div>;
        }
        else if(view === 'Kanban' || view === 'Popover') {
          return <div className="">
            <span className="iconRatingEmpty iconsFilter mr10"></span>
            <span className="iconRatingEmpty iconsFilter mr10"></span>
            <span className="iconRatingEmpty iconsFilter mr10"></span>
            <span className="iconRatingEmpty iconsFilter mr10"></span>
            <span className="iconRatingEmpty iconsFilter mr10"></span>
          </div>;
        }
        else if(view === 'Miniblock') {
          return <div className="">
            <span className="iconRatingEmpty iconsMiniblock mr10"></span>
            <span className="iconRatingEmpty iconsMiniblock mr10"></span>
            <span className="iconRatingEmpty iconsMiniblock mr10"></span>
            <span className="iconRatingEmpty iconsMiniblock mr10"></span>
            <span className="iconRatingEmpty iconsMiniblock mr10"></span>
          </div>;
        }
        else if(view === 'Minicard') {
          return <div className="">
            <span className="iconRatingEmpty iconsMinicardKanban mr10"></span>
            <span className="iconRatingEmpty iconsMinicardKanban mr10"></span>
            <span className="iconRatingEmpty iconsMinicardKanban mr10"></span>
            <span className="iconRatingEmpty iconsMinicardKanban mr10"></span>
            <span className="iconRatingEmpty iconsMinicardKanban mr10"></span>
          </div>;
        }
      }
      else {
        return null;
      }
    }
  }

  render() {
    const { language, axe, type, value, view } = this.state;

    return (
      <div className={(view === 'Minicard' && axe !== 'Project' ? "flex center" : "flex width100p")}>
        {this.buildAxe(axe, type, value, view)}
      </div>
    );
  }
}

export default Axe;