import React, {Component} from 'react';
import { Button, Form, OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
import { ListBoxComponent, Inject, CheckBoxSelection } from '@syncfusion/ej2-react-dropdowns';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';

const API = '/WebAppService/GetViewAvailableColumns';

class FiltersColumnChooser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: null,
      authId: null,
      language: null,
      itemId: null,
      itemType: null,
      blockType: null,
      currentView: {},
      columns: [],
      columnValues: '',
      columnChooserPopup: false,
      search: ''
    };
    
    // Data Structure
    this.getAvailableColumns = this.getAvailableColumns.bind(this);

    // Actions
    this.created = this.created.bind(this);
    this.change = this.change.bind(this);
    this.drop = this.drop.bind(this);
    this.applyColumns = this.applyColumns.bind(this);
    this.searchColumn = this.searchColumn.bind(this);
    this.openColumnChooserPopup = this.openColumnChooserPopup.bind(this);

    // Template
    this.templateColumnChooser = this.templateColumnChooser.bind(this);
    this.closeColumnChooserPopup = this.closeColumnChooserPopup.bind(this);

    this.search = React.createRef();
  }

  componentDidMount() {
    const login = Authentication.getCookie('login');
    const authId = Authentication.getCookie('authId');
    const language = Authentication.getCookie('language');
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const blockType = this.props.BlockType;
    const currentView = this.props.CurrentView;
    // let columnValues = "";

    // Get Current View Columns List
    // if(currentView.Parameters.find(param => param.Name === 'Columns')) {
    //   columnValues = (currentView.Parameters.find(param => param.Name === 'Columns').Value).split(',');
    // }

    // Search Auto focus
    // setTimeout(() => { this.search.current.focus() }, 1);
    
    this.setState({ login, authId, language, itemId, itemType, blockType, currentView });
  }

  componentDidUpdate(prevProps) {
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const blockType = this.props.BlockType;
    const currentView = this.props.CurrentView;
    // let columnValues = "";

    if(itemId !== prevProps.ItemId || itemType !== prevProps.ItemType || blockType !== prevProps.BlockType) {
      this.setState({ itemId, itemType, blockType });
    }
    if(JSON.stringify(this.props.CurrentView) !== JSON.stringify(prevProps.CurrentView)) {
      // Get Current View Columns List
      // if(currentView.Parameters.find(param => param.Name === 'Columns')) {
      //   columnValues = (currentView.Parameters.find(param => param.Name === 'Columns').Value).split(',');
      // }

      this.setState({ currentView });
    }
  }

  // Get Available Columns from the API
  async getAvailableColumns(login, authId, itemId, itemType, blockType, view) {
    const language = this.state.language;
    
    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': itemType,
        'ItemId': itemId,
        'BlockType': blockType,
        'View': view,
        'WithData': false,
        'WithInformation': true,
        'InactiveData': false
      })
    };

    try{
      const response = await fetch(API, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const result = data.GetViewAvailableColumnsResult;

      if(result) {
        this.setState({ columns: result });
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    } catch(error) {
      this.setState({ error });
    }
  }

  created() {
    const currentView = this.state.currentView;
    const columns = this.state.columns;
    let checkedLabels = [];
    let checkedNames;

    if(this.listBox) {
      if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Columns')) {
        // Get Current View Columns List
        if(currentView.Parameters.find(param => param.Name === 'Columns')) {
          checkedNames = (currentView.Parameters.find(param => param.Name === 'Columns').Value).split(',');
        }

        // Get corresponding Labels to check Columns in ListBox component
        checkedNames.forEach(name => {
          if(columns.find(column => column.FieldName === name)) {
            checkedLabels.push(columns.find(column => column.FieldName === name).Label);
          }
        });
      }

      this.listBox.value = checkedLabels;
    }
  }

  change(args) {
    const columns = this.state.columns;
    const items = args.items;
    let dataSource = [], checkedValues = [], values = '';

    // Get Columns Name corresponding to checked Labels
    items.forEach(item => {
      checkedValues.push(item.value);
    });

    if(this.listBox) {
      // Get Columns List from ListBox checked
      checkedValues.forEach((value, index) => {
        if(index < checkedValues.length - 1) {
          values = values.concat(value, ',');
        }
        else {
          values = values.concat(value);
        }
      });

      // Add Checked Columns
      dataSource = items;

      // Add other Columns
      columns.forEach(column => {
        // Check if Column is not already include in Checkbox list
        if(!items.find(item => item.value === column.FieldName)) {
          // Exclude empty Labels & Column Index, Open Day, Weekend, Bank Holiday
          if(column.Label && column.FieldName !== 'Index' && (column.FieldType !== 'OpenDay' && column.FieldType !== 'Weekend' && column.FieldType !== 'BankHoliday')) {
            dataSource.push({ text: column.Label, value: column.FieldName });
          }
        }
      });

      // Update Listbox DataSource
      this.listBox.dataSource = dataSource;
    }

    // Call the event from the Parent component through the props with new Columns selected
    // this.props.onColumnsAdd(values);

    this.setState({ columnValues: values, search: '' });
  }

  drop(args) {
    // const currentView = this.state.currentView;
    const columnValues = this.state.columnValues;
    const items = args.source.currentData;
    let valuesList = [], values = '';
    // let checkedValues;

    // Get Current View Columns List
    // if(currentView.Parameters.find(param => param.Name === 'Columns')) {
    //   checkedValues = (currentView.Parameters.find(param => param.Name === 'Columns').Value).split(',');
    // }

    // Loop through the ListBox Columns
    // this.listBox.listData.forEach((column, index) => {
    //   if(checkedValues.find(item => item === column.value)) {
    //     // Update Columns position after Drag&Drop
    //     valuesList[index] = checkedValues.find(item => item === column.value);
    //   }
    // });

    // Get Columns Name corresponding to checked Labels
    items.forEach(item => {
      // Check if Value is included in CurrentView
      if(columnValues.split(',').find(value => value === item.value)) {
      // if(checkedValues.find(value => value === item.value)) {
        valuesList.push(item.value);
      }
    });

    if(this.listBox) {
      // Get Columns List from ListBox checked
      valuesList.forEach((value, index) => {
        if(index < valuesList.length - 1) {
          values = values.concat(value, ',');
        }
        else {
          values = values.concat(value);
        }
      });

      // Update Listbox DataSource
      this.listBox.dataSource = this.listBox.listData;
    }

    // Call the event from the Parent component through the props with new Columns changes
    // this.props.onColumnsChange(values);

    this.setState({ columnValues: values });
  }

  applyColumns() {
    const columnValues = this.state.columnValues;

    // Call the event from the Parent component through the props with new Columns changes
    this.props.onColumnsChange(columnValues);

    this.setState({ columnChooserPopup: false, search: '' });
  }

  searchColumn(event) {
    const { columns, columnValues } = this.state;
    // const currentView = this.state.currentView;
    const search = event.target.value;
    let filtered = [];
    // let checkedValues;

    // Get Current View Columns List
    // if(currentView.Parameters.find(param => param.Name === 'Columns')) {
    //   checkedValues = (currentView.Parameters.find(param => param.Name === 'Columns').Value).split(',');
    // }

    if(this.listBox) {
      this.listBox.dataSource.forEach(column => {
        if(columnValues.split(',').find(value => value === column.value)) {
        // if(checkedValues.find(value => value === column.value)) {
          filtered.push(column);
        }
      });

      columns.filter(column => column.Label.toLowerCase().includes(search.toLowerCase())).forEach(data => {
        if(!filtered.find(column => column.text === data.Label)) {
          filtered.push({ text: data.Label, value: data.FieldName });
        }
      });

      this.listBox.dataSource = filtered;
    }

    this.setState({ search });
  }

  async openColumnChooserPopup() {
    const { login, authId, itemId, itemType, blockType, currentView } = this.state;
    let columnValues = "";

    // Get View Available Columns
    await this.getAvailableColumns(login, authId, itemId, itemType, blockType, currentView);

    // Get Current View Columns List
    if(currentView.Parameters.find(param => param.Name === 'Columns')) {
      columnValues = currentView.Parameters.find(param => param.Name === 'Columns').Value;
    }

    this.setState({ columnChooserPopup: true, columnValues });
  }

  templateColumnChooser() {
    const { language, itemId, itemType, blockType, currentView, columns, search } = this.state;
    let columnList = [], checkedList = [], columnHeader = [];

    // Get Current View Columns List
    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Columns')) {
      checkedList = (currentView.Parameters.find(param => param.Name === 'Columns').Value).split(',');
    }

    // Add Checkbox list
    checkedList.forEach(columnName => {
      // Get Column corresponding Column Header
      if(columns.find(columnHeader => columnHeader.FieldName === columnName)) {
        columnHeader = columns.find(columnHeader => columnHeader.FieldName === columnName);
        
        // Exclude empty Labels & Column Index
        if(columnHeader.Label && columnHeader.FieldName !== 'Index') {
          columnList.push({ text: columnHeader.Label, value: columnHeader.FieldName });
        }
      }
    });

    // Add other Columns
    columns.forEach(column => {
      // Check if Column is not already include in Checkbox list
      if(!checkedList.includes(column.FieldName)) {
        // Exclude empty Labels & Column Index, Open Day, Weekend, Bank Holiday
        if(column.Label && column.FieldName !== 'Index' && (column.FieldType !== 'OpenDay' && column.FieldType !== 'Weekend' && column.FieldType !== 'BankHoliday')) {
          columnList.push({ text: column.Label, value: column.FieldName });
        }
      }
    });

    return (<div className="flex flex-column popupColumnChooser">
      <div className="space-between">
        {/* Label */}
        <div className="flex-start columnChooserLabel">{Traduction.translate(language, 'choose_columns')}</div>
        {/* Search */}
        <div className="flex-end filtersColumnsSearch">
          <Form.Group className="searchBlock">
            <Form.Control type="text" id="searchFilters" name="search" value={search} placeholder={Traduction.translate(language, 'search')} autoFocus onChange={this.searchColumn} ref={this.search}/>
          </Form.Group>
        </div>
      </div>
      {/* Column Chooser Listbox */}
      <div className="columnChooserContent scrollbar-miniblock">
        <div id="" className="control-pane">
          <ListBoxComponent dataSource={columnList} height={'100%'} selectionSettings={{ showCheckbox: true }} created={this.created} change={this.change} drop={this.drop} allowDragAndDrop={true} ref={listBox=>this.listBox=listBox}>
            <Inject services={[ CheckBoxSelection ]}/>
          </ListBoxComponent>
        </div>
      </div>
      {/* Button Validate or Cancel */}
      <div className="flex flex-end align-items-center">
        <Button className="fs12 bold brd-radius mr15" variant="primary" onClick={() => this.applyColumns()}>{Traduction.translate(language, 'validate')}</Button>
        <Button className="fs12 bold brd-radius" variant="warning" onClick={() => this.closeColumnChooserPopup()}>{Traduction.translate(language, 'cancel')}</Button>
      </div>
    </div>);
  }

  closeColumnChooserPopup() {
    this.setState({ columnChooserPopup: false, search: '' });
  }
  
  render() {
    const { language, currentView, columns, columnChooserPopup } = this.state;

    return (
      <div className="">
        {/* Popup Column Chooser */}
        {(columnChooserPopup === true) && <div className="columnChooserPopup">
          <div className="columnChooserInnerPopup">{this.templateColumnChooser()}</div>
        </div>}

        {['top'].map(placement => (
          <OverlayTrigger key={placement} placement={placement} overlay={<Tooltip id={`tooltip-${placement}`}><strong>{Traduction.translate(language, 'choose_columns')}</strong></Tooltip>}>
            <div className="filtersColumnChooser" onClick={() => this.openColumnChooserPopup()}>
              <span className="iconColumns iconsFilter cursor"/>
            </div>
          </OverlayTrigger>
        ))}
      </div>
    )
  }
}

export default FiltersColumnChooser;