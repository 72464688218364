import React, {Component} from 'react';
import '../Css/App.css';
import MiniTable from './MiniTable';
import MiniPivot from './MiniPivot';
import MiniPlanning from './MiniPlanning';
import MiniChart from './MiniChart.js';
import MiniMeetingCalendar from './MiniMeetingCalendar';
import EmptyBlockMeetings from '../Images/EmptyBlockMeetings.png';

class MiniBlockMeetings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itemId: null,
      itemType: null,
      itemTitle: null,
      blockType: null,
      blockLabel: null,
      blockContent: {},
      filters: null,
      currentView: {},
      views: [],
      tables: [],
      columns: [],
      rows: [],
      chart: {},
      emptyBlock: null
    };
    
    this.buildBlockData = this.buildBlockData.bind(this);
    this.convertStringtoBoolean = this.convertStringtoBoolean.bind(this);
    this.formatDateFr = this.formatDateFr.bind(this);
  }

  componentDidMount() {
    const itemId = this.props.Id;
    const itemType = this.props.Type;
    const itemTitle = this.props.Title;
    const blockType = this.props.BlockType;
    const blockLabel = this.props.BlockLabel;
    const blockContent = this.props.BlockContent;
    const filters = this.props.Filters;

    // Get Block Content Data
    this.buildBlockData(blockContent);

    this.setState({ itemId, itemType, itemTitle, blockType, blockLabel, blockContent, filters });
  }

  componentDidUpdate(prevProps) {
    const itemId = this.props.Id;
    const itemType = this.props.Type;
    const blockContent = this.props.BlockContent;

    // if(this.props.BlockContent !== prevProps.BlockContent) {
    //   // Get Block Content Data
    //   this.buildBlockData(blockContent);

    //   this.setState({ blockContent });
    // }
  }

  buildBlockData(blockContent) {
    let views = [], currentView = {}, columns = [], rows = [], chart = {};
    let emptyBlock;

    if(blockContent) {
      // Views
      if(blockContent.Views) {
        views = blockContent.Views;

        // Find CurrentView in View list
        if(views.find(view => view.ViewId === blockContent.DefaultViewId)) {
          currentView = views.find(view => view.ViewId === blockContent.DefaultViewId);
        }
      }

      // Tables
      if(blockContent.Tables && blockContent.Tables.length > 0) {
        columns = blockContent.Tables[0].ColumnHeaders;
        rows = blockContent.Tables[0].Rows;
      }

      // Chart
      if(blockContent.Chart) {
        chart = blockContent.Chart;
      }

      // Header
      // if(blockContent.Headers) {
      //   if(blockContent.Headers.find(element => element.FieldName === 'EmptyBlock')) {
      //     emptyBlock = blockContent.Headers.find(element => element.FieldName === 'EmptyBlock').FieldValue;
      //   }
      // }

      this.setState({ currentView, views, columns, rows, chart, emptyBlock });
    }
  }

  convertStringtoBoolean(string) {
    if(string === 'true') {
      return true;
    }
    else {
      return false;
    }
  }

  // Formatting Date to French format
  formatDateFr(date) {
    let formattedDate;

    if(date) {
      const split = date.split('/');
      const day = split[1];
      const month = split[0]-1; // Between 0 and 11
      const year = split[2];
      
      formattedDate = new Date(Date.UTC(year, month, day)).toLocaleString().substring(0,10);
    }

    return formattedDate;
  }
  
  render() {
    const { itemId, itemType, itemTitle, blockType, blockLabel, blockContent, filters, currentView, views, columns, rows, chart, emptyBlock } = this.state;
    let visibleComponent;

    // Initialise Visible Component
    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'VisibleComponent')) {
      visibleComponent = this.convertStringtoBoolean(currentView.Parameters.find(param => param.Name === 'VisibleComponent').Value);
    }
    else {
      visibleComponent = true;
    }

    return (
      <div className="miniblock">
        <div className="miniblockContent">
          {/* Mini Components */}
          {columns && rows.length > 0 && <div className={(!visibleComponent ? "miniblockTable" : "miniblockComponent")}>
          {/* {columns && rows.length > 0 && <div className="miniblockTable scrollbar-miniblock"> */}
            {visibleComponent && currentView.ViewType === 0 && <MiniMeetingCalendar ref={this.calendar} CurrentView={currentView} Columns={columns} Rows={rows}></MiniMeetingCalendar>}
            {!visibleComponent && currentView.ViewType === 0 && <MiniTable ItemId={itemId} ItemType={itemType} Title={itemTitle} BlockType={blockType} Editable={false} Pagging={false} CurrentView={currentView} Columns={columns} Rows={rows}></MiniTable>}
            {currentView.ViewType === 1 && <MiniPlanning ItemId={itemId} ItemType={itemType} Title={itemTitle} BlockType={blockType} Editable={false} CurrentView={currentView} Columns={columns} Rows={rows}></MiniPlanning>}
            {currentView.ViewType === 3 && <MiniPivot ItemId={itemId} ItemType={itemType} Title={itemTitle} BlockType={blockType} Editable={false} CurrentView={currentView} Columns={columns} Rows={rows}></MiniPivot>}
          </div>}

          {/* Mini Chart */}
          {chart.Series && chart.Series.length > 0 && <div className="miniblockTable">
            {currentView.ViewType === 7 && <MiniChart ItemId={itemId} ItemType={itemType} Title={itemTitle} BlockType={blockType} CurrentView={currentView} Chart={chart}></MiniChart>}
          </div>}

          {/* Empty Block */}
          {/* {Array.isArray(rows) && rows.length === 0 && filters == 0 && 
            <div className="miniblockEmpty scrollbar-miniblock">
              <div className=""><img className="iconsEmptyBlock" src={EmptyBlockMeetings} alt="EmptyBlock Meetings"/></div>
              <div className=""><div className="miniblockEmptyText" dangerouslySetInnerHTML={{ __html: emptyBlock }}></div></div>
            </div>
          } */}
        </div>
      </div>
    )
  }
}

export default MiniBlockMeetings;