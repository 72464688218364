import React, {Component} from 'react';
import { Button, Form } from 'react-bootstrap';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';

class PopoverEditChartIndicator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: null,
      itemId: null,
      itemType: null,
      index: null,
      chartIndicator: null,
      availableFields: [],
      search: ''
    };

    this.searchItem = this.searchItem.bind(this);
    this.pressKey = this.pressKey.bind(this);
    this.addChartIndicator = this.addChartIndicator.bind(this);
    this.updateChartIndicator = this.updateChartIndicator.bind(this);

    this.search = React.createRef();
  }

  componentDidMount() {
    const language = Authentication.getCookie('language');
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const index = this.props.Index;
    const chartIndicator = this.props.ChartIndicator;
    const availableFields = this.props.AvailableFields;

    // Search Auto focus
    setTimeout(() => { this.search.current.focus() }, 1);

    this.setState({ language, itemId, itemType, index, chartIndicator, availableFields });
  }

  componentDidUpdate(prevProps) {
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const index = this.props.Index;
    const chartIndicator = this.props.ChartIndicator;
    const availableFields = this.props.AvailableFields;
  }

  searchItem(event) {
    const search = event.target.value;

    this.setState({ search });
  }

  pressKey(event) {
    const index = this.state.index;
    const availableFields = this.state.availableFields;
    const search = event.target.value;
    let chartIndicator;

    if(event.key === 'Enter' && search !== '') {
      if(availableFields.find(value => value.Label.toLowerCase().includes(search.toLowerCase()))) {
        chartIndicator = availableFields.find(value => value.Label.toLowerCase().includes(search.toLowerCase()));

        // Close Popover
        if(document.getElementById("popover-basic")) {
          document.getElementById("popover-basic").classList.remove("show");
        }

        // Call the event from the Parent component through the props
        this.props.onChartIndicatorEdit(chartIndicator, index);

        this.setState({ chartIndicator });
      }
    }
  }

  addChartIndicator(chartIndicator) {
    // Close Popover
    if(document.getElementById("popover-basic")) {
      document.getElementById("popover-basic").classList.remove("show");
    }

    // Call the event from the Parent component through the props
    this.props.onChartIndicatorAdd(chartIndicator);
  }

  updateChartIndicator(chartIndicator) {
    const index = this.state.index;
    
    // Close Popover
    if(document.getElementById("popover-basic")) {
      document.getElementById("popover-basic").classList.remove("show");
    }

    // Call the event from the Parent component through the props
    this.props.onChartIndicatorEdit(chartIndicator, index);
  }

  render() {
    const { language, itemId, itemType, index, chartIndicator, availableFields, search } = this.state;

    return (
      <div className="chartTypeEditPopover">
        {/* Search */}
        <div className="chartTypeEditSearch">
          <Form.Group className="searchBlock">
            <Form.Control type="text" id="searchFilters" name="search" value={search} placeholder={Traduction.translate(language, 'search')} autoFocus onChange={this.searchItem} onKeyPress={this.pressKey} ref={this.search}/>
          </Form.Group>
        </div>
        {/* Chart Row */}
        <div className="chartTypeEditValues scrollbar-y">
          {/* Current Value */}
          {chartIndicator && <div className="chartTypeCurrentValue">{chartIndicator.Label}</div>}
          {/* Border */}
          <div className="popoverBorder"></div>
          {/* Other Values */}
          {availableFields.map((field, index) => {
            if(chartIndicator && chartIndicator.Id !== field.Id) {
              if(search === "" || field.Label.toLowerCase().includes(search.toLowerCase())) {
                return <div key={index} className="chartTypeEditValue" onClick={() => this.updateChartIndicator(field)}>
                  <span className="">{field.Label}</span>
                </div>;
              }
            }
            else {
              if(search === "" || field.Label.toLowerCase().includes(search.toLowerCase())) {
                return <div key={index} className="chartTypeEditValue" onClick={() => this.addChartIndicator(field)}>
                  <span className="">{field.Label}</span>
                </div>;
              }
            }
          })}
        </div>
      </div>
    );
  }
}

export default PopoverEditChartIndicator;