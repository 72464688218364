import React, {Component} from 'react';
import { Form } from 'react-bootstrap';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';
import Axe from './Axe';
import DatePickerCalendar from './DatePickerCalendar';
import Progress from './Progress';
import Timeline from './Timeline';

class MiniBlockDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: null,
      formatDate: null,
      itemId: null,
      itemType: null,
      itemTitle: null,
      blockType: null,
      blockLabel: null,
      blockContent: {},
      currentView: {},
      views: [],
      tables: [],
      columns: [],
      rows: [],
      fields: [],
      visibleLabels: true
    };

    // Data Structure
    this.buildBlockData = this.buildBlockData.bind(this);
    this.getFields = this.getFields.bind(this);
    this.getFieldChecked = this.getFieldChecked.bind(this);
    this.convertStringtoBoolean = this.convertStringtoBoolean.bind(this);
    this.formatDateEn = this.formatDateEn.bind(this);
    this.formatDateFr = this.formatDateFr.bind(this);
    this.formatDateKr = this.formatDateKr.bind(this);
  }

  componentDidMount() {
    const language = Authentication.getCookie('language');
    const formatDate = Authentication.getCookie('formatDate');
    const itemId = this.props.Id;
    const itemType = this.props.Type;
    const itemTitle = this.props.Title;
    const blockType = this.props.BlockType;
    const blockLabel = this.props.BlockLabel;
    const blockContent = this.props.BlockContent;

    // Get Block Content Data
    this.buildBlockData(blockContent);

    this.setState({ language, formatDate, itemId, itemType, itemTitle, blockType, blockLabel, blockContent });
  }

  componentDidUpdate(prevProps) {
    const itemId = this.props.Id;
    const itemType = this.props.Type;
    const blockContent = this.props.BlockContent;

    if(this.props.BlockContent !== prevProps.BlockContent) {
      // Get Block Content Data
      this.buildBlockData(blockContent);

      this.setState({ blockContent });
    }
  }

  buildBlockData(blockContent) {
    let views = [], currentView = {}, columns = [], rows = [];
    let visibleLabels;

    if(blockContent) {
      // Views
      if(blockContent.Views) {
        views = blockContent.Views;

        // Find CurrentView in View list
        if(views.find(view => view.ViewId === blockContent.DefaultViewId)) {
          currentView = views.find(view => view.ViewId === blockContent.DefaultViewId);
        }

        // Initialise Visible Component
        if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'VisibleComponent')) {
          visibleLabels = this.convertStringtoBoolean(currentView.Parameters.find(param => param.Name === 'VisibleComponent').Value);
        }
        else {
          visibleLabels = true;
        }
      }

      // Tables
      if(blockContent.Tables && blockContent.Tables.length > 0) {
        columns = blockContent.Tables[0].ColumnHeaders;
        rows = blockContent.Tables[0].Rows;
      }

      // Get Fields
      let fields = this.getFields(currentView, columns, rows);

      this.setState({ currentView, views, columns, rows, fields, visibleLabels });
    }
  }

  getFields(currentView, columns, rows) {
    let columnsNames = '';
    let fields = [];

    // Get Current View Columns
    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Columns')) {
      columnsNames = (currentView.Parameters.find(param => param.Name === 'Columns').Value).split(',');
    }

    // Loop through the columns to build and add them to the Grid
    for(let i = 0; i < columnsNames.length; i++) {
      let columnHeader;

      if(columns.find(columnHeader => columnHeader.FieldName === columnsNames[i])) {
        // Get Column corresponding Column Header
        columnHeader = columns.find(columnHeader => columnHeader.FieldName === columnsNames[i]);

        if(rows[0].Cells.find(cell => cell.ColumnName === columnsNames[i])) {
          // Parse Value for Axes, Location, Reources, Meteo & Trend
          if(columnHeader.FieldType === 'Axe' || columnHeader.FieldType === 'Location' || columnHeader.FieldType === 'Resource' || columnHeader.FieldName === 'Meteo' || columnHeader.FieldName === 'Trend') {
            if(rows[0].Cells.find(cell => cell.ColumnName === columnsNames[i]).Value) {
              columnHeader.FieldValue = JSON.parse(rows[0].Cells.find(cell => cell.ColumnName === columnsNames[i]).Value);
            }
            else {
              if(columnHeader.FieldName === 'HashTag') {
                columnHeader.FieldValue = [];
              }
              else {
                columnHeader.FieldValue = "";
              }
            }
          }
          // Axis Table
          else if(columnHeader.FieldType === 'AxisTable' || columnHeader.FieldType === 'Files' || columnHeader.FieldType === 'ResourceTable') {
            if(rows[0].Cells.find(cell => cell.ColumnName === columnsNames[i]).Value) {
              columnHeader.FieldValue = JSON.parse(rows[0].Cells.find(cell => cell.ColumnName === columnsNames[i]).Value);
            }
            else {
              columnHeader.FieldValue = [];
            }
          }
          else {
            columnHeader.FieldValue = rows[0].Cells.find(cell => cell.ColumnName === columnsNames[i]).Value;
          }
        }

        fields.push(columnHeader);
      }
    }

    return fields;
  }

  getFieldChecked(field) {
    if(field.FieldValue === 'True') {
      return true;
    }
    else {
      return false;
    }
  }

  convertStringtoBoolean(string) {
    if(string === 'true') {
      return true;
    }
    else {
      return false;
    }
  }

  // Formatting Date to English format
  formatDateEn(date) {
    let formattedDate;

    if(date) {
      // let dateFr = new Date(date).toLocaleString().split(' ')[0];
      let dateFr = new Date(date).toLocaleString("en-GB").split(/,| /)[0];

      const split = dateFr.split('/');
      const day = split[0];
      const month = split[1];
      const year = split[2];

      formattedDate = month + "/" + day + "/" + year;
    }

    return formattedDate;
  }

  // Formatting Date to French format
  formatDateFr(date) {
    let formattedDate;

    if(date) {
      // let dateFr = new Date(date).toLocaleString().split(' ')[0];
      let dateFr = new Date(date).toLocaleString("en-GB").split(/,| /)[0];

      const split = dateFr.split('/');
      const day = split[0];
      const month = split[1];
      const year = split[2];

      formattedDate = day + "/" + month + "/" + year;
    }

    return formattedDate;
  }

  // Formatting Date to Korean format
  formatDateKr(date) {
    let formattedDate;

    if(date) {
      // let dateFr = new Date(date).toLocaleString().split(' ')[0];
      let dateFr = new Date(date).toLocaleString("en-GB").split(/,| /)[0];

      const split = dateFr.split('/');
      const day = split[0];
      const month = split[1];
      const year = split[2];

      formattedDate = year + "-" + month + "-" + day;
    }

    return formattedDate;
  }
  
  render() {
    const { language, formatDate, itemId, itemType, itemTitle, blockType, blockLabel, blockContent, filters, currentView, views, columns, rows, fields, visibleLabels } = this.state;
    let status, startDate, endDate;

    // Status
    if(rows[0] && rows[0].Cells.find(cell => cell.ColumnName === 'Status')) {
      status = rows[0].Cells.find(cell => cell.ColumnName === 'Status').Value;
    }
    // StartDate
    if(rows[0] && rows[0].Cells.find(cell => cell.ColumnName === 'StartDate')) {
      startDate = rows[0].Cells.find(cell => cell.ColumnName === 'StartDate').Value;
    }
    // EndDate
    if(rows[0] && rows[0].Cells.find(cell => cell.ColumnName === 'EndDate')) {
      endDate = rows[0].Cells.find(cell => cell.ColumnName === 'EndDate').Value;
    }

    return (
      <div className="miniblock">
        {columns && rows.length > 0 && <div className="miniblockDetails scrollbar-miniblock">
          {fields.map((field, index) => 
            <div key={index} className="miniblockDetailsField row">
              {/* Labels */}
              {visibleLabels && <div className="miniblockDetailsLabel col-md-4">{field.Label}</div>}
              {/* Values */}
              <div className={"miniblockDetailsValue " + (visibleLabels ? "col-md-8" : "col-md-12")}>
                {/* Axe */}
                {field.FieldType === 'Axe' && 
                  <Axe Axe={field.FieldName} Value={field.FieldValue} View={'Miniblock'}></Axe>
                }
                {/* Axis Table */}
                {field.FieldType === 'AxisTable' && 
                  <Axe Axe={field.FieldName} Type={field.FieldType} Value={field.FieldValue} View={'Miniblock'}></Axe>
                }
                {/* Boolean */}
                {/* Administrator / Cost_Following / Reporting / Static_Data / Users_Config */}
                {(field.FieldName === 'Administrator' || field.FieldName === 'Cost_Following' || field.FieldName === 'Reporting' || field.FieldName === 'Static_Data' || field.FieldName === 'Users_Config') && <div className="">
                  {field.FieldValue === "True" && <span className="iconShieldGreen iconsMiniblock"></span>}
                  {field.FieldValue === "False" && <span className="iconShieldGrey iconsMiniblock"></span>}
                </div>}
                {/* Active */}
                {field.FieldName === 'Active' && <div className="">
                  {field.FieldValue === "True" && <span className="iconActiveTrue iconsMiniblock"></span>}
                  {field.FieldValue === "False" && <span className="iconActiveFalse iconsMiniblock"></span>}
                </div>}
                {/* External / Internal */}
                {field.FieldName === 'External' && <div className="">
                  {field.FieldValue === "True" && <span className="iconExternal iconsMiniblock"></span>}
                  {field.FieldValue === "False" && <span className="iconInternal iconsMiniblock"></span>}
                </div>}
                {/* Highlighted / Not Highlighted */}
                {field.FieldName === 'Highlighted' && <div className="">
                  {field.FieldValue === "True" && <span className="iconHighlighted iconsMiniblock ml-5 mr10"></span>}
                  {field.FieldValue === "False" && <span className="iconNotHighlighted iconsMiniblock"></span>}
                </div>}
                {/* Template */}
                {field.FieldName === 'AsTemplate' && <div className="">
                  {field.FieldValue === "True" && <div className="fs12">
                    <span className="iconCheck iconsMiniblock mr10"></span>
                    <span className="">{Traduction.translate(language, 'defined_as_template')}</span>
                  </div>}
                  {field.FieldValue === "False" && <div className="fs12">
                    <span className="iconCircleGrey iconsMiniblock mr10"></span>
                    <span className="">{Traduction.translate(language, 'not_defined_as_template')}</span>
                  </div>}
                </div>}
                {/* Others Boolean */}
                {field.FieldType === 'Boolean' && (field.FieldName !== 'Active' && field.FieldName !== 'External' && field.FieldName !== 'Highlighted' && field.FieldName !== 'Administrator' && field.FieldName !== 'Cost_Following' && field.FieldName !== 'Reporting' && field.FieldName !== 'Static_Data' && field.FieldName !== 'Users_Config' && field.FieldName !== 'AsTemplate') && <div className="">
                  {field.FieldValue === "True" && <span className="iconCheck iconsMiniblock"></span>}
                  {field.FieldValue === "False" && <span className="iconCircleGrey iconsMiniblock"></span>}
                  {/* <Form.Check id="" className="" type="checkbox" label="" checked={this.getFieldChecked(field)} disabled={true}/> */}
                </div>}
                {/* Date */}
                {field.FieldType === 'Date' && <div className="">
                  {formatDate === 'MM/DD/YYYY' && <span className="fs12">{this.formatDateEn(field.FieldValue)}</span>}
                  {formatDate === 'DD/MM/YYYY' && <span className="fs12">{this.formatDateFr(field.FieldValue)}</span>}
                  {formatDate === 'YYYY-MM-DD' && <span className="fs12">{this.formatDateKr(field.FieldValue)}</span>}
                </div>}
                {/* {field.FieldType === 'Date' && 
                  <DatePickerCalendar Date={field.FieldValue} FieldName={field.FieldName} Display={'Datepicker'} Editable={false}></DatePickerCalendar>
                } */}
                {/* Double */}
                {field.FieldType === 'Double' && (field.FieldName !== 'Meteo' && field.FieldName !== 'Trend') && field.Unit && 
                  <div className="fs12">{field.FieldValue + " " + field.Unit}</div>
                }
                {field.FieldType === 'Double' && (field.FieldName !== 'Meteo' && field.FieldName !== 'Trend') && !field.Unit && 
                  <div className="fs12">{field.FieldValue}</div>
                }
                {/* Files */}
                {field.FieldType === 'Files' && 
                  <Axe Axe={field.FieldName} Type={field.FieldType} Value={field.FieldValue} View={'Miniblock'}></Axe>
                }
                {/* HTML */}
                {field.FieldType === "HTML" && 
                  <div className="flex align-items-center fs12" dangerouslySetInnerHTML={{ __html: field.FieldValue }}></div>
                }
                {/* Link */}
                {field.FieldType === 'Link' && <a target="_self" className="" href={field.FieldValue}>
                  <div className="fs12">{field.FieldValue}</div>
                </a>}
                {/* Location */}
                {field.FieldType === 'Location' && 
                  <Axe Axe={field.FieldName} Type={field.FieldType} Value={field.FieldValue} View={'Miniblock'}></Axe>
                }
                {/* Meteo & Trend */}
                {(field.FieldName === 'Meteo' || field.FieldName === 'Trend') && 
                  <Axe Axe={field.FieldName} Value={field.FieldValue} View={'Miniblock'}></Axe>
                }
                {/* Progress */}
                {field.FieldType === 'Percentage' && 
                  <Progress Progress={field.FieldValue}></Progress>
                }
                {/* Rating */}
                {field.FieldType === 'Rating' && 
                  <Axe Axe={field.FieldName} Type={field.FieldType} Value={field.FieldValue} View={'Miniblock'}></Axe>
                }
                {/* Resource */}
                {(field.FieldType === 'Resource' || field.FieldName === 'Project_Manager') && 
                  <Axe Axe={field.FieldName} Value={field.FieldValue} View={'Miniblock'}></Axe>
                }
                {/* Resource Table */}
                {field.FieldType === 'ResourceTable' && 
                  <Axe Axe={field.FieldName} Type={field.FieldType} Value={field.FieldValue} View={'Miniblock'}></Axe>
                }
                {/* String */}
                {/* Data & Meteo Freshness */}
                {(field.FieldName === 'Data_Freshness' || field.FieldName === 'Meteo_Freshness') && <div className="">
                  <Axe Axe={field.FieldName} Value={field.FieldValue} View={'Miniblock'}></Axe>
                </div>}
                {/* Others String */}
                {field.FieldType === 'String' && (field.FieldName !== 'Data_Freshness' && field.FieldName !== 'Meteo_Freshness' && field.FieldName !== 'AsTemplate') && 
                  <div className="fs12">{field.FieldValue}</div>
                }
                {/* Timeline */}
                {field.FieldName === 'Timeline' && startDate && endDate && status && 
                  <Timeline View={'Miniblock'} StartDate={startDate} EndDate={endDate} Status={status}></Timeline>
                }
                {/* Warnings */}

              </div>
            </div>
          )}
        </div>}
      </div>
    )
  }
}

export default MiniBlockDetails;